import React from 'react'

import './styles.scss'
import iconClose from 'assets/images/onboard_close.svg'

import { AlertPage } from './page'
import { AlertFooter } from './footer'
import { DOMHelper, ArrayHelper } from 'utils'
import { zIndexes, keyCodes } from '../../../constants'

export class Alert extends React.Component {
	componentDidMount() {
		DOMHelper.enableScroll(false)
	}

	componentWillUnmount() {
		DOMHelper.enableScroll(true)
	}

	handleClick = (event) => {
		if (!DOMHelper.closest(event.target, '.alert-message')) {
			event.preventDefault()
		}

		if (!event.target.closest('.alert-content')) {
			this.handleCancel(event)
		}
	}

	pages = () => {
		const { children } = this.props
		const results = []
		React.Children.forEach(children, (child) => {
			if (child && child.type === AlertPage) {
				results.push(child)
			}
		})
		return results
	}

	footer = () => {
		const { children } = this.props
		let footer
		React.Children.forEach(children, (child) => {
			if (child && child.type === AlertFooter) {
				footer = child
				return
			}
		})
		return footer
	}

	currentPage = () => {
		const { children, step = 0 } = this.props
		const results = []
		React.Children.forEach(children, (child) => {
			if (child && child.type === AlertPage) {
				results.push(child)
			}
		})
		return results[step]
	}

	handleCancel = (event) => {
		event.preventDefault()
		event.stopPropagation()

		const { onCancel } = this.props
		if (onCancel) onCancel()
	}

	handleKeyDown = (event) => {
		if (event.keyCode === keyCodes.Esc) {
			this.handleCancel(event)
		}
	}

	render() {
		const { id = "alert", className = '', show = true, step = 0, zIndex = zIndexes.Alert, onCancel, containerClassName = '', wrapperClassName = '', hideCloseIcon = false } = this.props
		const pages = this.pages()
		const footer = this.footer()
		const pageIndexes = pages.map((page, index) => index)

		return (
			<div className={`alert ${!show && 'd-none'}`} role="alert" style={{ zIndex }} onClick={this.handleClick} onKeyDown={this.handleKeyDown}>
				<div className={`alert-container ${containerClassName}`}>
					<div className={`alert-wrapper ${wrapperClassName}`}>
						<div id={id} className={`alert-content ${className}`}>
							{pages.map((page, index) => {
								const { className: pageClassName = '', children } = page.props
								return (
									<AlertPage key={pageIndexes[index]} className={`${pageClassName} ${index !== step ? 'd-none' : ''}`}>
										{children}
									</AlertPage>
								)
							})}
							{(onCancel && !hideCloseIcon) &&
								<button className="alert-close" role="button" tabIndex={0} onClick={this.handleCancel} onKeyDown={(event) => { if (event.keyCode === keyCodes.Enter) this.handleCancel() }}>
									<img src={iconClose} alt="Close" />
								</button>
							}
						</div>
						{footer}
					</div>
				</div>
			</div>
		)
	}
}