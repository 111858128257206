import React from 'react'

import './styles.scss'
import iconClear from 'assets/images/cross/gray-16.svg'

import {
	Loader,
	SearchField,
	Select, SelectOption,
	Pagination,
	TableBatchContainer, BadgeButton, BlueButton,
} from 'components'
import { ListItem } from './list-item'
import { AddBlockedIPAlert } from './add-blocked-ip-alert'

import {
	useAlert,
	useAuth,
	AdminBlockedIPProvider, useAdminBlockedIP, AdminBlockIP
} from 'core'
import { ArrayHelper } from 'utils'

export function BlockedIPAddressEl({
	className = '',
}) {
	const { setError } = useAlert()
	const { currentUser } = useAuth()
	const { isLoading, totalCount, blockedIPAddrs, adminBlockedIPSearch } = useAdminBlockedIP()

	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(5)
	const [sortBy, setSortBy] = React.useState("createdAt")

	/** @type {[Array<AdminBlockIP>, Function]} */
	const [selectedAddrs, setSelectedAddrs] = React.useState([])
	React.useEffect(() => { setSelectedAddrs(blockedIPAddrs.filter(x => selectedAddrs.some(y => y.ipAddr === x.ipAddr))) }, [blockedIPAddrs])

	const [isAddNew, setAddNew] = React.useState(false)

	React.useEffect(() => {
		load()
	}, [currentUser, searchText, pageIndex, pageSize, sortBy])

	function load() {
		if (!currentUser?.isAdmin) return

		adminBlockedIPSearch({ searchText, pageIndex, pageSize, sortBy })
	}

	/** @param {AdminBlockIP} blockedIPAddress */
	function handleSelectAddress(blockedIPAddress) {
		if (!blockedIPAddress?.ipAddr) return

		if (selectedAddrs.some(x => x.ipAddr === blockedIPAddress.ipAddr)) {
			setSelectedAddrs(selectedAddrs.filter(x => x.ipAddr !== blockedIPAddress.ipAddr))
		} else {
			setSelectedAddrs([...selectedAddrs, blockedIPAddress])
		}
	}

	async function handleBatchDelete() {
		const { error } = await AdminBlockIP.delete(selectedAddrs)
		if (error) {
			setError(error)
			return
		}

		load()
	}

	return (
		<React.Fragment>
			<div className='admin-blocked-ip-address'>
				<div className="total">Total: {totalCount}</div>
				<div className='header'>
					<TableBatchContainer
						selected={ArrayHelper.isValid(selectedAddrs)}
						onSelectAll={(value) => setSelectedAddrs(value ? blockedIPAddrs : [])}>
						<BadgeButton
							icon={iconClear}
							description="Delete"
							onClick={() => handleBatchDelete()} />
					</TableBatchContainer>
					<SearchField
						value={searchText}
						onChange={value => setSearchText(value)} />
					<Select
						className="view-options"
						value={sortBy}
						onChange={(value) => setSortBy(value)}>
						<SelectOption value="createdAt">Newest</SelectOption>
						<SelectOption value="tryCount">Most Used</SelectOption>
						<SelectOption value="ipAddr">Alphabetical</SelectOption>
					</Select>
					<BlueButton
						className="btn-add"
						type="medium"
						onClick={() => setAddNew(true)}>
						Add
					</BlueButton>
				</div>
				<div className='list'>
					<ListItem isHeader />
					{isLoading ? <Loader /> :
						blockedIPAddrs.map((item, index) => {
							const selected = selectedAddrs.some(x => x.email === item.email)
							return (
								<ListItem
									key={index}
									blockedIPAddress={item}
									selected={selected}
									onSelect={() => handleSelectAddress(item)} />
							)
						})
					}
				</div>
				<Pagination
					className="admin-senders-pagination"
					totalSize={totalCount}
					pageIndex={pageIndex}
					pageSize={pageSize}
					onPageIndex={value => setPageIndex(value)}
					onPageSize={value => setPageSize(value)}
				/>
			</div>
			{isAddNew &&
				<AddBlockedIPAlert
					onClose={(updated) => {
						setAddNew(false)
						if (updated) load()
					}} />
			}
		</React.Fragment>
	)
}

export function AdminBlockIPAddressEl() {
	return (
		<AdminBlockedIPProvider>
			<BlockedIPAddressEl />
		</AdminBlockedIPProvider>
	)
}