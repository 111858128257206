import React from 'react'

import './styles.scss'
import iconTrianglePrev from '../../assets/images/triangle/prev.svg'

export class Modal extends React.Component {
	ref = React.createRef()
	constructor(props) {
		super(props)
	}

	handleNext = (event) => {
		event.preventDefault()
		const { onNext } = this.props
		if (onNext) { onNext() }
	}

	handleClose = (event) => {
		event.preventDefault()
		const { onClose } = this.props
		if (onClose) { onClose() }
	}

	render() {
		const { containerStyle, className, title, message, next, close, children, onNext, onClose } = this.props
		return (
			<div ref={this.ref} className={`modal-container ${containerStyle || ''}`}>
				<div className={`modal ${className || ''}`}>
					{title && <div className="modal-title">{title}</div>}
					{message && <div className="modal-message" dangerouslySetInnerHTML={{ __html: message }}></div>}
					{children}
					<div className="modal-button-container">
						{onNext && <button onClick={this.handleNext}>{next || 'Next'}</button>}
						{onClose && <button onClick={this.handleClose}>{close || 'Close'}</button>}
					</div>
					<img className="modal-triangle" src={iconTrianglePrev} alt="" />
				</div>
			</div>
		)
	}
}