import React from "react";
import { Alert, AlertPage, AlertTitle } from "components";
import "./styles.scss";

import { useAlert } from 'core';

/**
 * @typedef {{onClose: Function}} DigestSavedModalProps
 * @param {DigestSavedModalProps} param0 
 */
export function DigestSavedModal({ onClose }) {
    const { alert, error } = useAlert();
    return (
        <Alert containerClassName='save-digest-modal-wrapper' show={!alert && !error} onCancel={onClose}>
            <AlertPage>
                <AlertTitle>Digest Saved</AlertTitle>
                <div className="save-digest-modal-subtitle-wrapper">
                    <span className="save-digest-modal-subtitle">Your new digest has now been saved successfully.</span>
                </div>
            </AlertPage>
        </Alert>
    )
}