import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { errorMessages } from 'constants/error'

export class AdminUserTag extends BaseModel {
	name

	/** @returns {AdminUserTag} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		return result
	}

	/** @returns {Array<AdminUserTag>} */
	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminUserTag.fromJSON(json))
	}

	static async getAll() {
		try {
			const { error, data } = await Api.get('/admin/user-tags')
			if (error) return { error: errorMessages.CONTACT_US }

			const { specialLinks = [], wordpressLinks = [], userTags = [] } = data || {}
			return {
				specialLinks: specialLinks.map(e => e.permalink),
				wordpressLinks,
				userTags: userTags.map(e => e.name)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getUserTags() {
		try {
			const { error, data } = await Api.get('/admin/user-tags')
			return {
				error: error ? errorMessages.CONTACT_US : null,
				results: data ? AdminUserTag.fromJSONArray(data.userTags) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async batchUpdate(userIds, tags) {
		try {
			const { error, data } = await Api.post('/admin/update-users-tag', { userIds, tags })
			return {
				error: error ? errorMessages.CONTACT_US : null,
				results: data ? AdminUserTag.fromJSONArray(data) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async update(params) {
		try {
			const { error, data } = await Api.post('/admin/update-tags', params)
			return {
				error: error ? errorMessages.CONTACT_US : null,
				result: data ? AdminUserTag.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
}