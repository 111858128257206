import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAlert, Mail } from 'core'
import { routes, redirectTo } from 'routes'

// deliver-mail/9098fd3e-dd16-4671-85d2-cde910d63926

export function DeliverMailScene(props) {
	const navigate = useNavigate()
	const { mailId } = useParams()

	const { setAlert, setError } = useAlert()

	React.useEffect(() => { redirect() }, [])

	async function redirect() {
		const { error } = await Mail.deliver([mailId])
		if (error) {
			setError(error)
		} else {
			setAlert({ message: 'Check your inbox! This email has now been delivered.' })
		}

		navigate(redirectTo({
			route: routes.Dashboard
		}))
	}

	return (<></>)
}