import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconDeleteGray from 'assets/images/delete/gray.svg'
import iconDeleteGreen from 'assets/images/delete/green.svg'

import {
	TableBatchContainer, Pagination,
	Select, SelectOption,
	SearchField, IconButton,
	Loader, BlueButton,
	ConfirmAlert,
} from 'components'
import { ListItem } from './list-item'
import { AddBlockedDiscountDomainAlert } from './add-blocked-domain-alert'

import { AdminBlockedDiscountDomainProvider, useAdminBlockedDiscountDomain } from 'core'
import { ArrayHelper } from 'utils'

export function AdminBlockedDiscountDomain({ }) {
	const { width: windowWidth } = useWindowSize()

	const { isLoading, totalCount, blockedDomains, searchBlockedDomains, deleteBlockedDomains } = useAdminBlockedDiscountDomain()

	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(25)
	const [sortBy, setSortBy] = React.useState('createdAt')
	React.useEffect(() => { search() }, [searchText, pageIndex, pageSize, sortBy])

	console.log(blockedDomains)
	const [selectedBlockedDomains, setSelectedBlockedDomains] = React.useState([])

	const [isAddOn, setAddOn] = React.useState(false)
	const [isBatchDelete, setBatchDelete] = React.useState(false)

	function deviceName() {
		if (windowWidth >= 992) return 'desktop'
		if (windowWidth >= 577) return 'tablet'
		return 'mobile'
	}

	async function search() {
		searchBlockedDomains({ searchText, pageIndex, pageSize, sortBy })
	}

	function handleAdd() {
		setAddOn(true)
	}

	function handleSelect(blockedDomain) {
		if (selectedBlockedDomains.some(x => x.id === blockedDomain?.id)) {
			setSelectedBlockedDomains(selectedBlockedDomains.filter(x => x.id !== blockedDomain?.id))
		} else {
			setSelectedBlockedDomains([...selectedBlockedDomains, blockedDomain])
		}
	}

	function handleBatchSelect(value) {
		if (value) setSelectedBlockedDomains(blockedDomains)
		else setSelectedBlockedDomains([])
	}

	return (
		<>
			<div className='admin-blocked-domains'>
				<div className="total">Total: {totalCount}</div>
				<div className={`table-header ${deviceName()}`}>
					<TableBatchContainer
						selected={ArrayHelper.isValid(selectedBlockedDomains)}
						onSelectAll={(value) => handleBatchSelect(value)} />
					<div className={`batch-container ${ArrayHelper.isValid(selectedBlockedDomains) && 'active'}`}>
						<IconButton
							className="btn-batch delete"
							normal={iconDeleteGray}
							hover={iconDeleteGreen}
							onClick={() => setBatchDelete(true)} />
					</div>
					<div className="filter-container">
						<SearchField
							minimized={windowWidth <= 576}
							value={searchText}
							onChange={(value) => setSearchText(value)} />
						<Select
							value={sortBy}
							onChange={(value) => setSortBy(value)}>
							<SelectOption value="createdAt">Newest</SelectOption>
							<SelectOption value="-createdAt">Oldest</SelectOption>
							<SelectOption value="-domain">Alphabetical A - Z</SelectOption>
							<SelectOption value="domain">Alphabetical Z - A</SelectOption>
						</Select>
						<BlueButton type="medium" onClick={() => handleAdd()}>Add</BlueButton>
					</div>
				</div>
				<div className="admin-blocked-domains-container">
					<ListItem isHeader={true} />
					{isLoading && <Loader />}
					{(!isLoading && !ArrayHelper.isValid(blockedDomains)) &&
						<div className="no-user">There are currently no blocked domains.</div>
					}
					{(!isLoading && ArrayHelper.isValid(blockedDomains)) &&
						blockedDomains.map((blockedDomain, index) => {
							return (
								<ListItem
									key={index}
									blockedDomain={blockedDomain}
									selected={selectedBlockedDomains.some(x => x.id === blockedDomain.id)}
									onSelect={() => handleSelect(blockedDomain)} />
							)
						})
					}
				</div>
				<Pagination
					className='admin-blocked-domains-pagination'
					totalSize={totalCount}
					pageIndex={pageIndex}
					pageSize={pageSize}
					onPageIndex={index => setPageIndex(index)}
					onPageSize={pageSize => setPageSize(pageSize)} />
			</div>
			{isAddOn &&
				<AddBlockedDiscountDomainAlert
					onClose={(updated) => {
						setAddOn(false)
						if (updated) search()
					}} />
			}
			{isBatchDelete &&
				<ConfirmAlert
					message="Are you sure you want to delete selected domains from the blocked domains?"
					onOk={() => {
						setBatchDelete(false)
						setSelectedBlockedDomains([])
						deleteBlockedDomains(selectedBlockedDomains.map(x => x.domain))
					}}
					onCancel={() => setBatchDelete(false)} />
			}
		</>
	)
}

export function AdminBlockedDiscountDomainEl() {
	return (
		<AdminBlockedDiscountDomainProvider>
			<AdminBlockedDiscountDomain />
		</AdminBlockedDiscountDomainProvider>
	)
}
