import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './styles.scss'

import {
	FilterKeywordContainer,
	Switch,
	Selector, SelectorOption,
	HMenuDatePicker
} from 'components'
import { FilterPreviewEl } from './filter-preview'

import {
	useFilter,
	FilterKeyword, Filter, useCustomFilter,
} from 'core'
import { ArrayHelper, ObjectHelper } from 'utils'

const dragItemStyle = (draggableStyle, isDragging) => ({
	...draggableStyle,
	background: isDragging ? '#000000' : 'transparent',
	padding: isDragging ? '8px' : '0',
	border: isDragging ? '1px solid #61CEF7' : 'transparent',
	borderRadius: '2px'
})

const dragListStyle = (isDraggingOver) => ({})

export function CustomFilterEl({
	isDigest = false,
	className = "",
}) {
	const { filter, senders } = useFilter()
	const {
		alwaysOn, setAlwaysOn,
		from, setFrom, to, setTo,
		keywords, setKeywords,
		filterByAttachment, setFilterByAttachment,
		hasAttachment, setHasAttachment,
		hasAttachmentOp, setHasAttachmentOp,
		isSaveFilter, setIsSaveFilter,
		name, setName,
		senderError, dateError, nameError, error,
		isDefaultFilter,
	} = useCustomFilter()

	function handleKeywordDragResult(list, startIndex, endIndex) {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	function handleKeywordDragEnd(result) {
		if (!result.destination) { return }
		const results = this.handleKeywordDragResult(ArrayHelper.copy(keywords), result.source.index, result.destination.index)
		setKeywords(results)
	}

	function handleKeywordClear(index) {
		if (keywords.length <= 1) return
		setKeywords(keywords.filter((x, i) => i !== index))
	}

	function handleAddKeyword() {
		setKeywords([...keywords, FilterKeyword.create()])
	}

	function handleResetFilter() {
		const { alwaysOn, from, to, keywords, hasAttachment, hasAttachmentOp } = Filter.defaultFilter()
		setAlwaysOn(alwaysOn)
		setFrom(from)
		setTo(to)
		setKeywords(keywords)
		setHasAttachment(hasAttachment)
		setHasAttachmentOp(hasAttachmentOp)
	}

	return (
		<div className={`filter-custom ${className}`}>
			<div className="group timeframe">
				<div>Timeframe</div>
				<Selector
					options={[
						new SelectorOption({ label: 'Always on', value: true }),
						new SelectorOption({ label: 'Specific dates', value: false }),
					]}
					value={alwaysOn}
					onChange={(value) => setAlwaysOn(value)}
				/>
			</div>
			{!alwaysOn &&
				<div className="group from-to">
					<div>Date</div>
					<div className="container">
						<HMenuDatePicker
							position="first"
							initialLabel="Specific dates"
							date={from}
							onSelect={(date) => setFrom(date)}
						/>
						<HMenuDatePicker
							position="second"
							baseDate={from || new Date()}
							date={to}
							onSelect={(date) => setTo(date)}
						/>
					</div>
					{dateError && <div className="error">{dateError}</div>}
				</div>
			}
			<div className="group keywords">
				<div>Keywords</div>
				<div>
					<DragDropContext onDragEnd={handleKeywordDragEnd}>
						<Droppable droppableId="keywordsContainer">
							{(provided, snapshot) => (
								<div ref={provided.innerRef} style={dragListStyle(snapshot.isDraggingOver)}>
									{keywords.map((keyword, index) => {
										return (
											<Draggable index={index} key={`${index}`} draggableId={`${index}`}>
												{(provided, snapshot) => (
													<div className={`keyword-item ${snapshot.isDragging ? 'dragging' : ''}`} {...provided.draggableProps} {...provided.dragHandleProps}>
														<div ref={provided.innerRef} style={dragItemStyle(provided.draggableStyle, snapshot.isDragging)}>
															<FilterKeywordContainer
																keyword={keyword}
																index={index}
																dragging={snapshot.isDragging}
																onChange={value => {
																	const results = keywords.map((e, i) => {
																		const result = (i === index) ? ObjectHelper.copy(value) : ObjectHelper.copy(e)
																		return result
																	})
																	setKeywords(results)
																}}
																onClear={() => handleKeywordClear(index)}
															/>
														</div>
													</div>
												)}
											</Draggable>
										)
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<button className="add-keyword" onClick={() => handleAddKeyword()}>+ Add</button>
				</div>
			</div>
			<div className="group">
				<div className="filter-by-attachment">
					<div>Filter by attachment</div>
					<Switch
						value={filterByAttachment}
						onChange={() => {
							setFilterByAttachment(!filterByAttachment)
							setHasAttachment(!filterByAttachment ? true : false)
						}} />
				</div>
				{filterByAttachment &&
					<div className="has-attachment">
						<Selector
							options={[
								new SelectorOption({ label: 'And', value: 'and' }),
								new SelectorOption({ label: 'Or', value: 'or' }),
							]}
							value={hasAttachmentOp}
							onChange={(value) => setHasAttachmentOp(value)}
						/>
						<Selector
							options={[
								new SelectorOption({ label: 'Has attachment(s)', value: true }),
								new SelectorOption({ label: 'No attachment(s)', value: false }),
							]}
							value={hasAttachment}
							onChange={(value) => setHasAttachment(value)}
						/>
					</div>
				}
			</div>
			<div className="group">
				<div className="save-filter">
					<div>Save filter preferences</div>
					<Switch
						value={isSaveFilter}
						onChange={() => setIsSaveFilter(!isSaveFilter)} />
				</div>
			</div>
			{isSaveFilter &&
				<div className="group filter-name-input">
					<input value={name} onChange={(event) => setName(event.target.value)} />
					{nameError && <div className="error">{nameError}</div>}
				</div>
			}
			{error && <div className="error">{error}</div>}
			<div className="group divider"></div>
			<FilterPreviewEl
				isDigest={isDigest}
				className="group" />
			{(filter && !isDefaultFilter()) && <button className="reset-filter btn-delete-filter" onClick={() => handleResetFilter()}>Reset Filter</button>}
		</div>
	)
}