import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "./core";

export function OnlyOneLockrScanAlert({
    onClose = () => { }
}) {
    return (
        <Alert onCancel={() => onClose()}>
            <AlertPage>

                <AlertMessage>
                    Currently, only 1 lockrScan account can be linked to your lockrMail account.
                </AlertMessage>

            </AlertPage>
        </Alert>
    )
}