import React from 'react'

import { useAlert } from 'core/providers/alert'
import { Sender } from 'core/models'

const SendersContext = React.createContext({})

export function SendersProvider({
	children,
}) {
	const { setError } = useAlert()
	const [isLoading, setLoading] = React.useState(false)
	const [senders, setSenders] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)
	const [groupCount, setGroupCount] = React.useState([])
	const [senderIdsInRefresh, setSenderIdsInRefresh] = React.useState([])
	const [params, setParams] = React.useState({})

	async function sendersSearch(params, loading = true) {
		setParams(params)
		if (loading) setLoading(true)
		try {
			const { error, count, results, groupCount } = await Sender.getSenders(params)
			if (loading) setLoading(false)

			if (error) {
				setError(error)
				return
			}

			setSenders(results)
			setTotalCount(count)
			setGroupCount(groupCount)

		} catch (err) {
			setError(err.message || 'Unexpected error occurred.')
		} finally {
			if (loading) setLoading(false)
		}
	}

	async function sendersReload() {
		sendersSearch(params, false)
	}

	async function refreshSenders(params) {
		const senderIds = params.map(x => x instanceof Sender ? x.id : x)
		setSenderIdsInRefresh([...senderIdsInRefresh, ...senderIds])
		const { results } = await Sender.batchGetSender(senderIds)

		setSenders(senders.map(sender => {
			return results.find(x => x.id === sender.id) ?? sender
		}))
		setSenderIdsInRefresh(senderIdsInRefresh.filter(x => results?.some(y => y.id === x.id)))
	}

	function isSenderLoading(senderId) {
		return senderIdsInRefresh.includes(senderId)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		senders,
		totalCount,
		groupCount,

		sendersSearch, sendersReload,
		refreshSenders,
		isSenderLoading,
	}), [isLoading, senders, totalCount, groupCount, senderIdsInRefresh])

	return (
		<SendersContext.Provider value={memoedValue}>
			{children}
		</SendersContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, senders: Array<Sender>, totalCount: number, groupCount, sendersSearch: Function, sendersReload: Function, refreshSenders: *, isSenderLoading: *}} UseSenders
 * @returns {UseSenders}
 */
export function useSenders() {
	return React.useContext(SendersContext)
}