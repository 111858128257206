const { Alert, AlertPage, AlertTitle, AlertButton, AlertButtonContainer } = require("../core")
import './styles.scss'
import LockrMailPreviewImage from '../../../assets/images/lockrMail-preview.svg'
import { useNavigate } from 'react-router-dom'
import { useAuth, User } from 'core'
import { parseSearchParams, redirectTo, routes } from 'routes'
import React from 'react'
import { AlreadyAccountExists } from '../account-exists-alert'
import { Helmet } from 'react-helmet'

export const LockrMailPreview = () => {
    const navigate = useNavigate()
    const { currentLockrScanUser, currentUser, isLoading } = useAuth()
    const [alreadyHaveAccount, setAlreadyHaveAccount] = React.useState(false);
    React.useEffect(() => {
        if (!isLoading) {
            if (!currentLockrScanUser && !currentUser) {

                navigate('/lockrScan/login?openPreview=true')
            }
            else if (currentUser) {

                navigate(routes.LockrScan)
            }
        }
    }, [isLoading])
    return (
        <>
            <Helmet>
                <title>lockrScan | Unsubscribe from Spam</title>
            </Helmet>
            {alreadyHaveAccount ? <AlreadyAccountExists email={currentLockrScanUser?.email}></AlreadyAccountExists> : !isLoading && <Alert className="lockrMail-preview-alert" onCancel={() => { navigate("/lockrscan/dashboard") }}>
                <AlertPage>
                    <AlertTitle>Get lockrMail</AlertTitle>
                    <div className="lockrMail-preview-wrapper">
                        <div className="lockrMail-info-text">
                            Prevent spam before it hits your email.
                        </div>
                        <div className='lockrMail-info-image-wrapper'>
                            <div>
                                <div className='lockrMail-info-text-bold'><span >lockrMail is a</span><span style={{ color: "#61CEF7" }}> new email address</span> but <span style={{ color: '#FAC748' }}>not a new inbox.</span></div>
                                <div className="lockrMail-info-text">
                                    Curate your email experience with easy <span style={{ fontWeight: 'bold', color: '#2E70D2' }}>blocking</span>, automated <span style={{ fontWeight: 'bold', color: '#50E8B1' }}>filters</span>, and custom <span style={{ fontWeight: 'bold', color: '#61CEF7' }}>digests</span>.
                                </div>
                                <div className='lockrMail-info-bold'> Stop giving out your personal email address - sign up for lockrMail today.</div>
                                <AlertButtonContainer>
                                    <AlertButton onClick={async () => {
                                        const { error } = await User.checkEmailUsed(currentLockrScanUser.email);
                                        if (error) {
                                            setAlreadyHaveAccount(true);
                                            return;
                                        }
                                        else {
                                            const searchParams = { email: window.btoa(currentLockrScanUser.email), firstName: window.btoa(currentLockrScanUser.firstName), lastName: window.btoa(currentLockrScanUser.lastName) };
                                            navigate(redirectTo({
                                                route: routes.Register,

                                                searches: searchParams
                                            }))
                                        }

                                    }}>Get lockrMail</AlertButton>
                                </AlertButtonContainer>
                                <div className='lockrMail-already-account'> Already have a lockrMail account? <span style={{ color: '#61CEF7', fontWeight: "bold", cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => {
                                    navigate(routes.Login)
                                }}>Sign in</span></div>
                            </div>
                            <div className='lockrMail-info-image'>
                                <img src={LockrMailPreviewImage}></img>
                            </div>
                        </div>


                    </div>
                </AlertPage>

            </Alert>}
        </>
    )
}