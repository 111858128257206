import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import iconAccount from 'assets/images/icon_account.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'

import { AppHeader, AppHeaderRightContainer, AppHeaderRightItem } from '../app-header'
import { AppMenu, AppMenuItem, AppMenuDivider } from '../app-menu'
import { ConfirmAlert } from '..'

import { config } from 'constants/config'
import { redirectTo } from 'routes'

class MenuItem {
	className
	leftIcon
	label
	rightIcon
	path
	url
	divider = false
	skip
	onClick

	constructor({ className = '', leftIcon, label, rightIcon, path, url, divider, skip, onClick }) {
		this.className = className
		this.leftIcon = leftIcon
		this.label = label
		this.rightIcon = rightIcon
		this.path = path
		this.url = url
		this.divider = divider || false
		this.skip = skip || false
		this.onClick = onClick
	}
}


export function PublicHeader({
	className = '',
	children
}) {
	const navigate = useNavigate()

	const appMenuRef = React.createRef()
	const [isOpenMenu, setIsOpenMenu] = useState(false)
	useEffect(() => {
		if (appMenuRef.current) { appMenuRef.current.open(isOpenMenu) }
	}, [isOpenMenu])

	const [alert, setAlert] = React.useState(null)

	function handleMenu() {
		setIsOpenMenu(!isOpenMenu)
	}

	function handleItem(item) {
		if (item.onClick) {
			item.onClick()
			return
		}

		if (item.path) navigate(redirectTo({ route: item.path }))
		if (item.url) window.open(item.url, '_blank')
	}

	function handleItemClick(item) {
		if (appMenuRef.current) { appMenuRef.current.open(false) }

		handleItem(item)
	}

	function menuItems() {
		let results = [
			new MenuItem({ label: 'FAQ', url: `${config.LOCKR_URL}/faq`, }),
			new MenuItem({ label: 'Contact', url: `${config.LOCKR_URL}/contact` }),
			new MenuItem({ label: 'Sign in', path: '/login' })]
		return results
	}

	return (
		<>
			<AppHeader
				className={className}
				isPublic
				onLogo={(event) => handleItemClick(new MenuItem({ url: config.LOCKR_URL }))}
				onMenu={() => handleMenu()}>
				<AppHeaderRightContainer>
					{menuItems().map((menuItem, index) => <AppHeaderRightItem key={index} {...menuItem} />)}
				</AppHeaderRightContainer>
			</AppHeader>
			<AppMenu ref={appMenuRef} onClose={() => setIsOpenMenu(false)}>
				{menuItems().filter(item => !item.skip).map((item, index) => {
					if (item.divider) return <AppMenuDivider key={index} />
					return <AppMenuItem key={index} {...item} onClick={() => handleItemClick(item)} />
				})}
			</AppMenu>
			{alert &&
				<ConfirmAlert {...alert}
					onOk={() => setAlert(null)}
					onClose={() => setAlert(null)} />
			}
		</>
	)
}