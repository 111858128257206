import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "components"

export const DeleteShipmentModal = ({ onCancel, onDelete }) => {
    return <Alert onCancel={() => onCancel()}>
        <AlertPage>
            <AlertTitle>Delete Order</AlertTitle>
            <AlertMessage>
                Are you sure you want to delete this order? By doing so, there will no longer be the ability to track it in My Orders.
            </AlertMessage>

            <AlertButtonContainer>
                <AlertButton type="positive" title="Delete" onClick={onDelete}></AlertButton>
                <AlertButton type="negative" title="Cancel" onClick={() => { onCancel() }}></AlertButton>
            </AlertButtonContainer>
        </AlertPage>
    </Alert>
}