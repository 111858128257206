import React from 'react'

import './styles.scss'
import iconRemove from '../../../../../assets/images/cross/gray-16.svg'
import iconRemoveHover from '../../../../../assets/images/cross/green-16.svg'

import { Icon } from '../../../../../components'

import { firstLetter } from '../../../../../utils'

export function SenderEl({
	value: sender,
	onRemove = () => { }
}) {
	return (
		<div className={`account-group-sender ${!sender && 'no-sender'}`}>
			{sender ?
				<>
					<div>
						<Icon className="group-icon" src={sender.avatar} placeholder={firstLetter(sender.name)} />
						<div className="group-name">{sender.name}</div>
						<div className="group-email">{sender.email}</div>
					</div>
					<div>
						<button className="remove" onClick={() => onRemove()}>
							<img src={iconRemove} alt="" />
							<img src={iconRemoveHover} alt="" />
							<span>Remove</span>
						</button>
					</div>
				</>
				:
				'There is no sender added yet.'
			}
		</div>
	)
}