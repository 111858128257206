import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconDisable from 'assets/images/disable-gray.svg'
import iconMinimize from 'assets/images/arrow/down-white-small.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'

import {
	Checkbox, Icon,
} from 'components'
import { SubItem, SubItemType } from './sub-item'
import { DomainSettingsAlert } from '../domain-settings-modal'

import {
	useAlert,
	useAuth,
	DomainSetting
} from 'core'
import { DOMHelper } from 'utils'
import { AddDomainModal } from '../add-new-modal'

/**
 * @typedef {{domainSetting: DomainSetting, selected: Array<boolean>, isExpanded: boolean, onSelect: *, onChange: *, handleSetting: *}} Props
 * @param {Props} param0
 */
export function DomainListItem({
	domainSetting: initialDomainSetting,	/// Instance of Domain
	selected = [],
	isExpanded: initialExpanded = false,
	onSelect = (email, popup, password, allowConsent, allowGPC) => { },
	onChange = (value, isMobile) => { },
	handleSetting = (value) => { }
}) {
	const { width: windowWidth } = useWindowSize()

	const { setAlert } = useAlert()
	const { currentUser } = useAuth()

	const ref = React.useRef()

	const [selectedEmail, selectedPopup, selectedPassword, selectedConsent, selectedGPC] = selected

	const [domainSetting, setDomainSetting] = React.useState(initialDomainSetting)
	React.useEffect(() => { if (initialDomainSetting) setDomainSetting(initialDomainSetting) }, [initialDomainSetting])
	const [isExpanded, setExpanded] = React.useState(initialExpanded)
	React.useEffect(() => {
		setExpanded(initialExpanded)
		if (initialExpanded) {
			DOMHelper.scrollIntoView(ref.current)
		}
	}, [initialExpanded])
	const [isSettingsOn, setSettingsOn] = React.useState(false)
	const [isAddNew, setIsAddNew] = React.useState(false)

	function isDesktop() { return windowWidth >= 992 }

	async function unblockAll() {
		const { error } = await DomainSetting.unblockAll([domainSetting.domain])
		if (error) { }
		else { onChange() }
	}

	function handleClick(event) {
		if (isDesktop()) setExpanded(!isExpanded)
		else if (domainSetting.id === "null") {
			setIsAddNew(true)
		} else { setSettingsOn(true) }
	}

	async function handleUnblockAll(event) {
		event.preventDefault()
		event.stopPropagation()

		if ((selectedPassword || selectedConsent || selectedGPC) && (!selectedEmail && !selectedPopup)) {
			await DomainSetting.resetToDefault([domainSetting?.domain], selectedPassword, selectedConsent, selectedGPC)
			onChange()
		}
		else {

			setAlert({
				message: 'Please confirm you wish to remove all block settings for the selected domain(s).',
				buttons: [
					{ type: 'positive', label: 'Confirm', onClick: () => unblockAll() },
					{ type: 'negative', label: 'Cancel' },
				]
			})
		}
	}

	function handleChange(value) {
		setDomainSetting(value)
		handleSetting(value)
	}

	return (
		<>
			{domainSetting &&
				<>
					<div ref={ref} className="domain-list-item" onClick={handleClick}>
						<Checkbox
							iconUnchecked={iconUnchecked}
							iconChecked={iconChecked}
							checked={selectedEmail || selectedPopup || selectedPassword || selectedConsent || selectedGPC}
							onChange={() => {
								const value = !(selectedEmail || selectedPopup || selectedPassword || selectedConsent || selectedGPC)
								onSelect(value, value, value, value, value)
							}} />
						<Icon src={domainSetting.avatar} placeholder={domainSetting.name} />
						<div className="info">
							<div className="name">{domainSetting.name}</div>
							{/* {domainSetting.isBlocked &&
								<div className="status">
									<img src={iconDisable} alt="" />
									<span>{domainSetting.status}</span>
								</div>
							} */}
						</div>
						{!domainSetting.manuallyAdded && <button className="unblock-all" onClick={handleUnblockAll}>Reset</button>}
						<button className={`btn-expand ${isExpanded && 'expanded'}`} onClick={() => setExpanded(!isExpanded)}>
							<img src={iconMinimize} alt="" />
						</button>
					</div>
					{isExpanded &&
						<React.Fragment>
							<SubItem
								domainSetting={domainSetting}
								type={SubItemType.Email}
								selected={selectedEmail}
								onSelect={(value) => onSelect(value, selectedPopup, selectedPassword, selectedConsent, selectedGPC)}
								onChange={(value) => handleChange(value)} />
							<SubItem
								domainSetting={domainSetting}
								type={SubItemType.Popup}
								selected={selectedPopup}
								onSelect={(value) => onSelect(selectedEmail, value, selectedPassword, selectedConsent, selectedGPC)}
								onChange={(value) => handleChange(value)} />
							<SubItem
								domainSetting={domainSetting}
								type={SubItemType.Password}
								selected={selectedPassword}
								onSelect={(value) => onSelect(selectedEmail, selectedPopup, value, selectedConsent, selectedGPC)}
								onChange={(value) => handleChange(value)} />
							<SubItem
								domainSetting={domainSetting}
								type={SubItemType.Consent}
								selected={selectedConsent}
								onSelect={(value) => onSelect(selectedEmail, selectedPopup, selectedPassword, value, selectedGPC)}
								onChange={(value) => handleChange(value)} />
							<SubItem
								domainSetting={domainSetting}
								type={SubItemType.GPC}
								selected={selectedGPC}
								onSelect={(value) => onSelect(selectedEmail, selectedPopup, selectedPassword, selectedConsent, value)}
								onChange={(value) => handleChange(value)} />
						</React.Fragment>
					}
					{isSettingsOn &&
						<DomainSettingsAlert
							value={domainSetting}
							onClose={(value, isUnblockAll = false) => {
								setSettingsOn(false)
								if (isUnblockAll) {
									setIsAddNew(true)
								} else if (value) onChange(value, true)
							}} />
					}

					{isAddNew && <AddDomainModal
						domain={domainSetting.domain}
						onClose={(updated) => {
							setIsAddNew(false)
							onChange(null, true)
						}}
					/>}
				</>
			}
		</>
	)
}