import React from 'react'

import './styles.scss'

export function AlertMessage({
	className = "",
	value,
	children
}) {
	return (
		<>
			{value && typeof value === 'string' && <div className={`alert-message ${className}`} dangerouslySetInnerHTML={{ __html: value }} ></div>}
			{value && typeof value !== 'string' && <div className={`alert-message ${className}`}>{value}</div>}
			{children &&
				<div className={`alert-message ${className}`}>
					{children}
				</div>
			}
		</>
	)
}