import React from 'react'

import {
	Loader, InfoAlert, ErrorAlert
} from '../../../../components'

import { useAlert } from 'core'

export function ReferralListItem({
	value
}) {
	const { email, isAccepted } = value

	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [isReminded, setReminded] = React.useState(false)

	async function handleRemind() {
		setLoading(true)
		const { error } = await value.remind()
		setLoading(false)

		if (error) { setError(error) }
		else { setReminded(true) }
	}

	return (
		<>
			<div className="referral-item">
				{isLoading ? <Loader /> :
					<>
						<div className="email">{email}</div>
						<button disabled={isAccepted} onClick={() => handleRemind()}>{isAccepted ? "Accepted" : "Remind"}</button>
					</>
				}
			</div>
			{isReminded &&
				<InfoAlert
					message="lockrMail has sent a reminder invitation to the email address provided."
					onClose={() => setReminded(false)} />
			}
		</>
	)
}