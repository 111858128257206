import React from 'react'

import { useAlert } from 'core/providers'
import { AdminBlockIP } from 'core/models'

const AdminBlockedIPContext = React.createContext({})

export function AdminBlockedIPProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [blockedIPAddrs, setBlockedIPAddrs] = React.useState([])
	const [params, setParams] = React.useState({})

	async function adminBlockedIPSearch(params, loading = true) {
		setParams(params)

		if (loading) setLoading(true)
		const { error, totalCount, results } = await AdminBlockIP.search(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(totalCount)
		setBlockedIPAddrs(results ?? [])
	}

	function adminBlockedIPReload() {
		adminBlockedIPSearch(params, false)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		blockedIPAddrs,
		totalCount,

		adminBlockedIPSearch,
		adminBlockedIPReload,
	}), [isLoading, totalCount, blockedIPAddrs])

	return (
		<AdminBlockedIPContext.Provider value={memoedValue}>
			{children}
		</AdminBlockedIPContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, blockedIPAddrs: Array<AdminBlockIP>, totalCount: number, adminBlockedIPSearch: Function, adminBlockedIPReload: Function}} UseAdminBlockedIPAddress
 * @returns {UseAdminBlockedIPAddress}
 */
export function useAdminBlockedIP() {
	return React.useContext(AdminBlockedIPContext)
}