import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton,
} from 'components/alert/core'

import { config } from 'constants/config'

export function PublisherConsentAlert({
	onOk = () => { },
	onCancel = () => { },
	onClose,
}) {

	function handleOk() {
		onOk()
		handleCancel()
	}

	function handleCancel() {
		onCancel()
		if (onClose) onClose()
	}

	return (
		<Alert className="publisher-consent-alert" onCancel={onClose}>
			<AlertPage>
				<AlertTitle>Publisher Consent</AlertTitle>
				<AlertMessage>
					lockrMail partners with publishers to enable the open and free web. By consenting to share your information with publishers in our network, you are allowing lockrMail to share your lockrMail alias, as well as hashed versions of your associated destination emails for the purposes of informing targeted advertising across the publisher site. lockrMail will never reveal your actual destination email addresses to partners.<br></br>
					<br></br>
					lockrMail users can also control their consent settings individually by publisher on the Domain Settings page. To learn more about our Publisher Consent Network, <a rel="noreferrer noopener" href={`${config.LOCKR_URL}/tutorial/publisher-consent-network/`} target="_blank">please see here</a>.<br></br>
					<br></br>
					Consent in the publisher network is not required for lockrMail access.
				</AlertMessage>
				<AlertButtonContainer>
					<AlertButton onClick={() => handleOk()}>Yes, I agree</AlertButton>
					<AlertButton onClick={() => handleCancel()}>Not right now</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}