import React, { forwardRef } from 'react'
import { Navigate, useLocation, useNavigate, } from 'react-router-dom'
import { useAsync } from 'react-use'

import { useAlert } from 'core/providers/alert'
import { User, UserActivity } from 'core/models'
import {
	MixPanel, FacebookPixel, Canny, Segment,
} from 'core/analytics'
import { Storage, DateHelper } from 'utils'
import { routes } from 'routes'
import { Api } from 'core/api'
import { errorMessages } from 'constants/error'
import { useImperativeHandle } from 'react'
import { lockrScanRoutes } from 'routes/lockrscan'
import { LockrScanSegment } from 'core/analytics/segment-lockrscan'

const AuthContext = React.createContext({})

export const AuthProvider = forwardRef(({
	children,
}, ref) => {
	const navigate = useNavigate()
	const { setError, setAlert } = useAlert()
	useImperativeHandle(ref, () => ({
		userLogout,
	}))
	const [isLoading, setLoading] = React.useState(true)

	/** @type {[User, Function]} */
	const [currentUser, setCurrentUser] = React.useState(null)
	/** @type {[Array<UserActivity>, Function]} */
	const [userActivities, setUserActivities] = React.useState([])
	const [isNewUser, setNewUser] = React.useState(false)
	const [lockrScanStatus, setLockrScanStatus] = React.useState(false);
	const [currentLockrScanUser, setCurrentLockrScanUser] = React.useState(null)
	const [previousLockrScanProfile, setPreviousLockrScanProfile] = React.useState(null);

	React.useEffect(() => {
		if (Storage.onlyLockrScanUser == "true") { lockrScanUserGetProfile() }
		else {
			if (!currentUser) userGetProfile()

		}
	}, [])
	React.useEffect(() => {
		setPreviousLockrScanProfile(currentLockrScanUser)
	}, [currentLockrScanUser])

	useAsync(async () => {
		await handleCurrentUser()
		return () => {
			const cannyScriptId = 'lockr-canny-script'
			let cannyScript = document.querySelector(`script#${cannyScriptId}`)
			if (cannyScript) {
				document.body.removeChild(cannyScript)
			}
		}
	}, [currentUser])

	async function handleCurrentUser() {
		if (!currentUser) return

		Canny.add(currentUser)

		// Timezone
		DateHelper.default = currentUser?.timezone ?? 'America/New_York'

		// FullStory
		// const email = currentUser.email
		// if (FullStoryBlockingUsers.includes(email.toLowerCase())) {
		// 	FullStory.shutdown()
		// } else {
		// 	FullStory.restart()

		// 	FullStory.identify(currentUser.id, {
		// 		displayName: currentUser.name,
		// 		email: currentUser.email,
		// 	})

		// 	FullStory.setVars("page", {
		// 		displayName: currentUser.name,
		// 		email: currentUser.email,
		// 	})

		// 	FullStory.setUserVars({
		// 		displayName: currentUser.name,
		// 		email: currentUser.email,
		// 	})
		// }


		// MixPanel
		MixPanel.identify(currentUser.email)
		MixPanel.people.set({
			email: currentUser.email,
			firstName: currentUser.firstName,
			lastName: currentUser.lastName
		})

		// Facebook
		FacebookPixel.init({
			email: currentUser.email,
			firstName: currentUser.firstName,
			lastName: currentUser.lastName
		})

		// Segment
		Segment.identify(currentUser.id, currentUser.name, currentUser.email, currentUser)

		if (!currentUser.isGeneratedUser) {
			await loadUserActivities()
		}
	}
	async function deleteLockrScanAcccount() {
		try {

			const { data, error } = await Api.delete('/lockrScan/delete', { lockrScanUserId: Storage.lockrScanUserId })
			setCurrentLockrScanUser(null)
			setPreviousLockrScanProfile(null);
			if (Storage.onlyLockrScanUser == "true") {
				Storage.onlyLockrScanUser = null
				userLogout();
				navigate(routes.Login);
			}
			else {

				await userGetProfile();
			}
			return { data }
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	async function lockrScanLogin(data) {
		if (data?.id) {

			Storage.accessToken = data?.accessToken
			Storage.refreshToken = data?.refreshToken

			Storage.cookieAccessToken = data?.accessToken
			Storage.cookieRefreshToken = data?.refreshToken

			Storage.onlyLockrScanUser = data?.isOnlyLockrScanUser
			const lockrScan = currentLockrScanUser
			const lockScanUser = await lockrScanUserGetProfile()
			if (!data?.isOnlyLockrScanUser) {
				userGetProfile()
			}
			// if (!lockrScanUserId) {
			// 	navigate(routes.LockrScanDashboard, { state: { enableSuccessScreen: true } })
			// }			
			if (data?.isNewLockrScanUser) {
				Storage.lockrScanSuccess = true
				navigate(routes.LockrScanSuccessScreen, { state: { enableSuccessScreen: true } })
			}
			else {
				navigate(routes.LockrScanDashboard)
			}



		}
	}
	async function lockrScanUserGetProfile(loading = true) {
		const accessToken = Storage.accessToken
		if (!accessToken) { setLoading(false); return }
		if (loading) setLoading(true)
		const { data, error } = await User.getLockrScanProfile();
		if (error) {
			if (setError) {
				setError(error)
			}
			setLoading(false)
			userLogout()
			return
		}

		Storage.lockrScanUserId = data?.lockrScanUser?.id
		setLockrScanStatus(data?.lockrScanUser?.status == 0 ? false : true)
		setCurrentLockrScanUser(data.lockrScanUser)
		if (data.lockrScanUser.id) {
			LockrScanSegment.identify(data.lockrScanUser)
		}
		setLoading(false)
		setPreviousLockrScanProfile(currentLockrScanUser);
		return data.lockrScanUser
	}
	async function userGetProfile(loading = true) {
		const accessToken = Storage.accessToken
		if (!accessToken) { setLoading(false); return }

		if (loading) setLoading(true)
		const { error, result } = await User.getProfile()

		const _data = await User.getLockrScanProfile();


		Storage.lockrScanUserId = result?.lockrScanUserId
		Storage.userId = result?.id

		setLockrScanStatus(_data.data?.lockrScanUser?.status == 0 ? false : true)

		setLoading(false)

		if (error || _data.error) {
			setAlert({
				message: `Your account has been logged out due to inactivity.<br /><br /> Please use the button below to log back in. If this problem persists, then please ask for <a target='_blank' href='https://lockrmail.com/support/' style='font-weight:bold; color:white; cursor:pointer;'> help here</a>.`,
				buttons: [
					{
						title: 'Login',
						type: 'positive',
						onClick: () => {
							navigate('/login')
						}
					}
				]
			})
			userLogout()
			return
		}
		Storage.onlyLockrScanUser = null
		setPreviousLockrScanProfile(currentLockrScanUser);
		setCurrentUser(result)
		setLoading(false)
		setCurrentLockrScanUser(_data.data.lockrScanUser)
		if (_data.data.lockrScanUser) {
			LockrScanSegment.identify(_data.data.lockrScanUser)
		}

		return _data.data.lockrScanUser
	}

	function updateCurrentUser(params) {
		Object.keys(params).map(key => {
			currentUser[key] = params[key]
		})
		setCurrentUser(currentUser)
	}

	function setCurrentUserwithhide() {
		setCurrentUser({ ...currentUser, showDigestPopup: false })
	}

	function userLogout() {
		try {
			if (currentUser) {
				currentUser?.logout()
			}

			setCurrentUser(null)
			setCurrentLockrScanUser(null)

			Storage.accessToken = null
			Storage.refreshToken = null

			Storage.cookieAccessToken = null
			Storage.cookieRefreshToken = null

			Storage.lockrScanUserId = null
			Storage.userId = null





			// FullStory.restart()
		} catch (error) {
			console.log(error)
		} finally {
			if (Storage.onlyLockrScanUser) {
				Storage.onlyLockrScanUser = null
				navigate(routes.LockrScanLogin);
			}
			else {
				Storage.onlyLockrScanUser = null
				navigate(routes.Login)
			}
		}
	}

	async function loadUserActivities() {
		if (!currentUser) return

		const { error, results } = await UserActivity.load()
		setUserActivities(results)
		setNewUser(results.filter(x => x.activity === UserActivity.Type.Login).length <= 1)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		currentUser,
		currentLockrScanUser,
		isNewUser,

		updateCurrentUser,
		setCurrentUserwithhide,
		userGetProfile,
		lockrScanUserGetProfile,
		userLogout,
		lockrScanLogin,
		deleteLockrScanAcccount,
		lockrScanStatus,

	}), [isLoading, currentUser, isNewUser, currentLockrScanUser])

	return (
		<AuthContext.Provider value={memoedValue}>
			{children}
		</AuthContext.Provider>
	)
})

/**
 * @typedef {{isLoading: boolean, currentUser: User, isNewUser: boolean, userGetProfile: Function, userLogout: Function, currentLockrScanUser}} UseAuth
 * @returns {UseAuth}
 */
export function useAuth() {
	return React.useContext(AuthContext)
}

export function RequireAuth({ children }) {
	const location = useLocation()

	if (!Storage.accessToken) return <Navigate replace to={routes.Login} state={{ from: location.state?.from ?? location }} />
	return children
}

export function RequireLockrScanAuth({ children }) {
	const location = useLocation()

	if (!Storage.accessToken) return <Navigate replace to={routes.LockrScanLogin} state={{ from: location.state?.from ?? location }} />
	return children
}

export function RequireLockrScanEnabled({ children }) {
	const { currentUser, isLoading } = useAuth();

	if (!Storage.accessToken) {
		console.log("here")
		return (<Navigate replace to={routes.Login} state={{ lockrScanPreview: true }}></Navigate>)
	}
	// if (currentUser && !currentUser.isLockrScanEnabled && !isLoading && !currentUser?.lockrScanUserId) {
	// 	return (<Navigate replace to={routes.Dashboard} state={{ lockrScanPreview: true }}></Navigate>)
	// }
	return <>{children}</>
}

export function NoLockrScanAccount({ children }) {
	const { currentUser, isLoading, currentLockrScanUser, lockrScanUserGetProfile } = useAuth();
	if (Storage.onlyLockrScanUser) {
		return <>{children}</>
	}
	else if (currentLockrScanUser) {
		if (!currentUser?.lockrScanUserId && !isLoading) {

			return <Navigate replace to={routes.LockrScanDashboard} state={{ lockrScanPreview: true }} />
		}
	}
	return <>{children}</>
}

export function Require2FA({ children }) {
	const location = useLocation()

	const { currentUser } = useAuth()

	if (currentUser && currentUser.allow2FA && !currentUser.is2FAPassed) {
		return <Navigate replace to={routes.TFA} state={{ from: location.state?.from ?? location }} />
	}

	return <RequireAuth>{children}</RequireAuth>
}

export function RequireAdmin({ children }) {
	const { currentUser } = useAuth()

	if (currentUser && !currentUser?.isAdmin) return <Navigate replace to={routes.Dashboard} />
	return <Require2FA>{children}</Require2FA>
}

export function RequireDigestEnabled({ children }) {
	const { currentUser } = useAuth()

	// if (currentUser && !currentUser.isDigestEnabled) return <Navigate replace to={routes.Dashboard} state={{ openDigestPreview: true }} />
	return <Require2FA>{children}</Require2FA>
}
