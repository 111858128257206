import React from 'react'

export function LockrScanDomainListItem({
    value,
    isHeader,
    onClick = () => { }
}) {
    return (
        <>
            {isHeader ?
                <div className="sender-domains-list-item header">
                    <div></div>
                    <div>Total Accounts</div>
                    <div>Total Emails</div>
                    <div>Total Unsubscribed</div>
                    <div>Total lockrArchived</div>
                </div>
                :
                <div className={`sender-domains-list-item ${isHeader && "header"}`}>
                    <div>{value.domain}</div>
                    <div>{value.lockrScanUsers}</div>
                    <div>{value.lockrEmails}</div>
                    <div>{value.unSubscribedAccounts}</div>
                    <div>{value.lockrArchivedAccounts}</div>
                </div>
            }
        </>
    )
}