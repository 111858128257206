import React from 'react'
import { useWindowSize } from 'react-use'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import './styles.scss'
import { randomUUID } from 'utils'

const articles = [
	"Sign up on any site using your lockrmail email address.",
	"Your emails will filter through lockrMail and deliver to your primary email inbox.",
	"Use the lockrMail dashboard to control delivery settings for each Sender.",
	"Enable lockrMail's automated Filters to fully personalize your inbox.",
	"Review and manually deliver any blocked emails that may be of importance.",
]

const featuredImages = [
	require('assets/images/browser-extension/how-it-works-1.png').default,
	require('assets/images/browser-extension/how-it-works-2.svg').default,
	require('assets/images/browser-extension/how-it-works-3.svg').default,
	require('assets/images/browser-extension/how-it-works-4.svg').default,
	require('assets/images/browser-extension/how-it-works-5.svg').default,
]

export function HowItWorksEl({
	id = randomUUID(),
	className = ''
}) {
	const { width } = useWindowSize()
	const ref = React.useRef()

	React.useLayoutEffect(() => {
		gsap.registerPlugin(ScrollTrigger)
		if (width >= 768) {
			ScrollTrigger.create({
				trigger: '.how-it-works--content',
				pin: '.featured-image-container',
				start: 'top 20%',
				end: 'bottom 740',
				scrub: true,
			})

			const images = gsap.utils.toArray('.featured-image>img')
			const articles = gsap.utils.toArray('.article')
			articles.forEach((article, index) => {
				let params = {
					trigger: article,
					start: 'top 50%',
					end: () => `+=${article.clientHeight}`,
					toggleClass: {
						targets: images[index],
						className: 'show'
					}
				}
				if (index === 0) params = { ...params, start: 'top 50%' }
				ScrollTrigger.create(params)
			})
		}
	}, [])

	return (
		<div ref={ref} id={id} className={`${className} how-it-works`}>
			<div className='how-it-works--title'>How lockrMail works</div>
			<div className='how-it-works--content'>
				<div className='article-container'>
					{articles.map((article, index) => {
						return (
							<React.Fragment key={index}>
								<div className='article'>
									{article}
									{index === 0 &&
										<button className='btn-article'>
											<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="20" cy="20" r="19.5" transform="rotate(90 20 20)" stroke="#8B8B8B"></circle>
												<path d="M14.2593 18L20.1297 23.8704L26.0002 18" stroke="#8B8B8B" strokeWidth="1.17409" strokeLinecap="round"></path>
											</svg>
										</button>
									}
								</div>
							</React.Fragment>
						)
					})}
				</div>
				<div className='featured-image-container'>
					{featuredImages.map((featuredImage, index) =>
						<div key={index} className='featured-image'>
							<img className={`${index === 0 && 'show'}`} src={featuredImage} alt='' />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}