import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
	useAlert,
	User,
	Sender,
} from 'core'
import { errorMessages } from 'constants/error'
import { routes, redirectTo, parseSearchParams } from 'routes'

// allow-sender/437c2bde-f97a-4fd1-a12f-8f4aff4504c9?email=dailyskimm@morning7.theskimm.com
// allow-sender/c6706020-158f-4034-b474-7d747c31544e?email=eletters@om.adexchanger.com
// allow-sender/c9cb8c55-1024-4e72-94db-13f1b0ce2e2b?email=westelm@mail.westelm.com

export function AllowBlockSenderPage({
	block = false,
}) {
	const location = useLocation()
	const navigate = useNavigate()
	const { senderId } = useParams()
	const [searchParams] = useSearchParams()

	const { setAlert, setError } = useAlert()

	useEffect(() => {
		redirect()
	}, [])

	async function getSender() {
		const { error, errorCode, result } = await Sender.getSender(senderId)
		if (error) {
			navigate(redirectTo({
				route: routes.Dashboard,
			}), {
				state: { error: errorMessages.EMAIL_WRAPPER_UPDATE_SENDER_FAILED, errorCode }
			})
			return
		}

		await allowSender(result)
	}

	/** @param {Sender} sender */
	async function allowSender(sender) {
		const { email } = parseSearchParams(searchParams)

		if (!sender?.isVerified) {
			setAlert({
				message: `lockrMail now requires each destination email to be verified prior to any delivery of messages. This is a new requirement, but one that we implement to improve the overall system and reliability.
					<br><br>
					To re-send an activation email to the destination email ${sender?.connectedEmail?.email}, please click the confirmation below and an email with an authentication link will immediately be delivered. The link expires in 24-hours.
					<br><br>
					No messages will be delivered to this destination email until it is verified. We appreciate you complying with this new lockrMail policy.`,
				buttons: [
					{
						title: 'Send email',
						type: 'positive',
						onClick: () => {
							User.sendVerifyEmail(sender?.connectedEmail?.email)
							setAlert(null)
						}
					},
				]
			})
			navigate(redirectTo({
				route: routes.Dashboard,
			}))
			return
		}

		const { error } = await Sender.block([sender], block)
		if (error) {
			setError(error)
			navigate(redirectTo({
				route: routes.Dashboard,
			}))
			return
		}

		setAlert({
			title: 'Confirmed!',
			message: block ? `You have now blocked emails from ${email}.` : `You have now allowed emails from ${email}.`,
			buttons: [
				{
					type: 'positive',
					title: 'Ok',
					onClick: () => setAlert(null)
				}
			]
		})

		navigate(redirectTo({
			route: routes.Dashboard,
		}))
	}

	async function redirect() {
		await getSender()
	}

	return (
		<></>
	)
}