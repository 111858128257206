import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import './styles.scss'
import iconHeaderLogo from 'assets/images/lockr-logo.svg'
import { parseSearchParams } from 'routes';
import { User, useAuth } from 'core';
import { Alert, AlertPage, AppFooter, BlueButton, Checkbox, Navigation } from 'components';
import numberIcon from '../../assets/images/browser-extension/extension-feedback-number-1.svg'
import { useEffect, useState } from 'react';

export function BrowserExtensionFeedback() {
    const params = useParams();
    const { currentUser, currentLockrScanUser } = useAuth()

    const [inteferingBrowsing, setInterferingBrowser] = useState(false);
    const [dontFunctionAsExpected, setDontFunctionAsExpected] = useState(false);
    const [notUsingLockrMail, setNotusingLockrmail] = useState(false);
    const [otherCheckbox, setOther] = useState(false);
    const [otherText, setOtherText] = useState('');
    const [improvementText, setImprovementText] = useState('');
    const [feedbackCollect, setFeedbackCollect] = useState(false)
    const [feedbackFilled, setFeedbackFilled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkUninstallToken();
    }, [])

    const checkUninstallToken = async () => {
        if (params.token) {
            const { collectFeedback } = await User.checkUninstallToken(params.token);
            setFeedbackCollect(collectFeedback);
            if (!collectFeedback) {
                navigate('/dashboard');
            }
        }
    }

    const collectFeedback = async () => {
        await User.collectFeedback({ inteferingBrowsing, dontFunctionAsExpected, notUsingLockrMail, otherCheckbox, otherText, improvementText, token: params.token });
        setFeedbackFilled(true);
    }


    const renderHeader = () => {
        if (currentUser || currentLockrScanUser) {
            return <Navigation></Navigation>
        }
        else {
            return (
                <>
                    <div className="top-bar"></div>
                    <div className="header">
                        <div className="header-content">
                            <a href="/"><img src={iconHeaderLogo} alt="Lockr.io" /></a>
                            <div className="header-content-right">
                                <Link className="header-item active" to="/browser-extension">Browser Extension</Link>
                                <a className="header-item" href={`${process.env.REACT_APP_WORDPRESS_URL}/faq`} target="_blank">FAQ</a>
                                <a className="header-item" href={`${process.env.REACT_APP_WORDPRESS_URL}/contact`} target="_blank">Contact</a>
                                <a className="header-item" href="/login">Sign in</a>
                                <a className="header-item" href="/signup">Sign up</a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const checkSendDisabled = () => {
        if (inteferingBrowsing || notUsingLockrMail || dontFunctionAsExpected || otherCheckbox) {
            return false
        }
        return true
    }

    return (
        <div className='browser-extension-feedback'>
            {renderHeader()}
            {feedbackCollect && <div className='body-wrapper'>
                <div className='heading-sorry'>
                    Sorry to see you go...
                </div>
                <div className='heading-sorry-info'>
                    We’re always looking to improve so please take a moment to share your
                    feedback with the lockr team.

                </div>
                <div className='heading-feedback-inputs'>
                    <img src={numberIcon}></img>
                    <div className='text'> Why did you uninstall the lockr Chrome extension?  </div>
                </div>
                <div className='checkboxes'>
                    <>
                        <div className='checkbox-item'>
                            <Checkbox checked={inteferingBrowsing} onChange={() => { setInterferingBrowser(!inteferingBrowsing) }}></Checkbox>
                            <div className='checkbox-item-text'>It was interfering with my browsing</div>
                        </div>

                    </>
                    <>
                        <div className='checkbox-item'>
                            <Checkbox checked={dontFunctionAsExpected} onChange={() => { setDontFunctionAsExpected(!dontFunctionAsExpected) }}></Checkbox>
                            <div className='checkbox-item-text'>The product doesn’t function the way I expected</div>
                        </div>

                    </>
                    <>
                        <div className='checkbox-item'>
                            <Checkbox checked={notUsingLockrMail} onChange={() => { setNotusingLockrmail(!notUsingLockrMail) }}></Checkbox>
                            <div className='checkbox-item-text'>I’m not using lockrMail anymore</div>
                        </div>

                    </>
                    <>
                        <div className='checkbox-item'>
                            <Checkbox checked={otherCheckbox} onChange={() => { setOther(!otherCheckbox) }}></Checkbox>
                            <div className='checkbox-item-text'>Other</div>
                        </div>
                        {otherCheckbox && <input maxLength={100} autoFocus className='other-input' value={otherText} onChange={(e) => { setOtherText(e.target.value) }} />}
                    </>
                </div>
                <div style={{ marginTop: 36 }}></div>
                <div className='heading-feedback-inputs'>

                    <div style={{ height: 34, width: 34, display: 'flex', border: '2px solid #FFFFFF', borderRadius: 50, fontSize: 20, color: '#61CEF7', justifyContent: 'center', alignItems: 'center' }}>2</div>
                    <div className='text'>How can we improve the lockr Chrome extension?</div>



                </div>
                <textarea maxLength={250} value={improvementText} onChange={(e) => { setImprovementText(e.target.value) }} className='text-area' placeholder='Message' />
                <div style={{ marginTop: 32 }}></div>
                <BlueButton disabled={checkSendDisabled()} onClick={() => { collectFeedback() }}>Send</BlueButton>

                <div className='consider-reinstall'>
                    Consider re-installing?
                    <div className='info'>
                        Re-install the lockr extension by clicking on the button below.

                    </div>
                    <div style={{ marginTop: 10 }}></div>
                    <BlueButton onClick={() => { window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank') }}>Add to chrome</BlueButton>
                </div>
            </div>}
            {feedbackFilled && <Alert onCancel={() => {
                if (currentUser) { navigate('/dashboard') }
                else {
                    navigate('/login')
                }
            }}>
                <AlertPage>
                    Thank you for your feedback.
                </AlertPage>
            </Alert>}
            <AppFooter></AppFooter>
        </div>
    )
}