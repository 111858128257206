import React from "react";

import './styles.scss';
import { Carousel, ConfigProvider } from "antd";

export const ImageCarousel = ({ images = [], autoPlay = false }) => {
    return (<ConfigProvider theme={{
        components: {
            Carousel: {
                dotWidth: 10,
                dotHeight: 5,
                dotActiveWidth: 20,

            }
        }
    }}>
        <Carousel autoplay={autoPlay} style={{ height: 218 }} dotPosition="bottom" dots={{ className: 'dots-class' }}>
            {images.map(item => (
                <div >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={item} style={{ height: 218 }} />
                    </div>
                </div>
            ))}

        </Carousel>
    </ConfigProvider>)
}