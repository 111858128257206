import { config } from "constants/config"
import { UAParser } from "ua-parser-js"

export const isDesktop = (window.innerWidth >= 992)

export const isMobile = (window.innerWidth < 992)

export const isSmallMobile = (window.innerWidth <= 576)

export function isNumber(variable) {
	const result = isFinite(variable)
	return result
}

export function isValidText(text, requiredTrim = false) {
	return (text && (requiredTrim ? (text.trim() !== "") : (text !== "")))
}

export function stringContainsUrl(value) {
	let inputArray = value?.split(" ");
	for (let i = 0; i < inputArray.length; i++) {
		if (checkUrl(inputArray[i])) {
			return true;
		}
		else {
			if (isUrl(inputArray[i])) {
				return true;
			}
		}
	}
	return false;
}
export function checkUrl(input) {
	let url;
	try {
		url = new URL(input);
		return true;
	} catch (e) {
		return false;
	}
}
export function isUrl(value) {
	var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(value);
}

/**
 * @param {string} value
 * @returns {boolean}
 */
export function isIPAddr(value) {
	if (!value) return false

	const blocks = value.split(".")
	if (blocks.length !== 4) return false
	for (const block of blocks) {
		if (!block || !isNumber(block)) return false

		try {
			const num = parseInt(block)
			if (num < 0 || num > 255) return false
		} catch (error) {
			return false
		}
	}
	return true
}

/**
 * @param {string} text
 * @returns {string}
 */
export function firstLetter(text) {
	if (!isValidText(text)) { return "" }
	return text.substr(0, 1).toUpperCase()
}

export function capitalizeFirstLetter(text, locale = navigator.language) {
	if (!text) return null

	const [first, ...rest] = text
	return first.toLocaleUpperCase(locale) + rest.join('')
}

export function isEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function isLockrMail(email) {
	return isEmail(email) && (email.toLowerCase().includes(`@${config.REACT_APP_MAIL_ENVIRONMENT.toLowerCase()}`) || email.toLowerCase().includes('@lockrmail.com'))
}

export function isValidPassword(password) {
	const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\-/@#$%{}^&_+=()!,<>?:;*]).{6,}$/
	//	const regExp = /^(?=.*[\\d])(?=.*[A-Z])[\\w!@#$%^&*-:;<>.,]{8,}$/
	return regExp.test(password)
}

export function isDomain(value) {
	if (!value) return false
	var regExp = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/)
	return value.match(regExp)
}

export function checkPasswordValidation(password1, password2) {
	let results = []
	if (!password1 || password1 === "" || !password2 || password2 === "") {
		results.push("Please fill passwords.")
	}

	if (!isValidPassword(password1)) {
		results.push("Password doesn't meet requirements.")
	}

	if (password1 !== password2) {
		results.push("Passwords do not match.")
	}

	return results
}

export function generateEmailSuggestions(firstName, lastName) {
	const suffix = "@lockrmail.com"
	let results = []
	results.push(`${firstName.toLowerCase()}${lastName.toLowerCase()}${suffix}`)
	if (firstName.toLowerCase() !== lastName.toLowerCase()) {
		results.push(`${lastName.toLowerCase()}${firstName.toLowerCase()}${suffix}`)
	}
	results.push(`${firstName.toLowerCase()}${lastName.toLowerCase()}${Math.floor(Math.random() * 100)}${suffix}`)
	if (firstName.toLowerCase() !== lastName.toLowerCase()) {
		results.push(`${lastName.toLowerCase()}${firstName.toLowerCase()}${Math.floor(Math.random() * 100)}${suffix}`)
	}

	return results
}

export function isValidPhoneNumber(phoneNumber) {
	if (!isValidText(phoneNumber, true)) { return false }
	const filteredPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
	return filteredPhoneNumber.length >= 10
}

export function phoneNumberAutoComplete(value, previousValue) {
	// return nothing if no value
	if (!value) return value;

	// only allows 0-9 inputs
	const currentValue = value.replace(/[^\d]/g, '');
	const cvLength = currentValue.length;

	if (!previousValue || value.length > previousValue.length) {

		// returns: "x", "xx", "xxx"
		if (cvLength < 4) return currentValue;

		// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
		if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

		// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
	}
}


export function randomUUID() {
	let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16)
	let result = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-')
	return result
}

export function zeroPad(number, base = 10) {
	var len = (String(base).length - String(number).length) + 1
	return len > 0 ? String(new Array(len).join('0') + number) : String(number)
}

export function colorFromText(text, invert = false) {
	function hashCode(text) {
		let hash = 0
		for (let i = 0; i < text.length; i++) {
			hash = text.charCodeAt(i) + ((hash << 5) - hash)
		}
		return hash;
	}

	const i = hashCode(text)
	let hex = i & 0x00FFFFFF
	if (invert) { hex = 0x00FFFFFF - hex }
	let c = (hex).toString(16).toUpperCase()

	const result = "00000".substring(0, 6 - c.length) + c
	return '#' + result
}

export function scrollToId(id) {
	if (!id) return
	const anchorEl = document.getElementById(id)
	if (anchorEl) {
		anchorEl.scrollIntoView()
	}
}

export function scrollIntoView(el, animate = true) {
	if (!el) return
	el.scrollIntoView({ behavior: animate && 'smooth' })
}

export function range(start, stop, step = 1) {
	if ((step > 0 && start > stop) || (step < 0 && start < stop)) {
		return [];
	}

	var result = [];
	for (var i = start; step > 0 ? i <= stop : i >= stop; i += step) {
		result.push(i);
	}

	return result;
}

export function copyToClipboard(value) {
	/* Copy the text inside the text field */
	navigator.clipboard.writeText(value);

	/* Alert the copied text */
	alert("Copied lockrMail: " + value);
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export function userAgent() {
	return new UAParser().getUA()
}

/**
 * @param {string} value
 * @returns {boolean}
 */
export function containsNoneLetter(value) {
	var regExp = new RegExp(/^(([a-zA-Z]))$/)
	return !regExp.test(value)
}

export function base64Decode(base64) {
	return window?.atob(base64) || undefined;
}
export function nFormatter(num) {
	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B';
	}
	if (num >= 1000000) {
		return (num / 1000000).toFixed(0).replace(/\.0$/, '') + 'M';
	}
	if (num >= 10000) {
		return (num / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
	}
	if (num >= 1000) {
		return num.toLocaleString();
	}
	return num;
}

export function secondsToDhms(seconds) {

	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	let result = {}
	const range = []
	if (d > 0) {
		range.push(d != 1 ? 'Days' : 'Day')
		range.push(h != 1 ? 'Hours' : 'Hour')
		range.push(m != 1 ? 'Minutes' : 'Minute');
		result[range[0]] = nFormatter(d)
		result[range[1]] = h
		result[range[2]] = m
	}
	else {
		range.push(h != 1 ? 'Hours' : 'Hour');
		range.push(m != 1 ? 'Minutes' : 'Minute');
		range.push(s != 1 ? 'Seconds' : 'Second');
		result[range[0]] = h
		result[range[1]] = m
		result[range[2]] = s
	}

	return result;

}

export function insertElementInArray(element, index, array) {
	const updatedArray = [...array]; // Create a shallow copy of the array
	updatedArray.splice(index, 0, element); // Insert the element at the specified index
	return updatedArray; // Return the updated array
}