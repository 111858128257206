import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './styles.scss'
import iconGear from 'assets/images/gear/gray.svg'
import iconCaretDown from 'assets/images/caret-down-8B8B8B.svg'
import iconShare from 'assets/images/share-gray.svg'
import iconClose from 'assets/images/close-thin-C4.svg'

import {
	Loader,
	SelectDropDown, SelectOption,
	FilterModal,
	BlueButton,
} from 'components'
import { AddSenderField } from './add-sender-field'
import { DigetSenderListItem } from './sender-list-item'

import {
	useAlert,
	Digest, useDigests, Filter,
} from 'core'
import { ArrayHelper, DateHelper, dragItemStyle, dragListStyle, handleDragResult } from 'utils'
import { routes, redirectTo, parseSearchParams } from 'routes'
import { SaveDigestModal } from './save-digest-modal'
import { DigestSavedModal } from './digest-saved-modal'

export function DigestDetailsEl({ }) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { setAlert, setError } = useAlert()
	const {
		currentDigest, digestSelect, digestOpenSettings, digestsLoad,
		isSenderLoading, currentSenders, digestLoadSenders, enableDigestSave, setEnableDigestSave
	} = useDigests()

	const [senders, setSenders] = React.useState(currentSenders)
	React.useEffect(() => { setSenders(currentSenders) }, [currentSenders])

	const [filterSender, setFilterSender] = React.useState(null)
	const [filterId, setFilterId] = React.useState(null)
	const [showSaveDigestModal, setShowSaveDigestModal] = React.useState(false);
	const [showDigestSavedModal, setShowDigestSavedModal] = React.useState(false);
	const previousDigest = React.useRef({
		value: currentDigest
	})
	const previousSenders = React.useRef({
		value: currentSenders,
		count: 0
	});
	const [allowSave, setAllowSave] = React.useState(false);
	const [isDigestSaved, setIsDigestSaved] = React.useState(false);

	React.useEffect(() => { parseLocation() }, [searchParams])

	React.useEffect(() => {
		previousSenders.current.count = 0;
		previousDigest.current.value = currentDigest;
		setIsDigestSaved(false);		
		digestLoadSenders(true, (updatedSenders) => {
			previousSenders.current.count = 1;
			previousSenders.current.value = updatedSenders;
			if (updatedSenders.length >= 1) {
				setIsDigestSaved(true);
			}
		});
		setAllowSave(false);
		return;
	}, [currentDigest]);

	React.useEffect(() => {
		if (previousSenders.current.value.length !== senders.length && previousSenders.current.count === 1) {
			setAllowSave(true);
		}
	}, [senders])

	function parseLocation() {
		const { filterId } = parseSearchParams(searchParams)

		setFilterId(filterId)
	}

	async function handleArchive() {
		const { error } = await Digest.toggleArchive(currentDigest)
		if (error) {
			setError(error)
			return
		}

		digestsLoad(false)
	}

	async function deleteDigest() {
		const { error } = await Digest.delete(currentDigest)

		if (error) {
			setError(error)
			return
		}

		digestsLoad(false)
	}

	async function handleDelete() {
		setAlert({
			dismissable: false,
			message: 'Are you sure you want to delete this Digest? Any and all settings, Senders, Filters, etc. will be removed. You can always Archive so you can reactivate at a later date.',
			buttons: [
				{ type: 'positive', label: 'Cancel', },
				{ type: 'warning', label: 'Delete Digest', onClick: () => deleteDigest() },
			]
		})
	}

	async function handleAddSender(sender) {
		if (!currentDigest?.id) return
		const { error } = await Digest.addSenders(currentDigest?.id, [sender])
		if (error) {
			setError(error)
			return
		}

		digestLoadSenders(false)
	}
	async function handleDragEnd(result) {
		if (!result.destination) { return }

		const results = handleDragResult(currentSenders, result.source.index, result.destination.index)
		setSenders(results)

		const { error } = await Digest.reorganize([currentSenders[result.destination.index], currentSenders[result.source.index]], currentDigest.id)
		if (error) {
			setError(error)
			return
		}

		digestLoadSenders(false)
	}

	function handleViewArchive(detailId) {
		navigate(redirectTo({
			route: routes.ViewDigestArchives,
			searches: { detailId }
		}))
	}

	function handleClose() {
		digestSelect(null)
	}

	function renderSenderCount() {
		if (senders.length === 1) return `${senders.length} Sender`
		return `${senders.length} Senders`
	}

	return (
		<React.Fragment>
			<div className="my-digest-details">
				<div className="wrapper">
					<header>
						<div>
							<div>{currentDigest?.name}</div>
							<div>{renderSenderCount()}
								{ArrayHelper.isValid(currentDigest.receivedDates) &&
									<div className="view-archives">
										View archives
										<SelectDropDown>
											{currentDigest.receivedDates?.map((receivedDate, index) =>
												<SelectOption
													key={index}
													onClick={() => handleViewArchive(receivedDate?.detailId)}>
													{DateHelper.format(receivedDate.date, "MMM DD, YYYY")}
												</SelectOption>
											)}
										</SelectDropDown>
									</div>
								}
							</div>
						</div>
						<div className="actions">
							{(!!senders.length && isDigestSaved && !enableDigestSave) && <div onClick={() => {
								navigate(routes.Dashboard, {
									state: {
										selectedDigest: {
											currentDigest,
											fromScreen: 'DIGEST',
										}
									}
								})
							}} className='adjust-sender-settings-wrapper'>
								<span>Adjust sender settings</span>
							</div>}
							<div className="btn-action btn-settings">
								<img src={iconGear} alt="" />
								<span>Settings</span>
								<img src={iconCaretDown} alt="" />
								<SelectDropDown>
									<SelectOption selectable={false} onClick={() => digestOpenSettings(true)}>Settings</SelectOption>
									<SelectOption className="d-none" selectable={false}>Share</SelectOption>
									<SelectOption selectable={false} onClick={() => handleArchive()}>{currentDigest?.isArchived ? 'Unarchive' : 'Archive'}</SelectOption>
									<SelectOption selectable={false} onClick={() => handleDelete()}>Delete</SelectOption>
								</SelectDropDown>
							</div>
						</div>
						{(!!senders.length && isDigestSaved && !enableDigestSave) && <div onClick={() => {
							navigate(routes.Dashboard, {
								state: {
									selectedDigest: {
										currentDigest,
										fromScreen: 'DIGEST',
									}
								}
							})
						}} className='adjust-sender-settings-wrapper-mobile'>
							<span>Adjust sender settings</span>
						</div>}
					</header>
					<AddSenderField
						removeSenders={senders}
						onSelectSender={(sender) => handleAddSender(sender)} />
					<div className="sender-list">
						{isSenderLoading ? <Loader /> :
							<React.Fragment>
								<DragDropContext onDragEnd={handleDragEnd}>
									<Droppable droppableId="my-digests-sender-list">
										{(provided, snapshot) => (
											<div ref={provided.innerRef} style={dragListStyle(snapshot.isDraggingOver)}>
												{senders.map((sender, index) => {
													return (
														<Draggable index={index} key={sender.id} draggableId={sender.id}>
															{(provided, snapshot) => (
																<div className={`group-email ${snapshot.isDragging ? 'dragging' : ''}`} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<div ref={provided.innerRef} style={dragItemStyle(provided.draggableStyle, snapshot.isDragging)}>
																		<DigetSenderListItem
																			key={sender.id}
																			dragging={snapshot.isDragging}
																			sender={sender}
																			onFilter={() => setFilterSender(sender)} />
																	</div>
																	{provided.placeholder}
																</div>
															)}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</React.Fragment>
						}
					</div>
					<BlueButton onClick={() => {
						setShowSaveDigestModal(true);
						setEnableDigestSave(false);
					}} disabled={!allowSave && !enableDigestSave} className='save-button'>Save</BlueButton>
					<button className="close" onClick={() => handleClose()}>
						<img src={iconClose} alt="" />
					</button>
				</div>
			</div>
			{filterSender &&

				<FilterModal
					isDigest={true}
					category={Filter.Category.Digest}
					initialSenders={[filterSender]}
					onClose={(updated) => {
						setFilterSender(null)
						if (updated) digestLoadSenders(false)
					}} />
			}
			{filterId &&
				<FilterModal
					isDigest={true}
					category={Filter.Category.Digest}
					onClose={(updated) => {
						if (updated) digestLoadSenders(false)
					}} />
			}
			{showSaveDigestModal && <SaveDigestModal
				onClose={() => {
					setShowSaveDigestModal(false);
					setShowDigestSavedModal(true);
				}}
				onUpdateDashboardSettings={() => {
					setShowSaveDigestModal(false);
					navigate(routes.Dashboard, {
						state: {
							selectedDigest: {
								currentDigest
							}
						}
					})
				}}
			/>}
			{showDigestSavedModal && <DigestSavedModal onClose={() => {
				setShowDigestSavedModal(false);
				setAllowSave(false);
				setIsDigestSaved(true);
			}} />}
		</React.Fragment>
	)
}