export const zIndexes = Object.freeze({
	'Checklist': 10,

	'Menu': 98,

	'Alert': 100,
	'EmailModal': 101,
	'FilterModal': 102,

	'SelectDropDown': 100,

	'IModal': 201,
})