import { AppFooter, BlueButton, Loader, Navigation, NotificationContainer, Pagination, SearchField, Switch } from 'components';
import './styles.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Tiles } from 'scenes/dashboard/statistics/tiles';
import { GoogleMap, Marker, MarkerClusterer, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { darkStyles } from 'utils/google-maps-style';
import customMarker from '../../assets/images/custom-map-marker.svg'
import customMarkerSelected from '../../assets/images/custom-map-marker-selected.svg'
import { ShippingListComponent } from './ShippingListComponent';
import { DateHelper, Storage, useWidth } from 'utils';
import MapViewImage from '../../assets/images/map-view.svg'
import { Shippment } from 'core/models/shipment';
import moment from 'moment-timezone';
import { ShippingDetailsModal } from './ShippingDetailsModal';
import { ImageCarousel } from 'components/image-carousel';
import emptyShippments from '../../assets/images/empty-shipments.svg';
import { DiscountComponent } from 'scenes/Discount/DiscountComponent/DiscountComponent';
import { Discount } from 'core/models/discount';
import externalIcon from 'assets/images/external-arrow.svg'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from 'core';
import showDiscount from '../../assets/images/show-discounts.svg'
import { parseSearchParams } from 'routes';
import noShipmentImage from '../../assets/images/shipment-no-images.jpg'
import { shippingStatus } from 'utils/shipping-helper';
import { AddShipmentModal } from './AddShipmentModal';
import clusterMarker from '../../assets/images/cluser-marker.svg'

const dummyMapsData = [
    {
        lat: 40.741895,
        lng: -73.989308,

    },
    {
        lat: 39.00338,
        lng: 3.17403,
    }
]



export function ShippingCenterScene() {

    const renderByGroups = ['OutForDelivery', 'InTransit', 'InfoReceived', 'Others']
    const [searchParams] = useSearchParams();
    const { width } = useWidth()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })
    const [mapSelected, setMapSelected] = useState(false);
    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        map.setZoom(12)
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const [tabSelected, setTabSelected] = useState('Active')
    const [selected, setSelected] = useState(-1);
    const [hovered, setHovered] = useState();
    const [lat, setLat] = useState(dummyMapsData[0].lat);
    const [lng, setLng] = useState(dummyMapsData[0].lng);
    const [searchText, setSearchText] = useState('');

    const [shipmentList, setShipmentList] = useState([]);
    const [shipmentDates, setShipmentsDate] = useState([]);
    const [groupCounts, setGroupCounts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedElement, setSelectedElement] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [coupons, setSearchCoupons] = useState([])
    const [pageSize, setPageSize] = useState(5)
    const [shipmentsLoading, setShipmentsLoading] = useState(0);
    const [shipmentId, setShipmentId] = useState('');
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addShipmentError, setAddShipmentError] = useState('');
    const [clusteredComplete, setClusteredComplete] = useState(false);
    const timeout = useRef({ timer: null });

    const { currentLockrScanUser, isLoading, currentUser, } = useAuth();

    useEffect(() => {
        let bounds = undefined

        let counter = 0;
        let tempLat = undefined;
        let tempLng = undefined;


        window?.google.maps.LatLngBounds && (bounds = new window.google.maps.LatLngBounds())
        shipmentList.forEach((mapData) => {

            if (mapData?.latestShipmentInfo?.latestLatitude) {
                bounds?.extend({
                    lat: parseInt(mapData?.latestShipmentInfo?.latestLatitude), lng: parseInt(mapData?.latestShipmentInfo?.latestLongitude)
                })
                counter++;
                tempLat = parseInt(mapData?.latestShipmentInfo?.latestLatitude);
                tempLng = parseInt(mapData?.latestShipmentInfo?.latestLongitude);
            }
        })
        map && counter > 0 && map.fitBounds(bounds);
        map && map.setCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() })
        bounds?.getCenter()?.lat() && counter > 0 && setLat(bounds?.getCenter()?.lat());
        bounds?.getCenter()?.lat() && counter > 0 && setLng(bounds?.getCenter()?.lng());
        if (counter == 1) {
            setLat(tempLat);
            setLng(tempLng);
            map && map?.setZoom(6)
        }
        if (counter == 0) {

            map && map.setCenter({ lat: 43.000000, lng: -75 })
            map && map?.setZoom(4)
        }

    }, [shipmentList, map])

    const navigate = useNavigate();
    useEffect(() => {
        if ((!currentUser?.hiddenFeatures?.includes('shipping') && !isLoading && !Storage.onlyLockrScanUser == "true")) {
            navigate('/dashboard')
        }
    }, [currentUser])

    const onShippmentComponentHover = (lat, lng) => {
        if (lat && lng) {
            map?.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) })
        }
    }
    useEffect(() => {
        getSearchCoupons();

    }, [])
    useEffect(() => {
        parseLocation();
    }, [searchParams])
    useEffect(() => {
        setPageNum(0)
        if (pageNum == 0) {
            loadShipments(0)
        }
    }, [searchText, pageSize])
    useEffect(() => {
        loadShipments(pageNum)
    }, [pageNum])
    useEffect(() => {

        loadShipments(0);
        setPageNum(0);

    }, [tabSelected])

    function parseLocation() {
        const params = parseSearchParams(searchParams);
        if (params?.shipmentId) {
            setShipmentId(params?.shipmentId)
            setShowModal(true);
        }
        else {
            setShowModal(false);
            setShipmentId('')
        }
    }


    const renderToggle = () => {
        return <div className='shipment-map-toggle'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='toggle-image' style={{ marginRight: 8 }} src={(shipmentList?.length > 0 && tabSelected == 'Active') ? MapViewImage : showDiscount}></img> {(shipmentList?.length > 0 && tabSelected == 'Active') ? 'Map View' : 'Show Discounts'}
            </div>
            <div>
                <Switch value={mapSelected} onChange={() => { setMapSelected(!mapSelected) }} />
            </div>
        </div>
    }

    const addShipment = async (trackingNumber, senderName, senderEmail) => {
        const { success, error } = await Shippment.createShipment(trackingNumber, senderName, senderEmail)
        if (success) {
            setAddModalOpen(false);
            setPageNum(0)
            loadShipments(0);
            setAddShipmentError('')
        }
        if (error) {
            setAddShipmentError(error)
        }


    }


    const loadShipments = async (pageNumber) => {
        setShipmentsLoading(true)
        const data = await Shippment.getShipmentList(searchText, pageNumber, pageSize, tabSelected == 'Completed' ? true : false);

        setShipmentList(data.results)
        setShipmentsDate(data.countByDate);
        setTotalCount(data.count);
        setGroupCounts(data.groupCounts);
        setTotalPages(Math.ceil(data.count / 20));
        setShipmentsLoading(false)

    }

    const getSearchCoupons = async () => {
        let { count, result } = await Discount.getCoupons(undefined, undefined, undefined, undefined, undefined, undefined, 1, 5, undefined, undefined, false);
        setSearchCoupons(result)

    }

    const handleDeleteShipment = (shipmentId) => {
        const index = shipmentList.findIndex((s) => s.id == shipmentId);
        if (index >= 0) {
            let shipments = [...shipmentList]
            shipments.splice(index, 1);
            setShipmentList(shipments)
        }
    }

    const renderShippingList = () => {
        if (shipmentList.length !== 0) {
            return <>

                <div className='shipping-list-parent'
                // we had scroll to paginate before, but this is now changed to pagination
                // onScroll={(e) => {
                //     if ((e.currentTarget.scrollTop + e.currentTarget.clientHeight === e.currentTarget.scrollHeight) && !shipmentsLoading && !(pageNum >= totalPages)) {
                //         setPageNum(pageNum + 1)
                //     }
                // }}
                >
                    {shipmentsLoading ? <div style={{ height: '1205px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div> :
                        tabSelected == 'Active' ? <>
                            {renderByGroups.map(head => {
                                if (shipmentList.some(element => element.latestShipmentInfo.latestStatus == head || (head == 'Others' && (element.latestShipmentInfo.latestStatus == 'Exception' || element.latestShipmentInfo.latestStatus == 'AvailableForPickup' || element.latestShipmentInfo.latestStatus == 'AttemptFail' || element.latestShipmentInfo.latestStatus == 'Pending' || element.latestShipmentInfo.latestStatus == 'Expired')))) {
                                    return <><div className='shipping-date'>
                                        {shippingStatus[head]} ({groupCounts[head]})
                                    </div>
                                        <div className='list'>
                                            {shipmentList?.map((element, index) => {
                                                if (element.latestShipmentInfo.latestStatus == head || (head == 'Others' && (element.latestShipmentInfo.latestStatus == 'Exception' || element.latestShipmentInfo.latestStatus == 'AvailableForPickup' || element.latestShipmentInfo.latestStatus == 'AttemptFail' || element.latestShipmentInfo.latestStatus == 'Pending' || element.latestShipmentInfo.latestStatus == 'Expired')))
                                                    return <div onMouseEnter={() => { setHovered(index) }} onMouseLeave={() => { setHovered(-1) }}><ShippingListComponent handleDelete={handleDeleteShipment} onSelect={() => { setSelectedElement(element) }} onShippmentComponentHover={onShippmentComponentHover} shipmentElement={element} markCompleted={markCompleted} /></div>
                                            })}
                                        </div></>
                                }
                            })}
                        </> : shipmentDates?.map((shipmentDate, index) => {
                            if ((shipmentList.some(element => DateHelper.format(element.deliveredAt, 'MM/DD/YYYY') === DateHelper.format(shipmentDate.date, 'MM/DD/YYYY')) && tabSelected == 'Completed')) {
                                // if (true) {
                                return <>
                                    <div className='shipping-date'>{shipmentDate.date ? DateHelper.format(shipmentDate.date, 'MM/DD/YYYY') : 'Delivered'} ({shipmentDate.dateCount})</div>
                                    <div className='list'>
                                        {shipmentList?.map((element) => {
                                            if (moment(element.deliveredAt).format('MM/DD/YYYY') == moment(shipmentDate.date).format('MM/DD/YYYY')) {
                                                return <ShippingListComponent handleDeleteShipment={handleDeleteShipment} isDelivered={true} onShippmentComponentHover={onShippmentComponentHover} shipmentElement={element} markCompleted={markCompleted} />
                                            }
                                        })}
                                    </div>
                                </>
                            }
                            else {
                                return <></>
                            }
                        })}

                </div>
                <Pagination className='shipping-center-pagination' onPageIndex={(i) => { setPageNum(i) }} totalSize={totalCount} pageIndex={pageNum} pageSize={pageSize} onPageSize={(v) => setPageSize(v)} />

            </>
        }
        return <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ height: 200, width: 200 }} src={emptyShippments} />
                <div style={{ fontWeight: 600, fontSize: 32, marginTop: 24, textAlign: 'center' }}>No orders found</div>
                <div style={{ fontSize: 18, marginTop: 24, textAlign: 'center' }}>You currently have 0 active orders to track</div>
            </div>
        </div>

    }

    const markCompleted = async (id) => {
        await Shippment.markAsDelivered(id)
        loadShipments(0);
    }

    function renderOverlays(clusterer, lat, lng) {

        let clusters = clusterer?.getClusters();

        for (let i = 0; i < clusters.length; i++) {
            let markers = clusters[i].getMarkers();

            let indexOfMarker = markers.findIndex(marker => (marker.position.lat() == lat && marker.position.lng() == lng));

            if (indexOfMarker >= 0) {
                if (clusters[i]?.getMarkers()?.length > 1) {

                    return false
                }
                if (clusters[i]?.getMarkers()?.length == 1) {
                    return true
                }

            }

        }
        return true
    }

    if (!isLoading) {

        return (<div>
            <NotificationContainer />
            <Navigation />
            <div className='shipping-center-parent'>
                {/* <div className='snapshot'>Snapshot</div>
            <div className='last-updated'>Last updated 3:27pm</div>
            <div className='header'>
                <div className='header-tabs'>
                    <div className='header-tabs-item'>
                        <div className='total-number'>8</div>
                        <div className='item-description'>Active orders</div>
                    </div>
                    <div className='header-tabs-item'>
                        <div className='total-number'>294</div>
                        <div className='item-description'>Completed orders</div>
                    </div>
                    <div className='header-tabs-item'>
                        <div className='total-number'>302</div>
                        <div className='item-description'>Total orders tracked</div>
                    </div>

                </div>
                <div className='header-slider'>
                    <Tiles />
                </div>
            </div> */}
                <div className='my-orders-header'>
                    <div style={{ flex: 1 }}>
                        <div className='my-orders'>
                            My Orders
                        </div>
                        <div className='my-orders-header-description'>
                            Track your packages without all the interruptions.
                        </div>
                    </div>
                    <div className='my-orders-header-search-wrapper' style={{ flex: 1, justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
                        <SearchField value={searchText} onChange={(t) => { setSearchText(t) }} className='my-orders-header-search' />
                        <BlueButton onClick={() => { setAddModalOpen(true) }} className='add-order-button'> Add an order </BlueButton>
                    </div>
                </div>
                <div className='shipments-wrapper'>

                    <div className='shipment-list-wrapper' style={{ flex: ((shipmentList.length == 0 || tabSelected == 'Completed') && width < 1236 && mapSelected) ? 0 : 1.8 }} >
                        <div style={{ display: 'flex', alignContent: 'center', borderBottom: '1px solid var(--Stone, #353738)', justifyContent: 'space-between' }}>
                            <div className='tabs-header'>
                                <div className={`tab ${tabSelected == 'Active' && 'selected'}`} onClick={() => { setTabSelected('Active') }}>
                                    {width > 800 ? "Active Orders" : "Active"}
                                </div>
                                <div className={`tab ${tabSelected == 'Completed' && 'selected'}`} onClick={() => { setTabSelected('Completed') }}>{width > 800 ? "Completed Orders" : "Completed"}</div>

                            </div>
                            {width < 1236 && width > 800 && renderToggle()}
                        </div>
                        <div className='shipment-list'>
                            {width < 800 && renderToggle()}

                            {width > 1236 ? renderShippingList() : mapSelected ? <></> : renderShippingList()}
                        </div>
                    </div>
                    {(shipmentList.length == 0 || tabSelected == 'Completed') ? (width > 1236 || mapSelected) && <>
                        <div style={{ flex: 0.85, height: 1025, background: 'black', padding: 40, boxSizing: 'content-box', display: 'flex', flexDirection: 'column' }}>
                            <div className='discounts-scroll' style={{ flex: 1, overflow: 'scroll', }}>
                                <div style={{ fontWeight: 700, fontSize: 24 }}>
                                    Discounts from the web
                                </div>
                                <div style={{ fontSize: 14, color: '#8B8B8B', marginTop: 16 }}>We’ve found discounts we think you will love</div>
                                <div style={{ display: 'flex', marginTop: 40, flexDirection: 'column', gap: 40 }}>
                                    {coupons?.map((coupon) => (
                                        <DiscountComponent discountObj={coupon} height={173} fromShippment />
                                    ))}
                                </div>
                            </div>
                            <div style={{ height: 84, display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: '1px solid #26282A', fontSize: 16, fontWeight: 700, cursor: 'pointer' }} onClick={() => { navigate('/discounts') }}>
                                See more discounts <img style={{ marginLeft: 8 }} src={externalIcon} />
                            </div>
                        </div></> : <>
                        {((width > 1236) || mapSelected) && <div onMouseDownCapture={() => { timeout.current.longPressed = false; timeout.current.timer = setTimeout(() => { timeout.current.longPressed = true }, 100) }} onMouseUpCapture={(e) => { clearTimeout(timeout.current.timer); if (!timeout.current.longPressed && (e.target.className !== 'map-button' && e.target.tagName !== 'BUTTON')) { setSelectedElement({}); setHovered({}); setSelected(-1); } }} className='shipment-map-wrapper' style={{ flex: 1, height: 1025 }}>
                            {isLoaded ? (
                                <GoogleMap
                                    clickableIcons={false}
                                    // center={{ lat: lat, lng: lng }}
                                    mapContainerStyle={{ height: 1009 }}
                                    options={{
                                        backgroundColor: 'black',
                                        gestureHandling: 'greedy',
                                        styles: darkStyles,
                                        mapTypeControl: false, controlSize: false,
                                        fullscreenControlOptions: false,
                                        mapTypeControlOptions: false,
                                        panControlOptions: false,
                                        zoomControlOptions: false,
                                        scaleControlOptions: false,
                                        streetViewControl: false,
                                        panControl: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                        keyboardShortcuts: false,
                                        disableDoubleClickZoom: true,
                                        minZoom: 2
                                    }}
                                    zoom={2}
                                    onLoad={onLoad}
                                    // onLoad={(e)=>{e.fitBounds()}}
                                    onUnmount={onUnmount}
                                >
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <>
                                        <MarkerClusterer options={{
                                            styles: [{
                                                height: 48,
                                                width: 48,
                                                textColor: '#fff',
                                                url: clusterMarker,
                                                textSize: 13,
                                                anchorText: [-6, 0],
                                                anchorIcon: [40, 0],
                                                fontFamily: 'Montserrat',

                                            }]
                                        }} onClusteringEnd={(cluster) => { setClusteredComplete(cluster.getClusters()); }}>
                                            {(clusterer) => {
                                                return shipmentList?.map((mapData, index) => {
                                                    if (mapData?.latestShipmentInfo?.latestLatitude && mapData?.latestShipmentInfo?.latestLongitude) {
                                                        return (
                                                            <>
                                                                <Marker visible={false} children={<div style={{ backgroundColor: 'blue', height: 50, width: 50 }}>Hello there</div>} clusterer={clusterer} position={{ lat: parseFloat(mapData?.latestShipmentInfo?.latestLatitude), lng: parseFloat(mapData?.latestShipmentInfo?.latestLongitude) }} clickable={false}>
                                                                    <div style={{ background: 'blue', height: 50, width: 500 }}>Hello there</div>
                                                                </Marker>
                                                                {renderOverlays(clusterer, mapData?.latestShipmentInfo?.latestLatitude, mapData?.latestShipmentInfo?.latestLongitude) && <OverlayView
                                                                    position={{ lat: mapData?.latestShipmentInfo?.latestLatitude, lng: mapData?.latestShipmentInfo?.latestLongitude }}
                                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                                                                    <div style={{ position: 'relative' }}>
                                                                        {selected == index && <div>
                                                                            <div className='shipment-map-marker-selected'>
                                                                                <div className='map-product-image'>
                                                                                    {(mapData?.itemImages?.length > 0 && mapData?.itemImages?.every(item => item === null)) ? <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', fontSize: 42, textTransform: 'capitalize' }}><img src={noShipmentImage} style={{ height: 218 }} /> </div> : <ImageCarousel autoPlay={true} images={mapData?.itemImages} />}
                                                                                </div>
                                                                                <div className='map-shipment-status'>
                                                                                    {mapData.senderName && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='map-status-left'>Sender</div>
                                                                                        <div className='map-status-right'>{mapData?.senderName}</div>
                                                                                    </div>}
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='map-status-left'>Status</div>
                                                                                        <div className='map-status-right'>{tabSelected == 'Completed' ? 'Delivered' : mapData?.latestShipmentInfo?.latestStatus}</div>
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='map-status-left'>Delivery</div>
                                                                                        <div className='map-status-right'>{mapData.latestShipmentInfo?.latestStatus == 'Delivered' ? mapData?.deliveredAt ? moment(mapData?.deliveredAt).format('MM/DD/YYYY') : "Delivered" : mapData?.estimatedDeliverDate ? moment(mapData?.estimatedDeliverDate).format('MM/DD/YYYY') : 'Soon'}</div>
                                                                                    </div>

                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='map-status-left'>Tracking #</div>
                                                                                        <div className='map-status-right'>{mapData?.trackingNumber}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='map-buttons'>
                                                                                    <div className='map-button' onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        setShowModal(true);
                                                                                        setSelectedElement(mapData)

                                                                                    }}>View Details</div>
                                                                                    {!(tabSelected == 'Completed') && <div className='map-button' onClick={() => {
                                                                                        markCompleted(mapData.id)
                                                                                    }}>Mark as Delivered</div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                        <div onMouseEnter={() => { setHovered(index) }} onMouseLeave={() => { setHovered(-1) }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                setLat(mapData.lat);
                                                                                setLng(mapData.lng);
                                                                                if (mapData?.latestShipmentInfo?.latestLatitude) {
                                                                                    map?.panTo({ lat: map?.getZoom() <= 6 ? (parseFloat(mapData?.latestShipmentInfo?.latestLatitude) - 5) : map.getZoom() <= 7 ? parseFloat(mapData?.latestShipmentInfo?.latestLatitude) - 3 : map.getZoom() <= 8 ? parseFloat(mapData?.latestShipmentInfo?.latestLatitude) - 2 : parseFloat(mapData?.latestShipmentInfo?.latestLatitude), lng: parseFloat(mapData?.latestShipmentInfo?.latestLongitude) })
                                                                                }

                                                                                selected >= 0 ? setSelected(-1) : setSelected(index);

                                                                            }} style={{ position: 'absolute', top: (selected == index || hovered == index) ? -45 : -42, left: (selected == index || hovered == index) ? -21 : -18, cursor: 'pointer', zIndex: selected == index && 502 }}>
                                                                            <img src={(selected == index || hovered == index) ? customMarkerSelected : customMarker}></img>
                                                                            <div style={{ height: 24, width: 24, position: 'absolute', backgroundColor: 'black', top: (selected == index || hovered == index) ? 10 : 7, left: (selected == index || hovered == index) ? 11 : 7, borderRadius: 100, }}>
                                                                                {mapData.avatar && <img style={{ height: '100%', width: '100%', borderRadius: 200 }} src={mapData?.avatar} />}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </OverlayView>}

                                                            </>)
                                                    }
                                                })
                                            }
                                            }
                                        </MarkerClusterer>
                                    </>
                                </GoogleMap>
                            ) : <></>}
                        </div>}
                    </>}
                    {(selectedElement || shipmentId) && showModal && <ShippingDetailsModal onBack={() => { setSelectedElement({}); setShowModal(false); setShipmentId(''); navigate('/my-orders') }} shipmentId={shipmentId} shipmentElement={selectedElement} handleDeleteShipment={handleDeleteShipment} markCompleted={markCompleted} />}

                    {addModalOpen && <AddShipmentModal onClose={() => setAddModalOpen(false)} addShipment={addShipment} error={addShipmentError} />}
                </div>
            </div>
            <AppFooter />

        </div>)
    }
    return <></>
}

