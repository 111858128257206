import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

/**
 * @typedef {{className: String, type:("large"|"medium"|"small"), disabled: Boolean, responsive: Object, onClick: *}} Props
 * @param {Props} param0
 */
export function BlueRoundedButton({
	className = "",
	type = "large",
	disabled = false,
	responsive = { breakpoint: 768 },
	children,
	onClick = (event) => { }
}) {
	const { breakpoint } = responsive
	const { width: windowWidth } = useWindowSize()

	function handleClick(event) {
		onClick(event)
	}

	return (
		<button
			className={`${className} blue-rounded-button ${type} ${windowWidth < breakpoint ? 'mobile' : 'desktop'}`}
			disabled={disabled}
			onClick={(event) => handleClick(event)}>
			{children}
		</button>
	)
}