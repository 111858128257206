import React from 'react'

import './styles.scss'

import { ObjectHelper } from '../../../utils'

export function StickyButton(props) {
	const btnRef = React.useRef()

	const { className = '', top, threshold = 0, children } = props

	React.useEffect(() => {
		window.addEventListener('scroll', update)
		window.addEventListener('resize', update)
		return () => {
			window.removeEventListener('scroll', update)
			window.removeEventListener('resize', update)
		}
	}, [])

	React.useEffect(() => { update() }, [top])

	function update() {
		if (!btnRef.current) return

		const parentEl = btnRef.current.parentElement
		if (ObjectHelper.isValid(top)) {
			const parentTop = parentEl.getBoundingClientRect().top
			if (parentTop < top) {
				btnRef.current.style.top = `${Math.abs(parentTop) + threshold}px`
			} else {
				btnRef.current.style.top = `${top}px`
			}
		}
	}

	return (
		<button
			{...props}
			ref={btnRef}
			type="button"
			className={`sticky-button ${className}`}>
			{children}
		</button>
	)
}