import React from 'react'

import './styles.scss'
import iconArrowDown from '../../../assets/images/arrow/down-white-small.svg'
import iconFilterOn from '../../../assets/images/filter/on.svg'

import { Menu } from '../'
import { SelectorMenuEl } from './menu'

import { isMobile, ObjectHelper } from '../../../utils'


export class Selector extends Menu {
	containerRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			isFocus: false
		}
	}

	componentWillUnmount() {
		Menu.closeComponent(this)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { disabled } = this.props
		const { isOpen, isFocus } = this.state
		if (isFocus && !isOpen) this.open()

		if (disabled && (isFocus || isOpen)) {
			this.close()
		}

		// if (prevState.isOpen && !isOpen) Menu.closeComponent(this)
		// if (!prevState.isOpen && isOpen) Menu.closeOtherComponents(this)
	}

	close() {
		this.setState({ isFocus: false })
		super.close()
	}

	label = () => {
		const { defaultLabel, labelPersist = false, options, value } = this.props
		if (labelPersist) return defaultLabel
		if (!ObjectHelper.isValid(value)) return defaultLabel || ''
		const selectedOption = options.find(e => e.value === value)
		return selectedOption ? (selectedOption.label || (defaultLabel || '')) : defaultLabel
	}

	handleClick = (event) => {
		if (event.target.closest('.selector-menu')) return

		event.stopPropagation()
		if (!this.state.isFocus && this.containerRef.current) {
			this.containerRef.current.focus()
			if (isMobile) this.open()
		} else {
			this.containerRef.current.blur()
			this.close()
		}
	}

	handleFocus = (event) => {
		// if (event.currentTarget === event.target && event.target.matches('.selector')) {
		// 	this.setState({ isFocus: true })
		// }
		this.setState({ isFocus: true })
	}

	handleBlur = (event) => {
		this.setState({ isFocus: false })
	}

	render() {
		const {
			className = '',
			disabled = false,
			multiselect = false,
			bulkEnabled = false,
			hasFilterStatus = false,
			isFilterOn,
			minimized = false,
			options = [],
			value,
			responsive = { breakpoint: 768 }
		} = this.props
		const { isOpen, isFocus } = this.state
		return (
			<div
				ref={this.containerRef}
				className={`selector ${className} ${disabled && 'disabled'} ${hasFilterStatus && 'has-option-icon'} ${minimized && 'minimized'}`}
				tabIndex={isFocus ? -1 : 0}
				onClick={this.handleClick}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}>
				<span className="label">{this.label()}</span>
				<img className={`filter-icon ${hasFilterStatus || 'd-none'} ${isFilterOn || 'd-none'}`} src={iconFilterOn} alt="" />
				<img className="arrow" loading="lazy" src={iconArrowDown} alt="" />
				{isOpen &&
					<SelectorMenuEl
						options={options}
						multiselect={multiselect}
						bulkEnabled={bulkEnabled}
						value={value}
						responsive={responsive}
						onChange={this.props.onChange}
						onClose={this.close} />
				}
			</div>
		)
	}
}

export * from './core'