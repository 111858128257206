import React from 'react'
import ReactDOM from 'react-dom'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconNext from 'assets/images/arrow/next-small-green.svg'

import { DOMHelper, randomUUID } from 'utils'
import { zIndexes } from 'constants/z-index'

export class IModal extends React.Component {
	constructor(props) {
		super(props)

		this.id = randomUUID()
	}

	componentDidMount() {
		this.appendEl()

		window.addEventListener('resize', this.handleWindowResize)
		window.addEventListener('scroll', this.updatePosition)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize)
		window.removeEventListener('scroll', this.updatePosition)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.anchorEl !== prevProps?.anchorEl) {
			if (this.props.anchorEl) this.appendEl()
			else this.removeEl()
		}
	}

	removeEl = () => {
		if (this.modal) document.body.removeChild(this.modal)
		this.modal = null
	}

	appendEl = () => {
		const { anchorEl, responsive = { breakpoint: 992 }, style = { zIndex: zIndexes.IModal }, children } = this.props

		this.removeEl()

		this.modal = document.createElement('div')
		this.modal.id = this.id
		this.modal.classList.add('i-modal-parent')
		this.modal.style.zIndex = style.zIndex
		document.body.appendChild(this.modal)
		ReactDOM.render(
			<IModalEl
				anchorEl={anchorEl}
				responsive={responsive}
				style={style}
				onShow={() => this.updatePosition()}>
				{children}
			</IModalEl>,
			document.getElementById(this.id)
		)

		if (anchorEl) anchorEl.classList.add('i-modal-anchor')

		this.updatePosition()
	}

	updatePosition = () => {
		const { anchorEl, responsive = { breakpoint: 992 } } = this.props
		if (window.innerWidth <= responsive.breakpoint || !anchorEl || !this.modal) return

		const anchorPos = DOMHelper.position(anchorEl)
		this.modal.style.top = `${anchorPos.top + anchorPos.height}px`
		this.modal.style.left = `${anchorPos.left + (anchorPos.width - 8) / 2 + 8}px`
	}

	handleWindowResize = () => {
		if (!this.modal) return

		const { breakpoint = 992 } = this.props.responsive ?? {}

		if (window.innerWidth >= breakpoint) {
			this.modal.classList.add('desktop')
			this.modal.classList.remove('mobile')
		} else {
			this.modal.classList.remove('desktop')
			this.modal.classList.add('mobile')
		}

		this.updatePosition()
	}

	render() {
		return (<></>)
	}
}

export function IModalEl({
	anchorEl,
	responsive = {},
	style = { zIndex: zIndexes.IModal },
	onShow = () => { },
	children,
}) {
	const { width: windowWidth } = useWindowSize()
	const { breakpoint = 992 } = responsive
	const { zIndex } = style

	const [isClosed, setClosed] = React.useState(true)
	React.useEffect(() => { if (!isClosed) onShow() }, [isClosed])

	React.useEffect(() => {
		function updateParent() {
			if (!anchorEl) return

			// if (windowWidth >= breakpoint) {
			// 	anchorEl.addEventListener("mouseout", handleParentMouseOut, false)
			// }

			anchorEl.addEventListener("click", (event) => {
				event.stopPropagation()
				setClosed(false)
			})
			if (windowWidth >= breakpoint) {
				anchorEl.addEventListener("mouseenter", handleOpen)
			}
		}

		updateParent()
		window.addEventListener("click", handleWindowClick)
		window.addEventListener('mouseout', handleMouseLeave)
		return () => {
			window.removeEventListener("click", handleWindowClick)
			window.removeEventListener('mouseout', handleMouseLeave)
		}
	}, [windowWidth])

	function handleWindowClick(event) {
		// console.log(event.target)
		if (event.target.matches('.i-modal-content a')) {
			event.stopPropagation()
			return
		}

		if (windowWidth < breakpoint) {
			if (event.target.matches(".i-modal-anchor")) {
				handleOpen(event)
			} else if (event.target.matches(".i-modal")) {
				handleClose(event)
			}
		} else {
			if (event.target.closest(".i-modal")) {
				handleClose(event)
			}
		}
	}

	/**
	 * @param {MouseEvent} event
	 */
	function handleMouseLeave(event) {
		if (!DOMHelper.closest(event.toElement, '.i-modal') && !DOMHelper.closest(event.toElement, '.i-modal-parent') && !DOMHelper.closest(event.toElement, '.i-modal-anchor')) {
			handleClose(event)
		}
	}

	function handleOpen(event) {
		// console.log("open")
		event.stopPropagation()
		setClosed(false)
	}

	function handleClose(event) {
		// console.log("close")
		event.stopPropagation()
		setClosed(true)
	}

	return (
		<div
			className={`i-modal ${windowWidth >= breakpoint ? 'desktop' : 'mobile'} ${isClosed && 'd-none'}`}
			style={style}
			onClick={handleClose}>
			<div className="i-modal-triangle"></div>
			<div className="i-modal-wrapper">
				<div className="i-modal-content">
					{children}
				</div>
			</div>
		</div>
	)
}