import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton,
	TextField
} from 'components'

import { SenderGroup, useSenderGroup } from 'core'
import { isValidText } from 'utils'
import { errorMessages } from 'constants/error'

export function AccountNewGroupAlert({
	onClose = (groupId) => { }
}) {

	const { senderGroups } = useSenderGroup()
	const [groupName, setGroupName] = React.useState('')
	React.useEffect(() => { setError(null) }, [groupName])
	const [error, setError] = React.useState(null)

	async function handleSave() {
		if (senderGroups.some(e => e.name === groupName)) {
			setError(errorMessages.SENDER_GROUP_DUPLICATED_NAME)
			return
		}

		const { error, result } = await SenderGroup.create(groupName)
		if (error) setError(error)
		if (result) onClose(result.id)
	}

	return (
		<Alert className="account-new-group-alert" onCancel={() => onClose(false)}>
			<AlertPage>
				<AlertTitle>New Group</AlertTitle>
				<div className="form">
					<div className="form-item">
						<div>Name</div>
						<div className="input-field">
							<TextField
								type="normal"
								clearable
								value={groupName}
								onChange={(event) => setGroupName(event.target.value)} />
						</div>
					</div>
					{error && <div className="error">{error}</div>}
				</div>
				<AlertButtonContainer>
					<AlertButton className='btn-create-group' disabled={!isValidText(groupName)} onClick={() => handleSave()}>Save</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}