import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import './styles.scss'
import iconCheckboxUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconCheckboxChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconOptions from 'assets/images/icon_options.svg'
import iconInfoBlue from 'assets/images/info-blue.svg'
import iconEmailCount from 'assets/images/email-gray.svg'
import iconCaretDown from 'assets/images/caret-down-8B8B8B.svg'
import iconAddMore from 'assets/images/plus-8B8B8B.svg'
import iconSenderGroup from 'assets/images/group-808080.svg'
import iconDigest from 'assets/images/digest_icon.svg'

import iconFilter from 'assets/images/filter/gray.svg'
import iconFilterActive from 'assets/images/filter/light.svg'
import iconDomainSettings from 'assets/images/disable-gray.svg'
import iconDomainSettingsActive from 'assets/images/disable-light.svg'

import {
	Loader,
	Checkbox,
	Icon,
	BadgeButton,
	DeleteDomainSettingsAlert,
	SelectDropDown, SelectOption,
	FilterModal,
} from 'components'
import { MailModal } from '../mail-modal'
import { DomainSettingsAlert } from '../../account/domains/domain-settings-modal'
import { AddDomainModal } from '../../account/domains/add-new-modal'
import { MobileSenderSettings } from '../mobile-settings'

import {
	useAlert,
	useAuth, User,
	Sender, useSenders,
	useSenderGroup, SenderGroup,
	ConnectedEmail, Filter, DomainSetting, Segment, useDigests, Digest
} from 'core'
import { DomainHelper, DOMHelper, ArrayHelper } from 'utils'
import { routes, redirectTo, parseSearchParams, routeToMe } from 'routes'
import { errorCodes, errorMessages } from 'constants/error'

/**
 * @typedef {{sender: Sender, selected: boolean, highlighted: boolean, onChange: *, onSelect: *}} Props
 * @param {Props} param0
 */
export function SenderListItem({
	tutorial = false,
	sender,
	selected = false,
	onChange = (value) => { },
	onSelect = (value) => { },
	defaultSelectedDigestFilter
}) {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const containerRef = React.useRef()
	const connectedEmailSelectorRef = React.useRef()

	const { setAlert, setError, resetAlert } = useAlert()
	const { currentUser } = useAuth()
	const { myDigests } = useDigests();
	const { isSenderLoading, refreshSenders, sendersReload } = useSenders()
	const { senderGroups } = useSenderGroup()

	const [selectedSenderId, setSelectedSenderId] = React.useState(null)
	const [highlight, setHighlight] = React.useState(null)
	const [changeDestination, setChangeDestination] = React.useState(null)
	const [selectedDigests, setSelectedDigests] = React.useState([]);

	const [isMailOn, setMailOn] = React.useState(false);
	React.useEffect(() => { setMailOn(sender.id === selectedSenderId) }, [selectedSenderId])

	const [highlighted, setHighlighted] = React.useState(false)
	React.useEffect(() => { setHighlighted(sender.id === highlight || sender.id === changeDestination) }, [highlight, changeDestination])

	const [isChangeDestination, setIsChangeDestination] = React.useState(false)
	React.useEffect(() => { setIsChangeDestination(sender.id === changeDestination) }, [changeDestination])

	const [isFilterOn, setFilterOn] = React.useState(false)
	const [isDomainSettingOn, setDomainSettingOn] = React.useState(false)
	const [deleteDomainSetting, setDeleteDomainSetting] = React.useState(null)
	const [mobileSettingsOn, setMobileSettingsOn] = React.useState(false)
	const [filter, setFilter] = React.useState(null)
	const [senderGroup, setSenderGroup] = React.useState(null)

	// Scroll to change destination
	React.useEffect(() => {
		if (highlighted && isChangeDestination) {
			DOMHelper.scrollIntoView(containerRef.current)
		}
		if (isChangeDestination) {
			if (connectedEmailSelectorRef.current) connectedEmailSelectorRef.current.focus()
		}
	}, [highlighted, isChangeDestination])

	const { connectedEmail } = sender

	const [isLoading, setLoading] = React.useState(false)

	React.useEffect(() => {
		setSelectedDigests(sender.digests);
	}, [])

	React.useEffect(() => {
		const isLoading = Boolean(sender?.id && isSenderLoading(sender.id))
		setLoading(isLoading)
	}, [isSenderLoading])

	React.useEffect(() => { onChange(sender) }, [sender])

	React.useEffect(() => {
		loadFilter()
		loadSenderGroup()
	}, [sender])

	const iconStyle = () => {
		if (tutorial) return { backgroundColor: ConnectedEmail.iconColor(0) }
		return connectedEmail ? { backgroundColor: currentUser.connectedEmailIconColor(connectedEmail) } : { backgroundColor: '#E88D67' }
	}

	async function refreshSender() {
		refreshSenders([sender.id])
	}

	async function loadFilter() {
		if (!sender || !sender.hasFilter) {
			setFilter(null)
			return
		}

		const { error, result } = await Filter.getFilter({ id: sender.filterId })
		if (error) {
			setError(error)
			return
		}

		setFilter(result)
	}

	async function loadSenderGroup() {
		if (!sender || !sender.hasGroup) return

		setSenderGroup(senderGroups.find(x => x.id === sender.senderGroupId))
	}

	React.useEffect(() => {
		parseLocation()
	}, [searchParams])
	function parseLocation() {
		const { senderId, highlight, changeDestination } = parseSearchParams(searchParams)

		setSelectedSenderId(senderId)
		setHighlight(highlight)
		setChangeDestination(changeDestination)
	}

	function handleMailOpen() {
		Segment.track(Segment.Event.openingEmailModule, currentUser?.id, { senderId: sender?.id })
		navigate(redirectTo({
			route: routes.Dashboard,
			searches: { senderId: sender.id, highlight, changeDestination },
		}))
	}

	function handleMailClose() {
		navigate(redirectTo({
			route: routes.Dashboard,
			searches: { highlight, changeDestination },
		}))
	}

	function handleClick(event) {
		if (window.innerWidth <= 576) {
			handleMailOpen()
		}
	}

	function handleFilter(event) {
		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}

		if (sender && sender.isEmailBlocked) {
			setDeleteDomainSetting(sender.domainSettings)
			return
		}

		if (!sender?.isVerified) {
			setAlert({
				message: `lockrMail now requires each destination email to be verified prior to any delivery of messages. This is a new requirement, but one that we implement to improve the overall system and reliability.
					<br><br>
					To re-send an activation email to the destination email ${sender?.connectedEmail?.email}, please click the confirmation below and an email with an authentication link will immediately be delivered. The link expires in 24-hours.
					<br><br>
					No messages will be delivered to this destination email until it is verified. We appreciate you complying with this new lockrMail policy.`,
				buttons: [
					{
						title: 'Send email',
						type: 'positive',
						onClick: () => {
							User.sendVerifyEmail(sender?.connectedEmail?.email)
							setAlert(null)
						}
					},
				]
			})
			return
		}

		handleOpenFilter(true)
	}

	async function handleBlock(event, block) {
		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}

		if (sender && sender.isEmailBlocked) {
			setDeleteDomainSetting(sender.domainSettings)
			return
		}
		if (sender.hasFilter) {
			setAlert({
				message: errorMessages.FILTER_OVERRIDE_MESSAGE,
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: async () => {
							setLoading(true)
							const { error } = await sender.block(block)
							setLoading(false)

							if (error) {
								setError(error)
								return
							}

							refreshSender()
						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							resetAlert()

						}
					},]
			})
			return;
		}

		setLoading(true)
		const { error } = await sender.block(block)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		refreshSender()
	}

	async function handleSetConnectedEmail(connectedEmailId) {
		setLoading(true)
		const { error } = await sender.setConnectedEmail(connectedEmailId)
		// setLoading(false)

		if (error) {
			setError(error)
			return
		}

		refreshSender()
	}

	function handleAddNewConnectedEmail() {
		navigate(redirectTo({
			route: routes.Account,
			searches: { requireAdd: true }
		}))
	}

	function handleOpenFilter(open = true) {
		if (sender.hasFilter) {
			navigate(routeToMe({
				searches: { filterId: open && sender.filterId }
			}))
			return
		}

		setFilterOn(open)
	}

	async function redirectToDomainSettings() {
		if (!sender?.domain) return

		navigate(redirectTo({
			route: routes.Account,
			category: routes.Category.Account.Domains,
			searches: { domain: sender?.domain }
		}))
	}

	async function handleDomainSettings(event) {
		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}

		if (!sender?.domain) return

		if (sender?.domainSettings) {
			redirectToDomainSettings()
			return
		}

		setLoading(true)
		const { error, errorCode } = await DomainSetting.checkAvailability([sender.domain])
		setLoading(false)

		if (errorCode === errorCodes.DS_OVERRIDE_ALLOW_FILTER) {
			setAlert({
				message: errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER,
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: () => {
							redirectToDomainSettings()
							resetAlert()
						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							resetAlert()
						}
					},]
			})
			return
		} else if (error) {
			setError(error)
			return
		}

		redirectToDomainSettings()
	}

	// Sender Groups
	function calcSenderGroups() {
		let results = ArrayHelper.sort(senderGroups.slice(0, 2), 'senderCount')
		if (!results.some(x => x.id === sender.senderGroupId)) {
			const senderGroup = senderGroups.find(x => x.id === sender.senderGroupId)
			if (senderGroup) results = [...results, senderGroup]
		}
		return results
	}

	async function handleSenderGroupSet(senderGroupId) {
		const { error } = await sender?.setSenderGroup(senderGroupId)

		if (error) {
			setError(error)
			return
		}

		sendersReload()
	}

	function handleSenderGroupViewAll() {
		navigate(redirectTo({
			route: routes.Account,
			category: routes.Category.Account.Groups,
		}))
	}

	function handleDigestViewAll() {
		navigate(redirectTo({
			route: routes.Digest,
		}))
	}
	
	function handleDigestAddMore() {
		navigate(redirectTo({
			route: `${routes.Digest}/${routes.Category.Digest['My Digests']}`,
		}), {state: { isAdd: true }})
	}
	
	async function handleSenderGroupAddNew() {
		setLoading(true)
		const { error, result } = await SenderGroup.create(null, [sender])
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		navigate(redirectTo({
			route: routes.Groups,
			searches: { senderGroupId: result.id }
		}))
	}

	function handleMore(event) {
		event.stopPropagation()

		setMobileSettingsOn(true)
	}

	function renderAllowBlockDescription() {
		if (sender.isEmailBlocked) return 'Remove domain setting to select'
		// if (sender.hasFilter) return 'Remove filter to select'
		return null
	}

	function renderFilter() {
		if (!filter) return ""

		const results = [filter.timeFrame]
		if (filter.hasKeywords) results.push(filter.formattedKeywords())
		if (filter.filterByAttachment) results.push(filter.formattedAttachment)
		if (filter.hasLibraries) results.push(
			filter.filterLibraries.map((filterLibrary, index) =>
				filterLibrary.libraries.map((library, index) => {
					const formattedKeywords = library.formattedKeywords
					return formattedKeywords ? `${library.name} ${formattedKeywords}` : library.name
				}).join(', ')
			))

		return results.map((e, index) =>
			<div className="description-item" key={index}>
				<span>· </span>
				<span dangerouslySetInnerHTML={{ __html: e }}></span>
			</div>
		)
	}

	function getDigestName() {
		if (selectedDigests?.length) {
			let result = '';
			selectedDigests.forEach((digestItem, index) => {
				result += `${digestItem.name}${index !== selectedDigests.length - 1 ? ', ' : ''}`;
			})
			return result;
		}
		return 'Digest'
	}

	function checkIfDigestSelected(digestId) {
		let isDigestSelected = false;
		if (selectedDigests?.length) {
			selectedDigests.forEach(digestItem => {
				if (digestItem.id === digestId) {
					isDigestSelected = true;
				}
			})
		}
		return isDigestSelected;
	}

	async function onDigestOptionClick(digestId, isSelected) {
		const senderId = sender.id;
		if (!isSelected) {
			Digest.addSenders(digestId, [senderId]);
			const digestItemIndex = myDigests.findIndex(item => {
				if (item.id === digestId) {
					return 1
				}
				return 0;
			})
			setSelectedDigests([...selectedDigests, myDigests[digestItemIndex]]);
			return;
		}

		const { error, results } = await Digest.getSenders(digestId);
		if (error) {
			return;
		}
		let digestSenderId = undefined;
		results.forEach(senderItem => {
			if (senderItem.senderId === senderId) {
				digestSenderId = senderItem.id
			}
		})
		Digest.removeSenders([digestSenderId], digestId);
		const localDigests = [...selectedDigests];
		const localDigestItemIndex = selectedDigests.findIndex(item => {
			if (item.id == digestId) {
				return 1;
			}
			return 0;
		});
		localDigests.splice(localDigestItemIndex, 1);
		setSelectedDigests(localDigests);
	}

	return (
		<>
			<div
				ref={containerRef}
				id={sender.id}
				className={`dashboard-sender-list-item ${highlighted ? 'highlight' : ''} ${tutorial && 'tutorial'}`}
				onClick={(event) => handleClick(event)}>
				{isLoading ? <Loader /> :
					<>
						<Checkbox
							id={sender.id}
							iconChecked={iconCheckboxChecked}
							iconUnchecked={iconCheckboxUnchecked}
							checked={selected}
							onChange={onSelect} />
						<Icon className="avatar" hasWhite={sender.hasWhite} src={sender.avatar} placeholder={sender.domainGroupName ? sender.domainGroupName : sender.name} />
						<div className="info">
							<div className="name">{sender.domainGroupName ? sender.domainGroupName : sender.name}</div>
							<div className="email">{sender.email}</div>
						</div>
						<div className="allow-block">
							<button
								className={`${sender.isAllowed ? 'active' : 'btn-allow'} ${(sender.isEmailBlocked) && 'disabled'}`}
								data-description={renderAllowBlockDescription()}
								onClick={(event) => handleBlock(event, false)}>
								<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.4043 3.82457L5.09614 12.8361L1.2964 9.54533" stroke="#888888" strokeLinecap="round" />
									<path d="M11.4043 3.82457L5.09614 12.8361L1.2964 9.54533" stroke="#888888" strokeLinecap="round" />
								</svg>
								<span>{sender.isAllowed ? 'Allowed' : 'Allow'}</span>
							</button>
							<button
								className={`${sender.isBlocked ? 'active' : 'btn-block'} ${(sender.isEmailBlocked) && 'disabled'}`}
								data-description={renderAllowBlockDescription()}
								onClick={(event) => handleBlock(event, true)}>
								<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<line x1="0.5" y1="-0.5" x2="13.642" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1.00024)" stroke="#888888" strokeLinecap="round" />
									<line x1="0.5" y1="-0.5" x2="13.642" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1.00024)" stroke="#888888" strokeLinecap="round" />
									<line x1="0.5" y1="-0.5" x2="13.642" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 10.9999)" stroke="#888888" strokeLinecap="round" />
									<line x1="0.5" y1="-0.5" x2="13.642" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 10.9999)" stroke="#888888" strokeLinecap="round" />
								</svg>
								<span>{sender.isBlocked ? 'Blocked' : 'Block'}</span>
							</button>
						</div>
						<div className="badge-container">
							<div
								ref={connectedEmailSelectorRef}
								className={`connected-email-selector ${!connectedEmail && 'invalid'}`}
								data-error="Sender was previously assigned a destination email that was removed from your account. Please redirect the Sender to a verified email address to resume delivery.">
								{(connectedEmail || tutorial) ?
									<Icon src={connectedEmail?.avatar} placeholder={tutorial ? 'E' : connectedEmail?.email} style={iconStyle()} />
									:
									<img src={iconInfoBlue} alt="" style={iconStyle()} />
								}
								<img src={iconCaretDown} alt="" />
								<SelectDropDown>
									{currentUser?.connectedEmails?.filter(x => x.isVerified)?.map((e, index) => {
										const selected = sender?.connectedEmailId === e.id
										return (
											<SelectOption
												className={`${selected ? '' : 'change-destination'}`}
												key={index}
												selected={selected}
												onClick={() => handleSetConnectedEmail(e.id)}>
												{e.email}
											</SelectOption>
										)
									})}
									<SelectOption className="add-more" selectable={false} onClick={() => handleAddNewConnectedEmail()}>
										<img src={iconAddMore} alt="" loading="lazy" />
										<span>Add new</span>
									</SelectOption>
								</SelectDropDown>
							</div>
							<BadgeButton
								className="filter-icon"
								icon={iconFilter}
								activeIcon={iconFilterActive}
								active={sender.hasFilter && !sender.isEmailBlocked}
								description={!sender.hasFilter && 'Filter'}
								onClick={handleFilter}>
								{filter &&
									<div className="description-modal">
										<div>Filter is ON</div>
										<div>{renderFilter()}</div>
									</div>
								}
							</BadgeButton>
							<BadgeButton
								className="domain-settings"
								icon={iconDomainSettings}
								activeIcon={iconDomainSettingsActive}
								active={sender.hasDomainBlocked}
								description={sender.domainSettings ? sender.domainSettings.status : 'Domains'}
								onClick={handleDomainSettings}
							/>
							<BadgeButton
								className="sender-group"
								icon={iconSenderGroup}
								active={sender.hasGroup}
								description={senderGroup ? senderGroup.name : 'Groups'}>
								<SelectDropDown
									value={sender.senderGroupId}
									onChange={value => handleSenderGroupSet(value)}>
									{calcSenderGroups().map((senderGroup, index) =>
										<SelectOption
											key={index}
											value={senderGroup.id}>
											{senderGroup.name}
										</SelectOption>
									)}
									<SelectOption className="view-all" onClick={() => handleSenderGroupViewAll()}>View all</SelectOption>
									<SelectOption className="add-more" selectable={false} onClick={() => handleSenderGroupAddNew()}>
										<img src={iconAddMore} alt="" loading="lazy" />
										<span>Add new</span>
									</SelectOption>
								</SelectDropDown>
							</BadgeButton>
							<BadgeButton
								className="sender-group"
								icon={iconDigest}
								isSelectorParent={true}
								active={Boolean(selectedDigests?.length) || !!defaultSelectedDigestFilter}
								description={getDigestName()}>
								<SelectDropDown>
									{myDigests?.map?.((digestItem, index) => {
										if (index < 3) {
											const isDigestSelected = checkIfDigestSelected(digestItem.id);
											return (
												<SelectOption
													selected={isDigestSelected}
													key={index}
													onClick={() => onDigestOptionClick(digestItem.id, isDigestSelected)}
													value={digestItem.id}
												>
													{digestItem.name}
												</SelectOption>
											)
										}
										return <></>
									})}
									<SelectOption className="view-all" onClick={handleDigestViewAll}>View All</SelectOption>
									<SelectOption className="add-more" selectable={false} onClick={handleDigestAddMore}>
										<img src={iconAddMore} alt="" loading="lazy" />
										<span>Add new</span>
									</SelectOption>
								</SelectDropDown>
							</BadgeButton>
							<button className="email-count" onClick={() => handleMailOpen()}>
								<img src={iconEmailCount} alt="" />
								<span>{sender.mailCount}</span>
							</button>
						</div>
						<div className="mobile-dots" onClick={(event) => handleMore(event)}>
							<img src={iconOptions} alt="" loading="lazy" />
						</div>
					</>
				}
			</div>
			{isMailOn &&
				<MailModal
					className={`${(isFilterOn || isDomainSettingOn) && "d-none"}`}
					sender={sender}
					onEditFilter={() => handleOpenFilter()}
					onAddDomainSetting={() => setDomainSettingOn(true)}
					onClose={(updated) => {
						if (updated) refreshSender()
						handleMailClose()
					}} />
			}
			{isFilterOn &&
				<FilterModal
					category={Filter.Category.Filter}
					initialSenders={[sender]}
					onClose={(updated) => {
						handleOpenFilter(false)
					}} />
			}
			{(isDomainSettingOn && sender.hasDomainSettings) &&
				<DomainSettingsAlert
					value={sender.domainSettings}
					onClose={(value) => {
						setDomainSettingOn(false)
						if (value) refreshSender()
					}} />
			}
			{(isDomainSettingOn && !sender.hasDomainSettings) &&
				<AddDomainModal
					domain={DomainHelper.parseDomain(sender.email)}
					onClose={(updated) => {
						setDomainSettingOn(false)
						if (updated) refreshSender()
					}} />
			}
			{deleteDomainSetting &&
				<DeleteDomainSettingsAlert domainSetting={deleteDomainSetting} onClose={() => setDeleteDomainSetting(null)} />
			}
			{mobileSettingsOn &&
				<MobileSenderSettings
					senders={[sender]}
					onRefresh={() => refreshSender()}
					onClose={(updated) => {
						setMobileSettingsOn(false)
						if (updated) refreshSender()
					}} />
			}
		</>
	)
}