import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import lockrScanArrowDown from 'assets/images/arrow/lockrScan-admin-arrow-down.svg'
import nextGrey from 'assets/images/arrow/lockrScan-admin-next.svg'

import {
    Icon, Checkbox
} from 'components'
import { AdminUserInfoAlert } from '../user-info-alert'

import { DateHelper } from 'utils'
import moment from 'moment-timezone'
import { LockrScanInfoAlert } from '../lockrScan-user-info-alert'

export function LockrScanUserListItem({
    user = {},
    isHeader = false,
    selected = false,
    onSelect = (value) => { },
    onRefresh = () => { }
}) {
    // const { avatar, name, email, createdAt, tags = [], verifiedCount } = user
    const { firstName, lastName, email, lockrEmail, createdAt, unSubscribedCount, isArchivedCount } = user

    const [isOpenInfo, setOpenInfo] = React.useState(false)

    function handleClick() {
        if (isHeader) return

        setOpenInfo(true)
        // console.log('hello')
    }

    function renderCreatedAt(date) {
        if (!date) return ''

        return 'Create ' + DateHelper.format(date, 'MM/DD/YYYY')
    }

    return (
        <>
            <div className={`user-list-item ${isHeader && 'header'}`} onClick={() => handleClick()}>
                {isHeader ?
                    <>
                        <div className="name">Name <img src={lockrScanArrowDown} /></div>
                        <div className="lockrScan">lockrScan <img src={lockrScanArrowDown} /></div>
                        <div className="lockrMail">lockrMail <img src={lockrScanArrowDown} /></div>
                        <div className="created">Created <img src={lockrScanArrowDown} /></div>
                        <div className="unsubscribed">Unsubscribed <img src={lockrScanArrowDown} /></div>
                        <div className="lockrArchived">lockrArchived <img src={lockrScanArrowDown} /></div>
                    </>
                    :
                    <>
                        {/* <Checkbox
                            iconUnchecked={iconUnchecked}
                            iconChecked={iconChecked}
                            checked={selected}
                            onChange={(value) => onSelect(value)} /> */}
                        <Icon className="avatar" placeholder={firstName} />
                        <div className="name">{firstName + ' ' + lastName}</div>
                        <div className="lockrScan">{email}</div>
                        <div className="lockrMail">{lockrEmail}</div>
                        <div className="created">{moment(createdAt).format('MM/DD/YYYY')}</div>
                        <div className="unsubscribed">{unSubscribedCount}</div>
                        <div className="lockrArchived">{isArchivedCount}</div>
                        <img src={nextGrey} ></img>
                    </>
                }
            </div>
            {isOpenInfo &&
                <LockrScanInfoAlert
                    user={user}
                    onClose={(updated) => {
                        setOpenInfo(false)
                        if (updated) onRefresh()
                    }} />
            }
        </>
    )
}