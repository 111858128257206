import React from 'react'

import './styles.scss'
import iconGear from '../../../../assets/images/gear/gray.svg'
import iconGearHover from '../../../../assets/images/gear/green.svg'
import iconClose from '../../../../assets/images/close-thin-C4.svg'

import { Loader } from '../../../../components'
import { AccountAddSenderField } from '../add-sender-field'
import { SenderEl } from './sender-item'
import { AccountGroupSettingsAlert } from '../group-settings-alert'

import { SenderGroup, Sender } from '../../../../core'
import { ArrayHelper, ObjectHelper } from '../../../../utils'
import { DIGEST_FLOWS, DigestFlowSelectorModal } from 'scenes/digest/new-digest-flow-selector-modal'
import { DigestModal } from 'scenes/digest/digest-modal'

export function GroupDetails({
	groupId,
	onClose = () => { },
}) {
	const [isLoading, setLoading] = React.useState(false)
	const [group, setGroup] = React.useState(null)
	const [showNewDigestModal, setShowNewDigestModal] = React.useState(false);
	const [isSettingsOpen, setSettingsOpen] = React.useState(false)
	const [showDigestModal, setShowDigestModal] = React.useState(false);
	const [selectedDigestMode, setSelectedDigestMode] = React.useState(DIGEST_FLOWS.SCRATCH);

	React.useEffect(() => { loadGroupDetails() }, [groupId])

	async function loadGroupDetails() {
		if (!groupId) return

		setLoading(true)
		const { error, result } = await SenderGroup.getDetail(groupId)
		setLoading(false)

		if (error) { }
		setGroup(result)
	}

	async function handleAddSender(sender) {
		if (!sender) return
		const { error } = await SenderGroup.addSender(groupId, [sender.id])
		if (error) { }
		else {
			// group.senders = [...group.senders, sender]
			// setGroup(ObjectHelper.copy(group))
			loadGroupDetails()
		}
	}

	async function handleRemoveSender(senderId) {
		const { error } = await Sender.removeFromGroup(senderId)
		if (error) { }
		else {
			group.senders = group.senders.filter(e => e.id !== senderId)
			setGroup(ObjectHelper.copy(group))
		}
	}

	return (
		<>
			<div className="group-details-container">
				<div className="wrapper">
					<div className="title-container">
						<div>
							<div className="group-name">{group && group.name}</div>
							<div className="domain-count">{group && group.formattedSenderCount}</div>
						</div>
						<button onClick={() => {
							setShowNewDigestModal(true);
						}} className='create-digest-button'>
							<span>Create Digest</span>
						</button>
						<button className="group-settings" onClick={() => setSettingsOpen(true)}>
							<img src={iconGear} alt="" />
							<img src={iconGearHover} alt="" />
							<span>Settings</span>
						</button>
						<button onClick={() => {
							setShowNewDigestModal(true);
						}} className='create-digest-mobile-button'>
							<span>Create Digest</span>
						</button>
					</div>
					<AccountAddSenderField
						onSelectSender={(sender) => handleAddSender(sender)} />
					<div className="group-list">
						{(isLoading || !group) ? <Loader /> :
							<>
								{ArrayHelper.isValid(group.senders) ?
									group.senders.map((sender) =>
										<SenderEl
											key={sender.id}
											value={sender}
											onRemove={() => handleRemoveSender(sender.id)} />
									)
									:
									<SenderEl />
								}
							</>
						}

					</div>
					<button className="close" onClick={() => onClose()}>
						<img src={iconClose} alt="" />
					</button>
				</div>
			</div>
			{isSettingsOpen &&
				<AccountGroupSettingsAlert
					group={group}
					onClose={(updated) => {
						setSettingsOpen(false)
						if (updated) {
							loadGroupDetails()
						}
					}} />
			}
			{showNewDigestModal && <DigestFlowSelectorModal
				isFromGroup
				onClose={() => { setShowNewDigestModal(false) }}
				onFlowSelected={(mode) => {
					setSelectedDigestMode(mode)
					setShowNewDigestModal(false);
					setShowDigestModal(true);
				}}
			/>}
			{showDigestModal && <DigestModal 
			defaultGroupId={groupId}
			senderList = {group.senders}			
			isFromGroup={selectedDigestMode === DIGEST_FLOWS.GROUP} isNew onClose={() => {
				setShowDigestModal(false);
			}} />}
		</>
	)
}