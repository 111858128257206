import { Api } from 'core/api'
import { errorMessages } from 'constants/error'
import moment from 'moment-timezone'

const Analytics = require('analytics-node')
const analytics = new Analytics(process.env.REACT_APP_SEGMENT_LOCKRSCAN_WRITE_KEY)

export class LockrScanSegment {
    static Type = Object.freeze({
        Discover: 'discover',
        HelpCenter: 'help-center',
    })

    static Event = Object.freeze({
        unsubscribeSender: 'User unsubscribed from sender',
        lockrArchiveEnabled: 'Enabled lockrArchive',
        lockrArchiveDisabled: 'Disabled lockrArchive'

    })

    static identify(lockrScanUser, isDeleted) {
        analytics.identify({
            userId: lockrScanUser?.id,
            traits: {
                "lockrScanUserId": lockrScanUser?.id,
                "lastName": lockrScanUser?.lastName,
                'email': lockrScanUser?.email,
                "registraionDate": moment(lockrScanUser?.createdAt).format("MM/DD/YYYY"),
                "deletedAt": lockrScanUser?.deletedAt && moment(lockrScanUser?.deletedAt).format("MM/DD/YYYY"),
                "frequency": lockrScanUser?.frequency,
                allowEmailNotification: lockrScanUser?.allowEmailNotification,
            }
        })
    }

    static track(event, lockrScanUserId, params = {}, currentUser) {
        if (lockrScanUserId) {
            analytics.track({
                event,
                userId: lockrScanUserId,
                properties: params,

            })
        }
    }
}