import React from 'react'

import './styles.scss'
import iconCheckmark from 'assets/images/checkbox1.svg'
import iconLoading from 'assets/images/loading.svg'

import {
	TextField, BlueButton, BlueRoundedButton
} from 'components'

import { isEmail } from 'utils'

export function PersonalEmailEl({
	className = "",
	error,
	value,
	isVerifying = false,
	onChange = (value) => { },
	onNext = () => { }
}) {
	return (
		<>
			<div className={`register-personal-email ${className}`}>
				<div className="form">
					<TextField
						id="personalEmail"
						type="large"
						placeholder="Personal or work email"
						autoFocus
						value={value}
						error={Boolean(error)}
						onChange={event => onChange(event.target.value.trim())}
						onNext={() => onNext()}
					/>
					<img className={`checkmark ${isEmail(value) || 'd-none'}`} src={iconCheckmark} alt="" />
				</div>
				{error && <div className="error">Error: {error}</div>}
			</div>
			<div className="form-button-container">
				{!isVerifying && (
					<BlueButton
						type="large"
						className="btn-next"
						disabled={!isEmail(value)}
						onClick={() => onNext()}>
						Next
					</BlueButton>
				)}
				{isVerifying && (
					<BlueRoundedButton
						disabled={true}
						type="large"
						className="btn-loading"
						// disabled={!isEmail(value)}
						onClick={() => onNext()}>
						<img src={iconLoading} alt="" />
						Verifying...
					</BlueRoundedButton>
				)}
			</div>

		</>
	)
}