import React from 'react'

import './styles.scss'
import BeatLoader from 'react-spinners/BeatLoader'

export class Loader extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { className = '' } = this.props
		return (
			<div className={`loader ${className}`}>
				<BeatLoader
					loading={true}
					color={'#61CEF7'}
				/>
			</div>
		)
	}
}