import React from "react";
import { isValidText } from "utils";
import { AlertButton, AlertButtonContainer, AlertPage, AlertTitle, Alert } from "./core";
import { TextField } from "components/text-field";
import { useDomainGroupListContext } from "core/providers/admin/domain-group-list";

export function NewGroup({ onClose = () => { } }) {
    const [groupName, setGroupName] = React.useState('');
    const { isLoading, domainGroupList, getDomainGroupList, addNewDomainGroup } = useDomainGroupListContext();

    const onDomainGroupSave = async () => {
        console.log({ groupName })
        await addNewDomainGroup({ groupName })
        onClose();
    }

    return (
        <Alert className="account-new-group-alert" onCancel={() => onClose(false)}>
            <AlertPage>
                <AlertTitle>New Group</AlertTitle>
                <div className="form">
                    <div className="form-item">
                        <div>Name</div>
                        <div className="input-field">
                            <TextField
                                type="normal"

                                value={groupName}
                                onChange={(event) => setGroupName(event.target.value)} />
                        </div>
                    </div>
                    {/* {error && <div className="error">{error}</div>} */}
                </div>
                <AlertButtonContainer>
                    <AlertButton className='btn-create-group' onClick={() => { onDomainGroupSave() }} disabled={!isValidText(groupName)} >Save</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}