import React from 'react'

import {
	SpecialLinkModal,
	ConfirmAlert
} from '../../../../components'

export function SpecialLinkListItem({
	value = {},
	isHeader,
	selected: initialSelected = false,
	onUpdate = (updated) => { }
}) {
	const { permalink, limit, userCount } = value
	// const [isSelected, setSelected] = React.useState(initialSelected)
	const [isSelected, setSelected] = React.useState(initialSelected)
	const [isDelete, setDelete] = React.useState(false)

	async function deleteSpecialLink() {
		const { error } = await value.delete()
		if (error) { }
		else onUpdate(true)
	}

	function handleClick(event) {
		setSelected(true)
	}

	function handleDelete(event) {
		event.stopPropagation()
		setDelete(true)
	}

	return (
		<>
			{isHeader ?
				<div className="special-link-list-item header">
					<div>Link</div>
					<div>Limit</div>
					<div>Registration Count</div>
					<div></div>
				</div>
				:
				<div className={`special-link-list-item ${isHeader && "header"}`} onClick={handleClick}>
					<div>{permalink}</div>
					<div>{limit}</div>
					<div>{userCount}</div>
					<div><button className="delete" onClick={handleDelete}><img alt="" /></button></div>
				</div>
			}
			{isSelected &&
				<SpecialLinkModal
					specialLink={value}
					onClose={(updated) => {
						setSelected(false)
						onUpdate(updated)
					}}
				/>
			}
			{isDelete &&
				<ConfirmAlert
					message="Are you sure you want to delete this link?"
					onOk={() => deleteSpecialLink()}
					onClose={() => setDelete(false)}
				/>
			}
		</>
	)
}