import React from 'react'
import { useAsync } from 'react-use'

import { useAlert } from 'core/providers/alert'
import {
	DeliveryHour,
	Sender,
} from 'core/models'

import { ArrayHelper, ObjectHelper } from 'utils'
import { errorMessages } from 'constants/error'

const DeliveryHoursContext = React.createContext({})

export function DeliveryHoursProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	/** @type {[DeliveryHour, Function]} */
	const [deliveryHour, setDeliveryHour] = React.useState(null)

	const [isOngoing, setOngoing] = React.useState(false)
	const [from, setFrom] = React.useState(DeliveryHour.defaultFrom)
	const [to, setTo] = React.useState(DeliveryHour.defaultTo)
	const [deliveryTimes, setDeliveryTimes] = React.useState({})
	const [dateRangeError, setDateRangeError] = React.useState(null)
	React.useEffect(() => { setDateRangeError(null) }, [isOngoing, from, to])

	/** @type {[Array<Sender>, Function]} */
	const [excludedSenders, setExcludedSenders] = React.useState([])

	React.useEffect(() => {
		if (deliveryHour) {
			setOngoing(deliveryHour.isOngoing)
			setFrom(deliveryHour.from ?? DeliveryHour.defaultFrom)
			setTo(deliveryHour.to ?? DeliveryHour.defaultTo)
			setDeliveryTimes(ObjectHelper.copy(deliveryHour.deliveryTimes ?? {}))
			setExcludedSenders(deliveryHour.excludedSenders)
		}
	}, [deliveryHour])

	useAsync(async () => { await load() }, [])
	async function load() {
		setLoading(true)
		const { error, result } = await DeliveryHour.get()
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setDeliveryHour(result)
	}

	function checkChanged() {
		if (!deliveryHour) return false
		const result = deliveryHour.isOngoing !== isOngoing
			|| (!isOngoing && deliveryHour.from !== from)
			|| (!isOngoing && deliveryHour.to !== to)
			|| !ObjectHelper.equals(deliveryHour.deliveryTimes, deliveryTimes)
			|| !ArrayHelper.equals(excludedSenders, deliveryHour.excludedSenders)
		return result
	}

	function checkValidation() {
		const now = new Date()

		if (!isOngoing) {
			if (!from || !to) {
				setDateRangeError(errorMessages.DELIVERY_HOUR_DATE_RANGE_REQUIRED_FUTURE)
				return false
			} else if (from < now) {
				setDateRangeError(errorMessages.DELIVERY_HOUR_DATE_RANGE_REQUIRED_FUTURE)
				return false
			} else if (to < from) {
				setDateRangeError(errorMessages.DELIVERY_HOUR_DATE_RANGE_REQUIRED_FUTURE)
				return false
			}
		}

		const keys = Object.keys(deliveryTimes)
		const hasOn = keys.some(key => deliveryTimes[key]?.isOn)
		if (!hasOn) {
			setError("To enable Delivery Hours please select at least a day and time window when messages are permitted to be delivered.")
			return false
		}

		return true
	}

	async function deliveryHoursSave() {
		if (!checkValidation()) return false

		let params = {
			isOngoing,
			deliveryTimes,
			excludedSenders: excludedSenders.filter(x => typeof x !== "string").map(x => x.id),
			emails: excludedSenders.filter(x => typeof x === "string"),
		}
		if (!isOngoing) params = { ...params, from, to }

		const { error } = DeliveryHour.save(params)

		if (error) {
			setError(error)
			return false
		}

		return true
	}

	const memoedValue = React.useMemo(() => ({
		isLoading, deliveryHour,

		isOngoing, setOngoing,
		from, setFrom,
		to, setTo,
		dateRangeError,
		deliveryTimes, setDeliveryTimes,
		excludedSenders, setExcludedSenders,
		checkChanged,
		deliveryHoursSave,
	}), [isLoading, deliveryHour, isOngoing, from, to, deliveryTimes, excludedSenders, dateRangeError])

	return (
		<DeliveryHoursContext.Provider value={memoedValue}>
			{children}
		</DeliveryHoursContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, deliveryHour: DeliveryHour, isOngoing: boolean, setOngoing: *, from: Date, setFrom: *, to: Date, setTo: *, dateRangeError: string, deliveryTimes: *, setDeliveryTimes: *, excludedSenders: Array<Sender>, setExcludedSenders: *, checkChanged: *, deliveryHoursSave: *}} UseDeliveryHours
 * @returns {UseDeliveryHours}
 */
export function useDeliveryHours() {
	return React.useContext(DeliveryHoursContext)
}