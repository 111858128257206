import React from 'react'

import './styles.scss'

import { FormInvite } from './invite'
import { FormTrackInvites } from './track-invites'

import { useAuth, useReferral } from 'core'
import { ObjectHelper } from 'utils'

const TabIndexes = Object.freeze({
	Invite: 0,
	Track: 1,
})

const TabTitles = ["Invite", "Track invites"]

export function Form({
	className = "",
}) {
	const { currentUser } = useAuth()
	const { creditCount } = useReferral()
	const [tabIndex, setTabIndex] = React.useState(TabIndexes.Invite)

	function handleTabIndex(value) {
		setTabIndex(value)
	}

	function renderSubtitle() {
		if (!ObjectHelper.isValid(creditCount)) return (<></>)

		if (creditCount <= 0) return <div>You do not currently have any referrals. Earn a referral for each additional Email Inbox you connect & verify, as well as for every 5 Senders to your lockrMail address.</div>
		if (creditCount === 1) return <div>Great work! You have 1 invite to send to your favorite friend!</div>
		return <div>Great work! You have {creditCount} invites to send to your favorite friends!</div>
	}

	return (
		<div className={`${className} referrals-form`}>
			<div>My Invites</div>
			<div>lockrMail appreciates your support! Share the love of productivity and a sane inbox with your family, friends, and colleagues!</div>
			<div className="tab-container">
				{TabTitles.map((title, index) =>
					<div
						key={index}
						className={`tab ${tabIndex === index && "selected"}`}
						onClick={() => handleTabIndex(index)}>
						{title}
					</div>
				)}
			</div>
			<FormInvite
				className={tabIndex !== TabIndexes.Invite && "d-none"}
			/>
			<FormTrackInvites
				className={tabIndex !== TabIndexes.Track && "d-none"}
			/>
		</div>
	)
}