import React from 'react'

export class TabItem extends React.Component {
	constructor(props) {
		super(props)
	}

	handleClick = (event) => {
		const { value, onClick = () => { } } = this.props
		onClick(value)
	}

	render() {
		const {
			className = "",
			label, children,
			selected = false,
		} = this.props

		return (
			<button
				className={`${className} tab-item ${selected && 'selected'}`}
				onClick={(event) => this.handleClick(event)}>
				{label || children}
			</button>
		)
	}
}