import React, { forwardRef } from 'react'

export const SectionContainer = forwardRef(function SectionContainer({
	className = "",
	title = "",
	children,
}, ref) {
	return (
		<div ref={ref} className={`notification-section-container ${className}`}>
			<div className="title">{title}</div>
			<div className="section-wrapper">
				{children}
			</div>
		</div>
	)
})