import React from 'react'

import './styles.scss'

export function IconButton(props) {
	const { className = "", normal, hover } = props

	return (
		<button {...props} className={`${className} icon-button ${normal && hover && 'two-status'}`}>
			<img className="normal" src={normal} alt="" />
			<img className="hover" src={hover} alt="" />
		</button>
	)
}