import React from 'react'

export function NotificationItemEl({
	type = "default",
	icon,
	isClose=false,
	onClose = () => { },
	children,
}) {
	/** @param {MouseEvent} event */
	function handleClose(event) {
		event?.stopPropagation()
		onClose()
	}

	return (
		<div className={`notification-item ${type}`}>
			<img src={icon} alt="" />
			<span>{children}</span>
			{ isClose && (
				<button className='btn-close' onClick={handleClose}>
					<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="-0.5" x2="28.284" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#C4C4C4" />
						<line y1="-0.5" x2="28.284" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#C4C4C4" />
						<line y1="-0.5" x2="28.284" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 21)" stroke="#C4C4C4" />
						<line y1="-0.5" x2="28.284" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 21)" stroke="#C4C4C4" />
					</svg>
				</button>
			)}

		</div>
	)
}