import React from 'react'
import { Link } from 'react-router-dom'

import { IModal, ProgressBar } from 'components'

import { useReferral } from 'core'

export function TileReferrals({
	className = "",
}) {
	const iconRef = React.useRef()

	const { acceptedCount } = useReferral()

	return (
		<div className={`${className} tile-referrals`}>
			<div className="title">Refer your friends</div>
			<div className="description">
				{/* Invite friends, earn swag! <Link to="/referral-hub">Track progress</Link> */}
				Invite friends, earn credits, get swag! <Link to="/referral-hub">Track progress</Link>
			</div>
			<ProgressBar type="divided" min={0} max={10} progress={acceptedCount} />
		</div>
	)
}