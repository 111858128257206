import React from 'react'

import { useAlert } from 'core/providers'
import { AdminUser } from 'core/models'

const AdminCurrentUsersContext = React.createContext({})

export function AdminCurrentUsersProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [currentUsers, setCurrentUsers] = React.useState([])

	async function adminCurrentUsersSearch(params) {
		setLoading(true)
		const { error, count, results } = await AdminUser.load(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setCurrentUsers(results)
		setTotalCount(count)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		currentUsers,
		totalCount,

		adminCurrentUsersSearch,
	}), [isLoading, currentUsers, totalCount])

	return (
		<AdminCurrentUsersContext.Provider value={memoedValue}>
			{children}
		</AdminCurrentUsersContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, totalCount: number, currentUsers: Array<AdminUser>, adminCurrentUsersSearch: Function}} UseAdminCurrentUsers
 * @returns {UseAdminCurrentUsers}
 */
export function useAdminCurrentUsers() {
	return React.useContext(AdminCurrentUsersContext)
}