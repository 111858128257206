import React from 'react'

import { AdminUserTag } from 'core/models'
import { useAlert } from 'core/providers/alert'

const AdminUserTagContext = React.createContext({})

export function AdminUserTagProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [adminUserTags, setAdminUserTags] = React.useState([])

	async function adminUserTagsLoad() {
		setLoading(true)
		const { error, results } = await AdminUserTag.getUserTags()
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setAdminUserTags(results)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		adminUserTags,

		adminUserTagsLoad
	}), [isLoading, adminUserTags])

	return (
		<AdminUserTagContext.Provider value={memoedValue}>
			{children}
		</AdminUserTagContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, adminUserTags: Array<AdminUserTag>, adminUserTagsLoad: Function}} UseAdminUserTags
 * @returns {UseAdminUserTags}
 */
export function useAdminUserTags() {
	return React.useContext(AdminUserTagContext)
}