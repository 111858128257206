import { Alert, AlertButton, AlertButtonContainer, AlertPage } from 'components';
import './styles.scss';
import { useState } from 'react';
import { isEmail } from 'utils';
import { Shippment } from 'core/models/shipment';

function CommonInput({ title = '', value = '', onChange = () => { }, placeholder = '' }) {
    return <div className='add-shipping-input-wrapper'>
        <div className='add-shipping-input-title'>{title}</div>
        <input placeholder={placeholder} className='add-shipping-input' value={value} onChange={(e) => { onChange(e.target.value) }} />
    </div>
}

export function AddShipmentModal({ addShipment = () => { }, error = '', onClose = () => { } }) {
    const [trackingNumber, setTrackingNumber] = useState();
    const [senderName, setSenderName] = useState();
    const [senderEmail, setSenderEmail] = useState();

    function checkValidity() {

        if (!trackingNumber) {
            return true;
        }
        if (!senderName?.trim())
            return true
        return false
    }
    return <div className='add-shipment-modal-wrapper'>
        <Alert onCancel={onClose}>
            <AlertPage>
                <div className='add-an-order-title'>Add an order</div>
                <div className='add-an-order-description'>
                    Manually add an order to track
                </div>
                <CommonInput title='Tracking Number' value={trackingNumber} onChange={(t) => setTrackingNumber(t?.toUpperCase())} placeholder='xxx-xxx-xxx-xxx' />
                <CommonInput title='Order Name' value={senderName} onChange={(t) => setSenderName(t)} placeholder='Name' />
                <CommonInput title='Order Email' value={senderEmail} onChange={(t) => setSenderEmail(t)} placeholder='Email' />

                {error && <div style={{ marginTop: 16, color: '#F46666', fontSize: 14 }}>{error}</div>}

                <AlertButtonContainer>
                    <AlertButton disabled={checkValidity()} className='add-shipping-button' type='positive' onClick={() => { addShipment(trackingNumber, senderName, senderEmail); }}>Add order</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    </div>
}