import React from 'react'

import { AdminBlockedDomainsProvider } from 'core'
import { AdminBlockedDomains } from './blocked-domains'

export function AdminWebKit({ }) {
	return (
		<div className='admin-webkit'>
			<AdminBlockedDomainsProvider>
				<AdminBlockedDomains />
			</AdminBlockedDomainsProvider>
		</div>
	)
}