import React from 'react'

import { Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton } from './core'

export function ErrorAlert({
	title,
	error,
	onClose = () => { }
}) {

	return (
		<>
			{error &&
				<Alert onCancel={() => onClose()}>
					<AlertPage>
						{title && <AlertTitle>{title}</AlertTitle>}
						<AlertMessage>{error}</AlertMessage>
					</AlertPage>
				</Alert>
			}
		</>
	)
}