import React from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import iconGear from 'assets/images/gear-17px-26282A.svg'

import {
	NotificationContainer, Navigation, AppFooter, SelectDropDown, SelectOption,
} from 'components'
import { DigestArchivesMailList } from './mail-list'
import { DigestArchivesMailPreview } from './mail-preview'

import {
	ViewDigestArchivesProvider, useViewDigestArchive, DigestReceivedDate,
} from 'core'
import { routes, redirectTo } from 'routes'
import { DateHelper } from 'utils'

function ViewDigestArchivesPageEl({ }) {
	const navigate = useNavigate()

	const { isLoading, digestArchive } = useViewDigestArchive()

	/** @param {DigestReceivedDate} receivedDate */
	function handleReceivedDate(receivedDate) {
		navigate(redirectTo({
			route: routes.ViewDigestArchives,
			searches: { detailId: receivedDate.detailId }
		}))
	}

	function handleSettings() {
		navigate(redirectTo({
			route: routes.Digest,
			category: routes.Category.Digest['My Digests'],
			searches: { digestId: digestArchive.digest.id, settings: true }
		}))
	}

	function renderDetails() {
		if (!digestArchive) return ""

		if (digestArchive.count === 1) return `1 email delivered ${DateHelper.format(digestArchive.receivedAt, "ddd, MM/DD/YY @ h:mm a")}`
		return `${digestArchive.count} emails delivered ${DateHelper.format(digestArchive.receivedAt, "ddd, MM/DD/YY @ h:mm a")}`
	}

	return (
		<div id="parent" className="page-container digest-archives">
			<NotificationContainer />
			<Navigation />
			<div className="digest-archives-wrapper">
				<section className="header">
					<div className="info-container">
						<div className="digest-name">{digestArchive?.digest?.name}</div>
						<div className="digest-details">{renderDetails()}</div>
					</div>
					<div className="actions">
						<div className="btn-action">
							Archives
							{digestArchive?.receivedDates &&
								<SelectDropDown>
									{digestArchive.receivedDates.map((receivedDate, index) =>
										<SelectOption
											key={index}
											selectable={false}
											onClick={() => handleReceivedDate(receivedDate)}>
											{DateHelper.format(receivedDate.date, "MMM DD, YYYY")}
										</SelectOption>
									)}
								</SelectDropDown>
							}
						</div>
						<button className="btn-action" onClick={() => handleSettings()}>
							<img src={iconGear} alt="" />
							Settings
						</button>
					</div>
				</section>
				<section className="content-wrapper">
					<DigestArchivesMailList />
					<DigestArchivesMailPreview />
				</section>
			</div>
			<AppFooter />
		</div>
	)
}

export function ViewDigestArchivesPage() {
	return (
		<ViewDigestArchivesProvider>
			<ViewDigestArchivesPageEl />
		</ViewDigestArchivesProvider>
	)
}