import { BaseModel } from 'core/models/base'
import { ConnectedEmail, DiscountNewsletterMail, LockrBox } from './'
import { Api } from 'core/api'
import {
	isValidText, capitalizeFirstLetter,
	Crypto, Storage,
	DateHelper,
	ExtensionHelper,
} from 'utils'
import { errorCodes, errorMessages } from 'constants/error'
import { Segment } from 'core/analytics'
import { LockrScanSegment } from 'core/analytics/segment-lockrscan'

export class User extends BaseModel {
	hiddenFeatures = []
	firstName = ''
	lastName = ''
	email = ''
	phone = ''
	phoneVerifyCode = ''
	phoneVerifiedAt
	/** @type {Array<ConnectedEmail>} */
	connectedEmails = []
	role
	allowGPC = false
	allow2FA = false
	allowSmsNotification = false
	allowEmailNotification = false
	allowSupportPublisher = false
	allowReferralNotification = false
	allowDomainNotification = false
	allowEmailHeader = false
	allowAccountNotification = true
	previewDigestClosed = false
	allowLockrBox = false
	allowPasswordOption = false
	allowDeliveryHours = false
	allowConsent
	lockrBox
	isExtensionInstalled
	isBlockNewSender = true	// auto block, default is true
	doneTutorial = false
	showWelcome
	showChecklist
	showHelpBanner
	showDigestPopup = true
	showDigestNotification = true
	refCredits
	refCreditFill
	accessToken
	refreshToken
	timezone
	lockrScanUserId
	isGeneratedUser = false
	seenOnChrome
	discountNewsletterMail
	lockrPay = {
		totalCPA: 0,
		availableCPA: 0,
		inTransitCPA: 0
	}
	disableEmailTracking
	lockrPayEnabled

	static HiddenFeature = Object.freeze({
		Discounts: 'discounts',
		Shipping: 'shipping'
	})

	/** @returns {User} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			if (json.firstName) result.firstName = capitalizeFirstLetter(json.firstName ?? "")
			if (json.lastName) result.lastName = capitalizeFirstLetter(json.lastName ?? "")
			if (json.email) result.email = json.email.replace('@lockrmail.com', '@lockrMail.com')
			result.connectedEmails = ConnectedEmail.fromJSONArray(json.connectedEmails || [])
			if (result.allowLockrBox) result.lockrBox = LockrBox.fromJSON(json.lockrBox) ?? LockrBox.default
			else result.lockrBox = LockrBox.fromJSON(json.lockrBox)
			if (result.discountNewsletterMail && result.discountNewsletterMail.allowDiscountNewsletterMail) result.discountNewsletterMail = DiscountNewsletterMail.fromJSON(json.discountNewsletterMail) ?? DiscountNewsletterMail.default
			else result.discountNewsletterMail = DiscountNewsletterMail.fromJSON(json.discountNewsletterMail)

		}

		return result
	}

	static async sendVerifyEmail(email, params = {}) {
		try {
			const { error } = await Api.post('/email/send-verify', {
				email,
				...params
			})
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async updateSignupGclid(email, gclid) {
		try {
			const { error } = await Api.post('/gclid-update', {
				email,
				gclid
			})
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async verifyReferralEmail(token) {
		try {
			const { error, errorCode, data } = await Api.post('/email/verify', { token, is_bOption: true })
			return { error: error && errorMessages.CONTACT_US, errorCode, result: data?.email }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async verifyEmail(token) {
		try {
			const { error, errorCode, data } = await Api.post('/email/verify', { token })
			return { error: error && errorMessages.CONTACT_US, errorCode }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async lockrScanToLockrMailLogin(email, password) {
		try {
			const { error, errorCode, data } = await Api.post('/lockrScan/lockrMail/login', { email, password: Crypto.encrypt(password) })

			const result = User.fromJSON(data)

			if (error) {
				Storage.accessToken = null
				Storage.refreshToken = null

				Storage.cookieAccessToken = null
				Storage.cookieRefreshToken = null

				Storage.lockrScanUserId = null
				Storage.onlyLockrScanUser = null
				Storage.userId = null
			} else {

				Storage.accessToken = result?.accessToken
				Storage.refreshToken = result?.refreshToken

				Storage.cookieAccessToken = result?.accessToken
				Storage.cookieRefreshToken = result?.refreshToken

				Storage.lockrScanUserId = result?.lockrScanUserId
				Storage.userId = result?.id
			}

			if (errorCode === errorCodes.BLOCKED_ACCOUNT) return { error: errorMessages.BLOCKED_ACCOUNT }

			return {
				error: error && errorMessages.CONTACT_US,
				errorCode,
				result,
			}
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async login(email, password) {
		try {
			const { error, errorCode, data } = await Api.post('/login', { email, password: Crypto.encrypt(password) })

			const result = User.fromJSON(data)

			if (error) {
				Storage.accessToken = null
				Storage.refreshToken = null

				Storage.cookieAccessToken = null
				Storage.cookieRefreshToken = null

				Storage.lockrScanUserId = null
				Storage.onlyLockrScanUser = null
				Storage.userId = null
			} else {

				Storage.accessToken = result?.accessToken
				Storage.refreshToken = result?.refreshToken

				Storage.cookieAccessToken = result?.accessToken
				Storage.cookieRefreshToken = result?.refreshToken

				Storage.lockrScanUserId = result?.lockrScanUserId
				Storage.userId = result?.id
			}

			if (errorCode === errorCodes.BLOCKED_ACCOUNT) return { error: errorMessages.BLOCKED_ACCOUNT }
			if (errorCode === errorCodes.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE) {
				return { error: errorMessages.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE, errorCode: errorCodes.CONFIGURED_TO_SIGN_IN_WITH_GOOGLE }
			}
			if (errorCode === errorCodes.DELETED_BEFORE) return { error: error, errorCode: errorCodes.DELETED_BEFORE }
			return {
				error: error && errorMessages.CONTACT_US,
				errorCode,
				result,
			}
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async registerToLockrMailFromLockrScan(params) {
		try {
			const { error, errorCode, data } = await Api.post('/lockrScan/lockrMail/signup', params);
			console.log(error, errorCode, data);
			const result = data ? User.fromJSON(data) : null
			Storage.onlyLockrScanUser = null
			Storage.cookieAccessToken = result?.accessToken
			Storage.cookieRefreshToken = result?.refreshToken
			Storage.accessToken = result?.accessToken
			Storage.refreshToken = result?.refreshToken

			Storage.cookieAccessToken = result?.accessToken
			Storage.cookieRefreshToken = result?.refreshToken

			Storage.lockrScanUserId = result?.lockrScanUserId
			Storage.userId = result?.id
			return {
				error: error ? errorMessages.ERROR_SIGNUP : null,
				errorCode,
				result
			}
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.ERROR_SIGNUP) }
		}

	}

	static async register(params) {
		try {
			const isGoogleSignup = params.isGoogleSignup
			delete params.isGoogleSignup
			const { error, errorCode, data } = await Api.post('/signup', params)

			const result = data ? User.fromJSON(data) : null

			if (isGoogleSignup) {
				Storage.accessToken = result?.accessToken
				Storage.refreshToken = result?.refreshToken
			}
			Storage.cookieAccessToken = result?.accessToken
			Storage.cookieRefreshToken = result?.refreshToken

			return {
				error: error ? errorMessages.ERROR_SIGNUP : null,
				errorCode,
				result
			}
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.ERROR_SIGNUP) }
		}
	}

	static async updateEmail(email, newEmail, emailToken) {
		try {
			const { error, data } = await Api.post('/email/update', { email, newEmail, emailToken })
			return {
				error: error,
				results: data
			}
		} catch (error) {
			return { error: Api.parseError(error) }
		}
	}


	static async getSuggestedEmails(firstName, lastName) {
		try {
			const { error, data } = await Api.get('/suggested-email-addresses', { firstName, lastName })
			return {
				error: error && errorMessages.ERROR_SUGGESTED_EMAILS,
				results: data
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.ERROR_SUGGESTED_EMAILS) }
		}
	}

	static async checkLockrMailAvailability(email) {
		try {
			const { error, errorCode } = await Api.get('/check-lockrmail-available', { email })
			if (error === 'Email already in use') return { error: 'Email' }
			if (errorCode === errorCodes.INPUT_VALIDATION_FAILED) { return { error } }
			return { error: error && errorMessages.ERROR_CHECK_LOCKRMAIL_AVAILABILITY, errorCode }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getProfile() {
		const defaultError = 'Sorry, there is trouble to get profile. Please try again later.'
		try {
			const { error, data } = await Api.get('/get-profile')
			const result = data ? User.fromJSON(data) : null

			return { error, result }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async requestForgotUsername(email) {
		const defaultError = 'Sorry, there are some troubles to send password reset link.'
		try {
			const { error, errorCode } = await Api.post('/request-forgot-username', { email: email })
			if (errorCode === 404) { return { error: 'The email address entered is not registered in our system.' } }
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async checkEmailUsed(email) {
		const defaultError = 'Sorry, there is some trouble to check email is usable.'
		try {
			const { error, errorCode } = await Api.get('/email/check-used', { email })
			if (errorCode === 422) return { error, errorCode }
			if (errorCode === 5301) return { error, errorCode }
			if (errorCode === errorCodes.BLOCKED_ACCOUNT) return { error: errorMessages.BLOCKED_ACCOUNT }
			return { error: error ? defaultError : null, errorCode }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async checkMachineEmail(email) {
		const defaultError = 'Sorry, there is some trouble to check email is usable.'
		try {
			const { error, errorCode, data, success } = await Api.get('/email/check-valid', { email })
			if (error) {
				return { error, errorCode }
			}
			if (success && (data != "Valid" && data != "Accept All" && data != "Unknown")) {
				return { error: errorMessages.NOT_SUPPORTED_EMAILS }
			}
			return { error: null, errorCode }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async getChecklist() {
		const defaultError = 'Sorry, there are some troubles to get checklist.'
		try {
			const { error, data } = await Api.get('/get-checklist')
			return { error: error ? defaultError : null, result: data }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, defaultError) }
		}
	}

	// 2FA
	static async sendVerificationCode(phone) {
		try {
			const { error, errorCode } = await Api.post('/2fa/send', { phone })
			if (errorCode === errorCodes.TFA_PHONE_ALREADY_REGISTERED) { return { error: errorMessages.TFA_PHONE_ALREADY_REGISTERED } }
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async verifyPhoneNumber(phone, code) {
		try {
			const { error, errorCode } = await Api.post('/2fa/verify', { phone, code })
			if (errorCode === errorCodes.BAD_REQUEST) { return { error: errorMessages.TFA_INVALID_CODE, errorCode } }
			if (errorCode === errorCodes.TFA_MAX_ATTEMPS_REACHED) { return { error: errorMessages.TFA_MAX_ATTEMPS_REACHED, errorCode } }
			if (errorCode === errorCodes.TFA_INCORRECT_CODE) { return { error: errorMessages.TFA_INCORRECT_CODE, errorCode } }
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	isLoggedIn() {
		return isValidText(this.accessToken, true)
	}

	get personalEmail() { return this.connectedEmails.find(x => x.isPrimary) }
	get primaryEmail() { return this.personalEmail }
	get isVerified() { return this.primaryEmail?.isVerified }

	businessEmails() {
		return this.connectedEmails.filter(e => !e.isPrimary)
	}

	isPhoneVerified(newPhone = undefined) {
		if (newPhone && newPhone !== this.phone) {
			return false
		}
		return this.phoneVerifiedAt !== undefined
	}

	get name() { return `${this.firstName || ''} ${this.lastName || ''}` }
	get memberSince() {
		if (!this.createdAt) return ""
		return DateHelper.format(this.createdAt, "MMMM YYYY")
	}

	/** @returns {Array<ConnectedEmail>} */
	get emails() { return ConnectedEmail.sort(this.connectedEmails) }

	/** @returns {Array<ConnectedEmail>} */
	get presentEmails() {
		const emails = this.emails

		let results = emails.filter(e => e.isVisible)
		if (results.length <= 0) {
			const moreEmails = emails.filter(e => !e.isVisible).slice(0, 3 - results.length)
			results = [...results, ...moreEmails]
		}

		return results
	}
	get isAdmin() { return this.role === 1 }

	get is2FAPassed() { return Storage.get(Storage.Keys.PhoneVerifiedEmail) === this.email }
	set is2FAPassed(value) { Storage.set(Storage.Keys.PhoneVerifiedEmail, value ? this.email : null) }

	get isDigestEnabled() { return this.hiddenFeatures.includes(User.HiddenFeature.Digest) }
	get isLockrScanEnabled() { return this.hiddenFeatures.includes(User.HiddenFeature.LockrScan) }

	async update(params) {
		const defaultError = 'Sorry, there is trouble to update profile. Please try again later.'
		try {
			const { error, errorCode, errorValue, data } = await Api.post('/update-profile', params)
			if (data) {
				ExtensionHelper.updateGPCSettingsInExtension();
				Segment.track(Segment.Event.userUpdatedProfile, data?.id, params)
			}
			if (errorCode === 422 || errorValue) {
				return {
					error: `This email ${errorValue} is already added as someone’s connnected email. We are not able to add this email as your connected email.`
				}
			} else if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) {
				return { error: errorMessages.NOT_SUPPORTED_EMAILS }
			} else if (errorCode == errorCodes.LOCKRMAIL_NOT_SUPPORTED) {
				return { error: errorMessages.ERROR_EMAIL_NOT_SUPPORTED }
			}

			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	async closeChecklist() {
		return await this.update({ showChecklist: false })
	}

	async changePassword(oldPassword, password1, password2) {
		const encryptedOldPassword = Crypto.encrypt(oldPassword)
		const encryptedPassword1 = Crypto.encrypt(password1)
		const encryptedPassword2 = Crypto.encrypt(password2)

		const defaultError = 'Sorry, there are some troubles to reset password.'
		try {
			const { error, param, data } = await Api.post('/change-password', {
				oldPassword: encryptedOldPassword,
				newPassword: encryptedPassword1,
				newPasswordConfirmation: encryptedPassword2
			})

			if (data) {
				Storage.accessToken = data?.accessToken;
				Storage.refreshToken = data?.refreshToken
			}
			if (error) {
				switch (param) {
					case 'oldPassword':
						return { error: 'Current password is incorrect.' }
					default:
						return { error: defaultError }
				}
			}
			return { error: null }
		} catch (error) {
			return { error: 'Sorry, there are some troubles to reset password.' }
		}
	}

	connectedEmailIconColor(connectedEmail) {
		const index = this.connectedEmails.findIndex(e => e.id === connectedEmail.id)
		return ConnectedEmail.iconColor(index)
	}

	async deleteProfile() {
		const defaultError = 'Sorry, there is trouble to delete account. Please try again later.'
		try {
			const { error } = await Api.post('/delete-profile')
			return { error }
		}
		catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async sendLogout() {
		try {
			const { error } = await Api.get('/logout')
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	logout() {
		try {
			this.is2FAPassed = false
		} catch (error) {
			console.log(error)
		}
	}

	// Lockrscan

	static async getLockrScanAuth(permalink = null) {
		try {
			let url = '/lockrscan/googleOauth';
			if (permalink) url += `?permalink_param=${permalink}`;
			const { error, errorCode, data } = await Api.get(url)
			return { error, data }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getUserAuth(permalink = null) {
		try {
			let url = '/googleOauth';
			if (permalink) url += `?permalink_param=${permalink}`;
			const { error, errorCode, data } = await Api.get(url)
			return { error, data }
		} catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getSenderList(params: Object) {
		try {
			const { data, error } = await Api.get('/lockrscan/senders', params)
			return { data, error };
		}
		catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async moveSenderEmailsToLockrArchive(data) {
		try {
			const { success } = await Api.post('/lockrscan/moveEmailsToLockrArchive', data);
			return { success }
		}
		catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async unsubscribe(data) {
		try {
			const { success } = await Api.post('/lockrScan/senders/unsubscribe', data)
			return { success }
		}
		catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getVulgurNamesList() {
		try {
			const { data, error } = await Api.get('/vulgar-names')
			return { data }
		}
		catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async getLockrScanProfile() {
		try {
			const { data, error } = await Api.get('/lockrScan/get-profile');
			return { data }
		}
		catch (error) {
			console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async updateLockrScanProfile(params) {
		try {
			const { success, error } = await Api.post('/lockrScan/update-profile', params);
			if (error) {
				console.log(error)
				return { error: Api.parseError(error, errorMessages.CONTACT_US) }
			}
			return { success }
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async deleteLockrScanAcccount() {
		try {
			const { data, error } = await Api.delete('/lockrScan/delete', { lockrScanUserId: Storage.lockrScanUserId })
			await this.getProfile();
			return { data }
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async checkLockrScanAuthentications() {
		try {
			const { success, error } = await Api.get('/lockrscan/check-authentications')
			return { success }
		}
		catch (error) {
			console.log(error);
			return { success: error.response?.data?.success }
		}
	}
	static async hideSenders(params) {
		try {
			const { success, error } = await Api.post('/lockrscan/senders/hide', params)
			return { success }
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
	static async getLockrScanDashboardStats() {
		try {
			const { success, data, error } = await Api.get('/lockrscan/get-dashboard-stats');
			return { data };
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
	static async checkUninstallToken(token) {
		try {
			const { collectFeedback } = await Api.post('/check-uninstall-token', { token: token })
			return { collectFeedback }
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
	static async collectFeedback(params) {
		try {
			const data = await Api.post('/collect-extension-feedback', params)
			return data
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async generatePayout(payoutCompany, connectedEmailId, amount) {
		try {
			const { success, error } = await Api.post('/payouts/add-payout-request', {
				"payoutCompany": payoutCompany,
				"connectedEmailId": connectedEmailId,
				"amount": parseFloat(amount)
			})
			if (error) {
				return { error }
			}
			else {
				return { success }
			}

		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
	static async sendOtherPayout(message) {
		try {
			const { success, error } = await Api.post('/payouts/other-payout', { message })
			if (error) {
				return { error }
			}
			else {
				return { success }
			}
		}
		catch (error) {
			console.log(error);
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
}