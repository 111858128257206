import React from 'react'
import { AdminDomainGroupEl } from './domain-groups'
import { AdminBlockedExtensionDomainEl } from './blocked-extension-domains'
import { AdminBlockedDiscountDomainEl } from './blocked-discount-domains'



export function AdminDomainEl({ }) {
    return (
        <React.Fragment>
            <AdminDomainGroupEl />
            <div className="admin-group">
                <div className="admin-group-title">Blocked Extension Domains</div>
                <AdminBlockedExtensionDomainEl />
            </div>
            <div className="admin-group">
                <div className="admin-group-title">Blocked Discount Domains</div>
                <AdminBlockedDiscountDomainEl />
            </div>
        </React.Fragment>
    )
}
