import React from 'react'

import './styles.scss'
import iconSearch from 'assets/images/icon_search.svg'
import iconClear from 'assets/images/cross-12px-888888.svg'

import {
	SelectDropDown, SelectGroup, SelectOption,
	Button,
} from 'components'

import {
	ArrayHelper,
	isValidText,
	DOMHelper,
} from 'utils'

export function SearchField({
	className = '',
	placeholder = 'Search',
	isFilterOn = false,
	minimized = false,
	options,
	value = '',
	onChange = (value) => { }
}) {
	const inputRef = React.useRef()
	const optionRef = React.useRef()
	let onChangeTimer = null

	const [text, setText] = React.useState(value || '')

	React.useEffect(() => {
		const timer = setTimeout(() => {
			onChange(text)
		}, 1000)

		return () => { clearTimeout(timer) }
	})

	function focus() {
		if (inputRef.current) inputRef.current.focus()
	}

	function handleChange(event) {
		event.preventDefault()

		const { value } = event.target
		setText(value)
	}

	/**
	 * @param {Event} event
	 */
	function handleClear(event) {
		event?.preventDefault()
		event?.stopPropagation()

		setText('')
		// onChange('')
	}

	function handleOption(event) {
		event.preventDefault()

		if (optionRef.current) optionRef.current.toggle()
	}

	function handleClickOption() {
		focus()
	}

	function handleClick(event) {
		if (minimized) {
			if (!DOMHelper.closest(event.target, '.search-field-option')) focus()
		}
	}

	function renderOptions(options) {
		return options.map((option, index) => {
			const { label, selected, subOptions, onClick = () => { } } = option
			if (ArrayHelper.isValid(subOptions)) {
				return (
					<SelectGroup key={index} label={label}>
						{renderOptions(subOptions)}
					</SelectGroup>
				)
			}
			return (
				<SelectOption key={index} selected={selected} onClick={() => onClick()}>{label}</SelectOption>
			)
		})
	}

	return (
		<div
			className={`search-field ${className} ${isValidText(text) && 'has-clear'} ${ArrayHelper.isValid(options) && 'has-options'} ${minimized && 'minimized'}`}
			onClick={handleClick}>
			<img className="search-icon" src={iconSearch} alt="" />
			<input ref={inputRef} placeholder={placeholder} value={text} onChange={handleChange} />
			{isValidText(text) &&
				<Button
					className="clear"
					responsive={{ breakpoint: 576 }}
					onClick={handleClear}>
					<img src={iconClear} alt="" />
				</Button>
			}
			{options &&
				<>
					<div className={`search-field-option ${isFilterOn ? 'on' : ''}`} onClick={handleOption}>
						<img />
						<SelectDropDown>{renderOptions(options)}</SelectDropDown>
					</div>
				</>
			}
		</div>
	)
}