import React from 'react'

import './styles.scss'
import iconCheckmark from 'assets/images/checkmark-61CEF7.svg'
import newCheckMarkUnselected from 'assets/images/new_checkmark_unselected.svg';
import newCheckMarkSelected from 'assets/images/new_checkmark_selected.svg';

export class SelectOption extends React.Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef()
	}

	get classNameList() {
		const { className = "", selectable = true, selected = false, disabled = false } = this.props

		const results = [className, 'select-option']

		if (selectable) results.push('selectable')
		if (selected) results.push('selected')
		if (disabled) results.push('disabled')

		return results
	}

	handleHover = (event) => {
		// event.stopPropagation()

		// this.containerRef.current?.focus()
	}

	handleClick = (event) => {
		event?.stopPropagation()

		const { disabled, alwaysShow, value, onClick = (value) => { } } = this.props
		if (disabled) return
		if (value !== undefined) onClick(value)
		else onClick()

		if (!alwaysShow) document.activeElement?.blur()
	}

	render() {
		const {
			newCheckbox,
			checkmark = iconCheckmark,
			children,
			selected
		} = this.props
		return (
			<div
				ref={this.containerRef}
				className={this.classNameList.join(' ')}
				tabIndex={0}
				onMouseOver={this.handleHover}
				onClick={this.handleClick}
				onFocus={this.handleClick}>
				{newCheckbox && !selected && <img className='checkmark-unselected' src={newCheckMarkUnselected} alt="" />}
				{newCheckbox && selected && <img className='checkmark' src={newCheckMarkSelected} alt="" />}
				{!newCheckbox && <img className="checkmark" src={checkmark} alt="" />}
				{(typeof children === 'string') ?
					<span className="label">{children}</span>
					:
					children
				}
			</div>
		)
	}
}