import React from 'react'

import { useAlert } from 'core/providers'
import { AdminBlockedDiscountDomain } from 'core/models'

const AdminBlockedDiscountDomainContext = React.createContext({})

export function AdminBlockedDiscountDomainProvider({
    children,
}) {
    const { setError } = useAlert()

    const [isLoading, setLoading] = React.useState(false)
    const [blockedDomains, setBlockedDomains] = React.useState([])
    const [totalCount, setTotalCount] = React.useState(0)

    async function searchBlockedDomains(params) {
        setLoading(true)
        const { error, totalCount, results } = await AdminBlockedDiscountDomain.search(params)
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setTotalCount(totalCount)
        setBlockedDomains(results ?? [])
    }

    async function deleteBlockedDomains(domains = []) {
        setLoading(true)
        const { error } = await AdminBlockedDiscountDomain.destroy({ domains })
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setBlockedDomains(blockedDomains.filter(x => !domains.includes(x.domain)))
    }

    const memoedValue = React.useMemo(() => ({
        isLoading,
        blockedDomains,
        totalCount,

        searchBlockedDomains,
        deleteBlockedDomains,
    }), [isLoading, blockedDomains, totalCount])

    return (
        <AdminBlockedDiscountDomainContext.Provider value={memoedValue}>
            {children}
        </AdminBlockedDiscountDomainContext.Provider>
    )
}

/**
 * @typedef {{isLoading: boolean, blockedDomains: Array<AdminBlockedDiscountDomain>, totalCount: number, searchBlockedDomains: Function, deleteBlockedDomains: Function}} UseAdminBlockedDiscountDomain
 * @returns {UseAdminBlockedDiscountDomain}
 */
export function useAdminBlockedDiscountDomain() {
    return React.useContext(AdminBlockedDiscountDomainContext)
}