import React from 'react'

import './styles.scss'

import {
	SearchField,
	Loader,
	MenuOption, VMenu,
} from 'components'
import { SavedFilterListItem } from './list-item'

import { useAlert, Filter } from 'core'
import { DateHelper, ObjectHelper } from 'utils'

export function SavedFiltersEl({
	category = 'filter',
	className = '',
	senders = [],
	onChange = () => { },
	onEdit = (filter) => { }
}) {
	const { setAlert } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [searchText, setSearchText] = React.useState('')
	const [searchCriteria, setSearchCriteria] = React.useState('all')
	const [filters, setFilters] = React.useState([])
	const [error, setError] = React.useState(null)

	React.useEffect(() => { load() }, [searchText, searchCriteria])

	async function load() {
		setLoading(true)
		const { error, results, count } = await Filter.getSavedFilters({ searchText, searchCriteria, isDigest: category === Filter.Category.Digest })
		setLoading(false)

		if (error) {
			setError(error)
		} else {
			setFilters(results)
			if (results.length <= 0) {
				setError((searchText || searchCriteria !== 'all') ? "There are no saved filters that match your search criteria." : "There are no saved filters.")
			} else {
				setError(null)
			}
		}
	}

	async function handleAdd(filter) {
		setLoading(true)
		const { error, errorCode } = await Filter.applySavedFilter({ savedFilter: filter, senders, isDigest: category === Filter.Category.Digest })
		setLoading(false)

		if (error) {
			if (errorCode === 5101) setError('Each sender can only have a single filter assigned to it. We will support multiple filters per Sender in the future.')
			else setError(error)
		} else {
			onChange()
		}
	}

	async function deleteSavedFilter(filter) {
		if (!filter?.id) return

		const { error } = await Filter.deleteSavedFilter(filter.id)
		if (error) {
			setError(error)
			return
		}

		load()
	}

	function handleEdit(filter) {
		onEdit(Filter.fromJSON({ ...filter, id: null, isSaveFilter: false, name: "" }))
	}

	function handleDelete(filter) {
		setAlert({
			message: 'Are you sure you want to delete this filter?',
			buttons: [
				{ type: 'positive', label: 'Yes', onClick: () => deleteSavedFilter(filter) },
				{ type: 'negative', label: 'No' },
			]
		})
	}

	return (
		<div className={`saved-filters ${className}`}>
			<div className="saved-filters-group">
				<SearchField
					isFilterOn={searchCriteria !== 'all'}
					value={searchText}
					onChange={value => setSearchText(value)}
					options={[
						new MenuOption({ label: 'Title & Keywords', selected: searchCriteria === 'all', onClick: () => setSearchCriteria('all') }),
						new MenuOption({ label: 'Title only', selected: searchCriteria === 'only_title', onClick: () => setSearchCriteria('only_title') }),
						new MenuOption({ label: 'Keywords only', selected: searchCriteria === 'only_keywords', onClick: () => setSearchCriteria('only_keywords') }),
					]} />
			</div>
			<div className="saved-filter-list">
				{error && <div className="error">{error}</div>}
				{isLoading ? <Loader /> :
					filters.map((filter, index) =>
						<SavedFilterListItem
							key={index}
							filter={filter}
							onAdd={() => handleAdd(filter)}
							onEdit={() => handleEdit(filter)}
							onDelete={() => handleDelete(filter)} />
					)
				}
			</div>
		</div>
	)
}