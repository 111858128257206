import { BaseModel } from 'core/models/base'
import { Api, Filter } from 'core'
import { ArrayHelper } from 'utils'

export class FilterLibraryCategory extends BaseModel {
	id
	name

	/** @returns {FilterLibraryCategory} */
	static fromJSON(json) { return super.fromJSON(json) }

	/** @returns {Array<FilterLibraryCategory>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async get() {
		const defaultError = 'Sorry, there are some troubles to get categories.'
		try {
			const { error, data } = await Api.get('/filters/library/categories')
			return { error, results: FilterLibraryCategory.fromJSONArray(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}
}

export class FilterLibrary extends BaseModel {
	relNo
	type
	name
	description
	keywords = []

	static Type = Object.freeze({
		Unknown1: 1,
		Unknown2: 2,
		Random: 3,
		Between: 4,
		CustomInput: 5,
	})

	/** @returns {FilterLibrary} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			if (!ArrayHelper.isValid(result.keywords)) result.keywords = result.initialKeywords
		}
		return result
	}

	/** @returns {Array<FilterLibrary>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static trim(libraries) {
		let filterLibraries = []
		if (libraries) {
			// check selection.
			filterLibraries = libraries.filter(e => ArrayHelper.isValid(e.libraries))

			// remove not-used properties.
			filterLibraries = filterLibraries.map(filterLibrary => ({
				categoryId: filterLibrary.categoryId,
				libraries: filterLibrary.libraries.map(library => {
					if (library.type === FilterLibrary.Type.CustomInput) {
						const { keywords } = library

						let filteredKeywords = keywords[0] ? keywords[0].split(",").map(keyword => keyword.trim()).filter(keyword => Boolean(keyword)) : []
						return { relNo: library.relNo, keywords: filteredKeywords }
					}
					return { relNo: library.relNo, keywords: library.keywords }
				})
			}))
		}
		return filterLibraries
	}

	static async get(categoryId) {
		const defaultError = 'Sorry, there are some troubles to get libraries.'
		try {
			const { error, data } = await Api.get(`/filters/library/${categoryId}`)
			return { error, results: FilterLibrary.fromJSONArray(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async apply({ senders, libraries, filter = null, isDigest = false }) {
		const defaultError = 'Sorry, there are some troubles to apply libraries.'

		const senderIds = senders.map(x => typeof x === 'string' ? x : x.id)

		let filterLibraries = []
		if (libraries) {
			// check selection.
			filterLibraries = libraries.filter(e => ArrayHelper.isValid(e.libraries))

			// remove not-used properties.
			filterLibraries = filterLibraries.map(filterLibrary => ({
				categoryId: filterLibrary.categoryId,
				libraries: filterLibrary.libraries.map(library => {
					if (library.type === FilterLibrary.Type.CustomInput) {
						const { keywords } = library

						let filteredKeywords = keywords[0] ? keywords[0].split(",").map(keyword => keyword.trim()).filter(keyword => Boolean(keyword)) : []
						return { relNo: library.relNo, keywords: filteredKeywords }
					}
					return { relNo: library.relNo, keywords: library.keywords }
				})
			}))
		}

		if (!ArrayHelper.isValid(filterLibraries) && filter) {
			return await Filter.delete({ id: filter.id, isDigest })
		}

		let params = {
			isSaveFilter: false,
			alwaysOn: true,
			senderIds,
			filterLibraries: ArrayHelper.isValid(filterLibraries) ? filterLibraries : null,
			isDigest,
		}
		if (filter) {
			params = { id: filter.id, ...params }
		}

		try {
			const { error } = await Api.post(filter ? '/filters/edit' : '/filters/create', params)
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	get isKeywordsAvailable() { return [FilterLibrary.Type.CustomInput, FilterLibrary.Type.Between].includes(this.type) }
	get hasKeywords() {
		if (!ArrayHelper.isValid(this.keywords)) return false

		if (this.type === FilterLibrary.Type.CustomInput) {
			return Boolean(this.formattedKeywords)
		}

		return true
	}

	get formattedKeywords() {
		if (this.type === FilterLibrary.Type.Between) {
			if (this.keywords.length < 2) return ''
			return `${this.keywords[0]} - ${this.keywords[1]}`
		}
		if (this.type === FilterLibrary.Type.CustomInput) {
			return this.keywords.join(", ")
		}
		return ""
	}

	get initialKeywords() {
		if (this.hasKeywords) {
			if (this.type === FilterLibrary.Type.CustomInput) return [this.formattedKeywords]
			return this.keywords
		}

		if (this.type === FilterLibrary.Type.Between) return ['20%', '100%']
		if (this.type === FilterLibrary.Type.CustomInput) return []
		return []
	}

	get canExpand() { return this.type === FilterLibrary.Type.Between || this.type === FilterLibrary.Type.CustomInput }
}

export class FilterLibraryGroup extends BaseModel {
	categoryId
	/** @type {Array<FilterLibrary>} */
	libraries

	/** @returns {FilterLibraryGroup} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.libraries = FilterLibrary.fromJSONArray(json.libraries)
		}
		return result
	}

	/** @returns {Array<FilterLibraryGroup>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static create({ categoryId, libraries }) { return FilterLibraryGroup.fromJSON({ categoryId, libraries }) }
}