import { plainToClass } from "class-transformer"
import { Api } from "core/api"
import { DateHelper } from "utils"

export class LockrScanAdminTraction {
    totalAccounts
    accountsChange
    totalUnsubscribed
    unSubscribedChange
    totalLockrArchived
    lockrArchivedChange
    accountTicks
    lockrArchivedTicks
    unSubscribedTicks
    totalEmailProcessed
    emailChange
    emailProcessedTick


    static fromJson(json) {
        if (!json) return null

        const result = plainToClass(LockrScanAdminTraction, json)
        return result
    }
    static async loadTraction(startDate, endDate, rangeType) {
        const defaultError = 'Sorry, there are some troubles to get tractions.'
        let params = {
            startDate: DateHelper.format(startDate, 'YYYY-MM-DD'),
            endDate: DateHelper.format(endDate, 'YYYY-MM-DD'),
        }
        if (rangeType) params = { ...params, rangeType }

        try {
            const { error, data } = await Api.get('/lockrScan/admin/get-traction', params)
            if (error) return { error: defaultError }
            // console.log(AdminTraction.fromJSON(data))
            // return { result: AdminTraction.fromJSON(data) }
            return { result: this.fromJson(data) }
        } catch (error) {
            return { error: Api.parseError(error, defaultError) }
        }
    }
}