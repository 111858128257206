import React from 'react'

import './styles.scss'


import featured1 from 'assets/images/tutorial/tutorial-1.svg'
import featured2 from 'assets/images/tutorial/tutorial-2.svg'
import featured3 from 'assets/images/tutorial/tutorial-3.svg'
import featured4 from 'assets/images/tutorial/tutorial-4.svg'
import grayArrow from 'assets/images/arrow/next-gray.svg'

import chromeLogo from 'assets/images/chrome-logo.svg'

import { range, useWidth } from 'utils'
import { BlueButton } from 'components'
import { useNavigate } from 'react-router-dom'


export function TutorialTutorials({
	onContinue = () => { }
}) {
	const containerRef = React.useRef()
	const navigate = useNavigate()


	const [pageIndex, setPageIndex] = React.useState(0)
	React.useEffect(() => {
		// Scroll to top whenever page changed
		// if (containerRef.current?.parentElement) containerRef.current.parentElement.scrollTop = 0

	}, [pageIndex])

	function handleNext() {
		if (pageIndex >= 3) {
			navigate('/tutorial/discover')
		}
		setPageIndex(pageIndex + 1)

	}

	const { width } = useWidth();


	return (
		<div ref={containerRef} className="tutorial-tutorials">
			<div className='tutorials-item'>
				<div className={"tutorial-description"} >
					<div className='tutorial-numbers'>
						<span style={{ color: pageIndex == 0 ? '#61CEF7' : '#8B8B8B' }}>1</span><span style={{ marginLeft: 24, color: pageIndex == 1 ? '#61CEF7' : '#8B8B8B' }}>2</span><span style={{ marginLeft: 24, color: pageIndex == 2 ? '#61CEF7' : '#8B8B8B' }}>3</span><span style={{ marginLeft: 24, color: pageIndex == 3 ? '#61CEF7' : '#8B8B8B' }}>4</span><span style={{ marginLeft: 24, color: pageIndex == 4 ? '#61CEF7' : '#8B8B8B' }}>5</span> {width < 576 && <span onClick={() => navigate('/tutorial/discover')}><img src={grayArrow} style={{ height: 8, width: 8, marginLeft: 22 }} /></span>}
					</div>
					{pageIndex == 0 ? <div className='heading' style={{ marginTop: 40 }}>Streamlined <span style={{ color: '#2E70D2' }}>registration</span></div> :
						pageIndex == 1 ? <div className='heading' style={{ marginTop: 40 }}>Verification emails in<span style={{ color: '#FAC748' }}> real-time</span></div> :
							pageIndex == 2 ? <div className='heading' style={{ marginTop: 40 }}><span style={{ color: '#E88D67' }}>Discount</span> codes at your fingertips</div> :
								pageIndex == 3 ? <div className='heading' style={{ marginTop: 40 }}>Inbox <span style={{ color: '#BD94BC' }}>snapshot</span> in your browser</div> :
									<></>
					}
					<div className='description'>{pageIndex == 0 ? "Enable settings for brand domains during the sign-up process." :
						pageIndex == 1 ? "Complete the sign-up process without leaving the site." : pageIndex == 2 ? "Never miss a brand discount - easily activate or copy discount codes as you shop online. " :
							pageIndex == 3 ? "View your inbox with one click. Scan emails, adjust sender settings, and redeliver emails. " : ""}</div>
					<div style={{ display: 'flex', gap: 24, marginTop: 40 }}>
						<BlueButton onClick={() => { handleNext() }} className='next-button'>Next</BlueButton>
						{width > 1129 && <span onClick={() => { window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank') }} className='chrome-button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}><img style={{ height: 24, width: 24 }} src={chromeLogo} />Add to chrome</span>}
					</div>
				</div>
				<div><img className='tutorial-images' src={pageIndex == 0 ? featured1 : pageIndex == 1 ? featured2 : pageIndex == 2 ? featured3 : pageIndex == 3 ? featured4 : ""} /></div>
			</div>
		</div>
	)
}