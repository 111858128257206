import axios from 'axios'

import { config } from '../constants'
import { isValidText } from '../utils'
import { Storage, ArrayHelper } from '../utils'

const BASE_URL = config.API_BASE_URL

export class Api {
	// A map object to track ongoing requests by their path. 
	// This allows us to cancel them later if a new request to the same endpoint is initiated.
	static ongoingRequests = new Map();

	static formatError(value, errorCode) {
		if (!isValidText(value)) return null

		let message = value.replace('Error: ', '')
		if (message.includes("jwt malformed")) {
			message = 'Your credentials were not registered in system.'
		}
		if (message.includes("jwt expired") || errorCode === 401) {
			message = 'The session has expired. Please refresh the page and login to your account to return to the dashboard.'
		}
		if (message.slice(-1) !== '.') {
			return message + '.'
		}
		return message
	}

	static handleResponse(response) {
		try {
			if (!response?.data?.error) { return response?.data }
			const { error } = response?.data
			const { code: errorCode, message = "", errors = [] } = error || {}
			if (isValidText(message)) {
				return { success: false, error: Api.formatError(message, errorCode), errorCode }
			}

			if (ArrayHelper.isValid(errors)) {
				const { value, msg, param } = errors[0]
				return { success: false, error: Api.formatError(msg, errorCode), errorCode, errorValue: value }
			}
		} catch (error) {
			console.log(error)
		}
		return response.data
	}

	static parseError(error, placeholder = "Something went wrong") {
		if (error.isAxiosError) {
			return placeholder
		}

		if (error.message && error.message !== "") {
			return error.message
		}

		const { msg, param } = error.errors[0]
		return msg
	}

	static get headers() {
		const accessToken = Storage.accessToken
		if (!accessToken) return axios.defaults.headers
		return { ...axios.defaults.headers, Authorization: `Bearer ${accessToken}` }
	}

	static createConfiguration() {
		return { ...axios.defaults, headers: Api.headers }
	}

	static async refreshAccess() {
		const refreshToken = Storage.refreshToken
		if (!refreshToken) return { success: false }
		try {
			const { success, data, message } = Api.handleResponse(await axios.post(BASE_URL + '/refresh-access', { refreshToken }))
			if (success) {
				Storage.accessToken = data.accessToken
				Storage.refreshToken = data.refreshToken

				Storage.cookieAccessToken = data.accessToken
				Storage.cookieRefreshToken = data.refreshToken
			}
			return { success }
		} catch (error) {
			console.log(error)
			return { success: false }
		}
	}

	static async get(path, params = undefined, unique = false, handleLastParam = false, handleInitRequest = false) {
		let query = ''
		if (params) {
			query = '?'
			let keys = Object.keys(params)
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				query += `${key}=${encodeURIComponent(params[key])}`
				if (i < keys.length - 1) {
					query += '&'
				}
			}
		}

		let baseRoute = path;
		let lastRouteParam = '';
		if (handleLastParam) {
			const pathParts = path.split('/');
			lastRouteParam = pathParts.pop();
			baseRoute = pathParts.join('/');
		}

		const requestIdentifier = baseRoute + (params?.searchText ? `?searchText=${params.searchText}` : '');
		const searchText = params?.searchText;

		if (Api.ongoingRequests.has(baseRoute)) {
			const ongoingRequest = Api.ongoingRequests.get(baseRoute);
			const ongoingSearchText = new URLSearchParams(ongoingRequest.requestIdentifier.split('?')[1]).get('searchText');
			if (searchText !== undefined) {
				if (handleInitRequest || path === "/senders") {
					// searchText parameter exists in the new request
					// Cancel if new request's searchText is empty and ongoing request's searchText is not,
					// or if both new request's searchText and ongoing request's searchText are not empty
					if (searchText || ongoingSearchText || (searchText == '' && ongoingSearchText == null)) {
						ongoingRequest.source.cancel('Canceled because of a new unique request.');
					}
				} else {
					ongoingRequest.source.cancel('Canceled because of a new unique request.');
				}
			} else if (handleLastParam && (!lastRouteParam || lastRouteParam === ongoingRequest.lastRouteParam)) {
				ongoingRequest.source.cancel('Canceled because of a new unique request.');
			} else if (unique) {
				ongoingRequest.source.cancel('Canceled because of a new unique request.');
			}
		}

		const source = axios.CancelToken.source();

		Api.ongoingRequests.set(baseRoute, { source, requestIdentifier, lastRouteParam });

		try {
			const requestPath = BASE_URL + path + query
			const response = await axios.get(requestPath, {
				...Api.createConfiguration(),
				cancelToken: (unique || searchText !== undefined || handleLastParam) ? source.token : undefined
			});
			// After a successful response, remove the path from ongoingRequests as it's no longer "ongoing".
			if (unique || searchText !== undefined || handleLastParam) {
				Api.ongoingRequests.delete(baseRoute);
			}
			return Api.handleResponse(response)
		} catch (error) {
			// Check if the error was due to a unique request being intentionally canceled.
			if (axios.isCancel(error) && (unique || searchText !== undefined || handleLastParam) && error.message === 'Canceled because of a new unique request.') {
				// Return undefined for intentionally canceled requests.
				return;
			}
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.get(path, params, unique, handleLastParam, handleInitRequest);
				}
			}

			throw error
		}
	}

	static async post(path, params = undefined) {
		try {
			const response = await axios.post(BASE_URL + path, params, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.post(path, params)
				}
			}

			throw error
		}
	}

	static async uploadFiles(path, files) {
		try {
			const form = new FormData()
			files.forEach(file => {
				form.append('files', file)
			});

			let config = Api.createConfiguration()
			config['headers'] = {
				...config['headers'],
				"Content-Type": "multipart/form-data",
			}

			const response = await axios.post(BASE_URL + path, form, config)
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.uploadFiles(path, params)
				}
			}

			throw error
		}
	}

	static async put(path, params = undefined) {
		try {
			const response = await axios.put(BASE_URL + path, params, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.put(path, params)
				}
			}

			throw error
		}
	}

	static async delete(path, params = null) {
		try {
			const response = await axios.delete(BASE_URL + path, { headers: Api.headers, data: params })
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.delete(path, params)
				}
			}

			throw error
		}
	}
}

export function onSuccess(type, response = undefined) {
	return { type: type, response: response }
}

export function onFailure(type, error) {
	return { type: type, error: error }
}