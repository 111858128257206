import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import { FilterPreview, useFilter, useCustomFilter } from 'core'
import { firstLetter } from 'utils'

export function FilterPreviewEl({
	isDigest = false,
	className = '',
}) {
	const { senders } = useFilter()
	const { keywords, hasAttachment, hasAttachmentOp } = useCustomFilter()
	const [previews, setPreviews] = React.useState([])

	React.useEffect(() => {
		load()
	}, [senders, keywords, hasAttachment, hasAttachmentOp])

	async function load() {
		const params = {
			keywords,
			hasAttachment,
			hasAttachmentOp,
			isDigest
		}
		const { error, results } = await FilterPreview.getAll(senders, params)
		if (error) { console.log(error) }
		else {
			setPreviews(results)
		}
	}

	return (
		<div className={`filter-preview ${className}`}>
			<div>Filter Preview</div>
			<div className="list">
				{previews.map((preview, index) =>
					<div key={index} className="list-item">
						<div>
							<div className={`avatar ${preview.hasAvatar && 'has-avatar'}`}>
								{preview.hasAvatar ?
									<img src={preview.avatar} alt="" />
									:
									<span>{firstLetter(preview.name)}</span>
								}
							</div>
							<div className="name">{preview.name}</div>
						</div>
						<div className="description">{preview.subject}</div>
					</div>
				)}
				{(previews.length <= 0) &&
					<div className="error">
						There are either no prior emails from the Sender(s) selected or none that would meet the criteria you have entered. As such, all prior messages from this Sender(s) would have been blocked with these settings.
					</div>
				}
			</div>
		</div>
	)
}

FilterPreview.propTypes = {
	className: PropTypes.string
}