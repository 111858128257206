import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	Select, SelectOption,
	TimePicker, DatePicker,
	BlueButton,
	AlertButtonContainer,
	AlertButton,
} from 'components'

import {
	useAlert,
	useAuth,
	LockrBox,
	User
} from 'core'
import {
	range,
	DateHelper,
	ObjectHelper,
} from 'utils'
import moment from 'moment-timezone'

export function LockrScanRecurringEmailReportModal({
	currentRecurrinfEmailInfo = {},
	onChange = (value) => { },
	onSave = () => { },
	onClose = () => { },
}) {
	const startOfWeek = DateHelper.startOfWeek(new Date())

	const { setError } = useAlert()
	const { currentUser, userGetProfile, lockrScanUserGetProfile, currentLockrScanUser } = useAuth()


	const [recurringEmailInfo, setRecurringEmailInfo] = React.useState(currentRecurrinfEmailInfo)
	const [frequency, setFrequency] = React.useState(currentRecurrinfEmailInfo?.frequency)
	const [deliveryDay, setDeliveryDay] = React.useState(currentRecurrinfEmailInfo?.startTime ?? Date.now())
	const [deliveryTime, setDeliveryTime] = React.useState(currentRecurrinfEmailInfo?.startTime ?? Date.now())

	function isSaveEnabled() {
	}

	function handleFrequency(value) {
		setFrequency(value);
		// setLockrBox({ ...lockrBox, frequency: value })
	}

	function handleDeliveryDay(value) {
		setDeliveryDay(value)
		setDeliveryTime(DateHelper.merge({ day: value, time: deliveryTime }));

	}

	function handleDeliveryTime(value) {

		setDeliveryTime(DateHelper.merge({ day: deliveryTime, time: value }))
	}

	async function handleSave() {
		const { data, error } = await User.updateLockrScanProfile({ frequency: frequency, allowRecurringEmail: true, deliveryTime: deliveryTime });
		console.log(data);
		lockrScanUserGetProfile()
		onClose();
	}

	return (
		<div className='lockrScan-recurring-email-modal'>
			<Alert className={`lockr-box-modal`}
				onCancel={() => onClose()}>
				<AlertPage>
					<AlertTitle>lockrScan Report Settings</AlertTitle>
					<div className={`lockr-box-modal-wrapper`}>
						<div>Select frequency and a delivery time:</div>
						<div className="lockr-box-modal-group">
							<div>How often</div>
							<Select
								className="frequency-selector"
								value={frequency}
								onChange={(value) => setFrequency(value)}>
								<SelectOption value={"daily"}>Every day</SelectOption>
								<SelectOption value={"weekly"}>Every week</SelectOption>
								<SelectOption value={"monthly"}>Every month</SelectOption>
							</Select>
						</div>
						{frequency === "daily" &&
							<div className="lockr-box-modal-group">
								<div>Time</div>
								<TimePicker
									className="delivery-time-selector"
									keepLabel
									label={DateHelper.format(deliveryTime, 'hh:mm a')}
									hasMinutes={false}
									value={deliveryTime}
									onChange={(value) => handleDeliveryTime(value)} />
							</div>
						}
						{frequency === "weekly" &&
							<React.Fragment>
								<div className="lockr-box-modal-group">
									<div>Day</div>
									<Select
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(deliveryDay, 'dddd')}>
										{range(0, 6).map((i) => DateHelper.addDay(startOfWeek, i)).map((date, index) =>
											<SelectOption
												key={index}
												value={date}
												selected={DateHelper.isSameDay(deliveryDay, date)}
												onClick={() => handleDeliveryDay(date)}>{DateHelper.format(date, 'dddd')}
											</SelectOption>
										)}
									</Select>
								</div>
								<div className="lockr-box-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
						{frequency === "monthly" &&
							<React.Fragment>
								<div className="lockr-box-modal-group">
									<div>Day</div>
									<DatePicker
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(new Date(deliveryDay), 'Do')}
										value={new Date(deliveryDay)}
										onChange={(value) => handleDeliveryDay(value)} />
								</div>
								<div className="lockr-box-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
					</div>
					<AlertButtonContainer>
						<AlertButton
							type="positive"
							disabled={isSaveEnabled()}
							onClick={() => handleSave()}>
							Save
						</AlertButton>
					</AlertButtonContainer>
				</AlertPage>
			</Alert>
		</div>
	)
}