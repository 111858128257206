import { DiscoverCompany } from 'core'

export const privateRoutes = Object.freeze({
	TFA: '/verify-phone-number',
	Dashboard: '/dashboard',
	Account: '/account',
	Discover: '/discover',
	Tutorial: '/tutorial',
	Digest: '/digest',
	LockrScan: '/lockrscan',
	ViewDigestArchives: '/view-archives',
	Groups: '/groups',
	ReferralHub: '/referral-hub',
	Discounts: '/discounts',
	LockrScanDiscounts: '/lockrscan/discounts',
	Category: Object.freeze({
		Account: Object.freeze({
			'Account': 'profile',
			'Settings': 'settings',
			'Domains': 'domains',
			'Groups': 'groups',
		}),
		Tutorial: Object.freeze({
			'Tutorials': 'tutorials',
			'Discover': 'discover'
		}),
		Discover: DiscoverCompany.Category,
		Digest: Object.freeze({
			'My Digests': 'my-digests',
			// Discover: 'discover',
		})
	})
})