export class Notification {
	/** @type {"default"|"blue"} */
	type
	icon
	priority
	isClose
	children
	onClose

	static Priority = Object.freeze({
		DigestPreview: 0,
		CRX: 1,
		Canny: 2,
		VerifyEmail: 3,
		LockrScan: 4,
		Extension: 5,
	})

	constructor({ type = "default", icon, priority = 0, isClose = false, onClose = () => { }, children }) {
		this.type = type
		this.icon = icon
		this.priority = priority
		this.children = children
		this.isClose = isClose
		this.onClose = onClose
	}
}