import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'

import { Digest, DigestReceivedDate } from 'core/models/digest/core'
import { DigestMail } from 'core/models/digest/mail'

import { errorMessages } from 'constants/error'
import { DateHelper } from 'utils'

export class DigestArchive extends BaseModel {
	count
	receivedAt
	/** @type {Digest} */
	digest
	/** @type {Array<DigestMail>} */
	rows
	/** @type {Array<DigestReceivedDate>} */
	receivedDates = []

	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.receivedAt = DateHelper.parse(json.receivedAt)
			result.digest = Digest.fromJSON(json.digest)
			result.rows = DigestMail.fromJSONArray(json.rows)
			result.receivedDates = DigestReceivedDate.fromJSONArray(json.receivedDates)
		}
		return result
	}

	static async get(detailId) {
		try {
			const { error, data } = await Api.get("/digest/get-mails", { detailId })
			return {
				error: error && errorMessages.CONTACT_US,
				result: DigestArchive.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}
}