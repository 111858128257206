import React from 'react'

import './styles.scss'

import {
	TextField, BlueButton
} from 'components'

import { isUrl, isValidText, stringContainsUrl } from 'utils'
import sanitize from 'sanitize-html'

export function NameInputEl({
	className = "",
	firstName: initialFirstName,
	lastName: initialLastName,
	vulgarWords = [],
	onNext = (firstName, lastName) => { }
}) {
	const [firstName, setFirstName] = React.useState(initialFirstName || "")
	const [lastName, setLastName] = React.useState(initialLastName || "")
	const [isValid, setValid] = React.useState(false)
	const [error, setError] = React.useState(false)
	React.useEffect(() => { setValid(isValidText(firstName) && isValidText(lastName) && !stringContainsUrl(sanitize(firstName, { allowedTags: [] })) && !stringContainsUrl(sanitize(lastName, { allowedTags: [] }))) }, [firstName, lastName])

	function handleNext() {
		let sanitizeFirstName = sanitize(firstName, { allowedTags: [] })
		let sanitizeLastName = sanitize(lastName, { allowedTags: [] })
		if (!isValidText(firstName) || !isValidText(lastName) || stringContainsUrl(sanitizeFirstName) || stringContainsUrl(sanitizeLastName)) return




		if (vulgarWords.indexOf(firstName.toLowerCase()) >= 0 || vulgarWords.indexOf(lastName.toLowerCase()) >= 0) {
			setError(true);
			setFirstName('');
			setLastName('');
		}
		else onNext(sanitizeFirstName, sanitizeLastName)
	}

	return (
		<>
			<div className={`${className} register-name`}>
				<div className="form">
					<TextField
						type="large"
						id="firstName"
						placeholder="First name"
						autoFocus
						value={firstName}
						onChange={(event) => { setFirstName(event.target.value); setError(false) }} />
					<TextField
						type="large"
						className="form-divider"
						id="lastName"
						placeholder="Last name"
						value={lastName}
						onChange={(event) => { setLastName(event.target.value); setError(false) }}
						onNext={() => handleNext()} />
				</div>
				{error && <div className='error'>This entry does not comply with lockr's approved content guidelines. Please try again</div>}
			</div>
			<div className="form-button-container">
				<BlueButton
					type="large"
					className="btn-next"
					disabled={!isValid}
					onClick={() => handleNext()}>
					Next
				</BlueButton>
			</div>
		</>
	)
}