import React from 'react'

import './styles.scss'

export class AlertFooter extends React.Component {

	render() {
		const { className = '', children } = this.props
		return (
			<div className={`alert-footer ${className}`}>
				{children}
			</div>
		)
	}
}