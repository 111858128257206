import { plainToClass } from 'class-transformer'

import { BaseModel } from 'core/models/base'
import { Sender } from 'core/models/sender'
import { Api } from 'core/api'
import { DateHelper, Storage } from 'utils'
import { errorMessages } from 'constants/error'
import { Segment } from 'core/analytics'

export class DeliveryTime {
	isOn
	to
	from

	/** @returns {DeliveryTime} */
	static fromJSON(json) {
		const result = plainToClass(DeliveryTime, json)
		if (result) {
			result.from = result.isOn ? DateHelper.parse(json.from) : DeliveryTime.defaultFrom
			result.to = result.isOn ? DateHelper.parse(json.to) : DeliveryTime.defaultTo
		}
		return result
	}

	static get defaultFrom() { return DateHelper.addHour(DateHelper.startOfDay(new Date()), 9) }
	static get defaultTo() { return DateHelper.addHour(DateHelper.startOfDay(new Date()), 17) }
	static get default() { return DeliveryTime.fromJSON({ isOn: false, from: DeliveryTime.defaultFrom, to: DeliveryTime.defaultTo }) }

	static create({ isOn, from, to }) {
		const result = new DeliveryTime()
		result.isOn = isOn
		result.from = from
		result.to = to
		return result
	}
}

export class DeliveryHour extends BaseModel {
	userId
	isOngoing
	from
	to
	deliveryTimes
	excludedSenders = []

	static Weekdays = Object.freeze({
		Sunday: 'sun',
		Monday: 'mon',
		Tuesday: 'tue',
		Wednesday: 'wed',
		Thursday: 'thu',
		Friday: 'fri',
		Saturday: 'sat',
	})

	/** @returns {DeliveryHour} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.from = DateHelper.startOfDay(DateHelper.parse(json.from))
			result.to = DateHelper.endOfDay(DateHelper.parse(json.to))

			result.deliveryTimes = {}
			for (const key of Object.keys(DeliveryHour.Weekdays)) {
				const weekday = DeliveryHour.Weekdays[key]
				result.deliveryTimes[weekday] = DeliveryTime.fromJSON(json.deliveryTimes[weekday])
			}

			const excludedSenders = []
			for (const sender of json.excludedSenders) {
				if (typeof sender === "string") {
					excludedSenders.push(sender)
				} else {
					excludedSenders.push(Sender.fromJSON(sender))
				}
			}
			result.excludedSenders = excludedSenders
		}
		return result
	}

	static async get() {
		try {
			const { error, data } = await Api.get('/delivery-hours')
			return {
				error: error && errorMessages.CONTACT_US,
				result: DeliveryHour.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async save(params) {
		try {
			const { error, success } = await Api.post('/delivery-hours', params)
			if (success) {
				Segment.track(Segment.Event.editingeliveryHours, Storage.userId, params)
			}
			return {
				error: error && errorMessages.CONTACT_US,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static get defaultFrom() { return DateHelper.addDay(new Date(), 1) }
	static get defaultTo() { return DateHelper.addDay(DeliveryHour.defaultFrom, 7) }
}