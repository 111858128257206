
import { Alert, AlertPage, Icon, Loader } from 'components';
import './styles.scss'

import React, { useEffect, useRef, useState } from 'react';
import back from '../../../assets/images/arrow/back_bold_gray.svg'
import gmailLogo from '../../../assets/images/google-gmail-logo-small.svg'
import { Steps } from 'antd';
import completedStep from '../../../assets/images/order-step-completed.svg'
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { darkStyles } from 'utils/google-maps-style';
import { Shippment } from 'core/models/shipment';
import { DateHelper, useWidth } from 'utils';
import customMarker from '../../../assets/images/custom-map-marker.svg'
import customMarkerSelected from '../../../assets/images/custom-map-marker-selected.svg'
import { ShippingStatus } from '../Shipping-Status';
import lockrLogo from '../../../assets/images/lockr-logo-small.svg'
import { shippingStatus } from 'utils/shipping-helper';
import { Mail } from 'core';
import moment from 'moment-timezone';
import redBin from '../../../assets/images/red-bin.svg'
import { DeleteShipmentModal } from '../DeleteShipmentModal';
import { useNavigate } from 'react-router-dom';

export function ShippingDetailsModal({markCompleted, shipmentElement = {}, onBack = () => { }, shipmentId = '', handleDeleteShipment = () => { } }) {
    const [map, setMap] = React.useState(null);
    const [historyElement, setHistoryElement] = useState();
    const [loading, setLoading] = useState(false);
    const { width } = useWidth()
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const navigation = useNavigate();

    useEffect(() => {
        getShipmentTrackingHistory()
    }, [])

    const getShipmentTrackingHistory = async () => {
        setLoading(true)
        const data = await Shippment.getShipmentTrackingHistory(shipmentElement.id ?? shipmentId);

        setHistoryElement(data)
        // map.panTo({ lat: parseFloat(), lng: parseFloat() })
        !data && onBack();
        setLoading(false)
    }

    const onLoad = React.useCallback(function callback(map) {
        map?.setZoom(15)
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, [])
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    const handleDelete = async () => {
        await Shippment.deleteShipment(historyElement?.id)
        setDeleteModalVisible(false);
        navigation('/my-orders');
        handleDeleteShipment(historyElement?.id)
    }
    return (
        <div className='shippment-modal-wrapper'>
            <Alert onCancel={() => { onBack() }} hideCloseIcon={true}>
                <AlertPage>
                    {loading ? <Loader /> : <div className='shippment-modal-inner-wrapper'>
                        <div className='alert-back-header-wrapper'>
                            <div className='alert-back' style={{ cursor: 'pointer' }} onClick={onBack}>
                                <img src={back} />
                                Back
                            </div>
                            <div className='logo-status-wrapper'>
                                <div style={{ height: 20, width: 20, borderRadius: 200, background: historyElement?.type == 'lockrScan' ? 'white' : 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={historyElement?.type == 'lockrScan' ? gmailLogo : lockrLogo} style={{ height: historyElement?.type == 'lockrScan' ? 12 : 16, width: historyElement?.type == 'lockrScan' ? 12 : 16 }} />
                                </div>
                                {/* <div className='status-wrapper'>
                                    {historyElement?.histories[historyElement?.histories.length - 1]?.status}
                                    
                                </div> */}
                                <ShippingStatus status={historyElement?.shipmentCompleted ? 'Delivered' : historyElement?.histories[historyElement?.histories.length - 1]?.status ?? 'InfoReceived'} />
                            </div>
                        </div>
                        <div className='order-details-and-status-wrapper'>
                            <div className='order-details'>
                                Order Details
                            </div>
                            <div style={{ marginTop: 24 }}>
                                <Steps responsive={false} style={{ flexDirection: 'row' }} size='small' labelPlacement='vertical' items={[
                                    {
                                        title: <div style={{ position: 'relative', height: 33 }}><div style={{ position: 'absolute', width: 'max-content', left: -55, textAlign: 'left' }}>
                                            <div className='order-step-title'>{width > 567 ? 'Order Confirmed' : 'Confirmed'}</div>
                                            <div className='order-step-subtitle'>{DateHelper.format(historyElement?.createdAt, 'MM/DD/YYYY')}</div></div>
                                        </div>,
                                        icon: <img src={completedStep} />,

                                        status: 'finish'

                                    },
                                    {
                                        title: <div style={{ position: 'relative', height: 33 }}><div style={{ width: 'max-content', marginLeft: 'calc(-50% + 28px)', textAlign: 'center', }}>
                                            <div className='order-step-title'>{historyElement?.shipmentCompleted ? shippingStatus[historyElement?.histories[historyElement?.histories.length - 2]?.status] ?? 'In Transit' : shippingStatus[historyElement?.histories[historyElement?.histories.length - 1]?.status] ?? 'In Transit'}</div>
                                            {historyElement?.courierProvider && <div className='order-step-subtitle'>Via, {historyElement?.courierProvider}</div>}</div>
                                        </div>,
                                        status: (historyElement?.shipmentCompleted ? 'finish' : 'process'),
                                        icon: historyElement?.shipmentCompleted ? <img src={completedStep} /> : <div className='step-number' >2</div>,


                                    },
                                    {
                                        title: <div style={{ position: 'relative', height: 33 }}><div style={{ position: 'absolute', width: 'max-content', left: historyElement?.shipmentCompleted ? -120 : width > 567 ? -187 : -100, textAlign: 'right' }}>
                                            <div className='order-step-title'>{historyElement?.shipmentCompleted ? "Delivered" : width > 567 ? "Delivery Estimate" : "Delivery"}</div>
                                            <div className='order-step-subtitle'>{historyElement?.shipmentCompleted ? moment(DateHelper.format(historyElement?.deliveredAt)).format('MM/DD/YYYY') : DateHelper.format(historyElement?.estimatedDeliverDate, 'MM/DD/YYYY') ?? "Arriving soon"}</div>
                                        </div>
                                        </div>,
                                        icon: historyElement?.shipmentCompleted ? <img src={completedStep} /> : <div className='step-number'> 3</div>,
                                        status: (historyElement?.shipmentCompleted ? 'finish' : 'wait'),
                                        style: { width: 30 }


                                    },



                                ]}>

                                </Steps>
                            </div>
                        </div>
                        <div className='shipment-sender-details'>
                            <div className='sender-details-wrapper'>
                                <div >
                                    <Icon placeholder={historyElement?.domain} src={historyElement?.avatar} />
                                </div>
                                <div>
                                    <div className='brand-name'>{historyElement?.senderName?.replace(`"`, ``)}</div>
                                    <div className='brand-email'>{historyElement?.email}</div>
                                </div>
                            </div>
                            <div className='tracking-details-wrapper'>
                                <div className='tracking'>Tracking #</div>
                                <div className='tracking-id'>{historyElement?.trackingNumber}</div>
                            </div>
                        </div>
                        {historyElement?.shipmentCompleted ? <></> : <div className='shipment-maps-details'>
                            {console.log(shipmentElement)}
                            {shipmentElement?.latestShipmentInfo?.latestLatitude && <div className='shipment-map'>

                                {isLoaded && <GoogleMap
                                    // { lat: 40.741895, lng: -73.989308 }

                                    clickableIcons={false}
                                    center={{ lat: shipmentElement?.latestShipmentInfo?.latestLatitude ? parseFloat(shipmentElement?.latestShipmentInfo?.latestLatitude) : 40.741895, lng: shipmentElement?.latestShipmentInfo?.latestLongitude ? parseFloat(shipmentElement?.latestShipmentInfo?.latestLongitude) : -73.989301 }}

                                    mapContainerStyle={{ height: 148, width: '100%' }}
                                    options={{
                                        backgroundColor: 'black',
                                        gestureHandling: 'greedy',
                                        styles: darkStyles,
                                        mapTypeControl: false, controlSize: false,
                                        fullscreenControlOptions: false,
                                        mapTypeControlOptions: false,

                                        zoomControlOptions: false,
                                        scaleControlOptions: false,
                                        streetViewControl: false,
                                        panControl: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                        keyboardShortcuts: false,
                                        disableDoubleClickZoom: true,
                                        scrollwheel: false,
                                        scaleControl: false,
                                        draggableCursor: false,
                                        draggable: false,
                                        draggingCursor: false,

                                    }}
                                    onCenterChanged={() => { console.log(map?.getCenter()?.lat()) }}

                                    zoom={10}
                                    onLoad={onLoad}
                                    // onLoad={(e)=>{e.panTo({})}}
                                    onUnmount={onUnmount}
                                    children={
                                        <>
                                            {shipmentElement?.latestShipmentInfo?.latestLatitude && <OverlayView
                                                position={{ lat: shipmentElement?.latestShipmentInfo?.latestLatitude, lng: shipmentElement?.latestShipmentInfo?.latestLongitude }}
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                                                <>
                                                    <div style={{ position: 'relative' }}></div>
                                                    <div style={{ position: 'absolute', top: -42, left: -18 }}>
                                                        <img src={customMarker}></img>
                                                        <div style={{ height: 24, width: 24, position: 'absolute', backgroundColor: 'black', top: 7, left: 7, borderRadius: 100, }}></div>
                                                    </div>
                                                </>
                                            </OverlayView>}

                                        </>
                                    }
                                />}
                            </div>}
                            <div className='shipment-buttons'>
                                {(historyElement?.type !== 'Manuel' || historyElement?.type !== 'email-manael') && <div style={{cursor: 'pointer'}} onClick={() => {
                                    historyElement?.type == 'lockrMail' ? Mail.deliver([historyElement?.mailId]) : window.open(
                                        `https://mail.google.com/#search/from:${historyElement?.email} in:anywhere`, "_blank");
                                }} className='button'>
                                    {historyElement?.type == 'lockrMail' ? 'Resend Confirmation Email' : 'View Email'}
                                </div>}
                                {/* <div className='button'>
                                    Contact Sender
                                </div> */}
                                {!historyElement?.shipmentCompleted && <div className='button' style={{cursor: 'pointer'}} onClick={() => { markCompleted(historyElement.id), onBack()}}>
                                    Mark as Delivered
                                </div>}
                            </div>

                        </div>}
                        <div onClick={() => setDeleteModalVisible(true)} className='delete-shipment'><img src={redBin}></img>Delete Shipment</div>
                    </div>}


                </AlertPage>
            </Alert>
            {deleteModalVisible && <DeleteShipmentModal onCancel={() => setDeleteModalVisible(false)} onDelete={handleDelete} />}
        </div>)
}