export class GoogleAds {

	// Event snippet for New LockrMail Registered From Ads conversion page. In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
	static async reportNewLockrmailRegistered(permalink) {
		// console.log('reported New Lockrmail Registered')
		return await new Promise((resolve) => {
			gtag('event', 'conversion', {
				'send_to': 'AW-343960185/0Q5dCIrwrPECEPnUgaQB',
				'permalink': permalink || 'no-special-link',
				'event_callback': (value) => {
					// console.log(value)
					resolve()
				}
			})
		})
	}

	// Event snippet for New Customer Added in Walitlist conversion page. In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
	static async reportNewCustomerAddedInWaitlist() {
		console.log('report New Customer Added In Waitlist')
		return await new Promise((resolve) => {
			gtag('event', 'conversion', {
				'send_to': 'AW-343960185/kBAvCLy43PECEPnUgaQB',
				'event_callback': (value) => {
					// console.log(value)
					resolve()
				}
			})
		})
	}
}