export const adminRoutes = Object.freeze({
	Admin: '/admin',
	lockrScanAdmin: '/lockrScan/admin',

	Category: Object.freeze({
		Admin: Object.freeze({
			Statistics: 'statistics',
			Users: 'users',
			Marketing: 'marketing',
			WebKit: 'webkit',
			Domains: 'domainGroups'
		}),
	})
})