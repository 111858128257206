import React from 'react'

import './styles.scss'

import { config } from 'constants/config'

import {
	TextField,
	InfoAlert,
	SelectDropDown, SelectOption, Icon,
} from 'components'

import { Sender } from 'core'
import {
	ArrayHelper
} from 'utils'

let searchTimer = null
export function AccountAddSenderField({
	onSelectSender = (sender) => { }
}) {
	const inputRef = React.useRef()
	const menuRef = React.useRef()

	const [searchText, setSearchText] = React.useState('')
	React.useEffect(() => {
		callSearchSenders()
	}, [searchText])

	const [searchResults, setSearchResults] = React.useState(null)
	const [assignedSender, setAssignedSender] = React.useState(null)

	function callSearchSenders() {
		if (searchTimer) {
			clearTimeout(searchTimer)
			searchTimer = null
		}

		searchTimer = setTimeout(() => {
			searchSenders()
			searchTimer = null
		}, 500)
	}

	async function searchSenders() {
		const { results = [] } = await Sender.getSenders({ searchText, getAll: 1 })
		const sorted = ArrayHelper.sort(results || [], 'name')
		setSearchResults(sorted)
	}

	function handleSearchResultClick(sender) {
		if (sender.hasGroup && !assignedSender) {
			setAssignedSender(sender)
		} else {
			setAssignedSender(null)
			setSearchText('')
			setSearchResults([])
			onSelectSender(sender)
		}
	}

	return (
		<>
			<div className="account-add-group-field input-menu-parent">
				<TextField
					ref={inputRef}
					type="normal"
					placeholder={<span><span>+</span> Add a sender</span>}
					imodal={<>
						Begin typing a brand name to add Senders to your Group. Learn more about Groups in the <a href={`${config.LOCKR_URL}/faq/how-do-i-add-senders-to-a-group/`} target="_blank">Help Center</a>
					</>}
					value={searchText}
					onChange={(event) => setSearchText(event.target.value)}
					onFocus={() => searchSenders()}
				/>
				{ArrayHelper.isValid(searchResults) &&
					<SelectDropDown
						inputValue={searchText}
						onInputChange={(value) => setSearchText(value)}>
						{searchResults.map((sender, index) => {
							const disabled = sender.hasGroup
							return (
								<SelectOption
									key={sender.id}
									className={`${disabled ? '' : 'add-sender-to-group'}`}
									selectable={false}
									disabled={disabled}
									onClick={() => handleSearchResultClick(sender)}>
									<Icon placeholder={sender.name} src={sender.avatar} />
									<span className="label">{sender.name}</span>
								</SelectOption>
							)
						})}
					</SelectDropDown>
				}
			</div>
			{assignedSender &&
				<InfoAlert
					message="This Sender is already a member of another Group. By adding the Sender to this Group, we will remove them from the original Group. Select Confirm to continue."
					buttons={[
						{
							title: 'Confirm',
							type: 'positive',
							onClick: () => handleSearchResultClick(assignedSender)
						}, {
							title: 'Cancel',
							type: 'negative',
							onClick: () => setAssignedSender(null)
						}
					]}
					onClose={() => setAssignedSender(null)} />
			}
		</>
	)
}