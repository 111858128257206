import React from 'react'

import './styles.scss'
import iconPrev from '../../../../assets/images/arrow_back_gray.svg'
import iconNext from '../../../../assets/images/arrow_next_gray.svg'

import { DiscoverItem } from '../discover-item'

import { range } from '../../../../utils'

const groups = range(0, 20)

export function DigestDiscoverGroup({
	label,
}) {
	return (
		<div className="digest-discover-group">
			<header>
				<div className="title">{label}</div>
				<div className="buttons">
					<button><img src={iconPrev} alt="" /></button>
					<button><img src={iconNext} alt="" /></button>
				</div>
			</header>
			<main>
				{groups.map((group, index) =>
					<DiscoverItem key={index} />
				)}
			</main>
		</div>
	)
}