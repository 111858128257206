import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

import { TextField } from '../..'
import { SelectOption } from '../option'
import { ObjectHelper, DOMHelper } from '../../../utils'
import { zIndexes } from '../../../constants'
import searchIcon from '../../../assets/images/cross/cross-circle-grey.svg';
import CaretPrev from 'assets/images/caret-prev-FFFFFF.svg'

// type: ('vertical'|'horizontal')
export function SelectDropDown({
	test,
	className = "",
	alwaysShow = false,
	type = "vertical",
	focusable = true,
	value,
	inputPlaceholder = '',
	responsive = { breakpoint: 992 },
	style = {},
	mobileTitle,
	onChange = (value) => { },
	children,
	inputValue,
	onInputChange = (value) => { },
	supportSearch = false,
	onSearchValueChange = (val) => { },
	onCancel = () => { },
	onReset,
	mobileHeader,
}) {
	const containerRef = React.useRef()
	const inputRef = React.useRef()
	const searchRef = React.useRef();
	const [searchInputValue, setSearchInputValue] = React.useState('');


	const { width: windowWidth } = useWindowSize()
	const { breakpoint } = responsive
	const { zIndex = zIndexes.SelectDropDown } = style

	React.useEffect(() => {
		initParent()
		focus()
		return () => {
			deinitParent()
		}
	}, [])

	function isMobile() { return windowWidth < breakpoint }

	/**
	 * @returns {HTMLElement}
	 */
	function calcParent() { return containerRef.current?.parentElement }

	function initParent() {
		const parentEl = calcParent()
		if (!parentEl) return

		parentEl.classList.add('select-dropdown-parent')
		if (focusable) {
			parentEl.tabIndex = 0
			parentEl.addEventListener('mousedown', (event) => handleParentClick(event, parentEl))
		}

		const input = parentEl.querySelector('input')
		input?.addEventListener('focus', handleInputFocus)
	}

	function deinitParent() {
		const parentEl = calcParent()
		if (!parentEl) return

		if (focusable) {
			parentEl.tabIndex = null
			parentEl.removeEventListener('mousedown', handleParentClick)
		}

		const input = parentEl.querySelector('input')
		input?.addEventListener('focus', handleInputFocus)
	}

	function focus() {
		if (!isMobile()) return

		inputRef.current?.focus()
	}

	function close() {
		document.activeElement?.blur()
		onCancel()
	}

	/**
	 * @param {Event} event
	 */
	function handleParentClick(event) {

		const parentEl = calcParent()
		if (!parentEl) return
		// if (windowWidth >= breakpoint) { // Desktop
		const input = parentEl.querySelector('input')
		if (!input) event?.preventDefault()
		if (!DOMHelper.closest(event.target, '.select-option')) {
			event.stopPropagation();
			if (DOMHelper.closest(event.target, '.select-dropdown-parent')) {
				setSearchInputValue('');
				onSearchValueChange('');
				if (DOMHelper.closest(event.target, '.select-dropdown-parent') === document.activeElement) {
					setTimeout(() => {
						if (event.target.tagName.toUpperCase() == 'INPUT') {
							return;
						}
						document.activeElement.blur();
					}, 100)
					return;
				}
				parentEl.focus();
			} else {
				parentEl.blur();
			}
		}
		// } else { // Mobile
		// }
	}

	/**
	 * @param {Event} event
	 */
	function handleInputFocus(event) {
		const parentEl = calcParent()
		if (!parentEl) return

		focus()
	}

	function handleContainerClick(event) {
		event.stopPropagation()
		if (!supportSearch) {
			close()
		}

	}

	function handleWrapperClick(event) {
		event.stopPropagation()
	}

	function handleChange(value) {
		onChange(value)
	}

	function handleReset() {
		if (onReset) onReset()
	}
	return (
		<div

			ref={containerRef}
			className={`${test && 'test'} select-dropdown ${isMobile() ? 'mobile' : 'desktop'} ${type} ${className}`}
			style={{ ...style, zIndex }}
			onClick={(event) => handleContainerClick(event)}>

			{mobileTitle &&
				<div className="mobile-title">
					{mobileTitle}
					{onReset && <button className="reset" onClick={() => handleReset()}>Reset</button>}
				</div>
			}
			{mobileHeader && <div className="mobile-select-header-text"><img alt="" src={CaretPrev} />
				<label style={{ marginLeft: 16 }}>{mobileHeader}</label></div>}
			{supportSearch && <div style={{ position: 'relative' }}>
				<input ref={searchRef} value={searchInputValue} onChange={e => { onSearchValueChange(e.target.value); setSearchInputValue(e.target.value) }} className='search-input' placeholder='Search...' />
				<div style={{
					position: 'absolute',
					paddingRight: 16,
					top: 8,
					right: 0,
				}}>
					<img src={searchIcon} onClick={() => { onSearchValueChange(''); }} />
				</div>
			</div>}
			<div className="wrapper" onClick={(event) => handleWrapperClick(event)}>
				{ObjectHelper.isValid(inputValue) &&
					<TextField
						ref={inputRef}
						className="select-input"
						value={inputValue}
						onChange={(event) => onInputChange(event.target.value)} />
				}
				{children && React.Children.map(children, (child, index) => {
					if (child && child.type === SelectOption) {
						const selected = child.props.selected || (value !== undefined && value === child.props.value)
						const newCheckbox = child.props.newCheckbox;
						const onClick = child.props.onClick ? child.props.onClick : (value) => handleChange(value);
						const changeSelectValue = (value) => handleChange(value);
						const props = { ...child.props, alwaysShow, selected, onClick, newCheckbox, changeSelectValue }
						return (<SelectOption key={index} {...props} />)
					}
					return (<>{child}</>)
				})}
			</div>
			{onReset &&
				<div className="reset-container">
					<button className="reset" onClick={() => handleReset()}>Reset</button>
				</div>
			}
		</div>
	)
}