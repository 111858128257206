import { BaseModel } from 'core/models/base'
import { Sender } from 'core/models/sender'

export class DigestMail extends BaseModel {
	subject
	content
	/** @type {Sender} */
	sender

	/** @returns {DigestEmail} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.sender = Sender.fromJSON(json.sender)
		}
		return result
	}
}