import crossIcon from 'assets/images/cross/white.svg';

function FilterChip({ item, onClose, index, hideClose, filterType, border }) {
    if (border == 'linear') {
        return <div style={{}} className='linear-border' >
            <div className='filter-chip-parent-linear-border'>
                <label style={{ textTransform: filterType == 'DOMAIN' && 'capitalize' }} className='filter-chip-text'>{item.label}</label>
                {!hideClose && <img onClick={() => onClose(item)} alt='cross' style={{ marginLeft: 10, cursor: 'pointer' }} src={crossIcon} />}
            </div>
        </div>
    }
    return (
        <div style={{}} className='filter-chip-parent' >
            <label style={{ textTransform: filterType == 'DOMAIN' && 'capitalize' }} className='filter-chip-text'>{item.label}</label>
            {!hideClose && <img onClick={() => onClose(item)} alt='cross' style={{ marginLeft: 10, cursor: 'pointer' }} src={crossIcon} />}
        </div>
    )
}

/**
 * @typedef {{selectedFilters: {label: string, filterType, hideClose}[], onClose:()=>void}} FilterChipTabProps
 * @param {FilterChipTabProps} param0
 */
export function FilterChipTab({
    selectedFilters = [],
    onClose = () => { },
    defaultLabel = '',
}) {
    if (selectedFilters.length) {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                {selectedFilters.map((item, index) => {
                    return (
                        <FilterChip border={item?.border} filterType={item.filterType} key={index} hideClose={item?.hideClose} onClose={onClose} index={index} item={item} />
                    )
                })}
            </div>
        );
    }
    //Default when no filter
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>

            <FilterChip key={2} hideClose={true} item={{ label: 'All Domains' }} />

        </div>
    )
}