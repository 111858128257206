import React from 'react'

import iconFeatured from 'assets/images/help-center-featured.svg'

import { config } from 'constants/config'

export function TileHelpCenter({
	className = "",
}) {
	return (
		<div className={`${className} tile-help-center`}>
			<div>
				<img src={iconFeatured} alt="" />
			</div>
			<div>
				<div className="title">Help Center</div>
				<div className="description">
					Browse videos, commonly asked questions, article tips and more.
					<a rel="noopener noreferrer" href={config.HELP_CENTER_URL} target="_blank">Check it out</a>
				</div>
			</div>
		</div>
	)
}