import React, { useState, useEffect } from 'react'

import './styles.scss'

import {
	Alert, AlertButton, AlertButtonContainer, AlertPage, AlertTitle, AlertMessage,
	TextField,
} from 'components'

import { AdminBlockedDomain } from 'core'
import { isDomain } from 'utils'

export function AddBlockedDomainAlert({
	onClose = (updated) => { }
}) {
	const [domain, setDomain] = useState('')
	const [error, setError] = useState(null)
	useEffect(() => {
		setError(null)
	}, [domain])

	async function handleAdd() {
		if (!isDomain(domain)) {
			setError('Invalid email address.')
			return
		}

		const { error } = await AdminBlockedDomain.add({ domain })
		if (error) {
			setError(error)
			return
		}

		onClose(true)
	}

	return (
		<Alert className="admin-add-blocked-domain" onCancel={() => onClose()}>
			<AlertPage>
				<AlertTitle value="Add blocked domain" />
				<div className="input">
					<TextField
						type="large"
						placeholder="Domain"
						value={domain}
						onChange={event => setDomain(event.target.value.trim())}
						onNext={() => handleAdd()}
					/>
				</div>
				{error && <div className="error">{error}</div>}
				<AlertButtonContainer>
					<AlertButton type="positive" disabled={!isDomain(domain)} onClick={handleAdd}>Add</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}