import React from 'react'
import './styles.scss'

import {
    GoogleSignInAlert,
    Icon,
    UnsubscriptionAlert,
    Checkbox,
    IModal,
} from 'components'
import { Segment, User, useAuth } from 'core'
import { Storage, isMobile } from 'utils';
import eyeOpen from '../../../../assets/images/eye-open.svg'
import eyeClosed from '../../../../assets/images/eye-closed.svg'
import { HideSenderAlert } from 'components/alert/hidde-sender-alert';
const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;
import iconCheckboxChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconCheckboxUnchecked from 'assets/images/checkbox-gray-0.svg'
import externalIcon from 'assets/images/external-arrow.svg'
import { notification } from 'antd';
import { LockrScanSegment } from 'core/analytics/segment-lockrscan';

export function SenderListItem({
    sender,
    getSenderList,
    viewState = 1,
    selected = false,
    onSelect = (value) => { },
    highlight,
    googleOauthLink = null,
    ipAddr = null,
    permalink = null,
}) {
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [unsubscriptionAlert, setUnbubscriptionAlert] = React.useState(false);
    const [hiddenAlert, setHiddenAlert] = React.useState(false);
    const [isUnSubscribed, setIsUnsubscribed] = React.useState(sender.isUnSubscribed);

    const [tempSender, setTempSender] = React.useState();

    const { currentLockrScanUser } = useAuth()
    // const [googleOauthLink, setGoogleOauthLink] = React.useState("");

    React.useEffect(() => {
        setTempSender()
    }, [sender])
    // const getGoogleOauthLink = async () => {
    //     const { error, data } = await User.getLockrScanAuth();
    //     setGoogleOauthLink(data?.googleOAuthUrl);
    // }

    async function hideSenders() {
        let response = await User.hideSenders({ emails: [sender.email], hidden: !sender.isHidden })
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
            const message = !sender.isHidden ? "Sender Successfully Hidden" : 'Sender Successfully Unhidden'
            openNotification(message + ":", message);
            setTempSender({ ...sender, isHidden: !sender.isHidden })
        }
        getSenderList(false);
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (message, mobileMessage) => {
        api.open({
            message: <div className='toast-font-style'><svg className="tablet" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9043 1.36754L5.59614 10.3791L1.7964 7.0883" stroke="#FFFFFF" stroke-linecap="round" />
            </svg>{isMobile ? <>{mobileMessage ? mobileMessage : message ? message : 'Unsubscribed Successfully'}</> : <>{message ? message : 'Successfully unsubscribed from:'}<div className='toast-sender-name'>{sender.email?.split('@')[1].split('.')[sender.email?.split('@')[1].split('.').length - 2]}</div></>}</div>,
            placement: 'bottom',
            closeIcon: <></>,
            className: 'lockr-toast-message',
        });
    };

    async function moveToLockrArchive() {

        let response = await User.moveSenderEmailsToLockrArchive({
            emails: [sender.email]
        })
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            if (sender?.isArchive) {
                // Segment.track("User Disabled lockrArchive");
                LockrScanSegment.track(LockrScanSegment.Event.lockrArchiveDisabled, currentLockrScanUser.id, { emails: [sender.email] })
            }
            else {
                // Segment.track("User Enabled lockrArchive");
                LockrScanSegment.track(LockrScanSegment.Event.lockrArchiveEnabled, currentLockrScanUser.id, { emails: [sender.email] })
            }

            setErrorAlert(false)
            getSenderList(false)
        }

    }
    async function unsubscribe(fromEmail, unsubscribeLink) {
        let response = await User.unsubscribe({
            emails: [fromEmail],
            unsubscribeLink: unsubscribeLink
        })
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false);
            setIsUnsubscribed(true);
            openNotification();
            LockrScanSegment.track(LockrScanSegment.Event.unsubscribeSender, currentLockrScanUser?.id, { emails: [fromEmail] })
        }
        getSenderList(false)

    }

    const infoRef = React.useRef();
    return (
        <div
            id={sender.id}
            className={`dashboard-sender-list-item-lockrscan ${highlight ? 'lockrScan-dashboard-list-highlight' : ''}`}
        >
            {contextHolder}
            {errorAlert && <GoogleSignInAlert onButtonClick={async () => {
                // const { error, data } = await User.getLockrScanAuth();
                // if (error) {
                //     setError(error)
                //     return;
                // }
                if (Storage.onlyLockrScanUser == "true") {
                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                    );
                } else {

                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                    );
                }
                setErrorAlert(false);
            }} onCancel={() => { setErrorAlert(false) }}></GoogleSignInAlert>}
            <Checkbox
                id={sender.id}
                iconChecked={iconCheckboxChecked}
                iconUnchecked={iconCheckboxUnchecked}
                checked={selected}
                onChange={onSelect} />
            <div className='avatar-info'>
                <Icon className="avatar" src={sender.avatar} placeholder={sender.senderName?.replace(`"`, ``)} />
                <div className="info">
                    <div className="name">{sender.email?.split('@')[1].split('.')[sender.email?.split('@')[1].split('.').length - 2]}</div>
                    <div className="email" >{sender.email}  <img className='external-icon' style={{ cursor: 'pointer' }} onClick={() => {
                        window.open(
                            `https://mail.google.com/#search/from:${sender?.email} in:anywhere`, "_blank");
                    }} src={externalIcon}></img> &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{sender.mailCount} {sender.mailCount != 1 ? "emails" : "email"}</div>
                    {/* <span className="total-emails">{sender.mailCount} emails</span> */}
                </div>
            </div>
            <div className="actions">
                <div className='actions-wrapper'>
                    {sender.unsubscribeLink && (!isUnSubscribed ? <button ref={infoRef} onClick={() => {
                        // if (sender.unsubscribeLink?.startsWith("http")) {
                        //     window.open(sender.unsubscribeLink);
                        // }
                        // unsubscribe(sender.email, sender?.unsubscribeLink);
                        setUnbubscriptionAlert(true)
                    }}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7" cy="7" r="6.5" transform="rotate(-180 7 7)" stroke="white" stroke-linecap="round" />
                            <path d="M2.5 2.5L11.5 11.5" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>
                        <span>Unsubscribe</span>
                        {sender?.unsubscribeReactivated && <IModal anchorEl={infoRef.current}>Prior attempts to unsubscribe have not been respected - please try again. We suggest enabling lockrArchive to have emails from this Sender skip your inbox.</IModal>}
                    </button> : <button className='disabled'>
                        <svg className="tablet" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9043 1.36754L5.59614 10.3791L1.7964 7.0883" stroke="#8B8B8B" stroke-linecap="round" />
                        </svg>
                        <svg className="mobile" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <circle cx="8" cy="8" r="7.5" transform="rotate(-180 8 8)" stroke="white" stroke-linecap="round" />
                                <path d="M2.85712 2.85742L13.1428 13.1431" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
                            </g>
                        </svg>
                        <span>Unsubscribed</span>
                    </button>)}
                    {sender.isArchive ? <button onClick={() => { moveToLockrArchive() }}>
                        <svg className="tablet" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9043 1.36754L5.59614 10.3791L1.7964 7.0883" stroke="#8B8B8B" stroke-linecap="round" />
                        </svg>
                        <svg className="mobile" width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13804 15.2637L2.5519 9.0007C2.56669 8.93519 2.62489 8.88867 2.69204 8.88867H13.308C13.3751 8.88867 13.4333 8.93519 13.4481 9.0007L14.862 15.2637H10.8667C10.4801 15.2637 10.1667 15.5771 10.1667 15.9637V17.8887H5.83333V15.9637C5.83333 15.5771 5.51993 15.2637 5.13333 15.2637H1.13804ZM1 16.2593V20.745C1 20.8244 1.06432 20.8887 1.14367 20.8887H14.8563C14.9357 20.8887 15 20.8244 15 20.745V16.2593C14.9782 16.2622 14.9559 16.2637 14.9333 16.2637H11.1667V18.1887C11.1667 18.5753 10.8533 18.8887 10.4667 18.8887H5.53333C5.14673 18.8887 4.83333 18.5753 4.83333 18.1887V16.2637H1.06667C1.04406 16.2637 1.02181 16.2622 1 16.2593ZM0 15.8912C0 15.8064 0.00941567 15.722 0.0280737 15.6393L1.57645 8.78049C1.69417 8.25902 2.15745 7.88867 2.69204 7.88867H13.308C13.8426 7.88867 14.3058 8.25902 14.4236 8.78049L15.9719 15.6393C15.9906 15.722 16 15.8064 16 15.8912V20.745C16 21.3766 15.488 21.8887 14.8563 21.8887H1.14367C0.512037 21.8887 0 21.3766 0 20.745V15.8912Z" fill="white" />
                            <g filter="url(#filter0_d_9446_19836)">
                                <circle cx="16" cy="6" r="4" fill="#2E70D2" />
                            </g>
                            <defs>
                                <filter id="filter0_d_9446_19836" x="10" y="0" width="12" height="12" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_9446_19836" />
                                    <feOffset />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.17875 0 0 0 0 0.439228 0 0 0 0 0.825 0 0 0 0.3 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9446_19836" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9446_19836" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                        <span>lockrArchive</span>
                    </button> :
                        <button onClick={() => { moveToLockrArchive() }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13804 8.26392L2.5519 2.00095C2.56669 1.93544 2.62489 1.88892 2.69204 1.88892H13.308C13.3751 1.88892 13.4333 1.93544 13.4481 2.00095L14.862 8.26392H10.8667C10.4801 8.26392 10.1667 8.57732 10.1667 8.96392V10.8889H5.83333V8.96392C5.83333 8.57732 5.51993 8.26392 5.13333 8.26392H1.13804ZM1 9.25951V13.7452C1 13.8246 1.06432 13.8889 1.14367 13.8889H14.8563C14.9357 13.8889 15 13.8246 15 13.7452V9.25951C14.9782 9.26242 14.9559 9.26392 14.9333 9.26392H11.1667V11.1889C11.1667 11.5755 10.8533 11.8889 10.4667 11.8889H5.53333C5.14673 11.8889 4.83333 11.5755 4.83333 11.1889V9.26392H1.06667C1.04406 9.26392 1.02181 9.26242 1 9.25951ZM0 8.8914C0 8.80667 0.00941567 8.72221 0.0280737 8.63956L1.57645 1.78074C1.69417 1.25926 2.15745 0.888916 2.69204 0.888916H13.308C13.8426 0.888916 14.3058 1.25926 14.4236 1.78074L15.9719 8.63956C15.9906 8.72221 16 8.80667 16 8.8914V13.7452C16 14.3769 15.488 14.8889 14.8563 14.8889H1.14367C0.512037 14.8889 0 14.3769 0 13.7452V8.8914Z" fill="white" />
                            </svg>
                            <span>lockrArchive</span>
                        </button>}
                    <img src={tempSender ? tempSender.isHidden ? eyeClosed : eyeOpen : sender.isHidden ? eyeClosed : eyeOpen} style={{ cursor: 'pointer' }} onClick={() => { !sender.isHidden ? setHiddenAlert(true) : hideSenders(); }}></img>
                </div>
            </div>
            {
                unsubscriptionAlert && <UnsubscriptionAlert onConfirm={() => {
                    if (sender.unsubscribeLink?.startsWith("http")) {
                        window.open(sender.unsubscribeLink);
                    }
                    unsubscribe(sender.email, sender?.unsubscribeLink);
                    setUnbubscriptionAlert(false)
                }} onClose={() => setUnbubscriptionAlert(false)}></UnsubscriptionAlert>
            }
            {hiddenAlert && <HideSenderAlert onOk={() => { setHiddenAlert(false); hideSenders() }} onClose={() => { setHiddenAlert(false); }}></HideSenderAlert>}
        </div >
    )
}