import React from 'react'

import './styles.scss'
import iconDelete from 'assets/images/delete/gray.svg'
import iconDeleteHover from 'assets/images/delete/green.svg'

import {
	SenderInputField,
	Icon, IconButton,
} from 'components'

import {
	Sender, DeliveryHour,
	useDeliveryHours,
} from 'core'
import { isEmail } from 'utils'

/**
 * @typedef {{className: string, deliveryHour: DeliveryHour, onChange: *, onSave: *}} ExcludedElProps
 * @param {ExcludedElProps} param0
 */
export function ExcludedEl({
	className = '',
}) {
	const { excludedSenders, setExcludedSenders } = useDeliveryHours()

	function handleExcludedSenders(value) {
		setExcludedSenders(value)
	}

	function handleDeleteSender(sender) {
		const senders = excludedSenders.filter(x => typeof sender === "string" ? x !== sender : x.id !== sender.id)
		setExcludedSenders(senders)
	}

	async function handleAddEmail(email) {
		if (!isEmail(email) || excludedSenders.some(x => x === email || x?.email === email)) {
			return
		}

		const senders = [...excludedSenders, email]
		setExcludedSenders(senders)
	}

	return (
		<div className={`delivery-hours-modal-excluded ${className}`}>
			<div>Allow emails from Senders when Delivery Hours are enabled:</div>
			<div className="form-container">
				<SenderInputField
					allowAddEmail={true}
					placeholder={<><span>+</span> Add a Sender</>}
					senders={excludedSenders}
					checkDisableFn={(sender) => { return excludedSenders.some(x => typeof x === "string" ? x === sender : x.email === sender.email) }}
					onChange={(senders) => handleExcludedSenders(senders)}
					onAddEmail={(email) => handleAddEmail(email)} />
			</div>
			<div className='sender-list'>
				{excludedSenders.map((sender, index) => {
					return (
						<SenderListItem
							key={index}
							className="sender-list-item"
							sender={sender}
							onDelete={() => handleDeleteSender(sender)} />
					)
				})}
			</div>
		</div>
	)
}

/**
 * @typedef {{className: string, sender: Sender, onDelete: Function}} SenderListItemProps
 * @param {SenderListItemProps} param0
 */
function SenderListItem({
	className = '',
	sender,
	onDelete = () => { }
}) {
	async function handleDelete() {
		onDelete()
	}

	return (
		<div className={`${className}`}>
			{(typeof sender === 'string') ?
				<React.Fragment>
					<Icon className="avatar" placeholder={sender} />
					<div className='name'>{sender}</div>
				</React.Fragment>
				:
				<React.Fragment>
					<Icon className="avatar" src={sender.avatar} placeholder={sender.name} />
					<div className='name'>{sender.name}</div>
				</React.Fragment>
			}
			<IconButton className="delete"
				normal={iconDelete}
				hover={iconDeleteHover}
				onClick={() => handleDelete()} />
		</div>
	)
}