import React from 'react'

import './styles.scss'

export function RadialCheckbox({
	className = "",
	label = "",
	value = false,
	onChange = (value) => { },
	children,
}) {
	/** @param {MouseEvent} event */
	function handleClick(event) {
		onChange(!value)
	}

	return (
		<div className={`${className} radial-check-box ${value && 'checked'}`} onClick={handleClick}>
			<span className="checkbox"></span>
			{(label || children) && <div className="label">{label || children}</div>}
		</div>
	)
}