import { BaseModel } from 'core/models/base'
import { Api, Sender, Segment } from 'core'
import { errorMessages, errorCodes } from 'constants/error'
import { Storage } from 'utils'

export class SenderGroup extends BaseModel {
	userId
	name
	senderCount
	senders = []

	static placeholderName = 'Unnamed Group'

	/** @returns {SenderGroup} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			if (json?.senders) {
				result.senders = Sender.fromJSONArray(json.senders)
				result.senderCount = result.senders.length
			} else {
				result.senderCount = json?.senderCount ?? 0
			}
		}
		return result
	}

	static async getAll() {
		try {
			const { error, data } = await Api.get('/sender-groups')
			return {
				error: error ? errorMessages.CONTACT_US : null,
				results: data ? SenderGroup.fromJSONArray(data) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getDetail(id) {
		try {
			const { error, data } = await Api.get(`/sender-groups/detail/${id}`)
			return {
				error: error ? errorMessages.CONTACT_US : null,
				result: data ? SenderGroup.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	/**
	 * @param {string} name
	 * @param {Array<Sender>} name
	 * @returns
	 */
	static async create(name, senderIds = []) {
		try {
			const filteredSenderIds = senderIds.map(x => x instanceof Sender ? x.id : x)
			const { error, errorCode, data, success } = await Api.post('/sender-groups/create', { name, senderIds: filteredSenderIds })
			if (success) {
				Segment.track(Segment.Event.creatingGroup, Storage.userId, { name, senderIds });
			}
			if (errorCode === errorCodes.ALREADY_EXIST) return { error: errorMessages.SENDER_GROUP_DUPLICATED_NAME }
			return {
				error: error ? errorMessages.CONTACT_US : null,
				result: data ? SenderGroup.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async update(id, name) {
		try {
			const { error, data, success } = await Api.post('/sender-groups/update', { id, name })
			if (success) {
				Segment.track(Segment.Event.editingGroup, Storage.userId, { id, name });
			}
			return {
				error: error ? errorMessages.CONTACT_US : null,
				result: data ? SenderGroup.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async delete(id) {
		try {
			const { error, success } = await Api.post('/sender-groups/delete', { id })
			if (success) {
				Segment.track(Segment.Event.deletingGroup, Storage.userId, { id })
			}
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async addSender(senderGroupId, senderIds) {
		try {
			const { error, success } = await Api.post('/sender-groups/add-sender', { senderGroupId, senderIds });
			if (success) {
				Segment.track(Segment.Event.addingSenderToGroup, Storage.userId, { senderGroupId, senderIds })
			}
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	get domainCount() { return this.senders ? this.senders.length : 0 }
	get formattedSenderCount() {
		if (this.domainCount === 1) return '1 sender'
		return `${this.domainCount} senders`
	}
}