import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'
import iconNext from 'assets/images/arrow/next-gray.svg'

import { Digest, useDigests } from 'core'
import { routes, redirectTo } from 'routes'
import { DateHelper } from 'utils'

/**
 * @typedef {{digest: Digest}} DigestListItemProps
 * @param {DigestListItemProps} param0
 */
export function DigestListItem({
	digest,
}) {
	const { currentDigest, setEnableDigestSave } = useDigests()

	const { id, name, receivedDates = [] } = digest

	const [isExpanded, setExpanded] = React.useState(false)

	return (
		<div className="list-item">
			<Link
				className={`list-item-menu ${id === currentDigest?.id && 'selected'}`}
				onClick={() => {
					setEnableDigestSave(false);
				}}
				to={redirectTo({ route: routes.Digest, category: routes.Category.Digest['My Digests'], searches: { digestId: id } })}>
				<span className="label">{name}</span>
				<img className="arrow-next" src={iconNext} alt="" />
			</Link>
			{id === currentDigest?.id &&
				<div className={`received-date-list ${isExpanded && 'expanded'}`}>
					{receivedDates.map((receivedDate, index) =>
						<Link
							key={index}
							className="received-date-item"
							to={redirectTo({ route: routes.ViewDigestArchives, searches: { detailId: receivedDate.detailId } })}>
							{DateHelper.format(receivedDate.date, "MMM, DD, YYYY")}
						</Link>
					)}
					{receivedDates.length >= 5 &&
						<button
							className="show-more"
							onClick={() => setExpanded(!isExpanded)}>
							{isExpanded ? 'Show less' : 'Show more'}
						</button>
					}
				</div>
			}
		</div>
	)
}