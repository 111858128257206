import React from 'react'

import './styles.scss'
import iconDrag from '../../assets/images/drag-green.svg'
import iconNoDrag from '../../assets/images/no-drag-gray.svg'
import iconClear from '../../assets/images/cross/gray-16.svg'

import {
	Selector, SelectorOption,
} from '../menu'

export function FilterKeywordContainer({
	index,
	keyword,
	dragging = false,
	onClear = () => { },
	onChange = (value) => { }
}) {
	const keywordRef = React.useRef()

	function handleOp(value) {
		keyword.op = value
		onChange(keyword)
	}

	function handleKeyword(value) {
		keyword.keyword = value
		onChange(keyword)
	}

	function handleAction(value) {
		keyword.action = value
		onChange(keyword)
	}

	return (
		<div className="keyword">
			<div className="drag" ><img src={dragging ? iconDrag : iconNoDrag} alt="" /></div>
			<div className="value-container">
				{index > 0 &&
					<Selector
						options={[
							new SelectorOption({ label: 'And', value: 'and' }),
							new SelectorOption({ label: 'Or', value: 'or' }),
						]}
						value={keyword.op}
						onChange={value => handleOp(value)}
					/>
				}
				<div className="input">
					<input
						ref={keywordRef}
						value={keyword.keyword || ''}
						onChange={event => handleKeyword(event.target.value)} />
				</div>
				<Selector
					options={[
						new SelectorOption({ label: 'Contains', value: 'contains' }),
						new SelectorOption({ label: 'Does not contain', value: 'notContain' }),
						new SelectorOption({ label: 'Greater than', value: 'greaterThan' }),
						new SelectorOption({ label: 'Less than', value: 'lessThan' }),
					]}
					value={keyword.action}
					onChange={value => handleAction(value)}
				/>
			</div>
			<div className="clear" onClick={() => onClear()}>
				<img src={iconClear} alt="" />
			</div>
		</div>
	)
}