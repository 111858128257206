import React from 'react'
import { getTimeZones, rawTimeZones } from '@vvo/tzdb'

import './styles.scss'

import {
	Select, SelectOption
} from 'components'
import { ArrayHelper, DateHelper } from 'utils'

export function TimezoneSelect({
	className = '',
	value,
	onChange = (value) => { }
}) {

	function parseRawFormat({ name, rawFormat }) {
		const dividerIdx = rawFormat.indexOf(' ')
		let offset = rawFormat.substr(0, dividerIdx).trim()
		offset = offset.replace(')', '').replace(' ', '')
		const label = rawFormat.replace(offset, '').trim()
		const result = `GMT(${offset}) ${label}`
		// console.log(name, result)
		return result
	}

	function timezones() {
		const results = []
		let timezones = getTimeZones()
		timezones = ArrayHelper.sort(timezones, 'rawOffsetInMinutes')
		for (let timezone of timezones) {
			try {
				const { name, alternativeName, rawFormat } = timezone
				results.push({
					value: name,
					name: parseRawFormat(timezone),
				})
			} catch (error) {
				console.log(error)
			}
		}
		return results
	}

	function handleSelect(value) {
		// console.log(DateHelper.isDSTTimezone(value))
		// console.log(DateHelper.isDST())
		onChange(value)
	}

	return (
		<Select
			className={`${className} timezone-select`}
			value={value}
			onChange={(value) => handleSelect(value)}>
			{timezones().map((timezone, index) =>
				<SelectOption
					key={index}
					value={timezone.value}>
					{timezone.name}
				</SelectOption>
			)}
		</Select>
	)
}