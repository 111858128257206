import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { errorMessages } from 'constants/error'

import {
	isValidText, isUrl,
	DateHelper
} from 'utils'

export class AdminSpecialLink extends BaseModel {
	permalink
	startAt
	expireAt
	limit
	userCount
	isRestrictTime
	isRestrictRegistration

	static create({ permalink = "" }) {
		return AdminSpecialLink.fromJSON({ permalink })
	}

	/** @returns {AdminSpecialLink} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.startAt = DateHelper.parse(json.startAt)
			result.expireAt = DateHelper.parse(json.expireAt)
			result.createdAt = DateHelper.parse(json.createdAt)
			result.updatedAt = DateHelper.parse(json.updatedAt)
		}
		return result
	}

	static isValidLink(value) {
		return isValidText(value, true) && isUrl(`https://app.lockrmail.com/${value}`)
	}

	static async create(params) {
		try {
			const { error, data } = await Api.post('/special-links', params)
			return {
				error,
				result: data ? AdminSpecialLink.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async search(params) {
		const { searchText, pageSize = 25, pageIndex = 0, sortBy } = params
		try {
			const { error, data } = await Api.get('/special-links', { searchText, pageNum: pageIndex + 1, pageSize, sortBy })
			return {
				error,
				count: data ? data.count : 0,
				results: data ? AdminSpecialLink.fromJSONArray(data.rows) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async check(permalink) {
		try {
			const { error, data } = await Api.post('/check-special-link', { permalink })
			return {
				error,
				result: AdminSpecialLink.fromJSON(data),
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getAll() {
		try {
			const { error, data } = await Api.get('/special-links')
			return {
				error,
				results: data ? AdminSpecialLink.fromJSONArray(data) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async get(id) {
		try {
			const { error, data } = await Api.get(`/special-links/${id}`)
			return { error, result: data ? AdminSpecialLink.fromJSON(data) : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	async update(params) {
		try {
			const { error, data } = await Api.put(`/special-links/${this.id}`, params)
			return {
				error,
				result: data ? AdminSpecialLink.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	async delete() {
		try {
			const { error } = await Api.delete(`/special-links/${this.id}`)
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	get isExpired() { return this.expireAt < new Date() || this.userCount >= this.limit }
}