import { useNavigate } from "react-router-dom"
import { routes } from "routes"
import { Alert, AlertPage, AlertTitle } from "./core"

export const AlreadyAccountExists = ({ email }) => {
    const navigate = useNavigate()
    return (
        <Alert onCancel={() => {
            navigate(routes.Login)
        }}>
            <AlertPage>
                <AlertTitle>Account Exists</AlertTitle>
                <div style={{ fontSize: 16, lineHeight: '22px', marginTop: 24 }}>
                    A lockrMail account for <span style={{ fontWeight: 'bold' }}>{email}</span> already exists. Please login to continue.
                </div>
                <div style={{ fontWeight: 'bold', color: '#61CEF7', cursor: 'pointer', marginTop: 28 }} onClick={() => { navigate(routes.Login) }}>
                    Sign in
                </div>
            </AlertPage>
        </Alert>
    )
}