import { plainToClass } from 'class-transformer'

export class AdminRevenueDrivesState {
	total
	changePercent
	ticks = []

	static fromJSON(json) {
		const result = plainToClass(AdminRevenueDrivesState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminRevenueDriveStateTick.fromJSON(tick.current),
				'previous': AdminRevenueDriveStateTick.fromJSON(tick.previous),
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminRevenueDrivesState.fromJSON(json))
	}
}

export class AdminRevenueDriveStateTick {
	from
	to
	emailClicks
	extensionClicks
	userWithDiscounts
	webClicks

	static fromJSON(json) {
		return plainToClass(AdminRevenueDriveStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminRevenueDriveStateTick.fromJSON(json))
	}
}