import React from 'react'

import './styles.scss'
import iconMore from '../../../../assets/images/icon_options.svg'
import iconSample1 from '../../../../assets/images/user-2.jpg'
import iconSample2 from '../../../../assets/images/user-19.jpg'

import {
	Icon,
} from '../../../../components'

export function DiscoverItem({

}) {
	return (
		<div className="discover-item">
			<div className="header">
				<div className="icon-list">
					<div className="icon-item"><Icon src={iconSample1} /></div>
					<div className="icon-item"><Icon placeholder="A" /></div>
					<div className="icon-item"><Icon src={iconSample2} /></div>
					<div className="icon-item"><Icon placeholder="B" /></div>
				</div>
				<div className="menu-container">
					<img src={iconMore} alt="" />
				</div>
			</div>
			<div className="name">Digest Name</div>
			<div className="sender-count">4 Senders</div>
			<div className="description">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur adipiscing elit, sed do eiusmodconsectetur adipiscing elit, sed do eiusmodconsectetur adipiscing elit, sed do eiusmod
			</div>
		</div>
	)
}