import React from 'react'

import './styles.scss'
import iconClear from 'assets/images/cross-26282A.svg'

import {
	Alert, AlertPage, AlertTitle,
	Loader,
	HMenuDatePicker,
	AlertButtonContainer,
	AlertButton,
	Selector, SelectorOption,
	Switch,
} from 'components'
import { TagInputField } from './tag-input-field'

import {
	useAlert, User, AdminUser, AdminUserState, AdminUserTag,
	useAdminDeletedUsers,
} from 'core'
import {
	isValidText,
	DateHelper,
	ArrayHelper
} from 'utils'

/**
 * @typedef {{user: AdminUser, onClose: Function}} AdminUserInfoAlertProps
 * @param {AdminUserInfoAlertProps} param0
 */
export function AdminUserInfoAlert({
	user,
	onClose = (updated) => { }
}) {
	const { error, setError, setAlert } = useAlert()
	const { adminDeletedUsersReload } = useAdminDeletedUsers()

	const [isLoading, setLoading] = React.useState(false)
	const [startDate, setStartDate] = React.useState(DateHelper.addDay(new Date(), -7))
	const [endDate, setEndDate] = React.useState(new Date())
	/** @type {[AdminUserState, Function]} */
	const [userState, setUserState] = React.useState(null)
	const [tag, setTag] = React.useState('')
	const [hiddenFeatures, setHiddenFeatures] = React.useState([])
	const [isModified, setModified] = React.useState(false)
	const [isUpdated, setUpdated] = React.useState(false)

	// Special Links
	const [specialLinks, setSpecialLinks] = React.useState([])
	const [selectedSpecialLink, setSelectedSpecialLink] = React.useState('')

	// Wordpress Links
	const [wordpressLinks, setWordpressLinks] = React.useState([])
	const [selectedWordpressLink, setSelectedWordpressLink] = React.useState('')

	// User Tags
	const [userTags, setUserTags] = React.useState([])
	const [selectedUserTags, setSelectedUserTags] = React.useState([])

	React.useEffect(() => { loadUserTags() }, [])
	React.useEffect(() => { loadUserState() }, [user, startDate, endDate])

	React.useEffect(() => {
		if (userState) {
			setSelectedSpecialLink(userState.specialLink)
			setSelectedWordpressLink(userState.wordpressLink)
			setSelectedUserTags(userState.tags)
			setHiddenFeatures(userState.hiddenFeatures)
		}
	}, [userState])

	// check updated
	React.useEffect(() => {
		if (userState) {
			setModified((selectedSpecialLink !== userState?.specialLink
				|| selectedWordpressLink !== userState.wordpressLink
				|| !ArrayHelper.equals(selectedUserTags, userState.tags)
				|| !ArrayHelper.equals(hiddenFeatures, userState?.hiddenFeatures)))
		}
	}, [selectedSpecialLink, selectedWordpressLink, selectedUserTags, hiddenFeatures])

	async function loadUserState() {
		if (!user) return

		setLoading(true)
		const { error, result } = await AdminUserState.get(user.id, startDate, endDate)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setUserState(result)
	}

	async function loadUserTags() {
		const { specialLinks = [], wordpressLinks, userTags } = await AdminUserTag.getAll()

		setSpecialLinks(specialLinks)
		setWordpressLinks(wordpressLinks)
		setUserTags(userTags)
	}

	async function handleSetTag() {
		if (!isValidText(tag)) return

		handleUserTag(tag)
		setTag("")
	}

	async function handleSpecialLink(value) {
		setSelectedSpecialLink(selectedSpecialLink === value ? null : value)
	}

	async function handleWordpressLink(value) {
		setSelectedWordpressLink(selectedWordpressLink === value ? null : value)
	}

	async function handleUserTag(value) {
		let results = []
		if (selectedUserTags.some(e => e === value)) {
			results = selectedUserTags.filter(e => e !== value)
		} else {
			results = [...selectedUserTags, value]
		}

		setSelectedUserTags(results)
	}

	function handleHiddenFeature(feature) {
		let results = hiddenFeatures
		if (hiddenFeatures.includes(feature)) results = hiddenFeatures.filter(x => x !== feature)
		else results = [...hiddenFeatures, feature]

		setHiddenFeatures(results)
	}

	async function handleSave() {
		let params = {
			userId: user.id,
			tags: selectedUserTags,
			wordpressLink: selectedWordpressLink,
			specialLink: selectedSpecialLink
		}

		const { error, result } = await AdminUserTag.update(params)
		if (error) {
			setError(error)
			return
		}

		await AdminUser.enableHiddenFeatures({ userIds: [user.id], hiddenFeatures })

		loadUserState()
		loadUserTags()

		setUpdated(true)
	}

	async function handleBlock() {
		const { error } = await AdminUser.blockUsers([user])

		if (error) {
			setError(error)
			return
		}

		adminDeletedUsersReload()

		onClose(true)
	}

	async function handleDelete() {
		const { error } = await AdminUser.deleteUsers([user])

		if (error) {
			setError(error)
			return
		}
		onClose(true)
	}

	async function alertDelete() {
		setAlert({
			message: 'Are you sure you want to delete this user?',
			buttons: [
				{ type: 'positive', label: 'Yes', onClick: async () => await handleDelete() },
				{ type: 'negative', label: 'No', },
			]
		})
	}

	async function alertBlock() {
		setAlert({
			message: 'Are you sure you want to block this user?',
			buttons: [
				{ type: 'positive', label: 'Yes', onClick: async () => await handleBlock() },
				{ type: 'negative', label: 'No', },
			]
		})
	}
	async function handleResetPostfixPassword(email) {
		const { error } = await AdminUser.resetPostFixPassword(email);

		if (error) {
			setError(error)
			return
		}
		onClose(true)
	}

	return (
		<Alert className={`admin-userinfo-alert ${error && 'd-none'}`} onCancel={() => onClose(isUpdated)}>
			<AlertPage>
				<AlertTitle value={user.name} />
				<div className="admin-userinfo-alert-content">
					<div className="email">{user.email}</div>
					<div className="member-since">{user.memberSince}</div>
					<div className="last-login">{user.lastLogin}</div>
					<div className="option-container">
						<HMenuDatePicker
							position="first"
							initialLabel="Specific dates"
							date={startDate}
							onSelect={date => setStartDate(date)}
						/>
						<span>to</span>
						<HMenuDatePicker
							position="second"
							baseDate={startDate || new Date()}
							date={endDate}
							onSelect={date => setEndDate(date)}
						/>
					</div>
					{selectedSpecialLink &&
						<div className="tag-container">
							<div>Special Links</div>
							<div className="tag-list">
								{[selectedSpecialLink].map((tag, index) => {
									const selected = selectedSpecialLink === tag
									return (
										<div
											key={index}
											className={`tag-item ${selected && 'selected'}`}>
											<span>{tag}</span>
											<button className="clear" onClick={() => handleSpecialLink(tag)}><img src={iconClear} alt="" /></button>
										</div>
									)
								})}
							</div>
						</div>
					}
					{selectedWordpressLink &&
						<div className="tag-container">
							<div>Wordpress Links</div>
							<div className="tag-list">
								{[selectedWordpressLink].map((tag, index) => {
									const selected = selectedWordpressLink === tag
									return (
										<div
											key={index}
											className={`tag-item ${selected && 'selected'}`}>
											<span>{tag}</span>
											<button className="clear" onClick={() => handleWordpressLink(tag)}><img src={iconClear} alt="" /></button>
										</div>
									)
								})}
							</div>
						</div>
					}
					{ArrayHelper.isValid(selectedUserTags) &&
						<div className="tag-container">
							<div>User Tags</div>
							<div className="tag-list">
								{selectedUserTags.map((tag, index) => {
									const selected = selectedUserTags.some(e => e === tag)
									return (
										<div
											key={index}
											className={`tag-item ${selected && 'selected'}`}>
											<span>{tag}</span>
											<button className="clear" onClick={() => handleUserTag(tag)}><img src={iconClear} alt="" /></button>
										</div>
									)
								})}
							</div>
						</div>
					}
					<Selector
						className="tag-selector"
						defaultLabel="Select Tag"
						options={userTags.map((userTag) => new SelectorOption({ label: userTag, value: userTag, }))}
						value={tag}
						onChange={(value) => handleUserTag(value)} />
					<div className="tag-input">
						<TagInputField
							value={tag}
							onSelect={(value) => setTag(value)}
							onChange={(value) => setTag(value)} />
						<button className="set-tag" onClick={() => handleSetTag()}>Set Tag</button>
					</div>
					<div className="tag-input" style={{ padding: 0, }}>
						<button style={{ width: '100%', margin: 0, }} className="set-tag" onClick={() => { handleResetPostfixPassword(user.email) }}>Reset Mailbox Password</button>
					</div>
					<div className="list-container">
						{(isLoading || !userState) ? <Loader /> :
							<>
								<div className="list">
									<div className="list-item">
										<div>Verified emails</div>
										<div>{userState.countVerifiedEmails}</div>
									</div>
									<div className="list-item">
										<div>Senders</div>
										<div>{userState.countSenders}</div>
									</div>
									<div className="list-item">
										<div>Blocked emails</div>
										<div>{userState.countBlockedMails}</div>
									</div>
									<div className="list-item">
										<div>Redirected Emails</div>
										<div>{userState.countRedirectedEmails}</div>
									</div>
									<div className="list-item">
										<div>Outgoing Emails</div>
										<div>{userState.countOutgoingEmails}</div>
									</div>
									<div className="list-item">
										<div>Emails Opened</div>
										<div>{userState.totalEmailsOpened}</div>
									</div>
									<div className="list-item">
										<div>Time saved</div>
										<div>{userState.formattedTotalTimeSaved}</div>
									</div>
									<div className="list-item">
										<div>Referral Earned</div>
										<div>{userState.refEarned}</div>
									</div>
									{userState.referrer &&
										<div className="list-item">
											<div>Referred by</div>
											<div>{userState.referrer.email}</div>
										</div>
									}
									<div className="list-item">
										<div>Available Referrals</div>
										<div>{userState.refAvailable}</div>
									</div>
									<div className="list-item">
										<div>Referrals sent (total)</div>
										<div>{userState.refSent}</div>
									</div>
									<div className="list-item">
										<div>Referrals accepted (total)</div>
										<div>{userState.refAccepted}</div>
									</div>
									<div className="list-item">
										<div>GPC</div>
										<div>{userState.allowGPC ? "On" : "Off"}</div>
									</div>
									<div className="list-item">
										<div>Installed Chrome Extension</div>
										<div>{userState.isExtensionInstalled ? "Yes" : "No"}</div>
									</div>
									{userState.permalink &&
										<div className="list-item">
											<div>{userState.isWordpressLink ? 'Wordpress Link' : 'Link'}</div>
											<div>/{userState.formattedPermaLink}</div>
										</div>
									}
									{ArrayHelper.isValid(userState.tags) &&
										<div className="list-item">
											<div>Tags</div>
											<div>{userState.tags.join(', ')}</div>
										</div>
									}
									<div className="list-item">
										<div>Block by Default</div>
										<div>{userState.isBlockNewSender ? 'On' : 'Off'}</div>
									</div>
									<div className="list-item">
										<div>lockrBox</div>
										<div>{userState.allowLockrBox ? userState.lockrBox?.formattedFrequency : 'Off'}</div>
									</div>
									<div className="list-item">
										<div>2FA Enabled</div>
										<div>{userState.allow2FA ? 'On' : 'Off'}</div>
									</div>
									<div className="list-item">
										<div>Consent ON/OFF</div>
										<div>{userState.allowConsent ? 'On' : 'Off'}</div>
									</div>
									<div className='list-item'>
										<div>Active Digests</div>
										<div>{userState.activeDigestCount ?? '0'}</div>
									</div>
									<div className='list-item'>
										<div>Digests Delivered</div>
										<div>{userState.sentDigestCount ?? '0'}</div>
									</div>
									<div className='list-item'>
										<div>lockrMail Clicks</div>
										<div>{userState.mailClicks ?? '0'}</div>
									</div>
									<div className='list-item'>
										<div>lockrDeals Clicks</div>
										<div>{userState.dealClicks ?? '0'}</div>
									</div>
									<div className='list-item'>
										<div>Extension Clicks</div>
										<div>{userState.extensionClicks ?? '0'}</div>
									</div>
									<div className="list-item">
										<div>Shipments</div>
										<div>{userState.shipmentCount}</div>
									</div>
									<div className="list-item">
										<div>Total Discounts</div>
										<div>{userState.discountCount}</div>
									</div>
									<div className="list-item">
										<div>Unique emails with discounts</div>
										<div>{userState.uniqueEmailDiscount}</div>
									</div>
									<div className="list-item">
										<div>lockrScan</div>
										<div>{userState.isLockrScan ? 'Yes' : 'No'}</div>
									</div>
								</div>
								{userState.lockrScanStats && Object.keys(userState.lockrScanStats).length > 0 && <div className="list">
									<div>lockrScan Statistics</div>
									<div className="list-item">
										<div>Emails Processed</div>
										<div>{userState.lockrScanStats.lockrScanEmailCount}</div>
									</div>
									<div className="list-item">
										<div>Shipments</div>
										<div>{userState.lockrScanStats.lockrScanShipment}</div>
									</div>
									<div className="list-item">
										<div>Total Discounts</div>
										<div>{userState.lockrScanStats.lockrScanDiscount}</div>
									</div>
									<div className="list-item">
										<div>Unique emails with discounts</div>
										<div>{userState.lockrScanStats.lockrScanUniqueEmailDiscount}</div>
									</div>
									<div className="list-item">
										<div>Unique Senders</div>
										<div>{userState.lockrScanStats.uniqueSenderCount}</div>
									</div>
									<div className="list-item">
										<div>Senders Unsubscribed</div>
										<div>{userState.lockrScanStats.unSubscribedCount}</div>
									</div>
									<div className="list-item">
										<div>Senders in lockrArchive</div>
										<div>{userState.lockrScanStats.isArchivedCount}</div>
									</div>
								</div>}
								{/* Hidden Features */}
								{/* <div className="list">
									<div>Hidden Features</div>
									{Object.keys(User.HiddenFeature).map((key, index) => {
										const feature = User.HiddenFeature[key]
										const selected = hiddenFeatures.includes(feature)
										return (
											<div key={index} className="list-item">
												<div>{key}</div>
												<Switch
													value={selected}
													onChange={() => handleHiddenFeature(feature)} />
											</div>
										)
									})}
								</div> */}
							</>
						}
					</div>
				</div>
				<AlertButtonContainer>
					<AlertButton disabled={!isModified} onClick={() => handleSave()}>Save</AlertButton>
					<AlertButton className="btn-block" type="negative" onClick={() => alertBlock()}>Block</AlertButton>
					<AlertButton className="btn-block" type="negative" onClick={() => alertDelete()}>Delete</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}