import React, { Children } from 'react'

import './styles.scss'

export function BadgeButton({
	className = '',
	isSelectorParent,
	icon,
	activeIcon,
	active = false,
	description,
	onClick = (event) => { },
	children,
}) {
	function classList() {
		const results = ['btn-badge', className]
		if (active) results.push('active')
		if (activeIcon) results.push('has-active-icon')
		if (description) results.push('has-description')
		if (isSelectorParent) results.push('select-dropdown-parent')
		return results.join(' ')
	}
	return (
		<div
			className={classList()}
			onClick={onClick}
			data-description={description}>
			<span>
				{(icon && (!active || !activeIcon)) && <img src={icon} alt="" />}
				{(active && activeIcon) && <img src={activeIcon} alt="" />}
				{active && <div className="active-badge"></div>}
			</span>
			{children}
		</div>
	)
}