import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import './styles.scss'
import iconInfo from 'assets/images/info-white.svg'

import {
    Navigation,
    NotificationContainer,
    Loader,
    AppFooter,
    Button,
    GoogleSignInAlert,
} from 'components'
import { DateHelper, Storage } from 'utils'
import { DiscountNewsletterMail, useAuth, User } from 'core'
import { DeleteAlert, DeleteLockrScanAlert } from 'scenes/account'
import { redirectTo, routes } from 'routes'
import { LockrScanReportingEl } from './lockrScan-reporting'
import { LockrScanSectionContainer } from './section'
import { LockrScanRecurringEmailReportModal } from './lockrScan-reporting/edit-modal'
import { Helmet } from 'react-helmet'
import { DiscountNewsletterEl } from 'scenes/account/settings/discount-mail-newsletter'
import { DiscountNewsLetterModalEl } from 'scenes/account/settings/discount-mail-newsletter/edit-modal'
const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;

export function LockrScanAccountScene() {
    const [isDelete, setDelete] = React.useState(false)
    const { isLoading: isUserLoading, currentUser, userGetProfile, deleteLockrScanAcccount, currentLockrScanUser, userLogout, lockrScanUserGetProfile } = useAuth()
    const [signinAlert, setSigninAlert] = React.useState(false);
    const navigate = useNavigate();
    const [allowRecurringEmail, setAllowRecurringEmail] = React.useState(false);
    const [endTime, setEndTime] = React.useState(null);
    const [startTime, setStartTime] = React.useState(null);
    const [deliverTime, setDeliverTime] = React.useState(null);
    const [frequency, setFrequency] = React.useState(null)
    const [lockrScanRecurringEmailDisplay, setLockrScanRecurringEmailDisplay] = React.useState(false)
    const [googleOauthLink, setGoogleOauthLink] = React.useState("");
    const [ipAddr, setIpAddr] = React.useState("");
    const [permalink, setPermalink] = React.useState("");


    const [discountNewsletterMail, setDiscountNewsletterMail] = React.useState(null)
    const [isEditDiscountNewsletter, setEditDiscountNewsletter] = React.useState(false)
    const [allowDiscountNewsletterMail, setAllowDiscountNewsletterMail] = React.useState(false)


    const handleDeleteAccount = async () => {
        Storage.lockrScanStatus = 'false';

        const { data, error } = await deleteLockrScanAcccount();
        console.log(data, error);
        if (error) {
            setDelete(false)
            setSigninAlert(true)
        }
        else {
            if (Storage.onlyLockrScanUser == "true") {
                userLogout()
                navigate(redirectTo({ route: routes.Login }))
            }
            else {
                navigate(redirectTo({ route: routes.Login }))
                userGetProfile()
            }
        }
    }
    React.useEffect(() => {
        if (currentLockrScanUser) {
            checkAuthentications()
            setAllowRecurringEmail(currentLockrScanUser.allowRecurringEmail);
            setEndTime(currentLockrScanUser.endTime);
            setStartTime(currentLockrScanUser.startTime)
            setFrequency(currentLockrScanUser.frequency)
            setDiscountNewsletterMail(currentLockrScanUser.discountNewsletterMail ?? DiscountNewsletterMail.default)
            setAllowDiscountNewsletterMail(currentLockrScanUser.discountNewsletterMail ? currentLockrScanUser.discountNewsletterMail.allowDiscountNewsletterMail : false)
        }
        else {
            setSigninAlert(false)
        }
    }, [currentLockrScanUser])

    React.useEffect(() => {
        getGoogleOauthLink();
    }, [])



    const getGoogleOauthLink = async () => {
        const { error, data } = await User.getLockrScanAuth();
        setGoogleOauthLink(data?.googleOAuthUrl);
        setIpAddr(data?.ipAddr);
        setPermalink(data?.permalink);
    }

    const checkAuthentications = async () => {
        const result = await User.checkLockrScanAuthentications();

        if (result.success == false) {
            setSigninAlert(true)
        }
    }


    const onOffLockrScanRecurringEmailReport = async () => {
        const { data, error } = await User.updateLockrScanProfile({ allowRecurringEmail: false });
        if (error) {
            return;
        }
        lockrScanUserGetProfile();
    }

    const handleSave = async (value) => {
        if (value !== undefined) {
            discountNewsletterMail.allowDiscountNewsletterMail = value
        } else {
            discountNewsletterMail.allowDiscountNewsletterMail = true
        }
        console.log(discountNewsletterMail)
        const { data, error } = await User.updateLockrScanProfile({ discountNewsletterMail });
        if (error) return

        lockrScanUserGetProfile();
    }

    function handleAllowDiscountNewsletterMail(value) {
        if (value) setEditDiscountNewsletter(true)
        else handleSave(value)
    }


    return (
        <>
            <Helmet>
                <title>lockrScan | Unsubscribe from Spam</title>
            </Helmet>
            <div id="parent" className={`page-container account`}>
                <NotificationContainer />
                <Navigation />
                <div className='account-wrapper'>
                    <div className="greetings">
                        <div>Good {DateHelper.period()}, {currentLockrScanUser?.firstName ?? ""}</div>
                        <div>lockrScan user since {DateHelper.format(currentLockrScanUser?.createdAt, "MMMM YYYY") ?? ""}</div>
                    </div>
                    <div className='account-content-wrapper'>
                        <div style={{ marginTop: 52 }}></div>
                        <LockrScanSectionContainer title='Settings' className='settings-heading'>

                            <LockrScanReportingEl onEdit={() => {
                                setLockrScanRecurringEmailDisplay(true);
                            }} onChange={(value) => {
                                if (!value) {
                                    onOffLockrScanRecurringEmailReport()
                                }
                                else {
                                    setLockrScanRecurringEmailDisplay(true);
                                }
                            }} value={allowRecurringEmail} ></LockrScanReportingEl>
                            {Storage.onlyLockrScanUser == "true" && <DiscountNewsletterEl
                                value={allowDiscountNewsletterMail}
                                onChange={(value) => handleAllowDiscountNewsletterMail(value)}
                                onEdit={() => setEditDiscountNewsletter(true)}

                            />}
                        </LockrScanSectionContainer>
                        <div className='delete'>
                            <div className='delete-title'>Delete Account</div>
                            <div className='delete-wrapper'>
                                <div className='delete-wrapper-div'>
                                    <div>This action cannot be reversed. </div>
                                    <div>All content and history will be removed immediately.</div>
                                </div>
                                <div className='delete-button' onClick={() => setDelete(true)}> Delete</div>

                            </div>
                        </div>
                    </div>
                </div>

                <AppFooter />
            </div>
            {isDelete &&
                <DeleteLockrScanAlert
                    requiredCode="DELETE"
                    onDelete={() => handleDeleteAccount()}
                    onCancel={() => setDelete(false)}
                />
            }
            {lockrScanRecurringEmailDisplay && <LockrScanRecurringEmailReportModal currentRecurrinfEmailInfo={{ frequency: currentLockrScanUser.frequency, startTime }} className="lockrScan-recurring-email" onSave={() => {

            }} onClose={() => { setLockrScanRecurringEmailDisplay(false) }}></LockrScanRecurringEmailReportModal>}
            {signinAlert && <GoogleSignInAlert onButtonClick={async () => {

                // const { error, data } = await User.getLockrScanAuth();
                // if (error) {
                //     // setError(error)
                //     return;
                // }
                if (Storage.onlyLockrScanUser == "true") {
                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                    );
                } else {

                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                    );
                }
                setSigninAlert(false);
            }} onCancel={() => { setSigninAlert(false) }}></GoogleSignInAlert>}
            {isEditDiscountNewsletter &&
                <DiscountNewsLetterModalEl
                    discountNewsletterMail={discountNewsletterMail}
                    onChange={(value) => setDiscountNewsletterMail(value)}
                    onSave={() => handleSave()}
                    onClose={() => setEditDiscountNewsletter(false)} />
            }
        </>
    );
}