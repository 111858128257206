import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { errorMessages } from 'constants/error'

export class UserActivity extends BaseModel {
	activity
	browser
	device
	ipAddr

	static Type = Object.freeze({
		Login: 'login',
		Register: 'signup',
	})

	/** @returns {UserActivity} */
	static fromJSON(json) { return super.fromJSON(json) }

	/** @returns {Array<UserActivity>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async load() {
		try {
			const { error, data } = await Api.get('/get-user-activities')
			return {
				error: error && errorMessages.CONTACT_US,
				results: UserActivity.fromJSONArray(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}
}
