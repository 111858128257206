import { plainToClass } from 'class-transformer'

import { BaseModel } from 'core/models/base'
import { Api } from 'core'
import { errorCodes, errorMessages } from 'constants/error'
import { DateHelper } from 'utils'

export class DiscountNewsletterMail extends BaseModel {
	frequency
	deliveryDate

	static Frequency = Object.freeze({
		'Daily': 'daily',
		'Weekly': 'weekly',
		'Monthly': 'monthly',
	})

	/**
	 * @returns {DiscountNewsletterMail}
	 */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			// console.log('json', json.deliveryDate, DateHelper.default)
			result.deliveryDate = DateHelper.parse(json.deliveryDate)
			// console.log('result.deliveryDate', result.deliveryDate)
		}
		return result
	}

	static get default() {
		const result = new DiscountNewsletterMail()
		result.frequency = DiscountNewsletterMail.Frequency.Daily

		let deliveryDate = new Date()
		deliveryDate = DateHelper.startOfWeek(deliveryDate)
		deliveryDate = DateHelper.addHour(deliveryDate, 11)

		result.deliveryDate = DateHelper.addHour(DateHelper.startOfWeek(new Date()), 11)

		return result
	}

	get formattedFrequency() {
		if (this.frequency === DiscountNewsletterMail.Frequency.Daily) return 'Daily'
		if (this.frequency === DiscountNewsletterMail.Frequency.Weekly) return 'Weekly'
		if (this.frequency === DiscountNewsletterMail.Frequency.Monthly) return 'Monthly'
		return 'Off'
	}
}