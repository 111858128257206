import React, { useState } from "react";
import './styles.scss';
import { useWidth } from "utils";
import { ShippingDetailsModal } from "../ShippingDetailsModal";
import moment from "moment-timezone";
import { Carousel, ConfigProvider } from "antd";
import { ImageCarousel } from "components/image-carousel";
import gmailImage from "../../../assets/images/google-gmail-logo-small.svg"
import lockrIcon from "../../../assets/images/lockr-logo-small.svg"
import { Icon } from "components";
import { ShippingStatus } from "../Shipping-Status";
import noShipmentImage from '../../../assets/images/shipment-no-images.jpg'
import { useNavigate } from "react-router-dom";

export const ShippingListComponent = ({ onShippmentComponentHover, shipmentElement, markCompleted, isDelivered, onSelect }) => {
    const { width } = useWidth();
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    return (
        <div className="shipping-center-wrapper" onMouseEnter={() => {
            onShippmentComponentHover(shipmentElement.latestShipmentInfo.latestLatitude, shipmentElement.latestShipmentInfo.latestLongitude)
        }}>

            <div className="shipping-center-image">
                {(shipmentElement?.itemImages?.length > 0 && shipmentElement?.itemImages?.every(item => item === null)) ?
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', fontSize: 42, textTransform: 'capitalize' }}><img src={noShipmentImage} style={{ height: 218 }} /></div> :
                    <ImageCarousel images={shipmentElement?.itemImages} />}
                <div className={`type-${shipmentElement.type === 'Manuel' ? "" : shipmentElement.type}`}>
                    {shipmentElement.type != 'Manuel' && <img style={{ height: shipmentElement.type == 'lockrScan' ? 12 : 16, width: shipmentElement.type == 'lockrScan' ? 12 : 16 }} src={shipmentElement.type == 'lockrScan' ? gmailImage : lockrIcon} />}
                </div>
                {width > 568 && <div style={{ position: 'absolute', top: 162, right: -24 }}>
                    <Icon className={shipmentElement.avatar && "shipment-logo-icon"} src={shipmentElement.avatar} placeholder={shipmentElement.domain} />
                </div>}
            </div>
            <div className="shipping-details-wrapper">
                <div className="delivery-by-wrapper">
                    <div className="delivery-by">
                        {/* <div className="delivery-status">{shipmentElement.latestShipmentInfo.latestStatus}</div> */}
                        <ShippingStatus status={shipmentElement.shipmentCompleted ? 'Delivered' : shipmentElement.latestShipmentInfo.latestStatus ?? 'InfoReceived'} />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, gap: 16 }}>
                            {width < 567 && <div>
                                <Icon className={shipmentElement.avatar && "shipment-logo-icon"} src={shipmentElement.avatar} placeholder={shipmentElement.domain} style={{ height: 60, width: 60, borderRadius: 200 }} />
                            </div>}
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <div className="sender-name">{shipmentElement.senderName?.replace(`"`, ``)}</div>
                                <div className="sender-email">{shipmentElement.email}</div>
                            </div>
                        </div>
                        {shipmentElement.itemCount > 0 && <div className="order-item-count">{shipmentElement.itemCount} items</div>}
                    </div>
                    {width > 567 && <div className="delivery-buttons">
                        <div className="delivery-button" onClick={() => { navigate('/my-orders?shipmentId=' + shipmentElement.id); onSelect() }}>View Details</div>
                        {!shipmentElement.shipmentCompleted && <div className="delivery-button" onClick={() => { markCompleted(shipmentElement.id) }}>Mark as Delivered</div>}
                    </div>}
                </div>
                <div className="delivery-info-wrapper">
                    <div>
                        <div className="info-heading">Delivery</div>
                        <div className="info">{isDelivered ? shipmentElement?.deliveredAt ? moment(shipmentElement?.deliveredAt).format('MM/DD/YYYY') : "Delivered" : shipmentElement?.estimatedDeliverDate ? moment(shipmentElement?.estimatedDeliverDate).format('MM/DD/YYYY') : "Arriving Soon"}</div>
                    </div>
                    <div>
                        <div className="info-heading">Tracking #</div>
                        <div className="info tracking">{shipmentElement.trackingNumber}</div>
                    </div>
                    <div>
                        <div className="info-heading">Courier</div>
                        <div className="info">{shipmentElement.courierProvider}</div>
                    </div>
                    {width < 567 && <div className="delivery-buttons">
                        <div className="delivery-button" onClick={() => { navigate('/my-orders?shipmentId=' + shipmentElement.id) }}>View Details</div>
                        {!shipmentElement.shipmentCompleted && <div className="delivery-button" onClick={() => { markCompleted(shipmentElement.id) }}>Mark as Delivered</div>}
                    </div>}
                </div>
                {/* {show && <ShippingDetailsModal onBack={() => { setShow(false) }} shipmentElement={shipmentElement} />} */}

            </div>

        </div>
    )
}