import React from 'react'

import './styles.scss'

import {
	Loader, Pagination
} from 'components'

import { AdminDiscoverTableHeader } from './header'
import { AdminDiscoverCompanyItem } from './company-item'
import { AdminEditDiscoverCompanyModal } from './edit-modal'
import { AdminDiscoverOrderModal } from './order-modal'

import {
	useAlert, useAuth,
	DiscoverCompany,
} from 'core'
import { ArrayHelper } from 'utils'

export function AdminDiscoveryEl(props) {
	const { setError } = useAlert()
	const { currentUser } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [searchText, setSearchText] = React.useState('')
	const [selectedCategories, setSelectedCategories] = React.useState([])
	const [pageNum, setPageNum] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(10)
	React.useEffect(() => { loadCompanies() }, [currentUser, searchText, selectedCategories, pageNum, pageSize])

	const [totalSize, setTotalSize] = React.useState(0)
	const [companies, setCompanies] = React.useState([])
	const [selectedCompanies, setSelectedCompanies] = React.useState([])

	const [isAdd, setAdd] = React.useState(false)
	const [isChangeOrder, setChangeOrder] = React.useState(false)

	async function loadCompanies() {
		if (!currentUser?.isAdmin) return

		setLoading(true)
		const { error, totalSize, results } = await DiscoverCompany.search({ pageNum, pageSize, searchText, categories: selectedCategories })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalSize(totalSize)
		setCompanies(results)
	}

	function handleBatchSelect(value) {
		if (value) setSelectedCompanies(companies)
		else setSelectedCompanies([])
	}

	function handleSelectCompany(company, select) {
		if (select) setSelectedCompanies([...selectedCompanies, company])
		else setSelectedCompanies(selectedCompanies.filter((e) => e.id !== company.id))
	}

	/**
	 *
	 * @param {DiscoverCompany} value
	 */
	async function handleDelete(value) {
		if (!value?.id) return

		const { error } = await DiscoverCompany.delete(value?.id)
		if (error) {
			setError(error)
			return
		}

		loadCompanies()
	}

	return (
		<>
			<div className="admin-discovery">
				<AdminDiscoverTableHeader
					selectedCompanies={selectedCompanies}
					onSelectAll={(value) => handleBatchSelect(value)}
					searchText={searchText}
					onSearchText={(value) => setSearchText(value)}
					selectedCategories={selectedCategories}
					onCategories={(value) => setSelectedCategories(value)}
					onChange={(updated) => {
						if (updated) loadCompanies()
					}}
					onAdd={() => setAdd(true)}
					onOrder={() => setChangeOrder(true)} />
				<div className="admin-discover-company-list">
					{isLoading ? <Loader /> :
						companies.map((company, index) => {
							const selected = ArrayHelper.contains(selectedCompanies, company, 'id')
							return (
								<AdminDiscoverCompanyItem
									key={index}
									selected={selected}
									value={company}
									onSelect={(value) => handleSelectCompany(company, value)}
									onDelete={(value) => handleDelete(value)} />
							)
						})
					}
				</div>
				<Pagination
					className="admin-discover-pagination"
					totalSize={totalSize}
					pageIndex={pageNum}
					pageSize={pageSize}
					onPageIndex={(value) => setPageNum(value)}
					onPageSize={(value) => setPageSize(value)} />
			</div>
			{isAdd &&
				<AdminEditDiscoverCompanyModal
					onChange={() => loadCompanies()}
					onClose={() => setAdd(false)} />
			}
			{isChangeOrder &&
				<AdminDiscoverOrderModal
					onClose={(updated) => {
						setChangeOrder(false)
						if (updated) loadCompanies()
					}} />
			}
		</>

	)
}