import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton,
	TextField,
	Select, SelectOption,
	ImagePicker,
} from 'components'

import { useAlert, DiscoverCompany } from 'core'
import { ObjectHelper } from 'utils'

/**
 * @typedef {{value: DiscoverCompany, onChange: Function, onClose: Function}} Props
 * @param {Props} param0
 */
export function AdminEditDiscoverCompanyModal({
	value,
	onChange = () => { },
	onClose = () => { },
}) {
	const { setError } = useAlert()

	const [title, setTitle] = React.useState('')
	const [description, setDescription] = React.useState('')
	const [category, setCategory] = React.useState(null)
	const [avatarDesktop, setAvatarDesktop] = React.useState(null)
	const [avatarTablet, setAvatarTablet] = React.useState(null)
	const [avatarMobile, setAvatarMobile] = React.useState(null)
	const [url, setUrl] = React.useState('')
	const [subscribeText, setSubscribeText] = React.useState(null)

	React.useEffect(() => {
		if (value) {
			setTitle(value.title)
			setDescription(value.description)
			setCategory(value.category)
			setAvatarDesktop(value.avatarDesktop)
			setAvatarTablet(value.avatarTablet)
			setAvatarMobile(value.avatarMobile)
			setUrl(value.url)
			setSubscribeText(value.subscribeText)
		}
	}, [value])

	function checkValidation(showError = false) {
		if (!category) {
			return false
		}
		if (!title) return false
		if (!description) return false
		if (!url) return false
		if (!avatarDesktop) return false
		return true
	}

	async function uploadImages() {
		const files = [avatarDesktop, avatarTablet, avatarMobile]
		const uploadedFiles = []
		for (let file of files) {
			if (!ObjectHelper.isValid(file) || typeof file !== 'object') {
				uploadedFiles.push(null)
				continue
			}

			const { error, results } = await DiscoverCompany.uploadFiles([file])
			if (error) {
				setError(error)
				return
			}

			uploadedFiles.push(results[0])
		}

		return uploadedFiles
	}

	async function update() {
		if (!value?.id) return

		let params = {
			title: title.trim(),
			description: description.trim(),
			categories: [category],
			url: url.trim(),
			subscribeText: subscribeText?.trim()
		}

		const [desktop, tablet, mobile] = await uploadImages()
		if (desktop) params = { ...params, avatarDesktop: desktop }
		if (tablet) params = { ...params, avatarTablet: tablet }
		if (mobile) params = { ...params, avatarMobile: mobile }

		const { error } = await DiscoverCompany.update(value.id, params)
		if (error) {
			setError(error)
			return
		}

		onChange()
		onClose()
	}

	async function save() {
		if (!checkValidation()) return

		let uploadedFiles = []
		if (avatarDesktop) {
			const files = [avatarDesktop, avatarTablet, avatarMobile].filter(e => ObjectHelper.isValid(e))
			const { error, results } = await DiscoverCompany.uploadFiles(files)
			if (error) {
				console.log(error)
				return
			}

			uploadedFiles = results
		}

		const [desktop, tablet, mobile] = uploadedFiles

		const { error } = await DiscoverCompany.add({
			categories: [category],
			avatarDesktop: desktop,
			avatarTablet: tablet,
			avatarMobile: mobile,
			title: title.trim(),
			description: description.trim(),
			url: url.trim(),
			subscribeText: subscribeText?.trim(),
		})
		if (error) {
			console.log(error)
			return
		}

		setCategory(null)
		setTitle('')
		setDescription('')
		setUrl('')

		onChange()
	}

	function handleSave() {
		if (value) {
			update()
			return
		}

		save()
	}

	return (
		<Alert className="admin-add-discover-alert" onCancel={() => onClose()}>
			<AlertPage>
				<AlertTitle>Add</AlertTitle>
				<Select
					className="form-item"
					label="Category"
					value={category}
					onChange={(value) => setCategory(value)}>
					{Object.keys(DiscoverCompany.Category).map((key, index) =>
						<SelectOption key={index} value={DiscoverCompany.Category[key]}>{key}</SelectOption>
					)}
				</Select>
				<ImagePicker
					className="form-item"
					placeholder={avatarDesktop ?? 'Desktop Image'}
					value={avatarDesktop}
					onSelect={(value) => setAvatarDesktop(value)} />
				<ImagePicker
					className="form-item d-none"
					placeholder={avatarTablet ?? 'Tablet Image'}
					value={avatarTablet}
					onSelect={(value) => setAvatarTablet(value)} />
				<ImagePicker
					className="form-item  d-none"
					placeholder={avatarMobile ?? 'Mobile Image'}
					value={avatarMobile}
					onSelect={(value) => setAvatarMobile(value)} />
				<TextField
					className="form-item"
					placeholder="Title"
					value={title}
					onChange={(event) => setTitle(event.target.value)} />
				<TextField
					className="form-item"
					type="large"
					placeholder="Description"
					multiline
					value={description}
					onChange={(event) => setDescription(event.target.value)} />
				<TextField
					className="form-item"
					placeholder="URL"
					value={url}
					onChange={(event) => setUrl(event.target.value)} />
				<TextField
					className="form-item"
					placeholder="Subscribe Text"
					value={subscribeText}
					onChange={(event) => setSubscribeText(event.target.value)} />
				<AlertButtonContainer>
					<AlertButton disabled={!checkValidation()} onClick={() => handleSave()}>Save</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}