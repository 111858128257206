import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './styles.scss'

import {
	NotificationContainer, Navigation, AppFooter,
	SearchField,
	Loader,
	Tab, TabItem
} from 'components'
import { DiscoverCompanyItem } from 'scenes/discover/company-item'

import {
	useAuth,
	DiscoverCompany
} from 'core'
import { redirectTo } from 'routes'

export function DiscoverScene(props) {
	const navigate = useNavigate()
	const { category = 'all' } = useParams()

	const { currentUser, userGetProfile } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [searchText, setSearchText] = React.useState('')
	const [companies, setCompanies] = React.useState([])

	React.useEffect(() => { loadCompanies() }, [category, searchText])

	async function loadCompanies() {
		setLoading(true)
		const { error, totalSize, results } = await DiscoverCompany.search({ searchText, categories: category === 'all' ? [] : [category] })
		setLoading(false)

		if (error) {
			return
		}

		setCompanies(results)
	}

	function handleTabSelect(value) {
		let pathname = '/discover'
		if (value !== 'all') pathname = `${pathname}/${value}`

		navigate(redirectTo({ route: pathname }))
	}

	return (
		<div id="parent" className="page-container">
			<NotificationContainer />
			<Navigation />
			<div className="discover">
				<div className="discover-wrapper">
					<div className="title">Discover</div>
					<div className="description">
						Start using your lockrMail email address by subscribing to some newsletters and brand emails.
					</div>
					<SearchField
						value={searchText}
						onChange={(value) => setSearchText(value)} />
					<Tab className="slug-tab" value={category} onSelect={(value) => handleTabSelect(value)}>
						<TabItem value="all">All</TabItem>
						{Object.keys(DiscoverCompany.Category).map((key, index) =>
							<TabItem key={index} value={DiscoverCompany.Category[key]}>{key}</TabItem>
						)}
					</Tab>
					<div className="discover-company-list">
						{isLoading ? <Loader /> :
							companies.map((company, index) =>
								<DiscoverCompanyItem
									key={index}
									value={company} />
							)
						}
					</div>
				</div>
			</div>
			<AppFooter />
		</div>
	)
}