import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'
import iconDelete from 'assets/images/delete/gray.svg'
import iconDeleteHover from 'assets/images/delete/green.svg'

import {
	Checkbox, BlueButton, IconButton,
	Select, SelectDropDown, SelectOption,
	SearchField,
	ErrorAlert,
} from 'components'

import {
	useAlert,
	useAuth,
	Sender,
	DiscoverCompany
} from 'core'
import { ArrayHelper } from 'utils'

export function AdminDiscoverTableHeader({
	className = "",

	selectedCompanies = [],
	onSelectAll = (select) => { },

	searchText = "",
	onSearchText = (value) => { },

	// View Options
	selectedCategories = [],
	onCategories = (value) => { },

	onChange = (updated) => { },
	onAdd = () => { },
	onOrder = () => { },
}) {
	const { width: windowWidth } = useWindowSize()

	const { setError } = useAlert()
	const { currentUser } = useAuth()

	const [isBatchEnabled, setBatchEnabled] = React.useState(false)

	React.useEffect(() => {
	}, [currentUser])

	React.useEffect(() => {
		setBatchEnabled(ArrayHelper.isValid(selectedCompanies))
	}, [selectedCompanies])

	function deviceName() {
		if (windowWidth >= 992) return 'desktop'
		if (windowWidth >= 577) return 'tablet'
		return 'mobile'
	}

	async function handleDelete() {

	}

	return (
		<>
			<div className={`${className} admin-discover-table-header ${deviceName()}`}>
				<div className="checkbox-container">
					<Checkbox
						iconUnchecked={iconUnchecked}
						iconChecked={iconChecked}
						checked={isBatchEnabled}
						onChange={(value) => onSelectAll(value)} />
					<div className="arrow-container">
						<img src={iconArrowDown} alt="" />
						<SelectDropDown>
							<SelectOption selectable={false} onClick={() => onSelectAll(true)}>Select All</SelectOption>
							<SelectOption selectable={false} onClick={() => onSelectAll(false)}>Deselect All</SelectOption>
						</SelectDropDown>
					</div>
				</div>
				<div className={`batch-container ${isBatchEnabled && 'active'}`}>
					<IconButton
						className="btn-batch delete"
						normal={iconDelete}
						hover={iconDeleteHover}
						onClick={() => handleDelete()} />
				</div>
				<div className="filter-container">
					<SearchField
						minimized={windowWidth <= 576}
						value={searchText}
						onChange={(value) => onSearchText(value)} />
					<Select
						keepLabel
						label={`${windowWidth >= 577 ? 'View Options' : 'View'}`}
						minimized={windowWidth <= 576}
						mobileTitle="View Options"
						filterApplied={ArrayHelper.isValid(selectedCategories)}>
						<SelectOption
							selected={!ArrayHelper.isValid(selectedCategories)}
							onClick={() => onCategories([])}>
							All
						</SelectOption>
						{Object.keys(DiscoverCompany.Category).map((key, index) => {
							const value = DiscoverCompany.Category[key]
							const selected = ArrayHelper.contains(selectedCategories, value)
							return (
								<SelectOption
									key={index}
									selected={selected}
									onClick={() => onCategories(selected ? selectedCategories.filter(e => e !== value) : [...selectedCategories, value])}>
									{key}
								</SelectOption>
							)
						})}
					</Select>
					<BlueButton type="medium" onClick={() => onAdd()}>Add</BlueButton>
					<BlueButton type="medium" onClick={() => onOrder()}>Change Order</BlueButton>
				</div>
			</div>
		</>
	)
}