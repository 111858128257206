import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { SenderListItem } from '../sender-list-item'

import { useAuth, Sender } from 'core'
import { ArrayHelper, DateHelper } from 'utils'
import { parseSearchParams } from 'routes'
const userTimeZone = DateHelper.getUserTimeZone()
export function SenderGroupByDate({
	startDate,
	endDate,
	senders,
	viewOptions,
	selectedSenders = [],
	onSelectSender = (sender) => { },
	onError = (error) => { },
	defaultSelectedDigestFilter,
	groupCount
}) {
	const { currentUser } = useAuth()
	const [searchParams] = useSearchParams()
	const [highlightId, setHighlightId] = React.useState(null)
	const [count, setCount] = React.useState(null)
	React.useEffect(() => { loadCount() }, [currentUser, senders, highlightId])

	const [label, setLabel] = React.useState('')
	React.useEffect(() => { setLabel(calcLabel()) }, [startDate, endDate])

	React.useEffect(() => { parseLocation() }, [searchParams])
	function parseLocation() {
		const { highlight } = parseSearchParams(searchParams)

		if (highlight) setHighlightId(highlight)
	}

	async function loadCount() {
		if (!currentUser) return
		if (!ArrayHelper.isValid(senders)) return

		const count = getCountsByDateRange(startDate, endDate)
		setCount(parseInt(count[0]?.senderCount))
	}

	function getCountsByDateRange(startDate, endDate) {
		const currentDate = new Date();

		const startMonth = DateHelper.format(startDate, 'YYYY-MM', userTimeZone);
		const endMonth = DateHelper.format(endDate, 'YYYY-MM', userTimeZone);
		const isSameMonth = startMonth === endMonth;
		// Prepare the result
		let result = [];

		// Iterate over each groupCount item
		groupCount.forEach(item => {
			// For time periods labeled with specific days
			if (['today', 'yesterday', 'theDayBeforeYesterday', 'last7Days', 'earlierThisMonth'].includes(item.timePeriod)) {
				if (item.timePeriod === 'today' && DateHelper.isSameDay(currentDate, startDate, userTimeZone)) {
					result.push(item);

				} else if (item.timePeriod === 'yesterday' && DateHelper.isSameDay(DateHelper.addDay(currentDate, -1), startDate, userTimeZone)) {
					result.push(item);
				} else if (item.timePeriod === 'theDayBeforeYesterday' && DateHelper.isSameDay(DateHelper.addDay(currentDate, -2), startDate, userTimeZone)) {
					result.push(item);
				} else if (item.timePeriod === 'last7Days' && DateHelper.isSameDay(startDate, DateHelper.addDay(new Date(), -6), userTimeZone) && DateHelper.isSameDay(endDate, DateHelper.addDay(new Date(), -3), userTimeZone)) {
					// For 'last7Days', include if the start date is within the last 7 days
					result.push(item);
				} else if (item.timePeriod === 'earlierThisMonth' && DateHelper.isSameMonth(startDate, new Date(), userTimeZone) && DateHelper.isSameMonth(startDate, endDate, userTimeZone) && startDate <= DateHelper.startOfMonth(startDate, userTimeZone)) {
					// For 'earlierThisMonth', include if both dates are in the same month and match the start month
					result.push(item);
				}
			} else if (isSameMonth && item.timePeriod === startMonth) {
				// If dates are in the same month and match any specific month, include the item
				result.push(item);
			} else if (!isSameMonth && item.timePeriod === startMonth) {
				// If dates span across different months, include data only for the start month
				result.push(item);
			}
		});

		return result;
	}

	function calcLabel() {
		// today
		let date = new Date()

		if (DateHelper.isSameDay(date, startDate, userTimeZone)) return 'Today'

		// yesterday
		date = DateHelper.addDay(new Date(), -1)
		if (DateHelper.isSameDay(date, startDate, userTimeZone)) return 'Yesterday'

		// the day after yesterday
		date = DateHelper.addDay(new Date(), -2)
		if (DateHelper.isSameDay(date, startDate, userTimeZone)) return DateHelper.format(startDate, "MMMM DD", userTimeZone)

		// last 7 days
		if (DateHelper.isSameDay(startDate, DateHelper.addDay(new Date(), -6), userTimeZone) && DateHelper.isSameDay(endDate, DateHelper.addDay(new Date(), -3), userTimeZone))
			return 'Last 7 Days'

		// Earlier this month
		if (DateHelper.isSameMonth(startDate, new Date(), userTimeZone) && DateHelper.isSameMonth(startDate, endDate, userTimeZone) && startDate <= DateHelper.startOfMonth(startDate, userTimeZone))
			return 'Earlier this month'

		return DateHelper.isSameYear(new Date(), startDate, userTimeZone) ? DateHelper.format(startDate, "MMMM", userTimeZone) : DateHelper.format(startDate, "YYYY MMMM", userTimeZone)
	}

	function sortedSenders() {
		if (viewOptions?.sortBy === 'mailCount') return ArrayHelper.sort(senders, 'mailCount', true)
		return senders
	}

	function renderCount() {
		if (!count) return (<>{label}</>)

		if (count === senders.length) return (<>{label} ({count})</>)
		return (<>{label} ({senders.length}/{count})</>)
	}

	return (
		<>
			{ArrayHelper.isValid(senders) &&
				<div className="sender-list-group">
					<div className="sender-list-header">{renderCount()}</div>
					<div className="sender-list">
						{sortedSenders().map((sender) => {
							return (
								<SenderListItem
									defaultSelectedDigestFilter={defaultSelectedDigestFilter}
									key={sender.id}
									sender={sender}
									selected={selectedSenders.some(x => x.id === sender.id)}
									// onChange={(value) => setSenders(senders.map(x => x.id === value.id ? value : x))}
									onSelect={() => onSelectSender(sender)}
								/>
							)
						})}
					</div>
				</div>
			}
		</>
	)
}