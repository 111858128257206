import React from 'react'
import Lottie from 'react-lottie-player';
import * as animationData from '../../../assets/images/success-screen-animation.json'

export default class LottieControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false };
    }

    render() {
        const buttonStyle = {
            display: 'block',
            margin: '10px auto'
        };

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return <div>
            <Lottie
                className='lockrScan-success-animation'
                animationData={animationData}

                style={{ height: 300, width: 300 }}
                play
                isStopped={this.state.isStopped}
                isPaused={this.state.isPaused} />

        </div>
    }
}