import React from 'react'

import { InfoAlert, ErrorAlert } from 'components'
import { useNavigate } from 'react-router-dom'
import { redirectTo } from 'routes';
import { AuthProvider, useAuth } from './auth';
import { Storage } from 'utils';
import { SessionExperiedAlert } from 'components/alert/session-expired-alert';
import { ForceLogoutAlert } from 'components/alert/force-logout-alert';
import { axiosResponseInterceptor } from 'constants/axiosInterceptor';
import axios from 'axios';
import { errorMessages } from 'constants/error'
import { config } from '../../constants';
import googleButton from '../../assets/images/google_logo.png'


const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;

const AlertContext = React.createContext({})

export function AlertProvider({
	children,
	onLogout
}) {
    const [ipAddr, setIpAddr] = React.useState("");
	React.useEffect(() => {
        getIpAddr();
    }, [])

    const getIpAddr = async () => {
		const response = await fetch('https://api.ipify.org?format=json');
		const data = await response.json();
        setIpAddr(data?.ip);
    }

	React.useEffect(() => {
		axiosResponseInterceptor.use((res) => { return res; }, (error) => {
			if (!navigator.onLine) {
				throw new Error(errorMessages.CONNECTION_ERROR);
			}
			if (!error.response) {
				if (error.message === 'Network Error' || error.code === 'ECONNREFUSED') {
					throw new Error(errorMessages.NETWORK_ERROR);
				}
			}
			// Check if the error was due to an intentionally canceled unique request.	
			if (axios.isCancel(error) && error.message === 'Canceled because of a new unique request.') {
				// Ignore the error by returning a never-resolving promise, which essentially stops the error propagation.
				return new Promise(() => { });
			}
			if (error.response.status === 403) {
				if (error.response.data.error.code == 5019) setErrorCode(5019);
				else setErrorCode(403);
			} else {
				throw error;
			}
		})
	}, [])

	const [alert, setAlert] = React.useState(null)
	const [error, setError] = React.useState(null)
	const [errorCode, setErrorCode] = React.useState(null)

	function resetAlert() {
		setErrorCode(null)
		setAlert(null)
		setError(null)
	}
	function logOut() {
		setError(null)
		setErrorCode(null)

		Storage.sessionExpireCode = null
		Storage.accessToken = null
		Storage.refreshToken = null

		Storage.cookieAccessToken = null
		Storage.cookieRefreshToken = null

		Storage.lockrScanUserId = null
		Storage.userId = null
	}

	function handleCloseAlert() {
		const { onClose = () => { } } = alert
		onClose()

		setAlert(null)
	}

	const memoedValue = React.useMemo(() => ({
		alert,
		setAlert,
		error,
		setError,
		errorCode,
		setErrorCode,
		resetAlert
	}), [alert, error, errorCode])

	return (
		<AuthProvider>
			<AlertContext.Provider value={memoedValue}>
				{errorCode == 403 ? <SessionExperiedAlert onClose={() => { logOut() }}></SessionExperiedAlert> : errorCode == 5019 ? <ForceLogoutAlert onClose={() => { logOut() }}></ForceLogoutAlert> : children}
				{(alert && !errorCode) &&
					<InfoAlert {...alert} onClose={() => handleCloseAlert()} />
				}
				{(error && !errorCode) &&
					< ErrorAlert error={error} onClose={() => setError(null)} />
				}
				{(error && errorCode == 5007) &&
					< ErrorAlert error={<div>
						<span>{error}</span>
						<div style={{marginTop: 20}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								padding: '1px',
								height: '42px',
								width: '263px',
								cursor: 'pointer',
								background: '#4285F4',
								borderRadius: '100px',

							}}
							onClick={async () => {
								setError('');
								if (Storage.accessToken) {
									window.open(config.GOOGLE_AUTH_URL + `&state=${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
										'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
									);
								}
								else {
									window.open(config.GOOGLE_AUTH_URL + `&state=${ipAddr}`, 'popUpWindow',
										'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
								}
							}}
						>
							<img src={googleButton}></img>
							<span style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flex: 1,
								margin: '0 auto',
								width: '158px',
								height: '16px',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								lineHeight: '16px',
								textalign: 'center',
								letterSpacing: '0.01em',
								color: '#FFFFFF',
							}}>Sign in with Google</span>
						</div>
				</div>
					</div>} onClose={() => setError(null)} />
				}
				
			</AlertContext.Provider>
		</AuthProvider>
	)
}

/**
 * @typedef {{alert: *, setAlert: Function, error: *, setError: Function, resetAlert: Function, setErrorCode: Function}} UseAlert
 * @returns {UseAlert}
 */
export function useAlert() {
	return React.useContext(AlertContext)
}