import React, { useEffect } from 'react'

import './styles.scss'

export function TopProgressBar({
	className = "",
	progress = 1,
	isHidden = false
}) {
	const ref = React.createRef()

	function update() {
		if (!ref.current) { return }
		if (isHidden) {
			ref.current.style.display = 'none'
			return
		}

		ref.current.style.width = `${progress * 100}%`
	}

	useEffect(() => { update() }, [progress])

	return (
		<div ref={ref} className={`${className} top-progress-bar`} />
	)
}
