import React, { useState } from "react";
import './styles.scss'
import iconCopy from 'assets/images/copy.svg'
import iconCopyGreen from 'assets/images/copy-green.svg'
import externalIcon from 'assets/images/external-arrow-black.svg'

export function AffiliateComponent({ title = "", description = "", image = "", affiliateObject }) {
    const [imgError, setImgError] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const handleError = () => {
        setImgError(true);
    };

    const onCopy = () =>{
        setIsCopy(true);
        navigator.clipboard.writeText(affiliateObject.promoCode)
        setTimeout(()=>{
            setIsCopy(false)
        },5000)
    }

    return (
        <div className="affiliate-component-parent">
            <>
                {!imgError ? (
                    <img style={{ height: 50, width: 100 }} src={image ?? ""} onError={handleError} />
                ) : (affiliateObject.avatar ? <img style={{ height: 50, width: 100 }} src={affiliateObject.avatar ?? ""} onError={handleError} /> :
                    <div style={{ height: 50, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ height: 50, width: 50, backgroundColor: 'black', color: 'white', fontWeight: 'bold', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{title.split(" ")[2].charAt(0).toLocaleUpperCase()}</div></div>
                )}
            </>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <div className="title">{title}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            {affiliateObject.promoCode?
            <div style={{position:'relative', overflow:"hidden"}}>
            <div onClick={()=>onCopy()} className={`shop-now-code ${isCopy ? 'copy':''}`}>
                <div style={{flex:1, overflow:"hidden", textOverflow:'ellipsis'}}>
                {!isCopy ? affiliateObject.promoCode : <div style={{color:'#50E8B1'}}>Copied!</div>}
                </div>
                <div><img style={{height:16, width:16}} src={isCopy?iconCopyGreen:iconCopy}/></div>
                
            </div>
            {isCopy && <button onClick={() => { window.open(affiliateObject.url) }} className="visit-website">
                    <div style={{flex:1, textAlign:'center'}}>Visit Website</div>
                    <img src={externalIcon} style={{height:16, width:16}}/>
                </button>}</div>:
            <div className="shop-now" onClick={() => { window.open(affiliateObject.url) }}>
                Shop Now
            </div>}
        </div>
    )
}