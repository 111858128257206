import React from 'react'

import './styles.scss'
import 'react-calendar/dist/Calendar.css'

import { HMenuOption } from './options'
import { HMenuCommand } from './command'
import { HMenuCalendar } from './calendar'

import { isMobile } from '../../../utils/helper'
import { Menu } from '../'
import { zIndexes } from '../../../constants'

export class HMenu extends Menu {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.update()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { options } = this.props
		if (options !== prevProps.options) { this.update() }
	}

	update = () => {
		const { open } = this.props
		this.setState({
			isOpen: open || false,
			options: this.props.options.map(option => ({ ...option, isChildOpen: isMobile || option.selected }))
		})

		window.removeEventListener('click', this.handleWindowClicked, false)
		if (open) { window.addEventListener('click', this.handleWindowClicked, false) }
	}

	handleWindowClicked = (event) => {
		if (!event.target.closest(".h-menu-parent")) {
			this.handleSubOptionClose()
		}

		if (event.target.matches('.h-menu') && isMobile) {
			this.handleSubOptionClose()
		}
	}

	handleContainerClick = (event) => {
		if (!isMobile) {
			event.stopPropagation()
		}
	}

	handleClose = () => {
		this.setState({
			isOpen: false,
			options: this.props.options.map((e, i) => ({ ...e, isChildOpen: isMobile }))
		})
		Menu.closeComponent(this)
		window.removeEventListener('click', this.handleWindowClicked, false)
		if (this.props.onClose) { this.props.onClose() }
	}

	handleClickOption = (option, index) => {
		this.setState({
			options: this.props.options.map((e, i) => ({ ...e, isChildOpen: (i === index || isMobile) }))
		})

		if (option.subOptions.length <= 0) {
			const { disabled, onClick = () => { } } = option
			if (!disabled) {
				onClick()
				this.handleClose()
			}
		}
	}

	handleClickCommand = (command) => {
		if (command.onClick) command.onClick()
		this.handleClose()
	}

	handleSelectCalendar = (option, date) => {
		if (option.onClick) option.onClick(date)
		this.handleClose()
	}

	handleMouseOver = (option, index) => {
		this.setState({
			options: this.props.options.map((e, i) => ({ ...e, isChildOpen: (i === index || isMobile) }))
		})
	}

	handleSubOptionClose = () => {
		this.handleClose()
	}

	render() {
		const { style = {}, disabledStyle = {} } = this.props
		const { isOpen, options = [] } = this.state
		return (
			<>
				{isOpen &&
					<div id={this.menuId} ref={this.menuRef} className="h-menu" style={{ ...style, zIndex: zIndexes.Menu }}>
						<div className="h-menu-container" onClick={this.handleContainerClick}>
							{options.map((option, index) => {
								if (option.type === 'command') {
									return (
										<HMenuCommand
											key={index}
											command={option}
											onClick={() => this.handleClickCommand(option)}
										/>
									)
								}
								if (option.type === 'calendar') {
									return (
										<HMenuCalendar
											key={index}
											option={option}
											onSelect={date => this.handleSelectCalendar(option, date)}
										/>
									)
								}

								return (
									<HMenuOption
										key={index}
										option={option}
										index={index}
										disabledStyle={disabledStyle}
										onMouseOver={(option, index) => this.handleMouseOver(option, index)}
										onClick={(option, index) => this.handleClickOption(option, index)}
										onSubOptionClose={() => this.handleSubOptionClose()}
									/>
								)
							})
							}
						</div>
					</div>
				}
			</>
		)
	}
}