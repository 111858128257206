import React from 'react'

import { Storage } from 'utils'

const StorageContext = React.createContext({})

export function StorageProvider({
	children,
}) {
	const [checklistMinimized, setChecklistMinimized] = React.useState(Storage.checklistMinimized)

	const memoedValue = React.useMemo(() => ({
		checklistMinimized, setChecklistMinimized,
	}), [checklistMinimized])

	return (
		<StorageContext.Provider value={memoedValue}>
			{children}
		</StorageContext.Provider>
	)
}

/**
 * @typedef {{checklistMinimized: boolean, setChecklistMinimized: Function}} UseStorage
 * @returns {UseStorage}
 */
export function useStorage() {
	return React.useContext(StorageContext)
}