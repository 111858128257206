import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'animate.css'
import './styles.scss'

import { TileReferrals } from './tile-referrals'
import { TileHelpCenter } from './tile-help-center'
import { TileGettingStarted } from './title-getting-started'
import { TileJustLaunched } from './tile-just-launched'

export function Tiles({
	className = "",
}) {
	const slickSettings = {
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
		swipeToSlide: false,
		prevArrow:
			<button>
				<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5 1L0.999999 5L5 9" stroke="#8B8B8B" strokeLinecap="round" />
				</svg>
			</button>,
		nextArrow:
			<button>
				<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 9L5 5L1 1" stroke="#8B8B8B" strokeLinecap="round" />
				</svg>
			</button>
	}
	return (
		<div
			className={`${className} dashboard-statistics-tiles`}>
			<div className="dashboard-statistics-tiles-wrapper">
				<Slider {...slickSettings}>
					<TileReferrals
						className="dashboard-statistics-tiles-item" />
					<TileHelpCenter
						className="dashboard-statistics-tiles-item" />
					<TileGettingStarted
						className="dashboard-statistics-tiles-item" />
					<TileJustLaunched
						className="dashboard-statistics-tiles-item" />
				</Slider>
			</div>
		</div>
	)
}