import React from 'react'

import './styles.scss'

import {
	TextField,
	InputMenu, InputMenuOption,
} from 'components'
import { useAdminUserTags } from 'core'
import { ArrayHelper } from 'utils'

let timer = null

export function TagInputField({
	value = "",
	onChange = (value) => { },
	onSelect = (value) => { }
}) {
	const menuRef = React.useRef()
	const { adminUserTags } = useAdminUserTags()

	const [searchResults, setSearchResults] = React.useState([])
	React.useEffect(() => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			search()
		}, 500)
	}, [value])

	async function search() {
		if (value === '') return
		const searchResults = adminUserTags.filter(e => e.name.toLowerCase().includes(value.toLowerCase()))
		setSearchResults(searchResults)
		if (ArrayHelper.isValid(searchResults)) {
			if (menuRef.current) menuRef.current.open()
		}
	}

	return (
		<div className="tag-input-field v-menu-parent">
			<TextField
				value={value}
				onChange={(event) => onChange(event.target.value)} />
			<InputMenu
				open={ArrayHelper.isValid(searchResults)}
				options={searchResults.map((searchResult) =>
					new InputMenuOption({
						label: searchResult.name,
						onClick: () => onSelect(searchResult.name)
					})
				)}
				inputValue={value}
				onInputChange={(value) => onChange(value)}
				onClose={() => setSearchResults([])}
			/>
		</div>
	)
}