import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { errorMessages } from 'constants/error'

export class AdminSender extends BaseModel {
	name
	email
	domain
	avatar
	hasWhite

	/** @returns {AdminSender} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		return result
	}

	/** @returns {Array<AdminSender>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async search({ searchText, pageIndex = 0, pageSize = 5 }) {
		try {
			const { error, data } = await Api.get('/admin/get-all-senders', {
				searchText,
				pageNum: pageIndex + 1,
				pageSize
			})
			return {
				error: error && errorMessages.CONTACT_US,
				totalCount: data?.count ?? 0,
				results: AdminSender.fromJSONArray(data?.rows)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getSenderByEmail(email) {
		try {
			const { error, data } = await Api.post("/admin/get-senders-by-emails", { senderEmails: [email] })
			const results = AdminSender.fromJSONArray(data)
			const result = results.length > 0 ? results[0] : null
			return {
				error: error && errorMessages.CONTACT_US,
				result
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async setWhiteToSenders(senders, hasWhite = true) {
		try {
			const senderEmails = senders.map(x => typeof x === 'string' ? x : x.email)
			const requestPath = hasWhite ? '/admin/add-white-to-senders' : '/admin/remove-white-to-senders'
			const { error } = await Api.post(requestPath, { senderEmails })
			return { error: error && errorMessages.CONTACT_US, }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async updateAvatar(senders, url) {
		try {
			const emails = senders.map(x => x instanceof AdminSender ? x.email : x)
			const { error } = await Api.post('/admin/update-avatar', { emails, url })
			return { error: error && errorMessages.CONTACT_US, }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async deleteAvatar(senders) {
		try {
			const senderEmails = senders.map(x => x instanceof AdminSender ? x.email : x)
			const { error } = await Api.post('/admin/delete-avatar', { senderEmails })
			return { error: error && errorMessages.CONTACT_US, }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}
}