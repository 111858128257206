import React from 'react'

import './styles.scss'
import iconSendMail from 'assets/images/send-mail-8B8B8B.svg'
import iconSendMailHover from 'assets/images/send-mail-61CEF7.svg'

import {
    Loader,
    SearchField,
    Select, SelectOption, IconButton,
    TableBatchContainer, Pagination, BlueButton,
} from 'components'
import { ListItem } from './list-item'

import {
    useAlert,
    AdminUnregisteredUserProvider, useAdminUnregisteredUsers, AdminUnregisteredUser, Api,
} from 'core'
import { ArrayHelper } from 'utils'
import { LockrScanListItem } from './list-item/lockrScan-index'
import fetchImage from '../../../../assets/images/fetch.svg'

export function LockrScanUnregisteredUserEl({
    className = '',
}) {
    const { setError } = useAlert()
    // const { isLoading: isApiLoading, totalCount, unregisteredUsers, searchUnregisteredUsers } = useAdminUnregisteredUsers()

    const [isLoading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')
    const [pageIndex, setPageIndex] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(5)
    const [sortBy, setSortBy] = React.useState('createdAt')
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [unregisteredUsers, setUnregisteredUsers] = React.useState([])
    const [totalCount, setTotalCount] = React.useState(0);
    const [fetchClicked, setFetchClicked] = React.useState(false);

    React.useEffect(() => {
        if (fetchClicked) {
            getUnregisteredUsers()
        }

    }, [searchText, pageIndex, pageSize, sortBy])
    const getUnregisteredUsers = async () => {
        setLoading(true)
        const { data, error } = await Api.get('/lockrscan/admin/unregistered-users', { searchText, pageIndex, pageSize, sortBy });
        if (!error) {
            setUnregisteredUsers(data.results);
            setTotalCount(data.count);
        }
        else {
            setError(error);

        }
        setLoading(false)
    }

    const onFetch = () => {
        getUnregisteredUsers();
        setFetchClicked(true)
    }
    function handleSelectUser(user) {
        if (selectedUsers.some(x => x.id === user.id)) {
            setSelectedUsers(selectedUsers.filter(x => x.id !== user.id))
        } else {
            setSelectedUsers([...selectedUsers, user])
        }
    }

    async function handleBatchResendVerification() {
        setLoading(true)
        const { error } = await AdminUnregisteredUser.sendVerifyEmail(selectedUsers)
        setLoading(false)

        if (error) {
            setError(error)
            return
        }
    }

    return (
        <>
            <div style={{ marginTop: 24 }} className="admin-group-title">Unregistered Users</div>
            <div className={`${className} admin-unregistered-users`}>

                <div className="total">Total: {totalCount}</div>
                <div className="header">

                    <SearchField
                        value={searchText}
                        onChange={setSearchText} />
                    <Select
                        className="view-options"
                        value={sortBy}
                        onChange={(value) => setSortBy(value)}>
                        <SelectOption value='createdAt'>Newest</SelectOption>
                        <SelectOption value='-createdAt'>Oldest</SelectOption>
                        <SelectOption value='-email'>Alphabetical A - Z</SelectOption>
                        <SelectOption value='email'>Alphabetical Z - A</SelectOption>
                    </Select>
                    <BlueButton className='fetch-button' onClick={() => { onFetch(); }}>Fetch <img src={fetchImage}></img></BlueButton>
                </div>
                <div className="list">
                    <LockrScanListItem isHeader />
                    {(isLoading) ? <Loader /> :
                        unregisteredUsers.map((user, index) => {
                            const selected = selectedUsers.some(x => x.id === user.id)

                            return (
                                <LockrScanListItem
                                    key={index}
                                    user={user}
                                    selected={selected}
                                    onSelect={() => handleSelectUser(user)}
                                    onRefresh={() => { getUnregisteredUsers() }}
                                />
                            )
                        })
                    }
                </div>
                <Pagination
                    className="admin-unregistered-users-pagination"
                    totalSize={totalCount}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    onPageIndex={value => setPageIndex(value)}
                    onPageSize={value => setPageSize(value)}
                />
            </div>
        </>
    )
}

