import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton,
	TextField
} from 'components'

import { SenderGroup, useSenderGroup } from 'core'
import { isValidText } from 'utils'
import { errorMessages } from 'constants/error'

const PageIndex = Object.freeze({
	'Settings': 0,
	'DeleteConfirm': 1
})

export function AccountGroupSettingsAlert({
	group,
	onClose = (updated) => { }
}) {
	const { senderGroups, loadSenderGroups } = useSenderGroup()
	const [pageIndex, setPageIndex] = React.useState(PageIndex.Settings)
	const [groupId, setGroupId] = React.useState(group ? group.id : null)
	React.useEffect(() => {
		setGroupId(group ? group.id : null)
		setGroupName(group.name)
	}, [group])
	const [groupName, setGroupName] = React.useState('')
	React.useEffect(() => { setError(null) }, [groupName])
	const [error, setError] = React.useState(null)

	React.useEffect(() => { loadGroupDetails() }, [groupId])

	async function loadGroupDetails() {
		if (!groupId) return

		const { error, result } = await SenderGroup.getDetail(groupId)
		if (error) { }
		if (result) {
			setGroupName(result.name)
		}
	}

	async function handleSave() {
		if (senderGroups.some(e => e.name === groupName && e.id !== groupId)) {
			setError(errorMessages.SENDER_GROUP_DUPLICATED_NAME)
			return
		}

		const { error } = await SenderGroup.update(groupId, groupName)
		if (error) { }
		else {
			onClose(true)
			loadSenderGroups()
		}
	}

	async function handleDelete() {
		const { error } = await SenderGroup.delete(groupId)
		if (error) { }
		else {
			onClose(false)
			loadSenderGroups()
		}
	}

	return (
		<Alert className="account-group-settings-alert" step={pageIndex} onCancel={() => onClose(false)}>
			<AlertPage>
				<AlertTitle>Group Settings</AlertTitle>
				<div className="form">
					<div className="form-item">
						<div>Name</div>
						<div className="input-field">
							<TextField
								type="normal"
								clearable
								value={groupName}
								onChange={(event) => setGroupName(event.target.value)} />
						</div>
					</div>
					{error && <div className="error">{error}</div>}
				</div>
				<AlertButtonContainer>
					<AlertButton disabled={!isValidText(groupName)} onClick={() => handleSave()}>Save</AlertButton>
					<AlertButton type="negative" onClick={() => setPageIndex(PageIndex.DeleteConfirm)}>Delete</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
			<AlertPage>
				<AlertTitle>Delete Group</AlertTitle>
				<AlertMessage>Are you sure you want to delete this Group? By doing so, there will no longer be an association between these Senders allowing for the ease of classification. However, none of the current delivery settings will be impacted - all are still able to be controlled in your dashboard.</AlertMessage>
				<AlertButtonContainer>
					<AlertButton onClick={() => handleDelete()}>Delete</AlertButton>
					<AlertButton type="negative" onClick={() => setPageIndex(PageIndex.Settings)}>Cancel</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}