import React from 'react';

import { useAlert } from 'core/providers';
import { AdminWhiteListIP } from 'core/models';

const AdminWhiteListIPContext = React.createContext({});

export function AdminWhiteListIPProvider({ children }) {
	const { setError } = useAlert();

	const [isLoading, setLoading] = React.useState(false);
	const [totalCount, setTotalCount] = React.useState(0);
	const [whiteListIPAddrs, setWhiteListIPAddrs] = React.useState([]);
	const [params, setParams] = React.useState({});

	async function adminWhiteListIPSearch(params, loading = true) {
		setParams(params);

		if (loading) setLoading(true);
		const { error, totalCount, results } = await AdminWhiteListIP.search(
			params
		);
		setLoading(false);

		if (error) {
			setError(error);
			return;
		}

		setTotalCount(totalCount);
		setWhiteListIPAddrs(results ?? []);
	}

	function adminWhiteListIPReload() {
		adminWhiteListIPSearch(params, false);
	}

	const memoedValue = React.useMemo(
		() => ({
			isLoading,
			whiteListIPAddrs,
			totalCount,

			adminWhiteListIPSearch,
			adminWhiteListIPReload,
		}),
		[isLoading, totalCount, whiteListIPAddrs]
	);

	return (
		<AdminWhiteListIPContext.Provider value={memoedValue}>
			{children}
		</AdminWhiteListIPContext.Provider>
	);
}

/**
 * @typedef {{isLoading: boolean, whiteListIPAddrs: Array<AdminWhiteListIP>, totalCount: number, adminWhiteListIPSearch: Function, adminWhiteListIPReload: Function}} UseAdminWhiteListIPAddress
 * @returns {UseAdminWhiteListIPAddress}
 */
export function useAdminWhiteListIP() {
	return React.useContext(AdminWhiteListIPContext);
}
