import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconUpload from 'assets/images/upload.svg'
import iconClear from 'assets/images/cross/gray-16.svg'

import {
    Loader,
    Icon, Checkbox,
    Switch, ImagePicker,
    BadgeButton,
} from 'components'

import {
    useAlert,
    AdminWhiteListIP, useAdminWhiteListIP
} from 'core'
import { ArrayHelper, DateHelper } from 'utils'

/**
 * @typedef {{whiteListIPAddress: AdminWhiteListIP, isHeader: boolean, selected: boolean, onSelect: Function, onChange: Function}} Props
 * @param {Props} param0
 */
export function ListItem({
    isHeader = false,
    whiteListIPAddress = {},
    selected = false,
    onSelect = () => { },
    onChange = () => { },
}) {
    const { setError } = useAlert()
    const { adminWhiteListIPReload } = useAdminWhiteListIP()

    const { ipAddr, tryCount, createdAt } = whiteListIPAddress

    const [isLoading, setLoading] = React.useState(false)

    function renderDate(date) {
        if (!date) return ''

        return DateHelper.format(date, 'MM/DD/YYYY')
    }

    async function handleDelete() {
        const { error } = await AdminWhiteListIP.delete([ipAddr])

        if (error) {
            setError(error)
            return
        }

        adminWhiteListIPReload()
    }

    return (
        <React.Fragment>
            <div className={`admin-whitelist-ip-address-list-item ${isHeader && 'header'}`}>
                {isHeader ?
                    <>
                        <div className='checkbox'></div>
                        <div className='info'>
                            <div className="ip">IP</div>
                            <div className="created-at">Created</div>
                        </div>
                        <div className='actions'></div>
                    </>
                    :
                    <React.Fragment>
                        {isLoading ? <Loader /> :
                            <React.Fragment>
                                <Checkbox
                                    iconChecked={iconChecked}
                                    iconUnchecked={iconUnchecked}
                                    checked={selected}
                                    onChange={() => onSelect()} />
                                <div className='info'>
                                    <div className="ip">{ipAddr}</div>
                                    <div className="created-at">{renderDate(createdAt)}</div>
                                </div>
                                <div className='actions'>
                                    <BadgeButton
                                        icon={iconClear}
                                        description="Delete"
                                        onClick={() => handleDelete()} />
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}