import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	TextField, TextArea,
	Select, SelectOption, DatePicker, TimePicker,
	WeekdaySelect,
	BlueButton, BlueRoundedButton,
	CustomRadioGroup,
} from 'components'

import {
	useAlert,
	Digest, useDigests, useSenderGroup, useAuth, SenderGroup,
} from 'core'
import { customFrequencies, DateHelper, days, months, ObjectHelper, weekDays } from 'utils'
import { routeToMe } from 'routes'
import moment from 'moment-timezone'

/**
 * @typedef {{isNew: boolean, onClose: Function, isFromGroup: boolean, defaultGroupId: string, senderList: Array}} DigestModalProps
 * @param {DigestModalProps} param0
 */
export function DigestModal({
	isNew = false,
	isFromGroup = false,
	defaultGroupId,
	onClose = () => { },
	senderList = []
}) {
	const navigate = useNavigate()

	const { alert, setAlert, error, setError } = useAlert()
	const { currentDigest, digestUpdateOrCreate, digestsLoad, digestSelect, setEnableDigestSave } = useDigests()
	const { currentUser } = useAuth();
	const { senderGroups } = useSenderGroup();

	const [digest, setDigest] = React.useState(isNew ? null : currentDigest)
	const [name, setName] = React.useState('')
	const [description, setDescription] = React.useState('')
	const [selectedGroupId, setSelectedGroup] = React.useState(defaultGroupId ?? undefined);
	const [privacy, setPrivacy] = React.useState(Digest.Privacy.Public)
	const [startDay, setStartDay] = React.useState(new Date())
	const [startTime, setStartTime] = React.useState(DateHelper.startOfHour(new Date()))
	const [startDate, setStartDate] = React.useState(new Date())
	const [endDate, setEndDate] = React.useState(null)
	const [destinationEmail, setDestinationEmail] = React.useState('');
	const [senders, setSenders] = React.useState(senderList?.length ? senderList : []);
	const [week, setWeek] = React.useState(1)
	const [weekDay, setWeekDay] = React.useState('SU')

	const [customFrequency, setCustomFrequency] = React.useState(3)
	const [interval, setInterval] = React.useState(1)
	const [monthType, setMonthType] = React.useState("day")
	const [monthDay, setMonthDay] = React.useState(1)
	const [month, setMonth] = React.useState(1)
	const [count, setCount] = React.useState(10)
	const [endType, setEndType] = React.useState("never")

	useEffect(() => {
		loadGroupSenders();
	}, [selectedGroupId])

	React.useEffect(() => {
		if (!isNew) setDigest(currentDigest)
	}, [currentDigest])

	React.useEffect(() => {
		if (destinationEmail === '') {
			let selectedEmail = undefined
			const primaryEmails = currentUser?.connectedEmails?.filter?.(item => item.isPrimary)
			if (primaryEmails?.length) {
				selectedEmail = primaryEmails[0].email;
				setDestinationEmail(selectedEmail);
			}
		}
	}, [currentUser])

	React.useEffect(() => {
		if (digest) {
			setName(digest.name)
			setDescription(digest.description)
			setPrivacy(digest.privacy)
			setStartDay(digest.startDate)
			setStartTime(digest.startDate)
			setEndDate(digest.endTime)
			setDestinationEmail(digest.destinationEmail)

			let data = digest.rRuleData
			setCustomFrequency(data.freq)
			setInterval(data.interval)
			if(data.freq == 2){
				setWeekDay(data.byweekday)
				setWeek(data.bysetpos == -1 ? 5 : data.bysetpos)
			}else if(data.freq == 1){
				if(data.bymonthday){
					setMonthDay(data.bymonthday)
					setMonthType('day')
				}
				if(data.byweekday){
					setWeekDay(data.byweekday)
					setWeek(data.bysetpos == -1 ? 5 : data.bysetpos)
					setMonthType('weekday')
				}
				if(data.bymonth){
					setMonth(data.bymonth)
				}
			}else if(data.freq == 0){
				if(data.bymonthday){
					setMonthDay(data.bymonthday)
					setMonthType('day')
				}
				if(data.byweekday){
					setWeekDay(data.byweekday)
					setWeek(data.bysetpos == -1 ? 5 : data.bysetpos)
					setMonthType('weekday')
				}
				if(data.bymonth){
					setMonth(data.bymonth)
				}
			}
		}
	}, [digest])

	async function loadGroupSenders() {
		if (isFromGroup && selectedGroupId) {
			const { error, result } = await SenderGroup.getDetail(selectedGroupId);
			if (error) {
				return;
			}
			setSenders(result.senders);
		}
	}

	function checkChanged() {
		if (!digest) {
			return Boolean(name)
				|| Boolean(description)
				|| !DateHelper.isSame(new Date(), startDate, "hh:00 a")
				|| endDate !== null
		}

		return name !== digest.name
			|| description !== digest.description
			|| !ObjectHelper.equals(startDay, digest.startDate)
			|| !ObjectHelper.equals(startTime, digest.startDate)
			|| !ObjectHelper.equals(endDate, digest.endTime)
	}

	function checkValidation() {
		if (!name) return false
		if (isFromGroup && !selectedGroupId) return false
		// if (!description) return false
			if (!startTime) return false
		return true
	}

	function handleStartTime(value) {
		setStartTime(value)
		setStartDate(DateHelper.merge({ day: startDate, time: value }))
	}

	function generateCustomRule() {
		let data = { freq: customFrequency, interval: parseInt(interval) }

		if (customFrequency == 2) {
			data.byweekday = weekDay
		}else if (customFrequency == 1) {
			if(monthType == 'day'){
				data.bymonthday = monthDay
			}else if(monthType == 'weekday'){
				data.byweekday = weekDay
				data.bysetpos = week == 5 ? -1 : week
			}
		}else if (customFrequency == 0) {
			if(monthType == 'day'){
				data.bymonth = month
				data.bymonthday = monthDay
			}else if(monthType == 'weekday'){
				data.byweekday = weekDay
				data.bysetpos = week == 5 ? -1 : week
				data.bymonth = month
			}
		}
		if(endType == "count"){
			data.count = count
		}else if(endType == "date"){
			data.until = moment(endDate).toDate()
		}
		return data
	}

	async function handleSave() {
		let cRuleData
		cRuleData = generateCustomRule()
		
		const params = {
			digestId: digest?.id,
			name,
			description,
			privacy,
			// frequency,
			startDate,
			endDate,
			destinationEmail,
			rRuleData: cRuleData,
			timezone: currentUser.timezone
		}

		const { error, result } = await digestUpdateOrCreate(params, false)

		if (error) {
			setError(error)
			return
		}

		if (isFromGroup && senders.length) {
			const senderIds = senders.map(senderItem => {
				return senderItem.id;
			})
			const digestId = result.id;
			const { error } = await Digest.addSenders(digestId, senderIds);
			if (!error) {
				handleClose(true)
				digestsLoad(false)
				digestSelect(result);
				setEnableDigestSave(true);
			}
			return;
		} else {
			digestsLoad(false)
			handleClose(true)
			digestSelect(result);
		}

	}

	function handleClose(force = false) {
		if (!force && checkChanged()) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving?',
				buttons: [
					{ type: 'positive', label: 'Stay on this page' },
					{ type: 'negative', label: 'Leave this page', onClick: () => handleClose(true) },
				]
			})
			return
		}

		navigate(routeToMe({ searches: { settings: null } }))
		onClose()
	}

	function getDestinationEmail() {
		if (!!destinationEmail) {
			return destinationEmail;
		}
		let email = '';
		currentUser.connectedEmails.forEach(emailItem => {
			if (emailItem.isPrimary) {
				email = emailItem.email;
			}
		})
		return email;
	}

	function handleWeekDayChange(week, weekDay) {
		setWeek(week)
		setWeekDay(weekDay)
	}

	return (
		<Alert
			show={!alert && !error}
			className='new-digest-modal'
			onCancel={() => handleClose()}>
			<AlertPage>
				<AlertTitle>{digest ? 'Settings' : isFromGroup ? 'New Digest - From Group' : 'New Digest'}</AlertTitle>
				<div className='new-digest-modal-wrapper'>
					{isFromGroup && <div className='group'>
						<label>Select Group</label>
						<Select onChange={id => {
							setSelectedGroup(id)
						}} value={selectedGroupId} isTransparent >
							{senderGroups.map((groupItem, index) =>
								<SelectOption
									key={index}
									value={groupItem.id}>
									{groupItem.name}
								</SelectOption>
							)}
						</Select>
					</div>}
					<div className='group'>
						<label>Name</label>
						<TextField
							type="normal"
							clearable
							value={name}
							onChange={(event) => setName(event.target.value)} />
					</div>
					<div className='group'>
						<label>Description</label>
						<TextArea
							placeholder="Optional"
							clearable
							value={description}
							onChange={(event) => setDescription(event.target.value)} />
					</div>
					<div className='group d-none'>
						<label>Privacy</label>
						<Select
							value={privacy}
							onChange={(value) => setPrivacy(value)}>
							{Object.keys(Digest.Privacy).map((key, index) =>
								<SelectOption
									key={index}
									value={Digest.Privacy[key]}>
									{Digest.Privacy[key]}
								</SelectOption>
							)}
						</Select>
					</div>
					<div className='group-divider'></div>
					<div className='group'>
						<label>Destination</label>
						<Select
							value={getDestinationEmail()}
							onChange={(value) => setDestinationEmail(value)}>
							{currentUser?.connectedEmails?.filter?.(connectedEmailItem => connectedEmailItem.isVerified).map((connectedEmailItem, index) => {
								return (
									<SelectOption
										key={index}
										value={connectedEmailItem.email}
									>{connectedEmailItem.email}</SelectOption>
								)
							})}
						</Select>
					</div>
						<>
							<div className='group'>
								<label>Repeat Every</label>
								<div className='custom-repeat-every'>
									<TextField
										className="form-input"
										inputType="number"
										value={interval}
										onChange={(event) => setInterval(event.target.value)}
									/>
									<Select
										onChange={value => {
											setCustomFrequency(value)
										}}
										value={customFrequency}
									>
										{customFrequencies.map((groupItem, index) =>
											<SelectOption
												key={index}
												value={groupItem.id}>
												{groupItem.label}
											</SelectOption>
										)}
									</Select>
								</div>
							</div>
							{customFrequency == 3 ? <></> : customFrequency == 2 ? 
									<div className='group'>
										<label>on</label>
										<Select
											onChange={value => {
												setWeekDay(value)
											}}
											value={weekDay}
										>
											{weekDays.map((groupItem, index) =>
												<SelectOption
													key={index}
													value={groupItem.id}>
													{groupItem.label}
												</SelectOption>
											)}
										</Select>
									</div>
									: customFrequency == 1 ? <><div className='group'>
										<div className='custom-month-wrapper'>
											<CustomRadioGroup
												items={[{ id: 'day', label: 'on day' }, { id: 'weekday', label: 'on the' }]}
												selected={monthType}
												onChange={(value) => setMonthType(value)}
												className='group-radio-group'
											/>
											<div className='custom-month-day-wrapper'>
												<Select
													onChange={value => {
														setMonthDay(value)
													}}
													value={monthDay}
													className="custom-select custom-select-full-width"
												>
													{days.map((groupItem, index) =>
														<SelectOption
															key={index}
															value={index + 1}
														>
															{groupItem}
														</SelectOption>
													)}
												</Select>
												<TimePicker
													className='start-time-selector'
													keepLabel
													hasMinutes={false}
													label={DateHelper.format(DateHelper.startOfHour(new Date()), 'hh:mm a')}
													weekValue={week}
													weekDayValue={weekDay}
													onChange={(week, weekDay) => handleWeekDayChange(week, weekDay)}
													isWeekDaySelection={true}
												/> 
											</div>
										</div>
										</div>
									</> : customFrequency == 0 ? <><div className='group'>
										<div className='custom-month-wrapper'>
											<CustomRadioGroup
												items={[{ id: 'day', label: 'on' }, { id: 'weekday', label: 'on the' }]}
												selected={monthType}
												onChange={(value) => setMonthType(value)}
												className='group-radio-group'
											/>
											<div className='custom-month-day-wrapper'>
												<div className='custom-month-year-selection-wrapper' style={{ gap: '30px' }}>
													<Select
														onChange={value => {
															setMonth(value)
														}}
														value={month}
														className='custom-select'
													>
														{months.map((groupItem, index) =>
															<SelectOption
																key={index}
																value={index + 1}
															>
																{groupItem}
															</SelectOption>
														)}
													</Select>
													<Select
														onChange={value => {
															setMonthDay(value)
														}}
														value={monthDay}
														className='custom-select'
													>
														{days.map((groupItem, index) =>
															<SelectOption
																key={index}
																value={index + 1}
															>
																{groupItem}
															</SelectOption>
														)}
													</Select>
												</div> 
												<div className='custom-month-year-selection-wrapper'>
													<TimePicker
														className='start-time-selector'
														keepLabel
														hasMinutes={false}
														label={DateHelper.format(DateHelper.startOfHour(new Date()), 'hh:mm a')}
														weekValue={week}
														weekDayValue={weekDay}
														onChange={(week, weekDay) => handleWeekDayChange(week, weekDay)}
														isWeekDaySelection={true}
													/> 
													<label>of</label>
													<Select
														onChange={value => {
															setMonth(value)
														}}
														value={month}
														className='custom-select'
													>
														{months.map((groupItem, index) =>
															<SelectOption
																key={index}
																value={index + 1}>
																{groupItem}
															</SelectOption>
														)}
													</Select>
												</div>
											</div>
										</div>
										</div>
										</> : <div></div>}
						</>
					<div className='group'>
						<label>Time</label>
						<TimePicker
							className='start-time-selector'
							keepLabel
							hasMinutes={false}
							label={DateHelper.format(startTime, 'hh:mm a')}
							value={startTime}
							onChange={value => handleStartTime(value)}
						/>
					</div>
					<div className='group'>
						<label>End</label>
						<div className='custom-repeat-every'>
							<Select
								value={endType}
								onChange={(value) => setEndType(value)}>
								{[{id: "never", value: "Never"}, {id: "count", value: "After"}, {id: "date", value: "On date"}].map((obj, index) =>
									<SelectOption
										key={index}
										value={obj.id}>
										{obj.value}
									</SelectOption>
								)}
							</Select>
							{endType == "count" ? <TextField
								className="form-input count-input-wrapper"
								inputType="number"
								value={count}
								onChange={(event) => setCount(event.target.value)}
							/> : endType == "date" ? 
							<DatePicker
								className='end-date-selector'
								keepLabel
								label={Digest.formatDay(endDate)}
								value={endDate}
								onChange={value => setEndDate(value)}
								minDate={new Date()}
							/> : <></>}
							{endType == "count" && <label>occurrences</label>}
						</div>
					</div>
					<div className='save-container'>
						<BlueButton
							disabled={!checkValidation()}
							onClick={() => handleSave()}>
							Save
						</BlueButton>
					</div>
				</div>
			</AlertPage>
		</Alert>
	)
}