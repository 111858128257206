import React from 'react'

import './styles.scss'

import {
	Loader,
} from 'components'
import { ReferralListItem } from './referral-list-item'

import { useReferral } from 'core'

export function FormTrackInvites({
	className = ""
}) {
	const { isLoading: isReferralInFetch, referrals, loadReferrals } = useReferral()

	// Load referrals when tab is changed
	React.useEffect(() => { loadReferrals() }, [className])

	return (
		<div className={`referrals-form-track-invites ${className}`}>
			{isReferralInFetch ? <Loader /> :
				referrals.map((referral, index) =>
					<ReferralListItem key={index} value={referral} />
				)
			}
		</div>
	)
}