import React from 'react'

import { SectionItem } from 'scenes/account/settings/section'

export function DiscountNewsletterEl({
	className = '',
	value,
	onChange = (value) => { },
	onEdit = () => { },
}) {
	return (
		<React.Fragment>
			<SectionItem
				title="lockrDeals"
				description="Receive an email summary of discounts hidden in your inbox you might have otherwise missed."
				value={value}
				onChange={(value) => onChange(value)}>
				{value &&
					<div className='edit-details'>
						<button onClick={() => onEdit()}>
							Edit details
						</button>
					</div>
				}
			</SectionItem>
		</React.Fragment>
	)
}