import React from 'react'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton
} from './core'

/**
 * @typedef {{className: string, type: ('positive'|'negative'|'link'), title: string, disabled: boolean, onClick: * }} ButtonProps
 * @typedef {{title: string, message: string, buttons: Array<ButtonProps>, dismissable: boolean, onClose: Function}} InfoAlertProps
 * @param {InfoAlertProps} param0
 */
export function InfoAlert({
	title,
	message,
	buttons,
	dismissable = true,
	onClose = () => { }
}) {
	const [isClosed, setClosed] = React.useState(false)

	function handleButtonClick(button) {
		const { onClick = () => { } } = button
		onClick()
		handleClose()
	}

	function handleClose() {
		setClosed(true)
		onClose()
	}

	return (
		<React.Fragment>
			{!isClosed &&
				<Alert onCancel={dismissable ? () => handleClose() : null}>
					<AlertPage>
						{title && <AlertTitle>{title}</AlertTitle>}
						{message && <AlertMessage value={message} />}
						{buttons &&
							<AlertButtonContainer>
								{buttons.map((button, index) =>
									<AlertButton key={index} {...button} onClick={() => handleButtonClick(button)}>{button.title}</AlertButton>
								)}
							</AlertButtonContainer>
						}
					</AlertPage>
				</Alert>
			}
		</React.Fragment>
	)
}