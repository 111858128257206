import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage,
	AlertTitle, AlertMessage,
	AlertButtonContainer, AlertButton
} from 'components/alert/core'

export function TipAlert({
	className = '',
	title = 'Tip',
	message = '',
	url = '',
	onClose = () => { }
}) {
	function handleClickLink() {
		onClose()
	}

	return (
		<Alert className={`${className} alert-tip`} onCancel={() => onClose()}>
			<AlertPage>
				<AlertTitle>{title}</AlertTitle>
				<AlertMessage>{message}</AlertMessage>
				<AlertButtonContainer>
					<AlertButton type='link' url={url} onClick={() => handleClickLink()}>Learn More</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}