import React from 'react'



import { useAuth } from 'core'
import { SectionItem } from '../section'

export function LockrScanReportingEl({
    className = '',
    value,
    onChange = (value) => { },
    onEdit = () => { },
}) {
    return (
        <React.Fragment>
            <SectionItem
                title="lockrScan Reports"
                description="Receive an email summary of top offenders at your desired frequency."
                value={value}
                onChange={(value) => onChange(value)}>
                {value &&
                    <div className='edit-details'>
                        <button onClick={() => onEdit()}>
                            Edit details
                        </button>
                    </div>
                }
            </SectionItem>
        </React.Fragment>
    )
}
