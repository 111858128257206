import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	Select, SelectOption,
	TimePicker, DatePicker,
	BlueButton,
	AlertButtonContainer,
	AlertButton,
} from 'components'

import {
	useAlert,
	useAuth,
	LockrBox
} from 'core'
import {
	range,
	DateHelper,
	ObjectHelper,
} from 'utils'

export function LockrBoxModalEl({
	lockrBox: initialLockrBox,
	onChange = (value) => { },
	onSave = () => { },
	onClose = () => { },
}) {
	const startOfWeek = DateHelper.startOfWeek(new Date())

	const { setError } = useAlert()
	const { currentUser, userGetProfile } = useAuth()

	/** @type {[LockrBox, Function]} */
	const [lockrBox, setLockrBox] = React.useState(initialLockrBox ?? LockrBox.default)
	const [frequency, setFrequency] = React.useState(initialLockrBox?.frequency ?? LockrBox.Frequency.Daily)
	const [deliveryDay, setDeliveryDay] = React.useState(initialLockrBox?.deliveryDate ?? Date.now())
	const [deliveryTime, setDeliveryTime] = React.useState(initialLockrBox?.deliveryDate ?? Date.now())

	React.useEffect(() => {
		setLockrBox(currentUser?.lockrBox ?? LockrBox.default)
	}, [currentUser])

	React.useEffect(() => {
		setLockrBox(initialLockrBox ?? LockrBox.default)
	}, [initialLockrBox])

	React.useEffect(() => {
		// console.log(lockrBox?.deliveryDate)
		setFrequency(lockrBox?.frequency)
		setDeliveryDay(lockrBox?.deliveryDate)
		setDeliveryTime(lockrBox?.deliveryDate)
	}, [lockrBox])

	function isSaveEnabled() {
		if (!currentUser || !lockrBox) return true

		return Boolean(currentUser.lockrBox?.frequency === lockrBox.frequency && DateHelper.isSame(currentUser.lockrBox?.deliveryDate, lockrBox.deliveryDate))
	}

	function handleFrequency(value) {
		onChange({ ...lockrBox, frequency: value })
		// setLockrBox({ ...lockrBox, frequency: value })
	}

	function handleDeliveryDay(value) {
		onChange({ ...lockrBox, deliveryDate: DateHelper.merge({ day: value, time: deliveryTime }) })
	}

	function handleDeliveryTime(value) {
		onChange({ ...lockrBox, deliveryDate: DateHelper.merge({ day: deliveryDay, time: value }) })
	}

	async function handleSave() {
		await onSave()
		onClose()
	}

	return (
		<div className='lockr-box-modal-alert-parent'>
			<Alert className={`lockr-box-modal`}
				onCancel={() => onClose()}>
				<AlertPage>
					<AlertTitle>Edit lockrBox Settings</AlertTitle>
					<div className={`lockr-box-modal-wrapper ${(!currentUser || !lockrBox) && 'd-none'}`}>
						<div>Select frequency and a delivery time:</div>
						<div className="lockr-box-modal-group">
							<div>How often</div>
							<Select
								className="frequency-selector"
								value={frequency}
								onChange={(value) => handleFrequency(value)}>
								<SelectOption value={LockrBox.Frequency.Daily}>Every day</SelectOption>
								<SelectOption value={LockrBox.Frequency.Weekly}>Every week</SelectOption>
								<SelectOption value={LockrBox.Frequency.Monthly}>Every month</SelectOption>
							</Select>
						</div>
						{frequency === LockrBox.Frequency.Daily &&
							<div className="lockr-box-modal-group">
								<div>Time</div>
								<TimePicker
									className="delivery-time-selector"
									keepLabel
									label={DateHelper.format(deliveryTime, 'hh:mm a')}
									hasMinutes={false}
									value={deliveryTime}
									onChange={(value) => handleDeliveryTime(value)} />
							</div>
						}
						{frequency === LockrBox.Frequency.Weekly &&
							<React.Fragment>
								<div className="lockr-box-modal-group">
									<div>Day</div>
									<Select
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(deliveryDay, 'dddd')}>
										{range(0, 6).map((i) => DateHelper.addDay(startOfWeek, i)).map((date, index) =>
											<SelectOption
												key={index}
												value={date}
												selected={DateHelper.isSameDay(deliveryDay, date)}
												onClick={() => handleDeliveryDay(date)}>{DateHelper.format(date, 'dddd')}
											</SelectOption>
										)}
									</Select>
								</div>
								<div className="lockr-box-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
						{frequency === LockrBox.Frequency.Monthly &&
							<React.Fragment>
								<div className="lockr-box-modal-group">
									<div>Day</div>
									<DatePicker
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(deliveryDay, 'Do')}
										value={deliveryDay}
										onChange={(value) => handleDeliveryDay(value)} />
								</div>
								<div className="lockr-box-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
					</div>
					<AlertButtonContainer>
						<AlertButton
							type="positive"
							disabled={isSaveEnabled()}
							onClick={() => handleSave()}>
							Save
						</AlertButton>
					</AlertButtonContainer>
				</AlertPage>
			</Alert>
		</div>
	)
}