import React from 'react'

import { useAlert } from 'core/providers'
import { AdminBlockedExtensionDomain } from 'core/models'

const AdminBlockedExtensionDomainsContext = React.createContext({})

export function AdminBlockedExtensionDomainsProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [blockedDomains, setBlockedDomains] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)

	async function searchBlockedDomains(params) {
		setLoading(true)
		const { error, totalCount, results } = await AdminBlockedExtensionDomain.search(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(totalCount)
		setBlockedDomains(results ?? [])
	}

	async function deleteBlockedDomains(domains = []) {
		setLoading(true)
		const { error } = await AdminBlockedExtensionDomain.destroy({ domains })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setBlockedDomains(blockedDomains.filter(x => !domains.includes(x.domain)))
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		blockedDomains,
		totalCount,

		searchBlockedDomains,
		deleteBlockedDomains,
	}), [isLoading, blockedDomains, totalCount])

	return (
		<AdminBlockedExtensionDomainsContext.Provider value={memoedValue}>
			{children}
		</AdminBlockedExtensionDomainsContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, blockedDomains: Array<AdminBlockedExtensionDomain>, totalCount: number, searchBlockedDomains: Function, deleteBlockedDomains: Function}} UseAdminBlockedExtensionDomains
 * @returns {UseAdminBlockedExtensionDomains}
 */
export function useAdminBlockedExtensionDomains() {
	return React.useContext(AdminBlockedExtensionDomainsContext)
}