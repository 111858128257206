import React from "react";
import { DomainHelper, isValidText } from "utils";
import { AlertButton, AlertButtonContainer, AlertPage, AlertTitle, Alert } from "./core";
import { TextField } from "components/text-field";
import { useDomainGroupListContext } from "core/providers/admin/domain-group-list";

export function AddNewDomain({ onClose = () => { }, onDomainSave = () => { } }) {
    const [domain, setDomain] = React.useState('');

    return (
        <Alert className="account-new-group-alert" onCancel={() => onClose(false)}>
            <AlertPage>
                <AlertTitle>Add Domain</AlertTitle>
                <div className="form">
                    <div className="form-item">
                        <div>Domain Name</div>
                        <div className="input-field">
                            <TextField
                                type="normal"

                                value={domain}
                                onChange={(event) => setDomain(event.target.value)} />
                        </div>
                    </div>
                    {/* {error && <div className="error">{error}</div>} */}
                </div>
                <AlertButtonContainer>
                    <AlertButton className='btn-create-group' onClick={() => { onDomainSave(domain); onClose() }} disabled={(!isValidText(domain))} >Save</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}