import React from 'react'

import './styles.scss'

import { config } from 'constants/config'

export function FaqContainer({
	className = "",
}) {
	return (
		<div className={`faq-container ${className}`}>
			<div className="title">FAQs</div>
			<div className="group">
				<div>How do I get all this cool swag?</div>
				<div>
					For every referral that completes registration you'll earn 1 credit. Credits can earn rewards over time. So invite your friends and share lockrMail's time-saving techniques with them!
				</div>
			</div>
			<div className="group">
				<div>What should I tell my friends about lockrMail?</div>
				<div>
					<a href={config.LOCKR_URL} target="_blank">lockrMail.com</a> is a free tool that acts as a pre-filter for your inbox, keeping your identity private and secure. Whether making an online purchase, registering on a site for access to content, or RSVPing for an event, use lockrMail to filter email promotions - controlling which emails pass through to your personal inbox and which remain in lockrMail. By leveraging lockrMail's dynamic settings, you can save hours every week!
				</div>
			</div>
		</div>
	)
}