import React from 'react'

import './styles.scss'

import {
	Alert
} from '../'
import { ViewEl } from './view'
import { EditEl } from './edit'
import { AlertPage } from '../alert'

export function SpecialLinkModal({
	permalink,
	specialLink,
	onClose = (updated) => { }
}) {
	const PageIndex = Object.freeze({ View: 0, Edit: 1 })
	const [pageIndex, setPageIndex] = React.useState(0)
	React.useEffect(() => {
		if (permalink || !specialLink) setPageIndex(PageIndex.Edit)
		else setPageIndex(PageIndex.View)
	}, [permalink, specialLink])

	function handleClose(updated) {
		onClose(updated)
	}

	return (
		<Alert className="special-link-modal" step={pageIndex} onCancel={handleClose}>
			<AlertPage className={pageIndex !== PageIndex.View && 'd-none'}>
				<ViewEl
					className={pageIndex !== PageIndex.View && 'd-none'}
					specialLink={specialLink}
					onEdit={() => setPageIndex(PageIndex.Edit)}
				/>
			</AlertPage>
			<AlertPage className={pageIndex !== PageIndex.Edit && 'd-none'}>
				<EditEl
					className={pageIndex !== PageIndex.Edit && 'd-none'}
					initialPermalink={permalink}
					specialLink={specialLink}
					onSaved={() => handleClose(true)}
					onCancel={() => specialLink ? setPageIndex(PageIndex.View) : handleClose(false)}
				/>
			</AlertPage>
		</Alert>
	)
}