const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const imgLazyLoader = (function () {
	function preprocess(imgEl) {
		imgEl.style.opacity = 0
		imgEl.style.transition = 'opacity 100ms ease-in-out'
		setDimension(imgEl)
	}

	/**
	 *
	 * @param {HTMLElement} imgEl
	 * @param {number=} targetWidth
	 * @param {number=} targetHeight
	 */
	function setDimension(imgEl, targetWidth, targetHeight) {
		requestAnimationFrame(() => {
			imgEl.width = targetWidth || 32
			imgEl.height = targetHeight || 32
		})
	}

	function process(imgEl) {
		setTimeout(() => {
			imgEl.classList.remove('lazy')

			const dataSrc = imgEl.getAttribute('data-src')
			if (dataSrc) {
				imgEl.addEventListener('load', () => {
					imgEl.style.opacity = null
					setDimension(imgEl, imgEl.naturalWidth, imgEl.naturalHeight)
				})
				imgEl.src = dataSrc
				imgEl.removeAttribute('data-src')
			} else {
				imgEl.style.opacity = null
				if (imgEl.naturalWidth > 0 && imgEl.naturalHeight > 0) {
					setDimension(imgEl, imgEl.naturalWidth, imgEl.naturalHeight)
				}
			}
		}, 0)
	}

	function load() {
		const imageEls = document.querySelectorAll("img.lazy")
		if ("IntersectionObserver" in window && !isSafari) {
			// console.log(1)
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return

					let lazyImage = entry.target
					// if (getComputedStyle(lazyImage).display === 'none') return

					process(lazyImage)

					if (!isSafari) observer.unobserve(lazyImage)
					// console.log("lazy loaded", lazyImage)
				})
			})

			imageEls.forEach((imageEl) => {
				preprocess(imageEl)
				observer.observe(imageEl)
			})
		} else {
			// console.log(2)
			imageEls.forEach((imageEl) => {
				preprocess(imageEl)
				process(imageEl)
			})
		}
	}

	return { load }
})()

const bgLazyLoader = (function () {
	function process(el) {
		setTimeout(() => {
			el.classList.remove('lazy')
			let dataSrc = el.getAttribute('data-src')
			if (dataSrc) {
				dataSrc = dataSrc.includes("url") ? dataSrc : `url("${dataSrc}")`
				if (el.style.backgroundImage) {
					el.style.backgroundImage = `${el.style.backgroundImage}, ${dataSrc}`
				} else {
					el.style.backgroundImage = dataSrc
				}
				el.removeAttribute('data-src')
			}
		}, 0)
	}

	function load() {
		const els = [
			...document.querySelectorAll("div.lazy"),
			...document.querySelectorAll("header.lazy"),
		]

		if ("IntersectionObserver" in window) {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return

					let el = entry.target
					process(el)
					observer.unobserve(el)
					// console.log(lazyImage)
				})
			})

			els.forEach((el) => {
				observer.observe(el)
			})
		} else {
			els.forEach((el) => {
				process(el)
			})
		}
	}

	return { load }
})()

const linkLazyLoader = (function () {
	function process(el) {
		el.classList.remove("lazy")
		el.innerHTML = el.getAttribute("data-src")
	}

	function load() {
		const els = document.querySelectorAll("a.lazy")
		if ("IntersectionObserver" in window) {
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return

					let lazyA = entry.target
					process(lazyA)
					observer.unobserve(lazyA)
					// console.log(lazyA)
				})
			})

			els.forEach((el) => {
				observer.observe(el)
			})
		} else {
			els.forEach((el) => {
				el.style.innerHTML = el.getAttribute("data-src")
			})
		}
	}

	return { load }
})()

export const fontLazyLoader = (function () {
	const headers = document.getElementsByTagName('head')
	const header = (headers && headers.length > 0) ? headers[0] : null

	function preconnect() {
		if (!header) return

		const preconnectEl = document.createElement('link')
		preconnectEl.rel = 'preconnect'
		preconnectEl.href = 'https://fonts.googleapis.com'
		header.appendChild(preconnectEl)
	}

	function load(url) {
		preconnect()

		// the 'fetch' equivalent has caching issues
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		// xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
		xhr.onreadystatechange = () => {
			if (xhr.readyState == 4 && xhr.status == 200) {
				let css = xhr.responseText;
				css = css.replace(/}/g, 'font-display: swap; }');

				const style = document.createElement('style');
				const el = document.createTextNode(css)
				style.appendChild(el);
				header.appendChild(style);
			}
		};
		xhr.send();
	}

	return { load }
})()

export const lazyLoader = (function () {
	function load() {
		requestAnimationFrame(() => {
			imgLazyLoader.load()
			bgLazyLoader.load()
			linkLazyLoader.load()
		})
	}

	function init() {
		load()

		const observer = new MutationObserver(load)
		observer.observe(document, { subtree: true, childList: true });
	}

	return { init }
})()