import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useAlert, Sender } from 'core'
import { routes, redirectTo, parseSearchParams } from 'routes'

// filter/437c2bde-f97a-4fd1-a12f-8f4aff4504c9?email=dailyskimm@morning7.theskimm.com
// filter/c6706020-158f-4034-b474-7d747c31544e?email=eletters@om.adexchanger.com
// filter/c9cb8c55-1024-4e72-94db-13f1b0ce2e2b?email=westelm@mail.westelm.com

export function OnboardCreateFilter(props) {
	const navigate = useNavigate()
	const { senderId } = useParams()
	const [searchParams] = useSearchParams()

	const { setAlert } = useAlert()

	React.useEffect(() => {
		redirect()
	}, [])

	async function redirect() {
		const { email } = parseSearchParams(searchParams)

		const { error, errorCode, result } = await Sender.getSender(senderId)
		if (error) {
			navigate(redirectTo({
				route: routes.Dashboard,
			}), {
				state: { error, errorCode },
			})
			return
		}

		if (!result?.isVerified) {
			setAlert({
				message: `lockrMail now requires each destination email to be verified prior to any delivery of messages. This is a new requirement, but one that we implement to improve the overall system and reliability.
					<br><br>
					To re-send an activation email to the destination email ${result?.connectedEmail?.email}, please click the confirmation below and an email with an authentication link will immediately be delivered. The link expires in 24-hours.
					<br><br>
					No messages will be delivered to this destination email until it is verified. We appreciate you complying with this new lockrMail policy.`,
				buttons: [
					{
						title: 'Send email',
						type: 'positive',
						onClick: () => {
							User.sendVerifyEmail(result?.connectedEmail?.email)
							setAlert(null)
						}
					},
				]
			})
			navigate(redirectTo({
				route: routes.Dashboard,
			}))
			return
		}

		if (result?.filterId) {
			navigate(redirectTo({
				route: routes.Dashboard,
				searches: { highlight: result.id, filterId: result?.filterId },
			}))
			return
		}

		navigate(redirectTo({
			route: routes.Dashboard,
			searches: { highlight: result.id },
		}), {
			state: { filterSender: result }
		})
	}

	return (
		<></>
	)
}