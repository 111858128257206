import React from 'react'

import './styles.scss'
import imgRedFlag from 'assets/images/flag-red.svg'
import imgUnblock from 'assets/images/unblock-50px-8B8B8B.svg'
import imgUnblockHover from 'assets/images/unblock-50px-61CEF7.svg'

import {
	Icon, Checkbox, IconButton,
} from 'components'

import { useAlert, AdminUser, useAdminDeletedUsers } from 'core'
import { DateHelper } from 'utils'

export function UserListItem({
	user = {},
	isHeader = false,
	selected = false,
	onSelect = (value) => { },
	onRefresh = () => { }
}) {
	const { setAlert, setError } = useAlert()
	const { adminDeletedUsersReload } = useAdminDeletedUsers()

	const { id, avatar, name, email, isBlocked, createdAt, deletedAt } = user

	function handleClick() {
		if (isHeader) return
	}

	async function unblock() {
		const { error } = await AdminUser.unblockUsers([id])
		if (error) {
			setError(error)
			return
		}

		adminDeletedUsersReload()
	}

	function handleUnblock() {
		setAlert({
			message: 'Are you sure you want to unblock this user?',
			buttons: [
				{ type: 'positive', label: 'Yes', onClick: () => unblock() },
				{ type: 'negative', label: 'No', },
			]
		})
	}

	async function undelete() {
		const { error } = await AdminUser.undeleteUsers([id])
		if (error) {
			setError(error)
			return
		}

		adminDeletedUsersReload()
	}

	function handleUnDelete() {
		setAlert({
			message: 'Are you sure you want to undelete this user?',
			buttons: [
				{ type: 'positive', label: 'Yes', onClick: () => undelete() },
				{ type: 'negative', label: 'No', },
			]
		})
	}

	function renderDate(date) {
		if (!date) return ''

		return 'Create ' + DateHelper.format(date, 'MM/DD/YYYY')
	}

	return (
		<>
			<div className={`user-list-item ${isHeader && 'header'}`} onClick={() => handleClick()}>
				{isHeader ?
					<>
						<div className="status"></div>
						<div className="name"></div>
						<div className="email">Email</div>
						<div className="created-at">Created</div>
						<div className="deleted-at">Deleted</div>
						<div className="actions"></div>
					</>
					:
					<>
						<div className="status">
							{isBlocked && <img src={imgRedFlag} alt="" />}
						</div>
						<Icon className="avatar" src={avatar} placeholder={name} />
						<div className="name">{name}</div>
						<div className="email">{email}</div>
						<div className="created-at">{renderDate(createdAt)}</div>
						<div className="deleted-at">{renderDate(deletedAt)}</div>
						<div className="actions">
							{isBlocked &&
								<IconButton
									className="btn-unblock"
									normal={imgUnblock}
									hover={imgUnblockHover}
									onClick={() => handleUnblock()} />
							}
							{!isBlocked &&
								<IconButton
									className="btn-unblock"
									normal={imgUnblock}
									hover={imgUnblockHover}
									onClick={() => handleUnDelete()} />
							}

						</div>
					</>
				}
			</div>
		</>
	)
}