import React from 'react'

import './styles.scss'

export class AlertPage extends React.Component {
	title = () => {
		const { children } = this.props
		const results = []
		React.Children.forEach(children, (child) => {
			if (child.type.name === "AlertTitle") {
				results.push(child)
			}
		})
		return results
	}

	buttons = () => {
		const { children } = this.props
		const results = []
		React.Children.forEach(children, (child) => {
			if (child.type.name === "AlertButton") {
				results.push(child)
			}
		})
		return results
	}

	message = () => {
		const { children } = this.props
		const results = []
		React.Children.forEach(children, (child) => {
			if (child.type.name === "AlertMessage") {
				results.push(child)
			}
		})
		return results
	}

	render() {
		const { className = '', children } = this.props
		return (
			<div className={`alert-page ${className}`}>
				{children}
			</div>
		)
	}
}