import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

import {
	TextField,
	SelectDropDown, SelectOption, Icon,
} from 'components'

import { useAlert, DomainSetting } from 'core'
import { DomainHelper, ArrayHelper, ObjectHelper } from 'utils'

let searchTimer = null

export function DomainInput({
	className = '',
	searchText: initialSearchText = '',
	disabled = false,
	onChange = (domainUrl) => { },
	onAdd = (domain) => { }
}) {
	const { width } = useWindowSize()
	const { setError } = useAlert()

	const menuRef = React.useRef()

	const [searchText, setSearchText] = React.useState(initialSearchText)
	React.useEffect(() => { setSearchText(initialSearchText) }, [initialSearchText])
	const [searchResults, setSearchResults] = React.useState([])

	const [domainSetting, setDomainSetting] = React.useState(null)
	React.useEffect(() => {
		if (domainSetting) {
			setSearchText('')
			onChange(domainSetting.domain)
		} else {
			onChange("")
		}
	}, [domainSetting])

	function isDesktop() { return width >= 992 }

	function isValidDomain() {
		if (!isDesktop()) return false
		if (!DomainHelper.isDomain(searchText)) return false

		const parsedDomain = DomainHelper.parseDomain(searchText)
		if (!parsedDomain) return false

		return true
	}

	async function search(domain) {
		if (!ObjectHelper.isValid(domain)) return

		const { error, results } = await DomainSetting.search(domain)
		if (error) {
			setError(error)
			return
		}

		setSearchResults(results || [])

		if (menuRef.current) {
			if (results.length <= 0) menuRef.current.close()
			else menuRef.current.open()
		}
	}

	async function save() {
		if (!isDesktop()) return
		if (!DomainHelper.isDomain(searchText)) return

		const parsedDomain = DomainHelper.parseDomain(searchText)
		if (!parsedDomain) return


		onAdd(parsedDomain)
		setSearchText("")
	}

	async function saveDomainSetting(domainSetting) {
		const { domain, avatar } = domainSetting
		if (!DomainHelper.isDomain(domain)) return

		const parsedDomain = DomainHelper.parseDomain(domain)
		if (!parsedDomain) return


		onAdd({domain: parsedDomain, avatar})
		setSearchText("")
	
	}

	function handleDomainChange(value) {
		setSearchText(value)

		if (searchTimer) clearTimeout(searchTimer)
		searchTimer = setTimeout(() => {
			search(value)
		}, 500)
		onChange(value)
	}

	function handleSearchResultClick(value) {
		setDomainSetting(value)
		if (isDesktop()) saveDomainSetting(value)
		setSearchResults([])
	}

	return (
		<>
			<div className={`domain-input h-menu-parent ${className} ${disabled && 'disabled'}`}>
				<TextField
					type="normal"
					placeholder={<span><span>+</span> Add a domain</span>}
					imodal={<>
						Control email blocks and browser pop-up notifications by domain. Learn more about these settings in the <a href="https://lockrmail.com/faq/can-i-preemptively-block-emails-from-all-senders-at-a-given-root-domain/" target="_blank">Help Center</a>
					</>}
					disabled={disabled}
					value={searchText}
					onChange={event => handleDomainChange(event.target.value)}
					onNext={() => save()}
					onFocus={() => search(searchText)} />
				<button className={`save ${!isValidDomain() && 'd-none'}`} onClick={() => save()}>Add domain</button>
				{ArrayHelper.isValid(searchResults) &&
					<SelectDropDown
						inputValue={searchText}
						onInputChange={(value) => handleDomainChange(value)}
						onCancel={() => setSearchResults([])}>
						{searchResults.map((domainSetting, index) => {
							return (
								<SelectOption
									key={index}
									selectable={false}
									onClick={() => handleSearchResultClick(domainSetting)}>
									<Icon placeholder={domainSetting.name} src={domainSetting.avatar} />
									<span className="label">{domainSetting.name}</span>
								</SelectOption>
							)
						})}
					</SelectDropDown>
				}
			</div>
		</>
	)
}