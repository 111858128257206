import React, { useEffect, useState } from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'

import {
	HMenuDatePicker,
	Checkbox,
	Loader,
	BlueButton,
	SelectDropDown,
	SelectOption,
	Select,
} from 'components'
import { Charts } from './charts'

import { useAlert, useAuth, AdminTraction } from 'core'
import { DateHelper, ArrayHelper, ObjectHelper, zeroPad } from 'utils'
import fetchImage from '../../../../assets/images/fetch.svg'
import moment from 'moment-timezone'

const tractionTypes = ['account', 'email', 'digest', 'revenue']

const SelectionTypes = {
	CLICKS: 'clicks',
	SHIPMENT: 'shipment',
	COMPARE: 'comparePrevious',
};

export function AdminTractionEl() {
	const { setError } = useAlert()
	const { currentUser } = useAuth()


	const [startDate, setStartDate] = useState(DateHelper.addDay(new Date(), -6))
	const [endDate, setEndDate] = useState(new Date())
	const [timePeriod, setTimePeriod] = useState(0);
	useEffect(() => {
		// loadStatistics()
		// loadTractions()
	}, [currentUser, startDate, endDate])

	const titles = [
		'Total Accounts',
		'Emails Processed',
		'Digests',
		'Revenue Drivers'
	]

	const [isLoadingStatistics, setLoadingStatistics] = useState(false)
	const [statistics, setStatistics] = useState([
		{ total: 0, changePercent: 0 },
		{ total: 0, changePercent: 0 },
		{ total: 0, changePercent: 0 },
		{ total: 0, changePercent: 0 },
	])

	const rangeTypeValues = {
		"1": "yesterday",
		"7": "last_7_days",
		"29": "this_month",
		"30": "last_30_days",
		"31": "last_month",
		"90": "last_90_days",
		"120": "last_quarter"
	}

	const [isLoadingTractions, setLoadingTractions] = useState(false)
	const [tractions, setTractions] = useState(new AdminTraction())
	const [tractionIndex, setTractionIndex] = useState(-1);
	const [futureTractionIndex, setFutureTractionIndex] = useState(-1)
	// React.useEffect(() => { loadTractions() }, [tractionIndex])
	const [dataNames, setDataNames] = useState([])	/// [[Account, Active, Blocked], [Account, Active, Blocked], [Account, Active, Blocked]]
	const [dataNamesSelection, setDataNamesSelection] = useState([])


	const [activeSelection, setActiveSelection] = useState('');

	const toggleSelection = (selectionType) => {
		// If the current selection is already active, deselect it, otherwise, update to the new selection
		setActiveSelection(currentSelection =>
			currentSelection === selectionType ? '' : selectionType
		);
	};

	useEffect(() => {
		if (tractions) {
			// setStatistics(tractions.statistics ?? statistics)
			setDataNames(chartDataNames())
			setDataNamesSelection(chartDataNames().map(e => true))
		}
	}, [tractions, tractionIndex])

	async function loadStatistics() {
		if (!currentUser?.isAdmin) return

		setLoadingStatistics(true)
		if (timePeriod == 0) {
			const { error, result } = await AdminTraction.loadTraction(startDate, endDate)
			setLoadingStatistics(false)

			if (error) {
				setError(error)
				return
			}

			setStatistics([
				result.accountStats ?? { total: 0, changePercent: 0 },
				result.emailStats ?? { total: 0, changePercent: 0 },
				result.digestStats ?? { total: 0, changePercent: 0 },
				result.revenueDriverStats ?? { total: 0, changePercent: 0 }
			])
		}
		else {
			const { error, result } = await AdminTraction.loadTraction(startDate, endDate, null, rangeTypeValues[timePeriod]);
			setLoadingStatistics(false)

			if (error) {
				setError(error)
				return
			}

			setStatistics([
				result.accountStats ?? { total: 0, changePercent: 0 },
				result.emailStats ?? { total: 0, changePercent: 0 },
				result.digestStats ?? { total: 0, changePercent: 0 },
				result.revenueDriverStats ?? { total: 0, changePercent: 0 }
			])
		}
	}

	async function loadTractions() {
		if (!currentUser?.isAdmin) return

		setLoadingTractions(true)
		if (timePeriod == 0) {
			const { error, result } = await AdminTraction.loadTraction(startDate, endDate, tractionTypes[futureTractionIndex])
			setLoadingTractions(false)

			if (error) {
				setError(error)
				return
			}
			setTractions(result)
		}
		else {
			const { error, result } = await AdminTraction.loadTraction(startDate, endDate, tractionTypes[futureTractionIndex], rangeTypeValues[timePeriod])
			setLoadingTractions(false)

			if (error) {
				setError(error)
				return
			}
			if (result?.startDate && result?.endDate) {
				setStartDate(result?.startDate)
				setEndDate(result?.endDate)
			}
			setTractions(result)
		}

	}

	function updateTractions(values) {
		const newTractions = AdminTraction.fromJSON(ObjectHelper.merge(tractions, values))
		// console.log(newTractions.statistics)
		setStatistics(newTractions.statistics ?? statistics)
		setTractions(newTractions)
	}

	function statePath() {
		let result = 'accountStats'
		if (tractionIndex === 1) result = 'emailStats'
		if (tractionIndex === 2) result = 'digestStats'
		if (tractionIndex === 3) result = 'revenueDriverStats'
		return result
	}

	function chartTicksPath() {
		return statePath() + '.ticks'
	}

	function chartFromPath() { return 'current.from' }
	function chartBasePaths() {
		if (activeSelection === SelectionTypes.COMPARE) {
			return ['current', 'previous'];
		} else if (activeSelection === SelectionTypes.CLICKS) {
			return ['current', 'clicks'];
		} else if (activeSelection === SelectionTypes.SHIPMENT) {
			return ['current', 'shipments'];
		} else {
			return ['current'];
		}
	}

	function chartStacks() {
		if (activeSelection === SelectionTypes.COMPARE) {
			return ['Current', 'Previous'];
		} else if (activeSelection === SelectionTypes.CLICKS) {
			return ['Current', 'Clicks'];
		} else if (activeSelection === SelectionTypes.SHIPMENT) {
			return ['Current', 'Shipments'];
		} else {
			return ['Current'];
		}
	}

	// function chartDataNames() {
	// 	let results = ['Accounts', 'Active', 'Extension']
	// 	if (tractionIndex === 1) results = ['Processed', 'Blocked', 'Redirected', 'Filtered', 'Outgoing Emails', 'Emails Opened']
	// 	if (tractionIndex === 2) results = ['Digest']
	// 	if (tractionIndex === 3) results = ['Referral Submitted']
	// 	return results
	// }

	function chartDataNames() {
		let results = [
			{ name: 'Accounts', colors: { bar1: "#2E70D2", bar2: "#BD94BC" } },
			{ name: 'Active', colors: { bar1: '#61CEF7', bar2: '#E88D67' } },
			{ name: 'Extension', colors: { bar1: '#3E4671', bar2: '#FAC748' } }
		]
		if (tractionIndex === 1) results = [
			{ name: 'Processed', colors: { bar1: '#2E70D2', bar2: '#BD94BC' } },
			{ name: 'Blocked', colors: { bar1: '#61CEF7', bar2: '#E88D67' } },
			{ name: 'Redirected', colors: { bar1: '#3E4671', bar2: '#FAC748' } },
			{ name: 'Filtered', colors: { bar1: '#66ADE7', bar2: '#0288D1' } },
			{ name: 'Outgoing Emails', colors: { bar1: '#D32F2F', bar2: '#43A047' } },
			{ name: 'Emails Opened', colors: { bar1: '#567b99', bar2: '#7e57c2' } }
		]
		if (tractionIndex === 2) results = [
			{ name: 'Digest', colors: { bar1: '#2E70D2', bar2: '#BD94BC' } }
		]
		if (tractionIndex === 3) results = [
			{ name: 'Volume of Emails with Discounts', colors: { bar1: '#2E70D2', bar2: '#BD94BC' } },
			{ name: 'Email Clicks', colors: { bar1: '#61CEF7', bar2: '#E88D67' } },
			{ name: 'Injection Clicks', colors: { bar1: '#3E4671', bar2: '#FAC748' } },
			{ name: 'Web App Clicks', colors: { bar1: '#567b99', bar2: '#0288D1' } },
		]
		return results
	}

	function chartDataPaths(basePath = 'current') {
		let results = ['accounts', 'actives', 'extensions']
		if (tractionIndex === 1) {
			results = ['processed', 'blocked', 'redirected', 'filtered', 'replied', 'emailOpened']
			if (basePath === 'clicks') {
				results = ['clicked']
			} else if (basePath === 'shipments') {
				results = ['shipment']
			}
		}
		if (tractionIndex === 2) results = ['digest']
		if (tractionIndex === 3) results = ['userWithDiscounts', 'emailClicks', 'extensionClicks', 'webClicks']
		return results.map(e => `${basePath}.${e}`)
	}

	function isTotalDataPath(basePath, dataPath) {
		const path = dataPath.replace(`${basePath}.`, '')
		return path === 'accounts' || path === 'processed' || path === 'digest' || path === 'emailClicks'
	}

	function chartCategories() {
		if (!tractions) return []

		const ticks = ArrayHelper.value(tractions, chartTicksPath()) ?? []
		return ticks.map(tick => DateHelper.format(ArrayHelper.value(tick, chartFromPath()), 'MM-DD'))
	}

	function chartData() {
		if (!tractions) return []

		const ticks = ArrayHelper.value(tractions, chartTicksPath()) ?? []
		const results = []
		const basePaths = chartBasePaths()
		let maxIndices = []
		let skipCount = 0
		basePaths.forEach((basePath, baseIndex) => {
			const dataPaths = chartDataPaths(basePath)
			const curDataPaths = selectedDataPaths(basePath)
			const indexLength = dataPaths.length * baseIndex
			maxIndices.push(findMaxIndicesByKeys(dataNamesSelection, ticks, dataPaths, indexLength))
			dataPaths.forEach((dataPath, dataIndex) => {
				if (basePath !== 'clicks' || basePath !== 'shipments') {
					if (!dataNamesSelection[dataIndex]) {
						skipCount = skipCount + 1
						return
					}
				}
				results.push(
					ticks.map((tick, index) => {
						const maxIndex = maxIndices[baseIndex][index] - indexLength
						if (maxIndex + skipCount === dataIndex && curDataPaths.length > 1) {
							const temp = ObjectHelper.value(tick, dataPath) - ArrayHelper.sumOfPaths(tick, curDataPaths.filter((_, filterIndex) => filterIndex !== maxIndex))
							return temp
						}
						return ObjectHelper.value(tick, dataPath)
					})
				)
			})
		})
		return { data: results, maxIndices }
	}

	function findMaxIndicesByKeys(boolArray, ticks, dataPaths, indexLength) {
		const result = ticks.map((tick) => {

			const filteredDataPaths = dataPaths.filter((_, index) => boolArray[index]);

			let maxDataPath = filteredDataPaths[0];
			for (let dataPath of filteredDataPaths) {
				if (ObjectHelper.value(tick, dataPath) > ObjectHelper.value(tick, maxDataPath)) {
					maxDataPath = dataPath;
				}
			}
			// Return the index of the key with the maximum value in the original keys array
			const originalIndex = dataPaths.indexOf(maxDataPath);
			// Count the number of false values in boolArray before this index
			const falseCountBeforeIndex = boolArray.slice(0, originalIndex).filter(val => !val).length;
			// Adjust the index by subtracting the count of false values before it
			const finalIndex = originalIndex - falseCountBeforeIndex;
			return finalIndex + indexLength
		});

		return result;
	}

	function chartMax() {
		if (!tractions) return null
		const ticks = ArrayHelper.value(tractions, chartTicksPath()) ?? []
		const basePaths = chartBasePaths() ?? []
		const values = basePaths.map(basePath => {
			const dataPaths = chartDataPaths(basePath)
			const data = ticks.map(tick => ArrayHelper.sumOfPaths(tick, dataPaths))
			return ArrayHelper.max(data)
		})
		const result = ArrayHelper.max(values)
		return result
	}

	const selectedDataNames = () => {
		const indexes = []
		dataNamesSelection.forEach((e, index) => { if (e) indexes.push(index) })
		return indexes.map(i => dataNames[i])
	}

	const selectedDataPaths = (basePath) => {
		const dataPaths = chartDataPaths(basePath)
		const indexes = []
		if (basePath === 'clicks' || basePath === 'shipments') {
			return [dataPaths[0]]
		}
		dataNamesSelection.forEach((e, index) => { if (e) indexes.push(index) })
		return indexes.map(i => dataPaths[i])
	}

	function renderTimeSaved(timeSaved) {
		const hour = parseInt(timeSaved / 3600)
		const minutes = parseInt((timeSaved - hour * 3600) / 60)
		const seconds = parseInt(timeSaved % 60)

		return `${zeroPad(hour, 10)}:${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`
	}

	const onFetch = () => {
		setTractionIndex(futureTractionIndex)
		loadStatistics();
		loadTractions();
	}

	React.useEffect(() => {
		if (timePeriod == 0) {
			setStartDate(DateHelper.addDay(new Date(), -6));
			setEndDate(new Date());
		}
		if (timePeriod == 1) {
			setStartDate(moment().subtract(1, "days").startOf('day').toDate());
			setEndDate(moment().subtract(1, "days").endOf('day').toDate());
		}
		if (timePeriod == 7) {
			setStartDate(moment().subtract(7, "days").startOf('day').toDate());
			setEndDate(moment().subtract(1, "days").endOf('day').toDate());
		}
		if (timePeriod == 30) {
			setStartDate(moment().subtract(30, "days").startOf('day').toDate());
			setEndDate(moment().subtract(1, "days").endOf('day').toDate());
		}
		if (timePeriod == 31) {
			setStartDate(moment().subtract(1, 'months').startOf('month').startOf('day').toDate());
			setEndDate(moment().subtract(1, 'months').endOf('month').endOf('day').toDate());
		}
		if (timePeriod == 90) {
			setStartDate(moment().subtract(90, "days").startOf('day').toDate());
			setEndDate(moment().subtract(1, "days").endOf('day').toDate());
		}
		if (timePeriod == 120) {
			setStartDate(moment().subtract(3, 'months').startOf('quarter').startOf('day').toDate());
			setEndDate(moment().subtract(3, 'months').endOf('quarter').endOf('day').toDate());
		}
		if (timePeriod == 29) {
			setStartDate(moment().startOf('month').toDate());
			setEndDate(moment().toDate())
		}
	}, [timePeriod])

	return (
		<>
			<div className="admin-group admin-traction">
				<div className="admin-group-header">
					<div className="admin-group-title">Traction</div>
					<div className="admin-traction-option-container">

						<div>
							<div style={{ marginRight: 8 }}>
								<Select

									id="periods"
									label="Time Period"
									mobileTitle="Time Period"
									value={timePeriod}
									onChange={(value) => { setTimePeriod(value) }}
								>
									<SelectOption value={0}>Custom</SelectOption>
									<SelectOption value={1}>Daily</SelectOption>
									<SelectOption value={7}>Weekly</SelectOption>
									<SelectOption value={30}>Last 30 days</SelectOption>
									<SelectOption value={29}>This Month</SelectOption>
									<SelectOption value={31}>Last month</SelectOption>
									<SelectOption value={90}>Last 90 days</SelectOption>
									<SelectOption value={120}>Last Quarter</SelectOption>
								</Select>
							</div>
							<div style={{ pointerEvents: timePeriod !== 0 && "none", display: "contents" }}>
								<HMenuDatePicker
									position="first"
									date={startDate}
									onSelect={date => setStartDate(date)}
								/>
								<div className="to">to</div>
								<HMenuDatePicker
									position="first"
									date={endDate}
									onSelect={date => setEndDate(date)}
								/>
							</div>
						</div>
						<Checkbox
							label="Compare previous period"
							labelClassName="checkbox-label"
							iconChecked={iconChecked}
							iconUnchecked={iconUnchecked}
							checked={activeSelection === SelectionTypes.COMPARE}
							onChange={() => toggleSelection(SelectionTypes.COMPARE)}
						/>
						<BlueButton className='fetch-button' onClick={() => { onFetch(); }}>Fetch <img src={fetchImage}></img></BlueButton>
					</div>
				</div>
				<div className="admin-group-content">
					<div className="admin-traction-tab">
						{tractions &&
							titles.map((title, index) => (
								<div
									key={index}
									className={`admin-traction-tab-item ${index === tractionIndex ? 'selected' : ''} ${index === futureTractionIndex ? 'future-selected' : ''}`}
									onClick={() => setFutureTractionIndex(index)}>
									<div>
										<div className="count">{statistics[index]?.total ?? 0}</div>
										<div className={`percent ${statistics[index]?.changePercent >= 0 ? 'increase' : 'decrease'}`}>
											<img />
											{statistics[index]?.changePercent ?? 0}%
										</div>
									</div>
									<div>{title}</div>
								</div>
							))
						}
					</div>
					<div className="admin-traction-container">
						{(isLoadingStatistics || isLoadingTractions) ? <Loader /> :
							<>
								{(tractions && tractionIndex === 0) &&
									<div className="additional-statistics">
										<div title="Number of accounts with one new sender in past 7 days">
											<span>Number of accounts with one new sender in past 7 days:</span>
											<span>{tractions.accountStats?.totalW1Sender}</span>
										</div>
										<div title="Number of users with at least one interaction in the past 7 days">
											<span>Number of users with at least one interaction in the past 7 days:</span>
											<span>{tractions.accountStats?.totalActives}</span>
										</div>
										<div title="Post 7-Day Active">
											<span>Post 7-Day Active:</span>
											<span>{tractions.accountStats?.post7DayActive}</span>
										</div>
										<div title="Post 30-Day Active">
											<span>Post 30-Day Active:</span>
											<span>{tractions.accountStats?.post30DayActive}</span>
										</div>
										<div title="Total Filters / Account">
											<span>Total Filters / Account:</span>
											<span>{tractions.filterStats?.totalFiltersPerAccount}</span>
										</div>
										<div title="Filter Usage / Account">
											<span>Filter Usage / Account:</span>
											<span>{tractions.filterStats?.totalFilterUsagePerAccount}</span>
										</div>
										<div title="Consented Accounts">
											<span>Consenting Accounts</span>
											<span>{tractions.accountStats?.consentPercent}</span>
										</div>
										<div title="LockrScan Accounts">
											<span>% lockrMail users with lockrScan</span>
											<span>{tractions.accountStats?.lockrScanUserPercentage}</span>
										</div>
										<div title="Consented Accounts">
											<span>Total GPC / chrome extension users</span>
											<span>{tractions.accountStats?.gpcPercentChromeUsers}</span>
										</div>
										<div title="LockrScan Accounts">
											<span>lockrMail accounts added lockrScan</span>
											<span>{tractions.accountStats?.totalLockrMailAddedLockrScan}</span>
										</div>
										<div title="Consented Accounts">
											<span>Total GPC / all users</span>
											<span>{tractions.accountStats?.gpcPercentTotal}</span>
										</div>
									</div>
								}
								{(tractions && tractionIndex === 1) &&
									<div className="additional-statistics">
										<div title="Verified Destination Emails">
											<span>Verified Destination Emails:</span>
											<span>{tractions.emailStats?.verifiedDestinations}</span>
										</div>
										<div title="Unique Email Senders">
											<span>Unique Email Senders:</span>
											<span>{tractions.emailStats?.uniqueSenders}</span>
										</div>
										<div title="Time saved">
											<span>Time saved:</span>
											<span>{renderTimeSaved(tractions.emailStats?.timeSaved)}</span>
										</div>
										<div title="Number of filters created (total)">
											<span>Number of filters created (total):</span>
											<span>{tractions.filterStats?.total}</span>
										</div>
										<div title="Number of senders approved">
											<span>Number of senders approved:</span>
											<span>{tractions.emailStats?.approvedSenders}</span>
										</div>
										<div title="Number of senders blocked">
											<span>Number of senders blocked:</span>
											<span>{tractions.emailStats?.blockedSenders}</span>
										</div>
										<div title="Number of senders unapproved">
											<span>Number of senders unapproved:</span>
											<span>{tractions.emailStats?.unapprovedSenders}</span>
										</div>
										<div title="Number of blocked emails released">
											<span>Number of blocked emails released:</span>
											<span>{tractions.emailStats?.blockedReleasedEmails}</span>
										</div>
										<div title="Number of filters 'saved'">
											<span>Number of filters 'saved':</span>
											<span>{tractions.filterStats?.savedFilters}</span>
										</div>
										<div title="Outgoing Emails">
											<span>Outgoing Emails</span>
											<span>{tractions.emailStats?.outgoingEmails}</span>
										</div>
										<div title="Aggregate Unique Senders Mail/Scan">
											<span>Aggregate Unique Senders Mail/Scan</span>
											<span>{tractions.emailStats?.totalUniqueSendersLockrMailLockrScan}</span>
										</div>
										<div title="Emails Opened">
											<span>Emails Opened</span>
											<span>{tractions.emailStats?.totalEmailsOpened}</span>
										</div>
									</div>
								}
								{(tractions && tractionIndex === 2) &&
									<div className="additional-statistics">
										<div title="Number of accounts with at least one Digest created">
											<span>Number of accounts with at least one Digest created:</span>
											<span>{tractions.digestStats?.totalAccountAtLeastOneDigest}</span>
										</div>
										<div title="Average number of Digests per lockrMail accounts">
											<span>Average number of Digests per lockrMail account:</span>
											<span>{tractions.digestStats?.avgDigestNumPerAccount}</span>
										</div>
										<div title="Average number of Senders per Digest">
											<span>Average number of Senders per Digest:</span>
											<span>{tractions.digestStats?.avgSenderNumPerDiegst}</span>
										</div>
										<div title="Total Digests Delivered">
											<span>Total Digests Delivered:</span>
											<span>{tractions.digestStats?.totalDigestDelivered}</span>
										</div>
										<div title="Total Digests Deleted">
											<span>Total Digests Deleted:</span>
											<span>{tractions.digestStats?.totalDigestArchived}</span>
										</div>
										<div title="Total Digests with daily delivery">
											<span>Total Digests with daily delivery:</span>
											<span>{tractions.digestStats?.totalDigestDailyDelivery}</span>
										</div>
										<div title="Total Digests with weekly delivery">
											<span>Total Digests with weekly delivery:</span>
											<span>{tractions.digestStats?.totalDigestWeeklyDelivery}</span>
										</div>
										<div title="Total Digests with monthly delivery">
											<span>Total Digests with monthly delivery:</span>
											<span>{tractions.digestStats?.totalDigestMonthlyDelivery}</span>
										</div>
									</div>
								}{(tractions && tractionIndex === 3) &&
									<div className="additional-statistics">
										<div title="Percentage of emails with 1+ discount">
											<span>Percentage of emails with 1+ discount:</span>
											<span>{tractions.revenueDriverStats?.discountEmailPercentage}</span>
										</div>
										<div title="Average validity of discount">
											<span>Average validity of discount:</span>
											<span>{tractions.revenueDriverStats?.averageValidity}</span>
										</div>
										<div title="Average number of Senders per Digest">
											<span>Average discount percentage:</span>
											<span>{tractions.revenueDriverStats?.averageDiscountPercentage}</span>
										</div>
									</div>
								}
								<Charts
									categories={chartCategories()}
									names={selectedDataNames()}
									stacks={chartStacks()}
									data={chartData()}
									max={chartMax()}
									differentStack={["Clicks", "Shipments"]}
									differentStackNames={[{ name: 'Clicked', colors: { bar1: '#BD94BC', bar2: '#BD94BC' } }, { name: 'Shipments', colors: { bar1: '#E88D67', bar2: '#E88D67' } }]}
									yAxisName={tractionIndex === 3 && 'Proceeds'}
								/>
								<div className="names-container">
									{dataNames.map((name, index) =>
										<Checkbox
											key={index}
											label={name?.name}
											checked={dataNamesSelection[index]}
											onChange={value => {
												setDataNamesSelection(dataNamesSelection.map((e, i) => {
													if (i === index) return !e
													else return e
												}))
											}}
										/>
									)}
									{
										tractionIndex === 1 &&
										<>
										<Checkbox
											label={'Clicks'}
												checked={activeSelection === SelectionTypes.CLICKS}
											onChange={() =>
												toggleSelection(SelectionTypes.CLICKS)
											}
										/>
											<Checkbox
												label={'Shipments'}
												checked={activeSelection === SelectionTypes.SHIPMENT}
												onChange={() =>
													toggleSelection(SelectionTypes.SHIPMENT)
												}
											/>

										</>
									}
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</>
	)
}