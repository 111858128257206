import React from 'react'

export function LockrScanSectionContainer({
	className = "",
	title = "",
	children,
}) {
	return (
		<div className={`notification-section-container-lockrScan ${className}`}>
			<div className="title">{title}</div>
			<div className="section-wrapper">
				{children}
			</div>
		</div>
	)

}