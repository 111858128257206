import './styles.scss';
import React from 'react';

export const AdvertisingComponent = ({
    image = "",
    title = "",
    description = "",
    buttonNode = "",
    onButtonClick = () => { },
    opacity = 1,
}) => {
    return (
        <div className='advertising-component' style={{ opacity }}>
            <div className='advertising-image'>
                <img className='advertising-image' src={image}></img>
            </div>
            <div style={{ marginTop: 24 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: 288, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='advertise-title'>
                        {title}
                    </div>
                    <div className='advertise-description'>
                        {description}
                    </div>
                    <div className='advertise-button' onClick={() => { onButtonClick() }} style={{ marginTop: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, width: 250, cursor: 'pointer' }}>
                        {buttonNode}
                    </div>
                </div>

            </div>
        </div>
    )
}