import React from 'react'

import './styles.scss'

import {
	AlertPage, AlertTitle, AlertButtonContainer, AlertButton
} from '../../'

import { DateHelper } from '../../../utils'

export function ViewEl({
	className = '',
	specialLink = {},
	onEdit = () => { }
}) {
	const { permalink, startAt, expireAt, limit, userCount } = specialLink
	return (
		<>
			<AlertTitle>
				<span>{permalink}</span>
				<button onClick={() => onEdit()}>Edit</button>
			</AlertTitle>
			<div className="view-container">
				<div className="info-item">
					<span>Date</span>
					<div>
						<div>
							<span>Start:</span>
							<span>{DateHelper.format(startAt, 'YYYY-MM-DD hh:mm a')}</span>
						</div>
						<div>
							<span>End:</span>
							<span>{DateHelper.format(expireAt, 'YYYY-MM-DD hh:mm a')}</span>
						</div>
					</div>
				</div>
				<div className="info-item">
					<span>Landing Page</span>
					<div>Default</div>
				</div>
				<div className="info-item">
					<span>Custom URL</span>
					<div>/{permalink}</div>
				</div>
				<div className="info-item">
					<span>Registration Count</span>
					<div>{userCount}</div>
				</div>
			</div>
		</>
	)
}