import React from 'react'
import { Link } from 'react-router-dom'

import iconFeatured from 'assets/images/tiles/just-launched.svg'

import { routes, redirectTo } from 'routes'

export function TileJustLaunched({
	className = "",
}) {
	return (
		<div className={`${className} tile-just-launched`}>
			<div>
				{/* <img src={iconFeatured} alt="" /> */}
			</div>
			<div>
				<div className="title">Just launched</div>
				<div className="description">
					Chrome extension 2.0!
					Seamless site registrations and discount code notifications across the web.

					<Link to={''} onClick={() => window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank')}>Download Now.</Link>
				</div>
			</div>
		</div>
	)
}