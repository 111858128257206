import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton,
	Select, SelectOption, Switch,
} from 'components'
import { DomainInput } from '../domain-input'
import { ViewAllRibbonEl } from '../view-all-ribbon'

import {
	DomainSetting,
	useAlert, useAuth,
} from 'core'
import { DomainHelper } from 'utils'
import { errorMessages, errorCodes } from 'constants/error'

export function AddDomainModal({
	domain: initialDomain = '',
	onClose = (updated) => { },
}) {
	const { alert, setAlert, error, setError, resetAlert } = useAlert()
	const { currentUser } = useAuth()

	const [domain, setDomain] = React.useState(initialDomain)
	React.useEffect(() => {
		setDomain(initialDomain)
	}, [initialDomain])
	const [emailOption, setEmailOption] = React.useState(DomainSetting.Option.Unblock.value)
	const [popupOption, setPopupOption] = React.useState(DomainSetting.Option.Unblock.value)
	const [passwordOption, setPasswordOption] = React.useState(DomainSetting.PasswordOption.Default)
	const [allowConsent, setAllowConsent] = React.useState(DomainSetting.ConsentOption.Default)
	const [allowGPC, setAllowGPC] = React.useState(DomainSetting.GPCOption.Default);
	const [isChanged, setIsChanged] = React.useState(false);

	// array of initial values
	const initialValues = [
		DomainSetting.Option.Unblock.value,
		DomainSetting.Option.Unblock.value,
		DomainSetting.PasswordOption.Default,
		DomainSetting.ConsentOption.Default,
		DomainSetting.GPCOption.Default
	];

	React.useEffect(() => {
		// check if any of the options has changed
		const currentValues = [emailOption, popupOption, passwordOption, allowConsent, allowGPC];
		const hasChanged = !initialValues.every((val, index) => val === currentValues[index]);
		setIsChanged(hasChanged);
	}, [emailOption, popupOption, passwordOption, allowConsent, allowGPC]);



	function checkValidation() {
		if (!DomainHelper.isDomain(domain)) return false

		const parsedDomain = DomainHelper.parseDomain(domain)
		if (!parsedDomain) return false

		// return emailOption !== 0 || popupOption !== 0 || passwordOption !== DomainSetting.PasswordOption.Default || allowConsent !== DomainSetting.ConsentOption.Default
		return true;
	}

	async function checkEmailOptionAvailability() {
		if (!DomainHelper.isDomain(domain)) return false

		const parsedDomain = DomainHelper.parseDomain(domain)
		if (!parsedDomain) return false

		const { errorCode } = await DomainSetting.checkAvailability([parsedDomain])
		if (errorCode === errorCodes.DS_OVERRIDE_ALLOW_FILTER) {
			setAlert({
				message: errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER,
				buttons: [
					{ type: 'positive', title: 'Confirm', onClick: () => handleSave(true) },
					{ type: 'negative', title: 'Cancel', onClick: () => resetAlert() },
				]
			})
			return false
		}
		return true
	}

	async function handleSave(skipEmailOption = false) {
		if (!DomainHelper.isDomain(domain)) return

		const parsedDomain = DomainHelper.parseDomain(domain)
		if (!parsedDomain) return

		if (!skipEmailOption && emailOption !== DomainSetting.Option.Unblock.value) {
			const availability = await checkEmailOptionAvailability()
			if (!availability) return
		}

		const { error } = await DomainSetting.add(parsedDomain, { emailOption, popupOption, passwordOption, allowConsent, allowGPC })
		if (error) {
			setError(error)
			return
		}

		onClose(true)
	}

	function handleClose() {
		onClose(false)
	}

	const [isCrxInstalled, setCrxInstalled] = React.useState(true)
	React.useEffect(() => {
		setCrxInstalled(currentUser && currentUser.isExtensionInstalled)
	}, [currentUser])

	return (
		<React.Fragment>
			{!alert && !error &&
				<Alert className="account-add-domain-modal" onCancel={() => handleClose()}>
					<AlertPage>
						<AlertTitle>Add new</AlertTitle>
						<div className="group">
							<div>Domain name</div>
							<DomainInput
								searchText={domain}
								disabled={Boolean(initialDomain)}
								onChange={domainUrl => setDomain(domainUrl)} />
						</div>
						<div className="group email-option">
							<div>All Emails from {domain}</div>
							<span className='email-option'>
								<div>{emailOption === 0 ? 'On' : 'Off'}</div>
								<Switch
									value={emailOption === 0}
									onChange={(value) => setEmailOption(value === false ? -1 : 0)} />
							</span>
						</div>
						<div className="group">
							<div className={`${!isCrxInstalled && 'disable text'}`}>Browser pop-up notifications</div>
							<Select
								keepLabel
								label={(popupOption == 0 ? ('Default' + (currentUser?.allowDomainNotification ? ' (On)' : ' (Off)')) : DomainSetting.isBrowserPopupEnabled(popupOption, currentUser?.allowDomainNotification) ? 'On' : 'Off')}
								value={popupOption}
								onChange={(value) => setPopupOption(value)}
								disabled={!isCrxInstalled}>
								{Object.keys(DomainSetting.PopupOption).map((key, index) => {
									const value = DomainSetting.PopupOption[key]
									return (
										<SelectOption key={index} value={value}>{key + (value == 0 ? currentUser?.allowDomainNotification ? ' (On)' : ' (Off)' : '')}</SelectOption>
									)
								})}
							</Select>
						</div>
						<div className="group">
							<div>Account & password update emails</div>
							<Select
								// keepLabel
								// label={(passwordOption == null ? 'Default' : DomainSetting.isPasswordEnabled(passwordOption, currentUser?.allowPasswordOption) ? 'On' : 'Off')}
								value={passwordOption}
								onChange={(value) => setPasswordOption(value)}>
								{Object.keys(DomainSetting.PasswordOption).map((key, index) => {
									const value = DomainSetting.PasswordOption[key]
									return (
										<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowPasswordOption ? ' (On)' : ' (Off)' : '')}</SelectOption>
									)
								})}
							</Select>
						</div>
						{currentUser?.allowConsent &&
							<div className="group">
								<div>Publisher Consent</div>
								<Select
									// keepLabel
									// label={(allowConsent == null ? 'Default' : DomainSetting.isConsentEnabled(allowConsent, currentUser?.allowConsent) ? 'On' : 'Off')}
									value={allowConsent}
									onChange={(value) => setAllowConsent(value)}>
									{Object.keys(DomainSetting.ConsentOption).map((key, index) => {
										const value = DomainSetting.ConsentOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowConsent ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
							</div>
						}
						<div className="group">
							<div className={`${!isCrxInstalled && 'disable text'}`}>GPC</div>
							<Select
								value={allowGPC}
								onChange={(value) => setAllowGPC(value)}
								disabled={!isCrxInstalled}>
								{Object.keys(DomainSetting.GPCOption).map((key, index) => {
									const value = DomainSetting.GPCOption[key]
									return (
										<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowGPC ? ' (On)' : ' (Off)' : '')}</SelectOption>
									)
								})}
							</Select>

						</div>
						<AlertButtonContainer>
							<AlertButton disabled={!checkValidation() || !isChanged} onClick={() => handleSave()}>Save</AlertButton>
						</AlertButtonContainer>
						<ViewAllRibbonEl />
					</AlertPage>
				</Alert>
			}
		</React.Fragment>
	)
}