import { BaseModel } from 'core/models/base'
import { Api } from 'core'
import { errorMessages } from 'constants/error'

export class AdminBlockedDomain extends BaseModel {
	domain = ''
	tryCount = 0
	network = 0

	static async search({ searchText, pageIndex, pageSize, sortBy }) {
		try {
			const { error, data } = await Api.get('/admin/blocked-domains', {
				searchText,
				pageNum: pageIndex + 1,
				pageSize,
				sortBy
			})
			return {
				error: error && errorMessages.NETWORK_ERROR,
				totalCount: data?.count ?? 0,
				results: AdminBlockedDomain.fromJSONArray(data?.results)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async add({ domain }) {
		try {
			const { error } = await Api.post('/admin/blocked-domains', { domain })
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async destroy({ domains = [] }) {
		try {
			const { error } = await Api.delete('/admin/blocked-domains', { domains })
			return { error: error && errorMessages.NETWORK_ERROR }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}
}