import React from 'react'

import './styles.scss'

import {
	useEmailAutocomplete, randomUUID,
	DOMHelper
} from '../../../utils'

import eyeOpen from '../../../assets/images/eye-open.svg'
import eyeClosed from '../../../assets/images/eye-closed.svg'

export const LargeTextField = React.forwardRef(({
	id = randomUUID(),
	className,
	placeholder, initialValue, multiline,
	autoFocus, required, password, error,
	autoComplete, domains,
	value,
	onChange = (event) => { },
	onBlur = (event) => { },
	onNext = () => { },
	buttonTitle, onButtonClicked, isButtonShown,
	passwordVisibility = false,
	setPasswordVisibility = () => { }
}, ref) => {
	const containerRef = React.useRef()
	const measurerRef = React.useRef()

	const {
		email,
		onChange: handleEmailChange,
		bind
	} = useEmailAutocomplete({ domains: domains, validation: true })

	const [initialValueStyle, setInitialValueStyle] = React.useState({})
	React.useEffect(() => {
		calcInitialValueStyle()
	}, [initialValue, value])

	function handleChange(event) {
		event.preventDefault()

		let result = event.target.value
		if (!multiline && autoComplete) {
			handleEmailChange(event)
			result = email.address

			result = result.replace('lockrm', 'lockrM')
		}

		event.target.value = result
		onChange(event)
	}

	function calcInitialValueStyle() {
		let style = {}
		if (!containerRef.current || !measurerRef.current) {
			setInitialValueStyle({})
			return
		}

		const inputEl = containerRef.current.querySelector('input')
		if (!inputEl) {
			setInitialValueStyle({})
			return
		}

		const width = Math.min(DOMHelper.calcWidth(measurerRef.current), DOMHelper.calcWidth(inputEl) - parseInt(getComputedStyle(inputEl).paddingLeft))
		style = { left: `${width}px` }
		setInitialValueStyle(style)
	}

	function onKeyDown(event) {
		if (event.key === 'Enter' && onNext) {
			onNext()
		}
	}

	function onInnerButtonClicked() {
		if (onButtonClicked) {
			onButtonClicked()
		}
	}

	function calcValue() {
		return value ? value : ''
	}

	return (
		<div ref={containerRef} className={`text-field large ${className || ""} ${initialValue && 'has-initial-value'} ${error && 'text-field-error'} ${multiline && 'multiline'}`}>
			{multiline ?
				<textarea
					ref={ref}
					id={id}
					data-testid={id}
					placeholder=" "
					alt={id}
					value={calcValue()}
					autoFocus={autoFocus}
					required={required}
					onChange={handleChange}
					onKeyDown={onKeyDown}
					onBlur={onBlur}
				/>
				:
				<input
					ref={ref}
					{...bind}
					id={id}
					data-testid={id}
					type={passwordVisibility ? "password" : "text"}
					placeholder=" "
					alt={id}
					value={calcValue()}
					autoFocus={autoFocus}
					required={required}
					autoComplete="off"
					onChange={handleChange}
					onKeyDown={onKeyDown}
					onBlur={onBlur}
				/>
			}
			<label htmlFor={id || "input"}>{placeholder}</label>
			{initialValue &&
				<>
					<span ref={measurerRef} className="initial-value-measurer">{value}</span>
					<div className="initial-value" style={initialValueStyle}>{initialValue}</div>
				</>
			}
			{(password) && <img src={passwordVisibility ? eyeOpen : eyeClosed} style={{ height: 24, cursor: "pointer" }} onClick={() => { setPasswordVisibility(!passwordVisibility) }}></img>}
			{(buttonTitle && isButtonShown) &&
				<button className="text-field-button" onClick={onInnerButtonClicked}>{buttonTitle}</button>
			}
		</div>
	)
})