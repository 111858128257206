import React from 'react'

import './styles.scss'

import {
	Loader,
	Select, SelectOption,
} from 'components'
import { LibraryListItem } from './list-item'

import { FilterLibraryCategory, FilterLibrary, Filter, useFilterLibraries, FilterLibraryGroup } from 'core'
import { ObjectHelper, ArrayHelper } from 'utils'

export function FilterLibraryEl({
	className = "",
}) {
	const {
		isLoading, categories,
		categoryId, setCategoryId,
		curLibraries,
		selectionMap, setSelectionMap,
	} = useFilterLibraries()

	/** @returns {FilterLibraryGroup} */
	function curSelectedLibraries() {
		return selectionMap.find(x => x.categoryId === categoryId) ?? FilterLibraryGroup.create({ categoryId, libraries: [] })
	}

	// checkIfChanged = () => {
	// 	const { filter, senders, onChange = (value) => { } } = this.props
	// 	const { selectionMap } = this.state

	// 	let isChanged = false
	// 	if (filter) {
	// 		isChanged = !ArrayHelper.equals(selectionMap, filter.filterLibraries)
	// 	} else {
	// 		isChanged = !ArrayHelper.equals(selectionMap, [])
	// 	}
	// 	// console.log(isChanged)
	// 	onChange(isChanged)
	// }

	function handleSelectLibrary(library, selected) {
		const selectedLibraries = curSelectedLibraries()
		let { libraries } = selectedLibraries
		if (selected && !libraries.some(x => x.relNo === library.relNo)) {
			libraries = [...libraries, library]
		} else if (!selected && libraries.some(x => x.relNo === library.relNo)) {
			libraries = libraries.filter(x => x.relNo !== library.relNo)
		}
		selectedLibraries.libraries = libraries

		if (selectionMap.some(x => x.categoryId === selectedLibraries.categoryId)) {
			setSelectionMap(selectionMap.map(x => (x.categoryId === categoryId) ? selectedLibraries : x))
		} else {
			setSelectionMap([...selectionMap, selectedLibraries])
		}
	}

	/**
	 * @param {FilterLibrary} library
	 * @param {number} index
	 */
	function handleLibraryChange(library, index) {
		let selectedGroup = selectionMap.find(x => x.categoryId === categoryId)
		if (!selectedGroup) {
			selectedGroup = FilterLibraryGroup.create({ categoryId: categoryId, libraries: [library] })
		} else {
			if (selectedGroup.libraries.some(x => x.relNo === library.relNo)) {
				selectedGroup = FilterLibraryGroup.create({
					categoryId: categoryId,
					libraries: selectedGroup.libraries.map(e => e.relNo === library.relNo ? library : e)
				})
			} else if (library.type !== FilterLibrary.Type.CustomInput || library.hasKeywords) {
				selectedGroup = FilterLibraryGroup.create({
					categoryId: categoryId,
					libraries: [...selectedGroup.libraries, library]
				})
			}
		}

		setSelectionMap(selectionMap.map(x => x.categoryId === categoryId ? selectedGroup : x))
	}

	function handleResetFilter() {
		setSelectionMap([])
	}

	return (
		<div className={`filter-library ${className}`}>
			<div className="select-container">
				<Select
					value={categoryId}
					onChange={(value) => setCategoryId(value)}>
					{categories.map((x) =>
						<SelectOption key={x.id} value={x.id}>{x.name}</SelectOption>
					)}
				</Select>
				{(ArrayHelper.isValid(selectionMap)) &&
					<button className="reset-filter btn-delete-filter" onClick={() => handleResetFilter()}>Reset Filter</button>
				}
			</div>
			<div className="library-list">
				<div>Please select the following to apply</div>
				{isLoading ? <Loader /> :
					<div>
						{curLibraries().map((e, index) => {
							const selectedLibraries = curSelectedLibraries()
							const selectedLibrary = selectedLibraries.libraries.find(e1 => e1.relNo === e.relNo)
							const selected = ObjectHelper.isValid(selectedLibrary)
							return (
								<LibraryListItem
									key={index}
									library={selectedLibrary ?? e}
									selected={selected}
									onSelect={(value) => handleSelectLibrary(e, value)}
									onChange={(library) => handleLibraryChange(library, index)}
								/>
							)
						})}
					</div>
				}
			</div>
		</div>
	)
}