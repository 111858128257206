/*global chrome*/

require('dotenv').config()

const EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID

function checkInstalled() {
	// callback(true)
	// return

	return new Promise((resolve) => {
		try {
			if (!chrome || !chrome.runtime) {
				resolve(false)
				return
			}

			chrome.runtime.sendMessage(EXTENSION_ID, { message: "version" }, response => {
				resolve(Boolean(response))
			})
		} catch (error) {
			console.log(error)
			resolve(false)
		}
	})
}
function updateGPCSettingsInExtension() {
	return new Promise((resolve) => {
		try {
			if (!chrome || !chrome.runtime) {
				resolve(false)
				return
			}

			chrome.runtime.sendMessage(EXTENSION_ID, { message: "update-gpc-settings" }, response => {
				resolve(Boolean(response))
			})
		} catch (error) {
			console.log(error)
			resolve(false)
		}
	})
}

export const ExtensionHelper = {
	checkInstalled,
	updateGPCSettingsInExtension
}