import React from 'react'

import './styles.scss'

import { Menu } from '../'
import iconArrowDown from '../../../assets/images/arrow_down.svg'
import iconChecked from '../images/checked-green-no-outline.svg'
import iconFilters from '../../../assets/images/filter/on.svg'

import {
	isSmallMobile, isValidText,
	ArrayHelper
} from '../../../utils'

export class DropDownMenu extends Menu {
	constructor(props) {
		super(props)

		const { index, subIndex } = this.props
		this.state = {
			index: index || 0,
			subIndex: subIndex || 0,
		}
	}

	setIndex = (index) => {
		this.setState({ index: index })
		this.handleClose()
	}

	setSubIndex = (index) => {
		this.setState({ subIndex: index })
		this.handleClose()
	}

	option = (index) => {
		const { options } = this.props
		if (!options) { return undefined }
		return options[index]
	}

	subOption = (index) => {
		const option = this.option(this.state.index)
		return (option && option.subOptions) ? option.subOptions[index] : undefined
	}

	handleOptionClick = (option) => {
		if (!option) { return }
		if (option.subOptions.length > 0) {
			this.setSubIndex(0)
		} else {
			option.onClick()
		}

		this.handleClose()
	}

	label = () => {
		const { label = "", options = [] } = this.props
		if (isValidText(label) || this.state.index < 0) { return label }

		const selectedOptions = options.filter(e => e.selected)
		if (selectedOptions.length <= 0) { return '' }
		return selectedOptions[0].label
	}

	handleWindowClicked = (event) => {
		if (!event.target.closest(".dropdown")) {
			this.handleClose()
		}
	}

	handleClickBox = (event) => {
		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}

		const { disabled = false } = this.props
		if (!disabled) this.toggle()
	}

	render() {
		const { className, title = '', up, statusIcon, statusDisabled, options, children } = this.props
		const { index: optionIndex, subIndex: subOptionIndex, isOpen } = this.state
		const boxStyle = (statusIcon === undefined || isSmallMobile) ? {} : { justifyContent: 'center' }
		const statusIconStyle = () => {
			if (statusDisabled) { return { display: 'none' } }
			return (statusIcon) ? {} : (isSmallMobile ? { display: 'none' } : { opacity: 0 })
		}
		return (
			<div className={`dropdown ${className && className}`} >
				<div className="dropdown-box" style={boxStyle} onClick={this.handleClickBox} onFocus={this.handleClickBox}>
					<div className="dropdown-box-label">{this.label()}</div>
					<img className="dropdown-box-status-icon" src={statusIcon || iconFilters} alt="" style={statusIconStyle()} />
					<img src={iconArrowDown} alt="" />
					{children}
				</div>
				{(isOpen && options) &&
					<div
						id={this.menuId}
						ref={this.menuRef}
						className={`dropdown-overlay ${up ? 'up' : 'down'}`}
						onClick={event => { this.handleClose() }}>
						<div
							className="dropdown-list"
							onClick={() => this.handleClose()}>
							{isValidText(title) && <div className="dropdown-title">{title}</div>}
							{options.map((option, i) => (
								<DropDownMenuItem
									key={i}
									option={option}
									subOptionIndex={optionIndex === i ? subOptionIndex : undefined}
									onClick={() => {
										this.setIndex(i)
										if (option.onClick) {
											option.onClick()
										}
									}}
									onSubOptionClick={(index) => {
										this.setIndex(i)
										this.setSubIndex(index)
									}}
								/>
							))}
						</div>
					</div>
				}
			</div>
		)
	}
}

function DropDownMenuItem({
	className = '',
	option = {},
	onClick = () => { },
	onSubOptionClick = (index) => { }
}) {
	function handleClick(event) {
		event.preventDefault()
		event.stopPropagation()

		onClick()
	}

	function handleSubOptionClick(index) {
		onSubOptionClick(index)
	}

	return (
		<>
			<div
				className={`dropdown-option ${ArrayHelper.isValid(option.subOptions) ? 'dropdown-parent' : 'dropdown-child'} ${className ? className : ''} ${option.selected ? 'active' : ''}`}
				onClick={handleClick}>
				<img
					className={`${(option.subOptions.length > 0) ? 'd-none' : ''} ${option.selected ? "" : "d-hidden"}`}
					src={iconChecked}
					alt="" />
				<span>{option.label}</span>
				{ArrayHelper.isValid(option.subOptions) &&
					<div className="dropdown-sub-options">
						{option.subOptions.map((e, i) => (
							<DropDownMenuItem
								key={i}
								option={e}
								onClick={() => {
									handleSubOptionClick(i)
									if (e.onClick) { e.onClick() }
								}}
							/>
						))}
					</div>
				}
			</div>
		</>
	)
}