import React from 'react'

import { DefaultProgressBar } from './default'
import { DividedProgressBar } from './divided'

/**
 * @typedef {{className: string, type: "default"|"divided", min: number, max: number, progress: number}} ProgressBarProps
 * @param {ProgressBarProps} param0
 */
export function ProgressBar({
	className = "",
	type = "default",
	min = 0,
	max = 100,
	progress = 0,
}) {
	return (
		<>
			{type === "default" &&
				<DefaultProgressBar {...{ className, min, max, progress }} />}
			{type === "divided" &&
				<DividedProgressBar {...{ className, min, max, progress }} />
			}
		</>
	)
}