import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

import {
	Select, SelectOption
} from '../'
import { TabItem } from './tab-item'


export function Tab({
	className = "",
	breakpoint = 992,
	value,
	onSelect = (value) => { },
	children,
}) {
	const { width: windowWidth } = useWindowSize()

	return (
		<section className={`${className} tab ${windowWidth >= breakpoint ? 'desktop' : 'mobile'}`}>
			<div className="desktop-container">
				{children && React.Children.map(children, (child) => {
					if (child && child.type === TabItem) {
						const selected = child.props.selected || (child.props.value === value && value !== undefined)
						const onClick = (value) => onSelect(value)
						const props = { ...child.props, selected, onClick, }
						return (<TabItem {...props} />)
					}
					return (<>{child}</>)
				})}
			</div>
			<Select
				className="mobile-selector"
				value={value}
				onChange={(value) => onSelect(value)}>
				{children && React.Children.map(children, (child) => {
					if (child && child.type === TabItem) {
						const selected = child.props.selected || (child.props.value === value && value !== undefined)
						const onClick = (value) => onSelect(value)
						const props = { ...child.props, selected, onClick, }
						return (<SelectOption {...props} />)
					}
					return (<>{child}</>)
				})}
			</Select>
		</section>
	)
}

export * from './tab-item'