import React from 'react'

import { SectionItem } from 'scenes/account/settings/section'
import { DeliveryHoursModal } from './modal'

export function DeliveryHoursEl({
	className = '',
	value,
	onChange = (value) => { }
}) {
	const [isEditDetails, setEditDetails] = React.useState(false)

	function handleChange(value) {
		if (value) setEditDetails(true)
		onChange(value)
	}

	return (
		<React.Fragment>
			<SectionItem
				title="Set Delivery Hours"
				description="Enable a default window for all emails to be delivered."
				imodal={<>
					Limit email delivery to set hours per day.<br />Learn more about this setting in the <a href="https://lockrmail.com/faq/what-are-delivery-hours/" target="_blank">Help Center</a>
				</>}
				value={value}
				onChange={(value) => handleChange(value)}>
				{value &&
					<div className='edit-details'>
						<button onClick={() => setEditDetails(true)}>
							Edit details
						</button>
					</div>
				}
			</SectionItem>
			{isEditDetails &&
				<DeliveryHoursModal
					onClose={() => setEditDetails(false)} />
			}
		</React.Fragment>
	)
}