import React from 'react'

import { AdminCurrentUsersEl } from './current-users'
import { AdminDeletedUsersEl } from './deleted-users'
import { AdminUnregisteredUserEl } from './unregistered-users'

import {
	AdminCurrentUsersProvider,
	AdminDeletedUsersProvider,
} from 'core'

export function AdminUsersEl({ }) {
	return (
		<AdminCurrentUsersProvider>
			<AdminDeletedUsersProvider>
				<div className="admin-group">
					<div className="admin-group-title">Current Users</div>
					<AdminCurrentUsersEl />
				</div>
				<div className="admin-group">
					<div className="admin-group-title">Deleted Users</div>
					<AdminDeletedUsersEl />
				</div>
				<div className="admin-group">
					<div className="admin-group-title">Unregistered Users</div>
					<AdminUnregisteredUserEl />
				</div>
			</AdminDeletedUsersProvider>
		</AdminCurrentUsersProvider>
	)
}
