import React from 'react'

import { zeroPad, isNumber } from '../../utils'

export function TimePickerSlider({
	infinite = false,
	includeZero = false,
	range = [],
	value,
	onChange = (value) => { }
}) {
	function calcText(value) {
		return includeZero ? `${zeroPad(value)}` : `${value}`
	}

	function handleItemClick(event, value) {
		event.stopPropagation()
		if (isNumber(value)) onChange(parseInt(value))
		else onChange(value)
	}

	return (
		<div className="time-picker-slider">
			{range.map((e, index) => {
				const content = calcText(e)
				const selected = content === calcText(value)
				return (
					<div
						key={index}
						className={`time-picker-slider-item ${selected && 'selected'}`}
						onClick={(event) => handleItemClick(event, content)}>
						{content}
					</div>
				)
			})}
		</div>
	)
}