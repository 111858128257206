import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

import { ArrayHelper } from '../../../utils'

export class InputMenuOption {
	label
	disabled
	rightIcon
	description
	onClick

	constructor({ label, rightIcon, disabled = false, description, onClick = () => { } }) {
		this.label = label
		this.rightIcon = rightIcon
		this.disabled = disabled
		this.description = description
		this.onClick = onClick
	}
}

function InputMenuItem({
	option = {},
	onClick = () => { }
}) {
	const ref = React.useRef()

	const { label, rightIcon, disabled, description } = option

	function handleClick(event) {
		option.onClick()
		onClick()
	}

	function handleRightIconClick(event) {
		event.stopPropagation()
		if (ref.current) ref.current.focus()
	}

	return (
		<div ref={ref} className={`input-menu-item ${disabled && 'disabled'}`} onClick={handleClick}>
			<span className="label">{label}</span>
			{rightIcon && <img className="right-icon" src={rightIcon} alt="" onClick={handleRightIconClick} />}
			<div className="description">{description}</div>
		</div>
	)
}

export function InputMenu({
	className = "",
	open = true,
	options = [],
	inputValue = "",
	responsive = { breakpoint: 768 },
	onInputChange = (value) => { },
	onClose = () => { }
}) {
	const inputRef = React.useRef()
	const { width: windowWidth } = useWindowSize()
	const { breakpoint } = responsive

	React.useEffect(() => {
		if (windowWidth < breakpoint && open) {
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}

		if (open) {
			document.body.classList.add('keyboard')
		} else {
			document.body.classList.remove('keyboard')
		}
	}, [open])
	React.useEffect(() => { if (inputRef.current) inputRef.current.focus() }, [inputRef])

	function handleClick(event) {
		event.stopPropagation()
		onClose()
	}

	function handleItemClick() {
		onClose()
	}

	function handleInputFocus(event) {
		document.body.classList.add('keyboard')
	}

	function handleInputBlur(event) {
		document.body.classList.add('keyboard')
	}

	return (
		<>
			{(open && ArrayHelper.isValid(options)) &&
				<div className={`input-menu ${windowWidth >= breakpoint ? 'desktop' : 'mobile'} ${className}`} onClick={handleClick}>
					<div className="input-menu-wrapper">
						<div className="input-container">
							<input
								ref={inputRef}
								value={inputValue}
								onClick={(event) => event.stopPropagation()}
								onChange={(event) => onInputChange(event.target.value)}
								onFocus={handleInputFocus}
								onBlur={handleInputBlur} />
						</div>
						<div className="item-container">
							{options.map((option, index) =>
								<InputMenuItem
									key={index}
									option={option}
									onClick={() => handleItemClick()} />
							)}
						</div>
					</div>
				</div>
			}
		</>
	)
}