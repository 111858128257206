import React from 'react'

import './styles.scss'

import { DigestList } from './digest-list'
import { DigestDetailsEl } from './details'

import { useDigests } from 'core'
import { DigestModal } from '../digest-modal'

export function DigestMyDigests({
	className = '',
}) {
	const { currentDigest, isSettingsOpen } = useDigests()

	return (
		<React.Fragment>
			<div className={`digest-my-digests ${className}`}>
				<DigestList />
				{currentDigest &&
					<DigestDetailsEl />
				}
			</div>
			{isSettingsOpen &&
				<DigestModal />
			}
		</React.Fragment>
	)
}