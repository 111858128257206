import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import './styles.scss'
import iconLockrOpened from './images/lock-404.svg'

import {
	Navigation, AppFooter,
	Loader,
} from 'components'
import { SpecialLinkEl } from './special-link'

import { useAlert, useAuth, AdminSpecialLink } from "core"
import { Storage } from 'utils'

import { config } from 'constants/config'
import { routes, redirectTo } from 'routes'

// e.g. /product-hunt?ref=producthunt

export function NotFoundScene(props) {
	const location = useLocation()
	const navigate = useNavigate()

	const { setError } = useAlert()
	const { currentUser, userGetProfile } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [specialLink, setSpecialLink] = React.useState(null)

	React.useEffect(() => { checkSpecialLink() }, [])

	function calcPathname() {
		// const fullUrl = window.location.href
		// const paths = fullUrl.split(`${window.location.host}/`)
		// if (paths.length <= 1) return null
		// let result = paths[1]
		// // console.log(result)

		// // Remove producthunt.com suffix
		// result = result.replace('?ref=producthunt', '')
		// return result

		const pathname = location.pathname
		const paths = pathname.split('/')
		const result = paths[1]
		// console.log(result)
		return result
	}

	function redirect() {
		if (!Storage.accessToken) {
			navigate(routes.Login)
			return
		}

		refreshAccess()
	}

	async function checkSpecialLink() {
		// if (!currentUser) return

		let pathname = calcPathname()

		setLoading(true)
		const { error, result } = await AdminSpecialLink.check(pathname)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		if (result && (!result.isRestrictTime || result.expireAt > new Date())) {
			setSpecialLink(result)
		} else {
			redirect()
		}
	}

	React.useEffect(() => { refreshAccess() }, [currentUser])
	function refreshAccess() {
		if (isLoading || specialLink) return

		if (Storage.accessToken && !currentUser) {
			userGetProfile()
			return
		}

		if (currentUser?.isAdmin) {
			navigate(redirectTo({
				route: routes.Admin,
				category: routes.Category.Admin.Marketing,
				searches: { specialLink: calcPathname() }
			}))
		}
	}

	return (
		<>
			{isLoading && <Loader className="error-404-loader" />}
			{!isLoading && !specialLink &&
				<div id="parent" className="error-404">
					<div className="top-bar"></div>
					<Navigation />
					<div className="error-404-wrapper">
						<div className="error-404-item-container">
							<div>
								<div className="error-404-title">oooops!</div>
								<div className="error-404-content">
									<p>We can’t find the page you’re looking for or this page is missing...We’re working on it right now....</p>
									<p>Here are some links that might help:</p>
								</div>
								<div className="error-404-forward">
									{/* <li><a href="/dashboard">View your dashboard</a></li>
								<li><a href="/digest">Manage your digests</a></li> */}
									<li><a href={config.CONTACT_URL}>Contact the lockr team</a></li>
								</div>
							</div>
							<div>
								<img src={iconLockrOpened} alt="Lockr.io" />
							</div>
						</div>
					</div>
					<AppFooter />
				</div>
			}
			{!isLoading && specialLink &&
				<SpecialLinkEl specialLink={specialLink} />
			}
		</>
	)
}