import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { routes, redirectTo } from 'routes'

export function DigestSettingsPage({ }) {
	const navigate = useNavigate()
	const { digestId } = useParams()

	React.useEffect(() => {
		redirect()
	}, [])

	function redirect() {
		navigate(redirectTo({
			route: routes.Digest,
			category: routes.Category.Digest['My Digests'],
			searches: {
				digestId,
				settings: true,
			}
		}))
	}

	return (<></>)
}