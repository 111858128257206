import React from 'react'

import { Referral } from 'core/models/referral'

import { useAlert } from 'core/providers/alert'
import { useAuth } from 'core/providers/auth'

const ReferralContext = React.createContext({})

export function ReferralProvider({
	children,
}) {
	const { setError } = useAlert()
	const { currentUser } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [referrals, setReferrals] = React.useState([])
	const [creditCount, setCreditCount] = React.useState(null)
	const [acceptedCount, setAcceptedCount] = React.useState(null)

	async function loadReferrals() {
		setLoading(true)
		const { error, results } = await Referral.getAll()
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setReferrals(results)
	}

	async function loadAcceptedCount() {
		const { error, creditCount, acceptedCount } = await Referral.getAcceptedCount()
		if (error) {
			setError(error)
			return
		}

		setCreditCount(creditCount)
		setAcceptedCount(acceptedCount)
	}

	React.useEffect(() => {
		if (currentUser && !currentUser.isGeneratedUser) {
			loadReferrals()
			loadAcceptedCount()
		}
	}, [currentUser])

	const memoedValue = React.useMemo(() => ({
		isLoading,
		referrals,
		creditCount,
		acceptedCount,

		loadReferrals
	}), [isLoading, referrals, creditCount, acceptedCount])

	return (
		<ReferralContext.Provider value={memoedValue}>
			{children}
		</ReferralContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, referrals: Array<Referral>, creditCount: number, acceptedCount: number, loadReferrals: Function}} UseReferral
 * @returns {UseReferral}
 */
export function useReferral() {
	return React.useContext(ReferralContext)
}