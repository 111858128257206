import { plainToClass } from 'class-transformer'

export class AdminFilterState {
	total
	totalFiltersPerAccount
	totalFilterUsagePerAccount
	savedFilters
	alwaysOnFilters
	timeBoundFilters
	changePercent
	ticks

	static fromJSON(json) {
		const result = plainToClass(AdminFilterState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminFilterStateTick.fromJSON(tick.current),
				'previous': AdminFilterStateTick.fromJSON(tick.previous),
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminFilterState.fromJSON(json))
	}
}

export class AdminFilterStateTick {
	from
	to
	filter

	static fromJSON(json) {
		return plainToClass(AdminFilterStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminFilterStateTick.fromJSON(json))
	}
}