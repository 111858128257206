import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom'

import './styles.scss'
import iconClose from 'assets/images/onboard_close.svg'
import iconLock from 'assets/images/onboard_lock.svg'

import {
	Alert, AlertPage, AlertMessage, AlertButtonContainer, AlertButton,
	TextField,
} from 'components'

import { User, useAuth } from 'core'
import { isLockrMail, isEmail, isValidText, Storage } from "utils"
import { redirectTo, routes } from "routes"

export function ForgotUsername(props) {
	const navigate = useNavigate()
	const location = useLocation()
	const { currentUser } = useAuth()

	React.useEffect(() => {
		redirect()
	}, [])

	function redirect() {
		if (!currentUser) return

		if (currentUser.isAdmin) {
			navigate(redirectTo({ route: routes.Admin }))
		} else if (currentUser.doneTutorial) {
			navigate(redirectTo({ route: routes.Dashboard }))
		} else {
			navigate(redirectTo({ route: routes.Tutorial }))
		}
	}

	const [email, setEmail] = useState(location.state?.email ?? '')
	const [error, setError] = useState('')
	useEffect(() => { setError('') }, [email])

	const [requested, setRequested] = useState(false)

	function handleClose(event) {
		event.preventDefault()
		navigate(-1)
	}

	async function handleSubmit() {
		if (!isEmail(email)) { return }
		if (isLockrMail(email)) {
			setError("Please use your personal email address to reset your password.")
			return
		}

		const { success, error } = await User.requestForgotUsername(email)
		if (error) {
			setError(error)
		} else {
			setRequested(true)
		}
	}

	return (
		<div className="forgot-username">
			<div className="forgot-username-close" onClick={handleClose}>
				<img src={iconClose} alt="Close" />
			</div>
			<div className="forgot-username-container">
				<div className="forgot-username-logo">
					<img src={iconLock} alt="Loc.kr" />
				</div>
				<div className="forgot-username-title">Forgot lockrMail Account</div>
				<div className="forgot-username-message">Enter a verified email associated with your lockrMail account and we'll send you an email with your username.</div>
				<div className="forgot-username-form mt-48">
					<TextField
						type="large"
						id="emailInput"
						autoFocus
						placeholder="Email address"
						value={email}
						// autoComplete domains={['lockrmail.com']}
						error={isValidText(error)}
						onChange={event => setEmail(event.target.value)}
						onNext={() => handleSubmit()} />
				</div>
				<div className="forgot-username-error">
					{isValidText(error) && error}
				</div>
				<div className="forgot-username-button">
					<button type="button" disabled={!isEmail(email)} onClick={() => handleSubmit()}>
						Submit
					</button>
				</div>
			</div>
			{requested &&
				<Alert onCancel={() => setRequested(false)}>
					<AlertPage>
						<AlertMessage value="Please check your primary inbox for an email we just sent you with instructions for how to reset your password and log into your account." />
						<AlertButtonContainer>
							<AlertButton title="OK" type="positive" onClick={() => navigate(-1)} />
							<AlertButton title="Contact Us" type="negative" onClick={() => window.open('https://lockrmail.com/contact', '_blank')} />
						</AlertButtonContainer>
					</AlertPage>
				</Alert>
			}
		</div>
	)
}
