import { plainToClass } from 'class-transformer'

import { Api } from 'core/api'
import { DateHelper } from 'utils'
import { errorMessages } from 'constants/error'

export class BaseModel {
	id
	createdAt
	updatedAt
	deletedAt

	static fromJSON(json) {
		const result = json && plainToClass(this, json)
		if (result) {
			if (json?.createdAt) result.createdAt = DateHelper.parse(json.createdAt)
			if (json?.updatedAt) result.updatedAt = DateHelper.parse(json.updatedAt)
			if (json?.deletedAt) result.deletedAt = DateHelper.parse(json.deletedAt)
		}
		return result
	}

	/** @returns {Array} */
	static fromJSONArray(arrJson) {
		if (!arrJson) return []

		return arrJson.map((json) => this.fromJSON(json))
	}

	static async uploadFiles(files) {
		try {
			const { error, data } = await Api.uploadFiles('/admin/upload', files)
			// console.log(data)
			return { error, results: data || [] }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}
}