import React from 'react'

import iconClear from 'assets/images/cross-12px-888888.svg'
import iconDrag from '../../assets/images/drag-green.svg'
import iconNoDrag from '../../assets/images/no-drag-gray.svg'

import { MenuOption, DropDownMenu } from '../../components'
import { ConnectedEmail } from '../../core'
import { phoneNumberAutoComplete, isValidText } from '../../utils'
import iconLoading from '../../assets/images/loading.svg'


export class InputField extends React.Component {
	inputRef = React.createRef()

	focus = () => {
		if (this.inputRef.current) {
			this.inputRef.current.focus()
		}
	}

	options = () => {
		const { label } = this.props
		const labels = ConnectedEmail.emailTypes()
		return labels.map(e => (
			new MenuOption({
				label: e,
				selected: (e === label),
				onClick: () => this.handleLabelChanged(e)
			})
		))
	}

	handleChange = (event) => {
		const { type, value, onChange } = this.props
		event.preventDefault()
		if (!onChange) { return }

		if (type === 'phoneNumber') {
			onChange(phoneNumberAutoComplete(event.target.value.trim(), value))
			return
		}

		onChange(event.target.value)
	}

	handleClear = (event) => {
		const { onClear } = this.props
		event.preventDefault()
		if (onClear) {
			onClear()
		}
	}

	handleLabelChanged = (label) => {
		const { onLabelChanged } = this.props
		if (onLabelChanged) { onLabelChanged(label) }
	}

	handleKeyDown = (event) => {
		const { onNext } = this.props
		if (event.key === 'Enter' && onNext) {
			onNext()
		}
	}

	handleCheck = (event) => {
		if (event) event.preventDefault()
		const { onChecked } = this.props
		if (onChecked) {
			onChecked()
		}
	}

	handleCommand = (event) => {
		if (event) event.preventDefault()
		const { onCommand } = this.props
		if (onCommand) onCommand()
	}

	render() {
		const {
			id, className,
			type = 'text', label, value, command, commandIcon,
			inputStyle,
			isNewEmail,
			checked,
			dragging = false,
			optionEnabled,
			disabled = false, inputDisabled = false,
			isPersonalEmail = false,
			error,
			clearable = true,
			children,
			loader,
		} = this.props
		return (
			<>
				<div className={`group-item ${className ? className : ''}`}>
					{!optionEnabled && <div className="group-item-title">{label}</div>}
					{optionEnabled &&
						<div className="group-item-title">
							<DropDownMenu
								className="group-item-title"
								options={this.options()}
							/>
							{type === 'email' && <>{dragging ? <img src={iconDrag} alt="" /> : <img src={iconNoDrag} alt="" />}</>}
						</div>
					}
					<div className={`group-item-input ${inputStyle ? inputStyle : ''} ${disabled ? 'disabled' : ''}`}>
						<input
							ref={this.inputRef}
							id={id}
							className={`${isNewEmail && 'new-connected-email'}`}
							data-testid={id}
							type={type}
							value={value || ""}
							disabled={disabled || inputDisabled || isPersonalEmail}
							onChange={(event) => this.handleChange(event)}
							onKeyDown={this.handleKeyDown} />
						{(!disabled && isValidText(value || "") && clearable) &&
							<>
								{command ?
									<button className="group-item-button" onClick={(event) => this.handleCommand(event)}>
										{command}
										{commandIcon && <img src={commandIcon} alt="" />}
									</button>
									:
									<>
										{loader ? <img src={iconLoading} alt="" className='new-email-loader' /> : isNewEmail ?
											<button className="group-item-check btn-add-new-connected-email" onClick={(event) => this.handleCheck(event)}>
												{/* {checked && <img src={iconInputChecked} alt="" />} */}
												{checked && 'PRESS ENTER'}
											</button>
											:
											<button className="group-item-clear" onClick={(event) => this.handleClear(event)}>
												<img src={iconClear} alt="" />
											</button>
										}
									</>
								}
							</>
						}
						{children}
					</div>
				</div>
				{error && <label htmlFor={id}>{error}</label>}
			</>
		)
	}
}