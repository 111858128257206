import React from 'react'
import PropTypes from 'prop-types'

import { randomUUID, isMobile } from '../../utils/helper'

export class MenuOption {
	type = 'option'		/// option, command or calendar
	position = 'left'
	icon = null
	hoverIcon = null
	label = ''
	info = null
	description = null
	selected = false
	disabled = false
	isParent = true
	subOptions = []
	after = null
	onClick = () => { }

	constructor({ type, position, icon, hoverIcon, label, selected, disabled, info, description, subOptions, after, onClick }) {
		this.type = type || this.type
		this.position = position || this.position
		this.icon = icon || this.icon
		this.hoverIcon = hoverIcon || this.hoverIcon
		this.label = label || this.label
		this.info = info || this.info
		this.description = description || this.description
		this.selected = selected || false
		this.disabled = disabled || this.disabled
		this.subOptions = subOptions ? subOptions.map(e => {
			e.isParent = false
			return e
		}) : []
		this.after = after
		this.onClick = onClick || this.onClick
	}
}

MenuOption.propTypes = {
	type: PropTypes.oneOf(['option', 'command', 'calendar']),
	position: PropTypes.oneOf(['left', 'right']),
	icon: PropTypes.element,
	hoverIcon: PropTypes.element,
	label: PropTypes.string,
	info: PropTypes.object,
	description: PropTypes.string,
	selected: PropTypes.bool,
	disabled: PropTypes.bool,
	isParent: PropTypes.bool,
	subOptions: PropTypes.arrayOf(PropTypes.any),
	after: PropTypes.element,
}

export class Menu extends React.Component {
	menuRef = React.createRef()
	menuId = undefined
	mounted = false

	static openedComponents = []
	static closeOtherComponents(newComponent = null) {
		Menu.openedComponents.forEach((e) => {
			if (newComponent === e) return

			if (typeof e.handleClose !== "undefined") {
				Menu.openedComponents.pop(e)
				e.handleClose()
			}
			else if (typeof e.close !== "undefined") {
				Menu.openedComponents.pop(e)
				e.close()
			}
		})

		if (newComponent) {
			Menu.openedComponents.push(newComponent)
		}
	}

	static closeComponent(component) {
		Menu.openedComponents.pop(component)
	}

	constructor(props) {
		super(props)

		this.menuId = this.props.id || randomUUID()

		this.close = this.close.bind(this)
	}

	componentDidMount() {
		this.mounted = true
	}

	componentWillUnmount() {
		this.mounted = false
	}

	handleWindowClicked = (event) => { }

	get isOpen() {
		const { isOpen } = this.state
		return isOpen === true ? true : false
	}

	open = () => { this.handleOpen() }
	close() { this.handleClose() }
	toggle = () => {
		if (this.state && this.state.isOpen)
			this.close()
		else
			this.open()
	}

	handleOpen = () => {
		this.setState({ isOpen: true })
		Menu.closeOtherComponents(this)
		window.addEventListener('click', this.handleWindowClicked, false)

		if (isMobile && this.menuRef.current) {
			this.menuRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	/// Whenever update, copy inner proc to Hemu->handleClose()
	handleClose = () => {
		this.setState({ isOpen: false })
		Menu.closeComponent(this)
		window.removeEventListener('click', this.handleWindowClicked, false)
		if (this.props.onClose) { this.props.onClose() }
	}
}