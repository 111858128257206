import React from 'react'

import { useAlert } from 'core/providers/alert'
import { useFilter } from 'core/providers/filter/filter'
import { Filter, FilterKeyword } from 'core/models'
import { ArrayHelper } from 'utils'

const CustomFilterContext = React.createContext({})

export function CustomFilterProvider({
	children,
}) {
	const { filter, senders } = useFilter()

	const [alwaysOn, setAlwaysOn] = React.useState(true)
	const [from, setFrom] = React.useState(null)
	const [to, setTo] = React.useState(null)
	const [keywords, setKeywords] = React.useState(initialKeywords())
	const [filterByAttachment, setFilterByAttachment] = React.useState(false)
	const [hasAttachment, setHasAttachment] = React.useState(null)
	const [hasAttachmentOp, setHasAttachmentOp] = React.useState('and')
	const [isSaveFilter, setIsSaveFilter] = React.useState(false)
	const [name, setName] = React.useState('')

	const [senderError, setSenderError] = React.useState(null)
	const [dateError, setDateError] = React.useState(null)
	React.useEffect(() => { setDateError(null) }, [alwaysOn, from, to])
	const [nameError, setNameError] = React.useState(null)
	React.useEffect(() => { setNameError(null) }, [isSaveFilter, name])
	const [error, setError] = React.useState(null)

	React.useEffect(() => { handleFilter() }, [filter])

	function initialKeywords() {
		if (filter && filter.keywords.length > 0) {
			return ArrayHelper.copy(filter.keywords)
		}
		return [FilterKeyword.create(), FilterKeyword.create()]
	}

	function checkIfChanged() {
		let isChanged = false

		const lastKeywords = initialKeywords()
		if (filter) {
			isChanged = alwaysOn !== filter.alwaysOn
				|| from !== filter.from || to !== filter.to
				|| !ArrayHelper.equals(keywords, lastKeywords)
				|| filterByAttachment !== filter.filterByAttachment
				|| hasAttachment !== filter.hasAttachment
				|| (filter.filterByAttachment && hasAttachmentOp !== filter.hasAttachmentOp)
		} else {
			isChanged = isSaveFilter || name !== ''
				|| !alwaysOn
				|| (alwaysOn && (Boolean(from) || Boolean(to)))
				|| !ArrayHelper.equals(keywords, lastKeywords)
				|| filterByAttachment
				|| (filterByAttachment && (hasAttachment !== true || hasAttachmentOp === "and"))
		}

		return isChanged
	}

	function handleFilter() {
		setAlwaysOn(filter?.alwaysOn ?? true)
		setFrom(filter?.from ?? null)
		setTo(filter?.to ?? null)
		setKeywords(initialKeywords())
		setFilterByAttachment(filter?.filterByAttachment ?? false)
		setHasAttachment(filter?.hasAttachment ?? null)
		setHasAttachmentOp(filter?.hasAttachmentOp ?? 'and')
		setIsSaveFilter(Boolean(filter?.name ?? ''))
		setName(filter?.name ?? '')
	}

	function checkValidation() {
		if (!ArrayHelper.isValid(senders)) {
			setSenderError('Please assign filter to one or more Sender(s).')
			return false
		}

		if (!alwaysOn) {
			if (!from) {
				setDateError('Please select start date.')
				return false
			}

			if (!to) {
				setDateError('Please select end date.')
				return false
			}
		}

		if (isSaveFilter && !name) {
			setNameError('Please fill the filter\'s name.')
			return false
		}

		return true
	}

	function isDefaultFilter() {
		const result = Filter.isDefault({ alwaysOn, from, to, keywords, hasAttachment, hasAttachmentOp })
		return result
	}

	const memoedValue = React.useMemo(() => ({
		alwaysOn, setAlwaysOn,
		from, setFrom,
		to, setTo,
		keywords, setKeywords,
		filterByAttachment, setFilterByAttachment,
		hasAttachment, setHasAttachment,
		hasAttachmentOp, setHasAttachmentOp,
		isSaveFilter, setIsSaveFilter,
		name, setName,

		senderError, dateError, nameError, error,

		isDefaultFilter,
		checkIfChanged,
		checkValidation,
	}), [filter, senders, alwaysOn, from, to, keywords, filterByAttachment, hasAttachment, hasAttachmentOp, isSaveFilter, name, senderError, dateError, nameError, error])

	return (
		<CustomFilterContext.Provider value={memoedValue}>
			{children}
		</CustomFilterContext.Provider>
	)
}

/**
 * @typedef {{alwaysOn: boolean, setAlwaysOn: *, from: Date, setFrom: *, to: Date, setTo: *, keywords: Array<FilterKeyword>, setKeywords: *, filterByAttachment: boolean, setFilterByAttachment: *, hasAttachment: boolean, setHasAttachment: *, hasAttachmentOp: boolean, setHasAttachmentOp: *, isSaveFilter: boolean, setIsSaveFilter: *, name: string, setName: *, senderError: string, dateError: string, nameError: string, error: string, save: *, isDefaultFilter: * checkIfChanged: *, checkValidation: *}} UseCustomFilter
 * @returns {UseCustomFilter}
 */
export function useCustomFilter() {
	return React.useContext(CustomFilterContext)
}