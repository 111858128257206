import React from 'react'

import iconInfo from 'assets/images/info-gray-16.svg'


import {
	Switch, IModal
} from 'components'

export function SectionItem({
	className = "",
	title = "",
	description = "",
	imodal,
	children,
	value = false,
	onChange = (value) => { }
}) {
	const iIconRef = React.useRef()

	return (
		<div className={`account-settings-section-item ${className}`}>
			<div className="account-settings-section-item-wrapper">
				<div className="info">
					<div className="title">
						{title}
						{imodal &&
							<>
								<img ref={iIconRef} src={iconInfo} alt="" />
								<IModal anchorEl={iIconRef.current}>{imodal}</IModal>
							</>
						}
					</div>
					<div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
				</div>
				<Switch
					value={value}
					onChange={(value) => onChange(value)} />
			</div>
			{children}
		</div>
	)
}