import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'
import iconSendMail from 'assets/images/send-mail-8B8B8B.svg'
import iconSendMailHover from 'assets/images/send-mail-61CEF7.svg'

import {
	Checkbox,
	Select, SelectDropDown, SelectGroup, SelectOption,
	MenuOption,
	SearchField,
	IconButton,
} from 'components'

import { useAlert, Mail } from 'core'
import { ArrayHelper, DOMHelper } from 'utils'

export function MailModalHeader({
	selectedMailIds = [],

	onSelectAll = (value) => { },

	searchText = "",
	onSearchText = (value) => { },

	searchOption,
	onSearchOption = (value) => { },

	senderStatus = null,
	onSenderStatus = (value) => { },

	onChange = (mailIds) => { }
}) {
	const { width } = useWindowSize()

	const { setError } = useAlert()

	React.useEffect(() => {
		DOMHelper.enableScroll(false)
		return () => {
			DOMHelper.enableScroll(true)
		}
	}, [])

	const [isLoading, setLoading] = React.useState(false)

	const [isBatchEnabled, setBatchEnabled] = React.useState(false)
	React.useEffect(() => {
		setBatchEnabled(ArrayHelper.isValid(selectedMailIds))
	}, [selectedMailIds])

	async function handleDeliver() {
		setLoading(true)
		const { error } = await Mail.deliver(selectedMailIds)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setError(null)
		onChange(selectedMailIds)
	}

	return (
		<>
			<div className="mail-modal-header">
				<div className="checkbox-container">
					<Checkbox
						iconUnchecked={iconUnchecked}
						iconChecked={iconChecked}
						checked={isBatchEnabled}
						onChange={(value) => onSelectAll(value)} />
					<div className="arrow-container">
						<img src={iconArrowDown} alt="" />
						<SelectDropDown>
							<SelectOption selectable={false} onClick={() => onSelectAll(true)}>Select All</SelectOption>
							<SelectOption selectable={false} onClick={() => onSelectAll(false)}>Deselect All</SelectOption>
						</SelectDropDown>
					</div>
				</div>
				<div className={`batch-container ${isBatchEnabled && 'active'}`}>
					<IconButton
						className="btn-batch deliver"
						normal={iconSendMail}
						hover={iconSendMailHover}
						onClick={() => handleDeliver()}
					/>
				</div>
				<div className="filter-container">
					<SearchField
						minimized={width <= 576}
						options={[
							new MenuOption({
								label: 'Show results from:',
								subOptions: [
									new MenuOption({ label: 'All content', selected: (searchOption === 'all_content'), onClick: () => onSearchOption('all_content') }),
									new MenuOption({ label: 'Only email subject', selected: (searchOption === 'only_subject'), onClick: () => onSearchOption('only_subject') }),
									new MenuOption({ label: 'Only email content', selected: (searchOption === 'only_content'), onClick: () => onSearchOption('only_content') }),
								]
							})
						]}
						value={searchText}
						onChange={(value) => onSearchText(value)} />
					<Select
						keepLabel
						label={`${width >= 577 ? 'View Options' : 'View'}`}
						minimized={width <= 576}
						mobileTitle="View Options"
						filterApplied={senderStatus !== null}>
						<SelectGroup label="Sender Status">
							<SelectOption selected={senderStatus === null} onClick={() => onSenderStatus(null)}>All</SelectOption>
							<SelectOption selected={senderStatus === true} onClick={() => onSenderStatus(true)}>Blocked only</SelectOption>
							<SelectOption selected={senderStatus === false} onClick={() => onSenderStatus(false)}>Allowed only</SelectOption>
						</SelectGroup>
					</Select>
				</div>
			</div>
		</>
	)
}