import React from 'react'

import './styles.scss'
import iconArrowDown from '../../../assets/images/arrow_down.svg'

import { MenuOption, HMenu } from '..'

import { DateHelper } from '../../../utils'

export class HMenuDatePicker extends React.Component {
	constructor(props) {
		super(props)

		this.menuRef = React.createRef()
	}

	componentDidUpdate() {
		if (this.menuRef.current) {
			this.menuRef.current.update()
		}
	}

	handleBoxClick = (event) => {
		event.preventDefault()
		if (this.menuRef && this.menuRef.current) {
			this.menuRef.current.toggle()
		}
	}

	handleSelect = (date) => {
		const { onSelect = (date) => { } } = this.props
		onSelect(date)
	}

	renderDate = () => {
		const { date, initialLabel } = this.props
		if (!date) return initialLabel || 'Never'
		if (DateHelper.isSameDay(date, new Date())) {
			return `Today, ${DateHelper.format(date, 'MMM DD')}`
		} else if (DateHelper.isSameYear(date, new Date())) {
			return DateHelper.format(date, 'ddd MMM DD')
		} else {
			return DateHelper.format(date, 'ddd MMM DD, YYYY')
		}
	}

	render() {
		const { className = "", date, position, baseDate = new Date() } = this.props
		const options = position === 'first' ? [
			new MenuOption({ type: 'calendar', info: date, onClick: (date) => this.handleSelect(date) })
		] : [
			new MenuOption({
				label: 'Never',
				onClick: () => this.handleSelect(null),
			}),
			new MenuOption({
				label: '2 weeks',
				onClick: () => this.handleSelect(DateHelper.addDay(baseDate, 14)),
			}),
			new MenuOption({
				label: '1 Month',
				onClick: () => this.handleSelect(DateHelper.addMonth(baseDate, 1)),
			}),
			new MenuOption({
				label: '3 Months',
				onClick: () => this.handleSelect(DateHelper.addMonth(baseDate, 3)),
			}),
			new MenuOption({
				label: '1 year',
				onClick: () => this.handleSelect(DateHelper.addYear(baseDate, 1)),
			}),
			new MenuOption({
				label: 'Specific date',
				position: 'right',
				subOptions: [
					new MenuOption({ type: 'calendar', info: date, onClick: (date) => this.handleSelect(date) })
				]
			}),
		]

		return (
			<div className={`h-menu-date-picker h-menu-parent ${position} ${className}`}>
				<div className="date-picker-box" onClick={event => this.handleBoxClick(event)} >
					<div className="date-picker-box-label">{this.renderDate()}</div>
					<img src={iconArrowDown} />
				</div>
				<HMenu
					ref={this.menuRef}
					options={options}
				/>
			</div>
		)
	}
}