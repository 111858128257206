import React from 'react'

import './styles.scss'

export function DefaultProgressBar({
	className = "",
	min = 0,
	max = 100,
	progress = 0,
}) {
	const progressRef = React.useRef()

	React.useEffect(() => { applyProgress() }, [progress])

	function applyProgress() {
		if (!progressRef.current) return

		const percent = parseFloat(progress - min) / parseFloat(max - min) * 100.0

		progressRef.current.style.width = `${percent}%`
	}

	return (
		<div className={`${className} progress-bar default`}>
			<div ref={progressRef}></div>
		</div>
	)
}