import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowSize, usePrevious } from 'react-use'

import { useAlert } from 'core/providers/alert'
import { useAuth } from 'core/providers/auth'
import {
	Digest, Sender,
} from 'core/models'
import { ArrayHelper } from 'utils'
import { routes, redirectTo, parseSearchParams } from 'routes'


const DigestsContext = React.createContext({})

export function DigestsProvider({
	children,
}) {
	const navigate = useNavigate()
	const location = useLocation();
	const [searchParams] = useSearchParams()
	const { width } = useWindowSize()

	function isDesktop() { return width >= 768 }

	const { setError } = useAlert()
	const { currentUser } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [myDigests, setMyDigests] = React.useState([])
	const [archivedDigests, setArchivedDigests] = React.useState([])
	/** @type {[Array<Digest>, Function]} */
	const [digests, setDigests] = React.useState([])

	const [currentDigest, setCurrentDigest] = React.useState(null)
	const prevDigest = usePrevious(currentDigest)
	const [isSettingsOpen, setSettingsOpen] = React.useState(false)
	const [enableDigestSave, setEnableDigestSave] = React.useState(false);

	React.useEffect(() => {
		setMyDigests(digests.filter(x => !x.isArchived))
		setArchivedDigests(digests.filter(x => x.isArchived))
	}, [digests])

	React.useEffect(() => {
		parseLocation()
	}, [myDigests, archivedDigests, searchParams, location.pathname])
	function parseLocation() {
		const { digestId, settings } = parseSearchParams(searchParams)

		const digest = digestId && digests.find(x => x.id === digestId)
		if (digest) setCurrentDigest(digest)
		else if (isDesktop() && location.pathname.includes('/digest/my-digests')) {
			if (ArrayHelper.isValid(myDigests)) digestSelect(myDigests[0])
			else if (ArrayHelper.isValid(archivedDigests)) digestSelect(archivedDigests[0])
			else setCurrentDigest(null)
		}
		else setCurrentDigest(null)

		setSettingsOpen(settings)
	}

	React.useEffect(() => { if (currentUser) digestsLoad() }, [currentUser])
	async function digestsLoad(loading = true) {
		if (loading) setLoading(true)
		const { error, results } = await Digest.load()
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setDigests(results)
	}

	function digestSelect(digest) {
		const digestId = digest instanceof Digest ? digest.id : digest
		navigate(redirectTo({
			route: routes.Digest,
			category: routes.Category.Digest['My Digests'],
			searches: { digestId }
		}))
	}

	function digestOpenSettings(open = true) {
		const digestId = currentDigest instanceof Digest ? currentDigest.id : currentDigest
		navigate(redirectTo({
			route: routes.Digest,
			category: routes.Category.Digest['My Digests'],
			searches: { digestId, settings: open ? true : null }
		}))
	}

	async function digestUpdateOrCreate(params, navigateCheck = true) {
		const { error, result } = await Digest.updateOrCreate(params)

		if (result) {
			if (!digests.some(x => x.id === result?.id)) setDigests([...digests, result])
			if (navigateCheck) {
				digestSelect(result)
			}
		}

		return { error, result }
	}

	const [isSenderLoading, setSenderLoading] = React.useState(false)
	const [currentSenders, setCurrentSenders] = React.useState([])
	React.useEffect(() => { if (prevDigest?.id !== currentDigest?.id) digestLoadSenders() }, [currentDigest])
	async function digestLoadSenders(loading = true, successCallback = undefined) {
		setCurrentSenders([])

		if (!currentDigest?.id) return

		if (loading) setSenderLoading(true)
		const { error, results } = await Digest.getSenders(currentDigest?.id)
		setSenderLoading(false)

		if (error) {
			setError(error)
			return
		}

		if (successCallback) {
			successCallback(results);
		}

		setCurrentSenders(results)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		myDigests,
		archivedDigests,
		digests,
		currentDigest, isSettingsOpen,
		isSenderLoading, currentSenders, enableDigestSave,

		digestsLoad,
		digestSelect, digestOpenSettings,
		digestUpdateOrCreate,
		digestLoadSenders,
		setEnableDigestSave
	}), [isLoading, myDigests, archivedDigests, digests, currentDigest, isSettingsOpen, isSenderLoading, currentSenders, enableDigestSave])

	return (
		<DigestsContext.Provider value={memoedValue}>
			{children}
		</DigestsContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, myDigests: Array<Digest>, archivedDigests: Array<Digest>, digests: Array<Digest>, currentDigest: Digest, isSettingsOpen: boolean, digestsLoad: Function, digestSelect: Function, digestOpenSettings: Function, digestUpdateOrCreate: Function, isSenderLoading: boolean, currentSenders: Array<Sender>, digestLoadSenders: Function, enableDigestSave: boolean, setEnableDigestSave}} UseDigests
 * @returns {UseDigests}
 */
export function useDigests() {
	return React.useContext(DigestsContext)
}