import React from 'react'

import { useAlert } from 'core/providers'
import { AdminUnregisteredUser } from 'core/models'

const AdminUnregisteredUserContext = React.createContext({})

export function AdminUnregisteredUserProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [unregisteredUsers, setUnregisteredUsers] = React.useState([])

	async function searchUnregisteredUsers({ searchText, pageIndex, pageSize, sortBy }) {
		setLoading(true)
		const { error, totalCount, results } = await AdminUnregisteredUser.search({ searchText, pageIndex, pageSize, sortBy })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(totalCount)
		setUnregisteredUsers(results)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		unregisteredUsers,
		totalCount,

		searchUnregisteredUsers
	}), [isLoading, unregisteredUsers, totalCount])

	return (
		<AdminUnregisteredUserContext.Provider value={memoedValue}>
			{children}
		</AdminUnregisteredUserContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, totalCount: number, unregisteredUsers: Array<AdminUnregisteredUser>, searchUnregisteredUsers: Function}} UseAdminUnregisteredUser
 * @returns {UseAdminUnregisteredUser}
 */
export function useAdminUnregisteredUsers() {
	return React.useContext(AdminUnregisteredUserContext)
}