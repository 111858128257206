import React from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'

import { routes, redirectTo } from 'routes'

export function ViewAllRibbonEl() {
	const navigate = useNavigate()

	function handleAccount(event) {
		navigate(redirectTo({
			route: routes.Account,
			category: routes.Category.Account.Settings
		}))
	}
	return (
		<div className="view-all-ribbon">
			<span>View your default account settings&nbsp;</span>
			<a href={`/account/${routes.Category.Account.Settings}`} onClick={handleAccount}>here</a>
		</div>
	)
}