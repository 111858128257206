import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

/**
 * @typedef {{className: String, disabled: Boolean, responsive: Object, onClick: *}} Props
 * @param {Props} param0
 */
export function Button({
	className = "",
	disabled = false,
	responsive = { breakpoint: 768 },
	children,
	onClick = (event) => { }
}) {
	const { breakpoint } = responsive
	const { width: windowWidth } = useWindowSize()

	function handleClick(event) {
		onClick(event)
	}

	return (
		<>
			{windowWidth >= breakpoint ?
				<button
					className={`${className} btn-default desktop`}
					disabled={disabled}
					onClick={(event) => handleClick(event)}>
					{children}
				</button>
				:
				<button
					className={`${className} btn-default mobile`}
					disabled={disabled}
					onTouchStart={(event) => handleClick(event)}>
					{children}
				</button>
			}
		</>
	)
}