import React from 'react'

import './styles.scss'

import { DiscoverCompany } from 'core'

/**
 * @typedef {{value:DiscoverCompany}} Props
 * @param {Props} param0
 * @returns
 */
export function DiscoverCompanyItem({
	value = {}
}) {
	const { title, avatarDesktop, description, url, subscribeText } = value
	return (
		<div className="discover-company-item">
			<div className="avatar">
				<img src={avatarDesktop} alt="" loading="lazy" />
			</div>
			<div className="name">{title}</div>
			<div className="description">{description}</div>
			<div className="subscribe-container">
				<a href={url} target="_blank">{subscribeText || 'Subscribe'}</a>
			</div>
		</div>
	)
}