import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { User } from 'core/models/user'
import { errorMessages } from 'constants/error'

export class AdminUnregisteredUser extends BaseModel {
	firstName = ''
	lastName = ''
	email = ''
	permalink = ''
	params = ''

	/**
	 * @returns {AdminUnregisteredUser}
	 */
	static fromJSON(json) { return super.fromJSON(json) }


	/**
	 * @returns {Array<AdminUnregisteredUser>}
	 */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async search({ searchText, pageIndex, pageSize, sortBy }) {
		try {
			const { error, data } = await Api.get('/admin/unregistered-users', {
				searchText,
				pageNum: pageIndex + 1,
				pageSize,
				sortBy
			})

			return {
				error: error && errorMessages.NETWORK_ERROR,
				totalCount: data?.count ?? 0,
				results: AdminUnregisteredUser.fromJSONArray(data?.users)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async saveNewEmail({ email, newEmail, isRegistered}) {
		try {
			const { error } = await Api.post('/admin/unregistered-users', {
				email,
				newEmail,
				isRegistered
			})

			return {
				error:  errorMessages.NETWORK_ERROR && error
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	/** @param {Array<AdminUnregisteredUser>} users */
	static async sendVerifyEmail(users) {
		try {
			const params = {
				users: users.map(x => ({ email: x.email, firstName: x.firstName, lastName: x.lastName }))
			}
			const { error } = await Api.post('/admin/email/send-verify', params)
			return {
				error: error && errorMessages.NETWORK_ERROR,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	async resendVerifyEmail() {
		let params = { firstName: this.firstName, lastName: this.lastName }
		if (this.permalink?.includes('wordpress|')) params = { ...params, wordpressLink: this.permalink.replace('wordpress|', '') }
		else if (this.permalink) params = { ...params, permalink: this.permalink }

		return await User.sendVerifyEmail(this.email, params)
	}
}