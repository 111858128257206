import React from 'react'
import Calendar from 'react-calendar'

import iconCalendarBackGray from "../../../assets/images/arrow/back-small-gray.svg"
import iconCalendarBackGreen from "../../../assets/images/arrow/back-small-green.svg"

export function HMenuCalendar({
	option,
	onSelect = (date) => { }
}) {
	return (
		<div className="h-menu-calendar">
			<Calendar
				className="calendar"
				defaultValue={option.info || new Date()}
				prevLabel={
					<div className="prev">
						<img src={iconCalendarBackGray} alt="" />
						<img src={iconCalendarBackGreen} alt="" />
					</div>
				}
				nextLabel={
					<div className="next">
						<img src={iconCalendarBackGray} alt="" />
						<img src={iconCalendarBackGreen} alt="" />
					</div>
				}
				formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
				onClickDay={(value, event) => onSelect(value)}
			/>
		</div>
	)
}