import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import iconLogo from 'assets/images/lockr-logo.svg'
import lockLogo from 'assets/images/lock.svg'

import './styles.scss'
import { TutorialTutorials } from './tutorials'
import { TutorialDiscover } from './discover'

import { useAuth } from 'core'
import { routes, redirectTo } from 'routes'
import { useWindowSize } from 'react-use'
import { useWidth } from 'utils'

const tabs = routes.Category.Tutorial

export function TutorialScene({ responsive = { desktop: 992, tablet: 576 }, }) {
	const navigate = useNavigate()
	const { category = tabs.Tutorials } = useParams()
	const { width } = useWindowSize()

	const { currentUser } = useAuth()

	function handleTab(tab) {
		navigate(redirectTo({
			route: routes.Tutorial,
			category: tab,
		}))
	}

	function handleSkip() {
		if (category === tabs.Tutorials) {
			handleTab(tabs.Discover)
		} else {
			navigate(redirectTo({
				route: routes.Dashboard
			}))
			currentUser?.update({ doneTutorial: true })
		}
	}
	function device() {
		if (width >= responsive.desktop) return 'desktop'
		if (width >= responsive.tablet) return 'tablet'
		return 'mobile'
	}
	const { width: wid } = useWidth();

	return (
		<div id="parent" className=" tutorial">
			{wid > 576 ? <div style={{ borderBottom: '1px solid #353738' }}>
				<div style={{ maxWidth: 1440, marginRight: 'auto', marginLeft: 'auto', }}>
					<div className={`nav-wrapper ${device()}`}>
						<a className="logo" href="/">
							<img src={iconLogo} alt="Lockr.io" />
						</a>
						<button className={`btn-skip ${category === tabs.Discover && 'finish'}`} onClick={() => handleSkip()}>
							<span>{category === tabs.Discover ? 'Finish' : 'Skip'}</span>
							<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 21L11 11L1 0.999999" stroke="#61CEF7" strokeLinecap="round" />
							</svg>
						</button>
					</div>
				</div>
			</div> : <div className="header"> &nbsp;
				<button className={`button-skip ${category === tabs.Discover && 'finish'}`} onClick={() => handleSkip()}>
					<span>{category === tabs.Discover ? 'Finish' : 'Skip'}</span>
					<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 21L11 11L1 0.999999" stroke="#61CEF7" strokeLinecap="round" />
					</svg>
				</button>
			</div>}
			<div className="tutorial-wrapper">


				<div className="tutorial-content">
					{category === tabs.Tutorials &&
						<TutorialTutorials
							onContinue={() => handleTab(tabs.Discover)} />
					}
					{category === tabs.Discover &&
						<TutorialDiscover />
					}
				</div>
			</div>
		</div>
	)
}