import { AffiliateComponent } from "components/affiliate-component";
import React, { useState } from "react";
import './styles.scss';

export function AffiliateBanner({ affiliates = [] }) {


    return <div style={{ position: 'sticky', left: 0, height: '441px', width: '100vw' }}>
        <div className="affiliate-banner-parent" style={{ position: 'absolute' }}>
            <div className="shop-at-your-fav">Shop and save at your favorite sites</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, overflow: 'scroll', maxWidth: '100vw', }} className="scrollbar-width-remove">
                    {affiliates.map(affiliate => (
                        <AffiliateComponent affiliateObject={affiliate} description={affiliate.description} image={affiliate.imageUrl} title={affiliate.title} />
                    ))}
                </div>
            </div>
        </div>
    </div>
}