import React from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import imgPreview1 from 'assets/images/digest-confirm-preview.svg'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton
} from 'components/alert/core'

import { routes, redirectTo } from 'routes'

export function DigestAccessAlert({
	onClose = () => { }
}) {
	const navigate = useNavigate()
	function handleCreateDigest() {
		navigate(redirectTo({ route: routes.Digest }))
		onClose()
	}

	async function handleClose() {
		onClose()
	}

	return (
		<Alert className="digest-access-alert" onCancel={() => handleClose()}>
			<AlertPage>
				<div className="digest-preview-wrapper">
					<img className="digest-preview-image" src={imgPreview1} alt="" />
				</div>
				<AlertTitle>
					<div>Introducing Digests</div>
				</AlertTitle>
				<div className="digest-access-alert-description">
					You now have early access to our latest feature! Digests allow you to combine communications from various Senders into one convenient email - delivered at your requested date and time.
				</div>
				<AlertButtonContainer>
					<AlertButton onClick={() => handleCreateDigest()}>Create a Digest</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}