import React from 'react'

import './styles.scss'
import arrowNext from '../../../assets/images/arrow-next-white.svg'
import iconChecked from '../images/checked-green-no-outline.svg'

import { isDesktop, isMobile, isSmallMobile } from '../../../utils/helper'
import { Menu } from '../'


export class VMenu extends Menu {
	constructor(props) {
		super(props)

		const { isOpen } = this.props
		this.state = {
			isOpen: isOpen || false,
		}
	}

	handleWindowClicked = (event) => {
		if (!event.target.closest(".v-menu-parent")) {
			event.stopPropagation()
			this.handleSubOptionClose()
		}
	}

	handleClose = () => {
		this.setState({ isOpen: false })
		Menu.closeComponent(this)
		window.removeEventListener('click', this.handleWindowClicked, false)
		if (this.props.onClose) { this.props.onClose() }
	}

	handleClick = (event, option, index) => {
		event.preventDefault()
		event.stopPropagation()

		const { onClickMenu } = this.props
		if (option.subOptions <= 0) {
			if (option.onClick) option.onClick()
			if (onClickMenu) onClickMenu()
			this.handleClose()
		}
	}

	handleSubOptionClose = () => {
		this.handleClose()
	}

	renderOption = (option, index) => {
		const { noselection, onClickMenu } = this.props
		const isSingle = option.subOptions.length <= 0
		const selectionStyle = (noselection || !isSingle) ? 'noselection' : ''
		return (
			<div key={index}>
				<div
					className={`v-menu-item ${selectionStyle} ${isSingle ? 'single' : ''} ${option.selected ? 'selected' : ''}`}
					onClick={(event) => this.handleClick(event, option, index)}>
					{isSingle && <img src={iconChecked} alt="" />}
					<div>{option.label}</div>
				</div>
				{option.subOptions &&
					<div className="v-menu-sub-container">
						<VMenu isOpen={true} options={option.subOptions} onClose={this.handleSubOptionClose} onClickMenu={onClickMenu} />
					</div>
				}
			</div>
		)
	}

	render() {
		const { title, options, left, right } = this.props
		const { isOpen } = this.state
		const style = isDesktop ? ((right) ? { right: 0 } : { left: '4px' }) : {}
		return (
			<>
				{isOpen &&
					<div id={this.menuId} ref={this.menuRef} className="v-menu" style={style}>
						<div className="v-menu-container">
							{title && <div className="v-menu-title">{title}</div>}
							{options &&
								options.map((option, index) => (
									this.renderOption(option, index)
								))
							}
						</div>
					</div>
				}
			</>
		)
	}
}