import { AppHeader, BlueButton, Navigation } from "components";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { routes } from "routes";
import './styles.scss'
import lockrScanSuccessTick from '../../../assets/images/lockrScan-success-check.svg'
import LottieControl from "./success-animation";
import { User, useAuth } from "core";
import moment from "moment-timezone";
import { Storage } from "utils";
import { Helmet } from "react-helmet";

export function LockrScanSuccessScene() {
    const location = useLocation();
    const navigate = useNavigate()
    const { currentLockrScanUser } = useAuth()
    React.useEffect(() => {

        if (!(location?.state?.enableSuccessScreen) && Storage.lockrScanSuccess != 'true') {

            navigate(routes.LockrScanDashboard)
        }
        else {
            User.updateLockrScanProfile({ frequency: 'weekly', allowRecurringEmail: true, deliveryTime: moment().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).format() })
            Storage.lockrScanSuccess = null
            gtag('event', 'lockrscansuccess', {
                'event_callback': () => {
                    // console.log('new lockrmail registered.')
                }
            })
        }
        window.history.replaceState({}, document.title)
    }, [])
    return (<div>
        <Helmet>
            <title>lockrScan | Unsubscribe from Spam</title>
        </Helmet>
        <Navigation />
        <div className="lockrScan-success">
            <div className="lockrScan-success-wrapper">
                <img src={lockrScanSuccessTick} className="lockrScan-success-tick" />
                <LottieControl />

            </div>
            <div className="lockrScan-success-success">Success!</div>

            <div className="lockrScan-success-description">You've authenticated your Gmail account with lockrScan.</div>
            <div className="lockrScan-success-refresh-box">
                <div className="lockrScan-success-refresh-bold-text">Your data is processing...</div>
                <div className="lockrScan-success-refresh-text">Please refresh this page to navigate to the lockrScan dashboad.</div>
                <BlueButton className="lockrScan-success-refresh-button" onClick={() => { window.location.reload() }}>Refresh Page</BlueButton>
            </div>
        </div>
    </div>)
}