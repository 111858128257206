import { User } from "core/models"

export class Canny {
	static ScriptId = 'lockr-canny-script'

	static remove() {
		const script = document.body.querySelector(`script#${Canny.ScriptId}`)
		if (script) {
			document.body.removeChild(script)
		}
	}

	/** @param {User} currentUser */
	static add(currentUser) {
		Canny.remove()

		if (!currentUser) return

		const script = document.createElement('script')
		// script.async = true
		script.id = Canny.ScriptId
		const memberSince = currentUser.createdAt


		script.innerHTML = `Canny('identify', { appID: '5fce9dc8e01c0f394efc7c46', user: { email: '${currentUser.email}', name: '${currentUser.name}', id: '${currentUser.id}', created: '${currentUser.createdAt}' }, });`
		document.body.appendChild(script)
	}
}