import React from 'react'

import './styles.scss'
import iconArrowPrevGray from 'assets/images/arrow_back_gray.svg'
import iconArrowNextGray from 'assets/images/arrow_next_gray.svg'

import {
	Selector, SelectorOption
} from '../'

import { range } from 'utils'

class Option {
	className
	label
	onClick = () => { }
	constructor({ className, label, onClick }) {
		this.className = className || ''
		this.label = label
		this.onClick = onClick || this.onClick
	}
}

export function Pagination({
	className = '',
	totalSize = 100,
	pageIndex = 1,
	pageSize = 0,
	onPageIndex = (value) => { },
	onPageSize = (value) => { }
}) {
	const pageSizes = [5, 10, 25, 50, 100]
	const [pageCount, setPageCount] = React.useState(0)
	React.useEffect(() => {
		setPageCount(Math.ceil(parseFloat(totalSize) / parseFloat(pageSize)))
	}, [totalSize, pageSize])

	function pageIndexes() {
		if (totalSize === 0) return []

		let results = [new Option({ className: pageIndex === 0 && "active", label: "1", onClick: () => onPageIndex(0) })]

		if (pageCount <= 5) {
			const options = range(1, pageCount - 1, 1).map(e => new Option({
				className: pageIndex === e && 'active', label: `${e + 1}`, onClick: pageIndex === e ? null : () => onPageIndex(e)
			}))
			results = [...results, ...options]
		} else if (pageIndex > 1 && pageIndex < pageCount - 2) {
			results.push(new Option({ label: "\u2219\u2219\u2219" }))
			results.push(new Option({ className: 'active', label: `${pageIndex + 1}` }))
			results.push(new Option({ label: "\u2219\u2219\u2219" }))
			results.push(new Option({ label: `${pageCount}`, onClick: () => onPageIndex(pageCount - 1) }))
		} else {
			results.push(new Option({ className: pageIndex === 1 && "active", label: "2", onClick: () => onPageIndex(1) }))
			results.push(new Option({ label: "\u2219\u2219\u2219" }))
			results.push(new Option({ className: pageIndex === (pageCount - 2) && "active", label: `${pageCount - 1}`, onClick: () => onPageIndex(pageCount - 2) }))
			results.push(new Option({ className: pageIndex === (pageCount - 1) && "active", label: `${pageCount}`, onClick: () => onPageIndex(pageCount - 1) }))
		}

		return results
	}

	function handlePrev() {
		if (pageIndex < 1) return
		onPageIndex(pageIndex - 1)
	}

	function handleNext() {
		if (pageIndex >= pageCount - 1) return
		onPageIndex(pageIndex + 1)
	}

	return (
		<div className={`pagination ${className}`}>
			<Selector
				options={pageSizes.map(e => new SelectorOption({ label: `${e} per page`, value: e }))}
				value={pageSize}
				onChange={value => onPageSize(value)}
			/>
			<div className="page-index-list">
				<button className="page-index-item" onClick={() => handlePrev()}>
					<img src={iconArrowPrevGray} />
				</button>
				{pageIndexes().map((option, index) =>
					<button
						key={index}
						className={`page-index-item ${option.className}`}
						onClick={() => option.onClick()}>
						<span>{option.label}</span>
					</button>
				)}
				<button className="page-index-item" onClick={() => handleNext()}>
					<img src={iconArrowNextGray} />
				</button>
			</div>
		</div>
	)
}
