import React from 'react'

import './styles.scss'

import { firstLetter } from '../../utils'

export function Icon({
	className = "",
	type = "default", // normal, custom
	style = {},
	src,
	hasWhite = false,
	placeholder
}) {
	const imgRef = React.useRef()

	const [isLoaded, setLoaded] = React.useState(false)
	const [error, setError] = React.useState(null)
	React.useEffect(() => {
		setError(src ? null : 'no image')
	}, [src])

	function handleLoad(event) {
		imgRef.current.width = imgRef.current.naturalWidth
		imgRef.current.height = imgRef.current.naturalHeight

		setLoaded(true)
	}

	return (
		<div className={`${className} icon ${error && 'invalid'} ${hasWhite && 'has-white'} ${isLoaded && 'loaded'}`} style={style}>
			<img ref={imgRef} src={src} onLoad={handleLoad} onError={(error) => setError(error)} alt="" loading='lazy' />
			<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" preserveAspectRatio="xMinYMid meet">
				<text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fontSize={type === 'default' ? 16 : 24} fill="white">{firstLetter(placeholder)}</text>
			</svg>
		</div>
	)
}