import React from 'react'

import './styles.scss'

import { DigestListItem } from './list-item'

import {
	useDigests,
	Digest,
} from 'core'
import { ArrayHelper } from 'utils'

const Type = Object.freeze({
	All: 'all',
	Archived: 'archived'
})

export function DigestList({ }) {
	const { myDigests, archivedDigests, digestSelect } = useDigests()

	const [selectedType, setSelectedType] = React.useState(Type.All)

	function handleType(type = Type.All) {
		setSelectedType(type)

		if (type === Type.All) {
			if (ArrayHelper.isValid(myDigests))
				digestSelect(myDigests[0])
		}

		if (type === Type.All) {
			if (ArrayHelper.isValid(archivedDigests))
				digestSelect(archivedDigests[0])
		}
	}

	return (
		<React.Fragment>
			<div className="my-digests-list">
				<div className="my-digest-type">
					<div onClick={() => handleType()}>
						All ({myDigests.length})
					</div>
					{ArrayHelper.isValid(myDigests) &&
						<div className="digest-list">
							{myDigests.map((digest, index) =>
								<DigestListItem
									key={index}
									digest={digest} />
							)}
						</div>
					}
				</div>
				<div className="my-digest-type">
					<div onClick={() => handleType()}>
						Archived ({archivedDigests.length})
					</div>
					{ArrayHelper.isValid(archivedDigests) &&
						<div className="digest-list">
							{archivedDigests.map((digest, index) =>
								<DigestListItem
									key={index}
									digest={digest} />
							)}
						</div>
					}

				</div>
			</div>
		</React.Fragment>
	)
}