import React from 'react'

import { useAlert } from 'core/providers'
import { AdminBlockedDomain } from 'core/models'

const AdminBlockedDomainsContext = React.createContext({})

export function AdminBlockedDomainsProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [blockedDomains, setBlockedDomains] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)

	async function searchBlockedDomains(params) {
		setLoading(true)
		const { error, totalCount, results } = await AdminBlockedDomain.search(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(totalCount)
		setBlockedDomains(results ?? [])
	}

	async function deleteBlockedDomains(domains = []) {
		setLoading(true)
		const { error } = await AdminBlockedDomain.destroy({ domains })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setBlockedDomains(blockedDomains.filter(x => !domains.includes(x.domain)))
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		blockedDomains,
		totalCount,

		searchBlockedDomains,
		deleteBlockedDomains,
	}), [isLoading, blockedDomains, totalCount])

	return (
		<AdminBlockedDomainsContext.Provider value={memoedValue}>
			{children}
		</AdminBlockedDomainsContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, blockedDomains: Array<AdminBlockedDomain>, totalCount: number, searchBlockedDomains: Function, deleteBlockedDomains: Function}} UseAdminBlockedDomains
 * @returns {UseAdminBlockedDomains}
 */
export function useAdminBlockedDomains() {
	return React.useContext(AdminBlockedDomainsContext)
}