import React from 'react'

import './styles.scss'
import iconArrow from '../../assets/images/arrow_down.svg'
import iconFilter from '../../assets/images/filter-badge-8B8B8B.svg'

import { SelectDropDown } from './drop-down'

import { randomUUID, ArrayHelper, ObjectHelper } from '../../utils'

export const SelectType = Object.freeze({
	Vertical: "vertical",
	Horizontal: "horizontal"
})

export const Select = React.forwardRef(({
	id = randomUUID(),
	className = "",
	type = SelectType.Vertical, // vertical|horizontal
	minimized = false,
	responsive = { breakpoint: 992 },
	keepLabel = false,
	label = "",
	mobileTitle,
	value,
	onChange = (value) => { },
	children,
	isTransparent,
	filterApplied,
	newCheckbox = false,
	inputPlaceholder = '',
	supportSearch = false,
	filterIcon = iconFilter,
	test = false,
	onSearchValueChange = (val) => { },
	onReset,
	disabled = false,
	mobileHeader,
}, ref) => {
	function classList() {
		if (disabled) {
			className = className + ' disable'
		}
		const results = [className, 'select', 'select-dropdown-parent']
		if (minimized) results.push('minimized')
		if (filterApplied) results.push('filter-applied')
		return results.join(' ')
	}

	function calcLabel() {
		if (keepLabel || !ArrayHelper.isValid(children)) return label

		for (let child of children || []) {
			const { value: childValue, label: childLabel, children: childChildren } = child.props
			if (value !== undefined && childValue === value)
				return (childLabel || childChildren)
		}

		return label
	}

	return (
		<div
			ref={ref}
			id={id}
			style={isTransparent ? { background: 'transparent', borderRadius: 2, border: '1px solid #8B8B8B' } : {}}
			className={classList()}>

			{!!inputPlaceholder ? <label>{inputPlaceholder}</label> : <></>}
			{typeof calcLabel() === "string" ? <label>{calcLabel()}</label> :
				<div className="label">{calcLabel()}</div>
			}
			<img className="filter-icon" src={iconFilter} alt="" />
			<img className="arrow" src={iconArrow} alt="" />

			{!disabled && <SelectDropDown
				test={test}
				type={type}
				value={value}
				supportSearch={supportSearch}
				onChange={(value) => onChange(value)}
				responsive={responsive}
				mobileTitle={mobileTitle}
				onSearchValueChange={onSearchValueChange}
				newCheckbox={newCheckbox}
				onReset={onReset}
				mobileHeader={mobileHeader}>
				{children && children}
			</SelectDropDown>}
		</div>
	)
})

export * from './drop-down'
export * from './group'
export * from './option'