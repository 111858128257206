import { BaseModel } from 'core/models/base'
import { Api, ConnectedEmail, DomainSetting, Segment } from '../..'
import { firstLetter, ObjectHelper, DateHelper, ArrayHelper, Storage } from 'utils'
import { errorCodes, errorMessages } from 'constants/error'

export class Sender extends BaseModel {
	static Status = Object.freeze({
		'All': { key: 'all', label: 'All' },
		'Blocked': { key: 'blocked', label: 'Blocked only' },
		'Allowed': { key: 'allowed', label: 'Allowed only' },
		'Filtered': { key: 'filtered', label: 'Filtered' },
		'unclassified': { key: 'unclassified', label: 'Unclassified', }
	})

	userId
	connectedEmailId
	connectedEmail
	name
	email
	domain
	domainSettings
	blocked = false
	avatar
	hasWhite
	filterId
	digestId
	digests
	digestFilterId
	senderGroupId
	mailCount
	recentAt
	domainGroupName

	/** @returns {Sender} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.connectedEmail = ConnectedEmail.fromJSON(json.connectedEmail)
			result.domainSettings = DomainSetting.fromJSON(json.domainSettings)
			result.mailCount = parseInt(json?.mailCount || 0)
			result.recentAt = DateHelper.parse(json.recentAt)
			// console.log(result.recentAt)
		}
		return result
	}

	static async getStatistics(params) {
		try {
			const { data, error } = await Api.get('/get-statistics', params)
			return { ...data, error: error ? errorMessages.NETWORK_ERROR : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async add(email) {
		try {
			const { error, data } = await Api.post('/senders', { email })
			return {
				error: error && errorMessages.CONTACT_US,
				result: Sender.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getSenders(params = null) { // Update getCountByDate, whenever params changed
		const { status } = params
		let filteredParams = params
		filteredParams.timezone = DateHelper.getUserTimeZone()
		if (ArrayHelper.contains([Sender.Status.All.key, Sender.Status.Blocked.key, Sender.Status.Allowed.key], status)) {
			if (status === Sender.Status.All.key) { }
			else if (status === Sender.Status.Blocked.key) filteredParams = { ...filteredParams, blocked: true }
			else if (status === Sender.Status.Allowed.key) filteredParams = { ...filteredParams, blocked: false }
			delete filteredParams.status
		}


		try {
			const { data, error } = await Api.get('/senders', filteredParams, true, false, true)
			if (error) { return { error: error ? errorMessages.NETWORK_ERROR : null } }
			return {
				count: data.count,
				groupCount: data.groupCount,
				results: Sender.fromJSONArray(data.rows),
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getSender(id) {
		const defaultError = 'Sorry, there are some troubles to get sender information.'
		try {
			const { data, error, errorCode } = await Api.get(`/senders/${id}`)
			return {
				error: error ? defaultError : null,
				errorCode,
				result: data && Sender.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async batchGetSender(ids = []) {
		const results = []
		for (let id of ids) {
			const { error, result } = await Sender.getSender(id)
			if (error) return { error: errorMessages.CONTACT_US }

			results.push(result)
		}
		return { results }
	}

	static async getCountByDate(params, startDate, endDate) {
		const { status } = params
		let filteredParams = params
		if (ArrayHelper.contains([Sender.Status.All.key, Sender.Status.Blocked.key, Sender.Status.Allowed.key], status)) {
			if (status === Sender.Status.All.key) { }
			else if (status === Sender.Status.Blocked.key) filteredParams = { ...filteredParams, blocked: true }
			else if (status === Sender.Status.Allowed.key) filteredParams = { ...filteredParams, blocked: false }
			delete filteredParams.status
		}
		filteredParams = { ...filteredParams, startDate: startDate && DateHelper.format(startDate, 'YYYY-MM-DD') }
		if (endDate) {
			filteredParams = { ...filteredParams, endDate: DateHelper.format(endDate, 'YYYY-MM-DD') }
		}

		try {
			const { data, error } = await Api.get('/senders', filteredParams)
			if (error) { return {} }
			return {
				error: error ? errorMessages.NETWORK_ERROR : null,
				result: data ? data.count : 0,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async block(senders, block = true) {
		try {
			const filteredSenders = senders.filter(sender => !sender.isEmailBlocked)
			if (!ArrayHelper.isValid(senders)) return {}

			// if (!ArrayHelper.isValid(filteredSenders)) {
			// 	return { error: senders.length <= 1 ? errorMessages.DELETE_FILTER_FOR_ALLOW_BLOCK : errorMessages.BATCH_DELETE_FILTER_AND_DS_FOR_ALLOW_BLOCK }
			// }

			const senderIds = filteredSenders.map(e => e.id)
			const { error, errorCode, success } = await Api.post('/senders/block-senders', { senderIds, blocked: block })
			if (success) {
				console.log("SUCCESS");
				if (block == false)
					Segment.track(Segment.Event.userAllowedSender, Storage.userId, { senderIds });
				else
					Segment.track(Segment.Event.userBlockedSender, Storage.userId, { senderIds });

			}
			if (errorCode === 422) return { error: senders.length <= 1 ? errorMessages.DELETE_FILTER_FOR_ALLOW_BLOCK : errorMessages.BATCH_DELETE_FILTER_AND_DS_FOR_ALLOW_BLOCK }
			if (filteredSenders.length !== senders.length) return { error: errorMessages.BATCH_DELETE_FILTER_AND_DS_FOR_ALLOW_BLOCK, results: filteredSenders }
			return { error: error ? errorMessages.NETWORK_ERROR : null, results: filteredSenders }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async setConnectedEmail(senders, connectedEmailId) {
		const defaultError = 'We are having trouble reaching the server right now... We’re working on it.... Please refresh your page!'
		try {
			const response = await Api.post('/senders/set-connected-email', { senderIds: senders.map(e => e.id), connectedEmailId: connectedEmailId })
			if (response.success) { Segment.track(Segment.Event.changeDestinationEmailForSender, Storage.userId, { senderIds: senders.map(e => e.id), connectedEmailId: connectedEmailId }) }
			if (response.error) { return { error: defaultError } }
			return response
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async addToGroup(senderGroupId, senders) {
		const params = {
			senderGroupId,
			senderIds: senders.map((sender) => sender instanceof Sender ? sender.id : sender)
		}

		try {
			const { error } = await Api.post('/senders/add-to-group', params)
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async removeFromGroup(senderId) {
		try {
			const { error, success } = await Api.post('/senders/remove-from-group', { id: senderId })
			if (success) {
				Segment.track(Segment.Event.removingSenderFromGroup, Storage.userId, { id: senderId })
			}
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static fake() {
		return Sender.fromJSON({
			id: '0',
			userId: '0',
			connectedEmailId: '0',
			name: 'West Elm',
			email: 'info@loc.kr'
		})
	}

	static filter(senders, start, end = null, excludeIds = [], timezone = DateHelper.getUserTimeZone()) {
		// console.log("filter", start, end)
		if (!end) {
			return senders.filter(e => DateHelper.isSameDay(e.recentAt, start, timezone) && !excludeIds.some(x => e.id === x))
		}
		return senders.filter((e) => {
			if (e.recentAt < start) return false
			if (end && e.recentAt > end) return false
			if (excludeIds.some(x => e.id === x)) return false

			return true
		})
	}

	/**
	 * @param {Array<Sender>} senders
	 * @returns {boolean}
	 */
	static hasConflictedSenderGroup(senders = []) {
		if (!ArrayHelper.isValid(senders)) return false

		const senderGroupId = senders[0].senderGroupId
		return senders.some(x => x.senderGroupId !== senderGroupId)
	}

	get isVerified() { return this?.connectedEmail?.isVerified }
	get isBlocked() { return this.blocked === true && !this.hasFilter && !this.isEmailBlocked }
	get isAllowed() { return this.blocked === false && !this.hasFilter && !this.isEmailBlocked }
	get hasFilter() { return Boolean(this.filterId) }
	get hasDigest() { return Boolean(this.digestId) }
	get hasDigests() { return Boolean(this.digests && this.digests.length) }
	get hasDigestFilter() { return Boolean(this.digestFilterId) }
	get firstLetter() { return firstLetter(this.name) }
	get hasGroup() { return ObjectHelper.isValid(this.senderGroupId) }

	get hasDomainSettings() { return ObjectHelper.isValid(this.domainSettings) }
	get hasDomainBlocked() { return this.hasDomainSettings && (this.domainSettings.isBlocked) }
	get isEmailBlocked() { return this.hasDomainSettings && (this.domainSettings.isEmailBlocked) }

	async block(block = true) {
		return Sender.block([this], block)
	}

	async setConnectedEmail(connectedEmailId) {
		return Sender.setConnectedEmail([this], connectedEmailId)
	}

	async setSenderGroup(senderGroupId) {
		if (this.senderGroupId === senderGroupId) return await Sender.removeFromGroup(this.id)
		return await Sender.addToGroup(senderGroupId, [this])
	}
}