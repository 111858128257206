import React from 'react'
import { useSearchParams } from 'react-router-dom'

import './styles.scss'

import { BlueButton } from 'components'
import { GroupList } from './group-list'
import { GroupDetails } from './group-details'
import { AccountNewGroupAlert } from './new-group-alert'

import { useSenderGroup } from 'core'
import {
	isSmallMobile,
	ArrayHelper
} from 'utils'
import { parseSearchParams } from 'routes'

export function AccountGroups({
	className = '',
}) {
	const [searchParams] = useSearchParams()

	const { senderGroups, loadSenderGroups } = useSenderGroup()

	React.useEffect(() => { loadSenderGroups(); window.scrollTo(0, 0); }, [])
	React.useEffect(() => { handleGroupsChanged() }, [senderGroups])

	const [selectedGroupId, setSelectedGroupId] = React.useState(null)

	const [isNewGroupOpen, setNewGroupOpen] = React.useState(false)
	React.useEffect(() => {
		const { requireAdd, senderGroupId } = parseSearchParams(searchParams)
		setNewGroupOpen(requireAdd)
		setSelectedGroupId(senderGroupId)
	}, [searchParams])

	function handleGroupsChanged() {
		if (!ArrayHelper.isValid(senderGroups)) {
			setSelectedGroupId(null)
			return
		}

		if (!isSmallMobile && !selectedGroupId) {
			setSelectedGroupId(senderGroups[0].id)
			return
		}

		if (selectedGroupId) {
			if (!senderGroups.some(e => e.id === selectedGroupId)) {
				if (isSmallMobile) setSelectedGroupId(null)
				else setSelectedGroupId(senderGroups[0].id)
			}
		}
	}

	return (
		<>
			<div className={`account-groups ${className}`}>
				<div className="title">
					Use groups to help organize your Senders list in your Dashboard.<br />
					Need help? Check out our Help Center for step-by-step guides.
				</div>
				<div className="content-container">
					<GroupList
						selectedGroupId={selectedGroupId}
						onSelectGroup={(id) => setSelectedGroupId(id)} />
					{selectedGroupId &&
						<GroupDetails
							groupId={selectedGroupId}
							onClose={() => setSelectedGroupId(null)} />
					}
				</div>
				<BlueButton
					className="new-group-desktop"
					onClick={() => setNewGroupOpen(true)}>
					New Group
				</BlueButton>
				<div className="new-group-mobile">
					<BlueButton onClick={() => setNewGroupOpen(true)}>New Group</BlueButton>
				</div>
			</div>
			{isNewGroupOpen &&
				<AccountNewGroupAlert
					onClose={(groupId) => {
						if (groupId) {
							setSelectedGroupId(groupId)
							loadSenderGroups()
						}
						setNewGroupOpen(false)
					}}
				/>
			}
		</>
	)
}