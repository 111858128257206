import React, { useState, useEffect } from 'react'

import './styles.scss'

import {
    Loader,
    SearchField,
    Selector, SelectorOption,
    HMenuDatePicker,
    Pagination,
    BlueButton,
} from 'components'
import { DomainListItem } from './list-item'

import { useAuth, AdminDomain, Api } from 'core'
import { DateHelper } from 'utils'
import fetchImage from '../../../../assets/images/fetch.svg'
import { LockrScanDomainListItem } from './lockrScan-list-item'

export function LockrScanAdminDomainsEl({
    onClick = (senderDomain) => { }
}) {
    const { currentUser } = useAuth()

    const [isLoading, setLoading] = useState(false)

    const [searchText, setSearchText] = useState('')
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [startDate, setStartDate] = React.useState(DateHelper.addDay(new Date(), -6))
    const [endDate, setEndDate] = useState(new Date())
    const [sortBy, setSortBy] = useState("account")
    const [fetchClicked, setFetchClicked] = React.useState(false)

    const [totalSize, setTotalSize] = useState(0)
    const [senderDomains, setSenderDomains] = useState([])

    useEffect(() => {
        let timer;
        if (fetchClicked) { timer = setTimeout(() => loadDomains(), 500); }
        return (() => { clearTimeout(timer) })

    }, [searchText, pageIndex, pageSize, sortBy])
    useEffect(() => {
        setPageIndex(0);
    }, [pageSize, searchText, sortBy])

    const onFetch = () => {
        setFetchClicked(true)
        loadDomains();
    }

    async function loadDomains() {
        if (!currentUser) return

        setLoading(true)

        // const { results, count, error } = await AdminDomain.load(searchText, startDate, endDate, pageIndex, pageSize, sortBy)
        const result = await Api.get("/lockrScan/admin/sender-domains", { searchText, startDate, endDate, pageIndex, pageSize, sortBy })
        setSenderDomains(result?.data?.rows);
        setTotalSize(result?.data?.count);

        setLoading(false)
    }

    return (
        <div className="admin-group">
            <div className="admin-group-header">
                <div className="admin-group-title">Domains</div>
                <div className="admin-traction-option-container">
                    <div>
                        <HMenuDatePicker
                            position="first"
                            date={startDate}
                            onSelect={date => setStartDate(date)}
                        />
                        <div className="to">to</div>
                        <HMenuDatePicker
                            position="first"
                            date={endDate}
                            onSelect={date => setEndDate(date)}
                        />
                        <BlueButton className='fetch-button' onClick={() => { onFetch(); }}>Fetch <img src={fetchImage}></img></BlueButton>
                    </div>
                </div>
            </div>
            <div className="admin-sender-domains">
                <div className="header">
                    <div>
                        <SearchField
                            value={searchText}
                            onChange={setSearchText}
                        />
                        <Selector
                            defaultLabel="View Options"
                            options={[
                                new SelectorOption({ label: 'Most Accounts', value: 'account' }),
                                new SelectorOption({ label: 'Most Emails', value: 'email' }),
                                new SelectorOption({ label: 'Most Unsubscribed', value: 'unSubscribe' }),
                                new SelectorOption({ label: 'Most lockrArchived', value: 'lockrArchive' }),
                            ]}
                            value={sortBy}
                            onChange={value => setSortBy(value)}
                        />
                    </div>
                </div>
                <div className="sender-domains-list">
                    {isLoading ? <Loader /> :
                        <>
                            <LockrScanDomainListItem isHeader />
                            {senderDomains.map((senderDomain, index) =>
                                <LockrScanDomainListItem
                                    key={index}
                                    value={senderDomain}
                                    onClick={() => { }}
                                />
                            )}
                        </>
                    }
                </div>
                <Pagination
                    className="admin-sender-domains-pagination"
                    totalSize={totalSize}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    onPageSize={(value) => setPageSize(value)}
                    onPageIndex={value => setPageIndex(value)}
                />
            </div>
        </div>
    )
}