import { Alert, AlertButton, AlertButtonContainer, AlertPage, AlertTitle, BlueButton, FilterChipTab, SearchField, Select, SelectGroup, SelectOption } from 'components';
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Slider } from 'antd';
import resetGrey from '../../../assets/images/reset-grey.svg'


export function SearchFilterTab({
    searchtText = '',
    source = '',
    category = '',
    minDiscount = 0,
    maxDiscount = 100,
    setSearchtext = () => { },
    setType = () => { },
    setCategory = () => { },
    setMinDiscount = () => { },
    setMaxDiscount = () => { },
    domainList = [],
    selectedDomain = [],
    setSelectedDomain = () => { },
    onRemoveFilterItem = () => { },
    selectDomains = () => { },
    selectSource = () => { },
    selectedFilters = [],
    setSelectedFilters = () => { },
    sort = '',
    setSort = () => { },
    categories = [],
    selectedCategories = [],
    setSelectedCategories = () => { },
    selectCategory = () => { }
}) {
    const [filterAlertVisible, setAlertVisible] = useState(false);
    const [searchDomains, setSearchDomains] = useState([]);
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchDomainText, setSearchDomainText] = useState('');
    const [searchCategoryText, setSearchCategoryText] = useState('');

    const ref = useRef({ domainList: [], categories: [] });

    useEffect(() => {
        ref.current.domainList = domainList;
        ref.current.categories = categories;
        if (categories && categories.length > 0) {
            setSearchCategories(categories);
        }
        if (domainList && domainList.length > 0) {
            setSearchDomains(domainList)
        }
    }, [domainList, categories])


    return (
        <>
            <div className='search-filter-tab-desktop-upper-wrapper' style={{ maxWidth: 1440, marginLeft: 'auto', marginRight: 'auto', }}>
                <div className='search-filter-tab-desktop'>
                    <div className='search-filter-tab-desktop-wrapper'>
                        <div>
                            <div style={{ fontSize: 14 }}>Your filters <span className='filter-reset' onClick={() => { setSelectedFilters([]); setType(''); setSelectedDomain([]); setSelectedCategories([]); }}><img src={resetGrey} /> Reset</span></div>
                            <div style={{ marginTop: 10 }}>
                                <FilterChipTab onClose={onRemoveFilterItem} selectedFilters={selectedFilters} />
                            </div>
                        </div>

                        <div className='options-wrapper'>

                            <div>
                                <div style={{ fontSize: 14 }}>Domain</div>
                                <div style={{ marginTop: 10 }}>
                                    <Select className={'select-increase-width'} inputPlaceholder={'Select'} supportSearch={true} onSearchValueChange={val => {
                                        setSearchDomainText(val)
                                        let tempSearchDomains = []
                                        domainList.forEach((domain) => {
                                            if (domain?.senderName?.toLowerCase().includes(val.toLowerCase())) {
                                                tempSearchDomains.push(domain)
                                            }
                                        })
                                        if (domainList.length > 0) {
                                            setSearchDomains(tempSearchDomains);
                                        }
                                        if (tempSearchDomains.length == 0 && val == '') {
                                            ref.current.domainList.length > 0 && setSearchDomains(ref.current.domainList)
                                        }
                                    }}>
                                        <SelectGroup>
                                            {!searchDomainText && <SelectOption newCheckbox={true} selected={selectedDomain == "" || selectedDomain == undefined} onClick={() => { setSelectedDomain([]); selectDomains('', true) }}>All</SelectOption>}
                                            {searchDomains.map((domain) => (
                                                <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedDomain.find((d) => d.senderDomain == domain.senderDomain)} onClick={() => {
                                                    selectDomains(domain)
                                                }}>{domain?.senderName}</SelectOption>
                                            ))}

                                            {(searchDomains.length == 0 && searchDomainText) && <div style={{
                                                color: '#C4C5CD',
                                                fontSize: 12,
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                No results found</div>}
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 14 }}>Category</div>
                                <div style={{ marginTop: 10 }}>
                                    <Select className={'select-increase-width'} mobileHeader={"Category"} supportSearch={true} onSearchValueChange={(val) => {
                                        let temp = [...categories]
                                        let temp1 = []
                                        setSearchCategoryText(val)
                                        temp = temp.map(t => {
                                            if (t.toLowerCase().includes(val.toLowerCase())) {
                                                temp1.push(t)
                                            }
                                        })
                                        if (categories.length > 0) {
                                            setSearchCategories(temp1)
                                        }
                                        if (temp1.length == 0 && val == '') {
                                            ref.current.categories.length > 0 && setSearchCategories(ref.current.categories)
                                        }
                                    }} inputPlaceholder={'Select'}>
                                        <SelectGroup>

                                            {!searchCategoryText && <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedCategories == ""} onClick={() => {
                                                selectCategory("", true);
                                                setSelectedCategories([]);
                                            }}>All</SelectOption>}
                                            {searchCategories.map((category) => (
                                                <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedCategories.includes(category)} onClick={() => {
                                                    selectCategory(category);
                                                }}>{category}</SelectOption>
                                            ))}
                                            {searchCategories.length == 0 && searchCategoryText && <div style={{
                                                color: '#C4C5CD',
                                                fontSize: 12,
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                No results found</div>}
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 14 }}>Source</div>
                                <div style={{ marginTop: 10 }}>
                                    <Select inputPlaceholder={'Select'}>
                                        <SelectGroup>
                                            <SelectOption newCheckbox={true} selected={source == ''} onClick={() => { setType(''); selectSource('', true) }}>All</SelectOption>
                                            <SelectOption newCheckbox={true} selected={source == 'lockrMail'} onClick={() => { selectSource('lockrMail') }}>lockrMail</SelectOption>
                                            <SelectOption newCheckbox={true} selected={source == 'lockrScan'} onClick={() => selectSource('lockrScan')}>lockrScan</SelectOption>
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 14 }}>Amount</div>
                                <div style={{ marginTop: 12, marginLeft: 10, marginRight: 16 }}>
                                    <Slider dots={false} handleStyle={[{}, {}]} railStyle={{ backgroundColor: '#353738', height: 10, borderRadius: 100 }} trackStyle={[{ height: 10, borderRadius: 100, backgroundColor: '#61CEF7' }]} onChange={(value) => {
                                        setMinDiscount(value[0]);
                                        setMaxDiscount(value[1])
                                    }} value={[minDiscount, maxDiscount]} marks={{ 0: `${minDiscount}%`, 100: `${maxDiscount}%` }} range defaultValue={[0, 100]} style={{ width: 172 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MOBILE UI */}
            <div className='search-filter-tab-mobile'>
                <div onClick={() => { setAlertVisible(true) }} className='filter-discounts-mobile-button'>Filter Discounts</div>
                {filterAlertVisible && <Alert onCancel={() => { setAlertVisible(false) }}>
                    <AlertPage>
                        <AlertTitle>
                            Filter Discounts
                        </AlertTitle>

                        <div className='options-wrapper'>
                            <div style={{ paddingBottom: 16 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ fontSize: 14 }}>Your filters</div>
                                    <div className='filter-reset' onClick={() => { setSelectedFilters([]); setType(''); setSelectedDomain([]); setSelectedCategories([]) }}>
                                        <img src={resetGrey} />
                                        Reset</div>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <FilterChipTab onClose={onRemoveFilterItem} selectedFilters={selectedFilters} />
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 14 }}>Sort by</div>
                                <div style={{ marginTop: 10 }}>
                                    <Select mobileHeader={"Sort by"} inputPlaceholder={'Select'}>
                                        <SelectGroup>
                                            <SelectOption newCheckbox={true} selected={sort == 'discount'} onClick={() => { setSort('discount') }}>Discount</SelectOption>
                                            <SelectOption newCheckbox={true} selected={sort == 'recent'} onClick={() => { setSort('recent') }}>Most Recent</SelectOption>
                                        </SelectGroup>
                                    </Select>

                                </div>
                            </div>
                            <div>
                                <div>Domain</div>
                                <div style={{ marginTop: 16 }}>
                                    <Select mobileHeader={'Domain'} inputPlaceholder={'Select'} supportSearch={true} onSearchValueChange={val => {
                                        setSearchDomainText(val)
                                        let tempSearchDomains = []
                                        domainList.forEach((domain) => {
                                            if (domain?.senderName?.toLowerCase().includes(val.toLowerCase())) {
                                                tempSearchDomains.push(domain)
                                            }
                                        })
                                        if (tempSearchDomains.length > 0) {
                                            setSearchDomains(tempSearchDomains);
                                        }
                                        if (tempSearchDomains.length == 0 && searchDomainText == '') {
                                            ref.current.domainList.length > 0 && setSearchDomains(ref.current.domainList)
                                        }
                                    }}>
                                        <SelectGroup>
                                            {!searchDomainText && <SelectOption newCheckbox={true} selected={selectedDomain == "" || selectedDomain == undefined} onClick={() => { setSelectedDomain([]); selectDomains('', true) }}>All</SelectOption>}
                                            {searchDomains.map((domain) => (
                                                <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedDomain.find((d) => d.senderName == domain.senderName)} onClick={() => {
                                                    selectDomains(domain)
                                                }}>{domain?.senderName}</SelectOption>
                                            ))}
                                            {(searchDomains.length == 0 && searchDomainText) && <div style={{
                                                color: '#C4C5CD',
                                                fontSize: 12,
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                No results found</div>}
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div >Category</div>
                                <div style={{ marginTop: 16 }}>
                                    <Select mobileHeader={"Category"} supportSearch={true} onSearchValueChange={(val) => {
                                        let temp = [...categories]
                                        let temp1 = []
                                        setSearchCategoryText(val)

                                        temp = temp.map(t => {

                                            if (t.toLowerCase().includes(val.toLowerCase())) {
                                                temp1.push(t)
                                            }
                                        })
                                        if (temp1.length > 0) {
                                            setSearchCategories(temp1)
                                        }
                                        if (temp1.length == 0 && val == '') {
                                            ref.current.categories.length > 0 && setSearchCategories(ref.current.categories)
                                        }
                                    }}
                                        inputPlaceholder={'Select'}>
                                        <SelectGroup>

                                            {!searchCategoryText && <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedCategories == ""} onClick={() => {
                                                selectCategory("", true);
                                                setSelectedCategories([]);
                                            }}>All</SelectOption>}
                                            {searchCategories.map((category) => (
                                                <SelectOption className={'capitalize'} newCheckbox={true} selected={selectedCategories.includes(category)} onClick={() => {
                                                    selectCategory(category)
                                                }}>{category}</SelectOption>

                                            ))}
                                            {searchCategories.length == 0 && searchCategoryText && <div style={{
                                                color: '#C4C5CD',
                                                fontSize: 12,
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                No results found</div>}
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div>Source</div>
                                <div style={{ marginTop: 16 }}>
                                    <Select mobileHeader={"Source"} inputPlaceholder={'Select'}>
                                        <SelectGroup>
                                            <SelectGroup>
                                                <SelectOption newCheckbox={true} selected={source == ''} onClick={() => { setType(''); selectSource('', true) }}>All</SelectOption>
                                                <SelectOption newCheckbox={true} selected={source == 'lockrMail'} onClick={() => { selectSource('lockrMail') }}>lockrMail</SelectOption>
                                                <SelectOption newCheckbox={true} selected={source == 'lockrScan'} onClick={() => selectSource('lockrScan')}>lockrScan</SelectOption>
                                            </SelectGroup>
                                        </SelectGroup>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div>Amount</div>
                                <div style={{ marginTop: 16 }}>
                                    <Slider dots={false} handleStyle={[{}, {}]} railStyle={{ backgroundColor: '#353738', height: 10, borderRadius: 100 }} trackStyle={[{ height: 10, borderRadius: 100, backgroundColor: '#61CEF7' }]} onChange={(value) => {
                                        setMinDiscount(value[0]);
                                        setMaxDiscount(value[1])
                                    }} value={[minDiscount, maxDiscount]} marks={{ 0: `${minDiscount}%`, 100: `${maxDiscount}%` }} range defaultValue={[0, 100]} />
                                </div>
                            </div>

                        </div>
                        <AlertButtonContainer>
                            <AlertButton>Apply Filter</AlertButton>
                        </AlertButtonContainer>
                    </AlertPage>
                </Alert>}
            </div>
        </>
    )
}