import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'

import {
	SelectDropDown, SelectOption, Icon, Button,
	TextField,
} from 'components'

import { Sender } from 'core'
import { ArrayHelper, isEmail } from 'utils'

export function SenderInputField({
	className = '',
	disabled = false,
	params: additionalParams = {},
	allowAddEmail = false,
	senders = [],
	placeholder = null,
	checkDisableFn = (sender) => { return false },
	onChange = (senders) => { },
	onInputChanged = (value) => { },
	onAddEmail = (email) => { },
}) {
	const { width } = useWindowSize()

	const menuRef = React.useRef()
	const boxRef = React.useRef()
	const inputRef = React.useRef()

	const [searchTimer, setSearchTimer] = React.useState(null)
	const [searchText, setSearchText] = React.useState('')
	React.useEffect(() => {
		onInputChanged(searchText)

		if (searchTimer) clearTimeout(searchTimer)
		setSearchTimer(setTimeout(function () {
			search()
		}, 500))

		return () => clearTimeout(searchTimer)
	}, [searchText])
	const [searchResults, setSearchResults] = React.useState([])

	function calcDevice() { return width >= 768 ? 'desktop' : 'mobile' }

	async function search() {
		const params = { ...additionalParams, searchText }
		const { error, results = [] } = await Sender.getSenders(params)
		if (error) {
			console.log(error)
		}

		// const filteredResults = results.filter(e => !senders.some(e1 => e1.id === e.id))
		const filteredResults = ArrayHelper.sort(results || [], 'name')
		setSearchResults(filteredResults)

		if (filteredResults.length > 0) {
			if (menuRef.current) {
				menuRef.current.update()
				menuRef.current.open()
			}
			// if (inputRef.current) {
			// 	inputRef.current.blur()
			// }
		} else {
			if (menuRef.current) {
				menuRef.current.close()
			}
		}
	}

	function handleSearch(event) {
		const value = event.target.value
		setSearchText(value)
	}

	function handleFocus(event) {
		// search()
	}

	function handleBoxClicked(event) {
		inputRef.current?.focus()
	}

	function handleSenderClicked(sender) {
		if (checkDisableFn(sender)) return

		if (ArrayHelper.contains(senders, sender, 'id')) return

		const results = [...senders, sender]
		onChange(results)

		setSearchText('')
		if (inputRef.current) {
			setSearchText('')
			inputRef.current.focus()
		}
	}

	/**
	 * @param {MouseEvent} event
	 * @param {Sender} sender
	 */
	function handleSenderClear(event, sender) {
		event?.preventDefault()
		event?.stopPropagation()

		// Prevent focus container
		document.activeElement?.blur()

		if (disabled) return

		const results = senders.filter(e => e.id !== sender.id)
		onChange(results)
	}

	function handleAddEmail() {
		const sender = searchResults.find(x => x.email === searchText)
		if (sender) {
			handleSenderClicked(sender)
			return
		}

		if (allowAddEmail && isEmail(searchText) && !senders.some(x => x === searchText || x.email === searchText)) {
			onAddEmail(searchText)
		}

		setSearchText("")
	}

	return (
		<div className={`sender-input ${calcDevice()}`}>
			<div ref={boxRef} className={`sender-input-box ${className} ${allowAddEmail && 'allowed-add-email'}`} onClick={handleBoxClicked}>
				{senders.map((sender, index) =>
					<div
						key={index}
						className="sender"
						onClick={event => {
							handleSenderClear(event, sender)
						}}>
						<span>{sender.email}</span>
						{!disabled &&
							<Button
								className="sender-clear">
								<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<line y1="-0.5" x2="14.142" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#C4C4C4" />
									<line y1="-0.5" x2="14.142" y2="-0.5" transform="matrix(0.707114 0.7071 -0.707114 0.7071 1 1)" stroke="#C4C4C4" />
									<line y1="-0.5" x2="14.142" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 11)" stroke="#C4C4C4" />
									<line y1="-0.5" x2="14.142" y2="-0.5" transform="matrix(0.707114 -0.7071 0.707114 0.7071 1 11)" stroke="#C4C4C4" />
								</svg>
							</Button>
						}
					</div>
				)}
				{!disabled &&
					<TextField
						ref={inputRef}
						placeholder={placeholder}
						value={searchText}
						onChange={handleSearch}
						onFocus={handleFocus}
						onNext={() => handleAddEmail()} />
				}
				{(allowAddEmail && isEmail(searchText)) &&
					<button className="btn-add" onClick={() => handleAddEmail()}>Add</button>
				}
			</div>
			{ArrayHelper.isValid(searchResults) &&
				<SelectDropDown
					focusable={false}
					inputValue={calcDevice() === 'desktop' ? null : searchText}
					onInputChange={(value) => setSearchText(value)}>
					{searchResults.map((sender) => {
						const label = sender.name
						const disabled = checkDisableFn(sender) || senders.some(e => e.id === sender.id)
						return (
							<SelectOption
								key={sender.id}
								className="sender-item"
								disabled={disabled}
								onClick={() => handleSenderClicked(sender)}>
								<Icon placeholder={sender.name} src={sender.avatar} />
								<span className="label">{label}</span>
								{/* {sender.hasFilter &&
									<>
										<img src={iconFilterOn} alt="" />
										<div className="description">Each Sender can only be associated with a single filter.</div>
									</>
								} */}
							</SelectOption>
						)
					})}
				</SelectDropDown>
			}
		</div>
	)
}