import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './styles.scss'
import iconCircle1 from 'assets/images/numbers/circle-1.svg'
import iconCircle2 from 'assets/images/numbers/circle-2.svg'
import iconCircle3 from 'assets/images/numbers/circle-3.svg'
import iconLogo from 'assets/images/onboard_lock.svg'
import iconSpecialLinkFeatured from 'assets/images/special-link-featured.svg'
import iconBannerDots from 'assets/images/browser-extension/banner-dots.svg'
import iconArrowDown from 'assets/images/arrow/down-white-large.svg'
import iconHowItWorks from 'assets/images/browser-extension/how-it-works.svg'
import iconHowItWorksDots from 'assets/images/browser-extension/how-it-works-dots.svg'
import iconAutoFill from 'assets/images/browser-extension/auto-fill.svg'
import iconAutoFillDots from 'assets/images/browser-extension/auto-fill-dots.svg'

import {
	PublicHeader, AppFooter,
	TextField
} from 'components'

import { useAlert } from 'core'
import {
	isEmail, isValidText, isLockrMail
} from 'utils'
import { config } from 'constants/config'
import { redirectTo, routes } from 'routes'

const API_BASE_URL = process.env.REACT_APP_WORDPRESS_URL

const sampleHowItWorks = [
	{ featuredImage: iconCircle1, title: 'Log in to your lockrmail account', content: 'Use lockrMail on any website with the lockrMail extension or easily remember it for use on mobile or offline' },
	{ featuredImage: iconCircle2, title: 'Access your Senders & Digests', content: 'Have inbox fatigue? Concerned over the number of companies requiring your personal information? lockrMail is here to save you the hassle of email management and maintain your anonymity online.' },
	{ featuredImage: iconCircle3, title: 'Manage your account from any browser', content: 'Fast sign up on any site - with lockrMail browser extension, have your email auto-complete on any form. Plus, with partner websites you can be logged in automatically - avoiding any registration needs on publisher sites and some eCommerce platforms.' },
]

const sampleBottomBanner = {
	featuredImage: iconLogo,
	title: 'Saving you the hassle of email management.',
	content: 'Whether you have inbox fatigue and want to filter out emails from a specific sender, want to forward certain emails to your personal or professional email accounts, or want to create a digest of multiple emails into one.'
}

export function SpecialLinkEl({
	specialLink
}) {
	const navigate = useNavigate()

	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [howItWorks, setHowItWorks] = React.useState(sampleHowItWorks)
	const [bottomBanner, setBottomBanner] = React.useState(sampleBottomBanner)

	const [email, setEmail] = React.useState('')
	const [emailError, setEmailError] = React.useState('test')
	React.useEffect(() => { setEmailError(null) }, [email])

	const [firstName, setFirstName] = React.useState('')
	const [firstNameError, setFirstNameError] = React.useState('test')
	React.useEffect(() => { setFirstNameError(null) }, [firstName])

	const [lastName, setLastName] = React.useState('')
	const [lastNameError, setLastNameError] = React.useState('test')
	React.useEffect(() => { setLastNameError(null) }, [lastName])

	function checkValidation() {
		let result = true
		if (!isEmail(email)) {
			setEmailError('Email is invalid.')
			result = false
		}
		if (isLockrMail(email)) {
			setEmailError('Email should not be a lockrMail.')
			result = false
		}

		if (!isValidText(firstName, true)) {
			setFirstNameError('First name is invalid.')
			result = false
		}

		if (!isValidText(lastName, true)) {
			setFirstNameError('Last name is invalid.')
			result = false
		}

		return result
	}

	async function handleCreateAccount() {
		if (!checkValidation()) return

		navigate(redirectTo({
			route: routes.Register,
			searches: {
				email,
				firstName,
				lastName,
				permalink: specialLink.permalink
			}
		}))
	}

	return (
		<div id="parent" className="page-container special-link">
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<div className="top-bar"></div>
			<PublicHeader />
			<main className="banner-group">
				<section>
					<div className="screenshot-container">
						<img className="screenshot" src={iconSpecialLinkFeatured} alt="" />
					</div>
					<div className="register-container">
						<div className="register-title">Own the power to organize and protect your inbox.</div>
						<div className="register-content">
							<div className="register-subtitle">
								lockrMail is a free productivity tool that acts as a pre-filter for your inbox, providing you with a secure email address to use across the web, plus one-click block capability and advanced filtering functionality.
								<br /><br />
								For a limited time, we are allowing new users to <span>skip the waitlist</span> and receive instant access to their lockrMail account.
							</div>
							<div className="register-form">
								<TextField
									type="large"
									id="email"
									className="register-input"
									placeholder="Email address"
									autofocus
									value={email}
									onChange={event => setEmail(event.target.value.trim())}
								/>
								{emailError && <div className="register-error">{emailError}</div>}
								<TextField
									type="large"
									id="firstName"
									className="register-input"
									placeholder="First name"
									value={firstName}
									onChange={event => setFirstName(event.target.value)}
								/>
								{firstNameError && <div className="register-error">{firstNameError}</div>}
								<TextField
									type="large"
									id="lastName"
									className="register-input"
									placeholder="Last name"
									value={lastName}
									onChange={event => setLastName(event.target.value)}
								/>
								{lastNameError && <div className="register-error">{lastNameError}</div>}
								<button
									id="register-button"
									className="register-button"
									disabled={!(isEmail(email) && isValidText(firstName) && isValidText(lastName))}
									onClick={() => handleCreateAccount()}>
									Create account
								</button>
							</div>
						</div>
						<a className="see-how-it-works" href="#how-it-works">
							<span>See how it works</span>
							<img src={iconArrowDown} alt="" />
						</a>
					</div>
				</section>
				<div className="background"><img src={iconBannerDots} alt="" /></div>
			</main>
			<main id="how-it-works" className="how-it-works">
				<div className="background"><img src={iconHowItWorksDots} alt="" /></div>
				<section>
					<div className="title">How it works</div>
					<div className="how-it-works-container">
						<div className="item-container">
							{howItWorks.map((e, i) =>
								<div key={i} className="item">
									<img src={e.featuredImage} />
									<div>
										<div className="item-title">{e.title}</div>
										<div className="item-description">{e.content}</div>
									</div>
								</div>
							)}
						</div>
						<div>
							<img src={iconHowItWorks} alt="" />
						</div>
					</div>
				</section>
			</main>
			<main className="blog-container">
				<img className="background" src={iconAutoFillDots} alt="" />
				<section>
					<div className="blog-container-content">
						<img src={iconAutoFill} alt="" />
						<div>
							<div className="title">Email on Your Terms</div>
							<a className="read-blog" href={`${config.LOCKR_URL}/email-on-your-terms`} target="_blank">Read on the blog</a>
						</div>
					</div>
				</section>
			</main>
			<main className="bottom-banner">
				<section>
					<img src={bottomBanner.featuredImage} />
					<div className="title">{bottomBanner.title}</div>
					<div className="subtitle">{bottomBanner.content}</div>
					<div className="button-container">
						<a className="learn-more" href={`${config.LOCKR_URL}/blog`} target="_blank">Learn more</a>
					</div>
				</section>
			</main>
			<AppFooter />
		</div>
	)
}