import React from 'react'

import './styles.scss'

export function HMenuSubContainer({
	children
}) {
	const ref = React.useRef()

	React.useEffect(() => {
		updateStyle()
	}, [children])

	function updateStyle() {
		if (!ref.current) return

		// console.log(ref.current.children)
		// const hMenuEl = ref.current.querySelector(".h-menu")
		// console.log(hMenuEl)
	}

	return (
		<div ref={ref} className="h-menu-sub-container">
			{children}
		</div>
	)
}