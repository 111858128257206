import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'

import { DateHelper } from 'utils'
import { errorMessages } from 'constants/error'

export class AdminUser extends BaseModel {
	firstName = ''
	lastName = ''
	email
	phone
	phoneVerifyCode
	phoneVerifiedAt
	allowSmsNotification
	allowEmailNotification
	allowSupportPublisher
	doneTutorial
	statsReportPeriod
	statsReportOn
	role
	verifiedCount
	loginCount
	lastLoginAt
	mailCount
	refEarned
	refSent
	hiddenFeatures = []
	refAvailable
	refAccepted
	referrer
	tags
	isBlocked

	/** @returns {AdminUser} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.hiddenFeatures = json?.hiddenFeatures ?? []
		}
		return result
	}

	/** @returns {Array<AdminUser>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async load(params) {
		const defaultError = 'Sorry, there are some troubles to get users.'
		try {
			const { data, error } = await Api.get('/admin/users', params)
			if (error) { return { error: defaultError } }
			return { count: data.count, results: AdminUser.fromJSONArray(data.results) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async getDeletedUsers(params) {
		try {
			const { data, error } = await Api.get('/admin/get-deleted-users', params)
			return {
				error: error && errorMessages.CONTACT_US,
				count: data.count,
				results: AdminUser.fromJSONArray(data.rows)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async enableHiddenFeatures({ userIds = [], hiddenFeatures = [] }) {
		try {
			const { error } = await Api.post('/admin/update-hidden-features', { userIds, hiddenFeatures })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async blockUsers(users) {
		try {
			const userIds = users.map(x => typeof x === 'string' ? x : x.id)
			const { error } = await Api.post('/admin/block-users', { userIds })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async deleteUsers(users) {
		try {
			const userIds = users.map(x => typeof x === 'string' ? x : x.id)
			const { error } = await Api.post('/admin/delete-users', { userIds })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async resetPostFixPassword(email) {
		try {
			const { error } = await Api.post('/admin/user/update-postfix-password/v2', { emails: [email] });
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async unblockUsers(users) {
		try {
			const userIds = users.map(x => typeof x === 'string' ? x : x.id)
			const { error } = await Api.post('/admin/unblock-users', { userIds })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async undeleteUsers(users) {
		try {
			const userIds = users.map(x => typeof x === 'string' ? x : x.id)
			const { error } = await Api.post('/admin/undelete-users', { userIds })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	get name() { return `${this.firstName} ${this.lastName}`.trim() }
	get memberSince() {
		if (!this.createdAt) return ''
		return 'Member since ' + DateHelper.format(this.createdAt, 'MMMM YYYY')
	}
	get lastLogin() {
		if (!this.lastLoginAt) return ''
		return 'Last signin: ' + DateHelper.format(this.lastLoginAt, 'MM/DD/YYYY, hh:mma')
	}

	hasHiddenFeature(feature) {
		if (!feature) return false
		return this.hiddenFeatures.includes(feature)
	}
}