import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import './styles.scss'

import {
	NotificationContainer, AppFooter, Navigation,
	Tab, TabItem,
	Loader,
} from "components"
import { AccountProfile } from './account'
import { AccountGeneral } from './settings'
import { AccountDomainSettings } from './domains'
import { AccountGroups } from './groups'

import {
	useAlert, useAuth,
	DomainSettingsProvider
} from 'core'
import { DateHelper, useLocationChange, usePrevious } from 'utils'
import { routes, redirectTo, parseSearchParams } from 'routes'

const TabIndex = routes.Category.Account

export function AccountScene(props) {
	const navigate = useNavigate()
	const { category = props.category ?? TabIndex.Account } = useParams()
	const prevCategory = usePrevious(category)
	const [searchParams] = useSearchParams()

	const { setAlert } = useAlert()
	const { isLoading: isUserLoading, currentUser } = useAuth()

	React.useEffect(() => {
		setChanged(false)

		if (prevCategory !== category && category === TabIndex.Domains && currentUser && !currentUser.allowDomainNotification) {
			setAlert({
				message: 'Extension Notifications are currently turned off. To access Domain settings, please turn back on notifications on your account page. Extension Pop Ups allow easy access to relevant promotions and offers while browsing online.',
				buttons: [
					{ type: 'positive', label: 'Change Notifications', onClick: () => handleCategory(TabIndex.Settings) },
				],
				onClose: () => handleCategory(prevCategory),
			})
		}
	}, [currentUser, category])

	// Check logged in.
	React.useEffect(() => {
		const { domain } = parseSearchParams(searchParams)
		if (category === routes.Category.Account.Domains) {
			setDomain(domain)
		}
	}, [searchParams])

	// From Mail-Modal's Domain Settings Edit
	const [domain, setDomain] = React.useState(null)

	const [isChanged, setChanged] = React.useState(false)

	function redirectToCategory(category) {
		let searches = {}
		if (domain && category === TabIndex.Domains) searches = { ...searches, domain }

		navigate(redirectTo({
			route: routes.Account,
			category,
			searches
		}))
	}

	function handleCategory(newCategory, force = false) {
		if (!currentUser) return

		if (!force && newCategory !== category && isChanged) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving?',
				buttons: [
					{ type: 'positive', label: 'Stay on this page' },
					{
						type: 'negative',
						label: 'Leave this page',
						onClick: () => {
							setChanged(false)
							handleCategory(newCategory, true)
						}
					},
				],
			})
			return
		}

		redirectToCategory(newCategory)
	}

	return (
		<div id="parent" className="page-container account">
			<NotificationContainer />
			<Navigation locked={isChanged} />
			<div className="account-wrapper">
				{isUserLoading ? <Loader /> :
					<>
						<div className="greetings">
							<div>Good {DateHelper.period()}, {currentUser?.firstName ?? ""}</div>
							<div>Member since {currentUser?.memberSince ?? ""}</div>
						</div>
						<Tab
							className="tab-container"
							value={category}
							onSelect={(value) => handleCategory(value)}>
							{Object.keys(TabIndex).map((key, index) =>
								<TabItem key={index} value={TabIndex[key]}>{key}</TabItem>
							)}
						</Tab>
						<div className="content-container">
							{category === TabIndex.Account &&
								<AccountProfile
									onChange={value => setChanged(value)} />
							}
							{category === TabIndex.Settings &&
								<AccountGeneral
									onChange={(value) => setChanged(value)}
									onAlert={alert => setAlert(alert)}
								/>
							}
							{category === TabIndex.Domains &&
								<DomainSettingsProvider>
									<AccountDomainSettings
										selectedDomain={domain}
									/>
								</DomainSettingsProvider>
							}
							{category === TabIndex.Groups &&
								<AccountGroups />
							}
						</div>
					</>
				}
			</div>
			<AppFooter />
		</div>
	)
}

export function AccountGroupsScene(props) {
	return (
		<AccountScene {...props} category={routes.Category.Account.Groups} />
	)
}

export * from './delete-alert'
export * from './input-field'
export * from './email-verify-panel'
export * from './delete-alert-lockrscan'