import React from 'react'

import './styles.scss'

import {
	Loader,
	SearchField,
	Select, SelectOption,
	Pagination
} from 'components'
import { UserListItem } from './user-list-item'

import {
	AdminUser, useAdminCurrentUsers,
	useAdminDeletedUsers,
} from 'core'

export function AdminDeletedUsersEl({ }) {
	const { isLoading, totalCount, deletedUsers, adminDeletedUsersSearch } = useAdminDeletedUsers()

	const [searchText, setSearchText] = React.useState('')
	const [pageNum, setPageNum] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(5)
	const [lifeDays, setLifeDays] = React.useState(0)

	React.useEffect(() => {
		loadDeletedUsers()
	}, [searchText, pageNum, pageSize, lifeDays])

	async function loadDeletedUsers() {
		let params = {
			searchText,
			pageNum: pageNum + 1,
			pageSize,
			lifeDays
		}

		adminDeletedUsersSearch(params)
	}

	return (
		<div className="admin-deleted-users">
			<div className="total">Total: {totalCount}</div>
			<div className="header">
				<SearchField
					value={searchText}
					onChange={(value) => setSearchText(value)} />
				<Select
					className="life-days-selector"
					value={lifeDays}
					onChange={(value) => setLifeDays(value)}>
					<SelectOption value={0}>View All</SelectOption>
					<SelectOption value={1}>1-Day</SelectOption>
					<SelectOption value={7}>1-Week</SelectOption>
					<SelectOption value={30}>1-Month</SelectOption>
					<SelectOption value={90}>3-Months</SelectOption>
					<SelectOption value={365}>1-Year</SelectOption>
				</Select>
			</div>
			<div className="list">
				<UserListItem isHeader />
				{isLoading ? <Loader /> :
					deletedUsers.map((user, index) =>
						<UserListItem
							key={index}
							user={user}
							onRefresh={() => {
								loadDeletedUsers()
							}} />
					)
				}
			</div>
			<Pagination
				className="admin-current-users-pagination"
				totalSize={totalCount}
				pageIndex={pageNum}
				pageSize={pageSize}
				onPageIndex={value => setPageNum(value)}
				onPageSize={value => setPageSize(value)}
			/>
		</div>
	)
}