import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertInput, AlertButton, AlertFooter, AlertButtonContainer
} from 'components/alert/core'

import { isEmail } from 'utils'
import { errorCodes, errorMessages } from 'constants/error'
import { User } from 'core'
import gmailOption from '../../../assets/images/signup-gmail-button.svg'

export function SignupEmailConfirmation({
	personalEmail = "",
	resendDifDestinationEmail = () => { }
}) {

	const [email, setEmail] = React.useState("")
	const [disableResend, setDisableResend] = React.useState(true)
	const [emailError, setEmailError] = React.useState("")
	const [alertFooter, setAlertFooter] = React.useState(false);
	const [displayEmail, setDisplayEmail] = React.useState(personalEmail)
	const onChangeEmail = (e) => {
		setEmailError("")
		setEmail(e)
		if (isEmail(e)) {
			setDisableResend(false)
		} else {
			setDisableResend(true)
		}
	}

	const onClickResend = async () => {
		const { error, errorCode } = await User.checkEmailUsed(email);
		if (error) {
			if (errorCode === errorCodes.DELETED_BEFORE) setEmailError(error)
			else if (errorCode === errorCodes.REGISTER_EMAIL_ALREADY_IN_USE) {
				setEmailError(errorMessages.ERROR_EMAIL_ALREADY_IN_USE)
			} else if (errorCode === errorCodes.REQUIRED_VERIFY_PRIMARY_EMAIL) {
				setEmailError(errorMessages.ERROR_REGISTER_REQUIRED_VERIFY_PRIMARY_EMAIL)
			}
			else if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) setEmailError(errorMessages.NOT_SUPPORTED_EMAILS)
			else if (errorCode === errorCodes.LOCKRMAIL_NOT_SUPPORTED) setEmailError(errorMessages.ERROR_EMAIL_NOT_SUPPORTED)
			else setEmailError(error)

		}
		else {
			if (email == displayEmail) {
				setEmailError(errorMessages.SIGNUP_EMAIL_RESEND_SAME)
				return;
			}
			resendDifDestinationEmail(displayEmail, email)
			setDisplayEmail(email);
			setAlertFooter(false)

			setEmail("")
			setDisableResend(true)
		}
	}

	return (
		<Alert className="signup-confirmation-alert">
			<AlertPage>
				<AlertTitle>Check your inbox</AlertTitle>
				<AlertMessage>
					We have sent a confirmation email to <span className="weight-bold">{displayEmail}</span>
					<br /><br />
					Click the button in the confirmation email to complete your account sign-up and secure your lockrMail alias.

					{displayEmail.split('@').includes('gmail.com') && <><div className='gmail-button-image' style={{ marginTop: 48, cursor: 'pointer' }} onClick={() => { window.open('https://mail.google.com/#search/from:anonymous-reply@lockrmail.com in:anywhere') }}>
						<img src={gmailOption}></img>
					</div></>}
					<br />{displayEmail.split('@').includes('gmail.com') ? <></> : <br />}
					<span className="color-app-green">NOTE: lockrMail email is ONLY reserved for 24 hours so don’t delay!</span>
					<br /><br /><br />
					<span className="weight-bold">Need to change your email? <span className="weight-bold" style={{ cursor: 'pointer' }} onClick={() => { setAlertFooter(true) }}>Click Here</span></span>
				</AlertMessage>
			</AlertPage>
			<AlertFooter className={"change-email-alert " + (alertFooter ? 'change-email-alert-footer' : '')}>
				If you didn’t receive the confirmation email you can change the address.
				<AlertButtonContainer>
					<AlertInput id="signup-confirm-email" onChange={onChangeEmail} value={email} placeholder="Personal or work email" error={emailError} />
					<AlertButton disabled={disableResend} onClick={onClickResend}> Resend</AlertButton>
				</AlertButtonContainer>
			</AlertFooter>
		</Alert>
	)
}