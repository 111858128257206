export class SelectorOption {
	label
	value
	selected
	disabled
	leftIcon
	rightIcon
	subOptions
	onClick
	labelComponent

	constructor({ label, value, selected, disabled, leftIcon, rightIcon, subOptions, onClick, labelComponent }) {
		this.label = label || ''
		this.value = value
		this.selected = selected
		this.disabled = disabled || false
		this.leftIcon = leftIcon
		this.rightIcon = rightIcon
		this.subOptions = subOptions
		this.onClick = onClick
		this.labelComponent = labelComponent;
	}
}