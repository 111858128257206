import { plainToClass } from 'class-transformer'

export class AdminWaitlistState {
	total
	changePercent
	ticks = []

	static fromJSON(json) {
		const result = plainToClass(AdminWaitlistState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminWaitlistStateTick.fromJSON(tick.current),
				'previous': AdminWaitlistStateTick.fromJSON(tick.previous),
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminWaitlistState.fromJSON(json))
	}
}

export class AdminWaitlistStateTick {
	from
	to
	waitlist

	static fromJSON(json) {
		return plainToClass(AdminWaitlistStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminWaitlistStateTick.fromJSON(json))
	}
}