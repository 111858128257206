import React from 'react'

import {
    Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton
} from './core'

export function HideSenderAlert({
    onOk = () => { },
    onClose = () => { },
}) {

    function handleOk() {
        if (onOk) onOk()
        else onClose()
    }

    function handleCancel() {
        if (onCancel) onCancel()
        else onClose()
    }

    return (
        <Alert onCancel={onClose}>
            <AlertPage>
                <AlertTitle>Confirm Removal</AlertTitle>
                <AlertMessage>You are hiding this Sender from your lockrScan dashboard and scheduled report - please confirm to continue.</AlertMessage>
                <AlertButtonContainer>
                    <AlertButton type="positive" onClick={onOk}>Confirm</AlertButton>
                    <AlertButton type="negative" onClick={onClose}>Cancel</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}