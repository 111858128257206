import React from 'react'

import { AdminSpecialLinksEl } from './special-links'
import { AdminDiscoveryEl } from './discovery'
import { AdminAvatarEl } from './avatars'
import { AdminBlockIPAddressEl } from './blocked-ip'
import { AdminWhiteListIPAddressEl } from './whitelist-ip'

export function AdminMarketingEl({
	className = "",
}) {
	return (
		<React.Fragment>
			<AdminSpecialLinksEl />
			<div className="admin-group">
				<div className="admin-group-title">Discovery</div>
				<AdminDiscoveryEl />
			</div>
			<div className="admin-group">
				<div className="admin-group-title">Avatars</div>
				<AdminAvatarEl />
			</div>
			<div className="admin-group">
				<div className="admin-group-title">Blocked IP Address</div>
				<AdminBlockIPAddressEl />
			</div>
			<div className="admin-group">
				<div className="admin-group-title">WhiteList IP Address</div>
				<AdminWhiteListIPAddressEl />
			</div>
		</React.Fragment>
	)
}