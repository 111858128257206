import { Alert, AlertButton, AlertButtonContainer, AlertPage, AlertTitle } from "../core";
import './styles.scss'
import LockrScanPreviewImage from "../../../assets/images/lockrScan-preview.svg";
import React from "react";
import googleSigninButton from '../../../assets/images/google_button.png'
import { useAuth, User } from "core";
import { useNavigate } from "react-router-dom";
import { redirectTo, routes } from "routes";
import { Storage } from "utils";
import { useSearchParams } from 'react-router-dom'
import { parseSearchParams } from 'routes'

const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;

export function LockrScanPreview({
    lockrScanAccess = false,
    onClose = () => { },
    login = false
}) {
    const { lockrScanLogin, lockrScanStatus, currentLockrScanUser, currentUser } = useAuth();
    const [error, setError] = React.useState('')
    const [googleOauthLink, setGoogleOauthLink] = React.useState("");
    const [ipAddr, setIpAddr] = React.useState("");
    const [permalink, setPermalink] = React.useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    // React.useEffect(() => {
    //     window.addEventListener("message", handleLockrScanLogin)
    //     return (() => {
    //         window.removeEventListener("message", handleLockrScanLogin);
    //     })
    // }, [])
    React.useEffect(() => {
        getGoogleOauthLink();
    }, [])
    const getGoogleOauthLink = async () => {
        const { wordpressLink } = parseSearchParams(searchParams);
        const { error, data } = await User.getLockrScanAuth(wordpressLink);
        setGoogleOauthLink(data?.googleOAuthUrl);
        setIpAddr(data?.ipAddr);
        setPermalink(data?.permalink);
    }
    const handleLockrScanLogin = (event) => {
        const data = event.data;
        if (data?.error) {
            if (typeof data?.error === 'object') {
                setError(JSON.stringify(data.error))
            } else {
                setError(data.error)
            }
        }
        if (typeof data == "undefined" || data === "") {
            return;
        }
        if (!currentLockrScanUser) {

            (async () => { await lockrScanLogin(data) })()
        }
        // navigate(redirectTo({ route: routes.LockrScanDashboard }))
    }
    return (
        <Alert className="lockrScan-preview-alert" onCancel={() => { onClose() }}>
            <AlertPage >
                <AlertTitle>{login ? 'lockrScan is currently limited to lockrMail users' : !lockrScanAccess ? 'lockrScan is currently limited to test accounts' : 'Get lockrScan'}</AlertTitle>
                <div className="lockrScan-preview-wrapper">
                    <div className="lockrScan-preview">
                        <div className="lockrScan-text">
                            lockrScan <div className="lockrScan-text-yellow">cleans up your Gmail inbox</div> with unsubscribe and lockrArchive features.
                        </div>
                        <div className="lockrScan-img">
                            <img src={LockrScanPreviewImage}></img>
                        </div>
                    </div>
                </div>
                <AlertButtonContainer className="google-button-container">

                    {login ? <AlertButton onClick={() => onClose()}>Check out lockrMail</AlertButton>
                        : !lockrScanAccess ? <AlertButton onClick={() => onClose()}>Return to Dashboard</AlertButton>
                            : <button onClick={async () => {
                                setError('');
                                // const { error, data } = await User.getLockrScanAuth();
                                // if (error) {
                                //     setError(error)
                                //     return;
                                // }
                                if (Storage.accessToken) {
                                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                                    );
                                }
                                else {
                                    window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                                        'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
                                }
                            }}><img style={{ cursor: 'pointer' }} className="google-button-image" src={googleSigninButton}></img> </button>}

                </AlertButtonContainer>
                <div style={{ color: 'red' }}>{error}</div>

            </AlertPage>

        </Alert>
    )
}