import React from 'react'

import {
	SenderGroup,
} from 'core/models'
import { useAlert } from 'core/providers/alert'

const SenderGroupContext = React.createContext({})

export function SenderGroupProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [senderGroups, setSenderGroups] = React.useState([])

	async function loadSenderGroups() {
		setLoading(true)
		const { error, results } = await SenderGroup.getAll()
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setSenderGroups(results)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		senderGroups,

		loadSenderGroups,
	}), [isLoading, senderGroups])

	return (
		<SenderGroupContext.Provider value={memoedValue}>
			{children}
		</SenderGroupContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, senderGroups: Array<SenderGroup>, loadSenderGroups: Function}} UseSenderGroup
 * @returns {UseSenderGroup}
 */
export function useSenderGroup() {
	return React.useContext(SenderGroupContext)
}