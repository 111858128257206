import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAlert } from 'core/providers/alert'
import { useAuth } from 'core/providers/auth'
import { DigestArchive, DigestMail } from 'core/models'
import { routes, redirectTo, parseSearchParams } from 'routes'
import { ArrayHelper } from 'utils'

const ViewDigestArchiveContext = React.createContext({})

export function ViewDigestArchivesProvider({
	children,
}) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { setError } = useAlert()
	const { currentUser } = useAuth()
	const [isLoading, setLoading] = React.useState(false)

	/** @type {[DigestArchive, Function]} */
	const [digestArchive, setDigestArchive] = React.useState(null)

	/** @type {[Array<DigestMail>, Function]} */
	const [mails, setMails] = React.useState([])
	const [curMailIndex, setCurMailIndex] = React.useState(0)
	React.useEffect(() => {
		if (digestArchive) {
			setCurMailIndex(0)
			setMails(digestArchive.rows)
		}
	}, [digestArchive])

	const [currentMail, setCurrentMail] = React.useState(null)
	React.useEffect(() => {
		if (ArrayHelper.isValid(mails) && curMailIndex < mails.length) {
			setCurrentMail(mails[curMailIndex])
		}
	}, [mails, curMailIndex])

	const [detailId, setDetailId] = React.useState(null)
	React.useEffect(() => { load() }, [currentUser, detailId])

	React.useEffect(() => { parseLocation() }, [searchParams, mails, digestArchive])
	function parseLocation() {
		const { detailId, mailId } = parseSearchParams(searchParams)
		if (!detailId) {
			navigate(redirectTo({
				route: routes.Digest,
				category: routes.Category.Digest['My Digests'],
			}))
			return
		}

		setDetailId(detailId)

		if (!mailId && ArrayHelper.isValid(mails)) {
			navigate(redirectTo({
				route: routes.ViewDigestArchives,
				searches: { detailId, mailId: mails[0].id }
			}))
			return
		}

		const mailIdx = mails.findIndex(x => x.id === mailId) ?? 0
		setCurMailIndex(mailIdx)
	}

	async function load() {
		if (!currentUser || !detailId) return

		setLoading(true)
		const { error, result } = await DigestArchive.get(detailId)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setDigestArchive(result)
	}

	function canGoPrev() { return curMailIndex > 0 }
	function canGoNext() { return curMailIndex + 1 < mails.length }

	function goPrev() { setCurMailIndex(Math.max(curMailIndex - 1, 0)) }
	function goNext() { setCurMailIndex(Math.min(curMailIndex + 1, mails.length - 1)) }

	const memoedValue = React.useMemo(() => ({
		isLoading,
		detailId,
		digestArchive,
		mails,
		curMailIndex,
		currentMail,

		canGoPrev, canGoNext,
		goPrev, goNext,
	}), [isLoading, detailId, digestArchive, mails, curMailIndex, currentMail])

	return (
		<ViewDigestArchiveContext.Provider value={memoedValue}>
			{children}
		</ViewDigestArchiveContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, detailId: string, digestArchive: DigestArchive, curMailIndex: number, currentMail: DigestMail, mails: Array<DigestMail>, canGoPrev: Function, canGoNext: Function, goPrev: Function, goNext: Function }} UseViewDigestArchive
 * @returns {UseViewDigestArchive}
 */
export function useViewDigestArchive() {
	return React.useContext(ViewDigestArchiveContext)
}