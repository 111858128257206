import React from 'react'

import './styles.scss'
import iconFilter from '../../../assets/images/filter/gray.svg'

import {
	SearchField,
	Selector, SelectorOption
} from '../../../components'

import { DigestDiscoverGroup } from './discover-group'

const sampleDigests = [
	{ label: 'Finance' },
	{ label: 'Shopping' },
	{ label: 'Tech' },
]

export function DigestDiscover({
	className = '',
}) {
	return (
		<div className={`digest-discover ${className}`}>
			<header>
				<div>
					<SearchField />
				</div>
				<div>
					<Selector
						defaultLabel="Sort by" />
					<Selector
						defaultLabel="Categories" />
				</div>
				<div>
					<img src={iconFilter} alt="" />
				</div>
			</header>
			<main>
				{sampleDigests.map((digest, index) =>
					<DigestDiscoverGroup
						key={index}
						label={digest.label}
					/>
				)}
			</main>
		</div>
	)
}