import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './styles.scss'
import iconInputChecked from 'assets/images/checkbox1.svg'
import iconPlus from 'assets/images/icon_plus.svg'
import iconNotification from 'assets/images/notification_default.svg'
import iconInfo from 'assets/images/info-white.svg'
import eyeOpen from 'assets/images/eye-open.svg'
import eyeClosed from 'assets/images/eye-closed.svg'

import {
	Modal, StickyButton,
	IModal, TimezoneSelect
} from 'components'
import { InputField, EmailVerifyPanel, DeleteAlert } from '..'
import { SecurityEl } from './security'

import { useAlert, useAuth, ConnectedEmail, User, Segment, Api } from 'core'
import {
	isValidText, isValidPassword, isValidPhoneNumber, isEmail, isLockrMail,
	ObjectHelper, ArrayHelper, DOMHelper, stringContainsUrl,
	Storage,
} from 'utils'
import { parseSearchParams } from 'routes'
import sanitize from 'sanitize-html'
import { errorMessages } from 'constants/error'
import moment from 'moment-timezone'
// import googleSigninButton from '../../../assets/images/google_button.png'

// const popupWidth = 605;
// const popupHeight = 900;
// const popupLeft = (screen.width - popupWidth) / 2;
// const popupTop = (screen.height - popupHeight) / 2;

const dragItemStyle = (draggableStyle, isDragging) => ({
	...draggableStyle,
	background: isDragging ? '#000000' : 'transparent',
	padding: isDragging ? '8px' : '0',
	border: isDragging ? '1px solid #61CEF7' : 'transparent',
	borderRadius: '2px'
})

const dragListStyle = (isDraggingOver) => ({})

export function AccountProfile({
	className = '',
	onChange = (value) => { },
}) {
	const [searchParams] = useSearchParams()

	const { setAlert, setError } = useAlert()
	const { currentUser, userGetProfile, userLogout } = useAuth()

	const businessEmailRef = React.createRef()
	const iEmailIconRef = React.useRef()

	React.useEffect(() => { checkIfChanged() })
	const [isChanged, setChanged] = React.useState(false)
	React.useEffect(() => { onChange(isChanged) }, [isChanged])
	const [isDelete, setDelete] = React.useState(false)

	const [firstName, setFirstName] = React.useState("")
	const [firstNameError, setFirstNameError] = React.useState(null)
	React.useEffect(() => { setFirstNameError("") }, [firstName])

	const [lastName, setLastName] = React.useState("")
	const [lastNameError, setLastNameError] = React.useState(null)
	React.useEffect(() => { setLastNameError("") }, [lastName])

	const [timezone, setTimezone] = React.useState('America/New_York')

	const [isChangePassword, setChangePassword] = React.useState(false)
	const [oldPassword, setOldPassword] = React.useState('')
	const [oldPasswordVisibility, setOldPasswordVisibility] = React.useState(false);
	const [newPassword1, setNewPassword1] = React.useState('')
	const [newPassword1Visibility, setNewPassword1Visibility] = React.useState(false)
	const [newPassword2, setNewPassword2] = React.useState('')
	const [newPassword2Visibility, setNewPassword2Visibility] = React.useState(false)
	const [passwordError, setPasswordError] = React.useState(null)
	const [pwUpdated, setPwUpdated] = React.useState(false)
	React.useEffect(() => { setPasswordError(null) }, [oldPassword, newPassword1, newPassword2])

	const [phoneNumber, setPhoneNumber] = React.useState("")
	const [phoneNumberError, setPhoneNumberError] = React.useState(null)
	const [phoneNumberVerified, setPhoneNumberVerified] = React.useState(true)
	const [phoneVerificationCode, setPhoneVerificationCode] = React.useState("")
	React.useEffect(() => { setPhoneNumberError("") }, [phoneNumber])

	const [lockrMail, setLockrMail] = React.useState("")
	const [connectedEmails, setConnectedEmails] = React.useState([])
	const [removeConnectedEmailIds, setRemoveConnectedEmailIds] = React.useState([])
	const [newBusinessEmails, setNewBusinessEmails] = React.useState([])
	const [curConnectedEmails, setCurConnectedEmails] = React.useState([])
	React.useEffect(() => { setCurConnectedEmails(calcConnectedEmails()) }, [connectedEmails, removeConnectedEmailIds])

	const [newEmail, setNewEmail] = React.useState('')
	const [newEmailType, setNewEmailType] = React.useState(3)
	const [newEmailError, setNewEmailError] = React.useState(null)
	const [newEmailLoader, setNewEmailLoader] = React.useState(false);

	const [timer, setTimer] = React.useState(0)
	const [isTimer, setIsTimer] = React.useState(false)

	React.useEffect(() => {
		if (curConnectedEmails?.find(e => !e.isVerified)) {
			setIsTimer(true)
		}else{
			setIsTimer(false)
		}
	}, [JSON.stringify(curConnectedEmails)])

	React.useEffect(() => {
		let timerId = null
		if (isTimer) {
			timerId = setInterval(() => {
				setTimer((prevSeconds) => prevSeconds + 1);
			}, 1000);
		
		}
		return () => clearInterval(timerId);
	}, [isTimer])
	
    // const [googleOauthLink, setGoogleOauthLink] = React.useState("");
    // const [ipAddr, setIpAddr] = React.useState("");
	// const [permalink, setPermalink] = React.useState(null)
	const [requested, setRequested] = React.useState(false)

	React.useEffect(() => { setNewEmailError('') }, [newEmail, newEmailType])

	const [isAddModalOpen, setAddModalOpen] = React.useState(false)
	React.useEffect(() => {
		if (isAddModalOpen) {
			if (businessEmailRef.current) { businessEmailRef.current.focus() }
			// DOMHelper.scrollIntoView(document.querySelector('#newBusinessEmail'))
			document.getElementById('newBusinessEmail').scrollIntoView({ behavior: 'smooth' })
		}
	}, [isAddModalOpen])

	React.useEffect(() => {
		const { requireAdd } = parseSearchParams(searchParams)
		setAddModalOpen(requireAdd)
	}, [searchParams])

	React.useEffect(() => {
		if (currentUser) {
			setFirstName(currentUser.firstName)
			setLastName(currentUser.lastName)
			setTimezone(currentUser.timezone)
			setPhoneNumber(currentUser.phone)
			setPhoneNumberVerified(isValidText(currentUser.phone) && currentUser.isPhoneVerified())

			setLockrMail(currentUser.email)
			setConnectedEmails(ArrayHelper.copy(currentUser.connectedEmails))

			/// initialize
			setRemoveConnectedEmailIds([])
			setNewBusinessEmails([])
			setChanged(false)
			setNewEmailType(3)
		}
	}, [currentUser])

	// React.useEffect(() => {
	// 	getGoogleOauthLink();
	// }, [])

    // React.useEffect(() => {
    //     window.addEventListener("message", handleGoogleSignup)
    //     return (() => {
    //         window.removeEventListener("message", handleGoogleSignup);
    //     })
    // }, [])

	// const handleGoogleSignup = (event) => {
	// 	const data = event.data;
	// 	if(data.personalEmail){
	// 		userGetProfile()
	// 	}else if(data.error){
	// 		setError(data.error)
	// 	}
    // }

    // const getGoogleOauthLink = async () => {
    //     const { wordpressLink } = parseSearchParams(searchParams);
    //     const { error, data } = await User.getUserAuth(wordpressLink);
    //     setGoogleOauthLink(data?.googleOAuthUrl);
    //     setIpAddr(data?.ipAddr);
    //     setPermalink(data?.permalink);
    // }

	function primaryEmail() {
		return connectedEmails.filter(e => e.isPrimary)[0]
	}

	function calcConnectedEmails() {
		return connectedEmails
			.filter(e => !removeConnectedEmailIds.some(e2 => e2 === e.id))
			.sort((a, b) => {
				return (a.orderNum < b.orderNum) ? -1 : 0
			})
	}

	function checkIfChanged() {
		if (!currentUser) return false
		const result = !ObjectHelper.equals(firstName, currentUser.firstName)
			|| !ObjectHelper.equals(lastName, currentUser.lastName)
			|| !ObjectHelper.equals(timezone, currentUser?.timezone)
			|| !ObjectHelper.equals(phoneNumber, currentUser.phone)
			|| !ArrayHelper.equals(connectedEmails, currentUser.connectedEmails)
			|| !ObjectHelper.equals(primaryEmail(), currentUser.primaryEmail)
			|| ArrayHelper.isValid(removeConnectedEmailIds)
			|| ArrayHelper.isValid(newBusinessEmails)
		setChanged(result)
		return result
	}

	function checkValidation() {
		let result = true
		// if (!isValidText(firstName, true)) {
		// 	setFirstNameError("First name is invalid.")
		// 	result = false
		// }

		// if (!isValidText(lastName, true)) {
		// 	setLastNameError("Last name is invalid.")
		// 	result = false
		// }

		if (isValidText(phoneNumber, true) && !isValidPhoneNumber(phoneNumber)) {
			setPhoneNumberError("Phone number is invalid.")
			result = false
		}

		if (stringContainsUrl(firstName)) {
			setFirstNameError(errorMessages.FIRSTNAME_CANNOT_CONTAIN_URL);
			result = false;
		}
		if (stringContainsUrl(lastName)) {
			setLastNameError(errorMessages.LASTNAME_CANNOT_CONTAIN_URL)
			result = false
		}

		return result
	}

	async function handleChangePassword() {
		if (!isValidText(oldPassword)) {
			setPasswordError('Please fill the current password.')
			return
		}
		if (!isValidText(newPassword1) || !isValidText(newPassword2)) {
			setPasswordError('Please fill the new password.')
			return
		}
		if (!isValidPassword(newPassword1) || !isValidPassword(newPassword2)) {
			setPasswordError('Password doesn\'t meet requirements.')
			return
		}
		if (newPassword1 !== newPassword2) {
			setPasswordError('Passwords do not match.')
			return
		}

		const { error } = await currentUser.changePassword(oldPassword, newPassword1, newPassword2)
		if (error) {
			setPasswordError(error)
		} else {
			setChangePassword(false)
			setOldPassword(null)
			setNewPassword1(null)
			setNewPassword2(null)
			setPwUpdated(true)
		}
	}

	function handleAddNewEmail() {
		if (!newEmail) {
			setNewEmail('')
		}
	}

	function handleDeleteEmail(businessEmail) {
		if (businessEmail instanceof ConnectedEmail) {
			setRemoveConnectedEmailIds(removeConnectedEmailIds.concat(businessEmail.id))
			setNewBusinessEmails(newBusinessEmails.filter(e => e.email !== businessEmail.email))
		}
	}

	function handleSetPrimary(email) {
		if (email instanceof ConnectedEmail) {
			setConnectedEmails(connectedEmails.map(e => {
				e.isPrimary = email.id === e.id
				return e
			}))
			setChanged(true)
		}
	}

	async function handleVerifyEmail(connectedEmail) {
		if (!connectedEmail) return

		if (connectedEmail instanceof ConnectedEmail) {
			const { error } = await connectedEmail.verify()
			if (error) {
				setError(error)
				return
			}

			handleRequestVerifyResult(connectedEmail.email)
			return
		}

		const { error } = await User.sendVerifyEmail(connectedEmail)
		if (error) {
			setError(error)
			return
		}

		handleRequestVerifyResult(connectedEmail)
	}

	function handleEmailTypeChanged(connectedEmail, emailType) {
		if (connectedEmail instanceof ConnectedEmail) {
			setConnectedEmails(connectedEmails.map(e => {
				if (e.id === connectedEmail.id) { e.setEmailType(emailType) }
				return e
			}))
			setNewBusinessEmails(newBusinessEmails.map(e => {
				if (e.email === connectedEmail.email) { e.setEmailType(emailType) }
				return e
			}))
			setChanged(true)
		}
		if (connectedEmail === newEmail) {
			setNewEmailType(ConnectedEmail.emailTypes().indexOf(emailType))
		}
	}

	async function handleNewEmailChecked() {
		if (!isValidText(newEmail, true)) { return }
		if (!isEmail(newEmail)) {
			setNewEmailError("Email is invalid.")
			return
		}
		if (isLockrMail(newEmail)) {
			setAlert({ message: 'A lockrMail account can\'t be used as a secondary email address in lockrMail. We only accept personal, business, or school emails.' })
			return
		}

		/// Check existing
		if (connectedEmails.filter(e => !removeConnectedEmailIds.some(e2 => e2 === e.id)).filter(e => e.email === newEmail).length > 0
			|| newBusinessEmails.filter(e => e === newEmail).length > 0) {
			setNewEmailError("This email has been added already.")
			return
		}

		const emails = newBusinessEmails.filter(e => e.email !== newEmail)
		emails.push(new ConnectedEmail(newEmail, newEmailType))

		if (await handleSave(emails)) {
			setNewEmail(null)
		} else {
			setNewEmail('')
		}
	}

	function handleRequestVerifyResult(email) {
		setAlert({ message: `Please confirm your new inbox by clicking the link we sent to ${email}. The verification link is valid for 1 hour. Thanks.` })
	}

	function handleDragResult(list, startIndex, endIndex) {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	function handleDragEnd(result) {
		if (!result.destination) { return }
		setCurConnectedEmails(handleDragResult(curConnectedEmails, result.source.index, result.destination.index))
		setChanged(true)
	}

	async function handleSave(newEmails = null) {
		setNewEmailLoader(true);
		if (!checkValidation() || !currentUser) {
			setNewEmailLoader(false);
			return
		}

		const params = {
			firstName: sanitize(firstName, { allowedTags: [] }),
			lastName: sanitize(lastName, { allowedTags: [] }),
			timezone,
			phone: phoneNumber,
			setPrimaryEmailId: primaryEmail().id,
			addConnectedEmails: newEmails ? newEmails : newBusinessEmails,
			editConnectedEmails: curConnectedEmails.map((e, index) => ({ id: e.id, emailType: e.emailType, orderNum: index })),
			removeConnectedEmailIds: removeConnectedEmailIds,
		}

		const { error } = await currentUser.update(params)

		if (error) {
			setError(error)
			setNewEmailLoader(false);
			return false
		}
		else {
			console.log(removeConnectedEmailIds);
			if (newEmails) {
				Segment.track(Segment.Event.addNewDestinationEmail, currentUser.id, { addConnectedEmails: newEmails ? newEmails : newBusinessEmails })
			}
			if (removeConnectedEmailIds) {
				Segment.track(Segment.Event.removeDestinationEmail, currentUser.id, removeConnectedEmailIds)

			}
		}


		if (newEmails && newEmails.length > 0) {
			const lastEmail = newEmails[newEmails.length - 1]
			handleRequestVerifyResult(lastEmail.email)
		}

		userGetProfile()
		setNewEmailLoader(false);
		return true
	}

	async function handleDeleteAccount() {
		const { error } = await currentUser.deleteProfile()
		if (error) {
			setAlert(error)
			return
		}

		userLogout()
		window.open('https://lockrmail.com/', '_self')
	}
	async function handleAddNewPAssword() {
		Api.post("/request-forgot-password", { email: lockrMail })
			.then(response => {
				const { success, error } = response
				if (!success || error) {
					setError(error)
					return
				}
				setRequested(true)
			})
			.catch(error => {
				setError(Api.parseError(error, "Sorry, there are some troubles to send password reset link."))
			})
	}

	const getTimeString = (date) => {
		const currentDate = moment(); // Current date and time
		const givenDate = moment(date); // Given date
  
		const diff = currentDate.diff(givenDate);
  		const oneHourInMillis = 3600000;
  
		let timeLeftInMillis = oneHourInMillis - diff;
  
		if (timeLeftInMillis < 0) {
		  timeLeftInMillis = 0;
		}
  		const minutes = Math.floor(timeLeftInMillis / 60000);
		const seconds = Math.floor((timeLeftInMillis % 60000) / 1000);
  
		const formattedTimeLeft = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
		return formattedTimeLeft;
	}

	return (
		<>
			<div className={`account-profile ${className}`}>
				<div className="group">
					<InputField
						id="firstName"
						label="First name"
						value={firstName}
						error={firstNameError}
						onChange={value => setFirstName(value)}
						onClear={() => setFirstName('')} />
					<InputField
						id="lastName"
						label="Last name"
						value={lastName}
						error={lastNameError}
						onChange={value => setLastName(value)}
						onClear={() => setLastName('')} />
					<InputField
						id="password"
						type="password"
						label="Password"
						command={!currentUser?.isPassword ? (requested ? "Request Sent" : "Add new password via mail") : pwUpdated ? 'Password Changed' : 'Change'}
						commandIcon={pwUpdated && iconInputChecked}
						value="**********"
						inputDisabled
						onCommand={() => { 
							if(!currentUser?.isPassword){
								!requested && handleAddNewPAssword()
							}else{
								setChangePassword(true) 
							}
						}}>
						{isChangePassword &&
							<Modal
								next="Submit"
								onNext={() => handleChangePassword()}
								onClose={() => { setChangePassword(false) }}>
								<div className="group-item-input">
									<input
										id="oldPassword"
										data-testid="oldPassword"
										// type={oldPasswordVisibility ? "text" : "password"}
										type={"password"}
										value={oldPassword}
										placeholder="Current Password"
										onChange={event => setOldPassword(event.target.value)} />
									{/* <img src={oldPasswordVisibility ? eyeClosed : eyeOpen} style={{ height: 22, width: 22, marginRight: 8 }} onClick={() => { setOldPasswordVisibility(!oldPasswordVisibility) }}></img> */}
								</div>
								<div className="group-item-input">
									<input
										id="newPassword1"
										data-testid="newPassword1"
										type={newPassword1Visibility ? "text" : "password"}
										value={newPassword1}
										placeholder="New Password"
										onChange={event => setNewPassword1(event.target.value)} />
									<img src={newPassword1Visibility ? eyeClosed : eyeOpen} style={{ height: 22, width: 22, marginRight: 8 }} onClick={() => { setNewPassword1Visibility(!newPassword1Visibility) }}></img>
								</div>
								<div className="group-item-input">
									<input
										id="newPassword2"
										data-testid="newPassword2"
										type={newPassword2Visibility ? "text" : "password"}
										value={newPassword2}
										placeholder="Confirm Password"
										onChange={event => setNewPassword2(event.target.value)} />
									<img src={newPassword2Visibility ? eyeClosed : eyeOpen} style={{ height: 22, width: 22, marginRight: 8 }} onClick={() => { setNewPassword2Visibility(!newPassword2Visibility) }}></img>

								</div>
								{passwordError && <div className="group-item-error">{passwordError}</div>}
							</Modal>
						}
					</InputField>

					{/* Timezone */}
					<div className="group-item">
						<div className="group-item-title">Timezone</div>
						<TimezoneSelect
							className="group-item-input"
							value={timezone}
							onChange={(value) => setTimezone(value)} />
					</div>
				</div>
				<div className="group">
					<div className="group-title">
						Emails<img ref={iEmailIconRef} src={iconInfo} alt="" />
						<IModal
							anchorEl={iEmailIconRef.current}>
							Add Destination emails, making lockrMail the filter point for all of your inboxes.<br />Learn about Destination Emails in the <a href="https://lockrmail.com/topic/destination-emails/" target="_blank">Help Center</a>
						</IModal>
					</div>
					<InputField disabled label="lockrMail" value={lockrMail} />
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="connectedEmailsContainer">
							{(provided, snapshot) => (
								<div ref={provided.innerRef} style={dragListStyle(snapshot.isDraggingOver)}>
									{curConnectedEmails.map((e, index) => {
										return (
											<Draggable index={index} key={e.id} draggableId={e.id}>
												{(provided, snapshot) => (
													<div className={`group-email ${snapshot.isDragging ? 'dragging' : ''}`} {...provided.draggableProps} {...provided.dragHandleProps}>
														<div ref={provided.innerRef} style={dragItemStyle(provided.draggableStyle, snapshot.isDragging)}>
															<InputField
																optionEnabled
																key={index}
																id={index}
																type="email"
																dragging={snapshot.isDragging}
																label={e.emailTypeInText()}
																value={e.email}
																isPersonalEmail={e.isPrimary}
																clearable={!e.isPrimary}
																onClear={() => handleDeleteEmail(e)}
																onLabelChanged={label => handleEmailTypeChanged(e, label)}
															/>
															<EmailVerifyPanel
																checked={e.isPrimary}
																verified={e.isVerified}
																onSet={() => handleSetPrimary(e)}
																onVerify={() => handleVerifyEmail(e)} />
															{(!e.isVerified) &&
																<div className="group-message" style={{ display: 'flex', alignItems: 'flex-start' }}>
																	<img src={iconNotification} alt="Add" style={{ marginTop: '6px' }} />
																	<div>
																		<span>A link has been sent to this email for verification.</span>
																		<button onClick={() => handleVerifyEmail(e)}>Resend link</button>
																		<span>Verification link is valid for {getTimeString(e.createdAt)}.</span>
																		</div>
																</div>
															}
														</div>
														{provided.placeholder}
													</div>
												)}
											</Draggable>
										)
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{newBusinessEmails.map((e, index) => {
						return (
							<div key={index}>
								<InputField
									optionEnabled
									key={index}
									label={e.emailTypeInText()}
									value={e.email}
									onClear={() => handleDeleteEmail(e)}
									onLabelChanged={label => handleEmailTypeChanged(e, label)}
								/>
							</div>
						)
					})}
					{(newEmail !== null) &&
						<InputField
							id="newBusinessEmail"
							ref={businessEmailRef}
							inputStyle={`${isAddModalOpen ? 'modal-parent' : ''}`}
							optionEnabled
							isNewEmail
							loader={newEmailLoader}
							checked={isEmail(newEmail)}
							label={ConnectedEmail.emailTypeInText(newEmailType)}
							value={newEmail}
							error={newEmailError}
							onChange={value => setNewEmail(value.trim())}
							onClear={() => setNewEmail('')}
							onLabelChanged={label => handleEmailTypeChanged(newEmail, label)}
							onChecked={handleNewEmailChecked}
							onNext={handleNewEmailChecked}>
							{isAddModalOpen &&
								<Modal
									message="Click here to connect additional email addresses to your account. This allows you to route trusted senders to your inbox of choice."
									onClose={() => {
										setAddModalOpen(false)
										if (businessEmailRef.current) {
											businessEmailRef.current.focus()
											document.getElementById('newBusinessEmail').scrollIntoView({ behavior: 'smooth' })
										}
									}}
								/>
							}
						</InputField>
					}
					<div className="group-button" onClick={handleAddNewEmail}>
						<img src={iconPlus} alt="Add" />
						<span>Add new</span>
					</div>

                    {/* <div
						style={{
							marginTop: 10,
							display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
							marginRight: '5%'
						}}
					>
						<button
							onClick={async () => {
								setError('');
								if (Storage.accessToken) {
									window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}^${true}`, 'popUpWindow',
										'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
									);
								}
								else {
									window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^^${true}`, 'popUpWindow',
										'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
								}
							}}
						>
							<img style={{ cursor: 'pointer', maxWidth: 250  }} className="google-button-image" src={googleSigninButton}></img>
						</button>
					</div> */}
				</div>
				<div className="group">
					<div className="group-title">Security</div>
					<SecurityEl className="group-item" />
				</div>
				<button className="delete-account" onClick={() => setDelete(true)}>Delete Account</button>
				<StickyButton
					className="btn-save-desktop"
					top={0}
					threshold={40}
					disabled={!isChanged}
					onClick={() => handleSave()}>
					Save
				</StickyButton>
				<div className="btn-save-mobile">
					<button disabled={!isChanged} onClick={() => handleSave()}>Save</button>
				</div>
			</div>
			{isDelete &&
				<DeleteAlert
					requiredCode="DELETE"
					onDelete={() => handleDeleteAccount()}
					onCancel={() => setDelete(false)}
				/>
			}
		</>
	)
}