import React from 'react'
import PhoneInput from 'react-phone-input-2'

import './styles.scss'
import 'react-phone-input-2/lib/high-res.css'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton, TextField
} from 'components'

import { User, useAuth, Segment } from 'core'
import { isValidPhoneNumber, isValidText } from 'utils'

const PageIndex = Object.freeze({
	SendCode: 0,
	Verify: 1,
	Error: 2
})

export function SecurityModal({
	onClose = (updated) => { }
}) {
	const { currentUser, userGetProfile } = useAuth()

	const [pageIndex, setPageIndex] = React.useState(PageIndex.SendCode)
	const [prevPageIndex, setPrevPageIndex] = React.useState(PageIndex.SendCode)
	const [phoneNumber, setPhoneNumber] = React.useState('')
	const [code, setCode] = React.useState('')
	const [error, setError] = React.useState(null)
	const [hasResent, setHasResent] = React.useState(false)

	React.useEffect(() => {
		if (error) {
			setPrevPageIndex(pageIndex)
			setPageIndex(PageIndex.Error)
		} else {
			setPageIndex(prevPageIndex)
		}
	}, [error])

	function calcPhone() {
		if (!isValidText(phoneNumber)) return null

		let result = phoneNumber
		if (!phoneNumber.includes('+')) result = '+' + result
		return result
	}

	async function sendCode() {
		let phone = calcPhone()
		if (!phone) return

		const { error } = await User.sendVerificationCode(phone)
		if (error) {
			setError(error)
			return false
		}

		return true
	}

	async function verify() {
		let phone = calcPhone()

		const { error } = await User.verifyPhoneNumber(phone, code)
		if (error) {
			setError(error)
			return false
		}
		return true
	}

	async function updateProfile() {
		let phone = calcPhone()

		const params = { allow2FA: true, phone }
		const { error } = await currentUser.update(params)
		if (error) {
			setError(error)
			return
		}

		currentUser.is2FAPassed = true

		userGetProfile()
		handleClose(true)
	}

	function handleClose(updated) {
		if (pageIndex === PageIndex.Error) {
			setError(null)
			return
		}

		onClose(updated)
	}

	async function handleSendCode() {
		if (!currentUser) return

		if (await sendCode()) {
			setPageIndex(PageIndex.Verify)
		}
	}

	async function handleVerify() {
		if (!currentUser) return

		if (await verify()) {
			// Segment.submit(Segment.Type.TFA)
			Segment.track(Segment.Event.userEnabled2fa, currentUser?.id, { phoneNumber: phoneNumber })
			await updateProfile()
		}
	}

	async function handleResend() {
		if (await sendCode()) {
			setHasResent(true)
			setTimeout(() => {
				setHasResent(false)
			}, 3000)
		}
	}

	return (
		<Alert className="security-modal" step={pageIndex} onCancel={() => handleClose(false)}>
			<AlertPage>
				<AlertTitle>Enable security</AlertTitle>
				<AlertMessage>
					Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
				</AlertMessage>
				<div className="phone-input-container">
					<span>Phone number</span>
					<PhoneInput
						country="us"
						value={phoneNumber}
						placeholder=""
						onChange={(value) => setPhoneNumber(value)}
					/>
				</div>
				<AlertButtonContainer>
					<AlertButton type="positive" disabled={!isValidPhoneNumber(phoneNumber)} onClick={() => handleSendCode()}>Send code</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
			<AlertPage>
				<AlertTitle>Verify your phone</AlertTitle>
				<AlertMessage>Please enter the code that was sent to your device.  <button className={`resend-code ${hasResent && 'disabled'}`} onClick={() => handleResend()}>Resend code. {hasResent && <span>(Sent)</span>}</button></AlertMessage>
				<div className="code-input-container">
					<span>Code</span>
					<TextField
						type="normal"
						inputType="number"
						className="code-input"
						clearable
						value={code}
						onChange={(event) => setCode(event.target.value)} />
				</div>
				<AlertButtonContainer>
					<AlertButton className='btn-add-2fa' type="positive" disabled={!isValidText(code)} onClick={() => handleVerify()}>Submit</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
			<AlertPage>
				<AlertMessage>{error}</AlertMessage>
			</AlertPage>
		</Alert>
	)
}