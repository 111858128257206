import React from 'react'

export function DomainListItem({
	value,
	isHeader,
	onClick = () => { }
}) {
	return (
		<>
			{isHeader ?
				<div className="sender-domains-list-item header">
					<div></div>
					<div>Total Emails</div>
					<div>Blocked Emails</div>
					<div>Total Accounts</div>
					<div>Blocked Accounts</div>
					<div>Filtered Accounts</div>
				</div>
				:
				<div className={`sender-domains-list-item ${isHeader && "header"}`}>
					<div>{value.domain}</div>
					<div>{value.totalEmails}</div>
					<div>{value.blockedEmails}</div>
					<div>{value.totalAccounts}</div>
					<div>{value.blockedAccounts}</div>
					<div>{value.filteredAccounts}</div>
				</div>
			}
		</>
	)
}