import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconSendMail from 'assets/images/send-mail-8B8B8B.svg'
import iconSendMailHover from 'assets/images/send-mail-61CEF7.svg'

import {
    Loader,
    Icon, Checkbox,
    IconButton,
} from 'components'

import { AdminUnregisteredUserInfoAlert } from '../unregister-user-info-alert'

import {
    useAlert,
    User, AdminUnregisteredUser
} from 'core'
import { DateHelper } from 'utils'

export function LockrScanListItem({
    isHeader = false,
    user = {},
    selected = false,
    onSelect = () => { },
    onRefresh = () => { }
}) {
    const { setError } = useAlert()

    const { firstName, lastName, email, permalink, params, createdAt, isRegistered } = user

    const [isLoading, setLoading] = React.useState(false)
    // const [isOpenInfo, setOpenInfo] = React.useState(false)

    function handleClick(evt) {
        if (evt.target.tagName == "BUTTON") return;
        if (isHeader) return

        setOpenInfo(true)
    }

    function renderDate(date) {
        if (!date) return ''

        return DateHelper.format(date, 'MM/DD/YYYY')
    }

    async function handleResendVerification() {
        setLoading(true)
        const { error } = await user.resendVerifyEmail()
        setLoading(false)

        if (error) {
            setError(error)
            return
        }
    }

    return (
        <>
            <div className={`unregistered-user-list-item ${isHeader && 'header'}`} onClick={(evt) => handleClick(evt)}>
                {isHeader ?
                    <>
                        <div className='checkbox'></div>
                        <div className='avatar'></div>
                        <div className='info'>
                            <div className="first-name">First Name</div>
                            <div className="last-name">Last Name</div>
                            <div className="email">Email Entered</div>
                            <div className="created-at">Date Submitted</div>
                            <div className="url-submitted">Url Submitted</div>
                        </div>
                        {/* <div className='actions'></div> */}
                    </>
                    :
                    <React.Fragment>
                        {isLoading ? <Loader /> :
                            <React.Fragment>
                                {/* <Checkbox
									iconChecked={iconChecked}
									iconUnchecked={iconUnchecked}
									checked={selected}
									onChange={() => onSelect()} /> */}
                                <Icon className="avatar" placeholder={email} />
                                <div className='info'>
                                    <div className="first-name">{firstName}</div>
                                    <div className="last-name">{lastName}</div>
                                    <div className="email">{email}</div>
                                    <div className="created-at">{renderDate(createdAt)}</div>
                                    <div className="url-submitted">{permalink}</div>
                                </div>
                                {/* <div className='actions'>
									{isRegistered &&
										<IconButton
											className="btn-deliver btn-manual-deliver-mail"
											normal={iconSendMail}
											hover={iconSendMailHover}
											onClick={() => handleResendVerification()} />
									}
								</div> */}
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>

        </>
    )
}