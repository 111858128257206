import React from 'react'

import './styles.scss'
import iconNext from 'assets/images/arrow/next-gray.svg'

import { useSenderGroup } from 'core'
import { ArrayHelper } from 'utils'

export function GroupList({
	selectedGroupId,
	onSelectGroup = (id) => { }
}) {
	const { senderGroups } = useSenderGroup()

	return (
		<div className="group-list-container">
			{ArrayHelper.isValid(senderGroups) &&
				<>
					<div className="group-count">All ({senderGroups.length})</div>
					<div className="group-list">
						{senderGroups.map((group) => {
							const selected = selectedGroupId === group.id
							return (
								<GroupItem
									key={group.id}
									selected={selected}
									value={group}
									onSelect={() => onSelectGroup(group.id)} />
							)
						})}
					</div>
				</>
			}
		</div>
	)
}

function GroupItem({
	value,
	selected,
	onSelect = () => { }
}) {
	const { id, name } = value
	return (
		<div className={`group-list-item ${selected && 'selected'}`} onClick={() => onSelect()}>
			<span>{name}</span>
			<img src={iconNext} alt="" />
		</div>
	)
}