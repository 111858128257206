import React from 'react'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'

import {
	Checkbox,
	Select, SelectOption,
	InfoAlert,
	Switch
} from 'components'

import {
	useAlert,
	useAuth,
	DomainSetting,
	Segment,
} from 'core'
import { config } from 'constants/config'
import { errorCodes, errorMessages } from 'constants/error'

export const SubItemType = DomainSetting.Type

/**
 * @typedef {{domainSetting: DomainSetting, selected: boolean, type: string, onSelect: Function, onChange: Function}} Props
 * @param {Props} param0
 */
export function SubItem({
	domainSetting = {},		/// instance of Domain
	selected = false,
	type = SubItemType.Email,
	onSelect = (value) => { },
	onChange = (value) => { }
}) {
	const { setAlert, setError, resetAlert } = useAlert()
	const { currentUser } = useAuth()

	const { emailOption, popupOption, passwordOption, allowConsent, allowGPC } = domainSetting

	const [isEdit, setEdit] = React.useState(false)
	React.useEffect(() => { setEdit(false) }, [domainSetting])
	const [value, setValue] = React.useState(0)
	React.useEffect(() => { setValue(calcValue()) }, [domainSetting])
	const [isConfirmOverride, setConfirmOverride] = React.useState(false)

	function calcValue() {
		if (!domainSetting) return 0
		if (type === SubItemType.Email) return emailOption
		if (type === SubItemType.Popup) return popupOption
		if (type === SubItemType.Password) return passwordOption
		if (type === SubItemType.Consent) return allowConsent
		if (type === SubItemType.GPC) return allowGPC
		return emailOption
	}

	function calcStatus() {
		if (type === SubItemType.Email) return domainSetting.emailBlockedUntil
		if (type === SubItemType.Popup) return domainSetting.popupBlockedUntil
		if (type === SubItemType.Password) return domainSetting.formattedPassword(currentUser?.allowPasswordOption)
		if (type === SubItemType.Consent) return domainSetting.formattedConsent(currentUser?.allowConsent)
		if (type === SubItemType.GPC) return domainSetting.formattedGPC(currentUser?.allowGPC)

		return domainSetting.emailBlockedUntil
	}

	function isBlocked() {
		if (type === SubItemType.Email) return domainSetting.isEmailBlocked
		if (type === SubItemType.Popup) return domainSetting.isPopupBlocked
		if (type === SubItemType.Password) return domainSetting.isPasswordEnabled(currentUser?.allowPasswordOption)
		if (type === SubItemType.Consent) return domainSetting.isConsentEnabled(currentUser?.allowConsent)
		if (type === SubItemType.GPC) return domainSetting.isGPCEnabled(currentUser?.allowGPC)
		return false
	}

	async function handleSave(value) {
		const { emailOption, popupOption, passwordOption, allowConsent, allowGPC } = domainSetting
		// console.log(emailOption, popupOption)
		let params = {}
		if (type === SubItemType.Email) params = popupOption === 0 ? { emailOption: value, popupOption } : { emailOption: value }
		if (type === SubItemType.Popup) params = emailOption === 0 ? { emailOption, popupOption: value } : { popupOption: value }
		if (type === SubItemType.Password) params = { ...params, passwordOption: value }
		if (type === SubItemType.Consent) params = { ...params, allowConsent: value }
		if (type === SubItemType.GPC) params = { ...params, allowGPC: value }

		// console.log(params)
		if (domainSetting.manuallyAdded) {
			const { error } = await DomainSetting.add(domainSetting.domain)
			if (error) {
				setError(error)
				return
			}
		}
		const { error, results = [] } = await DomainSetting.update([domainSetting.domain], params)
		if (error) {
			setError(error)
			return
		}
		else {
			if (type === SubItemType.Email) {
				if (emailOption == 0) {
					Segment.track(Segment.Event.removingDomainBlockForEmails, currentUser?.id, params)
				}
				else {
					Segment.track(Segment.Event.editingDomainBlockForEmails, currentUser?.id, params)
				}
			}
			if (type === SubItemType.Popup) {
				if (popupOption == 0) {
					Segment.track(Segment.Event.removingDomainBlockForPopup, currentUser?.id, params)
				}
				else {
					Segment.track(Segment.Event.editingDomainBlockForPopup, currentUser?.id, params)
				}
			}
			if (type === SubItemType.Password) {
				Segment.track(Segment.Event.enablingAccountPasswordUpdatesBySender, currentUser?.id, params)
			}
			if (type === SubItemType.Consent) {
				Segment.track(Segment.Event.editingConsentBySender, currentUser?.id, params)
			}
		}

		onChange(results[0])
	}

	async function handleChange(value) {
		if (type === SubItemType.Email) {
			value = value === false ? -1 : 0;
			const { errorCode } = await DomainSetting.checkAvailability([domainSetting.domain])
			if (errorCode === errorCodes.DS_OVERRIDE_ALLOW_FILTER) {
				setAlert({
					message: errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER,
					buttons: [
						{ type: 'positive', title: 'Confirm', onClick: () => handleSave(value) },
						{ type: 'negative', title: 'Cancel', onClick: () => resetAlert() },
					]
				})
				return
			}
		}

		handleSave(value)
	}

	function handleUnblock() {
		if (type === SubItemType.Password) handleSave(null)
		else if (type === SubItemType.Consent) handleSave(false)
		else handleSave(0)
	}

	const [isCrxInstalled, setCrxInstalled] = React.useState(true)
	React.useEffect(() => {
		setCrxInstalled(currentUser && currentUser.isExtensionInstalled)
	}, [currentUser])

	return (
		<>
			<div className={`domain-list-sub-item`}>
				<div className="info">
					<Checkbox
						iconUnchecked={iconUnchecked}
						iconChecked={iconChecked}
						checked={selected}
						enabled={(type === SubItemType.Popup || type === SubItemType.GPC) ? isCrxInstalled : true}
						onChange={() => onSelect(!selected)} />
					{type === SubItemType.Email && <div className="description">Allow emails from {domainSetting.domain}</div>}
					{type === SubItemType.Popup && <div className={`description ${!isCrxInstalled && 'disabled'}`}>Browser pop-up notifications</div>}
					{type === SubItemType.Password && <div className='description'>Account & password update emails</div>}
					{type === SubItemType.Consent && <div className='description'>Publisher consent</div>}
					{type == SubItemType.GPC && <div className={`description ${!isCrxInstalled && 'disabled'}`}>Global Privacy Control</div>}
					<div className="status">
						{type === SubItemType.Email &&
							<React.Fragment>
								<div className='status-label'>{value === 0 ? 'On' : 'Off'}</div>
								<Switch
									value={value === 0}
									onChange={(value) => handleChange(value)} />
							</React.Fragment>
						}
						{type === SubItemType.Popup &&
							<React.Fragment>
								<Select
									keepLabel
									label={(value == 0 ? ('Default' + (currentUser?.allowDomainNotification ? ' (On)' : ' (Off)')) : DomainSetting.isBrowserPopupEnabled(popupOption, currentUser?.allowDomainNotification) ? 'On' : 'Off')}
									value={value}
									onChange={(value) => handleChange(value)}
									disabled={!isCrxInstalled}>
									{Object.keys(DomainSetting.PopupOption).map((key, index) => {
										const value = DomainSetting.PopupOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == 0 ? currentUser?.allowDomainNotification ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
							</React.Fragment>
						}
						{type === SubItemType.Password &&
							<React.Fragment>
								<Select
									keepLabel
									label={(value == null ? ('Default' + (currentUser?.allowPasswordOption ? ' (On)' : ' (Off)')) : DomainSetting.isPasswordEnabled(passwordOption, currentUser?.allowPasswordOption) ? 'On' : 'Off')}
									value={value}
									onChange={(value) => handleChange(value)}>
									{Object.keys(DomainSetting.PasswordOption).map((key, index) => {
										const value = DomainSetting.PasswordOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowPasswordOption ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
							</React.Fragment>
						}
						{type === SubItemType.Consent &&
							<React.Fragment>

								<Select
									keepLabel
									label={(value == null ? ('Default' + (currentUser?.allowConsent ? ' (On)' : ' (Off)')) : DomainSetting.isConsentEnabled(allowConsent, currentUser?.allowConsent) ? 'On' : 'Off')}
									value={value}
									onChange={(value) => handleChange(value)}>
									{Object.keys(DomainSetting.ConsentOption).map((key, index) => {
										const value = DomainSetting.ConsentOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowConsent ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>

							</React.Fragment>
						}
						{type === SubItemType.GPC &&
							<React.Fragment>
								<Select
									keepLabel
									label={(value == null ? ('Default' + (currentUser?.allowGPC ? ' (On)' : ' (Off)')) : DomainSetting.isGPCEnabled(allowGPC, currentUser?.allowGPC) ? 'On' : 'Off')}
									value={value}
									onChange={(value) => handleChange(value)}
									disabled={!isCrxInstalled}>
									{Object.keys(DomainSetting.GPCOption).map((key, index) => {
										const value = DomainSetting.GPCOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? DomainSetting.isGPCEnabled(allowGPC, currentUser?.allowGPC) ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
							</React.Fragment>
						}
					</div>
				</div>
			</div>
			{isConfirmOverride &&
				<InfoAlert
					message={errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER}
					buttons={[
						{
							title: 'Confirm',
							type: 'positive',
							onClick: () => {
								handleSave(value)
								setConfirmOverride(false)
							}
						}, {
							title: 'Cancel',
							type: 'negative',
							onClick: () => setConfirmOverride(false)
						},]}
					onClose={() => setConfirmOverride(false)}
				/>
			}
		</>
	)
}