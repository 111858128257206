import React from 'react'

import './styles.scss'
import iconUpload from 'assets/images/upload.svg'
import iconClear from 'assets/images/cross/gray-16.svg'

import {
	Loader,
	SearchField,
	Pagination,
	TableBatchContainer, ImagePicker, BadgeButton,
	Switch,
} from 'components'
import { ListItem } from './list-item'

import {
	useAlert,
	useAuth,
	AdminSenderProvider, useAdminSenders, AdminSender
} from 'core'
import { ArrayHelper } from 'utils'

export function AvatarEl({
	className = '',
}) {
	const { setError } = useAlert()
	const { currentUser } = useAuth()
	const { isLoading, totalCount, senders, adminSenderSearch } = useAdminSenders()

	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(5)
	const [sortBy, setSortBy] = React.useState("account")

	/** @type {[Array<AdminSender>, Function]} */
	const [selectedSenders, setSelectedSenders] = React.useState([])
	React.useEffect(() => { setSelectedSenders(senders.filter(x => selectedSenders.some(y => y.email === x.email))) }, [senders])
	const [batchHasWhite, setBatchHasWhite] = React.useState(false)
	React.useEffect(() => {
		setBatchHasWhite(ArrayHelper.isValid(selectedSenders) && !selectedSenders.some(x => !x.hasWhite))
	}, [selectedSenders])

	React.useEffect(() => {
		loadSenders()
	}, [currentUser, searchText, pageIndex, pageSize, sortBy])

	function loadSenders() {
		if (!currentUser?.isAdmin) return

		adminSenderSearch({ searchText, pageIndex, pageSize })
	}

	/** @param {AdminSender} sender */
	function handleSelectSender(sender) {
		if (!sender?.email) return

		if (selectedSenders.some(x => x.email === sender.email)) {
			setSelectedSenders(selectedSenders.filter(x => x.email !== sender.email))
		} else {
			setSelectedSenders([...selectedSenders, sender])
		}
	}

	async function handleBatchWhite(value) {
		const { error } = await AdminSender.setWhiteToSenders(selectedSenders, value)
		if (error) {
			setError(error)
			return
		}

		loadSenders()
	}

	async function uploadAvatar(file) {
		const { error, results } = await AdminSender.uploadFiles([file])

		if (error) {
			setError(error)
			return null
		}

		return ArrayHelper.isValid(results) ? results[0] : null
	}

	async function handleUploadAvatar(file) {
		const url = await uploadAvatar(file)
		if (!url) {
			setLoading(false)
			return
		}

		const { error } = await AdminSender.updateAvatar(selectedSenders, url)

		if (error) {
			setError(error)
			return
		}

		loadSenders()
	}

	async function handleDeleteAvatar() {
		const { error } = await AdminSender.deleteAvatar(selectedSenders)

		if (error) {
			setError(error)
			return
		}

		loadSenders()
	}

	return (
		<div className='admin-senders'>
			<div className="total">Total: {totalCount}</div>
			<div className='header'>
				<TableBatchContainer
					selected={ArrayHelper.isValid(selectedSenders)}
					onSelectAll={(value) => setSelectedSenders(value ? senders : [])}>
					<Switch
						value={batchHasWhite}
						onChange={(value) => handleBatchWhite(value)} />
					<ImagePicker
						onSelect={(file) => handleUploadAvatar(file)} >
						<BadgeButton
							icon={iconUpload}
							description="Upload avatar" />
					</ImagePicker>
					<BadgeButton
						icon={iconClear}
						description="Delete avatar"
						onClick={() => handleDeleteAvatar()} />
				</TableBatchContainer>
				<SearchField
					value={searchText}
					onChange={value => setSearchText(value)} />
				{/* <Select
					className="view-options"
					value={sortBy}
					onChange={(value) => setSortBy(value)}>
					<SelectOption>Newest</SelectOption>
					<SelectOption>Most Accounts</SelectOption>
					<SelectOption>Replaced Image</SelectOption>
				</Select> */}
			</div>
			<div className='list'>
				<ListItem isHeader />
				{isLoading ? <Loader /> :
					senders.map((sender, index) => {
						const selected = selectedSenders.some(x => x.email === sender.email)
						return (
							<ListItem
								key={index}
								sender={sender}
								selected={selected}
								onSelect={() => handleSelectSender(sender)} />
						)
					})
				}
			</div>
			<Pagination
				className="admin-senders-pagination"
				totalSize={totalCount}
				pageIndex={pageIndex}
				pageSize={pageSize}
				onPageIndex={value => setPageIndex(value)}
				onPageSize={value => setPageSize(value)}
			/>
		</div>
	)
}

export function AdminAvatarEl() {
	return (
		<AdminSenderProvider>
			<AvatarEl />
		</AdminSenderProvider>
	)
}