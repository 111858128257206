import psl from 'psl'

import { ObjectHelper } from './object-helper'

function isDomain(value) {
	return ObjectHelper.isValid(psl.get(value))
}

function parseDomain(value = "") {
	if (value.includes('@')) {
		value = value.split('@')[1]
	}
	const result = psl.get(value)
	// console.log(result)
	return result
}

function parseDomainName(domain) {
	const domainName = parseDomain(domain)
	let realDomainName = domainName.split('.')[0]
	const result = realDomainName.length >= 2 ? (realDomainName.charAt(0).toUpperCase() + realDomainName.slice(1)) : realDomainName.toUpperCase()
	return result
}

export const DomainHelper = {
	isDomain, parseDomain, parseDomainName
}