import React from 'react'

import './styles.scss'
import iconSunglass from 'assets/images/referral-sunglass.png'
import iconHat from 'assets/images/referral-hat.png'
import iconGiftCard from 'assets/images/referral-gift-card.png'

const sampleData = [
	{ label: "lockrMail Sunglasses", count: 2, icon: iconSunglass },
	{ label: "lockrMail Hat", count: 5, icon: iconHat },
	{ label: "Amazon Gift Card", count: 10, icon: iconGiftCard },
]

export function ReferralsList({ }) {
	return (
		<div className="referrals-list">
			{sampleData.map((data, index) =>
				<div key={index} className="referrals-list-item">
					<div className="info-container">
						<div className="label">{data.label}</div>
						<div className="count">
							<span>{data.count}</span>
							<span>Referrals</span>
						</div>
					</div>
					<div className="icon-container">
						<img src={data.icon} alt="" />
					</div>
				</div>
			)}
		</div>
	)
}