import React from 'react';
import $ from 'jquery';

import './styles.scss';

import { useViewDigestArchive } from 'core';

export function DigestArchivesMailPreview({ }) {
	const { currentMail } = useViewDigestArchive();

	const iframeRef = React.useRef();

	const [content, setContent] = React.useState(null);
	React.useEffect(() => {
		applyContent();
	}, [currentMail]);

	const [isLoaded, setLoaded] = React.useState(false);
	React.useEffect(() => {
		setLoaded(false);
	}, [content]);

	async function applyContent() {
		if (!currentMail?.content) return;

		const html = document.createElement('html');
		html.innerHTML = currentMail.content;

		await new Promise((resolve, reject) => {
			try {
				const anchors = html.querySelectorAll('a');
				if (anchors.length > 0)
					anchors.forEach((anchor, index) => {
						anchor.setAttribute('target', '_blank');
						if (index === anchors.length - 1) {
							resolve();
						}
					});
				else { resolve() }
			} catch (error) {
				reject(error);
			}
		});

		setContent(html.innerHTML);
	}

	async function handleIframeLoad() {
		if (!iframeRef.current) return

		setLoaded(true)

		const iframe = iframeRef.current

		const magnifyingPower = iframe.contentWindow.document.body.clientWidth / iframe.contentWindow.document.body.scrollWidth
		iframe.style.height = iframe.contentWindow.document.body.scrollHeight * magnifyingPower + "px"
		iframe.contentWindow.document.body.style.webkitTransform = `scale(${magnifyingPower})`
		iframe.contentWindow.document.body.style.webkitTransformOrigin = `0 0`
		iframe.contentWindow.document.body.style.height = iframe.contentWindow.document.body.scrollHeight * magnifyingPower + "px"
	}

	return (
		<div className="digest-archives-mail-preview">
			<div className={`content-wrapper ${isLoaded && 'loaded'}`}>
				<iframe
					ref={iframeRef}
					srcDoc={content}
					style={{ width: '100%' }}
					onLoad={() => handleIframeLoad()}
				></iframe>
			</div>
		</div>
	);
}
