import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const usePrevious = (value) => {
	const ref = React.useRef()
	React.useEffect(() => { ref.current = value })
	return ref.current
}

export const useLocationChange = (action) => {
	const location = useLocation()
	const prevLocation = usePrevious(location)
	React.useEffect(() => {
		action(location, prevLocation)
	}, [location, prevLocation])
}

export const useAtlasButtonHandler = () => {
	const location = useLocation();

	React.useEffect(() => {
		let retryCount = 0;
		const maxRetries = 6;
		let intervalId = null;

		const clickCloseButton = () => {
			const shadowHost = document.querySelector('#atlas-onboarding-widget-root');
			if (!shadowHost) return false;

			const shadowRoot = shadowHost.shadowRoot;
			if (!shadowRoot) return false;

			const closeButton = shadowRoot.getElementById('atlas-onboarding-widget-modal-close-button');
			if (closeButton) {
				closeButton.click();
				return true;
			}

			const instructionsElem = shadowRoot.querySelector('#atlas-onboarding-widget-instructions');
			if (instructionsElem) {
				const innerCloseButton = instructionsElem.querySelector('button[aria-label="Close"]');
				if (innerCloseButton) {
					innerCloseButton.click();
					return true;
				}
			}

			return false;
		};

		const tryClickButtonWithRetry = () => {
			if (clickCloseButton() || retryCount >= maxRetries) {
				clearInterval(intervalId);
			} else {
				retryCount++;
			}
		};

		if (location.pathname !== '/dashboard') {
			// First attempt to click the button
			if (!clickCloseButton()) {
				// If not successful, set an interval to retry
				intervalId = setInterval(tryClickButtonWithRetry, 500);
			}
		}

		return () => {
			if (intervalId !== null) {
				clearInterval(intervalId);
			}
		};
	}, [location.pathname]);
};

export const useWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		window.addEventListener("resize", handleResize)
		return (() => window.removeEventListener("resize", handleResize))
	}, [])
	function handleResize() {
		setWidth(window.innerWidth)
	}
	return {
		width
	}
}

