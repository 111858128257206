import React from "react";
import './styles.scss'
import { DomainGroupModal } from "components/alert/admin-domain-group-modal";
import { useAlert } from "core";
import deleteIcon from '../../../../../assets/images/delete/gray.svg'
import { DomainGroups } from "core/models/admin/domaingroups";
import { useDomainGroupListContext } from "core/providers/admin/domain-group-list";

export function DomainGroupListItem({ domainObj }) {
    const [domainGroupModal, setDomainGroupModal] = React.useState(false)
    const { setAlert } = useAlert()
    const { isLoading, domainGroupList, getDomainGroupList, addNewDomainGroup } = useDomainGroupListContext();
    return (
        <>
            <div className="domain-group-list-item" onClick={() => { setDomainGroupModal(true) }}>
                {domainObj.groupName}
                <div style={{ cursor: 'pointer' }}><img onClick={(event) => {
                    event.stopPropagation()
                    setAlert({
                        message: "Are you sure you want to delete the domain group?",
                        buttons: [
                            {
                                title: 'Confirm',
                                type: 'positive',
                                onClick: async () => {
                                    await DomainGroups.deleteDmainGroup({ domainGroupId: domainObj.id })
                                    getDomainGroupList();
                                }
                            }
                        ]
                    })
                }} style={{ height: 20, width: 20 }} src={deleteIcon}></img></div>


            </div>
            {domainGroupModal && <DomainGroupModal domainGroupId={domainObj.id} title={domainObj.groupName} onClose={() => setDomainGroupModal(false)} />}
        </>
    )
}