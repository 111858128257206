import React from 'react'

import { useAlert } from 'core/providers'
import { AdminUser } from 'core/models'

const AdminDeletedUsersContext = React.createContext({})

export function AdminDeletedUsersProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [deletedUsers, setDeletedUsers] = React.useState([])
	const [params, setParams] = React.useState({})

	async function adminDeletedUsersSearch(params, loading = true) {
		setParams(params)

		if (loading) setLoading(true)
		const { error, count, results } = await AdminUser.getDeletedUsers(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setDeletedUsers(results)
		setTotalCount(count)
	}

	async function adminDeletedUsersReload() {
		adminDeletedUsersSearch(params, false)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		deletedUsers,
		totalCount,

		adminDeletedUsersSearch,
		adminDeletedUsersReload,
	}), [isLoading, deletedUsers, totalCount])

	return (
		<AdminDeletedUsersContext.Provider value={memoedValue}>
			{children}
		</AdminDeletedUsersContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, totalCount: number, deletedUsers: Array<AdminUser>, adminDeletedUsersSearch: Function, adminDeletedUsersReload: Function}} UseAdminDeletedUsers
 * @returns {UseAdminDeletedUsers}
 */
export function useAdminDeletedUsers() {
	return React.useContext(AdminDeletedUsersContext)
}