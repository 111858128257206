import { User } from 'core';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { redirectTo, routes } from 'routes';
import { base64Decode } from 'utils';

export function LockrScanUnsubscribeDeepLink() {
    const params = useParams();
    const navigate = useNavigate();
    React.useEffect(() => {
        let decodedEmail = base64Decode(params.encryptedEmail)
        console.log(decodedEmail)
        navigate(routes.LockrScanDashboard, { state: { params: { email: decodedEmail, unsubscribe: true } } })

    }, [])
    return <></>
}