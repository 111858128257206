import React from 'react'

import { LargeTextField } from './large'
import { NormalTextField } from './normal'

export const TextField = React.forwardRef((props, ref) => {
	const { type = "normal" } = props

	return (
		<>
			{type === "large" &&
				<LargeTextField ref={ref} {...props} />
			}
			{type === "normal" &&
				<NormalTextField ref={ref}  {...props} />
			}
		</>
	)
})