import React from 'react'

import './styles.scss'

export function AlertTitle({ className = '', value, children }) {
	return (
		<div className={`alert-title ${className}`}>
			{value}
			{children}
		</div>
	)
}