import React from "react";
import { Alert, AlertPage, AlertTitle, BlueButton } from 'components';
import './styles.scss';

import { UseAlert, useAlert } from "core";

/**
 * @typedef {{onClose: Function, onUpdateDashboardSettings: Function}} SaveDigestModalProps
 * @param {SaveDigestModalProps} param0
 */
export function SaveDigestModal({ onClose, onUpdateDashboardSettings }) {

    const { alert, error } = useAlert();

    return (
        <Alert
            containerClassName = 'save-digest-modal-wrapper'
            show={!alert && !error}
            onCancel={onClose}
        >
            <AlertPage>
                <AlertTitle>Save Digest</AlertTitle>
                <div className="save-digest-modal-subtitle-wrapper">
                    <div>
                        <span className="save-digest-modal-subtitle">These Senders have now been added to a Digest.</span>
                    </div>
                    <div>
                        <span className="save-digest-modal-subtitle">To limit email delivery to this digest only, we recommend setting each Sender to Block on your dashboard.</span>
                    </div>
                </div>
                <div className="save-digest-modal-button-wrapper">
                    <BlueButton onClick={() => {
                        onUpdateDashboardSettings();
                    }}>Save & update dashboard settings</BlueButton>
                    <div className="save-digest-modal-button-space" />
                    <div onClick={() => {
                        onClose();
                    }} className="save-digest-no-button">No Thanks</div>
                </div>
            </AlertPage>
        </Alert>
    )
}