import React from 'react'

import './styles.scss'

export function AlertButtonContainer({
	className = "",
	children
}) {
	return (
		<div className={`alert-button-container ${className}`}>
			{children}
		</div>
	)
}