import React from 'react'

import {
    Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton, AlertInput,
} from '../../components'

export function DeleteLockrScanAlert({
    requiredCode = "DELETE",
    onDelete = () => { },
    onCancel = () => { }
}) {

    const [step, setStep] = React.useState(0)
    const [code, setCode] = React.useState('')

    return (
        <Alert step={step} onCancel={() => onCancel()}>
            <AlertPage>
                <AlertTitle value="Just a minute..." />
                <AlertMessage value="Are you sure you want to delete your lockrScan account? <br><br>If you are sure, confirm below." />
                <AlertButtonContainer>
                    <AlertButton type="positive" onClick={() => setStep(1)} >Delete</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
            <AlertPage>
                <AlertTitle value="Delete account" />
                <AlertMessage>Please confirm by typing DELETE into the field below:</AlertMessage>
                <AlertInput value={code} onChange={value => setCode(value)} />
                <AlertButtonContainer>
                    <AlertButton type="positive" disabled={code !== requiredCode} onClick={() => onDelete()}>Delete</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}