import React from 'react'

import { AdminTractionEl } from './traction'
import { AdminDomainsEl } from './domains'

export function AdminStatisticsEl({ }) {
	return (
		<React.Fragment>
			<AdminTractionEl />
			<AdminDomainsEl />
		</React.Fragment>
	)
}