import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './styles.scss'

import {
    Navigation,
    Tab, TabItem,
} from 'components'
import { AdminStatisticsEl } from './statistics'
import { AdminUsersEl } from './users'
import { AdminMarketingEl } from './marketing'
import { AdminWebKit } from './webkit'

import { routes, redirectTo } from 'routes'
import { LockrScanAdminTractionEl } from './statistics/traction/lockrScan-index'
import { AdminCurrentUsersEl } from './users/current-users'
import { LockrScanAdminCurrentUsersEl } from './users/current-users/lockrScan-index'
import { LockrScanAdminDomainsEl } from './statistics/domains/lockrScan-index'
import { LockrScanUnregisteredUserEl } from './users/unregistered-users/lockrscan-index'

const Category = routes.Category.Admin

export function LockrScanAdminScene(props) {
    const navigate = useNavigate()
    const { category = Category.Statistics } = useParams()

    return (
        <div id="parent" className="page-container">
            <Navigation />
            <div className="admin">
                <div className="admin-wrapper">
                    {/* <Tab
                        value={category}
                        onSelect={(value) => { navigate(redirectTo({ route: routes.Admin, category: value })) }}>
                        {Object.keys(Category).map((category, index) =>
                            <TabItem key={Category[category]} value={Category[category]}>{category}</TabItem>
                        )}
                    </Tab>
                    {category === Category.Statistics &&
                        <AdminStatisticsEl />
                    }
                    {category === Category.Users &&
                        <AdminUsersEl />
                    }
                    {category === Category.Marketing &&
                        <AdminMarketingEl />
                    }
                    {category === Category.WebKit &&
                        <AdminWebKit />
                    } */}
                    <LockrScanAdminTractionEl />
                    <LockrScanAdminCurrentUsersEl />
                    <LockrScanUnregisteredUserEl></LockrScanUnregisteredUserEl>
                    <LockrScanAdminDomainsEl />
                </div>
            </div>
        </div>
    )
}

export * from './marketing/discovery'