import _ from 'lodash'

import { isValidText } from './helper'
import { ArrayHelper } from './array-helper'

function is(val) {
	return val !== null && typeof val === 'object'
}

function isValid(val, property = null) {
	if (val === null || val === undefined) return false
	if (!isValid(property)) return true
	return isValid(value(val, property))
}


function value(val, path = null) {
	if (!isValid(val)) return null
	if (!path) return val

	if (!path.includes('.')) return val[path]

	const curPath = path.split('.')[0]
	if (curPath === path) return val[path]
	return value(val[curPath], path.replace(`${curPath}.`, ''))
}

function equals(a, b) { return _.isEqual(a, b) }

function copy(val) {
	if (val === null || val === undefined) return null
	return _.cloneDeep(val)
}

function merge(a, b) { return _.cloneDeep(_.merge(a, b)) }

export const ObjectHelper = {
	is, isValid, value, equals,
	copy, merge,
}