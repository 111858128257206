import React from 'react'
import { useWindowSize } from 'react-use'

import { SelectorItemEl } from './item'
import { SelectorOption } from './core'
import { ObjectHelper } from '../../../utils'
import { zIndexes } from '../../../constants'

export function SelectorMenuEl({
	options,
	multiselect = false,
	bulkEnabled = false,
	value,
	style = {},
	responsive = {},
	onChange = (value) => { },
	onClose = () => { }
}) {
	const selfRef = React.useRef()
	const wrapperRef = React.useRef()

	const { width } = useWindowSize()

	const { breakpoint } = responsive

	React.useEffect(() => {
		if (wrapperRef.current) {
			wrapperRef.current.focus()
		}

		if (selfRef.current) {
			selfRef.current.parentElement.style.position = 'relative'
		}

		window.addEventListener('click', handleWindowClick, false)
		return () => {
			window.removeEventListener('click', handleWindowClick, false)
			resetTabIndex()
		}
	}, [])

	const handleWindowClick = (event) => {
		if (!event.target.closest('.selector-menu-wrapper')) {
			onClose()
		}
	}

	const optionEls = () => Array.from(wrapperRef.current ? wrapperRef.current.querySelectorAll('.selector-option:not(.has-sub-options)') : [])
	function resetTabIndex() {
		optionEls().forEach(e => {
			e.removeAttribute('tabindex')
		})
	}

	function handleKeyDown(event) {
		if (event.keyCode === 9) {
			onClose()
			return
		}

		if (event.keyCode !== 38 && event.keyCode !== 40) return

		event.preventDefault()
		event.stopPropagation()

		const children = optionEls()
		if (children.length <= 0) return

		const currentIndex = children.findIndex(e => e.tabIndex === 0)
		let nextIndex = event.keyCode === 38 ? Math.max(currentIndex - 1, 0) : Math.min(currentIndex + 1, children.length - 1)
		children.forEach((e, index) => {
			if (nextIndex === index) {
				e.tabIndex = 0
				e.focus()
			} else {
				e.tabIndex = -1
			}
		})
	}

	function handleItemClick(val) {
		if (multiselect && ObjectHelper.isValid(value)) {
			if (value.includes(val)) onChange(value.filter(e => e !== val))
			else onChange([...value, val])
		} else {
			onChange(val)
		}
		onClose()
	}

	function handleBulkSelect(selected) {
		if (selected) onChange(options.map(e => e.value))
		else onChange([])
		onClose()
	}

	return (
		<div
			ref={selfRef}
			className={`selector-menu ${(width >= breakpoint) ? 'desktop' : 'mobile'}`}
			style={{ ...style, zIndex: zIndexes.Menu }}
			onClick={event => {
				event.stopPropagation()
				onClose()
			}}>
			<div
				ref={wrapperRef}
				className="selector-menu-wrapper"
				tabIndex="0"
				onKeyDown={handleKeyDown}
				onFocus={event => event.stopPropagation()}
				onClick={event => event.stopPropagation()}>
				{(multiselect && bulkEnabled) &&
					<>
						<SelectorItemEl
							className="bluk-option"
							option={new SelectorOption({ label: 'Select All', onClick: () => handleBulkSelect(true) })} />
						<SelectorItemEl
							className="bluk-option"
							option={new SelectorOption({ label: 'Deselect All', onClick: () => handleBulkSelect(false) })} />
					</>
				}
				{options.map((e, index) =>
					<SelectorItemEl
						key={index}
						disabled={e.disabled}
						option={e}
						selected={value && value === e.value}
						onClick={(val) => handleItemClick(val)} />
				)}
			</div>
		</div>
	)
}
