import { NewGroup } from "components/alert/new-group";
import { useAlert } from "core";
import { DomainGroupListProvider, useDomainGroupListContext } from "core/providers/admin/domain-group-list";
import React from "react";
import { DomainGroupListItem } from "./domain-group-list-item";
import { BlueButton } from "components";
import './styles.scss'

export const DomainGroups = () => {
    const [addGroupAlertOpen, setAddGroupAlertOpen] = React.useState();
    const { isLoading, domainGroupList, getDomainGroupList, addNewDomainGroup } = useDomainGroupListContext();
    React.useEffect(() => {
        getDomainGroupList();
    }, [])


    return <div>
        <div className="admin-group">
            <div className="domain-group-list-header">
                <div className="admin-group-title">Groups</div>
                <div><BlueButton onClick={() => { setAddGroupAlertOpen(true) }} className="domain-group-list-add-button">Add</BlueButton></div>
            </div>
        </div>
        <div style={{ background: '#171717', height: '100%', width: '100%', marginTop: 30, minHeight: 300 }}>

            <div style={{ borderBottom: '1px solid #26282A', height: 40, alignItems: 'center', display: 'flex', color: '#8B8B8B', paddingLeft: 16 }}>Groups</div>

            {domainGroupList?.map((domain) => (<DomainGroupListItem domainObj={domain} />))}
        </div>

        {addGroupAlertOpen && <NewGroup onClose={() => { setAddGroupAlertOpen(false) }} />}
    </div>
}

export function AdminDomainGroupEl() {
    return (
        <DomainGroupListProvider>
            <DomainGroups />
        </DomainGroupListProvider>
    )
}
