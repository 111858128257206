import React from "react"
import { NavLink } from 'react-router-dom'

import './styles.scss'

import iconLogo from "../../assets/images/lockr-logo.svg"
import iconHamburger from "../../assets/images/hamburger.svg"
import { config, keyCodes } from "../../constants"

export function AppHeader({
	className = '',
	isPublic = false,
	disabled = false,
	children,
	onLogo = () => { },
	onMenu = () => { }
}) {
	function handleHamburger(event) {
		event.preventDefault()
		event.stopPropagation()

		onMenu()
	}

	function handleHamburgerKeyDown(event) {
		event.preventDefault()
		event.stopPropagation()

		if (event.keyCode === keyCodes.Enter) onMenu()
	}

	function handleLogoClick(event) {
		if (disabled) event.preventDefault()
		onLogo()
	}

	return (
		<div id="app-header" className={`app-header ${className}`}>
			<div className="app-header-content">
				{isPublic ? <a href={config.LOCKR_URL} onClick={handleLogoClick}><img src={iconLogo} alt="Lockr.io" /></a> :
					<a href="/" onClick={handleLogoClick}><img src={iconLogo} alt="Lockr.io" /></a>
				}
				{children}
				<div className="app-header-content-hamburger" role="button" tabIndex="0" onClick={handleHamburger} onKeyDown={handleHamburgerKeyDown}>
					<img src={iconHamburger} alt="Menu" />
				</div>
			</div>
		</div>
	)
}

export function AppHeaderRightContainer(props) {
	const { children } = props

	return (
		<div className="app-header-content-right">
			{children}
		</div>
	)
}

export function AppHeaderRightItem({
	className = '',
	label,
	url,
	path,
	leftIcon,
	rightIcon,
	disabled = false,
	onClick = () => { }
}) {

	function handleClick(event) {
		if (event) {
			if (disabled) event.preventDefault()
			event.stopPropagation()
		}
		onClick()
	}

	function handleKeyDown(event) {
		if (event) {
			if (disabled) event.preventDefault()
			event.stopPropagation()
		}

		if (event.keyCode === keyCodes.Enter) onClick()
	}

	function renderContent() {
		if (path) return (
			<NavLink className={className} to={path} onClick={handleClick}>
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</NavLink>
		)

		if (url) return (
			<a className={className} target="_blank" rel="noreferrer" href={url}>
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</a>
		)

		return (
			<>
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</>
		)
	}

	return (
		<div className="app-header-item" role="button" tabIndex="0" onClick={handleClick} onKeyDown={handleKeyDown}>
			{renderContent()}
		</div>
	)
}
