import React, { useEffect } from "react";
import { Alert, AlertPage, AlertTitle, BlueButton } from 'components';
import DigestFlowSearchIcon from '../../../assets/images/digest-flow-scratch.svg'
import DigestFlowGroupIcon from '../../../assets/images/digest-create-group-icon.svg'
import './styles.scss'

import { useAlert, useSenderGroup } from "core";

export const DIGEST_FLOWS = {
    SCRATCH: 'SCRATCH',
    GROUP: 'GROUP'
}

/**
 * @typedef {{isFromGroup: boolean, onClose: Function, onFlowSelected: Function}} DigestModalProps
 * @param {DigestModalProps} param0
 */
export function DigestFlowSelectorModal({
    isFromGroup, onClose, onFlowSelected
}) {

    const { senderGroups } = useSenderGroup();
    const { alert, error } = useAlert();
    const [selectedMode, setSelectedMode] = React.useState(isFromGroup ? DIGEST_FLOWS.GROUP : DIGEST_FLOWS.SCRATCH);

    useEffect(() => {
        if (!senderGroups.length) {
            onClose();
            onFlowSelected(DIGEST_FLOWS.SCRATCH);
        }
    }, [])

    if (senderGroups.length) {
        return (
            <Alert
                containerClassName='new-digest-modal'
                wrapperClassName={'modal-content-wrapper'}
                show={!alert && !error}
                onCancel={onClose}
            >
                <AlertPage>
                    <AlertTitle>New Digest</AlertTitle>
                    <div className='content-wrapper'>
                        <div onClick={() => {
                            setSelectedMode(DIGEST_FLOWS.SCRATCH)
                        }} style={{
                            border: selectedMode === DIGEST_FLOWS.SCRATCH ? `1px solid white` : `1px solid transparent`
                        }} className="flow-content scratch-container">
                            <div>
                                <img src={DigestFlowSearchIcon} />
                            </div>
                            <span className='flow-content-text'>Create from scratch</span>
                        </div>
                        <div onClick={() => {
                            setSelectedMode(DIGEST_FLOWS.GROUP)
                        }} style={{
                            border: selectedMode === DIGEST_FLOWS.GROUP ? `1px solid white` : `1px solid transparent`
                        }} className="flow-content .group-container">
                            <div>
                                <img src={DigestFlowGroupIcon} />
                            </div>
                            <span className='flow-content-text'>Create from group</span>
                        </div>
                    </div>
                    <div className="save-container">
                        <BlueButton onClick={() => {
                            onFlowSelected(selectedMode)
                        }}>Next</BlueButton>
                    </div>
                </AlertPage>
            </Alert>
        )
    }
    return <></>
}