import React from 'react'

import './styles.scss'
import iconClear from 'assets/images/cross/gray-16.svg'

import { randomUUID } from 'utils'

export const TextArea = React.forwardRef(({
	id = randomUUID(),
	className = '',
	placeholder,
	clearable = false,
	disabled = false,
	autoFocus = false,
	value,
	onChange = (event) => { },
}, ref) => {
	const containerRef = React.useRef()

	React.useEffect(() => { ref?.current?.focus() }, [autoFocus])

	function handleChange(event) {
		onChange(event)
	}

	function handleClear() {
		onChange('')
		ref?.current?.focus()
	}

	return (
		<div ref={containerRef} className={`${className} text-area`}>
			<textarea
				ref={ref}
				id={id}
				placeholder=" "
				alt={id}
				value={value}
				autoFocus={autoFocus}
				onChange={handleChange}
			/>
			<label className='text-area-label' htmlFor={id || "input"}>{placeholder}</label>
			{(clearable && value) &&
				<button className="clear" onClick={() => handleClear()}>
					<img src={iconClear} alt="" />
				</button>
			}
		</div>
	)
})