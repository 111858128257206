import { plainToClass } from 'class-transformer'

export class AdminEmailState {
	total
	changePercent
	verifiedDestinations
	uniqueSenders
	approvedSenders
	blockedReleasedEmails
	outgoingEmails
	blockedSenders
	unapprovedSenders
	timeSaved
	ticks = []

	static fromJSON(json) {
		const result = plainToClass(AdminEmailState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminEmailStateTick.fromJSON(tick.current),
				'previous': AdminEmailStateTick.fromJSON(tick.previous),
				'clicks': AdminEmailClickStateTick.fromJSON(tick.clicks),
				'shipments': AdminEmailClickStateTick.fromJSON(tick.shipments),
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminEmailState.fromJSON(json))
	}
}

export class AdminEmailStateTick {
	from
	to
	processed = 0
	blocked = 0
	redirected = 0
	filtered = 0
	released = 0
	replied = 0
	emailOpened = 0

	static fromJSON(json) {
		return plainToClass(AdminEmailStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminEmailStateTick.fromJSON(json))
	}
}


export class AdminEmailClickStateTick {
	from
	to
	clicked = 0

	static fromJSON(json) {
		return plainToClass(AdminEmailClickStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminEmailClickStateTick.fromJSON(json))
	}
}

export class AdminEmailShipmentStateTick {
	from
	to
	shipment = 0

	static fromJSON(json) {
		return plainToClass(AdminEmailShipmentStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminEmailShipmentStateTick.fromJSON(json))
	}
}