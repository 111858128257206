import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton,
	Loader,
} from 'components'

import { useAlert, DiscoverCompany } from 'core'

const dragItemStyle = (draggableStyle, isDragging) => ({
	...draggableStyle,
	background: isDragging ? '#000000' : 'transparent',
	border: isDragging ? '1px solid #61CEF7' : null,
	borderRadius: '2px'
})

const dragListStyle = (isDraggingOver) => ({})

export function AdminDiscoverOrderModal({
	onClose = (updated) => { }
}) {
	const { error, setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [companies, setCompanies] = React.useState([])
	React.useEffect(() => {
		companies.forEach((company, index) => {
			company.order = index
		})
	}, [companies])

	React.useEffect(() => { loadCompanies() }, [])

	async function loadCompanies() {
		setLoading(true)
		const { error, results } = await DiscoverCompany.search({})
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setCompanies(results)
	}

	function handleDragResult(list, startIndex, endIndex) {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	function handleDragEnd(result) {
		if (!result.destination) { return }
		setCompanies(handleDragResult(companies, result.source.index, result.destination.index))
	}

	async function handleSave() {
		setLoading(true)
		const { error } = await DiscoverCompany.batchUpdate(companies)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		onClose(true)
	}

	return (
		<>
			{!error &&
				<Alert
					className="admin-discover-order-modal"
					onCancel={() => onClose(false)}>
					<AlertPage>
						<AlertTitle>Change Order</AlertTitle>
						<div className="company-list">
							{isLoading ? <Loader /> :
								<DragDropContext onDragEnd={handleDragEnd}>
									<Droppable droppableId="company-list">
										{(provided, snapshot) => (
											<div ref={provided.innerRef} style={dragListStyle(snapshot.isDraggingOver)}>
												{companies.map((company, index) => {
													return (
														<Draggable index={index} key={company.id} draggableId={company.id}>
															{(provided, snapshot) => (
																<div className={`${snapshot.isDragging ? 'dragging' : ''}`} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<div className="company-item" ref={provided.innerRef} style={dragItemStyle(provided.draggableStyle, snapshot.isDragging)}>
																		<span className="order">{index + 1}</span>
																		<img className="avatar" src={company.avatar} alt="" />
																		<div className="info">
																			<div className="title">{company.title}</div>
																			<div className="description">{company.description}</div>
																		</div>
																	</div>
																	{provided.placeholder}
																</div>
															)}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							}
						</div>
						{!isLoading &&
							<AlertButtonContainer>
								<AlertButton onClick={() => handleSave()}>Save</AlertButton>
							</AlertButtonContainer>
						}
					</AlertPage>
				</Alert>
			}
		</>

	)
}