import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Alert, AlertPage, AlertMessage, AlertButtonContainer, AlertButton, AlertTitle
} from '../'
import { errorMessages } from '../../constants'
import { routes, redirectTo } from 'routes'

export function UnsubscriptionAlert({
    onConfirm = () => { },
    onClose = () => { },
    isMulti = false,
}) {






    return (
        <Alert onCancel={() => onClose()}>
            <AlertPage>
                <AlertTitle>Confirm Unsubscription</AlertTitle>
                <AlertMessage>
                    {!isMulti && <>This action will stop all future email from this sender.<br></br><br></br>If you would like to resume your subscription in the future, you may need to revisit the sender’s website and re-enter your information. Please confirm you wish to unsubscribe from this sender.</>}
                    {isMulti && <>This action will stop all future email from these senders.<br></br><br></br>If you would like to resume your subscriptions in the future, you may need to revisit the senders’ websites and re-enter your information. Please confirm you wish to unsubscribe from these senders.</>}
                    {isMulti && <>
                        <br/>
                            <div style={{ color: "#F46666", "margin-top": "20px" }}>
                                NOTE: Multiple windows may open to complete the unsubscribe process on the senders’ websites. Please follow the prompts.
                            </div>
                    </>}
                </AlertMessage>
                <AlertButtonContainer>
                    <AlertButton onClick={() => onConfirm()}>Confirm</AlertButton>
                    <AlertButton type="negative" onClick={() => onClose()}>Cancel</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}