import React from "react"
import { useLocation, useNavigate } from 'react-router-dom'

import './styles.scss'
import iconClose from 'assets/images/onboard_close.svg'
import iconLock from 'assets/images/onboard_lock.svg'

import {
	TextField,
} from 'components'

import { User, useAuth } from 'core'
import { isValidText, Storage } from 'utils'
import { redirectTo, routes } from "routes"

export function TwoStepVerificationScene() {
	const location = useLocation()
	const navigate = useNavigate()

	const { currentUser, userLogout } = useAuth()

	const [code, setCode] = React.useState('')
	const [error, setError] = React.useState(null)
	const [hasResent, setHasResent] = React.useState(false)
	React.useEffect(() => { setError(null) }, [code])

	React.useEffect(() => {
		sendVerifyCode()
	}, [currentUser])

	/** @param {User} user */
	function redirect(user) {
		if (!user) return

		if (user.allow2FA && !user.is2FAPassed) return

		if (location.state?.from) {
			navigate(location.state?.from)
		} else if (user.isAdmin) {
			navigate(redirectTo({ route: routes.Admin }))
		} else if (user.doneTutorial) {
			navigate(redirectTo({ route: routes.Dashboard }))
		} else {
			navigate(redirectTo({ route: routes.Tutorial }))
		}
	}

	async function sendVerifyCode() {
		if (!Storage.accessToken) return false
		if (!currentUser?.allow2FA || !currentUser?.phone) return false

		const { error } = await User.sendVerificationCode(currentUser?.phone)

		if (error) {
			setError(error)
			return false
		}

		return true
	}

	function handleClose(event) {
		event.preventDefault()

		userLogout()

		navigate(redirectTo({
			route: routes.Login,
		}), {
			state: { from: location.state?.from },
		})
	}

	async function handleSubmit() {
		if (!currentUser) return
		const { error } = await User.verifyPhoneNumber(currentUser.phone, code)
		if (error) {
			setError(error)
			return
		}

		currentUser.is2FAPassed = true

		redirect(currentUser)
	}

	async function handlResendCode() {
		if (await sendVerifyCode()) {
			setHasResent(true)
			setTimeout(() => {
				setHasResent(false)
			}, 3000)
		}
	}

	return (
		<div className="two-step-verification">
			<div className="close" onClick={handleClose}>
				<img src={iconClose} alt="Close" />
			</div>
			<div className="container">
				<div className="logo">
					<img src={iconLock} alt="Loc.kr" />
				</div>
				<div className="title">2-step verification</div>
				<div className="message">Please enter the code that we have just sent to your device.</div>
				<div className="form">
					<TextField
						type="large"
						id="code"
						inputType="number"
						autoFocus
						placeholder="Authentication code"
						value={code}
						error={Boolean(error)}
						onChange={event => setCode(event.target.value)}
						onNext={() => handleSubmit()} />
				</div>
				<div className="error">
					{error && error}
				</div>
				<button className={`resend-code ${hasResent && 'has-resent'}`} onClick={() => handlResendCode()}>Resend code {hasResent && <span>(Sent)</span>}</button>
				<div className="button">
					<button type="button" disabled={!isValidText(code)} onClick={() => handleSubmit()}>Submit</button>
				</div>
			</div>
		</div>
	)
}
