import React from 'react'
import { usePrevious } from 'react-use'

import './styles.scss'

import {
	TextField,
	InfoAlert,
	SelectDropDown, SelectOption, Icon,
} from 'components'

import { Sender } from 'core'
import { ArrayHelper } from 'utils'
import { config } from 'constants/config'

let searchTimer = null
export function AddSenderField({
	onSelectSender = (sender) => { },
	removeSenders = []
}) {	
	const inputRef = React.useRef()
	const menuRef = React.useRef()

	const [searchText, setSearchText] = React.useState('')
	React.useEffect(() => {
		callSearchSenders()
	}, [searchText])

	/** @type {[Array<Sender>, Function]} */
	const [searchResults, setSearchResults] = React.useState(null)

	function callSearchSenders() {
		if (searchTimer) {
			clearTimeout(searchTimer)
		}

		searchTimer = setTimeout(() => {
			searchSenders()
			searchTimer = null
		}, 500)
	}

	async function searchSenders() {
		const { results = [] } = await Sender.getSenders({ searchText, getAll: 1 })
		const sorted = ArrayHelper.sort(results || [], 'email')

		const filteredEmailList = sorted.filter(function(senderItem){			
			const index = removeSenders.findIndex(sitem=>{				
				if(senderItem.email === sitem.email){
					return 1
				}
				return 0;
			})						
			return index < 0;
		});		

		setSearchResults(filteredEmailList)
	}

	/** @param {Sender} sender */
	function handleSearchResultClick(sender) {
		setSearchText('')
		setSearchResults([])
		onSelectSender(sender)
	}

	const handleBlurSearchSender = () => {
		setSearchText('')
		setSearchResults([])
	}

	return (
		<>
			<div className="add-sender-field">
				<TextField
					ref={inputRef}
					type="normal"
					placeholder={<span><span>+</span> Add a sender</span>}
					value={searchText}
					onChange={(event) => setSearchText(event.target.value)}
					// onBlur={handleBlurSearchSender}
					onFocus={() => searchSenders()}
				/>
				{ArrayHelper.isValid(searchResults) &&
					<SelectDropDown
						inputValue={searchText}
						onInputChange={(value) => setSearchText(value)}>
						{searchResults.map((sender, index) => {
							return (
								<SelectOption
									key={sender.id}
									selectable={false}
									// disabled={sender.hasDigest}
									onClick={() => handleSearchResultClick(sender)}>
									{/* <Icon placeholder={sender.name} src={sender.avatar} /> */}
									<span className="label">{sender.email}</span>
								</SelectOption>
							)
						})}
					</SelectDropDown>
				}
			</div>
		</>
	)
}