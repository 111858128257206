import React from 'react'

import { useAlert } from 'core/providers'
import { AdminSender } from 'core/models'

const AdminSenderContext = React.createContext({})

export function AdminSenderProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [senders, setSenders] = React.useState([])

	async function adminSenderSearch(params) {
		setLoading(true)
		const { error, totalCount, results } = await AdminSender.search(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(totalCount)
		setSenders(results ?? [])
	}

	async function adminSenderRefresh(sender) {
		const email = sender instanceof AdminSender ? sender.email : sender
		const { error, result } = await AdminSender.getSenderByEmail(email)

		if (error) {
			setError(error)
			return
		}

		setSenders(senders.map((x) => x.email === email ? result : x))
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		senders,
		totalCount,

		adminSenderSearch,
		adminSenderRefresh,
	}), [isLoading, totalCount, senders])

	return (
		<AdminSenderContext.Provider value={memoedValue}>
			{children}
		</AdminSenderContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, senders: Array<AdminSender>, totalCount: number, adminSenderSearch: Function, adminSenderRefresh: Function}} UseAdminSenders
 * @returns {UseAdminSenders}
 */
export function useAdminSenders() {
	return React.useContext(AdminSenderContext)
}