import React from 'react'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconDelete from 'assets/images/delete/gray.svg'
import iconDeleteHover from 'assets/images/delete/green.svg'

import {
	Checkbox, IconButton
} from 'components'
import { AdminEditDiscoverCompanyModal } from 'scenes/admin/marketing/discovery/edit-modal'

import { useAlert, DiscoverCompany } from 'core'

/**
 * @typedef {{value: DiscoverCompany, selected: boolean, onSelect: Function, onDelete: Function}} Props
 * @param {Props} param0
 */
export function AdminDiscoverCompanyItem({
	value: initialValue,
	selected = false,
	onSelect = (value) => { },
	onDelete = (value) => { }
}) {
	const { setError } = useAlert()

	const [value, setValue] = React.useState(initialValue)
	React.useEffect(() => { setValue(initialValue) }, [initialValue])

	const [isEditModalOn, setEditModalOn] = React.useState(false)

	async function refresh() {
		const { error, result } = await DiscoverCompany.get(value?.id)
		if (error) {
			setError(error)
			return
		}

		setValue(result)
	}

	/**
	 * @param {Event} event
	 */
	function handleClick(event) {
		setEditModalOn(true)
	}

	/**
	 * @param {Event} event
	 */
	function handleDelete(event) {
		event.stopPropagation()
		onDelete(value)
	}

	return (
		<>
			<div className="admin-discover-company-item" onClick={handleClick}>
				<Checkbox
					iconUnchecked={iconUnchecked}
					iconChecked={iconChecked}
					checked={selected}
					onChange={(value) => onSelect(value)} />
				<div className="avatar">
					<img src={value?.avatarDesktop} alt="" />
				</div>
				<div className="info">
					<div className="title">{value?.title}</div>
					<div className="description">{value?.description}</div>
				</div>
				<IconButton className="delete" normal={iconDelete} hover={iconDeleteHover} onClick={handleDelete} />
			</div>
			{isEditModalOn &&
				<AdminEditDiscoverCompanyModal
					value={value}
					onChange={() => refresh()}
					onClose={() => setEditModalOn(false)} />
			}
		</>
	)
}