import React from 'react'
import { useFilePicker } from 'use-file-picker'

import './styles.scss'
import iconClear from 'assets/images/cross-12px-888888.svg'

export function ImagePicker({
	className = "",
	placeholder = "",
	value,
	onSelect = (file) => { },
	children,
}) {
	const [name, setName] = React.useState('')
	const [content, setContent] = React.useState(null)
	const [openFileSelector, { filesContent, plainFiles, loading, errors }] = useFilePicker({
		readAs: 'DataURL',
		accept: 'image/*',
		multiple: true,
		limitFilesConfig: { max: 1 },
		maxFileSize: 50, // in megabytes
	});

	React.useEffect(() => {
		if (plainFiles && plainFiles.length > 0) {
			onSelect(plainFiles[0])
		}
	}, [plainFiles])
	React.useEffect(() => {
		if (filesContent && filesContent.length > 0) {
			setName(filesContent[0].name)
			setContent(filesContent[0].content)
		}
	}, [filesContent])

	function handleClear(event) {
		setName('')
		setContent('')

		onSelect(null)
	}

	return (
		<div className={`${className} image-picker ${content && 'active'}`}>
			<img className="thumbnail" src={content} alt="" />
			<span className="name">{name || placeholder.toString()}</span>
			<button className="btn-clear" onClick={handleClear}><img src={iconClear} alt="" /></button>
			{children ?
				React.Children.map(children, (child, index) => {
					return React.cloneElement(child, {
						onClick: () => openFileSelector()
					})
				})
				:
				<button className="btn-select" onClick={() => openFileSelector()}>Select...</button>
			}
		</div>
	)
}