import { plainToClass } from "class-transformer"

import { Api, Segment } from '../'
import { ArrayHelper, DateHelper, Storage } from '../../utils'
import { errorMessages } from '../../constants'

export class Mail {
	id
	senderId
	subject
	status
	receivedAt
	sentAt
	sentTo
	messageId
	deliveredTimes
	respondedTimes
	isMailPath
	createdAt
	updatedAt
	deletedAt
	isSpam
	spamScore

	static fromJSON(json) {
		return plainToClass(Mail, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => Mail.fromJSON(json))
	}

	static async load(senderId, params = null) {
		const defaultError = 'We are having trouble reaching the server right now... We’re working on it.... Please refresh your page!'
		try {
			const { data, error } = await Api.get('/mails', { senderId: senderId, ...params })
			if (error) { return { error: defaultError } }

			let results = data ? Mail.fromJSONArray(data) : []
			results = ArrayHelper.sort(results, 'createdAt', true)
			return { results }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async get(mailId) {
		try {
			const { error, data } = await Api.post('/mails', { mailIds: [mailId] })
			return {
				error: error ? errorMessages.CONTACT_US : null,
				result: data ? Mail.fromJSONArray(data)[0] : null
			}
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async deliver(mailIds) {
		try {
			const { error, success } = await Api.post('/mails/deliver-mails', { mailIds })
			if (success) {
				Segment.track(Segment.Event.manuallyDeliveringEmail, Storage.userId, { mailIds })
			}
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	formatDate(value) {
		if (!value) { return '' }
		if (DateHelper.isSameDay(value, new Date())) {
			return `Today, ${DateHelper.format(value, 'hh:mma')}`
			// } else if (DateHelper.isSameYear(value, new Date())) {
			// 	return DateHelper.format(value, 'MM/DD, hh:mma')
		} else {
			return DateHelper.format(value, 'M/DD/YY, hh:mma')
		}
	}

	get isSent() { return this.sentAt !== null }
	get formattedCreatedAt() { return this.formatDate(this.createdAt) }
	get formattedSentAt() { return this.formatDate(this.receivedAt) }
	get formattedDeliveredAt() { return this.formatDate(this.sentAt) }
	get hasDeliveredTimes() { return ArrayHelper.isValid(this.deliveredTimes) }
	get formattedDeliveredTimes() { return this.deliveredTimes.map(e => this.formatDate(e)).join(', <br>') }
	get hasRespondedAt() { return ArrayHelper.isValid(this.respondedTimes) }
	get respondedAt() {
		if (ArrayHelper.isValid(this.respondedTimes)) return this.formatDate(this.respondedTimes[0])
		return null
	}

	get isAbleToDeliver() { return this.isMailPath !== false }

}