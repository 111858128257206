import { plainToClass } from 'class-transformer'

import { Api, Sender } from '../..'
import { isValidText } from '../../../utils'

export class FilterPreviewItem {
	subject
	content
	receivedAt

	static fromJSON(json) {
		return plainToClass(FilterPreviewItem, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => FilterPreviewItem.fromJSON(json))
	}
}

export class FilterPreview {
	subject
	content
	receivedAt
	sender

	static fromJSON(json) {
		const result = plainToClass(FilterPreview, json)
		result.sender = Sender.fromJSON(json.sender)
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map(json => FilterPreview.fromJSON(json))
	}

	static async getAll(senders, params) {
		const defaultError = 'Sorry, there are some troubles to get filter previews.'
		try {
			const { keywords = [], hasAttachment = null, hasAttachmentOp, isDigest } = params
			const { error, data } = await Api.post('/filters/preview', {
				senderIds: senders.map(e => e.id),
				keywords: keywords.filter(e => isValidText(e.keyword)),
				hasAttachment: hasAttachment || false,
				hasAttachmentOp,
				isDigest
			})
			return { error, results: FilterPreview.fromJSONArray(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	get name() { return this.sender ? this.sender.name : '' }
	get hasAvatar() { return this.sender && Boolean(this.sender.avatar) }
	get avatar() { return this.sender ? this.sender.avatar : null }
}