import { useEffect, useRef } from 'react';
import './styles.scss';
import { isSmallMobile } from 'utils';
import { useLocation } from 'react-router-dom';

const AtlasOnboardingSetup = ({ senders, currentUser }) => {
    const location = useLocation();
    const isConfigured = useRef(false);
    useEffect(() => {
        const configureAtlas = () => {
            const AtlasOnboarding = window.AtlasOnboarding;
            if (
                !isConfigured.current &&
                AtlasOnboarding &&
                typeof AtlasOnboarding.configure === 'function'
            ) {
                if (currentUser && senders.length > 0) {
                    const onboardingTaskListId = process.env.REACT_APP_ATLAS_TASKID

                    AtlasOnboarding.configure({
                        environmentId:
                            process.env.REACT_APP_ATLAS_ENVIRONMENTID,
                        onboardingTaskListId,
                        userId: currentUser.email
                    });

                    isConfigured.current = true;
                }
            }
        };
        if (window.AtlasOnboarding) {
            configureAtlas();
        } else {
            window.atlasScriptLoaded = configureAtlas;
        }

        // MutationObserver setup
        const shadowHost = document.querySelector(
            '#atlas-onboarding-widget-root'
        );
        if (shadowHost) {
            if (shadowHost.shadowRoot) {
                // If shadow DOM is already there, update the CSS immediately.
                updateAtlasCSS(shadowHost.shadowRoot);
            } else {
                // Otherwise, observe for when shadow DOM is added.
                const observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (
                            mutation.type === 'childList' &&
                            mutation.addedNodes.length > 0
                        ) {
                            const shadowRoot = shadowHost.shadowRoot;
                            if (shadowRoot) {
                                updateAtlasCSS(shadowRoot);
                                observer.disconnect(); // Don't observe anymore after applying the CSS.
                            }
                        }
                    });
                });

                observer.observe(shadowHost, { childList: true });

                // Clean up the observer on component unmount
                return () => observer.disconnect();
            }
        }
    }, [currentUser, senders]);
    return null;
};

const updateAtlasCSS = () => {
    const shadowHost = document.querySelector('#atlas-onboarding-widget-root');
    if (!shadowHost) return;
    const shadowRoot = shadowHost.shadowRoot;
    if (!shadowRoot) return;

    // Check if the style is already added
    if (shadowRoot.querySelector('style#atlasStyle')) return;

    // Create a new style element with an ID
    const style = document.createElement('style');
    style.id = 'atlasStyle';

    const imagePath = `${window.location.origin}/lock.svg`;

    style.textContent = `
    #atlas-onboarding-widget-modal {
        font-family: "Manrope", sans-serif;
        border-radius: 6px;
        background: #000;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
        color: white;
        padding: 0 12px;
        padding-bottom: 12px;
    
        .chakra-card__header {
            padding-bottom: 0px;
            padding-top: 32px;
        }
    
        #atlas-onboarding-widget-modal-close-button {
            position: absolute;
            right: 12px;
            top: 8px;
            color: #939499;
        }
    
        #atlas-onboarding-widget-modal-heading {
    
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
    
            &::before {
                content: "";
                background-image: url("${imagePath}");
                background-size: contain;
                background-repeat: no-repeat;
                display: inline-block;
                width: 17.5px;
                height: 28px;
                margin-right: 8px;
                /* Spacing between the icon and the text */
                vertical-align: middle;
                /* To vertically align the icon with the text */
            }
        }
    
        #atlas-onboarding-widget-modal-subheading {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    
        [data-id="atlas-onboarding-widget-modal-task-checkbox"] {
            .chakra-checkbox__control {
                border-radius: 2px;
                border: 1px solid #C4C5CD;
    
                &[data-checked] {
                    background: #2E70D2;
                    border: 0
                }
    
            }
    
            .chakra-checkbox__label {
                &[data-checked] {
                    .chakra-text {
                        color: #605F64;
                    }
    
                }
            }
        }
    
    
        ul {
            .chakra-stack {
                gap: 0.75rem;
            }
        }
    
    
        .chakra-checkbox__label {
    
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #C4C5CD
        }
        
    }
    .chakra-stack > .chakra-button:nth-child(1) {
        order: 2;
    }
    
    .chakra-stack > button:nth-child(2) {
        order: 1;
    }
    
    #atlas-onboarding-widget-button-container{
        flex-direction: row !important;
    }

    #atlas-onboarding-widget-modal-dismiss-button{
        background: #313134;
        color: #fff;
        border: none;
        padding: 0px 16px;
        height: 40px;
        font-size: 12px;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        line-height: 24px;
    }

    #atlas-onboarding-widget-modal-outline-button:nth-child(1){
        background: #313134;
        color: #fff;
        border: none;
        padding: 0px 16px;
        height: 40px;
        font-size: 12px;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        line-height: 24px;
    }

     #atlas-onboarding-widget-modal-remind-me-later-button{
        background-color: #61CEF7; 
        color: #000000; 
        border: none;
        padding: 0px 16px;
        height: 40px;
        font-size: 12px;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        line-height: 24px;
    }

    #atlas-onboarding-widget-instructions {
        font-family: "Manrope", sans-serif;
        padding: 24px;
        border-radius: 10px;
        box-shadow: 0px 0px 30px 0px rgba(97, 206, 247, 0.2);
      }
      #atlas-onboarding-widget-instructions .chakra-alert__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: black;
      }
      #atlas-onboarding-widget-instructions span[data-status="info"], #atlas-onboarding-widget-instructions span[data-status="success"] {
        display: none;
      }
      #atlas-onboarding-widget-instructions button[aria-label="Close"] {
        position: absolute;
        top: 12px;
        right: 12px;
      }
      #atlas-onboarding-widget-instructions .chakra-alert__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: black;
      }
      #atlas-onboarding-widget-instructions[data-status="info"] {
        background-color: #61CEF7;
      }
      #atlas-onboarding-widget-instructions[data-status="success"] {
        background-color: #6BD685;
      }
      #atlas-onboarding-widget-instructions[data-status="success"]::after {
        content: "🎉";
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-right: 24px;
      }
      `;

    // Append the style element to the shadow root
    shadowRoot.appendChild(style);
};

export default AtlasOnboardingSetup;