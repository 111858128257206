import FbPixel from 'react-facebook-pixel'

import { config } from 'constants/config'

require('dotenv').config()

export class FacebookPixel {
	static init({ email, firstName, lastName }) {
		const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
		const options = {
			autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
			debug: config.IS_LOCAL || config.IS_DEV, // enable logs
			em: email?.toLowerCase(),
			fn: firstName?.toLowerCase(),
			ln: lastName?.toLowerCase(),
		}
		FbPixel.init(process.env.REACT_APP_FB_PIXEL_ID, advancedMatching, options)

		FbPixel.pageView()
	}

	static track(eventName, { email, firstName, lastName }) {
		FbPixel.track(eventName, {
			em: email?.toLowerCase(),
			fn: firstName?.toLowerCase(),
			ln: lastName?.toLowerCase(),
		})
	}
}