import React from 'react'

import iconCopy from 'assets/images/copy.svg'
import iconCopyHover from 'assets/images/copy-hover.svg'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'

import {
	Checkbox,
	TimePicker,
	IconButton,
} from 'components'

import { DeliveryTime } from 'core'

/**
 * @typedef {{label: string, value: DeliveryTime, onChange: Function, onCopy: Function}} Props
 * @param {Props} param0
 */
export function DeliveryTimeEl({
	label,
	value,
	onChange = (value) => { },
	onCopy = () => { }
}) {
	const { isOn, from, to } = value ?? DeliveryTime.default

	function handleOn(on) {
		onChange(DeliveryTime.create({ ...value, isOn: on }))
	}

	function handleFrom(from) {
		onChange(DeliveryTime.create({ ...value, isOn: true, from }))
	}

	function handleTo(to) {
		onChange(DeliveryTime.create({ ...value, isOn: true, to }))
	}

	return (
		<div className='delivery-time-item'>
			<Checkbox
				label={label}
				iconUnchecked={iconUnchecked}
				iconChecked={iconChecked}
				checked={isOn}
				onChange={(value) => handleOn(value)} />
			<div className="time-picker-container">
				<TimePicker
					hasMinutes={false}
					value={from ?? new Date()}
					onChange={(value) => handleFrom(value)} />
				<TimePicker
					hasMinutes={false}
					value={to ?? new Date()}
					onChange={(value) => handleTo(value)} />
			</div>
			<IconButton
				className="copy-all"
				normal={iconCopy}
				hover={iconCopyHover}
				data-description="Copy to all"
				onClick={() => onCopy()} />
		</div>
	)
}