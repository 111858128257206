import React, { useEffect, useRef, useState } from "react";
import './styles.scss'
import { useAuth } from "core";
import { NavLink, useNavigate } from "react-router-dom";
import iconAccount from 'assets/images/icon_account.svg'
import { useSwipeable } from "react-swipeable";

export const MobileBottomNavigator = (props) => {
    const navigate = useNavigate();

    const ref = useRef(null);

    const { currentUser, currentLockrScanUser } = useAuth()
    const [bottom, setBottom] = useState(-600);
    useEffect(() => {
        setBottom(-(ref.current.clientHeight - 80))
    }, [ref])
    const handlres = useSwipeable({
        onSwipedDown: () => {
            setBottom(-(ref.current.clientHeight - 80))
        },
        onSwipedUp: () => {
            setBottom(0)

        }
    })
    return <>
        {bottom != -(ref?.current?.clientHeight - 80) && <div onClick={() => setBottom(-(ref.current.clientHeight - 80))} style={{ position: 'absolute', top: 0, background: 'black', height: '100%', width: '100%', opacity: 0.5 }}></div>}
        <div ref={ref} className="mobile-bottom-navigator" style={{ bottom }} onClick={() => { bottom == 0 ? setBottom(-(ref.current.clientHeight - 80)) : setBottom(0) }}>

            <div {...handlres} className="bottom-header">
                <img height={24} width={24} src={iconAccount} alt="" style={{ marginRight: 10 }} />
                <div className="bottom-header-email">
                    {(!currentUser && currentLockrScanUser) ? currentLockrScanUser?.firstName + " " + currentLockrScanUser?.lastName : currentUser?.email ?? ""}
                </div>
            </div>
            <div className="bottom-list">
                {props.data.map((d) => {
                    if (d.className != 'nav-menu-divider') {
                        if (!d.ignore)
                            if (d.path)
                                return <NavLink to={d.path ? d.path : undefined} className={`bottom-list-item ${d.label == 'Log out' && 'red'} ${d.className}`} onClick={() => { d?.onClick ? d.onClick() : navigate(d.path) }}>{d.label}</NavLink>
                            else
                                return <div className={`bottom-list-item ${d.label == 'Log out' && 'red'} ${d.className}`} onClick={() => { d?.onClick && d?.onClick(); d?.url && window.open(d?.url, '_blank') }}>{d.label}</div>
                    }
                    else if (d.className == 'nav-menu-divider') {
                        return <div style={{ border: '1px solid #353738' }}></div>
                    }
                }
                )
                }
            </div>
        </div>
    </>

}