import { plainToClass } from 'class-transformer'

export class AdminReferralState {
	total
	changePercent
	ticks = []

	static fromJSON(json) {
		const result = plainToClass(AdminReferralState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminReferralStateTick.fromJSON(tick.current),
				'previous': AdminReferralStateTick.fromJSON(tick.previous),
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminReferralState.fromJSON(json))
	}
}

export class AdminReferralStateTick {
	from
	to
	referralSubmitted

	static fromJSON(json) {
		return plainToClass(AdminReferralStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminReferralStateTick.fromJSON(json))
	}
}