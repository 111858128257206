import { plainToClass } from "class-transformer";
import { errorMessages } from "constants/error";
import { Api } from "core/api";

export class DomainGroups {
    domainList

    static fromJson(json) {
        if (!json) return;

        const result = plainToClass(DomainGroups, json);
        return result
    }

    static async loadDomainList() {
        try {
            const { success, data, error } = await Api.get('/admin/domain-group/get-all-groups');
            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return data;
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async addNewDomainGroup(params = {}) {
        try {
            const { success, data, error } = await Api.post('/admin/domain-group/add-group', params);
            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return true
        }
        catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async getDomainListOfGroup(domainGroupId = '') {
        try {
            const { success, data, error } = await Api.get('/admin/domain-group/get-all-domains', { domainGroupId })
            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return data;
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async removeDomainsFromGroup(params = {}) {
        try {
            const { success, error } = await Api.delete('/admin/domain-group/remove-domains', params)
            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return success;
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async addDomainToGroup(params = {}) {
        try {
            const { success, error } = await Api.post('/admin/domain-group/add-domain', params);
            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return success;
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async deleteDmainGroup(params = {}) {
        try {
            const { success, error } = await Api.delete('/admin/domain-group/delete', params);

            if (!success)
                return { error: error && errorMessages.NETWORK_ERROR }
            return success;
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

}