import React from 'react'

import './styles.scss'

import { ProgressBar } from 'components'

import { useReferral } from 'core'
import { range, ObjectHelper } from 'utils'

export function AcceptedReferrals({
	className = "",
}) {
	const { acceptedCount } = useReferral()

	const [referralsCount, setReferralsCount] = React.useState(null)
	React.useEffect(() => {
		if (ObjectHelper.isValid(acceptedCount)) {
			if (acceptedCount < 2) setReferralsCount(2 - acceptedCount)
			else if (acceptedCount < 5) setReferralsCount(5 - acceptedCount)
			else if (acceptedCount < 10) setReferralsCount(10 - acceptedCount)
			else setReferralsCount(acceptedCount)
		}
	}, [acceptedCount])

	return (
		<div className={`${className} accepted-referrals`}>
			<div className="title">Accepted Referrals</div>
			<div className="subtitle">
				For every friend you refer that creates an account, you earn a credit. Credits can earn rewards over time.
			</div>
			<div className="value-container">
				<span>1</span>
				<div>
					<div className="progress-bar-container">
						<ProgressBar type="divided" min={0} max={10} progress={acceptedCount} />
					</div>
					{ObjectHelper.isValid(referralsCount) &&
						<div>
							{(acceptedCount < 2) && <>You're only <strong>{referralsCount}</strong> referrals away from receiving a pair of sunglasses!</>}
							{(acceptedCount >= 2 && acceptedCount < 5) && <>You're only <strong>{referralsCount}</strong> referrals away from receiving a hat!</>}
							{(acceptedCount >= 5 && acceptedCount < 10) && <>You're only <strong>{referralsCount}</strong> referrals away from receiving a $20 gift card!</>}
							{(acceptedCount >= 10) && <>You have been an incredible supporter of lockrMail! Check back soon to see if we added larger rewards!</>}
						</div>
					}
				</div>
				<span>10</span>
			</div>
		</div>
	)
}