import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconUpload from 'assets/images/upload.svg'
import iconClear from 'assets/images/cross/gray-16.svg'

import {
	Loader,
	Icon, Checkbox,
	Switch, ImagePicker,
	BadgeButton,
} from 'components'

import {
	useAlert,
	AdminSender, useAdminSenders,
} from 'core'
import { ArrayHelper, DateHelper } from 'utils'

/**
 * @typedef {{sender: AdminSender, isHeader: boolean, selected: boolean, onSelect: Function, onChange: Function}} Props
 * @param {Props} param0
 */
export function ListItem({
	isHeader = false,
	sender = {},
	selected = false,
	onSelect = () => { },
	onChange = () => { },
}) {
	const { setError } = useAlert()
	const { adminSenderRefresh } = useAdminSenders()

	const { name, email, domain, hasWhite, avatar, createdAt } = sender

	const [isLoading, setLoading] = React.useState(false)

	function renderDate(date) {
		if (!date) return ''

		return DateHelper.format(date, 'MM/DD/YYYY')
	}

	async function handleSetWhite(value) {
		setLoading(true)
		const { error } = await AdminSender.setWhiteToSenders([sender], value)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		adminSenderRefresh(sender)
	}

	async function uploadAvatar(file) {
		const { error, results } = await AdminSender.uploadFiles([file])

		if (error) {
			setError(error)
			return null
		}

		return ArrayHelper.isValid(results) ? results[0] : null
	}

	async function handleUploadAvatar(file) {
		setLoading(true)
		const url = await uploadAvatar(file)
		if (!url) {
			setLoading(false)
			return
		}

		const { error } = await AdminSender.updateAvatar([sender], url)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		adminSenderRefresh(sender)
	}

	async function handleDeleteAvatar() {
		setLoading(true)
		const { error } = await AdminSender.deleteAvatar([sender])
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		adminSenderRefresh(sender)
	}

	return (
		<React.Fragment>
			<div className={`admin-sender-list-item ${isHeader && 'header'}`}>
				{isHeader ?
					<>
						<div className='checkbox'></div>
						<div className='avatar'></div>
						<div className='info'>
							<div className="name">Name</div>
							<div className="email">Email</div>
							<div className="domain">Domain</div>
						</div>
						<div className='actions'></div>
					</>
					:
					<React.Fragment>
						{isLoading ? <Loader /> :
							<React.Fragment>
								<Checkbox
									iconChecked={iconChecked}
									iconUnchecked={iconUnchecked}
									checked={selected}
									onChange={() => onSelect()} />
								<Icon className="avatar" src={avatar} placeholder={email} />
								<div className='info'>
									<div className="name">{name}</div>
									<div className="email">{email}</div>
									<div className="domain">{domain}</div>
								</div>
								<div className='actions'>
									<Switch
										value={sender?.hasWhite ?? false}
										onChange={(value) => handleSetWhite(value)} />
									<ImagePicker
										onSelect={(file) => handleUploadAvatar(file)} >
										<BadgeButton
											icon={iconUpload}
											description="Upload avatar" />
									</ImagePicker>
									<BadgeButton
										icon={iconClear}
										description="Delete avatar"
										onClick={() => handleDeleteAvatar()} />
								</div>
							</React.Fragment>
						}
					</React.Fragment>
				}
			</div>
		</React.Fragment>
	)
}