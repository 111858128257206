import _ from 'lodash'

import { ObjectHelper } from '.'

function is(values) {
	return values && Array.isArray(values)
}

function isValid(values) {
	return (is(values) && values.length > 0)
}

function value(val, path) {
	return ObjectHelper.value(val, path)
}

function contains(values, val, path = null) {
	if (!isValid(values) || !val) return false
	return values.some(e => path ? value(e, path) === value(val, path) : ObjectHelper.equals(e, val))
}

function toggle(values, val, property = null) {
	if (!is(values) || val === null) return null
	if (values.some(e => property ? ObjectHelper.value(e, property) === ObjectHelper.value(val, property) : e === val)) {
		return values.filter(e => property ? ObjectHelper.value(e, property) !== ObjectHelper.value(val, property) : e !== val)
	} else {
		return [...values, val]
	}
}

function copy(values) {
	if (!is(values) || values === null) return null
	return _.cloneDeep(values)
}

function equals(a, b, keepOrder = false) {
	if (!is(a) || !is(b)) return false
	if (a.length !== b.length) {
		return false
	}

	let noMatches = a.some(e => !contains(b, e))
	if (noMatches) {
		return false
	}

	noMatches = b.some(e => !contains(a, e))
	if (noMatches) {
		return false
	}

	if (keepOrder) {
		let result = true
		a.forEach((e1, i) => {
			const e2 = b[i]
			if (!ObjectHelper.equals(e1, e2)) {
				result = false
			}
		})
		return result
	}

	return true
}

function sumOfPaths(val, paths = []) {
	if (value === undefined || value === null) return null
	if (!isValid(paths)) return val
	let result = 0
	paths.forEach(path => {
		result += value(val, path)
	})
	return result
}

function min(values, fieldName = null) {
	if (!isValid(values)) return null
	const fieldValues = values.map(val => value(val, fieldName))
	const sorted = fieldValues.sort((a, b) => a - b)
	return sorted[0]
}

function max(values, fieldName = null) {
	if (!isValid(values)) return null
	const fieldValues = values.map(val => value(val, fieldName))
	const sorted = fieldValues.sort((a, b) => a - b)
	const result = sorted[sorted.length - 1]
	return result
}

/**
 * @param {Array} values
 * @param {string} key
 * @param {boolean} reverse
 * @returns {boolean}
 */
function sort(values, key = null, reverse = false) {
	function compare(a, b) {
		if (a < b) return reverse ? 1 : -1
		if (a > b) return reverse ? -1 : 1
		return 0
	}

	if (!isValid(values)) return []
	if (!key)
		return values.sort((a, b) => compare(a, b))
	return values.sort((a, b) => compare(a[key], b[key]))
}

export const ArrayHelper = {
	is, isValid,
	value,
	contains, equals,
	toggle, copy,
	sumOfPaths,
	min, max,
	sort,
}