import { plainToClass } from 'class-transformer'

import { BaseModel } from 'core/models/base'
import { Api } from 'core'
import { errorCodes, errorMessages } from 'constants/error'
import { DateHelper } from 'utils'

export class LockrBox extends BaseModel {
	frequency
	deliveryDate

	static Frequency = Object.freeze({
		'Daily': 'daily',
		'Weekly': 'weekly',
		'Monthly': 'monthly',
	})

	/**
	 * @returns {LockrBox}
	 */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			// console.log('json', json.deliveryDate, DateHelper.default)
			result.deliveryDate = DateHelper.parse(json.deliveryDate)
			// console.log('result.deliveryDate', result.deliveryDate)
		}
		return result
	}

	static get default() {
		const result = new LockrBox()
		result.frequency = LockrBox.Frequency.Daily

		let deliveryDate = new Date()
		deliveryDate = DateHelper.startOfWeek(deliveryDate)
		deliveryDate = DateHelper.addHour(deliveryDate, 11)

		result.deliveryDate = DateHelper.addHour(DateHelper.startOfWeek(new Date()), 11)

		return result
	}

	static async addOrUpdate(frequency, deliveryDate) {
		try {
			const { error, data } = await Api.post('/lockrBox', { frequency, deliveryDate })
			return {
				error: error && errorMessages.CONTACT_US,
				result: data && LockrBox.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async destory() {
		try {
			const { error } = await Api.delete('/lockrBox', { frequency, deliveryDate })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	get formattedFrequency() {
		if (this.frequency === LockrBox.Frequency.Daily) return 'Daily'
		if (this.frequency === LockrBox.Frequency.Weekly) return 'Weekly'
		if (this.frequency === LockrBox.Frequency.Monthly) return 'Monthly'
		return 'Off'
	}
}