import React from 'react'

import './styles.scss'
import {
	Alert, AlertPage, AlertTitle,
	AlertButtonContainer,
	AlertButton,
} from 'components'
import {
	useAlert, AdminUnregisteredUser
} from 'core'
import {
	isEmail
} from 'utils'

export function AdminUnregisteredUserInfoAlert({
	user,
	onClose = (updated) => { }
}) {
	const [emailError, setEmailError] = React.useState(null)
	const [isSaveable, setIsSaveable] = React.useState(false)
	const [newEmail, setNewEmail] = React.useState("")

	const handleEmailChange = React.useCallback((e) => {
		setNewEmail(e.target.value)
		setEmailError(null)
		if (isEmail(e.target.value)) {
			setIsSaveable(true)
		} else {
			setIsSaveable(false)
		}
	}, [newEmail, isSaveable])

	async function handleSave() {
		let params = {
			email: user.email,
			newEmail: newEmail,
			isRegistered: user.isRegistered
		}

		const { error, result } = await AdminUnregisteredUser.saveNewEmail(params)
		if (error) {
			setEmailError(error)
			setIsSaveable(false)
			return
		} else {
			onClose(true)
		}
	}

	return (
		<Alert className={`admin-unregistered-userinfo-alert`} onCancel={() => onClose(false)}>
			<AlertPage>
				<AlertTitle value={user.firstName + " " + user.lastName} />
				<div className="admin-unregistered-userinfo-alert-content">
					<div className="list-container">
						<div className="list">
							<div className="list-item">
								<div>LockrMail</div>
								<div>{user.lockrMail}</div>
							</div>
							<div className="list-item">
								<div>Email</div>
								<div>{user.email}</div>
							</div>
							<div className="register-email-change-wrapper">
								<div>New email</div>
								<div>
									<input value={newEmail} onChange={handleEmailChange} />
									{emailError && <div className="error">{emailError}</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<AlertButtonContainer>
					<AlertButton disabled={!isSaveable} onClick={() => handleSave()}>Save</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}