import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	Select, SelectOption,
	TimePicker, DatePicker,
	BlueButton,
	AlertButtonContainer,
	AlertButton,
} from 'components'

import {
	useAlert,
	useAuth,
	DiscountNewsletterMail
} from 'core'
import {
	range,
	DateHelper,
	ObjectHelper,
} from 'utils'

export function DiscountNewsLetterModalEl({
	discountNewsletterMail: initialDiscountNewsletter,
	onChange = (value) => { },
	onSave = () => { },
	onClose = () => { },
}) {
	const startOfWeek = DateHelper.startOfWeek(new Date())

	const { setError } = useAlert()
	const { currentUser, userGetProfile, currentLockrScanUser } = useAuth()
	const activeUser = currentUser ? currentUser : currentLockrScanUser

	/** @type {[DiscountNewsletterMail, Function]} */
	const [discountNewsletterMail, setDiscountNewsletterMail] = React.useState(initialDiscountNewsletter ?? DiscountNewsletterMail.default)
	const [frequency, setFrequency] = React.useState(initialDiscountNewsletter?.frequency ?? DiscountNewsletterMail.Frequency.Daily)
	const [deliveryDay, setDeliveryDay] = React.useState(initialDiscountNewsletter?.deliveryDate ?? Date.now())
	const [deliveryTime, setDeliveryTime] = React.useState(initialDiscountNewsletter?.deliveryDate ?? Date.now())

	React.useEffect(() => {
		setDiscountNewsletterMail(activeUser?.discountNewsletterMail ?? DiscountNewsletterMail.default)
	}, [activeUser])

	React.useEffect(() => {
		setDiscountNewsletterMail(initialDiscountNewsletter ?? DiscountNewsletterMail.default)
	}, [initialDiscountNewsletter])

	React.useEffect(() => {
		// console.log(discountNewsletterMail?.deliveryDate)
		setFrequency(discountNewsletterMail?.frequency)
		setDeliveryDay(discountNewsletterMail?.deliveryDate)
		setDeliveryTime(discountNewsletterMail?.deliveryDate)
	}, [discountNewsletterMail])

	function isSaveEnabled() {
		if (!activeUser || !discountNewsletterMail) return true
		return Boolean(activeUser.discountNewsletterMail?.frequency === discountNewsletterMail.frequency && DateHelper.isSame(activeUser.discountNewsletterMail?.deliveryDate, discountNewsletterMail.deliveryDate))
	}

	function handleFrequency(value) {
		onChange({ ...discountNewsletterMail, frequency: value })
		// setDiscountNewsletterMail({ ...discountNewsletterMail, frequency: value })
	}

	function handleDeliveryDay(value) {
		onChange({ ...discountNewsletterMail, deliveryDate: DateHelper.merge({ day: value, time: deliveryTime }) })
	}

	function handleDeliveryTime(value) {
		onChange({ ...discountNewsletterMail, deliveryDate: DateHelper.merge({ day: deliveryDay, time: value }) })
	}

	async function handleSave() {
		await onSave()
		onClose()
	}

	return (
		<div className='discount-newsletter-modal-alert-parent'>
			<Alert className={`discount-newsletter-modal`}
				onCancel={() => onClose()}>
				<AlertPage>
					<AlertTitle>lockrDeals Settings</AlertTitle>
					<div className={`discount-newsletter-modal-wrapper ${(!activeUser || !discountNewsletterMail) && 'd-none'}`}>
						<div>Select frequency and a delivery time:</div>
						<div className="discount-newsletter-modal-group">
							<div>How often</div>
							<Select
								className="frequency-selector"
								value={frequency}
								onChange={(value) => handleFrequency(value)}>
								<SelectOption value={DiscountNewsletterMail.Frequency.Daily}>Every day</SelectOption>
								<SelectOption value={DiscountNewsletterMail.Frequency.Weekly}>Every week</SelectOption>
								<SelectOption value={DiscountNewsletterMail.Frequency.Monthly}>Every month</SelectOption>
							</Select>
						</div>
						{frequency === DiscountNewsletterMail.Frequency.Daily &&
							<div className="discount-newsletter-modal-group">
								<div>Time</div>
								<TimePicker
									className="delivery-time-selector"
									keepLabel
									label={DateHelper.format(deliveryTime, 'hh:mm a')}
									hasMinutes={false}
									value={deliveryTime}
									onChange={(value) => handleDeliveryTime(value)} />
							</div>
						}
						{frequency === DiscountNewsletterMail.Frequency.Weekly &&
							<React.Fragment>
								<div className="discount-newsletter-modal-group">
									<div>Day</div>
									<Select
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(deliveryDay, 'dddd')}>
										{range(0, 6).map((i) => DateHelper.addDay(startOfWeek, i)).map((date, index) =>
											<SelectOption
												key={index}
												value={date}
												selected={DateHelper.isSameDay(deliveryDay, date)}
												onClick={() => handleDeliveryDay(date)}>{DateHelper.format(date, 'dddd')}
											</SelectOption>
										)}
									</Select>
								</div>
								<div className="discount-newsletter-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
						{frequency === DiscountNewsletterMail.Frequency.Monthly &&
							<React.Fragment>
								<div className="discount-newsletter-modal-group">
									<div>Day</div>
									<DatePicker
										className="delivery-day-selector"
										keepLabel
										label={DateHelper.format(deliveryDay, 'Do')}
										value={deliveryDay}
										onChange={(value) => handleDeliveryDay(value)} />
								</div>
								<div className="discount-newsletter-modal-group">
									<div>Time</div>
									<TimePicker
										className="delivery-time-selector"
										hasMinutes={false}
										value={deliveryTime}
										onChange={(value) => handleDeliveryTime(value)} />
								</div>
							</React.Fragment>
						}
					</div>
					<AlertButtonContainer>
						<AlertButton
							type="positive"
							disabled={isSaveEnabled()}
							onClick={() => handleSave()}>
							Save
						</AlertButton>
					</AlertButtonContainer>
				</AlertPage>
			</Alert>
		</div>
	)
}