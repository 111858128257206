import React from 'react'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconExpand from 'assets/images/arrow_down.svg'

import {
	Checkbox,
	Select, SelectOption
} from 'components'
import { FilterLibrary } from 'core'
import {
	range,
	DOMHelper
} from 'utils'

/**
 * @typedef {{library: FilterLibrary, selected: boolean, onSelect: Function, onChange: Function}} LibraryListItemProps
 * @param {LibraryListItemProps} param0
 */
export function LibraryListItem({
	library,
	selected,
	onSelect = (value) => { },
	onChange = (library) => { },
}) {
	const firstSelectorRef = React.useRef()
	const secondSelectorRef = React.useRef()

	const { keywords } = library

	const [isExpanded, setExpanded] = React.useState(selected ? true : false)
	React.useEffect(() => { setExpanded(selected) }, [selected])

	/**
	 * @param {MouseEvent} event
	 */
	function handleClick(event) {
		if (!DOMHelper.closest(event?.target, '.select')) onSelect(!selected)
	}

	function handleExpand(event) {
		event.stopPropagation()

		if (!selected) {
			setExpanded(!isExpanded)
		}

		firstSelectorRef.current?.blur()
		secondSelectorRef.current?.blur()
	}

	function handleChangeKeywords(keywords) {
		if (!library.isKeywordsAvailable) return

		library.keywords = keywords
		onChange(library)
	}

	return (
		<div
			className={`library-list-item ${library.canExpand && 'expandable'} ${isExpanded && 'expanded'}`}
			onClick={handleClick}>
			<Checkbox
				iconUnchecked={iconUnchecked}
				iconChecked={iconChecked}
				checked={selected}
				onChange={(value) => onSelect(value)}
			/>
			<div>
				<div className="title">{library.name}</div>
				<div className="description">{library.description}</div>
				{(library.type === FilterLibrary.Type.Between && keywords.length >= 2) &&
					<div className={`expand-container ${!isExpanded && 'd-none'}`}>
						<div className="expand-item between">
							<span>From</span>
							<Select
								ref={firstSelectorRef}
								value={keywords[0]}
								onChange={value => handleChangeKeywords([value, keywords[1]])}>
								{range(0, 100, 5).map((e, index) => {
									const value = `${e}%`
									return (
										<SelectOption key={index} value={value}>
											{value}
										</SelectOption>
									)
								})}
							</Select>
						</div>
						<div className="expand-item between">
							<span>To</span>
							<Select
								ref={secondSelectorRef}
								value={keywords[1]}
								onChange={value => handleChangeKeywords([keywords[0], value])}>
								{range(0, 100, 5).map((e, index) => {
									const value = `${e}%`
									return (
										<SelectOption key={index} value={value}>
											{value}
										</SelectOption>
									)
								})}
							</Select>
						</div>
					</div>
				}
				{(library.type === FilterLibrary.Type.CustomInput) &&
					<div className={`expand-container ${!isExpanded && 'd-none'}`}>
						<div className="expand-item custom-input">
							<input
								type="text"
								placeholder="Separate each term with a comma (i.e. text, text, text)"
								value={keywords[0] || ""}
								onChange={event => handleChangeKeywords([event.target.value])}
								onClick={event => event.stopPropagation()} />
						</div>
					</div>
				}
			</div>
			{library.canExpand &&
				<button className="expand-button" onClick={handleExpand} >
					<img src={iconExpand} />
				</button>
			}
		</div>
	)
}