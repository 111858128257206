import { BaseModel } from 'core/models/base'
import { Api } from '..'
import { DateHelper, ArrayHelper, ExtensionHelper, DomainHelper } from 'utils'
import { errorMessages } from 'constants/error'

export class DomainSetting extends BaseModel {
	name
	avatar
	domain

	emailOption = 0
	emailUpdatedAt

	popupOption = 0
	popupUpdatedAt

	/** @type {null|0|1} */
	passwordOption = null
	passwordUpdatedAt

	allowConsent

	allowGPC

	updatedAt

	static BlockOption = Object.freeze({
		Daily: Object.freeze({ label: '24 hrs', value: 1 }),
		Weekly: Object.freeze({ label: '1 week', value: 7 }),
		Monthly: Object.freeze({ label: '1 month', value: 30 }),
		Yearly: Object.freeze({ label: '1 year', value: 365 }),
		Permanently: Object.freeze({ label: 'Block permanently', value: -1 }),
	})

	static Option = Object.freeze({
		...DomainSetting.BlockOption,
		Unblock: Object.freeze({ label: 'Unblock', value: 0 }),
	})

	static PopupOption = Object.freeze({
		Default: 0,
		On: -2,
		Off: -1,
	})

	static PasswordOption = Object.freeze({
		Default: null,
		On: true,
		Off: false,
	})

	static ConsentOption = Object.freeze({
		Default: null,
		On: true,
		Off: false
	})
	static GPCOption = Object.freeze({
		Default: null,
		On: true,
		Off: false
	})

	static Type = Object.freeze({
		Email: 'emailOption',
		Popup: 'popupOption',
		Password: 'passwordOption',
		Consent: 'allowConsent',
		GPC: 'allowGPC'
	})

	/** @returns {DomainSetting} */
	static fromJSON(json) { return super.fromJSON(json) }

	/** @returns {Array<DomainSetting>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static checkBlocked(option, updatedAt) {
		if (option === 0) return false
		if (option === -1) return true

		if (!updatedAt) return false

		const duration = option * 24 * 3600
		const now = new Date()
		const updatedTime = DateHelper.parse(updatedAt)
		const delta = (now.getTime() - updatedTime.getTime()) / 1000
		return delta <= duration
	}

	static async load({ searchText = '', pageIndex, pageSize, sortBy = "updatedAt", domain, addDomain = false }) {
		try {
			let avatar
			if (domain && typeof domain !== 'string') {
				avatar = domain.avatar
				domain = domain.domain
			}
			let otherError = undefined
			const params = !domain || addDomain ? {
				searchText,
				pageNum: pageIndex + 1,
				pageSize,
				sortBy
			} : { domain }
			const { error, data } = await Api.get('/domain/get-alert-setting', params)

			if (domain && !addDomain) {
				return {
					error: error ? errorMessages.CONTACT_US : error,
					result: data ? DomainSetting.fromJSON(data) : []
				}
			}
			if (addDomain) {
				if (data) {
					const existingDomainIndex = data.rows.findIndex(row => row.domain === domain)
					if (existingDomainIndex === -1) { // Adding only if there is no same domain value
						data.count += 1
						data.rows.unshift({
							id: "null",
							domain,
							emailOption: 0,
							emailUpdatedAt: null,
							popupOption: 0,
							popupUpdatedAt: null,
							passwordOption: null,
							updatedAt: null,
							allowConsent: null,
							avatar: avatar,
							name: DomainHelper.parseDomainName(domain),
							manuallyAdded: true
						})
					} else {
						otherError = errorMessages.DOMAIN_SETTING_ALREADY_EXIST
					}
				}
			}
			return {
				error: error ? errorMessages.CONTACT_US : otherError,
				totalSize: data ? data.count : 0,
				results: data ? DomainSetting.fromJSONArray(data.rows) : []
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async search(domain) {
		try {
			const { error, data } = await Api.get(`/domain/preview/${encodeURIComponent(domain === "" ? "all_keyword" : domain)}`, null, true, true)
			let results = ArrayHelper.sort(data ? DomainSetting.fromJSONArray(data) : [], 'name')

			return {
				error: error ? errorMessages.CONTACT_US : error,
				results
			}
		} catch (error) {
			// console.log(error)
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async checkAvailability(domains = []) {
		try {
			const { error, errorCode } = await Api.post('/domain/check-availability', { domains })
			return { error, errorCode }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async add(domain, params) {
		try {
			const { error, errorCode, data } = await Api.post('/domain/add-alert-setting', { domain, ...params })
			if (errorCode === 409) return { error: errorMessages.DOMAIN_SETTING_ALREADY_EXIST }
			ExtensionHelper.updateGPCSettingsInExtension();
			return {
				error: error ? errorMessages.CONTACT_US : error,
				errorCode,
				result: data ? DomainSetting.fromJSON(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async update(domains, { emailOption, popupOption, passwordOption, allowConsent, allowGPC }) {
		try {
			const params = { emailOption, popupOption, passwordOption, allowConsent, allowGPC }
			const { error, data } = await Api.post('/domain/set-alert-setting', { domains, params })
			ExtensionHelper.updateGPCSettingsInExtension();
			return {
				error: error ? errorMessages.CONTACT_US : error,
				results: data ? DomainSetting.fromJSONArray(data) : null
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async unblockAll(domains) {
		return await DomainSetting.update(domains, {
			emailOption: 0,
			popupOption: 0,
			passwordOption: DomainSetting.PasswordOption.Default,
			allowConsent: DomainSetting.ConsentOption.Default
		})
	}

	static async resetToDefault(domains, passwordOption, allowConsent, allowGPC) {
		if (passwordOption || allowConsent) {
			let update = {};
			if (passwordOption) {
				update.passwordOption = DomainSetting.PasswordOption.Default
			}
			if (allowConsent) {
				update.allowConsent = DomainSetting.ConsentOption.Default
			}
			if (allowGPC) {
				update.allowGPC = DomainSetting.GPCOption.Default
			}
			return await DomainSetting.update(domains, update)
		}
	}

	static async batchRemoveDomains(domains) {
		if (!ArrayHelper.isValid(domains)) return
		return await DomainSetting.unblockAll(domains)
	}

	static async batchRemoveDomainSettings(domainSettings = []) {
		const domains = domainSettings.map(e => e.domain)
		return await DomainSetting.batchRemoveDomains(domains)
	}

	static async removeDomain(domain) {
		if (!domain) return
		return await DomainSetting.batchRemoveDomains([domain])
	}

	static async removeDomainSetting(domainSetting) {
		if (!domainSetting) return
		return await DomainSetting.removeDomain(domainSetting.domain)
	}

	static async clearBlank() {
		try {
			const { error } = await Api.delete('/domain/clear-blank')
			return { error: error ? errorMessages.CONTACT_US : null }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static formattedSnoozed(option) {
		if (option === -1) return 'Blocked permanently'
		else if (option === 1) return 'Snoozed 24 hours'
		else if (option === 7) return 'Snoozed 1 week'
		else if (option === 30) return 'Snoozed 1 month'
		else if (option === 365) return 'Snoozed 1 year'
		return 'Block'
	}

	static isPasswordEnabled(value, allowPasswordOption) { return value === DomainSetting.PasswordOption.Default ? allowPasswordOption : value }
	static formattedPassword(value, allowPasswordOption) { return DomainSetting.isPasswordEnabled(value, allowPasswordOption) ? 'On' : 'Off' }

	static isConsentEnabled(value, allowConsent) { return value === null ? allowConsent : value }
	static formattedConsent(value, allowConsent) { return DomainSetting.isConsentEnabled(value, allowConsent) ? 'On' : 'Off' }

	static isGPCEnabled(value, allowGPC) { return value === null ? allowGPC : value }
	static formattedGPC(value, allowGPC) { return DomainSetting.isGPCEnabled(value, allowGPC) ? 'On' : 'Off' }

	static isBrowserPopupEnabled(value, allowBrowserPopup) {
		return value === 0 ? allowBrowserPopup : (value === -2);
	}


	get isNewCreated() { return Boolean(this.emailOption === 0 && !this.emailUpdatedAt && this.popupOption === 0 && !this.popupUpdatedAt) }
	get isEmailBlocked() { return DomainSetting.checkBlocked(this.emailOption, this.emailUpdatedAt) }
	get emailBlockedUntil() {
		if (!this.isEmailBlocked) return null
		if (this.emailOption === -1) return 'Snoozed permanently'
		const result = DateHelper.addDay(this.emailUpdatedAt || this.updatedAt, this.emailOption)
		if (DateHelper.isSameYear(result, this.emailUpdatedAt || this.updatedAt)) return `Snoozed till ${DateHelper.format(result, "MMM DD, HH:mm a")}`
		return `Snoozed till ${DateHelper.format(result, "MMM DD YYYY, HH:mm a")}`
	}

	get isPopupBlocked() { return DomainSetting.checkBlocked(this.popupOption, this.popupUpdatedAt) }
	get popupBlockedUntil() {
		if (!this.isPopupBlocked) return null
		if (this.popupOption === -1) return 'Snoozed permanently'
		const result = DateHelper.addDay(this.popupUpdatedAt || this.updatedAt, this.popupOption)
		if (DateHelper.isSameYear(result, this.popupUpdatedAt || this.updatedAt)) return `Snoozed till ${DateHelper.format(result, "MMM DD, HH:mm a")}`
		return `Snoozed till ${DateHelper.format(result, "MMM DD YYYY, HH:mm a")}`
	}

	get isBlocked() { return this.isEmailBlocked || this.isPopupBlocked }
	get hasExpired() { return !this.isNewCreated && !this.isEmailBlocked && !this.isPopupBlocked }
	get status() {
		if (this.isEmailBlocked && this.isPopupBlocked) return 'All Emails & Pop-ups Blocked'
		if (this.isEmailBlocked) return 'All Emails Blocked'
		if (this.isPopupBlocked) return 'Pop-ups Blocked'
		return null
	}

	// Account & password
	isPasswordEnabled(allowPasswordOption) { return DomainSetting.isPasswordEnabled(this.passwordOption, allowPasswordOption) }
	formattedPassword(allowPasswordOption) { return DomainSetting.formattedPassword(this.passwordOption, allowPasswordOption) }

	// Consent
	isConsentEnabled(allowConsent) { return DomainSetting.isConsentEnabled(this.allowConsent, allowConsent) }
	formattedConsent(allowConsent) { return DomainSetting.formattedConsent(this.allowConsent, allowConsent) }

	//GPC
	isGPCEnabled(allowConsent) { return DomainSetting.isGPCEnabled(this.allowConsent, allowConsent) }
	formattedGPC(allowConsent) { return DomainSetting.formattedGPC(this.allowConsent, allowConsent) }
}