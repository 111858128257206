import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from 'routes'

export function TileGettingStarted({
	className = "",
}) {
	return (
		<div className={`${className} tile-getting-started`}>
			<div className="title">Getting started</div>
			<div className="description">
				Welcome to lockr! We’re pumped to have you. Start by using your lockr email on any site!
				<Link to={routes.Discover}>Discover some of our favorites.</Link>
			</div>
		</div>
	)
}