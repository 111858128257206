import { Alert, AlertPage, AlertTitle, HMenuDatePicker, Loader } from "components";
import { Api, useAlert } from "core";
import moment from "moment-timezone";
import React from "react";
import { DateHelper } from "utils";

export function LockrScanInfoAlert({
    user = {},
    onClose = () => { }
}) {
    const { error, setError } = useAlert()
    const [startDate, setStartDate] = React.useState(DateHelper.addDay(new Date(), -7))
    const [endDate, setEndDate] = React.useState(new Date())
    const [isLoading, setLoading] = React.useState(false)
    const [userStats, setUserStats] = React.useState({});
    const [wordpressLink, setWordpressLink] = React.useState(null);


    React.useEffect(() => {
        loadUserData();
    }, [startDate, endDate])

    const loadUserData = async () => {
        setLoading(true);
        const result = await Api.get('/lockrscan/admin/user-stats', { userId: user.id, startDate: DateHelper.format(startDate, 'YYYY-MM-DD'), endDate: DateHelper.format(endDate, 'YYYY-MM-DD') });
        // console.log(result)
        setUserStats(result.data)
        setWordpressLink(result.data?.permalink);
        setLoading(false)
    }
    return (
        <Alert className={`admin-userinfo-alert ${error && 'd-none'}`} onCancel={() => onClose()}>
            <AlertPage>
                <AlertTitle value={user.firstName + ' ' + user.lastName} />
                <div className="admin-userinfo-alert-content">
                    <div className="email">{user.email}</div>
                    <div className="email">{user.lockrEmail}</div>
                    <div className="member-since">Member since {moment(user.createdAt).format('MMMM YYYY')}</div>
                    <div className="last-login">{user.lastLogin}</div>
                    <div className="option-container">
                        <HMenuDatePicker
                            position="first"
                            initialLabel="Specific dates"
                            date={startDate}
                            onSelect={date => setStartDate(date)}
                        />
                        <span>to</span>
                        <HMenuDatePicker
                            position="first"
                            baseDate={startDate || new Date()}
                            date={endDate}
                            onSelect={date => setEndDate(date)}
                        />
                    </div>
                    <div className="list-container">
                        {(isLoading) ? <Loader /> :
                            <>
                                {wordpressLink &&
                                    <div className="tag-container">
                                        <div>Wordpress Link</div>
                                        <div className="tag-list">
                                            <div
                                                key="1"
                                                className="tag-item selected">
                                                <span>{wordpressLink}</span>
                                                <button className="clear"></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="list">
                                    <div className="list-item">
                                        <div>Emails Processed</div>
                                        <div>{userStats?.emailCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Senders Unsubscribed</div>
                                        <div>{userStats?.isArchivedCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Senders in lockrArchive </div>
                                        <div>{userStats?.unSubscribedCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Unique Senders</div>
                                        <div>{userStats?.uniqueSendersCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Shipments</div>
                                        <div>{userStats?.shipmentCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Total Discounts</div>
                                        <div>{userStats?.discountCount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Unique emails with discounts</div>
                                        <div>{userStats?.uniqueEmailDiscount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>lockrMail</div>
                                        <div>{userStats?.isLockrMailUser ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                                {userStats?.lockrMailStats && Object.keys(userStats?.lockrMailStats).length > 0 && <div className="list">
                                    <div>lockrMail Statistics</div>
                                    <div className="list-item">
                                        <div>Registration Date</div>
                                        <div>{moment(userStats?.lockrMailStats?.createdAt).format('MM/DD/YYYY')}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Verified emails</div>
                                        <div>{userStats?.lockrMailStats.countVerifiedEmails}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Senders</div>
                                        <div>{userStats?.lockrMailStats.countSenders}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Blocked emails</div>
                                        <div>{userStats?.lockrMailStats.countBlockedMails}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Redirected Emails</div>
                                        <div>{userStats?.lockrMailStats.countRedirectedEmails}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Outgoing Emails</div>
                                        <div>{userStats?.lockrMailStats.countOutgoingEmails}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Emails Opened</div>
                                        <div>{userStats?.lockrMailStats.totalEmailsOpened}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Installed Chrome Extension</div>
                                        <div>{userStats?.lockrMailStats.isExtensionInstalled ? "Yes" : "No"}</div>
                                    </div>
                                    <div className='list-item'>
                                        <div>lockrMail Clicks</div>
                                        <div>{userStats?.lockrMailStats.mailClicks ?? '0'}</div>
                                    </div>
                                    <div className='list-item'>
                                        <div>lockrDeals Clicks</div>
                                        <div>{userStats?.lockrMailStats.dealClicks ?? '0'}</div>
                                    </div>
                                    <div className='list-item'>
                                        <div>Extension Clicks</div>
                                        <div>{userStats?.lockrMailStats.extensionClicks ?? '0'}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Shipments</div>
                                        <div>{userStats?.lockrMailStats.lockrMailShipment}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Total Discounts</div>
                                        <div>{userStats?.lockrMailStats.lockrMailDiscount}</div>
                                    </div>
                                    <div className="list-item">
                                        <div>Unique emails with discounts</div>
                                        <div>{userStats?.lockrMailStats.lockrMailUniqueEmailDiscount}</div>
                                    </div>
                                </div>}
                            </>}</div>
                </div>
            </AlertPage>
        </Alert>)
}