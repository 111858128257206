import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom'

import './styles.scss'
import iconClose from 'assets/images/onboard_close.svg'
import iconLock from 'assets/images/onboard_lock.svg'

import {
	Alert, AlertPage, AlertMessage, AlertButtonContainer, AlertButton,
	TextField,
} from 'components'

import { Api } from 'core'
import { isLockrMail, isEmail, isValidText, Storage } from "utils"
import { Helmet } from "react-helmet"
import { config } from "constants/config"

export function ForgotPassword(props) {
	const navigate = useNavigate()
	const location = useLocation()

	const [email, setEmail] = useState(location.state?.email ?? '')
	const [error, setError] = useState('')
	useEffect(() => { setError('') }, [email])

	const [requested, setRequested] = useState(false)

	function handleClose(event) {
		event.preventDefault()
		navigate(-1)
	}

	function handleSubmit() {
		if (!isEmail(email)) { return }
		if (!isLockrMail(email)) {
			setError("Please use your lockrMail email address to reset your password.")
			return
		}

		Api.post("/request-forgot-password", { email })
			.then(response => {
				const { success, error } = response
				if (!success || error) {
					setError(error)
					return
				}
				setRequested(true)
			})
			.catch(error => {
				setError(Api.parseError(error, "Sorry, there are some troubles to send password reset link."))
			})
	}

	return (
		<div className="forgot-password">
			<Helmet>
				<title>lockrMail | Reset your password</title>
			</Helmet>
			<div className="forgot-password-close" onClick={handleClose}>
				<img src={iconClose} alt="Close" />
			</div>
			<div className="forgot-password-container">
				<div className="forgot-password-logo">
					<img src={iconLock} alt="Loc.kr" />
				</div>
				<div className="forgot-password-title">Forgot Password</div>
				<div className="forgot-password-message">Enter your email and we'll send you a link to reset your password.</div>
				<div className="forgot-password-form mt-48">
					<TextField
						type="large"
						id="emailInput"
						autoFocus
						placeholder="lockrMail email address"
						value={email}
						autoComplete domains={[config.REACT_APP_MAIL_ENVIRONMENT]}
						error={isValidText(error)}
						onChange={event => setEmail(event.target.value)}
						onNext={() => handleSubmit()} />
				</div>
				<div className="forgot-password-error">
					{isValidText(error) && error}
				</div>
				<div className="forgot-password-button">
					<button type="button" disabled={!isEmail(email)} onClick={() => handleSubmit()}>
						Submit
					</button>
				</div>
			</div>
			{requested &&
				<Alert onCancel={() => setRequested(false)}>
					<AlertPage>
						<AlertMessage value="If an account is associated with this email address, a reset link will be delivered to your inbox." />
						<AlertButtonContainer>
							<AlertButton title="OK" type="positive" onClick={() => { navigate(-1) }} />
							<AlertButton title="Contact Us" type="negative" onClick={() => window.open('https://lockrmail.com/contact', '_blank')} />
						</AlertButtonContainer>
					</AlertPage>
				</Alert>
			}
		</div>
	)
}
