import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "./core";

export function SessionExperiedAlert({
    onClose = () => { }
}) {
    return (
        <Alert onCancel={() => onClose()}>
            <AlertPage>
                <AlertTitle>
                    Session Expired
                </AlertTitle>
                <AlertMessage>
                    Your session has expired. Please log in to continue using lockrMail.
                </AlertMessage>
                <AlertButtonContainer>
                    <AlertButton onClick={() => { onClose() }}>Log in</AlertButton>
                </AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}