import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconNext from 'assets/images/arrow/next-white-10.svg'
import iconMinimize from 'assets/images/arrow/down-white-16x7.svg'

import {
	Confetti
} from 'components'

import {
	useAlert,
	User, useAuth, useSenders, useReferral,
	useStorage,
} from 'core'
import { isDesktop, isMobile, Storage, DOMHelper } from 'utils'
import { config, zIndexes } from '../../../constants'

const checklistKeys = [
	'isVerifiedPrimaryEmail',
	'isVerifiedSecondDestinationEmail',
	'isUsed5Senders',
	'isFilterUsed',
	'isExtensionInstalled',
	'isReferred'
]

const checklistLabels = [
	'Verify your email',
	'Add a second destination email',
	'Use your lockrMail email with 5 senders',
	'Set up a filter',
	`Download the <a href="${config.CRX_URL}" target="_blank">Chrome Extension</a>`,
	'Refer a friend'
]

export function Checklist({ }) {
	const { width: windowWidth } = useWindowSize()

	const { setError } = useAlert()
	const { currentUser, userGetProfile } = useAuth()
	const { senders } = useSenders()
	const { referrals } = useReferral()
	const { checklistMinimized, setChecklistMinimized } = useStorage()

	const titleRef = React.useRef()
	const mainRef = React.useRef()

	const [isClosed, setClosed] = React.useState(true)
	React.useLayoutEffect(() => { updateStyle() }, [checklistMinimized, isClosed])

	React.useEffect(() => {
		updateStyle()
		if (!checklistMinimized && windowWidth <= 992) {
			setChecklistMinimized(true)
		}
	}, [windowWidth])

	const [isLoading, setLoading] = React.useState(false)
	const [checklist, setChecklist] = React.useState({})
	React.useEffect(() => { calcProgress() }, [checklist])
	const [progress, setProgress] = React.useState(0)
	const [confettiSource, setConfettiSource] = React.useState(null)

	React.useEffect(() => {
		if (currentUser) setClosed(!currentUser.showChecklist)
		loadChecklist()
	}, [currentUser, senders, referrals])

	async function loadChecklist() {
		if (!currentUser) return

		setLoading(true)
		const { result } = await User.getChecklist()
		setLoading(false)

		setChecklist(result || {})
		if (windowWidth >= 992) {
			setChecklistMinimized(Storage.checklistMinimized)
		}
	}

	function calcProgress() {
		const checkedKeys = checklistKeys.filter(key => Boolean(checklist[key]))
		const progress = parseFloat(checkedKeys.length) / parseFloat(checklistKeys.length) * 100.0
		setProgress(progress)
	}

	function updateStyle() {
		// console.log(isMinimized)
		if (!titleRef.current || !mainRef.current) return

		const padding = isMobile ? (18) : (36)
		const minimizedHeight = DOMHelper.calcHeight(titleRef.current) + padding

		// Update right
		let right = "0px"
		if (isDesktop) {
			if (window.innerWidth <= 1440) {
				right = "91px"
			} else {
				const wrapperWidth = 1440 - 91 * 2
				right = `${(window.innerWidth - wrapperWidth) / 2}px`
			}
		}
		mainRef.current.style.right = right

		// Update minizied style
		// console.log("isClosed", isClosed)
		// console.log("isMinimized", isMinimized)
		if (checklistMinimized) {
			mainRef.current.style.bottom = `-${DOMHelper.calcHeight(mainRef.current) - minimizedHeight}px`
		} else {
			mainRef.current.style.bottom = "0"
		}

		setConfettiSource({
			x: DOMHelper.calcX(mainRef.current) + DOMHelper.calcWidth(mainRef.current) / 2,
			y: -50,
		})
	}

	function handleMinimize(event) {
		event.stopPropagation()

		Storage.setChecklistMinimized(!checklistMinimized)
		setChecklistMinimized(!checklistMinimized)
	}

	async function handleClose(event) {
		event.stopPropagation()

		if (!currentUser) return

		const { error } = await currentUser.closeChecklist()
		if (error) {
			setError(error)
			return
		}

		setClosed(true)
	}

	return (
		<>
			<div ref={mainRef} className={`dashboard-checklist ${checklistMinimized ? "minimized" : "maximized"} ${isClosed && "closed"}`} style={{ zIndex: zIndexes.Checklist }}>
				<div ref={titleRef} className="title-container">
					<div className="title">Getting started</div>
					<div className="subtitle">Some tips to make the most out of lockrMail.</div>
				</div>
				<div className="bar"><div style={{ width: `${progress}%` }}></div></div>
				<div className="checklist">
					{checklistKeys.map((key, index) => {
						const label = checklistLabels[index]
						const checked = (key in checklist) && checklist[key]
						return <ChecklistItem key={index} label={label} checked={checked} />
					})}
				</div>
				<div className="bottom-banner">
					<span>Need help? Get more tips and view tutorials in our <a href={config.HELP_CENTER_URL} target="_blank" rel="noopener">Help Center</a></span>
					<span><img src={iconNext} /></span>
				</div>
				<button className="close" onClick={handleClose}>Don’t show this again</button>
				<button className="minimize" onClick={handleMinimize}><img src={iconMinimize} alt="" /></button>
			</div>
			{(progress >= 100 && !checklistMinimized && !isClosed) &&
				<Confetti confettiSource={confettiSource} zIndex={zIndexes.Checklist} />
			}
		</>
	)
}

function ChecklistItem({
	label,
	checked
}) {
	return (
		<div className={`checklist-item ${checked && 'checked'}`}>
			<img className="checkmark" alt="" />
			<span className="label" dangerouslySetInnerHTML={{ __html: label }}></span>
		</div>
	)
}