import React from 'react'

export function HMenuCommand({
	command,
	onClick
}) {
	function handleClick(event) {
		event.preventDefault()
		event.stopPropagation()
		if (onClick) { onClick() }
	}

	return (
		<div
			className="h-menu-command"
			onClick={handleClick}>
			{command.icon && <img src={command.icon} alt="" />}
			{command.hoverIcon && <img src={command.hoverIcon} alt="" />}
			<div>{command.label}</div>
		</div>
	)
}