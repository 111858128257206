import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconNext from 'assets/images/arrow/next-white-10.svg'
import iconInfo from 'assets/images/info-white.svg'

import {
	Alert, AlertPage,
	IModal
} from 'components'
import { FilterModalHeaderEl } from './header'
import { FilterLibraryEl } from './library'
import { CustomFilterEl } from './custom'
import { SavedFiltersEl } from './saved-filters'

import {
	useAlert, useSenders, useFilter, Filter, Sender,
	FilterLibrariesProvider, CustomFilterProvider,
	FilterProvider,
	useFilterLibraries,
	useCustomFilter,
} from 'core'
import { ArrayHelper } from 'utils'
import { routeToMe } from 'routes'
import { errorMessages } from 'constants/error'

const TabIndex = Filter.Type

/**
 * @typedef {{initialSenders: Array<Sender>, sender: Sender, onClose: Function}} DigestFilterModalProps
 * @param {DigestFilterModalProps} param0
 */
function FilterModalEl({
	isDigest = false,
	onClose = (updated) => { },
}) {
	const navigate = useNavigate()
	const { width: windowWidth } = useWindowSize()

	const { alert, setAlert, error, setError, resetAlert } = useAlert()

	const { category, filterId, filter, setFilter, senders } = useFilter()
	const { selectionMap } = useFilterLibraries()
	const { isDefaultFilter, alwaysOn, from, to, keywords, filterByAttachment, hasAttachment, hasAttachmentOp, isSaveFilter, name, checkValidation, checkIfChanged } = useCustomFilter()
	const { refreshSenders } = useSenders()

	const iLibraryRef = React.useRef()
	const [iLibrary, setILibrary] = React.useState(null)
	React.useEffect(() => { setILibrary(iLibraryRef.current) }, [iLibraryRef])

	const iCustomRef = React.useRef()
	const [iCustom, setICustom] = React.useState(null)
	React.useEffect(() => { setICustom(iCustomRef.current) }, [iCustomRef])

	const iSavedRef = React.useRef()
	const [iSaved, setISaved] = React.useState(null)
	React.useEffect(() => { setISaved(iSavedRef.current) }, [iSavedRef])

	const [tabIndex, setTabIndex] = React.useState(TabIndex.Library)

	React.useEffect(() => {
		if (filter) {
			if (filter.hasLibraries) setTabIndex(TabIndex.Library)
			else setTabIndex(TabIndex.Custom)
		}
	}, [filter])

	function checkChanged() {
		if (filter) {
			return !ArrayHelper.equals(filter.filterLibraries, selectionMap) || checkIfChanged()
		}

		return !ArrayHelper.equals(selectionMap, []) || !isDefaultFilter()
	}

	function checkIsDisabledTab(tabIndex) {
		if (tabIndex === TabIndex.Library) return filter && !filter.hasLibraries && !isDefaultFilter()
		if (tabIndex === TabIndex.Custom) return filter && filter.hasLibraries && ArrayHelper.isValid(selectionMap)
		return false
	}

	function handleTabIndex(tabIndex) {
		if (tabIndex === TabIndex.Library && checkIsDisabledTab(tabIndex)) {
			setAlert({
				message: "This will override the Filter settings currently enabled for this Sender. Click confirm to continue.",
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: async () => {
							setTabIndex(tabIndex)
						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							return;

						}
					},]
			})
			return
		}

		if (tabIndex === TabIndex.Custom && checkIsDisabledTab(tabIndex)) {
			// setError('lockrMail enables its users to create custom filters. You are unable to write a custom filter if you have a preset filter already selected from the Filter Library. Please turn off the preset Filters for this Sender(s) if you\'d like to write a custom filter.')
			setAlert({
				message: "This will override the Filter settings currently enabled for this Sender. Click confirm to continue.",
				buttons: [
					{
						title: 'Confirm',
						type: 'positive',
						onClick: async () => {
							setTabIndex(tabIndex)
						}
					}, {
						title: 'Cancel',
						type: 'negative',
						onClick: () => {
							return;

						}
					},]
			})
			return
		}

		setTabIndex(tabIndex)
	}

	async function handleSave() {
		if (tabIndex === TabIndex.Custom && !checkValidation()) return

		const { error } = await Filter.updateOrCreate(filter, tabIndex, {
			senders,
			libraries: selectionMap,
			alwaysOn, from, to,
			keywords: keywords.filter(x => x.keyword),
			filterByAttachment, hasAttachment, hasAttachmentOp,
			isSaveFilter, name,
			isDigest: category === Filter.Category.Digest,
		})

		if (error) {
			if (error == errorMessages.EMPTY_FILTER) {
				setAlert({
					message: errorMessages.EMPTY_FILTER,
					buttons: [
						{
							title: 'Stay on this page',
							type: 'positive',
							onClick: async () => {
								return;
							}
						}, {
							title: 'Leave with no settings',
							type: 'negative',
							onClick: () => handleClose(true, true)
						},]
				})
				return;
			}
			setError(error)
			return
		}

		handleClose(true)
	}

	function handleClose(updated = false, force = false) {
		if (!updated && !force && checkChanged()) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving?',
				buttons: [
					{ type: 'positive', label: 'Stay on this page', },
					{ type: 'negative', label: 'Leave this page', onClick: () => handleClose(updated, true) },
				]
			})
			return
		}

		if (category === Filter.Category.Filter && updated) {
			refreshSenders(senders)
			if (filter) refreshSenders(filter.senders)
		}

		if (filterId) {
			navigate(routeToMe({ searches: { filterId: null } }))
		}

		onClose(updated)
	}

	return (
		<Alert
			className="filter-modal"
			show={!alert && !error}
			onCancel={() => handleClose(false)}>
			<AlertPage>
				<FilterModalHeaderEl
					tabIndex={tabIndex}
					onSave={() => handleSave()} />
				<div className="content">
					<div className="content-wrapper">
						<div className="tab-container">
							<div
								// className={`tab-item ${tabIndex === TabIndex.Library && 'selected'} ${checkIsDisabledTab(TabIndex.Library) && 'disabled'}`}
								className={`tab-item ${tabIndex === TabIndex.Library && 'selected'} `}
								onClick={(event) => handleTabIndex(TabIndex.Library)}>
								<div>
									<div className="title">
										Library <img ref={iLibraryRef} className="i-modal-anchor" src={iconInfo} alt="" />
										<IModal
											anchorEl={iLibrary}>
											Select any combination from our preset Filter Library.<br />Learn about preset Filters in the <a href="https://lockrmail.com/decoding-the-filter-library/" target="_blank">Help Center</a>
										</IModal>
									</div>
									<div className="description">Browse pre-set filters</div>
								</div>
								<img src={iconNext} />
							</div>
							<div
								// className={`tab-item ${tabIndex === TabIndex.Custom && 'selected'} ${checkIsDisabledTab(TabIndex.Custom) && 'disabled'}`}
								className={`tab-item ${tabIndex === TabIndex.Custom && 'selected'}`}
								onClick={(event) => handleTabIndex(TabIndex.Custom)}>
								<div>
									<div className="title">
										{(windowWidth >= 768) ? "Add new" : "New"} <img ref={iCustomRef} className="i-modal-anchor" src={iconInfo} alt="" />
										<IModal
											anchorEl={iCustom}>
											Emails that match the outlined criteria will be approved.<br />Learn about custom Filters in the <a href="https://lockrmail.com/faq/how-do-i-create-a-custom-filter/" target="_blank">Help Center</a>
										</IModal>
									</div>
									<div className="description">
										Customize your own
									</div>
								</div>
								<img src={iconNext} />
							</div>
							<div
								className={`tab-item ${tabIndex === TabIndex.Saved && 'selected'}`}
								onClick={(event) => handleTabIndex(TabIndex.Saved)}>
								<div>
									<div className="title">
										Saved<img ref={iSavedRef} className="i-modal-anchor" src={iconInfo} alt="" />
										<IModal
											anchorEl={iSaved}>
											Save your favorite custom Filters for future use!<br />Learn how to save Filters in the <a href="https://lockrmail.com/faq/can-i-save-my-favorite-filters/" target="_blank">Help Center</a>
										</IModal>
									</div>
									<div className="description">Reuse your favorites</div>
								</div>
								<img src={iconNext} />
							</div>
						</div>
						<div className="content-container">
							<FilterLibraryEl
								className={(tabIndex !== TabIndex.Library) ? 'd-none' : ''} />
							<CustomFilterEl
								isDigest={isDigest}
								className={(tabIndex !== TabIndex.Custom) ? 'd-none' : ''} />
							<SavedFiltersEl
								className={(tabIndex !== TabIndex.Saved) ? 'd-none' : ''}
								category={category}
								senders={senders}
								onChange={() => handleClose(true)}
								onEdit={(filter) => {
									setFilter(filter)
									setTabIndex(TabIndex.Custom)
								}} />
						</div>
					</div>
				</div>
			</AlertPage>
		</Alert>
	)
}

export function FilterModal({
	isDigest = false,
	category = "filter",
	initialSenders = [],
	onClose = (updated) => { },
}) {
	const { filter } = useFilter()
	return (
		<FilterProvider category={category} initialSenders={initialSenders}>
			<FilterLibrariesProvider filter={filter}>
				<CustomFilterProvider>
					<FilterModalEl
						onClose={(updated) => onClose(updated)}
						isDigest={isDigest}
					/>
				</CustomFilterProvider>
			</FilterLibrariesProvider>
		</FilterProvider>
	)
}