import { User } from "core";
import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "./core";
import googleSigninButton from '../../assets/images/google_button.png'
import { errorCodes } from "constants/error";
import React from "react";

export function ErrorGoogleSignInAlert(
    { onCancel = () => { },
        onButtonClick = () => { },
        errStatus = null }
) {
    const [title, setTitle] = React.useState("");
    const [errMessages, setErrMessages] = React.useState([]);
    React.useEffect(() => {

        if (errStatus == errorCodes.LOCKRSCAN_GMAIL_PERMISSIONS_NOT_PROVIDED) {
            setTitle("Permissions Required");
            setErrMessages(['Your have not provided the necessary permissions to activate lockrScan.', 'Please sign in again and grant full access.'])
        }
        else if (errStatus == errorCodes.LOCKRSCAN_ACCOUNT_ALREADY_SYNCED) {
            setTitle("Error")
            setErrMessages(['This Gmail account is associated with a different lockrMail account.', 'Please sign in with a unique Gmail.'])
        }
    }, [])
    return (
        <Alert onCancel={() => { onCancel(); }}>
            <AlertPage>
                <AlertTitle>{title}</AlertTitle>
                {errMessages?.map(errMessage => (
                    <AlertMessage>{errMessage}</AlertMessage>
                ))}

                <AlertButtonContainer>
                    <img src={googleSigninButton} style={{ height: 63, width: 263, cursor: "pointer" }} onClick={async () => {
                        onButtonClick();
                    }} ></img></AlertButtonContainer>
            </AlertPage>
        </Alert>
    )
}