import React from 'react'

import './styles.scss'

import { Loader } from 'components'

export class InfiniteScroll extends React.Component {
	ref = React.createRef()
	constructor(props) {
		super(props)
	}

	componentDidMount() { this.ref.current?.addEventListener('scroll', this.handleScroll) }
	componentWillUnmount() { this.ref.current?.removeEventListener('scroll', this.handleScroll) }

	handleScroll = () => {
		const element = this.ref.current
		if (!element) return

		if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
			this.handleLoadNext()
		}
	}

	handleLoadNext = () => {
		const { hasMore = true, isLoading = false, loadNext = () => { } } = this.props

		if (!hasMore || isLoading) return

		loadNext()
	}

	render() {
		const { className = '', isLoading = false, loader = <Loader />, children } = this.props
		return (
			<div ref={this.ref} className={`${className} infinite-scroll`}>
				{children}
				{isLoading && loader}
			</div>
		)
	}
}