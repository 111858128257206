import { publicRoutes } from './public'
import { privateRoutes } from './private'
import { adminRoutes } from './admin'
import { deepLinkRoutes } from './deep-link'
import { lockrScanRoutes } from './lockrscan'

export const routes = Object.freeze({
	...publicRoutes,
	...privateRoutes,
	...adminRoutes,
	...deepLinkRoutes,
	...lockrScanRoutes,

	Category: Object.freeze({
		...publicRoutes.Category,
		...privateRoutes.Category,
		...adminRoutes.Category,
	}),
})

export function isPublicRoute(route) {
	return Object.keys(publicRoutes).filter(x => x !== 'Category').some(x => route?.includes(publicRoutes[x]))
}

export function isAdminRoute(route) {
	return Object.keys(adminRoutes).filter(x => x !== 'Category').some(x => route?.includes(adminRoutes[x]))
}

export function isPrivateRoute(route) {
	return isAdminRoute(route) || Object.keys(privateRoutes).filter(x => x !== 'Category').some(x => route?.includes(privateRoutes[x]))
}

export function isDeepLink(route) {
	return Object.keys(deepLinkRoutes).some(x => route?.includes(deepLinkRoutes[x]))
}

export function redirectPath({ route, category = null, searches = {}, state = undefined }) {
	const searchKeys = Object.keys(searches).filter(key => searches[key])
	let search = null
	if (searchKeys.length > 0) {
		search = "?"
		for (let i = 0; i < searchKeys.length; i++) {
			const key = searchKeys[i]
			search += `${key}=${encodeURIComponent(searches[key])}`
			if (i !== searchKeys.length - 1) search += "&"
		}
	}

	const pathname = category ? `${route}/${category}` : route
	return {
		pathname,
		search,
		state
	}
}

function convertSearches(params) {
	const searchKeys = Object.keys(params).filter(key => params[key])
	let search = null
	if (searchKeys.length > 0) {
		search = "?"
		for (let i = 0; i < searchKeys.length; i++) {
			const key = searchKeys[i]
			search += `${key}=${encodeURIComponent(params[key])}`
			if (i !== searchKeys.length - 1) search += "&"
		}
	}
	return search ?? ""
}

export function redirectTo({ route, category = null, searches = {} }) {
	const pathname = category ? `${route}/${category}` : route
	const search = convertSearches(searches)
	return { pathname, search }
}

export function routeToMe({ pathname = null, searches = {} }) {
	const prevSearches = parseSearchParams(new URLSearchParams(window.location.search))
	return {
		pathname: pathname ?? window.location.pathname,
		search: convertSearches({ ...prevSearches, ...searches })
	}
}

/** @param {URLSearchParams} searchParams */
export function parseSearchParams(searchParams) {
	const result = {}
	for (const entry of searchParams.entries()) {
		const [key, value] = entry
		result[key] = value
	}
	return result
}