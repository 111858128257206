import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Filter, Sender } from 'core/models'
import { useAlert } from 'core/providers/alert'
import { routeToMe, parseSearchParams } from 'routes'
import { ArrayHelper } from 'utils'
import { useLocation } from 'react-use'

const FilterContext = React.createContext({})

export function FilterProvider({
	category = Filter.Category.Filter,
	initialSenders,
	children,
}) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const location = useLocation();

	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [filterId, setFilterId] = React.useState(null)
	React.useEffect(() => {
		if (filterId) loadFilter()
		else setFilter(null)
	}, [filterId])

	/** @type {[Filter, Function]} */
	const [filter, setFilter] = React.useState(null)
	const [senders, setSenders] = React.useState([])
	React.useEffect(() => { if (ArrayHelper.isValid(initialSenders)) setSenders(initialSenders) }, [initialSenders])

	async function loadFilter() {
		setLoading(true)
		const { error, result } = await Filter.getFilter({ id: filterId }, location.state?.usr?.isDigest ? location.state?.usr?.isDigest : false)
		setLoading(false)

		if (error || !result) {
			navigate(routeToMe({ searches: { filterId: null } }))
			return
		}

		setFilter(result)
		setSenders(result?.senders ?? [])
	}

	React.useEffect(() => { parseLocation() }, [searchParams])
	function parseLocation() {
		const { filterId } = parseSearchParams(searchParams)
		setFilterId(filterId)
	}

	const memoedValue = React.useMemo(() => ({
		category,
		isLoading,
		filterId,
		filter, setFilter,

		senders, setSenders,
	}), [category, isLoading, filterId, filter, senders])

	return (
		<FilterContext.Provider value={memoedValue}>
			{children}
		</FilterContext.Provider>
	)
}

/**
 * @typedef {{category: string, isLoading: boolean, filterId: string, filter: Filter, setFilter: Function, senders: Array<Sender>, setSenders: *}} UseFilter
 * @returns {UseFilter}
 */
export function useFilter() {
	return React.useContext(FilterContext)
}