import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconCheckboxUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconCheckboxChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconSendMail from 'assets/images/send-mail-8B8B8B.svg'
import iconSendMailHover from 'assets/images/send-mail-61CEF7.svg'
import iconClose from 'assets/images/cross-12px-8B8B8B.svg'
import spamLabel from 'assets/images/spam-label.svg'

import {
	Loader,
	Checkbox,
	IconButton,
	TipAlert,
	Button,
} from 'components'

import { useAlert, Mail } from 'core'
import { config } from 'constants/config'

export function MailListItem({
	className = '',
	mail: initialMail,
	selected = false,
	responsive = { breakpoint: 992 },
	onSelect = (value) => { },
}) {
	const { breakpoint } = responsive

	const { width: windowWidth } = useWindowSize()
	const { setError } = useAlert()

	const containerRef = React.useRef()

	const [isLoading, setLoading] = React.useState(false)
	const [mail, setMail] = React.useState(initialMail)
	React.useEffect(() => {
		if (initialMail) setMail(initialMail)
	}, [initialMail])

	const [clickCount, setClickCount] = React.useState(0)
	const [isFrustrated, setFrustrated] = React.useState(false)
	React.useEffect(() => { if (clickCount >= 3) setFrustrated(true) }, [clickCount])
	React.useEffect(() => { if (!isFrustrated) setClickCount(0) }, [isFrustrated])

	async function reloadMail() {
		if (!mail?.id) return

		setLoading(true)
		const { error, result } = await Mail.get(mail.id)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		if (result) setMail(result)
	}

	/**
	 * @param {MouseEvent} event
	 */
	function handleClick(event) {
		setClickCount(clickCount + 1)
	}

	/**
	 * @param {MouseEvent} event
	 */
	async function handleDeliver(event) {
		event?.stopPropagation()

		if (!mail.id) return

		setLoading(true)
		const { error } = await Mail.deliver([mail.id])
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		reloadMail()
	}

	/**
	 * @param {MouseEvent} event
	 */
	function handleCloseFrustrated(event) {
		event?.stopPropagation()

		setFrustrated(false)
	}

	return (
		<div ref={containerRef} className={`mail-list-item ${className}`} onClick={handleClick}>
			{isLoading ? <Loader /> :
				<div className='mail-list-item-container'>
					<Checkbox
						id={mail.id}
						iconChecked={iconCheckboxChecked}
						iconUnchecked={iconCheckboxUnchecked}
						enabled={mail.isAbleToDeliver}
						checked={selected}
						onChange={(value) => onSelect(value)} />
					<div className="info">
						<div className="subject">{mail.isSpam && <img src={spamLabel} />} <span className='subject-text'>{mail.subject} </span></div>
						<div className="date">
							<span>Sent {mail.formattedSentAt} {(mail.hasDeliveredTimes || mail.hasRespondedAt) && '•'}</span>
							{mail.hasDeliveredTimes &&
								<span> Delivered {mail.formattedDeliveredAt} {mail.hasRespondedAt && '•'}</span>
							}
							{mail.hasRespondedAt &&
								<span> Responded {mail.respondedAt}</span>
							}
						</div>
					</div>
					{mail.isAbleToDeliver &&
						<IconButton
							className="btn-deliver btn-manual-deliver-mail"
							normal={iconSendMail}
							hover={iconSendMailHover}
							onClick={(event) => handleDeliver(event)} />
					}
					{mail.hasDeliveredTimes &&
						<div className="delivered-times">
							<div>Delivered:</div>
							<div dangerouslySetInnerHTML={{ __html: mail.formattedDeliveredTimes }}></div>
						</div>
					}
				</div>
			}
			{(isFrustrated && windowWidth >= breakpoint) &&
				<div className='mail-list-item-frustrated'>
					<span>Tip: </span>
					Allowed emails are delivered to your Destination Email - please check the associated email account to view the email.&nbsp;
					<a className='learn-more' href={`${config.LOCKR_URL}/faq/can-i-view-blocked-emails-how/`} target="_blank">Learn More.</a>
					<Button className='close-frustrated' onClick={handleCloseFrustrated}><img src={iconClose} alt='' loading='lazy' /></Button>
				</div>
			}
			{(isFrustrated && windowWidth < breakpoint) &&
				<TipAlert
					message='Allowed emails are delivered to your Destination Email - please check the associated email account to view the email.'
					url={`${config.LOCKR_URL}/faq/can-i-view-blocked-emails-how/`}
					onClose={() => setFrustrated(false)} />
			}
		</div>
	)
}