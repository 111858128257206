import React, { useEffect, useState } from "react";

import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "../core";
import './styles.scss';
import paypalImage from '../../../assets/images/paypal.svg'
import { BlueButton } from "components/button";
import { useAuth, User } from "core";
import { Select, SelectOption } from "components/select";
import back from '../../../assets/images/arrow/back_bold_gray.svg'

const PAGES = Object.freeze({
    SELECT_COMPANY: 'SELECT-COMPANY',
    OTHER: 'OTHER',
    PAYPAL_EMAIL_SELECTION: 'PAYPAL-EMAIL',
    SUCCESSFUL: 'SUCCESSFUL'
})

function PaymentTab({ company = 'PAYPAL', onClick = () => { }, selected = false, icon = <></> }) {
    return <div className={`payment-tab ${selected && 'selected'}`} onClick={() => { onClick(company) }}>
        {icon}
    </div>
}



export function LockrPayWithdrawl({
    onClose = () => { },
}) {
    const [selectedCompany, setSelectedCompany] = useState('PAYPAL');
    const [withdrawDisabled, setWithdrawDisabled] = useState(false);
    const [amount, setAmount] = useState();
    const [showEmailPrompt, setShowEmailPrompt] = useState(false);
    const [page, setPage] = useState(PAGES.SELECT_COMPANY);
    const [emailObject, setEmailObject] = useState(null);
    const [emailError, setEmailError] = useState('');
    const { currentUser, userGetProfile, updateCurrentUser } = useAuth();
    const [otherMessage, setOtherMessage] = useState("")
    const [otherMessageDisabled, setOtherMessageDisabled] = useState(true);

    useEffect(() => {
        console.log(amount, amount <= 0, !amount, currentUser.lockrPay?.availableCPA, amount > currentUser.lockrPay?.availableCPA)
        if (amount <= 0 || !amount || amount > parseFloat(currentUser.lockrPay?.availableCPA)) {
            setWithdrawDisabled(true);
        }
        else {
            setWithdrawDisabled(false)
        }
    }, [amount])
    const handleWithdrawClick = () => {
        if (!withdrawDisabled) {
            setShowEmailPrompt(true);
            setPage(PAGES.PAYPAL_EMAIL_SELECTION)
        }
    };
    useEffect(() => {

        if (!otherMessage.trim()) {

            setOtherMessageDisabled(true);
        }
        else {
            setOtherMessageDisabled(false)
        }
    }, [otherMessage])





    const handleEmailSubmit = async () => {
        if (validateEmail(emailObject?.email)) {
            setShowEmailPrompt(false)

            const response = await User.generatePayout('PAYPAL', emailObject.id, amount)
            if (response.error) {
                setEmailError(response?.error)
            }
            else {
                await updateCurrentUser({ lockrPay: { totalCPA: (Math.round((currentUser?.lockrPay?.totalCPA - amount) * 100) / 100).toFixed(2), availableCPA: (Math.round((currentUser?.lockrPay?.availableCPA - amount) * 100) / 100).toFixed(2) } })

                setPage(PAGES.SUCCESSFUL)
            }

        } else {
            setEmailError("Please enter a valid email address.");
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const companyArrayIcon = [
        {
            company: 'PAYPAL',
            icon: <img src={paypalImage} />,

        },
        {
            company: 'OTHER',
            icon: <div>Other</div>
        }
    ];

    const handleInput = (e) => {
        const inputValue = e.target.value;
        if (inputValue == '0') {
            setAmount(0);
            return;
        }
        if (inputValue === '' || parseFloat(inputValue)) {

            const decimalParts = inputValue.split('.');
            if (
                decimalParts.length <= 2 &&
                (decimalParts.length === 1 || decimalParts[1].length <= 2)
            ) {
                setAmount(inputValue);
            }
        }
    };
    return (
        <Alert containerClassName="lockrPay-withdrwal-alert" onCancel={() => onClose()} hideCloseIcon={false}>
            {page == PAGES.SELECT_COMPANY ? <AlertPage>
                <AlertTitle>Withdraw</AlertTitle>

                <AlertMessage>
                    <div className="lockrPay-select-account">Select Account</div>
                    <div className="lockrPay-accounts-wrapper">
                        {companyArrayIcon.map((com) => (
                            <PaymentTab company={com.company} icon={com.icon} selected={selectedCompany == com.company} onClick={(com) => { if (com == PAGES.OTHER) { setPage(PAGES.OTHER) } else { setSelectedCompany(com) } }} />
                        ))}
                    </div>
                    <div className="lockrPay-withdrawl-wrapper">
                        <div className="lockrPay-withdrawl-input-wrapper">
                            <div className="withdraw-amount">Withdraw Amount
                                <div className="withdraw-reminder">Reminder: A <span style={{ fontWeight: 'bold' }}>$0.25</span> processing fee applies to each lockrPay balance withdrawal.</div>
                            </div>

                            <div className="lockrPay-winthdraw-input-wrapper">
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }} className="lockrPay-winthdraw-input"><span>$</span><input placeholder="0.00" style={{ background: 'transparent', color: 'white', boxShadow: 'unset', border: 'none', fontSize: 18, width: '100%' }} type="number" value={amount} onChange={handleInput} /></div>
                                <div className="withdraw-button-wrapper"><button className={`withdraw-button ${withdrawDisabled && "disabled"}`} onClick={() => handleWithdrawClick()} disabled={withdrawDisabled}>Withdraw</button></div>
                            </div>
                        </div>
                        <div className="balance-wrapper">
                            <div className="balance-item-wrapper">
                                <div className="balance-header">
                                    Total Balance
                                </div>
                                <div className="balance">
                                    ${currentUser?.lockrPay?.totalCPA ?? 0}
                                </div>
                            </div>
                            <div className="balance-item-wrapper">
                                <div className="balance-header">
                                    Available
                                </div>
                                <div className="balance">
                                    ${currentUser?.lockrPay?.availableCPA ?? 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </AlertMessage>

            </AlertPage> : page == PAGES.PAYPAL_EMAIL_SELECTION ?
                <AlertPage>
                    <div className='alert-back' style={{ cursor: 'pointer' }} onClick={() => setPage(PAGES.SELECT_COMPANY)}>
                        <img src={back} />
                        Back
                    </div>
                    <div style={{ fontSize: 28, fontWeight: 'bold', marginTop: 20 }}>
                        Select Email
                    </div>
                    <AlertMessage>
                        <div className="email-prompt">
                            Select your PayPal email to proceed with the withdrawal.
                            {/* <input
                                className="lockrPay-winthdraw-input"
                                style={{ marginTop: 12, marginBottom: 12, background: '#1d1d1d', padding: 24, color: 'white', boxShadow: 'unset', border: 'none', fontSize: 18, width: '100%' }}
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); setEmailError(''); }}

                            /> */}
                            <Select
                                keepLabel
                                label={emailObject?.email ?? "Select Email"}
                                className={'email-select'}
                                value={emailObject}
                                onChange={(value) => setEmailObject(value)}>
                                {currentUser?.connectedEmails?.filter(ce => ce.verifiedAt !== null)?.map((x) =>
                                    <SelectOption key={x.email} value={x}>{x.email}</SelectOption>
                                )}
                            </Select>
                            {emailError && <div style={{ color: 'red', marginTop: 10 }}>{emailError}</div>}
                            <div className="withdraw-button-wrapper">

                            </div>
                        </div>
                    </AlertMessage>
                    <AlertButtonContainer>
                        <AlertButton disabled={!validateEmail(emailObject?.email)} onClick={() => handleEmailSubmit()}>
                            Confirm
                        </AlertButton>
                    </AlertButtonContainer>
                </AlertPage> : page == PAGES.OTHER ? <AlertPage>
                    <div className='alert-back' style={{ cursor: 'pointer' }} onClick={() => setPage(PAGES.SELECT_COMPANY)}>
                        <img src={back} />
                        Back
                    </div>
                    <div style={{ fontSize: 28, fontWeight: 'bold', marginTop: 20 }}>
                        Other Withdrawal
                    </div>
                    <AlertMessage>
                        <div className="other-tab-text-description">
                            lockrPay currently supports PayPal and will be adding additional options in the future. Please request other vendors within this field and we will prioritize their integration based on user feedback.

                            <div style={{ marginTop: 24 }}>In certain circumstances we will facilitate a manual withdrawal.
                                Provide details within this form.</div>
                        </div>
                        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 16 }}>
                            <div className="other-tab-head">Message:</div>
                            <textarea className="other-tab-input" placeholder="Type your message..." onChange={(e) => { setOtherMessage(e.target.value) }} value={otherMessage} style={{ color: 'white', boxShadow: 'unset', fontSize: 14, width: '100%' }} ></textarea>
                        </div>
                        <div style={{ marginTop: 30, display: 'flex', gap: 10 }}>
                            <button onClick={(async () => { await User.sendOtherPayout(otherMessage); onClose() })} disabled={otherMessageDisabled} className={`other-send-message-button ${otherMessageDisabled && "disabled"}`}>
                                Send Message
                            </button>
                            <button onClick={() => setPage(PAGES.SELECT_COMPANY)} className="other-back-button">
                                Back
                            </button>
                        </div>
                    </AlertMessage>
                </AlertPage> : <AlertPage>
                    <AlertTitle>
                        Withdraw Successful
                    </AlertTitle>
                    <AlertMessage>
                        Your funds will be deposited into your account within 3 working days we’ve sent an email confirmation with more information.

                        <div className="withdraw-modal">
                            <div className="you-withdrew">
                                <div>You withdrew:</div>
                                <div>${parseFloat(amount)?.toFixed(2)}</div>
                            </div>
                            <div className="balance-information">
                                <div>
                                    <div>New Balance:</div>
                                    <div style={{ marginTop: 4 }}>${currentUser?.lockrPay?.totalCPA ?? 0}</div>
                                </div>
                                <div>
                                    <div>New Available Balance:</div>
                                    <div style={{ marginTop: 4 }}>${currentUser?.lockrPay?.availableCPA ?? 0}</div>
                                </div>
                            </div>

                        </div>
                        <div style={{ marginTop: 30, display: 'flex', gap: 10 }}>
                            <button className="close" onClick={onClose}>Close</button>
                            <button className="withdraw-more" onClick={() => { setPage(PAGES.SELECT_COMPANY); setAmount(''); }}>Withdraw More</button>
                        </div>
                    </AlertMessage>
                </AlertPage>}


        </Alert>
    )
}