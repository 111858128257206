import { plainToClass } from 'class-transformer'

export class AdminAccountState {
	total
	totalW1Sender
	totalActives
	changePercent
	ticks = []
	post7DayActive
	post30DayActive

	static fromJSON(json) {
		const result = plainToClass(AdminAccountState, json)
		result.ticks = (json?.ticks ?? []).map(tick => {
			return {
				'current': AdminAccountStateTick.fromJSON(tick.current),
				'previous': AdminAccountStateTick.fromJSON(tick.previous)
			}
		})
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminAccountState.fromJSON(json))
	}
}

export class AdminAccountStateTick {
	from
	to
	accounts
	actives

	static fromJSON(json) {
		return plainToClass(AdminAccountStateTick, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminAccountStateTick.fromJSON(json))
	}
}