import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
	Alert, AlertPage, AlertMessage, AlertButtonContainer, AlertButton
} from '../'
import { errorMessages } from '../../constants'
import { routes, redirectTo } from 'routes'

export function DeleteDomainSettingsAlert({
	domainSetting = {},
	message = errorMessages.DELETE_DOMAIN_SETTING_FOR_FILTER_ALLOW_BLOCK,
	onClose = () => { }
}) {
	const { domain } = domainSetting

	const navigate = useNavigate()

	function handleDomainSettings() {
		navigate(redirectTo({
			route: routes.Account,
			category: routes.Category.Account.Domains,
			searches: { domain }
		}))
	}

	return (
		<Alert onCancel={() => onClose()}>
			<AlertPage>
				<AlertMessage>{message}</AlertMessage>
				<AlertButtonContainer>
					<AlertButton onClick={() => handleDomainSettings()}>Domains</AlertButton>
					<AlertButton type="negative" onClick={() => onClose()}>Cancel</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}