export const publicRoutes = Object.freeze({
	Login: '/login',
	Register: '/signup',
	ForgotUsername: '/forgot-username',
	ForgotPassword: '/forgot-password',

	Category: Object.freeze({
		Register: Object.freeze({
			'PersonalEmail': 'personal-email',
			'Name': 'name',
			'LockrMail': 'lockrmail',
			'Password': 'password'
		}),
	})
})