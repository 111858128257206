import React from 'react'

import './styles.scss'
import iconClear from 'assets/images/cross/gray-16.svg'

import {
    Loader,
    SearchField,
    Select, SelectOption,
    Pagination,
    TableBatchContainer, BadgeButton, BlueButton,
} from 'components'
import { ListItem } from './list-item'
import { AddWhiteListIPAlert } from './add-whitelist-ip-alert'

import {
    useAlert,
    useAuth,
    AdminWhiteListIPProvider, useAdminWhiteListIP, AdminWhiteListIP
} from 'core'
import { ArrayHelper } from 'utils'

export function WhiteListIPAddressEl({
    className = '',
}) {
    const { setError } = useAlert()
    const { currentUser } = useAuth()
    const { isLoading, totalCount, whiteListIPAddrs, adminWhiteListIPSearch } = useAdminWhiteListIP()

    const [searchText, setSearchText] = React.useState('')
    const [pageIndex, setPageIndex] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(5)
    const [sortBy, setSortBy] = React.useState("createdAt")

    /** @type {[Array<AdminWhiteListIP>, Function]} */
    const [selectedAddrs, setSelectedAddrs] = React.useState([])
    React.useEffect(() => { setSelectedAddrs(whiteListIPAddrs.filter(x => selectedAddrs.some(y => y.ipAddr === x.ipAddr))) }, [whiteListIPAddrs])

    const [isAddNew, setAddNew] = React.useState(false)

    React.useEffect(() => {
        load()
    }, [currentUser, searchText, pageIndex, pageSize, sortBy])

    function load() {
        if (!currentUser?.isAdmin) return

        adminWhiteListIPSearch({ searchText, pageIndex, pageSize, sortBy })
    }

    /** @param {AdminWhiteListIP} whiteListIPAddress */
    function handleSelectAddress(whiteListIPAddress) {
        if (!whiteListIPAddress?.ipAddr) return

        if (selectedAddrs.some(x => x.ipAddr === whiteListIPAddress.ipAddr)) {
            setSelectedAddrs(selectedAddrs.filter(x => x.ipAddr !== whiteListIPAddress.ipAddr))
        } else {
            setSelectedAddrs([...selectedAddrs, whiteListIPAddress])
        }
    }

    async function handleBatchDelete() {
        const { error } = await AdminWhiteListIP.delete(selectedAddrs)
        if (error) {
            setError(error)
            return
        }

        load()
    }

    return (
        <React.Fragment>
            <div className='admin-whitelist-ip-address'>
                <div className="total">Total: {totalCount}</div>
                <div className='header'>
                    <TableBatchContainer
                        selected={ArrayHelper.isValid(selectedAddrs)}
                        onSelectAll={(value) => setSelectedAddrs(value ? whiteListIPAddrs : [])}>
                        <BadgeButton
                            icon={iconClear}
                            description="Delete"
                            onClick={() => handleBatchDelete()} />
                    </TableBatchContainer>
                    <SearchField
                        value={searchText}
                        onChange={value => setSearchText(value)} />
                    <Select
                        className="view-options"
                        value={sortBy}
                        onChange={(value) => setSortBy(value)}>
                        <SelectOption value="createdAt">Newest</SelectOption>
                        <SelectOption value="ipAddr">Alphabetical</SelectOption>
                    </Select>
                    <BlueButton
                        className="btn-add"
                        type="medium"
                        onClick={() => setAddNew(true)}>
                        Add
                    </BlueButton>
                </div>
                <div className='list'>
                    <ListItem isHeader />
                    {isLoading ? <Loader /> :
                        whiteListIPAddrs.map((item, index) => {
                            const selected = selectedAddrs.some(x => x.email === item.email)
                            return (
                                <ListItem
                                    key={index}
                                    whiteListIPAddress={item}
                                    selected={selected}
                                    onSelect={() => handleSelectAddress(item)} />
                            )
                        })
                    }
                </div>
                <Pagination
                    className="admin-senders-pagination"
                    totalSize={totalCount}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    onPageIndex={value => setPageIndex(value)}
                    onPageSize={value => setPageSize(value)}
                />
            </div>
            {isAddNew &&
                <AddWhiteListIPAlert
                    onClose={(updated) => {
                        setAddNew(false)
                        if (updated) load()
                    }} />
            }
        </React.Fragment>
    )
}

export function AdminWhiteListIPAddressEl() {
    return (
        <AdminWhiteListIPProvider>
            <WhiteListIPAddressEl />
        </AdminWhiteListIPProvider>
    )
}