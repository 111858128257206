import React from "react";
import { Alert, AlertPage, AlertTitle, BlueButton, Select, SelectGroup, SelectOption } from 'components';
import { useAlert } from "core";
import './styles.scss'
import CaretPrev from 'assets/images/caret-prev-FFFFFF.svg'


export function MobileDashboardFilterModal({
    onClose,
    senderStatus,
    onOptionSenderStatus,
    senderGroups,
    checkIfGroupExistsInFilter,
    onGroupFilterSelected,
    groupSearch,
    selectedGroupFilters,
    onDigestFilterSelected,
    myDigests,
    checkIfDigestExistsInFilter,
    digestSearch,
    selectedDigestFilters,
    handleResetViewOptions,
    selectedFilterRenderer,
    onSenderStatusSelected,
    onGroupAllFilterSelected,
    checkIfFilterDisabled,
    selectedConnectedEmailId,
    onSelectConnectedEmailId = (selectedConnectedEmailId) => { },
    connectedEmails
}) {

    const { alert, error } = useAlert();

    return (
        <Alert
            wrapperClassName='mobile-filter-parent'
            show={!alert && !error}
            onCancel={onClose}
            className='mobile-filter-container-content'
        >
            <AlertPage>
                <AlertTitle>Filter Results</AlertTitle>
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                    <span className='your-filter-text'>Your filters</span>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <span onClick={handleResetViewOptions} className='reset-filter-text'>Reset</span>
                    </div>
                </div>
                {selectedFilterRenderer()}
                <div className='group-divider'></div>
                <div className='destination-email-container'>
                    <div className='filter-text-count-container'>
                        <span className='your-filter-text'>Destination Email</span>
                        <div className='status-text-count-container'>
                        </div>
                    </div>
                    <Select
                        inputPlaceholder={!selectedConnectedEmailId ? 'All' : 'Select'}
                        className={'sender-status-select'}
                    >
                        <SelectGroup>
                            <SelectOption
                                newCheckbox={true}
                                selected={selectedConnectedEmailId === null}
                                onClick={() => { onSelectConnectedEmailId(null); }}
                            >
                                View All
                            </SelectOption>

                            {connectedEmails.map((connectedEmail, index) => (
                                <SelectOption
                                    key={connectedEmail.id}
                                    newCheckbox={true}
                                    selected={selectedConnectedEmailId?.includes(connectedEmail.id)}
                                    onClick={() => { onSelectConnectedEmailId(connectedEmail); }}
                                >
                                    {connectedEmail.email}
                                </SelectOption>
                            ))}

                        </SelectGroup>
                    </Select>
                </div>
                <div className='sender-status-container'>
                    <div className='filter-text-count-container'>
                        <span className='your-filter-text'>Sender Status</span>
                        <div className='status-text-count-container'>
                        </div>
                    </div>
                    <Select
                        inputPlaceholder={'Select'}
                        className={'sender-status-select'}
                        keepLabel
                    >
                        <img alt="" src={CaretPrev} />
                        <label className="mobile-select-header-text">Sender Status</label>
                        <SelectGroup>
                            <SelectOption newCheckbox={true} selected={senderStatus == 'all'} onClick={() => { onSenderStatusSelected('all', 'All Senders') }}>All</SelectOption>
                            <SelectOption newCheckbox={true} selected={senderStatus == 'blocked'} onClick={() => onSenderStatusSelected('blocked', 'Blocked')}>Blocked</SelectOption>
                            <SelectOption newCheckbox={true} selected={senderStatus == 'allowed'} onClick={() => onSenderStatusSelected('allowed', 'Allowed')}>Allowed</SelectOption>
                            <SelectOption newCheckbox={true} selected={senderStatus == 'filtered'} onClick={() => onSenderStatusSelected('filtered', 'Filtered')}>Filtered</SelectOption>
                            <SelectOption newCheckbox={true} selected={senderStatus == 'unclassified'} onClick={() => onSenderStatusSelected('unclassified', 'Unclassified')}>Unclassified</SelectOption>
                        </SelectGroup>
                    </Select>
                </div>
                <div className='group-filter-container'>
                    <div className='filter-text-count-container'>
                        <span className='your-filter-text'>Groups</span>
                        <div className='text-count-container'>
                            <span className='filter-text-count'>{senderGroups.length}</span>
                        </div>
                    </div>
                    <Select
                        className={'sender-status-select'}
                        inputPlaceholder={'Select'}
                        keepLabel={'Select'}
                        onSearchValueChange={val => setGroupSearch(val)}
                    >
                        <img alt="" src={CaretPrev} />
                        <label className="mobile-select-header-text">Groups</label>
                        <SelectGroup>
                            <SelectOption newCheckbox={true} selected={selectedGroupFilters.length === 0} onClick={() => onGroupAllFilterSelected()}>All</SelectOption>
                            {!!senderGroups?.length && senderGroups.map((senderGroup, index) => {
                                if (senderGroup.name.toLowerCase().includes(groupSearch.toLowerCase())) {
                                    return (
                                        <SelectOption
                                            disabled={checkIfFilterDisabled(senderGroup)}
                                            newCheckbox={true}
                                            key={index}
                                            selected={checkIfGroupExistsInFilter(senderGroup)}
                                            onClick={() => onGroupFilterSelected(senderGroup)}
                                        >{senderGroup.name}</SelectOption>
                                    )
                                }
                            })}
                        </SelectGroup>
                    </Select>
                </div>
                <div className='digest-filter-container'>
                    <div className='filter-text-count-container'>
                        <span className='your-filter-text'>Digests</span>
                        <div className='text-count-container'>
                            <span className='filter-text-count'>{myDigests?.length ?? '0'}</span>
                        </div>
                    </div>
                    <Select
                        className={'sender-status-select'}
                        inputPlaceholder={'Select'}
                        onSearchValueChange={val => setDigestSearch(val)}
                        newCheckbox={true}
                    >
                        <img alt="" src={CaretPrev} />
                        <label className="mobile-select-header-text">Digests</label>
                        <SelectGroup>
                            <SelectOption newCheckbox={true} selected={selectedDigestFilters.length === 0} onClick={() => onAllDigestFilterSelected()}>All</SelectOption>
                            {myDigests?.length && myDigests.map((digestItem, index) => {
                                if (digestItem.name.toLowerCase().includes(digestSearch.toLowerCase())) {
                                    return (
                                        <SelectOption
                                            newCheckbox={true}
                                            key={index}
                                            selected={checkIfDigestExistsInFilter(digestItem)}
                                            onClick={() => { onDigestFilterSelected(digestItem) }}
                                        >{digestItem.name}</SelectOption>
                                    )
                                }
                            })}
                        </SelectGroup>
                    </Select>
                    <BlueButton className="mobile-apply-filter-button" onClick={onClose} type="large">Apply Filter</BlueButton>
                </div>
            </AlertPage>
        </Alert>
    )

}