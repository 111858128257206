import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import './styles.scss'
import iconInfo from 'assets/images/info-white.svg'

import {
	Navigation,
	AppFooter,
	NotificationContainer,
	Loader,
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton,
	InfoAlert,
	IModal, Pagination,
	Select, SelectOption,
	DigestPreviewAlert,
	FilterModal,
	LockrScanPreview,
} from 'components'
import { DashboardStatistics } from './statistics'
import { DashboardTableHeader } from './table-header'
import { DashboardSenderList } from './sender-list'
import { ConnectedEmailList } from './connected-email-list'
import { WelcomePopUp } from './welcome-popup'
import { Checklist } from './checklist'

import {
	useAuth, useSenders, Sender, Filter,
	FilterProvider,
	useStorage,
} from "core"
import {
	isValidText,
	Storage, ObjectHelper, ArrayHelper
} from 'utils'
import { config, errorCodes } from '../../constants'
import { routes, redirectTo, parseSearchParams, routeToMe } from 'routes'
import AtlasOnboardingSetup from './atlas'

// dashboard?modal=referral
// dashboard?flagError=true&mail=40ce1c2c-500a-4bae-8d69-3e100629c79e

export function DashboardPage(props) {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const iSendersTitleRef = React.useRef()

	const { isLoading: isUserLoading, currentUser, updateCurrentUser } = useAuth()
	const { isLoading: isSenderInSearch, totalCount: senderTotalCount, senders, sendersSearch, groupCount } = useSenders()
	const { checklistMinimized } = useStorage()

	const [highlight, setHighlight] = React.useState(null)
	const [changeDestination, setChangeDestination] = React.useState(null)
	const [filterId, setFilterId] = React.useState(null)
	React.useEffect(() => {
		if (highlight || changeDestination) {
			loadSenders()
		}
	}, [highlight, changeDestination])

	const [senderState, setSenderState] = React.useState(null)

	React.useEffect(() => {
		if (currentUser) {
			setWelcome(!currentUser.showWelcome)
			loadSenders()
		}
	}, [currentUser])

	const [error, setError] = React.useState(null)
	const [alert, setAlert] = React.useState(null)

	const [connectedEmailId, setConnectedEmailId] = React.useState(null)
	const [senderStatus, setSenderStatus] = React.useState(Sender.Status.All.key)
	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(25)
	const [sortBy, setSortBy] = React.useState('recentAt')
	const [senderGroupId, setSenderGroupId] = React.useState(null)
	const [selectedGroups, setSelectedGroups] = React.useState([]);
	const [selectedDigests, setSelectedDigests] = React.useState(!!location?.state?.selectedDigest?.currentDigest ? [location?.state?.selectedDigest?.currentDigest] : []);
	const [defaultSelectedDigest, setDefaultSelectedDigest] = React.useState(undefined);

	React.useEffect(() => {
		const { state } = location;
		if (!!state?.selectedDigest && state?.selectedDigest?.fromScreen === 'DIGEST') {
			console.log(`The incoming digest`);
			console.log(state?.selectedDigest);
			setDefaultSelectedDigest(state.selectedDigest.currentDigest);
			setSelectedDigests([state.selectedDigest.currentDigest]);
		}
	}, [])

	React.useEffect(() => {
		setPageIndex(0)
	}, [connectedEmailId, senderStatus, searchText, pageSize, sortBy, senderGroupId, selectedGroups, selectedDigests])
	React.useEffect(() => {
		loadSenders()
	}, [connectedEmailId, senderStatus, searchText, pageIndex, pageSize, sortBy, senderGroupId, selectedDigests, selectedGroups])

	const [selectedSenders, setSelectedSenders] = React.useState([])
	React.useEffect(() => {
		if (!ArrayHelper.isValid(senders) && pageIndex > 0) {
			setPageIndex(0)
			return
		}

		setSelectedSenders(selectedSenders.map((e) => {
			const sender = senders.find((e1) => e1.id === e.id)
			return sender
		}).filter(e => Boolean(e)))
	}, [senders])

	const [filterSenders, setFilterSenders] = React.useState([])
	const [isOnBatchDeleteFilter, setBatchDeleteFilter] = React.useState(false)
	const [welcome, setWelcome] = React.useState(true)

	// Flag Filter Error
	const [filterFlagMailId, setFilterFlagMailId] = React.useState(null)

	// Digest Preview
	const [isDigestPreviewOpen, setDigestPreviewOpen] = React.useState(false)

	// LockrScan Preview
	const [isLockrScanPreviewOpen, setLockrScanPreviewOpen] = React.useState(false)


	React.useEffect(() => {
		window.addEventListener('click', handleWindowClicked, false)
		return () => {
			window.removeEventListener('click', handleWindowClicked, false)
		}
	}, [])

	React.useEffect(() => { parseLocation() }, [searchParams])
	function parseLocation() {
		const { flagError, mail, senderId, highlight, changeDestination, filterId } = parseSearchParams(searchParams)
		if (ObjectHelper.isValid(flagError) && ObjectHelper.isValid(mail)) {
			handleFilterFlagError(mail)
			setFilterFlagMailId(mail)
		}

		setHighlight(highlight)
		setChangeDestination(changeDestination)
		setFilterId(filterId)

		if (location.state) {
			const {
				error,
				errorCode,
				senderState,
				openDigestPreview,
				filterSender,
				lockrScanPreview,

			} = location.state

			if (error) {
				if (errorCode === errorCodes.NOT_FOUND) {
					handleWrongLockrMail()
				} else {
					setError(error)
				}
			} else if (senderState) {
				setSenderState(senderState)
			}

			if (senderState) { setSenderState(senderState) }

			if (filterSender) { setFilterSenders([filterSender]) }

			setDigestPreviewOpen(Boolean(openDigestPreview))

			setLockrScanPreviewOpen(Boolean(lockrScanPreview))

			clearLocationState()
		}

		if (location.state?.from?.pathname === 'tutorial') {
			if (currentUser) currentUser.update({ doneTutorial: true })
			navigate(redirectTo({
				route: routes.Discover
			}))
		}

		const { modal } = parseSearchParams(searchParams)
		if (modal === 'referral') {
			navigate(redirectTo({
				route: routes.ReferralHub
			}))
		}
	}

	function clearLocationState() {
		delete location.state?.error
		delete location.state?.errorCode
		delete location.state?.senderState
		delete location.state?.filterFlagError
		delete location.state?.openDigestPreview
		delete location.state?.filterSender

		navigate(routeToMe({ pathname: location.pathname }), { replace: true })
	}

	async function loadSenders() {
		if (!currentUser && Storage.accessToken) { return }

		setError(null)
		let params = {};
		if (connectedEmailId !== null) {
			let connectedEmailIds = Array.isArray(connectedEmailId) ? connectedEmailId : [connectedEmailId];
			if (connectedEmailIds.length) {
				params.connectedEmailId = connectedEmailIds.join(',');
			}
		}
		params = {
			...params,
			status: senderStatus,
			searchText,
			pageNum: pageIndex + 1,
			pageSize,
			sortBy,
		}
		// if (senderGroupId) params = { ...params, senderGroupId }

		if (selectedDigests?.length) {
			const digests = selectedDigests.map(item => item.id)
			params = { ...params, digestIds: digests.join('^') }
		}

		if (selectedGroups.length) {
			const groups = selectedGroups.map(item => item.id);
			params = { ...params, senderGroupIds: groups.join('^') }
		}

		// highlight
		if (highlight || changeDestination)
			params = { ...params, highlightId: highlight || changeDestination }

		sendersSearch(params)
	}

	function handleWrongLockrMail() {
		setAlert({
			message: "The Sender you received an email from was sent to a different lockrMail user. To adjust your settings for this Domain or Sender, please use an email that was sent directly to you or navigate to this Sender's settings within your account.",
			buttons: [
				{ title: 'OK', type: 'positive', onClick: () => setAlert(null) },
				{
					title: 'Contact Us', type: 'negative', onClick: () => {
						setAlert(null)
						window.open(`${config.LOCKR_URL}/contact/`)
					}
				},
			]
		})
	}

	async function batchDeleteFilters() {
		const filteredSenders = selectedSenders.filter((sender) => sender.hasFilter)
		for (let sender of filteredSenders) {
			await Filter.delete({ id: sender.filterId })
		}

		loadSenders()
	}

	function handleWindowClicked() { }

	function handleSelectAll(value) {
		if (value) {
			if (selectedSenders.length < senders.length) {
				setSelectedSenders(senders)
			}
		} else {
			setSelectedSenders([])
		}
	}

	function handleSelectSender(sender) {
		if (selectedSenders.some(e => e.id === sender.id)) {
			setSelectedSenders(selectedSenders.filter(e => e.id !== sender.id))
		} else {
			setSelectedSenders([...selectedSenders, sender])
		}
	}

	async function handleBulkSetConnectedEmail(connectedEmailId) {
		if (selectedSenders.length <= 0) { return }

		const { error } = await Sender.setConnectedEmail(selectedSenders, connectedEmailId)
		if (isValidText(error, true)) {
			setError(error)
		} else {
			loadSenders()
		}
	}

	async function handleFilterFlagError(mailId) {
		if (!mailId) return

		const { error } = await Filter.sendFlagError(mailId)
		if (error) {
			setError(error)
		}

		navigate(redirectTo({ route: routes.Dashboard }))
	}

	const onSelectConnectedEmailId = (id) => {
		// Set connectedEmailId to null if id is null
		if (id === null) {
			setConnectedEmailId(null);
			return;
		}

		setConnectedEmailId(prevIds => {
			// Make prevIds is an array
			const currentIds = Array.isArray(prevIds) ? prevIds : [];

			// Check if the id is already in the array
			if (currentIds.includes(id)) {
				// If it is, remove it
				const updatedIds = currentIds.filter(existingId => existingId !== id);
				// Check if updatedIds is empty and return null if so
				return updatedIds.length === 0 ? null : updatedIds;
			} else {
				// If it's not, add it
				return [...currentIds, id];
			}
		});
	};



	return (
		<div id="parent" className={`page-container`}>
			<NotificationContainer />
			<Navigation />
			<div className="dashboard">
				<div className="dashboard-wrapper">
					{(isUserLoading || !currentUser) ? <Loader /> :
						<>
							{/* <HelpCenterBanner /> */}
							{/* {welcome &&
								<WelcomePopUp
									onClose={() => setWelcome(false)}
								/>
							} */}
							<DashboardStatistics
								onError={error => setError(error)} />
							<div className="dashboard-content">
								<div className="dashboard-senders">
									<div>
										<div className="dashboard-senders-title">
											Email Senders<img ref={iSendersTitleRef} src={iconInfo} alt="" />
											<IModal
												anchorEl={iSendersTitleRef.current}>
												Brands and emails that send email to your lockrMail email address.<br />Learn more about lockrMail terminology in the <a href="https://lockrmail.com/faq/can-you-break-down-the-lockrmail-terminology-for-me/" target="_blank">Help Center</a>
											</IModal>
										</div>
										<div className="dashboard-senders-subtitle">
											{(senderStatus === Sender.Status.Blocked.key) && 'These senders have been blocked. You can choose to allow senders below.'}
											{/* These senders have been blocked. You can choose to allow senders below or specificially deliver individual messages. */}
											{(senderStatus === Sender.Status.Allowed.key) && 'These senders have been allowed. You can choose to block individual senders below.'}
										</div>
									</div>
								</div>
							</div>
							<div className="dashboard-table">
								{/* {currentUser &&
									<ConnectedEmailList
										connectedEmailId={connectedEmailId}
										currentUser={currentUser}
										onSelect={id => setConnectedEmailId(id)}
										onViewAll={() => setConnectedEmailId(null)}
									/>
								} */}
								{/* <Select
									className="mobile-connected-email-menu"
									keepLabel label="All destination emails"
									mobileTitle="Destination Email Address"
									value={connectedEmailId}
									onChange={(value) => setConnectedEmailId(value)}>
									<SelectOption value={null}>View All</SelectOption>
									{(currentUser.connectedEmails || []).map((connectedEmail, index) =>
										<SelectOption key={index} value={connectedEmail.id}>{connectedEmail.email}</SelectOption>
									)}
								</Select> */}
								<DashboardTableHeader
									defaultSelectedDigests={selectedDigests?.length ? selectedDigests : []}
									selectedSenders={selectedSenders}
									onSelectAll={(select) => handleSelectAll(select)}
									onFilter={(senders) => setFilterSenders(senders)}
									searchText={searchText} onSearchText={(value) => setSearchText(value)}
									senderStatus={senderStatus} onOptionSenderStatus={(value) => setSenderStatus(value)}
									sortBy={sortBy} onOptionSortBy={(value) => setSortBy(value)}
									pageSize={pageSize} onOptionPageSize={(value) => setPageSize(value)}
									onDigestFiltersSelected={(value) => setSelectedDigests(value)}
									onGroupFiltersSelected={(value) => { setSelectedGroups(value) }}
									optionSenderGroupId={senderGroupId} onOptionSenderGroup={(value) => setSenderGroupId(value)}
									onSelectConnectedEmailId={id => onSelectConnectedEmailId(id)}
									selectedConnectedEmailId={connectedEmailId} />

								<DashboardSenderList
									id="sender-container"
									isLoading={isSenderInSearch}
									isActiveSearch={isValidText(searchText)}
									totalCount={senderTotalCount}
									senders={senders}
									defaultSelectedDigestFilter={defaultSelectedDigest}
									selectedSenders={selectedSenders}
									viewOptions={{ connectedEmailId, searchText, senderStatus, sortBy, senderGroupId, selectedGroups, selectedDigests }}
									onSelectSender={(sender) => handleSelectSender(sender)}
									onError={(error) => setError(error)}
									groupCount={groupCount}
								/>
								<Pagination
									className="dashboard-table-pagination"
									totalSize={senderTotalCount}
									pageSize={pageSize}
									pageIndex={pageIndex}
									onPageSize={value => setPageSize(value)}
									onPageIndex={value => setPageIndex(value)}
								/>
							</div>
						</>
					}
				</div>
			</div>
			{senderState &&
				<Alert onCancel={() => setSenderState(null)}>
					<AlertPage>
						<AlertTitle value={senderState.title} />
						<AlertMessage value={senderState.message} />
						<AlertButtonContainer>
							<AlertButton type="positive" title="OK" onClick={() => setSenderState(null)} />
						</AlertButtonContainer>
					</AlertPage>
				</Alert>
			}
			{filterSenders.length > 0 &&
				<FilterModal
					category={Filter.Category.Filter}
					initialSenders={filterSenders}
					onClose={(updated) => {
						setFilterSenders([])
						loadSenders()
					}}
				/>
			}
			{error &&
				<Alert onCancel={() => setError(null)}>
					<AlertPage>
						<AlertMessage>{error}</AlertMessage>
					</AlertPage>
				</Alert>
			}
			{filterFlagMailId &&
				<Alert onCancel={() => setFilterFlagMailId(null)}>
					<AlertPage>
						<AlertTitle>Thank you</AlertTitle>
						<AlertMessage>We appreciate your feedback. A lockrMail team member will review the error and incorporate any necessary improvements to the filter function.</AlertMessage>
						<AlertButtonContainer>
							<AlertButton onClick={() => setFilterFlagMailId(null)}>OK</AlertButton>
						</AlertButtonContainer>
					</AlertPage>
				</Alert>
			}
			{isOnBatchDeleteFilter &&
				<InfoAlert
					message="This action will remove all filters enabled for the selected Senders. Click to confirm."
					buttons={[
						{
							title: 'Confirm',
							type: 'positive',
							onClick: () => {
								batchDeleteFilters()
								setBatchDeleteFilter(false)
							}
						}, {
							title: 'Cancel',
							type: 'negative',
							onClick: () => setBatchDeleteFilter(false)
						}]}
					onClose={() => setBatchDeleteFilter(false)} />
			}
			{alert &&
				<InfoAlert {...alert} onClose={() => setAlert(null)} />
			}
			{(isDigestPreviewOpen) && <DigestPreviewAlert onClose={() => setDigestPreviewOpen(false)} />}

			{(isLockrScanPreviewOpen) && <LockrScanPreview lockrScanAccess={currentUser?.isLockrScanEnabled} onClose={() => { setLockrScanPreviewOpen(false) }}></LockrScanPreview>}
			<AppFooter className={`${checklistMinimized && 'checklist-minimized'} ${currentUser && !currentUser.showChecklist && 'checklist-closed'}`} />
			{currentUser && currentUser.showChecklist &&
				// <Checklist />
				<AtlasOnboardingSetup senders={senders} currentUser={currentUser} />
			}
			{filterId &&
				<FilterModal onClose={() => { setFilterId(null) }} />
			}
		</div>
	)
}