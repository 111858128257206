import React from 'react'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	Tab, TabItem,
	Loader,
	BlueButton,
} from 'components'
import { DetailsEl } from './details'
import { ExcludedEl } from './excluded'

import {
	useAlert,
	DeliveryHoursProvider, useDeliveryHours,
} from 'core'

const TabIndex = Object.freeze({
	Details: 'Details',
	Excluded: 'Excluded'
})

function DeliveryHoursModalEL({
	onClose = () => { }
}) {
	const { alert, setAlert, error, resetAlert } = useAlert()
	const { isLoading, deliveryHour, checkChanged, deliveryHoursSave } = useDeliveryHours()

	const [tabIndex, setTabIndex] = React.useState(TabIndex.Details)

	function handleTabIndex(value) {
		if (value === tabIndex) return

		setTabIndex(value)
	}

	function handleClose(force = false) {
		if (!force && checkChanged()) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving?',
				buttons: [
					{ type: 'positive', label: 'Stay on this page', onClick: () => resetAlert() },
					{ type: 'negative', label: 'Leave this page', onClick: () => handleClose(true) },
				]
			})
			return
		}

		onClose()
	}

	async function handleSave() {
		const saved = await deliveryHoursSave()
		if (!saved) {
			return
		}

		onClose()
	}

	return (
		<Alert
			show={!alert && !error}
			className="delivery-hours-modal"
			onCancel={() => handleClose()}>
			<AlertPage>
				<AlertTitle>Edit Delivery Hours</AlertTitle>
				<div className="delivery-hours-modal-wrapper">
					<Tab
						value={tabIndex}
						onSelect={(value) => handleTabIndex(value)}>
						{Object.keys(TabIndex).map((key, index) => {
							return (
								<TabItem key={index} value={TabIndex[key]}>{TabIndex[key]}</TabItem>
							)
						})}
					</Tab>
					{isLoading ? <Loader /> :
						<React.Fragment>
							{deliveryHour &&
								<React.Fragment>
									<DetailsEl
										className={tabIndex !== TabIndex.Details && 'd-none'}
										deliveryHour={deliveryHour} />
									<ExcludedEl
										className={tabIndex !== TabIndex.Excluded && 'd-none'}
										deliveryHour={deliveryHour} />
								</React.Fragment>
							}
						</React.Fragment>
					}
					<div className="save-container">
						<BlueButton
							disabled={!checkChanged()}
							onClick={() => handleSave()}>
							Save
						</BlueButton>
					</div>
				</div>
			</AlertPage>
		</Alert>
	)
}

export function DeliveryHoursModal({
	onClose = () => { },
}) {
	return (
		<DeliveryHoursProvider>
			<DeliveryHoursModalEL onClose={() => onClose()} />
		</DeliveryHoursProvider>
	)
}