import { plainToClass } from 'class-transformer'

import { Api } from '../..'
import { DateHelper } from '../../../utils'

export class AdminDomain {
	domain = ""
	totalEmails = 0
	totalAccounts = 0
	blockedEmails = 0
	blockedAccounts = 0
	filteredAccounts = 0

	static fromJSON(json) {
		return plainToClass(AdminDomain, json)
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminDomain.fromJSON(json))
	}

	static async load(searchText, startDate, endDate, pageNum, pageSize, sortBy) {
		const defaultError = 'Sorry, there are some troubles to get sender domains.'
		let params = { searchText, pageNum: pageNum + 1, pageSize, sortBy }
		if (startDate) params = { ...params, startDate: DateHelper.format(startDate, 'YYYY-MM-DD') }
		if (endDate) params = { ...params, endDate: DateHelper.format(endDate, 'YYYY-MM-DD') }

		try {
			const { data, error } = await Api.get('/admin/sender-domains', params)
			if (error) return { error: defaultError }
			return { count: data.count, results: AdminDomain.fromJSONArray(data.rows) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}
}