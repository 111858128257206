import React from 'react'

import './styles.scss'

import {
	InfoAlert
} from 'components'
import { SecurityModal } from '../security-modal'

import { Segment, useAlert, useAuth, User } from 'core'

export function SecurityEl({
	className = ''
}) {
	const { setAlert } = useAlert()
	const { currentUser, userGetProfile, userLogout } = useAuth()
	React.useEffect(() => {
		if (currentUser) {
			setAllow2FA(currentUser.allow2FA)
		}
	}, [currentUser])

	const [allow2FA, setAllow2FA] = React.useState(false)
	const [isInEnable, setInEnable] = React.useState(false)

	async function disable2FA() {
		if (!currentUser) return
		let phoneNumber = currentUser?.phone
		const params = { allow2FA: false, phone: null }
		const { error } = await currentUser.update(params)
		if (error) {
			setError(error)
			return
		}

		await userGetProfile()
		currentUser.is2FAPassed = false
		Segment.track(Segment.Event.userDisabled2fa, currentUser?.id, { phoneNumber: phoneNumber }, currentUser)
	}

	async function handleDisable() {
		setAlert({
			message: "Click confirm to remove two-factor authentication from your account.",
			buttons: [
				{ type: 'positive', label: 'Confirm', onClick: () => disable2FA() },
				{ type: 'negative', label: 'Cancel', }
			]
		})
	}

	async function handleLogout() {
		await User.sendLogout()
		userLogout();
	}

	return (
		<React.Fragment>
			<div className={`${className} security-el`}>
				{currentUser &&
					<>
						<div className="status">
							{allow2FA ? "Two-factor authentication is enabled." : "Two-factor authentication is not enabled yet."}
						</div>
						<div className="description">
							{allow2FA ? "Your lockrMail Account is now protected with two-factor authentication." : "Add an additional layer of security to your account."}
						</div>
						{allow2FA ?
							<button className="disable" onClick={() => handleDisable()}>Disable</button>
							:
							<button className="enable" onClick={() => setInEnable(true)}>Enable now</button>
						}
					</>
				}
			</div>
			<div className={`${className} security-el`}>
				<div className="status">
					Log-out from all active sessions.
				</div>
				<div className="description">
					This will log you out of all devices and sessions, including this active one.
				</div>
				<button className="logout" onClick={() => handleLogout()}>Log out</button>
			</div>
			{isInEnable &&
				<SecurityModal
					onClose={(updated) => {
						setInEnable(false)
						if (updated) {
							userGetProfile()
						}
					}} />
			}
		</React.Fragment>
	)
}