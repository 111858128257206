import React from 'react'

import { AdminSpecialLink } from 'core/models'
import { useAlert } from 'core/providers/alert'

const AdminSpecialLinkContext = React.createContext({})

export function AdminSpecialLinkProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [specialLinks, setSpecialLinks] = React.useState([])

	async function loadSpecialLink(params) {
		setLoading(true)
		const { error, count, results } = await AdminSpecialLink.search(params)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setTotalCount(count ?? 0)
		setSpecialLinks(results)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		totalCount,
		specialLinks,

		loadSpecialLink
	}), [isLoading, totalCount, specialLinks])

	return (
		<AdminSpecialLinkContext.Provider value={memoedValue}>
			{children}
		</AdminSpecialLinkContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, totalCount: number, specialLinks: Array<AdminSpecialLink>, loadSpecialLink: Function}} UseAdminSpecialLink
 * @returns {UseAdminSpecialLink}
 */
export function useAdminSpecialLink() {
	return React.useContext(AdminSpecialLinkContext)
}