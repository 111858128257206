import Cookies from 'js-cookie'

require('dotenv').config()

export class Storage {
	static Keys = Object.freeze({
		Email: "Email",
		Password: "Password",

		AccessToken: "lockrmail-access-token",
		RefreshToken: "lockrmail-refresh-token",

		WelcomePopupCount: 'WelcomePopupCount',
		ChecklistMinimized: 'lockrmail-checklist-minimized',
		ChecklistAnimated: 'lockrmail-checklist-animated',

		DomainSettingsCrxClosed: 'lockrmail-domain-settings-crx-banner-closed',

		// Notification
		NotificationClosedPermantely: 'lockrmail-notification-closed-permanentely',
		CannyNotificationClosed: 'lockrmail-canny-notification-closed',

		// 2FA
		PhoneVerifiedEmail: "lockrmail-phone-verified-lockrmail",

		// Timezone
		Timezone: 'lockrmail-user-timezone',

		LockrScanUserId: 'lockrScan-user-id',
		userId: "userId",

		LockrScanStatus: 'lockrScan-status',
		SessionExpireCode: 'session-expire-code',

		OnlyLockrScanUser: 'onlyLockrScanUser',

		lockrScanCannyNotification: 'lockrScan-canny-notification-closed',
		LockrScanSuccess: 'lockrScan-success',

		ChromeExtensionNotification: 'chrome-extension-2-notification',

	})

	static get domain() {
		return '.lockrmail.com'
		return window.location.hostname
	}
	static get cookiesPath() { return '/' }

	static get(key, defaultValue = undefined) { return localStorage.getItem(key) || defaultValue }
	static set(key, value) {
		if (value) localStorage.setItem(key, value)
		else localStorage.removeItem(key)
	}

	static getCookies(key, defaultValue = null) { return Cookies.get(key) ?? defaultValue }
	static setCookies(key, value) {
		if (!value) Cookies.remove(key, { path: Storage.cookiesPath, domain: Storage.domain })
		else Cookies.set(key, value, { path: Storage.cookiesPath, domain: Storage.domain, expires: 30 })
	}

	static get accessToken() { return Storage.get(Storage.Keys.AccessToken, Storage.get('AccessToken')) }
	static set accessToken(value) {
		Storage.set(Storage.Keys.AccessToken, value)
		Storage.set('AccessToken', value)
	}

	static get refreshToken() { return Storage.get(Storage.Keys.RefreshToken, Storage.get('RefreshToken')) }
	static set refreshToken(value) {
		Storage.set(Storage.Keys.RefreshToken, value)
		Storage.set('RefreshToken', value)
	}

	static get cookieAccessToken() { return Storage.getCookies(Storage.Keys.AccessToken) }
	static set cookieAccessToken(value) { Storage.setCookies(Storage.Keys.AccessToken, value) }

	static get cookieRefreshToken() { return Storage.getCookies(Storage.Keys.RefreshToken) }
	static set cookieRefreshToken(value) { Storage.setCookies(Storage.Keys.RefreshToken, value) }

	static get welcomePopupCloseCount() { return parseInt(Storage.get(Storage.Keys.WelcomePopupCount), 10) || 0 }
	static increaseeWelcomePopupCloseCount() {
		Storage.set(Storage.Keys.WelcomePopupCount, Storage.welcomePopupCloseCount + 1)
	}

	static get checklistMinimized() {
		// console.log(Storage.get(Keys.ChecklistClosed, false))
		return Storage.get(Storage.Keys.ChecklistMinimized, false)
	}
	static setChecklistMinimized(value = true) {
		Storage.set(Storage.Keys.ChecklistMinimized, value)
	}

	static get checklistAnimated() { return Storage.get(Storage.Keys.ChecklistAnimated, false) }
	static setChecklistAnimated(value = true) { Storage.set(Storage.Keys.ChecklistAnimated, value) }

	static get domainSettingsCrxClosed() { return Storage.get(Storage.Keys.DomainSettingsCrxClosed, false) }
	static setDomainSettingsCrxClosed(value = true) { Storage.set(Storage.Keys.DomainSettingsCrxClosed, value) }

	static get timezone() { return Storage.get(Storage.Keys.Timezone) }
	static set timezone(value) { Storage.set(Storage.Keys.Timezone, value) }

	static get notificationBarClosed() { return Storage.get(Storage.Keys.NotificationClosedPermantely) }
	static set notificationBarClosed(value) { return Storage.set(Storage.Keys.NotificationClosedPermantely, value) }

	static get cannyNotificationClosed() { return Storage.get(Storage.Keys.CannyNotificationClosed) }
	static set cannyNotificationClosed(value) { return Storage.set(Storage.Keys.CannyNotificationClosed, value) }

	static get lockrScanNotificationClosed() { return Storage.get(Storage.Keys.lockrScanCannyNotification) }
	static set lockrScanNotificationClosed(value) { return Storage.set(Storage.Keys.lockrScanCannyNotification, value) }

	static get lockrScanUserId() { return Storage.get(Storage.Keys.LockrScanUserId) }
	static set lockrScanUserId(value) { return Storage.set(Storage.Keys.LockrScanUserId, value) }
	static get userId() { return Storage.get(Storage.Keys.userId) }
	static set userId(value) { return Storage.set(Storage.Keys.userId, value) }

	static get lockrScanStatus() { return Storage.get(Storage.Keys.LockrScanStatus) }
	static set lockrScanStatus(value) { return Storage.set(Storage.Keys.LockrScanStatus, value == 0 ? "false" : "true") }

	static get sessionExpireCode() { return Storage.get(Storage.Keys.SessionExpireCode) }
	static set sessionExpireCode(value) { return Storage.set(Storage.Keys.SessionExpireCode, value) }

	static get onlyLockrScanUser() { return Storage.get(Storage.Keys.OnlyLockrScanUser) }
	static set onlyLockrScanUser(value) { return Storage.set(Storage.Keys.OnlyLockrScanUser, value) }

	static get lockrScanSuccess() { return Storage.get(Storage.Keys.LockrScanSuccess) }
	static set lockrScanSuccess(value) { return Storage.set(Storage.Keys.LockrScanSuccess, value) }

	static get chromeExtensionNotification() { return Storage.get(Storage.Keys.ChromeExtensionNotification) }
	static set chromeExtensionNotification(value) { return Storage.set(Storage.Keys.ChromeExtensionNotification, value) }

}