import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconDeleteGray from 'assets/images/delete/gray.svg'
import iconDeleteGreen from 'assets/images/delete/green.svg'

import {
	Checkbox, IconButton,
	ConfirmAlert,
} from 'components'
import { AdminBlockedDomain, useAdminBlockedDomains } from 'core'

/**
 * @typedef {{isHeader: boolean, blockedDomain: AdminBlockedDomain, selected: boolean, onSelect: Function}} Props
 * @param {Props} param0
 */
export function ListItem({
	isHeader = false,

	blockedDomain,

	selected = false,
	onSelect = (value) => { }
}) {
	const { deleteBlockedDomains } = useAdminBlockedDomains()
	const [isDelete, setDelete] = React.useState(false)

	function handleDelete(event) {
		setDelete(true)
	}

	return (
		<>
			{isHeader ?
				<div className='admin-blocked-domain-list-item header'>
					<div></div>
					<div>Domain</div>
					<div>lockrMail</div>
					<div>Network</div>
					<div></div>
				</div>
				:
				<div className='admin-blocked-domain-list-item'>
					<Checkbox
						iconUnchecked={iconUnchecked}
						iconChecked={iconChecked}
						checked={selected}
						onChange={value => onSelect(value)} />
					<div>{blockedDomain?.domain}</div>
					<div>{blockedDomain?.tryCount}</div>
					<div>{blockedDomain?.network}</div>
					<div>
						<IconButton
							className="delete"
							normal={iconDeleteGray}
							hover={iconDeleteGreen}
							onClick={() => handleDelete()} />
					</div>
				</div>
			}
			{isDelete &&
				<ConfirmAlert
					message={`Are you sure you want to delete ${blockedDomain?.domain} from the blocked domain list?`}
					onOk={() => deleteBlockedDomains([blockedDomain.domain])}
					onClose={() => setDelete(false)}
				/>
			}
		</>
	)
}