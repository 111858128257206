import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './styles.scss'

import {
	Navigation,
	Tab, TabItem,
} from 'components'
import { AdminStatisticsEl } from './statistics'
import { AdminUsersEl } from './users'
import { AdminMarketingEl } from './marketing'
import { AdminWebKit } from './webkit'

import { routes, redirectTo } from 'routes'
import { AdminDomainEl } from './domains'

const Category = routes.Category.Admin

export function AdminScene(props) {
	const navigate = useNavigate()
	const { category = Category.Statistics } = useParams()

	return (
		<div id="parent" className="page-container">
			<Navigation />
			<div className="admin">
				<div className="admin-wrapper">
					<Tab
						value={category}
						onSelect={(value) => { navigate(redirectTo({ route: routes.Admin, category: value })) }}>
						{Object.keys(Category).map((category, index) =>
							<TabItem key={Category[category]} value={Category[category]}>{category}</TabItem>
						)}
					</Tab>
					{category === Category.Statistics &&
						<AdminStatisticsEl />
					}
					{category === Category.Users &&
						<AdminUsersEl />
					}
					{category === Category.Marketing &&
						<AdminMarketingEl />
					}
					{category === Category.WebKit &&
						<AdminWebKit />
					}
					{category === Category.Domains &&
						<AdminDomainEl />
					}
				</div>
			</div>
		</div>
	)
}

export * from './marketing/discovery'