import { User } from 'core';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { redirectTo, routes } from 'routes';
import { base64Decode } from 'utils';

export function LockrScanArchiveDeepLink() {
    const params = useParams();
    const navigate = useNavigate();
    async function lockrArchive(decodedEmail) {
        await User.moveSenderEmailsToLockrArchive({
            emails: [decodedEmail]
        })
        navigate(
            routes.LockrScanDashboard,
            { state: { params: { email: decodedEmail } } })
    }
    React.useEffect(() => {
        let decodedEmail = base64Decode(params.encryptedEmail)
        lockrArchive(decodedEmail);
    }, [])
    return <></>
}