import React from 'react'

import iconGear from 'assets/images/gear/gray.svg'
import iconPlus from 'assets/images/plus-gray.svg'
import iconDelete from 'assets/images/delete/gray.svg'
import iconMore from 'assets/images/icon_options.svg'

import {
	SelectDropDown, SelectOption,
} from 'components'

import { Filter } from 'core'
import { DateHelper } from 'utils'

/**
 * @typedef {{filter: Filter, onAdd: Function, onEdit: Function, onDelete: Function}} SavedFilterListItemProps
 * @param {SavedFilterListItemProps} param0
 */
export function SavedFilterListItem({
	filter,
	onAdd = () => { },
	onEdit = () => { },
	onDelete = () => { },
}) {
	return (
		<div className="saved-filter-list-item">
			<div className="info-container">
				<div className="name"><span>{filter.name}</span></div>
				<div className="date">{filter.createdAt ? 'Created ' + DateHelper.format(filter.createdAt, 'DD/MM/YYYY') : ''}</div>
			</div>
			<div className="command-container">
				<div className="command" onClick={() => onAdd()}><img src={iconPlus} alt="" /></div>
				<div className="command" onClick={() => onEdit()}><img src={iconGear} alt="" /></div>
				<div className="command" onClick={() => onDelete()}><img src={iconDelete} alt="" /></div>
				<div className="more">
					<img src={iconMore} />
					<SelectDropDown mobileTitle="Saved Filter Options">
						<SelectOption onClick={() => onAdd()}>Add</SelectOption>
						<SelectOption onClick={() => onEdit()}>Edit</SelectOption>
						<SelectOption onClick={() => onDelete()}>Remove</SelectOption>
					</SelectDropDown>
				</div>
			</div>
		</div>
	)
}