import React from 'react'

import './styles.scss'
import iconDisable from 'assets/images/disable-gray.svg'

import {
	Alert, AlertPage, AlertButtonContainer, AlertButton,
	Icon,
	Select, SelectOption, Switch,
} from 'components'
import { MobileCrxEl } from '../mobile-crx'
import { ViewAllRibbonEl } from '../view-all-ribbon'

import { DomainSetting, Segment, useAlert, useAuth } from 'core'
import { errorCodes, errorMessages } from 'constants/error'
import { ArrayHelper } from 'utils'

/**
 * @typedef {{value: DomainSetting, onClose: Function}} DomainSettingsAlertProps
 * @param {DomainSettingsAlertProps} param0
 */
export function DomainSettingsAlert({
	value,
	onClose = (value, isUnblockAll) => { }
}) {
	const { alert, setAlert, error, setError, resetAlert } = useAlert()
	const { currentUser } = useAuth()

	/** @type {[DomainSetting, Function]} */
	const [domainSetting, setDomainSetting] = React.useState(value)
	React.useEffect(() => { setDomainSetting(value) }, [value])

	const [isUpdated, setUpdated] = React.useState(false)

	const [emailOption, setEmailOption] = React.useState(domainSetting.emailOption)
	const [popupOption, setPopupOption] = React.useState(domainSetting.popupOption)
	const [passwordOption, setPasswordOption] = React.useState(domainSetting.passwordOption)
	const [allowConsent, setAllowConsent] = React.useState(domainSetting.allowConsent)
	const [allowGPC, setAllowGPC] = React.useState(domainSetting.allowGPC)

	const [isCrxInstalled, setCrxInstalled] = React.useState(true)
	React.useEffect(() => {
		setCrxInstalled(currentUser && currentUser.isExtensionInstalled)
	}, [currentUser])

	async function unblockAll() {
		const { error } = await DomainSetting.unblockAll([domainSetting.domain])
		if (error) {
			setError(error)
			return
		}

		onClose(domainSetting, true)
	}

	async function handleEmailOption(value, force = false) {
		value = value === false ? -1 : 0;
		if (value !== 0 && !force) {
			const { errorCode } = await DomainSetting.checkAvailability([domainSetting.domain])
			if (errorCode === errorCodes.DS_OVERRIDE_ALLOW_FILTER) {
				setAlert({
					message: errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER,
					buttons: [
						{ type: 'positive', title: 'Confirm', onClick: () => handleEmailOption(value, true) },
						{ type: 'negative', title: 'Cancel', onClick: () => resetAlert() },
					]
				})
				return
			}
		}

		setEmailOption(value)

		const { error, results } = await DomainSetting.update([domainSetting.domain], { emailOption: value })
		if (error) {
			setError(error)
			return
		}
		else {
			Segment.track(Segment.Event.editingDomainBlockForEmails, currentUser?.id, { domains: [domainSetting.domain], emailOption: value })
		}

		setUpdated(true)
		if (ArrayHelper.isValid(results)) setDomainSetting(results[0])
	}

	async function handlePopupOption(value) {
		setPopupOption(value)

		const { error, results } = await DomainSetting.update([domainSetting.domain], { popupOption: value })
		if (error) {
			setError(error)
			return
		}
		else {
			Segment.track(Segment.Event.editingDomainBlockForPopup, currentUser?.id, { domains: [domainSetting.domain], popupOption: value })
		}

		setUpdated(true)
		if (ArrayHelper.isValid(results)) setDomainSetting(results[0])
	}

	async function handlePasswordOption(value) {
		setPasswordOption(value)

		const { error, results } = await DomainSetting.update([domainSetting.domain], { passwordOption: value })
		if (error) {
			setError(error)
			return
		}
		else {
			Segment.track(Segment.Event.enablingAccountPasswordUpdatesBySender, currentUser?.id, { passwordOption: value, domains: [domainSetting.domain] })
		}

		setUpdated(true)
		if (ArrayHelper.isValid(results)) setDomainSetting(results[0])
	}

	async function handleAllowConsent(value) {
		setAllowConsent(value)

		const { error, results } = await DomainSetting.update([domainSetting.domain], { allowConsent: value })
		if (error) {
			setError(error)
			return
		}
		else {
			Segment.track(Segment.Event.editingConsentBySender, currentUser?.id, { allowConsent: value, domains: [domainSetting.domain] })
		}

		setUpdated(true)
		if (ArrayHelper.isValid(results)) setDomainSetting(results[0])
	}

	async function handleAllowGPC(value) {
		setAllowGPC(value)

		const { error, results } = await DomainSetting.update([domainSetting.domain], { allowGPC: value })
		if (error) {
			setError(error)
			return
		}

		setUpdated(true)
		if (ArrayHelper.isValid(results)) setDomainSetting(results[0])
	}

	async function handleUnblockAll() {
		setAlert({
			message: 'Please confirm you wish to remove all block settings for the selected domain(s).',
			buttons: [
				{ type: 'positive', title: 'Confirm', onClick: () => unblockAll() },
				{ type: 'negative', title: 'Cancel', onClick: () => resetAlert() },
			]
		})
	}

	function handleClose() {
		if (isUpdated) onClose(domainSetting)
		else onClose()
	}

	return (
		<React.Fragment>
			{!alert && !error &&
				<Alert
					className="domain-settings-modal"
					onCancel={() => handleClose()}>
					<AlertPage>
						<div className="title-container">
							<Icon placeholder={domainSetting.name} src={domainSetting.avatar} />
							<div>{domainSetting.name}</div>
							{/* {domainSetting.isBlocked &&
								<div>
									<img src={iconDisable} alt="" />
									<span>{domainSetting.status}</span>
								</div>
							} */}
						</div>
						<div className="divider"></div>
						<div className="sub-item-container email-option">
							<div>All Emails from {domainSetting.domain}</div>
							<div className='email-option'>
								<div>{emailOption === 0 ? 'On' : 'Off'}</div>
								<Switch
									value={emailOption === 0}
									onChange={(value) => handleEmailOption(value)} />
							</div>
						</div>
						<div className="divider"></div>
						<div className="sub-item-container">
							<div className={`${!isCrxInstalled && 'disable text'}`}>Browser pop-up notifications</div>
							<Select
								keepLabel
								label={(popupOption == 0 ? ('Default' + (currentUser?.allowDomainNotification ? ' (On)' : ' (Off)')) : DomainSetting.isBrowserPopupEnabled(popupOption, currentUser?.allowDomainNotification) ? 'On' : 'Off')}
								value={popupOption}
								onChange={(value) => handlePopupOption(value)}
								disabled={!isCrxInstalled}>
								{Object.keys(DomainSetting.PopupOption).map((key, index) => {
									const value = DomainSetting.PopupOption[key]
									return (
										<SelectOption key={index} value={value}>{key + (value == 0 ? currentUser?.allowDomainNotification ? ' (On)' : ' (Off)' : '')}</SelectOption>
									)
								})}
							</Select>
						</div>


						<div className="divider"></div>
						<div className="sub-item-container">
							<div>Account & password update emails</div>
							<Select
								// keepLabel
								// label={key + (value == null ? DomainSetting.isPasswordEnabled(passwordOption, currentUser?.allowPasswordOption) ? ' (On)' : ' (Off)' : '')}
								value={passwordOption}
								onChange={(value) => handlePasswordOption(value)}>
								{Object.keys(DomainSetting.PasswordOption).map((key, index) => {
									const value = DomainSetting.PasswordOption[key]
									return (
										<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowPasswordOption ? ' (On)' : ' (Off)' : '')}</SelectOption>
									)
								})}
							</Select>
							{/* <button className="unblock" disabled={passwordOption == null} onClick={() => handlePasswordOption(DomainSetting.PasswordOption.Default)}>Reset</button> */}
						</div>
						<React.Fragment>
							<div className="divider"></div>
							<div className="sub-item-container">
								<div>Publisher consent</div>
								<Select
									value={allowConsent}
									onChange={(value) => handleAllowConsent(value)}>
									{Object.keys(DomainSetting.ConsentOption).map((key, index) => {
										const value = DomainSetting.ConsentOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowConsent ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
								{/* <button className="unblock" disabled={allowConsent == null} onClick={() => handleAllowConsent(DomainSetting.ConsentOption.Default)}>Reset</button> */}
							</div>
						</React.Fragment>
						<React.Fragment>
							<div className="divider"></div>
							<div className="sub-item-container">
								<div className={`${!isCrxInstalled && 'disable text'}`}>GPC</div>

								<Select
									value={allowGPC}
									onChange={(value) => handleAllowGPC(value)}
									disabled={!isCrxInstalled}>
									{Object.keys(DomainSetting.GPCOption).map((key, index) => {
										const value = DomainSetting.GPCOption[key]
										return (
											<SelectOption key={index} value={value}>{key + (value == null ? currentUser?.allowGPC ? ' (On)' : ' (Off)' : '')}</SelectOption>
										)
									})}
								</Select>
								{/* <button className="unblock" disabled={allowGPC == null} onClick={() => handleAllowGPC(DomainSetting.GPCOption.Default)}>Reset</button> */}
							</div>
						</React.Fragment>
						<div className="divider border margin-top-only"></div>
						<AlertButtonContainer>
							<AlertButton onClick={() => handleClose()}>Save</AlertButton>
							<AlertButton type='negative' onClick={() => handleUnblockAll()}>Reset all</AlertButton>
						</AlertButtonContainer>
						<ViewAllRibbonEl />
					</AlertPage>
				</Alert>
			}
		</React.Fragment>
	)
}