import { Api } from "core"
import React, { useEffect } from "react"
import { UserListItem } from "./user-list-item";
import './styles.scss'
import { LockrScanUserListItem } from "./user-list-item/lockrScan-index";
import { BlueButton, Loader, Pagination, SearchField, Selector, SelectorOption } from "components";
import fetchImage from '../../../../assets/images/fetch.svg'

export function LockrScanAdminCurrentUsersEl() {
    const [currentUsers, setCurrentUsers] = React.useState([])
    const [pageSize, setPageSize] = React.useState(5);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [totalSize, setTotalSize] = React.useState(0);
    const [sortBy, setSortBy] = React.useState("recent")
    const [searchText, setSearchText] = React.useState('');
    const [fetchClicked, setFetchClicked] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false);
    useEffect(() => {
        let timer;
        if (fetchClicked) { timer = setTimeout(() => loadCurrentUsers(), 500); }
        return (() => { clearTimeout(timer) })
    }, [pageIndex, pageSize, sortBy, searchText])

    useEffect(() => {
        setPageIndex(0);
    }, [pageSize, searchText, sortBy])



    async function loadCurrentUsers() {
        setIsLoading(true)
        const result = await Api.get('/lockrscan/admin/users', { pageNum: pageIndex + 1, pageSize: pageSize, sortBy: sortBy, searchText: searchText });

        setCurrentUsers(result.data.results)
        setTotalSize(result.data.count)
        setIsLoading(false)
        return result;
    }

    const onFetch = () => {
        setFetchClicked(true)
        loadCurrentUsers();
    }
    return (
        <>
            <div className="admin-group-title" style={{ marginTop: 60 }}>Current Users</div>
            <div className="admin-current-users lockrScan-admin-current-users">
                <div className="header">
                    <div className="header-wrapper">
                        <SearchField value={searchText} onChange={(text) => { setSearchText(text); }}></SearchField>
                        <Selector
                            defaultLabel="View Options"
                            options={[
                                new SelectorOption({ label: 'Most Recent', value: 'recent' }),
                                new SelectorOption({ label: 'Most Unsubscribed', value: 'unSubscribedCount' }),
                                new SelectorOption({ label: 'Most lockrArchived', value: 'lockrArchivedCount' }),
                                // new SelectorOption({ label: 'Most Emails', value: 'email' }),
                            ]}
                            value={sortBy}
                            onChange={value => setSortBy(value)}
                        />
                        <BlueButton className='fetch-button' onClick={() => { onFetch(); }}>Fetch <img src={fetchImage}></img></BlueButton>
                    </div>
                </div>
                <div className="list">
                    <LockrScanUserListItem isHeader />
                    {isLoading ? <Loader /> : currentUsers?.map(user => (
                        <LockrScanUserListItem user={user} onSelect={(e) => console.log(e)} />
                    ))}
                </div>

                <Pagination className="admin-current-users-pagination"
                    totalSize={totalSize}
                    pageIndex={pageIndex}
                    onPageSize={(value) => { setPageSize(value) }}
                    onPageIndex={(value) => { setPageIndex(value) }}

                    pageSize={pageSize}></Pagination>
            </div>
        </>
    )
}