import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAlert, User, useAuth } from 'core';
import { Storage } from 'utils';
import { routes, routeToMe, redirectTo, parseSearchParams } from 'routes';
import { errorCodes, errorMessages } from 'constants/error';
import { config } from 'constants/config';

// verify-email/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlZGVyaWNvam9zZWdlcmFyZG8xMjAzQGdtYWlsLmNvbSIsImlhdCI6MTY0MTgyMDMxOSwiZXhwIjoxNjQxODIxNTE5fQ.zv0-l6hpnI2MODQEvNB5VcYdZsvggrHqP-xAdFp2jSI?email=federicojosegerardo1203%40gmail.com&firstName=A&lastName=B&permalink=chrome-install&is_bOption=true&

export function VerifyEmailScene(props) {
	const navigate = useNavigate();
	const { verifyToken } = useParams();
	const [searchParams] = useSearchParams();

	const isForExtension = searchParams.get('isForExtension');

	const { setAlert, setError } = useAlert();
	const { userGetProfile } = useAuth();

	useEffect(() => {
		redirect();
	}, []);

	async function verifyEmail() {
		const { email, firstName, lastName, permalink, wordpressLink, isAutomail, isSecondary } =
			parseSearchParams(searchParams);
		const decodedEmail = window.atob(email)
		const { error, errorCode } = await User.verifyEmail(verifyToken);
		if (error) {
			if (errorCode === 401) {
				if (isAutomail) {
					setAlert({
						title: 'Link Expired',
						message:
							'This email verification link has expired. We have sent an email with an updated link - please click the updated link to confirm your new destination email.<br><br>Note: The new link will be valid for 24 hours.',
						buttons: [
							{
								title: 'Need help?',
								type: 'link',
								url: config.CONTACT_URL,
							},
						],
					});
				} else if (isSecondary) {
					setAlert({
						title: 'Link Expired',
						message:
							'This email verification link has expired. We have sent an email with an updated link - please click the updated link to verify the new email.<br><br>Note: The new link will be valid for 24 hours.',
						buttons: [
							{
								title: 'Need help?',
								type: 'link',
								url: config.CONTACT_URL,
							},
						],
					});
					if (firstName && lastName) {
						const decodedFirstName = window.atob(firstName)
						const decodedLastName = window.atob(lastName)
						await User.sendVerifyEmail(decodedEmail, {
							decodedFirstName,
							decodedLastName,
							isSecondary,
							permalink: wordpressLink
								? `wordpress|${wordpressLink}`
								: permalink,
						});
					}
				} else {
					setAlert({
						title: 'Link Expired',
						message:
							'This email verification link has expired. We have sent an email with an updated link - please click the updated link to continue setting up your lockrMail account.<br><br>Note: The new link will be valid for 24 hours.',
						buttons: [
							{
								title: 'Need help?',
								type: 'link',
								url: config.CONTACT_URL,
							},
						],
					});
					if (firstName && lastName) {
						const decodedFirstName = window.atob(firstName)
						const decodedLastName = window.atob(lastName)
						await User.sendVerifyEmail(decodedEmail, {
							decodedFirstName,
							decodedLastName,
							permalink: wordpressLink
								? `wordpress|${wordpressLink}`
								: permalink,
						});
					}
				}
			} else if (error && errorCode !== errorCodes.ALREADY_EXIST) {
				setError(error);
			}
			return false;
		}

		return true;
	}

	async function redirect() {
		if (!Storage.accessToken) {
			const {
				email,
				firstName,
				lastName,
				permalink,
				wordpressLink,
				lockrMail,
			} = parseSearchParams(searchParams);
			if (!email) {
				navigate(
					redirectTo({
						route: routes.Login,
					}),
					{
						state: { from: routeToMe() },
					}
				);
				return;
			}

			await verifyEmail();

			if (Storage.cookieAccessToken) {
				Storage.accessToken = Storage.cookieAccessToken;
				Storage.refreshToken = Storage.cookieRefreshToken;

				userGetProfile();
				if(isForExtension){
					window.location.href = 'https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi';
					return;
				}
				navigate(redirectTo({ route: routes.Tutorial }));
			} else {
				if (isForExtension) {
					window.location.href = 'https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi';
				}
				if (lockrMail) {
					const decodedLockrEmail = window.atob(lockrMail)
					navigate(
						redirectTo({
							route: routes.Login,
							searches: { lockrMail: decodedLockrEmail },
						})
					);
				} else {
					navigate(
						redirectTo({
							route: routes.Login,
						})
					);
				}
			}
		} else {
			await verifyEmail();

			navigate(
				redirectTo({
					route: routes.Account,
				})
			);
		}
	}

	return <></>;
}
