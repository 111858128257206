import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { config } from 'constants/config';
// import * as FullStory from '@fullstory/browser'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// FullStory.init({
// 	orgId: 'ZS0D6',
// 	devMode: false,
// 	recordCrossDomainIFrames: true,
// 	debug: config.IS_LOCAL || config.IS_DEV
// })

if (config.IS_PROD) {
	Sentry.init({
		dsn: "https://e4727577bf4b406491086cc893ef42df@o495300.ingest.sentry.io/4504277941682176",
		integrations: [new BrowserTracing()],

		tracesSampleRate: 1.0,
	});
}

// console.log("FullStory initialized: " + FullStory.isInitialized())

// ReactDOM.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
