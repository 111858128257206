import React from 'react'

import './styles.scss'

import { range } from 'utils'

export function DividedProgressBar({
	className = "",
	min = 0,
	max = 100,
	progress = 0
}) {
	return (
		<div className={`${className} progress-bar divided`}>
			<div className="progress-bar-inner" style={{ width: `${parseFloat(progress) / parseFloat(max - min) * 100.0}%` }}></div>
			<div className="progress-bar-overlay">
				{range(0, max - min - 1).map((x, index) =>
					<div key={index} className="progress-bar-overlay-item"></div>
				)}
			</div>
		</div>
	)
}