import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import './styles.scss'

import {
	SearchField,
	Tab, TabItem,
	Loader,
} from 'components'
import { DiscoverCompanyItem } from 'scenes/discover/company-item'

import { useAlert, DiscoverCompany } from 'core'
import { routes, redirectTo, parseSearchParams } from 'routes'
import grayArrow from 'assets/images/arrow/next-gray.svg'
import { useWidth } from 'utils'

export function TutorialDiscover({

}) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { setError } = useAlert()

	const tabs = routes.Category.Discover
	const { category = 'all' } = parseSearchParams(searchParams)

	const [isLoading, setLoading] = React.useState(false)
	const [searchText, setSearchText] = React.useState('')
	const { width } = useWidth();
	const navigator = useNavigate()

	const [discoverCompanies, setDiscoverCompanies] = React.useState([])

	React.useEffect(() => {
		search()
	}, [category, searchText])

	async function search() {
		setLoading(true)
		const { error, results } = await DiscoverCompany.search({ searchText, categories: category === 'all' ? [] : [category] })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setDiscoverCompanies(results)
	}

	function handleTab(tab) {
		navigate(redirectTo({
			route: routes.Tutorial,
			category: routes.Category.Tutorial.Discover,
			searches: { category: tab !== 'all' && tab }
		}))
	}

	return (
		<div className="tutorial-discover">
			<div style={{ marginBottom: 40, fontSize: width < 576 && 14 }}>
				<span style={{ color: '#8B8B8B', }}>1</span><span style={{ marginLeft: 24, color: '#8B8B8B' }}>2</span><span style={{ marginLeft: 24, color: '#8B8B8B' }}>3</span><span style={{ marginLeft: 24, color: '#8B8B8B' }}>4</span><span style={{ marginLeft: 24, color: '#61CEF7' }}>5</span>{width < 576 && <span onClick={() => navigate('/dashboard')}><img src={grayArrow} style={{ height: 8, width: 8, marginLeft: 22, }} /></span>}
			</div>
			<div className="title">Use your <span style={{ color: '#61CEF7' }}>lockrMail</span></div>
			<div className="description">Start using your lockrMail email address by subscribing to some newsletters and brand emails.</div>
			<SearchField
				value={searchText}
				onChange={value => setSearchText(value)} />
			<Tab value={category} onSelect={value => handleTab(value)}>
				<TabItem value="all">All</TabItem>
				{Object.keys(tabs).map((key, index) =>
					<TabItem
						key={index}
						value={tabs[key]}>
						{key}
					</TabItem>
				)}
			</Tab>
			<div className="discover-company-list">
				{isLoading ? <Loader /> :
					discoverCompanies.map((discoverCompany, index) =>
						<DiscoverCompanyItem
							key={index}
							value={discoverCompany} />
					)
				}
			</div>
		</div>
	)
}