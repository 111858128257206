import React from 'react'

import {
	Alert, AlertPage, AlertTitle, AlertMessage, AlertButtonContainer, AlertButton
} from './core'

export function ConfirmAlert({
	title,
	message,
	preventCancel = false,
	ok,
	cancel,
	onOk,
	onCancel,
	onClose = () => { },
}) {

	function handleOk() {
		if (onOk) onOk()
		else onClose()
	}

	function handleCancel() {
		if (onCancel) onCancel()
		else onClose()
	}

	return (
		<Alert onCancel={preventCancel ? handleCancel : onClose}>
			<AlertPage>
				{title && <AlertTitle>{title}</AlertTitle>}
				{message && <AlertMessage>{message}</AlertMessage>}
				<AlertButtonContainer>
					<AlertButton type="positive" onClick={handleOk}>{ok || 'Ok'}</AlertButton>
					<AlertButton type="negative" onClick={handleCancel}>{cancel || 'Cancel'}</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}