import React from 'react'

import './styles.scss'
import iconSendMail from 'assets/images/send-mail-8B8B8B.svg'
import iconSendMailHover from 'assets/images/send-mail-61CEF7.svg'

import {
	Loader,
	SearchField,
	Select, SelectOption, IconButton,
	TableBatchContainer, Pagination,
} from 'components'
import { ListItem } from './list-item'

import {
	useAlert,
	AdminUnregisteredUserProvider, useAdminUnregisteredUsers, AdminUnregisteredUser,
} from 'core'
import { ArrayHelper } from 'utils'

function UnregisteredUserEl({
	className = '',
}) {
	const { setError } = useAlert()
	const { isLoading: isApiLoading, totalCount, unregisteredUsers, searchUnregisteredUsers } = useAdminUnregisteredUsers()

	const [isLoading, setLoading] = React.useState(false)
	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(5)
	const [sortBy, setSortBy] = React.useState('createdAt')
	const [selectedUsers, setSelectedUsers] = React.useState([])

	React.useEffect(() => {
		searchUnregisteredUsers({ searchText, pageIndex, pageSize, sortBy })
	}, [searchText, pageIndex, pageSize, sortBy])

	/** @param {AdminUnregisteredUser} user */
	function handleSelectUser(user) {
		if (selectedUsers.some(x => x.id === user.id)) {
			setSelectedUsers(selectedUsers.filter(x => x.id !== user.id))
		} else {
			setSelectedUsers([...selectedUsers, user])
		}
	}

	async function handleBatchResendVerification() {
		setLoading(true)
		const { error } = await AdminUnregisteredUser.sendVerifyEmail(selectedUsers)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}
	}

	return (
		<div className={`${className} admin-unregistered-users`}>
			<div className="total">Total: {totalCount}</div>
			<div className="header">
				<TableBatchContainer
					selected={ArrayHelper.isValid(selectedUsers)}
					onSelectAll={(value) => setSelectedUsers(value ? unregisteredUsers : [])}>
					<React.Fragment>
						<IconButton
							className="btn-batch"
							normal={iconSendMail}
							hover={iconSendMailHover}
							onClick={() => handleBatchResendVerification()} />
					</React.Fragment>
				</TableBatchContainer>
				<SearchField
					value={searchText}
					onChange={setSearchText} />
				<Select
					className="view-options"
					value={sortBy}
					onChange={(value) => setSortBy(value)}>
					<SelectOption value='createdAt'>Newest</SelectOption>
					<SelectOption value='-createdAt'>Oldest</SelectOption>
					<SelectOption value='-email'>Alphabetical A - Z</SelectOption>
					<SelectOption value='email'>Alphabetical Z - A</SelectOption>
				</Select>
			</div>
			<div className="list">
				<ListItem isHeader />
				{(isApiLoading || isLoading) ? <Loader /> :
					unregisteredUsers.map((user, index) => {
						const selected = selectedUsers.some(x => x.id === user.id)

						return (
							<ListItem
								key={index}
								user={user}
								selected={selected}
								onSelect={() => handleSelectUser(user)}
								onRefresh={() => { searchUnregisteredUsers({ searchText, pageIndex, pageSize, sortBy }) }}
							/>
						)
					})
				}
			</div>
			<Pagination
				className="admin-unregistered-users-pagination"
				totalSize={totalCount}
				pageIndex={pageIndex}
				pageSize={pageSize}
				onPageIndex={value => setPageIndex(value)}
				onPageSize={value => setPageSize(value)}
			/>
		</div>
	)
}

export function AdminUnregisteredUserEl(props) {
	return (
		<AdminUnregisteredUserProvider>
			<UnregisteredUserEl {...props} />
		</AdminUnregisteredUserProvider>
	)
}