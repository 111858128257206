import React from 'react'

import './styles.scss'
import iconClear from 'assets/images/cross-12px-888888.svg'

import {
	TextField
} from 'components'

import { Referral } from 'core'
import { isEmail, isValidText } from 'utils'
import { errorCodes, errorMessages } from 'constants/error'

export function FormInvite({
	className = ""
}) {
	const [emails, setEmails] = React.useState(["", ""])
	const [totalError, setTotalError] = React.useState(null)
	const [error, setError] = React.useState({})
	React.useEffect(() => {
		setTotalError(null)
		setError({})
	}, [emails])

	function handleEmailChanged(value, index) {
		setEmails(emails.map((e, i) => {
			if (index === i) {
				return value.trim()
			}
			return e || ''
		}))
	}

	function handleAdd() {
		const filteredEmails = emails.filter(e => !isValidText(e))
		if (filteredEmails.length > 0) return

		setEmails([...emails, ""])
	}

	function handleClear(index) {
		if (emails.length <= 1) return
		setEmails(emails.filter((e, i) => i !== index))
	}

	async function submit(email) {
		const { error, errorCode } = await Referral.submit([email])
		if (error) {
			if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) {
				const newError = error ?? {}
				newError[email] = errorMessages.NOT_SUPPORTED_EMAILS
				setError(newError)
			} else {
				setTotalError(error)
			}
		}
	}

	async function handleSend() {
		// Check validation
		const filteredEmails = emails.filter(e => isValidText(e))
		if (filteredEmails.length <= 0) return

		if (filteredEmails.some(e => !isEmail(e))) {
			setTotalError("Invalid email addresses. Please check again.")
			return
		}

		const newError = error ?? {}
		for (const email of filteredEmails) {
			const { error, errorCode } = await Referral.submit([email])
			if (error) {
				if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) {
					newError[email] = errorMessages.NOT_SUPPORTED_EMAILS
				} else if (errorCode === errorCodes.LOCKRMAIL_NOT_SUPPORTED) {
					newError[email] = errorMessages.ERROR_EMAIL_NOT_SUPPORTED
				} else {
					setTotalError(error)
					return
				}
			}
		}

		if (Object.keys(newError).length > 0) {
			setError({ ...newError })
			return
		}

		setEmails(['', ''])
	}

	return (
		<div className={`referrals-form-invite ${className}`}>
			<div className="form">
				{emails.map((email, index) => {
					const emailError = error[email]
					return (
						<React.Fragment key={index}>
							<div className="input">
								<TextField
									type="large"
									placeholder="Email address"
									error={Boolean(emailError)}
									value={email}
									onChange={event => handleEmailChanged(event.target.value, index)}
								/>
								{(isEmail(email)) &&
									<button onClick={() => handleClear(index)}><img src={iconClear} /></button>
								}
							</div>
							{emailError &&
								<div className="error">
									Error: {emailError}
								</div>
							}
						</React.Fragment>
					)
				})}
			</div>
			<button className="add" onClick={() => handleAdd()}>Add</button>
			{totalError && <div className="error" dangerouslySetInnerHTML={{ __html: totalError }} />}
			<button className="send btn-referring-friend" onClick={() => handleSend()}>Send</button>
		</div>
	)
}