import React from 'react'

import { useAlert } from 'core/providers/alert'
import { DomainSetting } from 'core/models'
import { insertElementInArray } from 'utils'

const DomainSettingsContext = React.createContext({})

export function DomainSettingsProvider({
	children,
}) {
	const { setError } = useAlert()

	const [isLoading, setLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState(0)
	const [domainSettings, setDomainSettings] = React.useState([])

	async function domainSettingsSearch(params, bulkDomains) {
		setLoading(true);

		if (bulkDomains) {
			handleBulkDomains(bulkDomains);
		} else {
			await fetchDomainSettings(params);
		}
	}

	function handleBulkDomains(bulkDomains) {
		const mappedDomains = bulkDomains.map(domain => ({
			id: "null",
			domain: domain.domain,
			emailOption: 0,
			emailUpdatedAt: null,
			popupOption: 0,
			popupUpdatedAt: null,
			passwordOption: null,
			updatedAt: null,
			allowConsent: null,
			avatar: domain.avatar,
			name: domain.name,
			manuallyAdded: true
		}));
		setDomainSettings(mappedDomains);
		setLoading(false);
	}

	async function fetchDomainSettings(params) {
		let newTotalCount;
		let newDomainSettings;

		const { error, totalSize, results } = await DomainSetting.load(params);

		if (error) {
			setLoading(false);
			setError(error);
			return;
		}

		newTotalCount = totalSize;  // Default value is the totalSize received from API

		if (params.resetDomainSetting) {
			newDomainSettings = insertElementInArray(
				params.resetDomainSetting,
				params.resetDomainSetting.index,
				results
			);
			newTotalCount = totalSize + 1;  // Increment total count if adding an element
		} else {
			newDomainSettings = results;
		}

		// Update the state only once for efficiency
		setLoading(false);
		setTotalCount(newTotalCount);
		setDomainSettings(newDomainSettings);
	}

	const updateDomainSetting = (updatedDomainSetting) => {
		const updatedDomainSettings = domainSettings.map(domainSetting => {
			if (domainSetting.domain === updatedDomainSetting.domain) {
				return updatedDomainSetting;
			}
			return domainSetting;
		});
		setDomainSettings(updatedDomainSettings)
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		totalCount,
		domainSettings,
		updateDomainSetting,

		domainSettingsSearch,
	}), [isLoading, totalCount, domainSettings])

	return (
		<DomainSettingsContext.Provider value={memoedValue}>
			{children}
		</DomainSettingsContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, totalCount: number, domainSettings: Array<DomainSetting>, domainSettingsSearch: Function, updateDomainSetting: Function}} UseDomainSettings
 * @returns {UseDomainSettings}
 */
export function useDomainSettings() {
	return React.useContext(DomainSettingsContext)
}