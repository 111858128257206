import React from 'react'

import './styles.scss'

import {
	SenderInputField,
} from 'components'

import {
	useFilter, useDigests, Filter, Sender,
} from 'core'

export function FilterModalHeaderEl({
	tabIndex,
	onSave = () => { },
}) {

	const { category, senders, setSenders } = useFilter()
	const { currentDigest, currentSenders } = useDigests()

	/** @param {Sender} sender */
	function checkDisableFn(sender) {
		// console.log(category)
		return category === Filter.Category.Digest ? sender?.hasDigestFilter : sender?.hasFilter
	}

	return (
		<div className="header">
			<div className="title">{category === Filter.Category.Digest ? 'Digest Filters' : 'Filters'}</div>
			<div className="sender-input-container">
				<div>Apply to Sender(s):</div>
				<div className="sender-input-wrapper">
					<SenderInputField
						params={category === Filter.Category.Digest ? { digestId: currentDigest?.id } : {}}
						checkDisableFn={checkDisableFn}
						senders={senders}
						onChange={value => setSenders(value)} />
					{category === Filter.Category.Digest &&
						<button
							className="btn-apply-to-digest"
							onClick={() => setSenders(currentSenders)}>
							Apply to Entire Digest
						</button>
					}
				</div>
				<div className={`save ${(tabIndex !== Filter.Type.Library && tabIndex !== Filter.Type.Custom) && 'transparent'}`} onClick={() => onSave()}>
					<button className='btn-save-filter'>Save</button>
				</div>
			</div>
		</div>
	)
}