import React from "react";
import { Alert, AlertButton, AlertButtonContainer, AlertPage, AlertTitle } from "./core";
import { BlueButton } from "components/button";
import { DomainGroups } from "core/models/admin/domaingroups";
import deleteIcon from '../../assets/images/delete/gray.svg'
import { useDomainGroupListContext } from "core/providers/admin/domain-group-list";
import { NewGroup } from "./new-group";
import { AddNewDomain } from "./add-new-domain";
import { Loader } from "components/loader";


export const DomainGroupModal = ({ title, onClose = () => { }, domainGroupId = '' }) => {
    const [domainList, setDomainList] = React.useState([])
    const { getDomainGroupList } = useDomainGroupListContext()
    const [addNewDomain, setAddNewDomain] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        getDomains();
    }, [])

    const getDomains = async () => {
        setLoading(true);
        const domains = await DomainGroups.getDomainListOfGroup(domainGroupId);
        setDomainList(domains);
        setLoading(false)
    }

    const removeDomainsFromGroup = async (domainId) => {
        await DomainGroups.removeDomainsFromGroup({ domains: [domainId], domainGroupId })
        getDomains();
    }

    const addDomain = async (domainName) => {
        await DomainGroups.addDomainToGroup({ domains: [domainName], domainGroupId });
        getDomains();
    }


    return <Alert onCancel={onClose}>
        <AlertPage>
            <AlertTitle>{title}</AlertTitle>
            {loading ? <div style={{ marginTop: 40 }}><Loader /> </div> : <div style={{ marginTop: 20 }}>
                {domainList.map((domain) => <div style={{ fontSize: 16, fontWeight: '600', padding: 8, borderBottom: '1px solid', borderColor: 'white', display: 'flex', justifyContent: 'space-between' }}>
                    <div>{domain.domain}</div>
                    <div style={{ cursor: 'pointer' }}><img onClick={() => { removeDomainsFromGroup(domain.id) }} style={{ height: 20, width: 20 }} src={deleteIcon}></img></div>

                </div>)}
            </div>}
            <AlertButtonContainer>
                <AlertButton onClick={() => { setAddNewDomain(true) }}>Add Domain</AlertButton>
                {addNewDomain && <AddNewDomain onDomainSave={(domainName) => { addDomain(domainName) }} onClose={() => { setAddNewDomain(false) }} />}
            </AlertButtonContainer>
        </AlertPage>
    </Alert>
}