import { BaseModel } from 'core/models/base'
import { Storage } from 'utils'

import { Api, Segment } from '../'

export class Referral extends BaseModel {
	email
	status
	referredAt
	acceptedAt

	/** @returns {Referral} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		return result
	}

	static async getAll() {
		const defaultError = 'Sorry, there are some troubles to get referrals.'
		try {
			const { error, data } = await Api.get('/referrals')
			return { error: error ? defaultError : null, results: Referral.fromJSONArray(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async submit(emails) {
		const defaultError = 'There was an error submitting this referral request. It could be that the user has already been referred, has an existing account, or an issue with our servers. Please try again later or contact us directly to ensure your family, friend or colleague is able to set up their account.'
		try {
			const { error, errorCode, success } = await Api.post('/referrals/submit', { emails })
			if (success) {
				Segment.track(Segment.Event.referringFriend, Storage.userId, emails);
			}
			// if (errorCode === 422) return { error: 'The email provided has already been invited by another user or has already created an account.' }
			// else if (errorCode === 5001) return { error: 'This email address has already been referred by another user.' }
			// else if (errorCode === 5005) return { error: 'Insufficient referral credits.' }
			// else return { error: error ? defaultError : null }
			return {
				error: error ? defaultError : null,
				errorCode,
			}
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async getAcceptedCount() {
		const defaultError = 'Sorry, there are some troubles to get accepted count.'
		try {
			const { error, data } = await Api.get("/referrals/accepted-count")
			return {
				error: error ? defaultError : null,
				creditCount: data ? data.creditCount : 0,
				acceptedCount: data ? data.acceptedCount : 0
			}
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	get isAccepted() { return Boolean(this.acceptedAt) }

	async remind() {
		const defaultError = 'Sorry, there are some troubles to remind referral.'
		try {
			const { error } = await Api.post('/referrals/remind', { email: this.email })
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}
}