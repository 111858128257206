export const dragItemStyle = (draggableStyle, isDragging) => ({
	...draggableStyle,
	background: isDragging ? '#000000' : 'transparent',
	padding: isDragging ? '8px' : '0',
	border: isDragging ? '1px solid #61CEF7' : 'transparent',
	borderRadius: '2px'
})

export const dragListStyle = (isDraggingOver) => ({})

export function handleDragResult(list, startIndex, endIndex) {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}