import { User } from "core";
import { Alert, AlertButton, AlertButtonContainer, AlertMessage, AlertPage, AlertTitle } from "./core";
import googleSigninButton from '../../assets/images/google_button.png'



export function GoogleSignInAlert({ onCancel = () => { },
    onButtonClick = () => { } }) {
    return (<Alert onCancel={() => { onCancel() }}>
        <AlertPage>
            <AlertTitle>Authentication Required</AlertTitle>
            <AlertMessage>Your authentication with Google has expired. Please log in again to grant lockrScan access. </AlertMessage>

            <AlertButtonContainer>
                {/* <AlertButton onClick={async () => {
                    onButtonClick();
                }} type="positive">Sign in with google</AlertButton> */}
                <button onClick={async () => {
                    onButtonClick();
                }}>
                    <img src={googleSigninButton} style={{ height: 63, width: 263, cursor: "pointer" }}  ></img>
                </button>
            </AlertButtonContainer>
        </AlertPage>
    </Alert>)
}