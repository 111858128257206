import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'
import { Sender, FilterLibraryGroup, FilterLibrary } from 'core/models'

import {
	DateHelper, isValidText,
	ObjectHelper, ArrayHelper, Storage
} from 'utils'
import { errorCodes, errorMessages } from 'constants/error'
import { Segment } from 'core/analytics'

export class FilterKeyword extends BaseModel {
	op
	action
	keyword

	/** @returns {FilterKeyword} */
	static fromJSON(json) { return super.fromJSON(json) }

	/** @returns {Array<FilterKeyword>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	/** @returns {FilterKeyword} */
	static create(op = null) {
		const result = new FilterKeyword()
		result.op = op || 'and'
		result.keyword = ''
		result.action = 'contains'
		return result
	}

	get isEmpty() { return !isValidText(this.keyword) }
	get getAction() {
		switch (this.action) {
			case 'contains':
				return 'Contains'
			case 'notContain':
				return 'Does not contain'
			case 'greaterThan':
				return 'Greater Than'
			case 'lessThan':
				return 'Less Than'
			default:
				break;
		}
		return ''
	}

	get description() {
		return `${this.getAction} \"${this.keyword}\"`
	}
}

export class Filter extends BaseModel {
	userId
	name
	alwaysOn = true
	from = null
	to = null
	keywords = [FilterKeyword.create(), FilterKeyword.create()]
	hasAttachment = null
	hasAttachmentOp = 'and'
	/** @type {Array<FilterLibraryGroup>} */
	filterLibraries = []
	/** @type {Array<Sender>} */
	senders = []

	static Category = Object.freeze({
		Filter: 'filter',
		Digest: 'digest',
	})

	static Type = Object.freeze({
		Library: 'library',
		Custom: 'custom',
		Saved: 'saved',
	})

	/** @returns {Filter} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.from = DateHelper.parse(json?.from)
			result.to = DateHelper.parse(json?.to)
			result.keywords = FilterKeyword.fromJSONArray(json.keywords)
			result.filterLibraries = FilterLibraryGroup.fromJSONArray(json.filterLibraries)
			result.senders = json.senders ? Sender.fromJSONArray(json.senders) : []
		}
		return result
	}

	/** @returns {Array<Filter>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static async updateOrCreate(filter, filterType, { senders, libraries, alwaysOn = true, from, to, keywords, filterByAttachment, hasAttachment, hasAttachmentOp, isSaveFilter = false, name = "", isDigest = false }) {
		try {
			let filterLibraries = FilterLibrary.trim(libraries)
			const senderIds = senders.map(x => typeof x === 'string' ? x : x.id)
			let params = {
				id: filter?.id,
				senderIds,
				alwaysOn: true,
				isSaveFilter: false,
				isDigest,
			}

			if (filterType === Filter.Type.Library) {
				if (filter?.hasLibraries && !ArrayHelper.isValid(filterLibraries)) {
					return await Filter.delete({ id: filter?.id, isDigest })
				}

				params = { ...params, filterLibraries }
			} else {
				if (Filter.isDefault({ alwaysOn, from, to, keywords, hasAttachment, hasAttachmentOp })) {
					return await Filter.delete({ id: filter?.id, isDigest })
				}

				params = {
					...params,
					filterLibraries: [],
					alwaysOn,
					isSaveFilter, name,
					keywords,
				}
				if (!alwaysOn) params = { ...params, from, to }
				if (filterByAttachment) params = { ...params, hasAttachment, hasAttachmentOp }
			}

			const { error, errorCode } = filter?.id ? await Api.post('/filters/edit', params) : await Api.post('/filters/create', params)
			if (filter?.id) {
				Segment.track(Segment.Event.userEditedFilter, Storage.userId, params);
			}
			else {
				Segment.track(Segment.Event.userCreatedFilter, Storage.userId, params);
			}

			if (errorCode === 409) return { error: 'The filter name you have selected already exists. lockrMail doesn\'t support multiple filter preferences sharing the same name. Please rename the filter to prevent the duplicate file name.' }
			if (errorCode === 422) return { error: 'These senders already have filters.' }
			if (errorCode === 5101) return { error: 'These senders already have filters.' }
			if (errorCode === 5103) return { error: errorMessages.EMPTY_FILTER }
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async create(senderIds, params) {
		const defaultError = 'Sorry, there are some troubles to create filter.'
		try {
			const { error, errorCode } = await Api.post('/filters/create', { senderIds, ...params })
			if (errorCode === 409) return { error: 'The filter name you have selected already exists. lockrMail doesn\'t support multiple filter preferences sharing the same name. Please rename the filter to prevent the duplicate file name.' }
			if (errorCode === 5101) return { error: 'These senders already have filters.' }
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async applyLibraries({ senders, libraries, filter = null, isDigest = false }) {
		return await FilterLibrary.apply({ senders, libraries, filter, isDigest })
	}

	static async applySavedFilter({ savedFilter, senders, isDigest = false }) {
		const defaultError = 'Sorry, there are some troubles to apply filter.'
		try {
			const senderIds = senders.map(sender => sender.id)

			let params = {
				isSaveFilter: false,
				alwaysOn: savedFilter.alwaysOn,
				keywords: savedFilter.keywords,
				isDigest,
			}
			if (!savedFilter.alwaysOn) params = { ...params, from: savedFilter.from, to: savedFilter.to }
			if (ObjectHelper.isValid(savedFilter, 'hasAttachment')) params = { ...params, hasAttachment: savedFilter.hasAttachment }
			return await Filter.create(senderIds, params)
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async getSavedFilters({ searchText, searchCriteria = 'all', isDigest = false, }) {
		const defaultError = 'Sorry, there are some troubles to get filters.'
		try {
			const { error, data } = await Api.get('/filters/get-saved-filters', { searchText: searchText || '', searchCriteria, isDigest })
			return { error, results: data.rows ? Filter.fromJSONArray(data.rows) : [], count: data.count || 0 }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async saveSavedFilter(id, params) {
		const defaultError = 'Sorry, there are some troubles to save filter.'
		try {
			const { error, errorCode } = await Api.post('/filters/edit-saved-filter', { id, ...params })
			if (errorCode === 422) return { error: 'These senders already have filters.' }
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async deleteSavedFilter(id) {
		const defaultError = 'Sorry, there are some troubles to delete filter.'
		try {
			const { error } = await Api.post('/filters/delete-saved-filter', { id })
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async getFilter({ id }, isDigest = false) {
		if (!id) return {}

		const defaultError = 'Sorry, there are some troubles to get filter.'
		try {
			const { error, errorCode, data } = await Api.get('/filters/get-filter', { id, isDigest })
			if (errorCode === errorCodes.NOT_FOUND) return {}

			return { error, result: Filter.fromJSON(data) }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async saveFilter(id, params) {
		const defaultError = 'Sorry, there are some troubles to save filter.'
		try {
			const { error, errorCode } = await Api.post('/filters/edit', { id, ...params })
			if (errorCode === 5101) return { error: 'These senders already have filters.' }
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async delete({ id, isDigest = false }) {
		const defaultError = 'Sorry, there are some troubles to delete filter.'
		try {
			const { error, success } = await Api.post('/filters/delete', { id, isDigest })
			if (success) {
				Segment.track(Segment.Event.userDeletedFilter, Storage.userId, { id, isDigest })
			}
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static async sendFlagError(mailId) {
		if (!mailId) return

		const defaultError = 'Sorry, there are some troubles to send filter flag error.'
		try {
			const requestPath = `/filters/send_flag_error/${mailId}`
			const { error } = await Api.get(requestPath)
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	static defaultFilter() { return new Filter() }
	static isDefault(value) {
		const defaultFilter = Filter.defaultFilter()
		let result = ObjectHelper.equals(value?.alwaysOn, defaultFilter.alwaysOn)
		result = result && ObjectHelper.equals(value?.from, defaultFilter?.from)
		result = result && ObjectHelper.equals(value?.to, defaultFilter?.to)
		result = result && ObjectHelper.equals(value?.keywords?.filter(x => x.keyword), defaultFilter?.keywords?.filter(x => x.keyword))
		result = result && ObjectHelper.equals(value?.hasAttachment, defaultFilter?.hasAttachment)
		result = result && ObjectHelper.equals(value?.hasAttachmentOp, defaultFilter?.hasAttachmentOp)
		return result
	}

	get timeFrame() {
		const timeFormatter = "MM/DD/YY"
		if (this.alwaysOn) {
			return `Ongoing, beginning ${DateHelper.format(this.createdAt, timeFormatter)}`
		}

		return `${DateHelper.format(this.from, timeFormatter)} - ${DateHelper.format(this.to, timeFormatter)}`
	}

	get filterByAttachment() { return this.hasAttachment !== null }
	get formattedAttachment() {
		if (!this.filterByAttachment) return ""
		return this.hasAttachment ? "Has attachments" : "No attachments"
	}

	get hasKeywords() { return ArrayHelper.isValid(this.keywords) }
	formattedKeywords(separator = ", ") {
		if (!this.hasKeywords) return ""
		return this.keywords.map(keyword => keyword.description).join(separator)
	}

	get hasLibraries() { return ArrayHelper.isValid(this.filterLibraries) }

	fullDescription(separator = " ") {
		const results = [this.timeFrame]
		if (this.hasKeywords) results.push(this.formattedKeywords())
		if (this.filterByAttachment) results.push(this.formattedAttachment)
		if (this.hasLibraries) results.push(
			this.filterLibraries.map((filterLibrary, index) => filterLibrary.libraries.map((library, index) => {
				const formattedKeywords = library.formattedKeywords
				return formattedKeywords ? `${library.name} ${formattedKeywords}` : library.name
			})))
		return results.join(separator)
	}
}

export * from './library'
export * from './preview'