import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { isChrome } from 'react-device-detect'

import './styles.scss'
import iconNotificationDefault from 'assets/images/notification_default.svg'
import iconNotificationWhite from 'assets/images/disable-white.svg'
import iconDigestPreview from 'assets/images/play-circle-24px.svg'
import iconCannyNitification from 'assets/images/canny-notification.svg'

import { NotificationItemEl } from './item'
import { DigestPreviewAlert, DigestAccessAlert } from 'components'

import { Notification } from 'core/models'
import { useAlert } from 'core/providers/alert'
import { useAuth } from 'core/providers/auth'
import { Storage, ExtensionHelper, DOMHelper, ArrayHelper } from 'utils'
import { routes } from 'routes'
import { config } from 'constants/config';
import { useDigests } from 'core'

const enableInterval = 30 * 60 * 1000

export function NotificationContainer({
	className = "",
}) {
	const location = useLocation()
	const { width } = useWindowSize()

	const { setAlert, setError } = useAlert()
	const { currentUser, isNewUser, updateCurrentUser, currentLockrScanUser } = useAuth()
	const { digests } = useDigests();


	function isDesktop() { return width >= 992 }

	/** @type {[Array<Notification>, Function]} */
	const [notifications, setNotifications] = React.useState([])
	const [disabled, setDisabled] = React.useState(false)
	const [cannyNotificationVisible, setCannyNotificationVisible] = React.useState(true);
	React.useEffect(() => {
		if (disabled) {
			setTimeout(() => {
				setDisabled(false)
			}, enableInterval)
		}
	}, [disabled])

	/** @type {[Notification, Function]} */
	const [curNotification, setCurNotification] = React.useState(null)
	React.useEffect(() => {
		calcCurNotification()
	}, [notifications])

	const [isNotificationClosed, setNotificationClosed] = React.useState(closed)
	const [digestPreviewAvailable, setDigestPreviewAvailable] = React.useState(false)
	const [isVisibleDigestAccess, setIsVisibleDigestAccess] = React.useState(false)

	React.useEffect(() => {
		checkNotifications()
	}, [currentUser, currentLockrScanUser, digests])
	React.useEffect(() => {
		checkNotifications();
	}, [cannyNotificationVisible])

	async function checkNotifications() {
		if (!currentUser) {
			setNotifications([])
			return
		}

		if (new Date().getTime() - currentUser.createdAt?.getTime() <= enableInterval) {
			setDisabled(true)
		}

		const notVerifiedEmails = currentUser.connectedEmails.filter(x => {
			return !x.isVerified && x.isAfter7d >= 7
		})
		const notifications = []

		if (ArrayHelper.isValid(notVerifiedEmails)) {
			notifications.push(new Notification({
				type: "red",
				icon: iconNotificationWhite,
				priority: Notification.Priority.VerifyEmail,
				isClose: false,
				children: <React.Fragment>
					Oh no! We have been unable to verify your email address. You can no longer receive emails.&nbsp;
					<NavLink to="/account">Verify now</NavLink>
				</React.Fragment>
			}))
		}

		const crxNotification = await checkCrxInstalled()
		if (crxNotification && !Storage.notificationBarClosed) notifications.push(crxNotification)

		// const digestPreviewNotification = checkDigestPreview()

		// if (digestPreviewNotification && !Storage.notificationBarClosed) notifications.push(digestPreviewNotification)

		// const digestAccessNotification = checkDigestAcces()
		// if (digestAccessNotification) notifications.push(digestAccessNotification)

		const newChromeExtensionNotificationObject = new Notification({
			type: 'blue',
			icon: iconCannyNitification,
			priority: Notification.Priority.Extension,
			isClose: true,
			onClose: () => { setCannyNotificationVisible(false); Storage.chromeExtensionNotification = true },
			children: <>Introducing the all NEW lockrMail Chrome Extension! <a onClick={() => window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi', '_blank')} style={{ textDecoration: 'none', color: 'white' }}>Download now >></a></>
		})
		if (!Storage.chromeExtensionNotification && !currentUser.isExtensionInstalled && currentUser.seenOnChrome) {
			notifications.push(newChromeExtensionNotificationObject);
		}

		const cannyNotificationobject = new Notification({
			type: 'blue',
			icon: iconCannyNitification,
			priority: Notification.Priority.LockrScan,
			isClose: true,
			onClose: () => { setCannyNotificationVisible(false); Storage.lockrScanNotificationClosed = true },
			children: <>Just Launched: lockrScan - a new way to clean up your Gmail inbox. <Link to={routes.LockrScan}>Get started!</Link></>
		})
		if (cannyNotificationVisible && !Storage.lockrScanNotificationClosed && !currentLockrScanUser) {
			notifications.push(cannyNotificationobject)
		}






		setNotifications(notifications)
	}

	function handleNotificationClose() {
		Storage.notificationBarClosed = true;
		checkNotifications();
	}

	async function checkCrxInstalled() {
		// If browser is not desktop and chrome...
		if (!isChrome || !isDesktop()) return

		// If user has logged in on chrome extension...
		if (!currentUser || currentUser.isExtensionInstalled) return

		const isCrxInstalled = await ExtensionHelper.checkInstalled()
		if (isCrxInstalled) return null

		return new Notification({
			icon: iconNotificationDefault,
			priority: Notification.Priority.CRX,
			isClose: true,
			onClose: handleNotificationClose,
			children: <React.Fragment>
				Install lockrMail's Chrome Extension to unlock even more features: <a href={process.env.REACT_APP_CHROME_EXTENSION_URL} target="_blank">Click here</a>.
			</React.Fragment>
		})
	}

	function checkDigestPreview() {
		if (!currentUser) return null

		const { openDigestPreview = false } = location.state ?? {}

		let digestPreviewAvailable = currentUser && !currentUser.isDigestEnabled && location.pathname?.includes(routes.Dashboard)
		if (isNewUser) {
			digestPreviewAvailable = false
		} else {
			digestPreviewAvailable = digestPreviewAvailable || openDigestPreview
		}

		setDigestPreviewAvailable(digestPreviewAvailable)

		if (!digestPreviewAvailable) return null

		return new Notification({
			type: "blue",
			priority: Notification.Priority.DigestPreview,
			icon: iconDigestPreview,
			isClose: true,
			onClose: handleNotificationClose,
			children: <React.Fragment>
				Big news! We've added some brand new exclusive features. <a href="https://lockrmail.com/introducing-digests/" target="_blank">Learn more</a>
			</React.Fragment>
		})
	}

	function handleDigestAccessClose() {
		const { error } = currentUser.update({ showDigestNotification: false })
		if (error) {
			setError(error)
		}
		updateCurrentUser({ showDigestNotification: false })
		checkNotifications();
	}

	async function handleDigestAccessPopupClose() {
		setIsVisibleDigestAccess(false)
		const { error } = await currentUser.update({ showDigestPopup: false })
		if (error) {
			setError(error)
			return
		}
		updateCurrentUser({ showDigestPopup: false })
	}

	function checkDigestAcces() {
		if (!currentUser) return null

		if (currentUser.isDigestEnabled && currentUser.showDigestPopup) setIsVisibleDigestAccess(true)

		if (!currentUser.isDigestEnabled || !currentUser.showDigestNotification) return null

		if (digests.length > 0) return null

		return new Notification({
			type: "blue",
			priority: Notification.Priority.DigestPreview,
			icon: iconDigestPreview,
			isClose: true,
			onClose: handleDigestAccessClose,
			children: <React.Fragment>
				Big news! We’ve launched Digests. <a href="https://lockrmail.com/introducing-digests/" target="_blank">Learn more</a>
			</React.Fragment>
		})
	}

	function calcCurNotification() {
		if (!ArrayHelper.isValid(notifications)) {
			setCurNotification(null)
			return
		}

		const sorted = notifications.sort((a, b) => a.priority - b.priority)
		setCurNotification(sorted[sorted.length - 1])
	}

	/** @param {MouseEvent} event */
	function handleClick(event) {
		if (DOMHelper.matches(event.target, '.notification-container a')) {
			setNotificationClosed(true)
		}
	}

	return (
		<React.Fragment>
			{(curNotification && !isNotificationClosed && !disabled) &&
				<div className={`notification-container ${className} ${curNotification.type}`} onClick={handleClick}>
					<div className="notification-container-wrapper">
						<React.Fragment>
							<NotificationItemEl {...curNotification} />
						</React.Fragment>
					</div>
				</div>
			}
			{(currentUser && !currentUser.previewDigestClosed && digestPreviewAvailable) &&
				<DigestPreviewAlert
					onClose={() => {
						setDigestPreviewAvailable(false)
					}} />
			}
			{currentUser && isVisibleDigestAccess && (<DigestAccessAlert onClose={handleDigestAccessPopupClose} />)}
		</React.Fragment>
	)
}

