import React from 'react'

import './styles.scss'

export function Switch({
	value,
	onChange = (value) => { }
}) {
	return (
		<div className={`switch ${value && 'selected'}`} onClick={() => onChange(!value)}>
			<div className="switch-header"></div>
		</div>
	)
}