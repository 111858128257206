import React from 'react'

import arrowNext from '../../../assets/images/arrow-next-white.svg'
import iconChecked from '../images/checked-green-no-outline.svg'

import { HMenu } from '../'
import { HMenuSubContainer } from './sub-container'

import { isMobile } from '../../../utils'

export function HMenuOption({
	option,
	disabledStyle,
	index,
	onClick,
	onMouseOver,
	onSubOptionClose
}) {
	const [isOpenDescription, setOpenDescription] = React.useState(false)

	const isSingle = () => { return option.subOptions.length <= 0 }
	const selected = () => { return option.selected }
	const disabled = () => { return option.disabled }

	function handleMouseOver(event) {
		event.preventDefault()
		event.stopPropagation()

		if (onMouseOver) { onMouseOver(option, index) }
	}

	function handleClick(event) {
		event.preventDefault()
		event.stopPropagation()

		if (disabled() && Boolean(option.description)) {
			setOpenDescription(!isOpenDescription)
			return
		}

		if (onClick) { onClick(option, index) }
	}

	function handleSubOptionClose() {
		if (onSubOptionClose) { onSubOptionClose() }
	}

	return (
		<div key={index}>
			<div
				className={`h-menu-item ${isSingle() && !Boolean(option.after) ? 'single' : ''} ${option.selected ? 'selected' : ''} modal-parent`}
				style={disabled() ? disabledStyle : {}}
				onMouseOver={event => handleMouseOver(event)}
				onClick={(event) => handleClick(event, option, index)}>
				{!option.isParent && <img src={iconChecked} alt="" />}
				<div>{option.label}</div>
				{(option.subOptions.length > 0) &&
					<img src={arrowNext} alt="" />
				}
				{option.after &&
					<div className="after">{option.after}</div>
				}
				{(option.description && (!isMobile || isOpenDescription)) &&
					<div className="description">{option.description}</div>
				}
			</div>
			{(option.subOptions.length > 0 && option.isChildOpen) &&
				<HMenuSubContainer>
					<HMenu open options={option.subOptions} onClose={handleSubOptionClose} />
				</HMenuSubContainer>
			}
		</div>
	)
}