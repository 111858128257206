import { plainToClass } from 'class-transformer'

import { Api } from 'core'
import { BaseModel } from 'core/models/base'
import { errorCodes, errorMessages } from 'constants/error'
import { DateHelper, ArrayHelper } from 'utils'

export class DiscoverCompany extends BaseModel {
	static Category = Object.freeze({
		'Retail': 'retail',
		'Travel & Lifestyle': 'travel-lifestyle',
		'Tech': 'tech',
		'News': 'news',
		'Business': 'business',

	})

	userId
	title
	description
	categories
	avatarDesktop
	avatarTablet
	avatarMobile
	url
	subscribeText
	order

	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.order = json?.order ?? 0
		}
		return result
	}

	static async get(id) {
		if (!id) return {}

		try {
			const { error, data } = await Api.get(`/discover/${id}`)
			if (error) return { error: errorMessages.CONTACT_US }
			return { result: data && DiscoverCompany.fromJSON(data) }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async search(params) {
		try {
			const { error, data } = await Api.post('/discover', params)
			if (error) return { error }

			let results = data ? DiscoverCompany.fromJSONArray(data.rows) : []
			return { totalSize: data ? data.count : 0, results }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async add(params) {
		try {
			const { error } = await Api.post('/admin/discover', params)
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async update(id, params) {
		return await DiscoverCompany.batchUpdate([{ id, ...params }])
	}

	static async batchUpdate(companies) {
		try {
			const { error, data } = await Api.put(`/admin/discover`, companies)
			return { error: error ? errorMessages.CONTACT_US : null, results: DiscoverCompany.fromJSONArray(data?.data) }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	static async delete(id) {
		return await DiscoverCompany.batchDelete([id])
	}

	static async batchDelete(ids = []) {
		try {
			const { error } = await Api.delete('/admin/discover', { ids })
			return { error }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.CONTACT_US) }
		}
	}

	get category() { return ArrayHelper.isValid(this.categories) ? this.categories[0] : null }
	get avatar() {
		if (window.innerWidth >= 992) return this.avatarDesktop
		else if (window.innerWidth >= 577 && this.avatarTablet) return this.avatarTablet
		else if (window.innerWidth <= 576 && this.avatarMobile) return this.avatarMobile
		return this.avatarDesktop
	}
}