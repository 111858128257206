import React from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'

import {
	Icon, Checkbox
} from 'components'
import { AdminUserInfoAlert } from '../user-info-alert'

import { DateHelper } from 'utils'

export function UserListItem({
	user = {},
	isHeader = false,
	selected = false,
	onSelect = (value) => { },
	onRefresh = () => { }
}) {
	const { avatar, name, email, createdAt, tags = [], verifiedCount } = user

	const [isOpenInfo, setOpenInfo] = React.useState(false)

	function handleClick() {
		if (isHeader) return

		setOpenInfo(true)
	}

	function renderCreatedAt(date) {
		if (!date) return ''

		return 'Create ' + DateHelper.format(date, 'MM/DD/YYYY')
	}

	return (
		<>
			<div className={`user-list-item ${isHeader && 'header'}`} onClick={() => handleClick()}>
				{isHeader ?
					<>
						<div className="name"></div>
						<div className="email">Email</div>
						<div className="created-at">Created</div>
						<div className="user-tag">Tags</div>
						<div className="verified-count">Verified</div>
					</>
					:
					<>
						<Checkbox
							iconUnchecked={iconUnchecked}
							iconChecked={iconChecked}
							checked={selected}
							onChange={(value) => onSelect(value)} />
						<Icon className="avatar" src={avatar} placeholder={name} />
						<div className="name">{name}</div>
						<div className="email">{email}</div>
						<div className="created-at">{renderCreatedAt(createdAt)}</div>
						<div className="user-tag">{tags.join(', ')}</div>
						<div className="verified-count">
							<img className="checkmark" alt="" />
							<span>{verifiedCount}</span>
							<img className="arrow" alt="" />
						</div>
					</>
				}
			</div>
			{isOpenInfo &&
				<AdminUserInfoAlert
					user={user}
					onClose={(updated) => {
						setOpenInfo(false)
						if (updated) onRefresh()
					}} />
			}
		</>
	)
}