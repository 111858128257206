export const shippingColours = {
    "InfoReceived": '#EDEDED',
    "InTransit": '#2E70D2',
    "OutForDelivery": '#50E8B1',
    "AttemptFail": '#F46666',
    "Delivered": '#FAC748',
    "AvailableForPickup": '#BD94BC',
    "Exception": '#E88D67',
    "Expired": '#E88D67',
    "Pending": '#E88D67',
}

export const shippingStatus = {
    "InfoReceived": 'Info Received',
    "InTransit": 'In Transit',
    "OutForDelivery": 'Out For Delivery',
    "AttemptFail": 'Failed Attempt',
    "Delivered": 'Delivered',
    "AvailableForPickup": 'Available For Pickup',
    "Exception": 'Exception',
    "Expired": 'Exception',
    "Pending": 'Exception',
    "Others": 'Others',
}
export const shippingStatusFontColours = {
    "InfoReceived": 'black',
    "InTransit": 'white',
    "OutForDelivery": 'black',
    "AttemptFail": 'white',
    "Delivered": 'black',
    "AvailableForPickup": 'black',
    "Exception": 'white',
    "Expired": 'white',
    "Pending": 'white',
}
