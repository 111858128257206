import React from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import imgPreview1 from 'assets/images/digest-preview-1.svg'
import imgPreview2 from 'assets/images/digest-preview-2.svg'

import {
	Alert, AlertPage, AlertTitle, AlertButtonContainer, AlertButton
} from 'components/alert/core'

import { useAuth } from 'core'
import { routes } from 'routes'

export function DigestPreviewAlert({
	onClose = () => { }
}) {
	const navigate = useNavigate()

	const { currentUser } = useAuth()

	function handleReferFriend() {
		navigate(routes.ReferralHub)
		onClose()
	}

	async function handleClose() {
		currentUser?.update({ previewDigestClosed: true })
		onClose()
	}

	return (
		<Alert className="digest-preview-alert" onCancel={() => handleClose()}>
			<AlertPage>
				<AlertTitle>Get access to the all new Digests</AlertTitle>
				<div className="digest-preview-alert-wrapper">
					<div className="preview-list">
						<div className="preview-item">
							<div className="featured">
								<img src={imgPreview1} alt="" />
							</div>
							<div className="description">
								<strong>What are Digests?</strong><br></br>
								Digests are a powerful tool that allow you to combine communications from various Senders into one convenient email, delivered at your requested date and time.
							</div>
						</div>
						<br></br>
						<div className="preview-item">
							<div className="featured">
								<img src={imgPreview2} alt="" />
							</div>
							<div className="description">
								<strong>How can I get access to Digests?</strong><br></br>
								Digests is an early access feature at the moment - to get access, you only need to refer one person who successfully creates a lockrMail account.
							</div>
						</div>
					</div>
				</div>
				<AlertButtonContainer>
					<AlertButton onClick={() => handleReferFriend()}>Refer a friend</AlertButton>
				</AlertButtonContainer>
			</AlertPage>
		</Alert>
	)
}