import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import './styles.scss'

import { ArrayHelper } from '../../../../utils'

const colors = [
	['#2E70D2', '#61CEF7', '#50E8B1', '#8E24AA', '#D32F2F'],
	['#BD94BC', '#E88D67', '#FAC748', '#0288D1', '#43A047'],
]

export function Charts(props) {
	const { categories = [], names = [], stacks = [], data: dataDetails, differentStack = [], differentStackNames = [], yAxisName = null } = props
	const { data, maxIndices } = dataDetails
	function chartOption() {
		return {
			chart: {
				type: 'column',
				height: '200px',
				backgroundColor: 'transparent',
				spacingLeft: 0,
				spacingRight: 0,
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false
			},
			xAxis: {
				categories: categories,
				labels: {
					style: {
						fontFamily: 'Montserrat',
						fontStyle: 'normal',
						fontWeight: 'normal',
						fontSize: '14px',
						lineHeight: '20px',
						color: '#8B8B8B',
					},
				}
			},
			yAxis: {
				title: { text: yAxisName ? yAxisName : names[0]?.name },
				min: 0,
				// max: Math.max(10, max),
				// visible: false,
				stackLabels: {
					enabled: false,
					style: {
						fontWeight: 'bold',
						fontSize: '14px',
						lineHeight: '20px',
						color: '#FFFFFF',
						textOutline: 'none',
					},
					formatter: function () {
						return this.total === 0 ? '' : this.total
					}
				}
			},
			tooltip: {
				animation: false,
				enabled: true,
				formatter: function () {
					let tip = "<div>";
					const pointIndex = this.point.index;
					const currentStack = this.series.options.stack;
					let stackTotal = 0;
					let isStackTotalAdded = false; // Flag to check if stack total is already added
					let stackIndex = stacks.indexOf(currentStack)
					// First, calculate the total for the current stack
					this.series.chart.series.forEach(series => {
						if (series.options.stack === currentStack) {
							stackTotal += series.data[pointIndex].y;
						}
					});

					this.series.chart.series.forEach(series => {
						if (series.options.stack === currentStack) {
							let value = series.data[pointIndex].y;
							if (!isStackTotalAdded && maxIndices[stackIndex][pointIndex] === series.index) {
								value = stackTotal
								isStackTotalAdded = true;
							}
							let name = series.name;
							tip += `<div>${name}: ${value}</div><br />`;
						}
					});
					tip += `</div>`;
					return tip;
				}
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: true
					}
				},
			},
			legend: {
				enabled: false,
			},
			series: chartSeries(),
		}
	}

	function chartSeries() {
		if (!ArrayHelper.isValid(data)) return []
		const results = []
		stacks.forEach((stack, stackIndex) => {
			let namesToUse = names;
			let additionalIndex = 0
			const diffStackIndex = differentStack.indexOf(stack)
			if (diffStackIndex >= 0) {
				additionalIndex = namesToUse.length - 1;
				namesToUse = [differentStackNames[diffStackIndex]]
			}
			namesToUse.forEach((name, nameIndex) => {
				const dataIndex = stackIndex * namesToUse.length + nameIndex + additionalIndex
				if (dataIndex >= data.length) return
				const subData = data[dataIndex]
				// const color = colors[stackIndex][nameIndex]
				const colors = name.colors
				const color = stackIndex == 0 ? colors?.bar1 : colors?.bar2
				results.push({
					type: 'column',
					name: name?.name ? name?.name : name,
					borderColor: 'transparent',
					color: color,
					data: subData.map((e, index) => ({
						y: e,
						dataLabels: {
							className: "dataLabel",
							enabled: false
						}
					})),
					states: {
						hover: { enabled: false },
						inactive: { opacity: 1 }
					},
					stack: stack
				})
			})
		})
		return results
	}

	return (
		<div className="admin-traction-charts">
			<div className="admin-traction-chart">
				<HighchartsReact
					highcharts={Highcharts}
					options={chartOption()}
				/>
			</div>
		</div>
	)
}