import React from 'react'

import iconChecked from '../images/checked-green-no-outline.svg'

import { ArrayHelper, ObjectHelper } from '../../../utils'

export function SelectorItemEl({
	className = '',
	option,
	selected,
	disabled = false,
	onClick = (value) => { }
}) {
	const ref = React.useRef()

	const { label, value, subOptions, labelComponent } = option;
	React.useEffect(() => {
	}, [option])

	React.useEffect(() => {
		if (!ArrayHelper.isValid(subOptions) && ref.current) {
			ref.current.tabIndex = calcSelected() ? 0 : -1
		}
	}, [])

	function calcSelected() {
		return selected || option.selected
	}

	function isFocusable() {
		const { value, onClick } = option
		return (value || onClick) ? true : false
	}

	function callClick() {
		if (ObjectHelper.isValid(value)) onClick(value)
		else if (option.onClick) {
			option.onClick()
			onClick()
		}
	}

	function handleClick(event) {
		if (disabled) return;
		callClick()
	}

	function handleKeyDown(event) {
		if (disabled) return;
		if (event.keyCode === 13) {
			callClick()
		}
	}

	return (
		<div
			ref={ref}
			className={`selector-option ${className} ${(calcSelected() && !ObjectHelper.isValid(subOptions)) && 'selected'} ${(ObjectHelper.isValid(subOptions) || labelComponent) && 'has-sub-options'} ${isFocusable() && 'focusable'} ${disabled ? 'disabled' : ''} `}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
		>
			{(!subOptions) ?
				<>
					{!labelComponent && <img src={iconChecked} loading="lazy" alt="" />}
					{labelComponent ? labelComponent : <span>{label}</span>}
				</>
				:
				<>
					{labelComponent ? labelComponent : <div>{label}</div>}
					{(subOptions && subOptions.length > 0) &&
						<div className="selector-sub-options">
							{subOptions.map((option, index) =>
								<SelectorItemEl
									key={index}
									option={option}
									disabled={option.disabled}
									selected={option.selected}
									onClick={(value) => onClick(value)}
								/>
							)}
						</div>
					}
				</>
			}
		</div>
	);
}