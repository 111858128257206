import React from 'react'

import './styles.scss'

/**
 * @typedef {{className: string, title: string, type: ('positive'|'negative'|'link'), disabled: boolean, url: string, onClick: *}} Props
 * @param {Props} param0
 */
export function AlertButton({
	className = "",
	title, label,
	type = "positive", // one of positive, negative
	disabled,
	url,
	children,
	onClick = () => { }
}) {
	/**
	 * @param {MouseEvent} event
	 */
	function handleClick(event) {
		event?.stopPropagation()
		event?.preventDefault()

		if (type === 'link' && url) {
			window.open(url, '_blank')
		}

		onClick()
	}

	return (
		<>
			{type !== 'link' ?
				<button className={`alert-button ${type} ${className}`} type="button" disabled={disabled} onClick={handleClick}>
					{title || label ? <>{title || label}</> :
						children
					}
				</button>
				:
				<a rel="noreferrer noopener" className={`alert-button ${type} ${className}`} href={url} onClick={handleClick}>{title ?? children}</a>
			}
		</>
	)
}