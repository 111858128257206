import React from 'react'
import { useAsync } from 'react-use'

import { useAlert } from 'core/providers/alert'
import { useFilter } from 'core/providers/filter/filter'
import { Filter, FilterLibrary, FilterLibraryCategory, FilterLibraryGroup, Sender } from 'core/models'
import { ArrayHelper } from 'utils'

const FilterLibrariesContext = React.createContext({})

/**
 * @typedef {{category: "filter"|"digest", filter: Filter}} FilterLibrariesProviderProps
 * @param {FilterLibrariesProviderProps} param0
 */
export function FilterLibrariesProvider({
	children,
}) {
	const { setError } = useAlert()

	const { filter, senders } = useFilter()

	const [isLoading, setLoading] = React.useState(false)
	/** @type {[Array<FilterLibraryCategory>, Function]} */
	const [categories, setCategories] = React.useState([])
	const [categoryId, setCategoryId] = React.useState(null)
	React.useEffect(() => {
		if (ArrayHelper.isValid(categories)) {
			if (filter?.hasLibraries) setCategoryId(filter.filterLibraries[0].categoryId)
			else setCategoryId(categories[0].id)
		}
	}, [filter, categories])

	/** @type {[Array<FilterLibraryGroup>, Function]} */
	const [filterLibraries, setFilterLibraries] = React.useState([])
	/** @type {[Array<FilterLibraryGroup>, Function]} */
	const [selectionMap, setSelectionMap] = React.useState([])
	React.useEffect(() => { if (filter) setSelectionMap(filter.filterLibraries ?? []) }, [filter])

	function curLibraries() { return filterLibraries.find(x => x.categoryId === categoryId)?.libraries ?? [] }

	useAsync(async () => { loadCategories() }, [])
	async function loadCategories() {
		const { error, results } = await FilterLibraryCategory.get()

		if (error) {
			setError(error)
			return
		}

		setCategories(results)
	}

	useAsync(async () => { loadLibraries() }, [categoryId, filterLibraries])
	async function loadLibraries() {
		if (!categoryId) return

		if (ArrayHelper.isValid(curLibraries())) {
			return
		}

		setLoading(true)
		const { error, results } = await FilterLibrary.get(categoryId)
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setFilterLibraries([...filterLibraries, FilterLibraryGroup.create({ categoryId, libraries: results })])
	}

	const memoedValue = React.useMemo(() => ({
		isLoading,
		categories, filterLibraries,
		categoryId, setCategoryId,
		curLibraries,
		selectionMap, setSelectionMap,
	}), [filter, senders, isLoading, categories, filterLibraries, categoryId, curLibraries, selectionMap])

	return (
		<FilterLibrariesContext.Provider value={memoedValue}>
			{children}
		</FilterLibrariesContext.Provider>
	)
}

/**
 * @typedef {{isLoading: boolean, categories: Array<FilterLibraryCategory>, filterLibraries: Array<FilterLibraryGroup>, categoryId: string, setCategoryId: Function, curLibraries: Function, selectionMap: Array<FilterLibraryGroup>, setSelectionMap: Function, save: Function}} UseFilterLibraries
 * @returns {UseFilterLibraries}
 */
export function useFilterLibraries() {
	return React.useContext(FilterLibrariesContext)
}