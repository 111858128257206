import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import './styles.scss'

import {
	Loader,
	SearchField,
	Selector, SelectorOption,
	Pagination,
	SpecialLinkModal
} from 'components'
import { SpecialLinkListItem } from './list-item'

import { AdminSpecialLinkProvider, useAdminSpecialLink } from 'core'
import { parseSearchParams, redirectTo, routeToMe } from 'routes'

function SpecialLinksEl({ }) {
	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { isLoading, totalCount, specialLinks, loadSpecialLink } = useAdminSpecialLink()

	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(5)
	const [sortBy, setSortBy] = React.useState('mostRecent')

	const [permaLink, setPermaLink] = React.useState(null)

	React.useEffect(() => {
		loadSpecialLinks()
	}, [searchText, pageIndex, pageSize, sortBy])

	React.useEffect(() => { parseLocation() }, [searchParams])
	function parseLocation() {
		const { specialLink } = parseSearchParams(searchParams)
		setPermaLink(specialLink)
	}

	async function loadSpecialLinks() {
		loadSpecialLink({ searchText, pageIndex, pageSize, sortBy })
	}

	function clearLocationState() {
		delete location.state.specialLink
		navigate(redirectTo({ route: location.pathname }), { replace: true })
	}

	function handleAdd(event) {
		setPermaLink("")
	}

	return (
		<>
			<div className="admin-group">
				<div className="admin-group-header">
					<div className="admin-group-title">Special Links</div>
				</div>
				<div className="admin-special-link">
					<div className="header">
						<SearchField
							value={searchText}
							onChange={setSearchText}
						/>
						<Selector
							options={[
								new SelectorOption({ label: 'Most Recent', value: 'mostRecent' }),
								new SelectorOption({ label: 'Most Registrations', value: 'mostRegistrations' }),
								new SelectorOption({ label: 'Expired', value: 'expired' }),
							]}
							value={sortBy}
							onChange={(value) => setSortBy(value)} />
						<button className="btn-add" onClick={handleAdd}>Add</button>
					</div>
					<div className="special-link-list">
						{isLoading ? <Loader /> :
							<>
								<SpecialLinkListItem isHeader />
								{specialLinks.map((specialLink, index) => {
									return (
										<SpecialLinkListItem
											key={index}
											value={specialLink}
											onUpdate={(updated) => {
												if (updated) loadSpecialLinks()
											}}
										/>
									)
								})}
							</>
						}
					</div>
					<Pagination
						className="admin-special-link-pagination"
						totalSize={totalCount}
						pageIndex={pageIndex}
						pageSize={pageSize}
						onPageSize={(value) => setPageSize(value)}
						onPageIndex={value => setPageIndex(value)}
					/>
				</div>
			</div>
			{(typeof permaLink === "string") &&
				<SpecialLinkModal
					permalink={permaLink}
					onClose={(updated) => {
						setPermaLink(null)
						if (updated) loadSpecialLinks()

						navigate(routeToMe({ searches: { specialLink: null } }))
					}}
				/>
			}
		</>
	)
}

export function AdminSpecialLinksEl() {
	return (
		<AdminSpecialLinkProvider>
			<SpecialLinksEl />
		</AdminSpecialLinkProvider>
	)
}