import React from 'react'

import "./styles.scss"

import {
	NotificationContainer, Navigation, AppFooter,
} from 'components'
import { ReferralsList } from './referrals-list'
import { AcceptedReferrals } from './accepted-referrals'
import { Form } from './form'
import { FaqContainer } from './faq'
import { Helmet } from 'react-helmet'

export function ReferralHubPage(props) {
	return (
		<div id="parent" className="page-container referral-hub">
			<Helmet>
				<title>Refer a friend to lockrMail</title>
			</Helmet>
			<NotificationContainer />
			<Navigation />
			<div className="wrapper">
				<div className="title">
					Earn rewards when you share lockrMail's time-saving features!
				</div>
				<div className="content-wrapper">
					<div className="content-container">
						<ReferralsList />
						<AcceptedReferrals />
						<Form />
					</div>
					<FaqContainer />
				</div>
			</div>
			<AppFooter />
		</div>
	)
}