import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWindowSize, useAsync } from 'react-use'

import './styles.scss'
import iconArrowDown from 'assets/images/arrow_down.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'

import {
	Loader,
	Checkbox,
	SearchField,
	Selector, SelectorOption,
	Pagination,
	SelectOption,
	SelectDropDown,
} from 'components'
import { InstallCrxEl } from './install-crx'
import { DomainInput } from './domain-input'
import { DomainListItem } from './domain-list-item'
import { AddDomainModal } from './add-new-modal'

import { useAlert, useAuth, useDomainSettings, DomainSetting, Segment } from 'core'
import { ArrayHelper } from 'utils'
import { errorCodes, errorMessages } from 'constants/error'
import { parseSearchParams } from 'routes'
import { Helmet } from 'react-helmet'

export function AccountDomainSettings({
	className = '',
}) {
	const [searchParams] = useSearchParams()
	const { width } = useWindowSize()

	const { setAlert, setError } = useAlert()
	const { currentUser } = useAuth()
	const { isLoading, totalCount, domainSettings, domainSettingsSearch, updateDomainSetting } = useDomainSettings()

	const [searchText, setSearchText] = React.useState('')
	const [pageIndex, setPageIndex] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(25)
	const [isAddNew, setAddNew] = React.useState(false)
	const [selectedDomain, setSelectedDomain] = React.useState(null)
	/** @type {[Array<DomainSetting>, Function]} */
	const [selectedDomains, setSelectedDomains] = React.useState([])
	const [batchEmailOption, setBatchEmailOption] = React.useState(0)
	// Keep track of the previous domain names
	const prevDomainNamesRef = React.useRef([])
	const [isNewCreated, setIsNewCreated] = React.useState(false);
	// useRef to store the previous state of the first domain setting
	const prevFirstDomainSettingRef = React.useRef(domainSettings[0]);

	const prevDomainSettingsRef = React.useRef();


	useAsync(async () => {
		if (currentUser) {
			await DomainSetting.clearBlank()

			const { domain } = parseSearchParams(searchParams)
			if (domain) {
				setSelectedDomain(domain)
				await addPlaceholder(domain)
			}
		}
	}, [currentUser])

	React.useEffect(() => {
		// Check if 'manuallyAdded' of the first domain setting has changed from true to false or not present
		if (
			prevFirstDomainSettingRef.current?.manuallyAdded === true &&
			!domainSettings[0]?.manuallyAdded
		) {
			setIsNewCreated(true);
		}

		// Store the current first domain setting in ref for future comparisons
		prevFirstDomainSettingRef.current = domainSettings[0];
	}, [domainSettings]);

	React.useEffect(() => {
		loadDomainSettings()
	}, [currentUser, searchText, pageIndex, pageSize])

	React.useEffect(() => {
		setPageIndex(0);
	}, [pageSize, searchText])

	React.useEffect(() => {
		const currentDomainNames = domainSettings.map(e => e.domain);

		const domainsWithIdNowNull = [];
		domainSettings.forEach(domain => {
			const prevDomain = prevDomainSettingsRef.current?.find(d => d.domain === domain.domain);
			if (domain.id === "null" && prevDomain?.id !== "null") {
				domainsWithIdNowNull.push(domain.domain);
			}
		});

		// Check for removed domains
		const removedDomainNames = prevDomainNamesRef.current.filter(domainName => !currentDomainNames.includes(domainName));

		const domainsToUpdate = [...removedDomainNames, ...domainsWithIdNowNull];
		console.log(prevDomainNamesRef, domainsWithIdNowNull)
		if (domainsToUpdate.length > 0) {

			const updatedSelectedDomains = selectedDomains.filter(sd => !domainsToUpdate.includes(sd.domain));
			setSelectedDomains(updatedSelectedDomains);
		}

		prevDomainNamesRef.current = currentDomainNames;
		prevDomainSettingsRef.current = domainSettings;

	}, [domainSettings, selectedDomains])

	function loadDomainSettings({ addDomain = false, domain = null, bulkDomains = null, resetDomainSetting = null } = {}) {
		if (!currentUser) return
		domainSettingsSearch({ searchText, pageIndex, pageSize, domain, addDomain, resetDomainSetting }, bulkDomains)
	}

	async function addPlaceholder(domain) {
		if (!domain) return

		const { error, result } = await DomainSetting.load({ domain })
		if (error) {
			setError(error)
			return
		}

		if (!result?.id) {
			const { error } = await DomainSetting.add(domain)
			if (error) {
				setError(error)
				return
			}

			await loadDomainSettings()
		}
	}

	async function checkAvailability(emailOption) {
		const domains = selectedDomains.filter((e) => e.email).map((e) => e.domain)
		if (domains.length <= 0) return false

		const { errorCode } = await DomainSetting.checkAvailability(domains)
		if (errorCode === errorCodes.DS_OVERRIDE_ALLOW_FILTER) {
			setAlert({
				message: errorMessages.DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER,
				buttons: [
					{ type: 'positive', title: 'Confirm', onClick: () => handleBatchEmails(emailOption, true) },
					{ type: 'negative', title: 'Cancel', }
				]
			})
			return false
		}
		return true
	}

	async function batchUpdateEmails(emailOption) {
		try {
			const domains = selectedDomains.filter((e) => e.email).map((e) => e.domain)

			if (domains.length <= 0) return

			await updateManuallyAddedDomain()

			const { error } = await DomainSetting.update(domains, { emailOption: emailOption ?? batchEmailOption })
			if (error) {
				setError(error.message)
				return
			}
			else {
				Segment.track(Segment.Event.editingDomainBlockForEmails, currentUser?.id, { domains, emailOption: emailOption ?? batchEmailOption })
			}

			loadDomainSettings()

		} catch (error) {
			setError(error)
			return
		}

	}

	async function updateManuallyAddedDomain() {
		const manuallyAddedDomains = selectedDomains.filter((e) => e.manuallyAdded).map((e) => e.domain)
		if (manuallyAddedDomains.length > 0) {
			await Promise.all(manuallyAddedDomains.map(async (domain) => {
				const { error } = await DomainSetting.add(domain)
				if (error) {
					throw error
				}
			}))

			// After successfully adding the domains, update the manuallyAdded flag to false
			const updatedSelectedDomains = selectedDomains.map(domain => {
				if (manuallyAddedDomains.includes(domain.domain)) {
					return { ...domain, manuallyAdded: false }
				}
				return domain
			})

			setSelectedDomains(updatedSelectedDomains)
		}
	}

	async function batchUnblockAll() {
		const domains = selectedDomains.map((e) => e.domain)
		if (domains.length <= 0) return

		const { error } = await DomainSetting.unblockAll(domains)
		if (error) {
			setError(error)
			return
		}

		setSelectedDomains([])
		loadDomainSettings({ bulkDomains: domainSettings })
	}

	function handleDomainUpdate(index, isMobile) {
		if (isMobile) {
			loadDomainSettings()
		} else {
			const resetDomainSetting = {
				...domainSettings[index],
				index,
				id: "null",
				emailOption: 0,
				emailUpdatedAt: null,
				popupOption: 0,
				popupUpdatedAt: null,
				passwordOption: null,
				updatedAt: null,
				allowConsent: null,
				manuallyAdded: true
			}


			loadDomainSettings({ resetDomainSetting })
		}


	}

	function handleBulkSelectDomain(select) {
		if (select) setSelectedDomains(domainSettings.map(e => ({ domain: e.domain, email: true, popup: true, password: true, allowConsent: true, allowGPC: true, manuallyAdded: e.manuallyAdded })))
		else setSelectedDomains([])
	}

	function handleSelect(domain, email, popup, password, allowConsent, allowGPC, manuallyAdded) {
		let results = selectedDomains
		if (!email && !popup && !password && !allowConsent && !allowGPC) {
			results = results.filter((e) => e.domain !== domain)
		} else if (!selectedDomains.some((e) => e.domain === domain)) {
			results = [...selectedDomains, { domain }]
		}

		results = results.map((e) => {
			if (e.domain === domain) {
				e.email = email
				e.popup = popup
				e.password = password
				e.allowGPC = allowGPC
				e.allowConsent = allowConsent
				e.manuallyAdded = manuallyAdded
			}

			return e
		})
		setSelectedDomains(results)
	}

	async function handleBatchEmails(emailOption, force = false) {
		setBatchEmailOption(emailOption)

		if (!force) {
			const availability = await checkAvailability(emailOption)
			if (!availability) return
		}
		batchUpdateEmails(emailOption)
	}

	async function handleBatchPopups(popupOption) {
		try {
			const domains = selectedDomains.filter((e) => e.popup).map((e) => e.domain)
			if (domains.length <= 0) return
			await updateManuallyAddedDomain()
			const { error } = await DomainSetting.update(domains, { popupOption })
			if (error) {
				setError(error)
				return
			}
			else {
				Segment.track(Segment.Event.editingDomainBlockForPopup, currentUser?.id, { domains, popupOption })
			}

			loadDomainSettings()
		} catch (error) {
			setError(error)
			return
		}

	}

	function handleBatchUnblockAll() {
		setAlert({
			message: "Please confirm you wish to remove all block settings for the selected domain(s).",
			buttons: [
				{ type: 'positive', label: 'Confirm', onClick: () => batchUnblockAll() },
				{ type: 'negative', title: 'Cancel', }
			]
		})
	}

	const [isCrxInstalled, setCrxInstalled] = React.useState(true)
	React.useEffect(() => {
		setCrxInstalled(currentUser && currentUser.isExtensionInstalled)
	}, [currentUser])

	return (
		<>
			<Helmet>
				<title>lockrMail Domain Settings</title>
			</Helmet>
			<div className={`account-domain-settings ${className}`}>
				{/* <InstallCrxEl /> */}
				{(width >= 992) && <InstallCrxEl />}
				<div className="list-header">
					<div className={`bulk-selector ${!ArrayHelper.isValid(selectedDomains) ? 'inactive' : 'active'}`}>
						<div className="checkbox-container">
							<Checkbox
								iconUnchecked={iconUnchecked}
								iconChecked={iconChecked}
								checked={ArrayHelper.isValid(selectedDomains)}
								onChange={value => handleBulkSelectDomain(value)} />

							<div className="arrow-container">
								<img src={iconArrowDown} alt="" />
								<SelectDropDown>
									<SelectOption selectable={false} onClick={() => handleBulkSelectDomain(true)}>Select All</SelectOption>
									<SelectOption selectable={false} onClick={() => handleBulkSelectDomain(false)}>Deselect All</SelectOption>
								</SelectDropDown>
							</div>
						</div>
						{ArrayHelper.isValid(domainSettings) &&
							<>
								<Selector
									className="desktop-email-selector"
									defaultLabel="All Emails"
									labelPersist
									responsive={{ breakpoint: 992 }}
									options={[
										new SelectorOption({ label: 'On', value: 0 }),
										new SelectorOption({ label: 'Off', value: -1 }),
									]}
									onChange={(value) => handleBatchEmails(value)} />
								<Selector
									disabled={!isCrxInstalled}
									className="desktop-popup-selector"
									defaultLabel="Browser Popups"
									labelPersist
									responsive={{ breakpoint: 992 }}
									options={[
										new SelectorOption({ label: 'Default' + (currentUser?.allowDomainNotification ? ' (On)' : ' (Off)'), value: 0 }),
										new SelectorOption({ label: 'On', value: -2 }),
										new SelectorOption({ label: 'Off', value: -1 }),
									]}
									onChange={(value) => handleBatchPopups(value)} />
								<button className="bulk-unblock" onClick={() => handleBatchUnblockAll(true)}>Reset</button>
							</>
						}
						<Selector
							className="mobile-batch-selector"
							defaultLabel="Select"
							labelPersist
							responsive={{ breakpoint: 992 }}
							options={[
								new SelectorOption({
									labelComponent: <div className='select-heading'>All Emails</div>,
									subOptions: [
										new SelectorOption({ label: 'On', onClick: () => handleBatchEmails(0) }),
										new SelectorOption({ label: 'Off', onClick: () => handleBatchEmails(-1) })
									]
								}),
								new SelectorOption({
									disabled: !isCrxInstalled,
									labelComponent: <div className='select-heading'>Browser Pop-ups</div>,
									subOptions: [
										new SelectorOption({ label: 'Default' + (currentUser?.allowDomainNotification ? ' (On)' : ' (Off)'), onClick: () => handleBatchPopups(0), disabled: !isCrxInstalled, }),
										new SelectorOption({ label: 'On', onClick: () => handleBatchPopups(-2), disabled: !isCrxInstalled, }),
										new SelectorOption({ label: 'Off', onClick: () => handleBatchPopups(-1), disabled: !isCrxInstalled, }),
									]
								}),

								new SelectorOption({
									labelComponent: (<div className='select-footer'>
										<div className='select-button'>
											<button onClick={() => handleBatchUnblockAll()}>Reset</button>
										</div>
									</div>),


								}),
							]}
							onChange={(value) => handleBatchPopups(value)}
						/>
					</div>
					<SearchField
						value={searchText}
						onChange={value => setSearchText(value)}
					/>
				</div>
				<div className="add-domain-desktop">
					<DomainInput
						onAdd={(domain) => loadDomainSettings({ addDomain: true, domain })}
					/>
				</div>
				<div className="domain-list">
					{isLoading ? <Loader /> :
						domainSettings.map((domainSetting, index) => {
							const selectedEmail = selectedDomains.some(e => e.domain === domainSetting.domain && e.email)
							const selectedPopup = selectedDomains.some(e => e.domain === domainSetting.domain && e.popup)
							const selectedPassword = selectedDomains.some(e => e.domain === domainSetting.domain && e.password)
							const selectedConsent = selectedDomains.some(e => e.domain === domainSetting.domain && e.allowConsent)
							const selectedGPC = selectedDomains.some(e => e.domain === domainSetting.domain && e.allowGPC)
							const isExpanded = Boolean(((domainSetting.isNewCreated || isNewCreated) && index === 0) || (domainSetting.domain === selectedDomain))

							return (
								<DomainListItem
									key={index}
									domainSetting={domainSetting}
									selected={[selectedEmail, selectedPopup, selectedPassword, selectedConsent, selectedGPC]}
									isExpanded={isExpanded}
									onSelect={(email, popup, password, consent, gpc) => handleSelect(domainSetting.domain, email, popup, password, consent, gpc, domainSetting.manuallyAdded)}
									onChange={(value, isMobile = false) => handleDomainUpdate(index, isMobile)}
									handleSetting={updateDomainSetting} />
							)
						})
					}
				</div>
				<Pagination
					totalSize={totalCount}
					pageIndex={pageIndex}
					pageSize={pageSize}
					onPageIndex={(value) => setPageIndex(value)}
					onPageSize={(value) => setPageSize(value)}
				/>
				{(width < 992) && <div className='bottom-ext-banner'>
					<InstallCrxEl />
				</div>}
				<div className="add-domain-mobile">
					<button onClick={() => setAddNew(true)}>Add a domain</button>
				</div>
				{isAddNew &&
					<AddDomainModal
						onClose={(updated) => {
							setAddNew(false)
							if (updated) {
								loadDomainSettings()
							}
						}}
					/>
				}
			</div>
		</>
	)
}