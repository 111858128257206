import { DomainGroups } from "core/models/admin/domaingroups";
import { useAlert } from "../alert";
import React from "react";

const DomainGroupListContext = React.createContext({});

export function DomainGroupListProvider({
    children
}) {
    const { setError } = useAlert();
    const [isLoading, setIsLoading] = React.useState(false);
    const [domainGroupList, setDomainGroupList] = React.useState([]);

    const getDomainGroupList = async () => {
        setIsLoading(true);
        const data = await DomainGroups.loadDomainList();
        setIsLoading(false);
        setDomainGroupList(data);
    }

    const addNewDomainGroup = async (groupName) => {
        setIsLoading(true);
        const success = await DomainGroups.addNewDomainGroup(groupName);
        if (success) {
            getDomainGroupList();
            setIsLoading(false);
        }
        setIsLoading(false);

    }

    const memoedValue = React.useMemo(() => ({
        isLoading,
        domainGroupList,

        getDomainGroupList,
        addNewDomainGroup,
    }), [isLoading, domainGroupList])

    return <DomainGroupListContext.Provider value={memoedValue}>
        {children}
    </DomainGroupListContext.Provider>
}

/**
 * @typedef {{isLoading: boolean, domainGroupList: Array, getDomainGroupList: Function, addNewDomainGroup: Function}} UseDomainGroupListContext
 * @returns {UseDomainGroupListContext}
 */
export function useDomainGroupListContext() {
    return React.useContext(DomainGroupListContext)
}