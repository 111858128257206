import React from 'react'

import './styles.scss'
import iconDefaultUnchecked from '../../assets/images/checkbox0.svg'
import iconDefaultChecked from '../../assets/images/checkbox1.svg'

import { isValidText } from '../../utils'
import { keyCodes } from '../../constants'


export function Checkbox({
	className = '',
	id = "checkbox",
	label,
	labelClassName = '',
	iconChecked = iconDefaultChecked,
	iconUnchecked = iconDefaultUnchecked,
	enabled = true,
	checked = false,
	onChange = (value) => { }
}) {
	function handleClick(event) {
		event.preventDefault()
		event.stopPropagation()

		if (enabled) onChange(!checked)
	}

	function handleKeyDown(event) {
		if (event.keyCode === keyCodes.Space) handleClick(event)
	}

	React.useEffect(() => {
		// Prevent selecting checkbox when it's not enabled
		if (checked && !enabled) {
			onChange(false);
		}
	}, [checked, enabled, onChange]);

	return (
		<div className={`checkbox ${className} ${!enabled && 'disabled'}`} data-testid={id} onClick={handleClick} onKeyDown={handleKeyDown}>
			<img src={iconChecked} className={`${!checked && 'd-none'}`} alt="Checked" data-testid="checked" />
			<img src={iconUnchecked} className={`${checked && 'd-none'}`} alt="Unchecked" data-testid="unchecked" />
			{isValidText(label) &&
				<div className={`checkbox-label ml-16 ${labelClassName}`}>{label}</div>
			}
		</div>
	)
}
