import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import './styles.scss'
import iconCaret from 'assets/images/caret-next-14px-26282A.svg'

import {
	Icon,
	Select, SelectOption,
} from 'components'

import {
	useViewDigestArchive,
} from 'core'
import { redirectTo, routes } from 'routes'

export function DigestArchivesMailList({ }) {
	const navigate = useNavigate()

	const { detailId, mails, curMailIndex, currentMail, canGoPrev, canGoNext, goPrev, goNext } = useViewDigestArchive()

	const [isExpanded, setExpanded] = React.useState(false)

	return (
		<div className="digest-archives-mail-list">
			<div className="actions">
				<button disabled={!canGoPrev()} onClick={() => goPrev()}>
					<img src={iconCaret} alt="" />
					<span>Previous email</span>
				</button>
				<button disabled={!canGoNext()} onClick={() => goNext()}>
					<span>Next email</span>
					<img src={iconCaret} alt="" />
				</button>
			</div>
			<div className={`mail-list ${isExpanded && 'expanded'}`}>
				{mails.map((mail, index) =>
					<Link
						key={index}
						className={`mail-list-item ${index === curMailIndex && 'selected'}`}
						to={redirectTo({ route: routes.ViewDigestArchives, searches: { detailId, mailId: mail.id } })}>
						<div className="avatar">
							<Icon src={mail.sender.avatar} placeholder={mail.sender.name} />
						</div>
						<div className="info-container">
							<div>{mail.sender.name}</div>
							<div>{mail.subject}</div>
						</div>
					</Link>
				)}
				{mails.length > 4 &&
					<button className="btn-expand" onClick={() => setExpanded(!isExpanded)}>
						{isExpanded ? "Show less" : "Show more"}
					</button>
				}
			</div>
			<Select
				className="mail-select"
				keepLabel
				label={
					<div className="mail-select-label">
						<div className="avatar">
							<Icon src={currentMail?.sender.avatar} placeholder={currentMail?.sender.name} />
						</div>
						<div className="info-container">
							<div>{currentMail?.sender.name}</div>
							<div>{currentMail?.subject}</div>
						</div>
					</div>
				}>
				{mails.map((mail, index) =>
					<SelectOption
						key={index}
						selectable={false}
						onClick={() => navigate(redirectTo({ route: routes.ViewDigestArchives, searches: { detailId, mailId: mail.id } }))}>
						<div className="avatar">
							<Icon src={mail.sender.avatar} placeholder={mail.sender.name} />
						</div>
						<div className="info-container">
							<div>{mail.sender.name}</div>
							<div>{mail.subject}</div>
						</div>
					</SelectOption>
				)}
			</Select>
		</div>
	)
}