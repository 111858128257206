import React from 'react'

import './styles.scss'
import iconUnchecked from 'assets/images/checkbox-8B8B8B.svg'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'


import {
	Checkbox,
	SelectDropDown, SelectOption
} from '../../'

export function TableBatchContainer({
	selected = false,
	onSelectAll = (value) => { },
	children
}) {
	return (
		<div className={`table-batch-container ${selected && 'selected'}`}>
			<div className='checkbox-container'>
				<Checkbox
					iconUnchecked={iconUnchecked}
					iconChecked={iconChecked}
					checked={selected}
					onChange={(value) => onSelectAll(value)} />
				<div className="arrow-container">
					<img src={iconArrowDown} alt="" />
					<SelectDropDown>
						<SelectOption selectable={false} onClick={() => onSelectAll(true)}>Select All</SelectOption>
						<SelectOption selectable={false} onClick={() => onSelectAll(false)}>Deselect All</SelectOption>
					</SelectDropDown>
				</div>
			</div>
			<div className="batch-container">
				{children}
			</div>
		</div>
	)
}