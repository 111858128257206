import React from 'react'

import './styles.scss'
import iconFeatured from 'assets/images/account-domain-settings-crx-featured.svg'
import iconClose from 'assets/images/cross/light-gray-10.svg'
import extensionPreview from 'assets/images/domain-extension-preview.png'

import { useAuth } from 'core'
import { ExtensionHelper, Storage } from 'utils'
import { config } from 'constants/config'

export function InstallCrxEl({ }) {
	const { currentUser } = useAuth()
	const [isExtensionInstalled, setIsExtensioninstalled] = React.useState(true);

	const [isClosed, setClosed] = React.useState(Storage.domainSettingsCrxClosed)
	React.useEffect(() => {
		checkCrxInstalled();
	}, [currentUser])
	const checkCrxInstalled = async () => {
		setIsExtensioninstalled(currentUser && currentUser.isExtensionInstalled)
	}
	React.useEffect(() => {
		Storage.setDomainSettingsCrxClosed(isClosed)
	}, [isClosed])

	return (
		<div className={`install-crx ${(isExtensionInstalled) && 'd-none'}`}>
			<div className='preview-info'>
				<div className="preview-heading">
					lockrMail Chrome Extension
				</div>
				<div className="description">
					<span>
						If you have not installed the lockrMail Chrome extension, you can <a style={{ fontWeight: 'bold' }} href={config.CRX_URL} rel="noopenner" target="_blank">download it here.</a>
					</span>
					<span>
						Once installed, you will be able to control domain settings for the extension below.
					</span>
				</div>
			</div>
			<div className='preview-image'>
				<img src={extensionPreview}></img>
			</div>
			{/* <button className="close" onClick={() => setClosed(true)}>
				<img src={iconClose} alt="" />
			</button> */}
		</div>
	)
}