import React from 'react'
import { shippingColours, shippingStatus, shippingStatusFontColours } from 'utils/shipping-helper'

export function ShippingStatus({ status }) {
    if (status) {
        return <div style={{ backgroundColor: shippingColours[status], fontSize: 12, fontWeight: 600, borderRadius: 100, textTransform: 'uppercase', width: 'max-content', padding: '6px 8px', color: shippingStatusFontColours[status] }}>
            {shippingStatus[status]}
        </div>
    }
    return <></>
}