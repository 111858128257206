
import './styles.scss'
import emailIcon from '../../../../assets/images/email-icon.svg'
import timeIcon from '../../../../assets/images/last-updated-icon.svg'
import { nFormatter } from 'utils'
import moment from 'moment-timezone'


export function DashboardStatsHeader({
    stats = {},
    email = ""
}) {
    return (
        <div className='lockrScan-dashboard-header'>
            <div className='lockrScan-dashboard-header-wrapper'>
                <div className='lockrScan-dashboard-header-wrapper-info'>
                    <div className='info-wrapper'>
                        <div className='linked-account-text'>Linked account:</div>
                        <div className='email'><img src={emailIcon} className='email-icon' />{email}</div>
                        <div className='last-updated-time'><img src={timeIcon} className='time-icon' />Last updated {stats?.lastUpdated ? (moment(stats?.lastUpdated).format('hh:mma')) : ""}</div>
                    </div>
                </div>
                <div className='lockrScan-dashboard-header-wrapper-count-boxes'>
                    <div className='count-boxes-wrapper'>
                        <div className='count-box'>
                            <div className='count'>{stats?.unsubscribedSenderCount ?? 0}</div>
                            <div className='title'>Senders unsubscribed</div>
                        </div>
                        <div className='count-box'>
                            <div className='count'>{nFormatter(stats?.totalEmailProcessed) ?? 0}</div>
                            <div className='title'>Total emails processed</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}