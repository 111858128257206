import React from 'react';
import { useAuth, User, useAlert } from 'core';
import { BlueButton, ErrorGoogleSignInAlert, LockrScanPreview } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { useSearchParams } from 'react-router-dom'
import { parseSearchParams } from 'routes'
import { Helmet } from 'react-helmet';

const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen - popupWidth) / 2;
const popupTop = (screen - popupHeight) / 2;

export function LockrScanLoginScene() {
    const { lockrScanLogin } = useAuth();
    const [error, setError] = React.useState("");
    const [errStatus, setErrorStatus] = React.useState();
    const [googleOauthLink, setGoogleOauthLink] = React.useState("");
    const [ipAddr, setIpAddr] = React.useState("");
    const [permalink, setPermalink] = React.useState("");
    const { currentLockrScanUser } = useAuth()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    React.useEffect(() => {
        window.addEventListener("message", handleLockrScanLogin)
        return (() => {
            window.removeEventListener("message", handleLockrScanLogin);
        })
    }, [])
    React.useEffect(() => {
        const { openPreview } = parseSearchParams(searchParams);
        console.log(openPreview)
        getGoogleOauthLink();
        console.log(currentLockrScanUser, openPreview)
        if (currentLockrScanUser && openPreview == 'true') {
            navigate('/lockrscan/dashboard', { state: { openPreview: true } })
        }
        else if (currentLockrScanUser) {
            navigate(routes.LockrScanDashboard)
        }
    }, [currentLockrScanUser])
    const getGoogleOauthLink = async () => {
        const { wordpressLink } = parseSearchParams(searchParams);
        const { error, data } = await User.getLockrScanAuth(wordpressLink);
        setGoogleOauthLink(data?.googleOAuthUrl);
        setIpAddr(data?.ipAddr);
        setPermalink(data?.permalink);
    }
    const handleLockrScanLogin = (event) => {
        const data = event.data;
        if (data?.error) {
            if (typeof data?.error === 'object') {
                setErrorStatus(data.error?.errStatus)
                setError(JSON.stringify(data.error))
            } else {
                setError(data.error)
            }
        }
        if (typeof data == "undefined" || data === "") {
            return;
        }
        if (!currentLockrScanUser) {
            if (data) {
                (async () => { await lockrScanLogin(data) })()
                if (data?.isNewLockrScanUser) {
                    Storage.lockrScanSuccess = true;

                }
            }
        }
        // navigate(redirectTo({ route: routes.LockrScanDashboard }))
    }

    return <>
        <Helmet>
            <title>Create a lockrScan Account</title>
        </Helmet>
        <LockrScanPreview lockrScanAccess={true} />
        {errStatus && <ErrorGoogleSignInAlert errStatus={errStatus} onCancel={() => {
            // setLockrScanPreviewOpen(false);
            navigate({ pathname: '/lockrscan/login' })
        }} onButtonClick={async () => {
            // const { error, data } = await User.getLockrScanAuth();
            // if (error) {
            //     setError(error)
            //     return;
            // }
            if (Storage.accessToken) {
                window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                    'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                );
            }
            else {
                window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                    'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
            }
            setErrorAlert(false);
        }}></ErrorGoogleSignInAlert>}
    </>;
}