import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import './styles.scss'

import { Loader } from 'components'
import { SenderGroupByDate } from './group-by-date'
import { SenderListItem } from '../sender-list-item'

import { Sender } from 'core'
import {
	randomUUID,
	ArrayHelper, DateHelper
} from 'utils'
import { parseSearchParams } from 'routes'

export function DashboardSenderList({
	id = randomUUID(),
	className = "",
	isLoading = false,
	isActiveSearch = false,
	totalCount = 0,
	senders = [],
	selectedSenders = [],
	selectedSenderId,
	viewOptions,
	onSelectSender = (sender) => { },
	onError = (error) => { },
	defaultSelectedDigestFilter = undefined,
	groupCount
}) {
	const [searchParams] = useSearchParams()

	const [renderSenderList, setRenderSenderList] = useState(senders);

	useEffect(() => {
		const { highlight, changeDestination } = parseSearchParams(searchParams)
		const excludeIds = [highlight, changeDestination].filter(x => x)

		const results = []
		let totalCount = senders.length
		// console.log(totalCount)

		if (highlight || changeDestination) {
			const sender = senders.find(x => x.id === highlight || x.id === changeDestination)
			if (sender) results.push({ startDate: sender.recentAt, senders: [sender] })
		}

		const userTimeZone = DateHelper.getUserTimeZone()
		// today
		let date = new Date()
		let filteredSenders = Sender.filter(senders, date, null, excludeIds)
		totalCount -= filteredSenders.length
		results.push({ startDate: date, senders: filteredSenders })

		// yesterday
		date = DateHelper.addDay(new Date(), -1)
		filteredSenders = Sender.filter(senders, date, null, excludeIds)
		totalCount -= filteredSenders.length
		results.push({ startDate: date, senders: filteredSenders })

		// the day after yesterday
		date = DateHelper.addDay(new Date(), -2)
		filteredSenders = Sender.filter(senders, date, null, excludeIds)
		totalCount -= filteredSenders.length
		results.push({ startDate: date, senders: filteredSenders })

		// last 7 days
		let startDate = DateHelper.startOfDay(DateHelper.addDay(new Date(), -6), userTimeZone), endDate = DateHelper.endOfDay(DateHelper.addDay(new Date(), -3), userTimeZone)
		filteredSenders = Sender.filter(senders, startDate, endDate, excludeIds)
		totalCount -= filteredSenders.length
		results.push({ startDate, endDate, senders: filteredSenders })

		// Earlier this month
		if (DateHelper.isSameMonth(startDate, endDate, userTimeZone) && startDate <= DateHelper.startOfMonth(startDate, userTimeZone)) {
			startDate = DateHelper.startOfMonth(endDate, userTimeZone), endDate = DateHelper.endOfDay(DateHelper.addDay(endDate, -1), userTimeZone)
			filteredSenders = Sender.filter(senders, startDate, endDate, excludeIds)
			totalCount -= filteredSenders.length
			results.push({ startDate, endDate, senders: filteredSenders })
		}

		// next month
		while (totalCount > 0) {
			endDate = DateHelper.endOfDay(DateHelper.addDay(startDate, -1), userTimeZone)
			startDate = DateHelper.startOfMonth(endDate, userTimeZone)

			if (startDate <= DateHelper.parse("2020-01-01", null, userTimeZone)) break

			filteredSenders = Sender.filter(senders, startDate, endDate, excludeIds)

			results.push({ startDate, endDate, senders: filteredSenders })

			totalCount -= filteredSenders.length
		}
		setRenderSenderList(results)
	}, [senders])

	function calcFilterApplied() {
		const { searchText, senderStatus, sortBy = 'recentAt', senderGroupId, selectedGroups = [], selectedDigests = [] } = viewOptions
		return (searchText || senderStatus !== 'all' || senderGroupId || selectedGroups.length || selectedDigests.length)
	}

	function renderSendersMap() {
		if (viewOptions.sortBy !== 'recentAt') return renderSenders(senders)



		// console.log(totalCount)

		// render
		const noneEmptyResults = renderSenderList.filter((e) => ArrayHelper.isValid(e.senders))
		if (!ArrayHelper.isValid(noneEmptyResults)) {
			return (
				<div className="no-sender">
					{calcFilterApplied() ?
						"There are no senders that match this search criteria."
						:
						"There are currently no senders directed to this inbox."
					}
				</div>
			)
		}

		return renderSenderList.map(({ startDate, endDate, senders }, index) =>
			<SenderGroupByDate
				key={index}
				startDate={startDate}
				endDate={endDate}
				senders={senders}
				viewOptions={viewOptions}
				selectedSenders={selectedSenders}
				selectedSenderId={selectedSenderId}
				defaultSelectedDigestFilter={defaultSelectedDigestFilter}
				onSelectSender={(sender) => onSelectSender(sender)}
				onError={(error) => onError(error)}
				groupCount={groupCount} />
		)
	}

	function renderSenders(senders) {
		return (
			<>
				{ArrayHelper.isValid(senders) ?
					senders.map((sender) => {
						return (
							<SenderListItem
								key={sender.id}
								defaultSelectedDigestFilter={defaultSelectedDigestFilter}
								sender={sender}
								selected={selectedSenders.some(x => x.id === sender.id)}
								// onChange={(value) => setSenders(senders.map(x => x.id === value.id ? value : x))}
								onSelect={() => onSelectSender(sender)}
							/>
						)
					})
					:
					<div className="no-sender">
						{calcFilterApplied() ?
							"There are no senders that match this search criteria."
							:
							"There are currently no senders directed to this inbox."
						}
					</div>
				}
			</>
		)
	}

	return (
		<div id={id} className={`dashboard-sender-list ${className}`}>
			{isLoading && <Loader />}
			{!isLoading &&
				<>
					{isActiveSearch ?
						<div className="sender-list-group">
							{totalCount > 0 && <div className="sender-list-header">{`${totalCount} Results`}</div>}
							<div className="sender-list">{renderSenders(senders)}</div>
						</div>
						:
						<>{renderSendersMap()}</>
					}
				</>
			}
		</div>
	)
}