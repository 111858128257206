import { plainToClass } from 'class-transformer'

import { Api, LockrBox } from 'core'
import { DateHelper, zeroPad } from 'utils'

export class AdminUserState {
	countVerifiedEmails
	countSenders
	countFilters
	countBlockedMails
	countRedirectedEmails
	countOutgoingEmails
	totalTimeSaved
	refSent
	refAvailable
	refAccepted
	refEarned
	referrer
	isExtensionInstalled
	permalink
	tags
	isBlockNewSender
	allowLockrBox
	lockrBox
	hiddenFeatures = []
	allow2FA
	allowGPC

	static fromJSON(json) {
		const result = plainToClass(AdminUserState, json)
		if (result) {
			result.lockrBox = LockrBox.fromJSON(json.lockrBox)
			result.tags = json?.tags ?? []
			result.hiddenFeatures = json?.hiddenFeatures ?? []
		}
		return result
	}

	static fromJSONArray(jsonArray) {
		return jsonArray.map((json) => AdminUserState.fromJSON(json))
	}

	static async get(userId, startDate, endDate) {
		const defaultError = 'Sorry, there are some troubles to get user state.'
		let params = { userId }
		if (startDate) params = { ...params, startDate: DateHelper.format(startDate, 'YYYY-MM-DD') }
		if (endDate) params = { ...params, endDate: DateHelper.format(endDate, 'YYYY-MM-DD') }

		try {
			const { data, error } = await Api.get('/admin/user-stats', params)
			return {
				error: error ? defaultError : null,
				result: AdminUserState.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error) }
		}
	}

	get formattedTotalTimeSaved() {
		const hour = parseInt(this.totalTimeSaved / 3600)
		const minutes = parseInt((this.totalTimeSaved - hour * 3600) / 60)
		const seconds = parseInt(this.totalTimeSaved % 60)

		return `${zeroPad(hour, 10)}:${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`
	}

	get wordpressLink() {
		if (this.permalink?.includes('wordpress|')) return this.permalink?.replace('wordpress|', '')
		return null
	}
	get isWordpressLink() { return Boolean(this.wordpressLink) }

	get specialLink() { return this.wordpressLink ? null : this.permalink }
	get formattedPermaLink() {
		if (!this.permalink) return null
		return this.permalink.replace('wordpress|', '')
	}
}