import React from 'react'

import './styles.scss'

import { Loader, InfiniteScroll } from 'components'
import { MailModalHeader } from './header'
import { MailListItem } from './mail-list-item'

import { useAlert, Sender, Mail } from 'core'
import { ObjectHelper, DOMHelper, ArrayHelper } from 'utils'

export class MailModal extends React.Component {
	/**
	 * @typedef {{className: string, sender: Sender, onClose: *}} Props
	 * @param {Props} props
	 * @returns
	 */
	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,

			searchText: '',
			searchOption: 'all_content',
			senderStatus: null,

			mails: [],
			selectedMailIds: [],

			pageIndex: 1,
			hasMore: false,

			error: null
		}
	}

	componentDidMount() {
		this.loadMails()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.searchText !== prevState?.searchText
			|| this.state.searchOption !== prevState?.searchOption
			|| this.state.senderStatus !== prevState?.senderStatus) {
			this.setState({ pageIndex: 1, mails: [] }, this.loadMails)
			return
		}

		if (this.state.pageIndex !== prevState?.pageIndex) {
			this.loadMails()
			return
		}
	}

	loadMails = async () => {
		const { sender } = this.props
		const { searchText, searchOption, senderStatus, pageIndex, mails } = this.state

		let params = {
			searchText,
			searchCriteria: searchOption,
			pageNum: pageIndex
		}
		if (ObjectHelper.isValid(senderStatus)) params = { ...params, blocked: senderStatus }

		this.setState({ isLoading: true })
		const { results, error } = await Mail.load(sender.id, params)
		this.setState({ isLoading: false })

		if (error) {
			this.setState({ error })
			return
		}


		this.setState({
			mails: [...mails, ...results],
			hasMore: ArrayHelper.isValid(results)
		})
	}

	/**
	 * Reload mails currently loaded
	 * @param {Array} mailIds
	 */
	reloadMails = (mailIds = []) => {
		mailIds.forEach((mailId) => {
			Mail.get(mailId)
				.then(({ result }) => {
					if (!result) return
					const { mails } = this.state
					this.setState({ mails: mails.map(mail => mail.id === result.id ? result : mail) })
				})
		})
	}

	handleContainerClick = (event) => {
		const { onClose = () => { } } = this.props
		if (!DOMHelper.closest(event.target, '.mail-modal>.wrapper')) {
			onClose()
		}
	}

	handleSelectMail = (mail) => {
		if (!mail?.id) return

		const { selectedMailIds = [] } = this.state

		if (selectedMailIds.some(e => e === mail.id)) {
			this.setState({ selectedMailIds: selectedMailIds.filter((e) => e !== mail.id) })
		} else {
			this.setState({ selectedMailIds: [...selectedMailIds, mail.id] })
		}
	}

	renderDescription = () => {
		const { sender } = this.props
		if (!sender) return ''

		const count = sender?.mailCount || 0
		if (count === 1) return `You have 1 email from ${sender.domainGroupName ? sender.domainGroupName : sender.name}`
		return `You have ${count} emails from ${sender.domainGroupName ? sender.domainGroupName : sender.name}`
	}

	render() {
		const { sender, onClose = () => { } } = this.props
		const { isLoading, searchText, searchOption, senderStatus, mails, selectedMailIds, pageIndex, hasMore, error } = this.state
		return (
			<>
				{!error &&
					<div className={`mail-modal`} onClick={this.handleContainerClick}>
						<div className="wrapper">
							<div className="modal-title">Emails</div>
							<div className="modal-description">{this.renderDescription()}</div>
							<MailModalHeader
								selectedMailIds={selectedMailIds}
								onSelectAll={(value) => {
									if (value) this.setState({ selectedMailIds: mails.filter(mail => mail.isAbleToDeliver).map(e => e.id) })
									else this.setState({ selectedMailIds: [] })
								}}
								searchText={searchText}
								onSearchText={(value) => this.setState({ searchText: value })}
								searchOption={searchOption}
								onSearchOption={(value) => this.setState({ searchOption: value })}
								senderStatus={senderStatus}
								onSenderStatus={(value) => this.setState({ senderStatus: value })}
								onChange={(mailIds) => this.reloadMails(mailIds)} />
							<InfiniteScroll
								className="mail-list"
								hasMore={hasMore}
								isLoading={isLoading}
								loadNext={() => this.setState({ pageIndex: pageIndex + 1 })}>
								{mails.map((mail, index) => {
									const selected = selectedMailIds.some(e => e === mail.id)
									return (
										<MailListItem
											key={index}
											mail={mail}
											selected={selected}
											onSelect={() => this.handleSelectMail(mail)} />
									)
								})}
							</InfiniteScroll>
							<button className="modal-close" onClick={() => onClose()}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.66699 1.6665L10.0003 9.99984L18.3337 1.6665" stroke="white" strokeLinecap="round" />
									<path d="M18.333 18.3335L9.99967 10.0002L1.66634 18.3335" stroke="white" strokeLinecap="round" />
								</svg>
							</button>
						</div>
					</div>
				}
			</>
		)
	}


}
