import React from 'react'

import './styles.scss'

import { isValidText } from "../../../utils"

export class AlertInput extends React.Component {
	handleChange = (event) => {
		event.preventDefault()

		const { onChange } = this.props
		if (onChange) {
			onChange(event.target.value.trim())
		}
	}

	render() {
		const { placeholder, id, value, error } = this.props
		return (
			<div className="alert-input">
				<div className="alert-input-wrapper">
					<input id={id} data-testid={id} placeholder={placeholder} type="text" value={value || ""} onChange={this.handleChange} />
				</div>
				{isValidText(error, true) && <label htmlFor={id}>{error}</label>}
			</div>
		)
	}
}