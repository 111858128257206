export const errorMessages = Object.freeze({
	NETWORK_ERROR: 'We are having trouble reaching the server right now... We’re working on it.... Please refresh your page!',
	CONTACT_US: 'Sorry, there seems to be a problem. Please refresh and try again. If the problem persists please contact us.',
	CONNECTION_ERROR: 'Please check your internet connection and try again.',

	// SIGNUP
	ERROR_SIGNUP: 'Sorry, there was an error communicating with the server. Please reload the page and try again.',
	ERROR_SUGGESTED_EMAILS: 'Sorry, there is some trouble to get suggested emails.',
	ERROR_CHECK_LOCKRMAIL_AVAILABILITY: 'Sorry, there is some trouble to check email availability.',
	ERROR_VERIFY_TOKEN_NOT_MATCH_EMAIL: "Verify token doesn't match with email",
	ERROR_EMAIL_NOT_SUPPORTED: 'Invalid email. Please add your existing personal or work email address.',
	ERROR_EMAIL_ALREADY_IN_USE: 'Personal email already associated with lockrMail account.',
	ERROR_REGISTER_REQUIRED_VERIFY_PRIMARY_EMAIL: 'Personal email has already registered. To complete your registration please verify your email by clicking the link in your inbox.',
	ERROR_MUST_BEGIN_WITH_LETTER: 'lockrMail email must begin with a letter (a-z).',
	SIGNUP_EMAIL_RESEND_SAME: 'The personal email you have entered is same as pervious one. Please use a different email.',
	VPN_NOT_ALLOWED: 'We are not allowed to sign up using VPN.',

	// Block domains as registration/destination emails
	NOT_SUPPORTED_EMAILS: 'We do not accept machine-generated emails. Please enter a valid email address.',
	BLOCKED_ACCOUNT: <>lockrMail has restricted access to this user. The analytics that we observed suggest there is something counter to lockrMail's intended usage.<br></br>
		<br></br>
		We welcome you to reach out with any question or concerns and would be happy to welcome you back to the platform should the explanation justify the activities witnessed.<br></br>
		<br></br>
		If you have any questions or believe you have received this message in error, please <a href="https://lockrmail.com/contact/" target="_blank">contact us</a>.</>,
	CONFIGURED_TO_SIGN_IN_WITH_GOOGLE: 'This lockrMail account is configured to sign-in with Google. To log-in, please use the button below.',

	// Login
	INCORRECT_USERNAME_OR_PASSWORD: 'The username or password you have entered does not match our records.',
	REQUIRED_VERIFY_PRIMARY_EMAIL: 'Please click the link in your email to complete account sign-up.',

	EMAIL_WRAPPER_UPDATE_SENDER_FAILED: 'There was an error processing your request. Please use the dashboard to change any Sender settings.',

	// Sender Group
	SENDER_GROUP_DUPLICATED_NAME: 'A Group with this name already exists. Please enter a unique name for this Group.',

	// Filter
	DELETE_FILTER_FOR_ALLOW_BLOCK: 'You must delete the filter for this Sender to use the Allow/Block functionality.',
	BATCH_DELETE_FILTER_AND_DS_FOR_ALLOW_BLOCK: 'To Allow or Block all Senders in this list, you must remove any active Filters or Domain Blocks.',

	// Domain Settings
	DOMAIN_SETTING_ALREADY_EXIST: 'The domain you have entered already exists. Please change the notification settings by searching for the domain.',
	DELETE_DOMAIN_SETTING_FOR_FILTER_ALLOW_BLOCK: 'You must delete the domain settings for this Sender to use the Allow/Block/Filter functionality.',
	DS_CONFIRM_OVERRIDE_ALLOW_BLOCK_FILTER: 'Blocking all emails from this domain will override Allow or Filter settings currently enabled for a Sender using this Domain. Click confirm to continue.',
	FILTER_OVERRIDE_MESSAGE: 'Selecting Allow or Block will override Filter settings currently enabled for this Sender. Click confirm to continue.',
	FILTER_OVERRIDE_MESSAGES_MULTIPLE: 'Selecting Allow or Block will override Filter settings currently enabled for selected Senders. Click confirm to continue.',
	EMPTY_FILTER: 'You do not currently have any filter settings selected. If you save, there will be no settings saved for this Sender.',

	// 2FA
	TFA_PHONE_ALREADY_REGISTERED: 'This phone number is associated with another lockrMail account. To enable 2FA, please use a different number that accepts SMS messages.',
	TFA_USER_NOT_FOUND: 'Can’t find the user',
	TFA_INCORRECT_CODE: 'The code provided is incorrect. Please try again or resend an updated code.',
	TFA_REQUIRED_VERIFICATION_CODE: 'Verification code Required.',
	TFA_INVALID_CODE: 'Invalid code.',
	TFA_MAX_ATTEMPS_REACHED: 'Max attemps reached.',

	// Login block
	LOGIN_ATTEMPT_REACHED: 'Your account is temporarily blocked after too many unsuccessful login attempts. Please try again after 30 minutes. Or, <a href="https://lockrmail.com/contact/" target="_blank" style="color:#F46666;font-weight: bold;">contact us.</a>',

	// Delivery Hours
	DELIVERY_HOUR_DATE_RANGE_REQUIRED_FUTURE: "Please specify a future date range. Limited Delivery Hours must be set up at least 1-day in advance.",

	FIRSTNAME_CANNOT_CONTAIN_URL: "First name cannot contain URL.",
	LASTNAME_CANNOT_CONTAIN_URL: 'Last name cannot conatin URL.'
})

export const errorCodes = Object.freeze({
	INPUT_VALIDATION_FAILED: 422,

	// 2FA
	TFA_PHONE_ALREADY_REGISTERED: 423,
	TFA_USER_NOT_FOUND: 424,
	TFA_INCORRECT_CODE: 425,
	TFA_REQUIRED_CODE: 426,
	TFA_MAX_ATTEMPS_REACHED: 429,

	BAD_REQUEST: 400,
	AUTH_FAILED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	ALREADY_EXIST: 409,
	UNKNOWN_ERROR: 500,
	/** Internal error codes */
	/* deleted user */
	DELETED_BEFORE: 5301,
	PERMALINK_INVALID: 5302,

	// Login block
	LOGIN_ATTEMPT_REACHED: 5415,

	/* waitlist */
	NOT_WHITELISTED: 4404,
	ALREADY_SIGNED_UP: 5001,
	ALREADY_ON_WAITLIST: 5002,
	ALREADY_WHITELISTED: 5003,

	/* referral */
	ALREADY_REFERRED: 5004,
	INSUFFICIENT_REF_CREDITS: 5005,

	/* Block domains as registration/destination emails */
	NOT_SUPPORTED_EMAILS: 5010,

	/* Login */
	REQUIRED_VERIFY_PRIMARY_EMAIL: 5006,

	/* Same IP address */
	SAME_IP_ADDRESS_NOT_ALLOWED: 5013,

	/* Register */
	REGISTER_EMAIL_ALREADY_IN_USE: 422,
	LOCKRMAIL_NOT_SUPPORTED: 5011,
	BLOCKED_ACCOUNT: 5014,
	CONFIGURED_TO_SIGN_IN_WITH_GOOGLE: 5007,
	
	VPN_NOT_ALLOWED: 5016,

	/* filter */
	SENDER_HAVE_FILTER: 5101,
	IGNORE_DOMAIN: 5102,
	EMPTY_FILTER_NOT_ALLOWED: 5103,

	/* Google Recaptcha Vaildation Failed */
	G_RECAPTCHA_FAILED: 5311,

	RECIPIENT_NOT_EXIST: 5404,
	SENDER_EXIST: 5409,

	/* postfix */
	USER_EXIST_IN_POSTFIX: 5501,

	// Domain Settings
	DS_OVERRIDE_ALLOW_FILTER: 5600,

	// lockrScan erros
	LOCKRSCAN_GMAIL_PERMISSIONS_NOT_PROVIDED: 7001,
	LOCKRSCAN_ACCOUNT_ALREADY_SYNCED: 7002,
})
