import React, { useEffect, useState } from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'

import {
    HMenuDatePicker,
    Checkbox,
    Loader,
    BlueButton,
    Select,
    SelectOption,
} from 'components'
import { Charts } from './charts'

import { useAlert, useAuth, AdminTraction } from 'core'
import { DateHelper, ArrayHelper, ObjectHelper, zeroPad } from 'utils'
import { LockrScanAdminTraction } from 'core/models/admin/lockrScan-traction'

import fetchImage from '../../../../assets/images/fetch.svg'
import moment from 'moment-timezone'

const tractionTypes = ['account', 'email', 'waitlist', 'referral']

const SelectionTypes = {
    SHIPMENT: 'shipment',
    COMPARE: 'comparePrevious',
};

export function LockrScanAdminTractionEl() {
    const { setError } = useAlert()
    const { currentUser } = useAuth()


    const [startDate, setStartDate] = useState(DateHelper.addDay(new Date(), -6))
    const [endDate, setEndDate] = useState(new Date())
    // useEffect(() => {
    //     // loadStatistics()
    //     loadTractions()
    // }, [currentUser, startDate, endDate])

    const titles = [
        'Total Accounts',
        'Total Senders Unsubscribed',
        'Total Senders in lockrArchive',
        'Email Processed'
    ]
    const rangeTypeValues = {
        "1": "yesterday",
        "7": "last_7_days",
        "29": "this_month",
        "30": "last_30_days",
        "31": "last_month",
        "90": "last_90_days",
        "120": "last_quarter"
    }

    const [isLoadingStatistics, setLoadingStatistics] = useState(false)
    const [statistics, setStatistics] = useState([
        { total: 0, changePercent: 0, upgradedLockrMailPercentage: 0, upgradedLockrMailAccountNumber: 0 },
        { total: 0, changePercent: 0, totalLifeTime: 0 },
        { total: 0, changePercent: 0, totalLifeTime: 0 },
        { total: 0, changePercent: 0, totalLifeTime: 0 },
    ])

    const [isLoadingTractions, setLoadingTractions] = useState(false)
    const [tractions, setTractions] = useState(new AdminTraction())
    const [lockrScantractions, setLockrScanTractions] = useState(new LockrScanAdminTraction)
    const [tractionIndex, setTractionIndex] = useState(0)
    // React.useEffect(() => { loadTractions() }, [tractionIndex])
    const [comparePrevious, setComparePrevious] = useState(false)
    const [dataNames, setDataNames] = useState([])	/// [[Account, Active, Blocked], [Account, Active, Blocked], [Account, Active, Blocked]]
    const [dataNamesSelection, setDataNamesSelection] = useState([])
    const [timePeriod, setTimePeriod] = useState(0);

    const [activeSelection, setActiveSelection] = useState('');

    const toggleSelection = (selectionType) => {
        // If the current selection is already active, deselect it, otherwise, update to the new selection
        setActiveSelection(currentSelection =>
            currentSelection === selectionType ? '' : selectionType
        );
    };

    useEffect(() => {
        if (tractions) {
            // setStatistics(tractions.statistics ?? statistics)
            setDataNames(chartDataNames())
            setDataNamesSelection(chartDataNames().map(e => true))
        }
    }, [tractions, tractionIndex])
    React.useEffect(() => {
        if (timePeriod == 0) {
            setStartDate(DateHelper.addDay(new Date(), -6));
            setEndDate(new Date());
        }
        if (timePeriod == 1) {
            setStartDate(moment().subtract(1, "days").startOf('day').toDate());
            setEndDate(moment().subtract(1, "days").endOf('day').toDate());
        }
        if (timePeriod == 7) {
            setStartDate(moment().subtract(7, "days").startOf('day').toDate());
            setEndDate(moment().subtract(1, "days").endOf('day').toDate());
        }
        if (timePeriod == 30) {
            setStartDate(moment().subtract(30, "days").startOf('day').toDate());
            setEndDate(moment().subtract(1, "days").endOf('day').toDate());
        }
        if (timePeriod == 31) {
            setStartDate(moment().subtract(1, 'months').startOf('month').startOf('day').toDate());
            setEndDate(moment().subtract(1, 'months').endOf('month').endOf('day').toDate());
        }
        if (timePeriod == 90) {
            setStartDate(moment().subtract(90, "days").startOf('day').toDate());
            setEndDate(moment().subtract(1, "days").endOf('day').toDate());
        }
        if (timePeriod == 120) {
            setStartDate(moment().subtract(3, 'months').startOf('quarter').startOf('day').toDate());
            setEndDate(moment().subtract(3, 'months').endOf('quarter').endOf('day').toDate());
        }
        if (timePeriod == 29) {
            setStartDate(moment().startOf('month').toDate());
            setEndDate(moment().toDate())
        }
    }, [timePeriod])

    // async function loadStatistics() {
    //     if (!currentUser?.isAdmin) return

    //     setLoadingStatistics(true)
    //     const { error, result } = await AdminTraction.loadTraction(startDate, endDate)
    //     setLoadingStatistics(false)

    //     if (error) {
    //         setError(error)
    //         return
    //     }

    //     // setStatistics([
    //     //     result.accountStats ?? { total: 0, changePercent: 0 },
    //     //     result.emailStats ?? { total: 0, changePercent: 0 },
    //     //     result.waitlistStats ?? { total: 0, changePercent: 0 },
    //     //     result.referralStats ?? { total: 0, changePercent: 0 }
    //     // ])
    // }

    async function loadTractions() {
        if (!currentUser?.isAdmin) return

        setLoadingTractions(true)
        if (timePeriod == 0) {
            // const { error, result } = await AdminTraction.loadTraction(startDate, endDate, tractionTypes[tractionIndex])
            const { error, result } = await LockrScanAdminTraction.loadTraction(startDate, endDate);
            setLoadingTractions(false)
            setStatistics([
                { total: result.totalAccounts, changePercent: result.accountsChange, upgradedLockrMailPercentage: result.upgradedLockrMailPercentage, upgradedLockrMailAccountNumber: result.upgradedLockrMailAccountNumber },
                { total: result.totalUnsubscribed, changePercent: result.unSubscribedChange, totalLifeTime: result.totalLifetimeUnsubscribed },
                { total: result.totalLockrArchived, changePercent: result.lockrArchivedChange, totalLifeTime: result.totalLifetimeLockrArchived },
                { total: result.totalEmailProcessed, changePercent: result.emailChange, totalLifeTime: result.lifeTimeTotalProcessed }
            ])
            if (error) {
                setError(error)
                return
            }
            // console.log(result)
            // setTractions(result)
            setLockrScanTractions(result)
        }
        else {
            const { error, result } = await LockrScanAdminTraction.loadTraction(startDate, endDate, rangeTypeValues[timePeriod]);
            setLoadingTractions(false)
            setStatistics([
                { total: result.totalAccounts, changePercent: result.accountsChange, upgradedLockrMailPercentage: result.upgradedLockrMailPercentage, upgradedLockrMailAccountNumber: result.upgradedLockrMailAccountNumber },
                { total: result.totalUnsubscribed, changePercent: result.unSubscribedChange, totalLifeTime: result.totalLifetimeUnsubscribed },
                { total: result.totalLockrArchived, changePercent: result.lockrArchivedChange, totalLifeTime: result.totalLifetimeLockrArchived },
                { total: result.totalEmailProcessed, changePercent: result.emailChange, totalLifeTime: result.lifeTimeTotalProcessed }
            ])
            if (error) {
                setError(error)
                return
            }
            setLockrScanTractions(result)
        }
        setLoadingTractions(false)
    }

    function updateTractions(values) {
        const newTractions = AdminTraction.fromJSON(ObjectHelper.merge(tractions, values))
        // console.log(newTractions.statistics)
        setStatistics(newTractions.statistics ?? statistics)
        setTractions(newTractions)
    }

    function statePath() {
        let result = 'accountTicks'
        if (tractionIndex === 1) result = 'unSubscribedTicks'
        if (tractionIndex === 2) result = 'lockrArchivedTicks'
        if (tractionIndex === 3) result = 'emailProcessedTick'
        return result
        // let result = 'accountTicks'
        // if (tractionIndex === 1) result = 'unSubscribedTicks'
        // if (tractionIndex === 2) result = 'unSubscribedTicks'
        // return result
    }

    function chartTicksPath() {
        return statePath()
    }

    function chartTicks() {
        let ticks = []
        // let result = 'accountTicks'

        if (tractionIndex === 0) ticks = lockrScantractions?.accountTicks ?? []
        if (tractionIndex === 1) ticks = lockrScantractions?.unSubscribedTicks ?? []
        if (tractionIndex === 2) ticks = lockrScantractions?.lockrArchivedTicks ?? []

        return ticks
    }

    function chartFromPath() { return 'current.from' }
    function chartBasePaths() {
        if (activeSelection === SelectionTypes.COMPARE) {
            return ['current', 'previous'];
        } else if (activeSelection === SelectionTypes.SHIPMENT) {
            return ['current', 'shipments'];
        } else {
            return ['current'];
        }
    }

    function chartStacks() {
        if (activeSelection === SelectionTypes.COMPARE) {
            return ['Current', 'Previous'];
        } else if (activeSelection === SelectionTypes.SHIPMENT) {
            return ['Current', 'Shipments'];
        } else {
            return ['Current'];
        }
    }
    function chartDataNames() {
        let results = [{ name: 'Accounts', colors: { bar1: "#2E70D2", bar2: "#BD94BC" } }]
        if (tractionIndex === 1) results = [{ name: 'Senders', colors: { bar1: "#2E70D2", bar2: "#BD94BC" } }]
        if (tractionIndex === 2) results = [{ name: 'Senders', colors: { bar1: "#2E70D2", bar2: "#BD94BC" } }]
        if (tractionIndex === 3) results = [{ name: 'Emails', colors: { bar1: "#2E70D2", bar2: "#BD94BC" } }, { name: 'New Registrations', colors: { bar1: '#61CEF7', bar2: '#E88D67' } }]

        // if (tractionIndex === 3) results = ['Referral Submitted']
        return results
    }

    function chartDataPaths(basePath = 'current') {
        let results = ['accounts']
        if (tractionIndex === 1) results = ['unSubscribed']
        if (tractionIndex === 2) results = ['lockrArchived']
        if (tractionIndex === 3) {
            results = ['emailProcessed', 'newAccounts']
            if (basePath === 'shipments') {
                results = ['shipment']
            }
        }


        return results.map(e => `${basePath}.${e}`)
    }

    function isTotalDataPath(basePath, dataPath) {
        const path = dataPath.replace(`${basePath}.`, '')
        return path === 'accounts' || path === 'emailProcessed'
    }

    function chartCategories() {
        if (!tractions) return []

        const ticks = ArrayHelper.value(lockrScantractions, chartTicksPath()) ?? []
        // const ticks = chartTicks();
        // return ticks.map(tick => DateHelper.format(tick.from, 'MM-DD'))
        return ticks.map(tick => DateHelper.format(ArrayHelper.value(tick, chartFromPath()), 'MM-DD'))
    }

    function chartData() {
        if (!tractions) return []

        const ticks = ArrayHelper.value(lockrScantractions, chartTicksPath()) ?? []
        // const ticks = chartTicks()
        const results = []
        const basePaths = chartBasePaths()
        let maxIndices = []
        let skipCount = 0
        basePaths.forEach((basePath, baseIndex) => {
            const dataPaths = chartDataPaths(basePath)
            const curDataPaths = selectedDataPaths(basePath)
            const indexLength = dataPaths.length * baseIndex
            maxIndices.push(findMaxIndicesByKeys(dataNamesSelection, ticks, dataPaths, indexLength))
            dataPaths.forEach((dataPath, dataIndex) => {
                if (basePath !== 'shipments') {
                    if (!dataNamesSelection[dataIndex]) {
                        skipCount = skipCount + 1
                        return
                    }
                }
                results.push(
                    ticks.map((tick, index) => {
                        const maxIndex = maxIndices[baseIndex][index] - indexLength
                        if (maxIndex + skipCount === dataIndex && curDataPaths.length > 1) {
                            const temp = ObjectHelper.value(tick, dataPath) - ArrayHelper.sumOfPaths(tick, curDataPaths.filter((_, filterIndex) => filterIndex !== maxIndex))
                            return temp
                        }
                        return ObjectHelper.value(tick, dataPath)
                    })
                )
            })
        })

        return { data: results, maxIndices }
    }

    function findMaxIndicesByKeys(boolArray, ticks, dataPaths, indexLength) {
        const result = ticks.map((tick) => {

            const filteredDataPaths = dataPaths.filter((_, index) => boolArray[index]);

            let maxDataPath = filteredDataPaths[0];
            for (let dataPath of filteredDataPaths) {
                if (ObjectHelper.value(tick, dataPath) > ObjectHelper.value(tick, maxDataPath)) {
                    maxDataPath = dataPath;
                }
            }
            // Return the index of the key with the maximum value in the original keys array
            const originalIndex = dataPaths.indexOf(maxDataPath);
            // Count the number of false values in boolArray before this index
            const falseCountBeforeIndex = boolArray.slice(0, originalIndex).filter(val => !val).length;
            // Adjust the index by subtracting the count of false values before it
            const finalIndex = originalIndex - falseCountBeforeIndex;
            return finalIndex + indexLength
        });

        return result;
    }


    function chartMax() {
        if (!tractions) return null
        const ticks = ArrayHelper.value(tractions, chartTicksPath()) ?? []
        // const ticks = chartTicks();
        const basePaths = chartBasePaths() ?? []
        const values = basePaths.map(basePath => {
            const dataPaths = chartDataPaths(basePath)
            const data = ticks.map(tick => ArrayHelper.sumOfPaths(tick, dataPaths))
            return ArrayHelper.max(data)
        })

        const result = ArrayHelper.max(values)

        return result
    }
    const onFetch = () => {
        loadTractions();
    }
    const selectedDataNames = () => {
        const indexes = []
        dataNamesSelection.forEach((e, index) => { if (e) indexes.push(index) })

        return indexes.map(i => dataNames[i])
    }

    const selectedDataPaths = (basePath) => {
        const dataPaths = chartDataPaths(basePath)
        const indexes = []
        if (basePath === 'shipments') {
            return [dataPaths[0]]
        }
        dataNamesSelection.forEach((e, index) => { if (e) indexes.push(index) })
        return indexes.map(i => dataPaths[i])
    }

    function renderTimeSaved(timeSaved) {
        const hour = parseInt(timeSaved / 3600)
        const minutes = parseInt((timeSaved - hour * 3600) / 60)
        const seconds = parseInt(timeSaved % 60)

        return `${zeroPad(hour, 10)}:${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`
    }

    return (
        <>
            <div className="admin-group admin-traction">
                <div className="admin-group-header">
                    <div className="admin-group-title">Traction</div>
                    <div className="admin-traction-option-container">
                        <div>
                            <div style={{ marginRight: 8 }}>
                                <Select

                                    id="periods"
                                    label="Time Period"
                                    mobileTitle="Time Period"
                                    value={timePeriod}
                                    onChange={(value) => { setTimePeriod(value) }}
                                >
                                    <SelectOption value={0}>Custom</SelectOption>
                                    <SelectOption value={1}>Daily</SelectOption>
                                    <SelectOption value={7}>Weekly</SelectOption>
                                    <SelectOption value={30}>Last 30 days</SelectOption>
                                    <SelectOption value={29}>This Month</SelectOption>
                                    <SelectOption value={31}>Last month</SelectOption>
                                    <SelectOption value={90}>Last 90 days</SelectOption>
                                    <SelectOption value={120}>Last Quarter</SelectOption>
                                </Select>
                            </div>
                            <div style={{ pointerEvents: timePeriod !== 0 && "none", display: "contents" }}>
                                <HMenuDatePicker
                                    position="first"
                                    date={startDate}
                                    onSelect={date => setStartDate(date)}
                                />
                                <div className="to">to</div>
                                <div className='end-date-picker'>
                                    <HMenuDatePicker
                                        position="first"
                                        date={endDate}
                                        onSelect={date => setEndDate(date)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Checkbox
                            label="Compare previous period"
                            labelClassName="checkbox-label"
                            iconChecked={iconChecked}
                            iconUnchecked={iconUnchecked}
                            checked={activeSelection === SelectionTypes.COMPARE}
                            onChange={() => toggleSelection(SelectionTypes.COMPARE)}
                        />
                        <BlueButton className='fetch-button' onClick={() => { onFetch(); }}>Fetch <img src={fetchImage}></img></BlueButton>
                    </div>
                </div>
                <div className="admin-group-content">
                    <div className="admin-traction-tab">
                        {tractions &&
                            titles.map((title, index) => (
                                <div
                                    key={index}
                                    className={`admin-traction-tab-item-lockrScan admin-traction-tab-item ${index === tractionIndex ? 'selected' : ''}`}
                                    onClick={() => setTractionIndex(index)}>
                                    <div>
                                        <div className="count">{statistics[index]?.total ?? 0}</div>
                                        <div className={`percent ${statistics[index]?.changePercent >= 0 ? 'increase' : 'decrease'}`}>
                                            <img />
                                            {statistics[index]?.changePercent ?? 0}%
                                        </div>
                                    </div>
                                    <div>{title}</div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="admin-traction-container">
                        {(isLoadingStatistics || isLoadingTractions) ? <Loader /> :
                            <>
                                {(statistics && tractionIndex == 0) &&
                                    <div className="additional-statistics">
                                        <div title="% lockrScan users who upgraded to ADD a lockrMail account">
                                            <span>% lockrScan upgraded to lockrMail:</span>
                                            <span>{parseFloat(statistics[0].upgradedLockrMailPercentage).toFixed(2)}%</span>
                                        </div>
                                        <div title="lockrScan accounts added lockrMail">
                                            <span>lockrScan accounts added lockrMail:</span>
                                            <span>{statistics[0].upgradedLockrMailAccountNumber}</span>
                                        </div>
                                    </div>
                                }
                                {tractionIndex === 1 && <span>{`Lifetime Total: ${statistics[1].totalLifeTime}`}</span>}
                                {tractionIndex === 2 && <span>{`Lifetime Total: ${statistics[2].totalLifeTime}`}</span>}
                                {tractionIndex === 3 && <span>{`Lifetime Total: ${statistics[3].totalLifeTime}`}</span>}
                                <Charts
                                    categories={chartCategories()}
                                    names={selectedDataNames()}
                                    stacks={chartStacks()}
                                    data={chartData()}
                                    differentStack={["Shipments"]}
                                    differentStackNames={[{ name: 'Shipments', colors: { bar1: '#E88D67', bar2: '#E88D67' } }]}
                                // max={chartMax()}
                                />
                                <div className="names-container">

                                    {
                                        tractionIndex === 3 &&
                                        <>
                                            <Checkbox
                                                label={'Shipments'}
                                                checked={activeSelection === SelectionTypes.SHIPMENT}
                                                onChange={() =>
                                                    toggleSelection(SelectionTypes.SHIPMENT)
                                                }
                                            />

                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}