import { BaseModel } from 'core/models/base'
import { Api } from "core/api"
import { connectedEmailIconColors } from '../../constants'
import { DateHelper, ObjectHelper } from 'utils'

const emailTypes = ['Personal', 'Business', 'School', 'Other']

export class ConnectedEmail extends BaseModel {
	userId
	email
	domain
	isPrimary
	verifiedAt
	orderNum
	avatar
	emailType = 0
	isVisible

	/** @returns {ConnectedEmail} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.verifiedAt = DateHelper.parse(json.verifiedAt)
		}
		return result
	}

	/** @returns {Array<ConnectedEmail>} */
	static fromJSONArray(arrJson) { return super.fromJSONArray(arrJson) }

	static createBusinessEmail(email) {
		const result = new ConnectedEmail()
		result.email = email
		result.isPrimary = false
		return result
	}

	static sort(emails) {
		return emails.sort((a, b) => {
			return (a.orderNum < b.orderNum) ? -1 : 0
		})
	}

	static emailTypes() { return emailTypes }
	static emailTypeInText(emailType) { return emailTypes[emailType] }
	static async verify(token) {
		try {
			return await Api.post('/email/verify', { token: token })
		} catch (error) {
			return { error: Api.parseError(error, 'Sorry, there are some troubles to verify email.') }
		}
	}

	static iconColor(index) {
		return connectedEmailIconColors[Math.max(index, 0) % connectedEmailIconColors.length]
	}

	constructor(email, emailType = 3) {
		super()

		this.email = email
		this.emailType = emailType
	}

	get isVerified() { return ObjectHelper.isValid(this.verifiedAt) }

	get isAfter7d() {
		return DateHelper.getDayDifference(Date.now(), this.createdAt)
	}

	emailTypeInText() {
		return emailTypes[this.emailType]
	}

	async verify() {
		const defaultError = 'There is a trouble to send verify email. Please try again later.'
		const params = {
			connectedEmailId: this.id,
			email: this.email
		}

		try {
			const { error } = await Api.post("/email/resend-verify", params)
			return { error: error ? defaultError : null }
		} catch (error) {
			return { error: Api.parseError(error, defaultError) }
		}
	}

	setEmailType(emailType) {
		this.emailType = emailTypes.indexOf(emailType)
	}
}