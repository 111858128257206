import React from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

import './styles.scss'
import iconClose from 'assets/images/onboard_close.svg'
import iconLock from 'assets/images/onboard_lock.svg'

import {
	TextField,
} from 'components'

import { Api, useAuth } from "core"
import {
	checkPasswordValidation, isValidText,
	Crypto,
	Storage,
} from "utils"
import { redirectTo, routes } from "routes"
import { Helmet } from "react-helmet"

export function NewPasswordScene(props) {
	const navigate = useNavigate()
	const location = useLocation()
	const { verifyToken } = useParams()

	const [password1, setPassword1] = React.useState('')
	const [password2, setPassword2] = React.useState('')
	const [passwordOneVisibility, setPasswordOneVisibility] = React.useState(true);
	const [passwordTwoVisibility, setPasswordTwoVisibility] = React.useState(true);
	const [isPasswordUpdated, setIsPasswordUpdated] = React.useState(false);
	const [errorCode, setErrorCode] = React.useState()
	const { userGetProfile, currentUser } = useAuth()

	const [error, setError] = React.useState([])

	React.useEffect(() => {
		setError([])
	}, [password1, password2])

	React.useEffect(() => {
		redirect(currentUser)
	}, [currentUser])

	function redirect(user) {
		if (!user || !isPasswordUpdated) return

		if (user.allow2FA && !user.is2FAPassed) {
			navigate(redirectTo({ route: routes.TFA }), { state: { from: location.state?.from } })
			return
		}

		if (location.state?.from) {
			navigate(location.state?.from)
		} else if (user.isAdmin) {
			navigate(redirectTo({ route: routes.Admin }))
		} else if (user.doneTutorial) {
			navigate(redirectTo({ route: routes.Dashboard }))
		} else {
			navigate(redirectTo({ route: routes.Tutorial }))
		}
	}

	function handleClose() {
		navigate(-1)
	}

	function handleChangeMyPassword() {
		const validationError = checkPasswordValidation(password1, password2)
		if (validationError.length > 0) {
			setError(validationError)
			return
		}

		const encryptedPassword1 = Crypto.encrypt(password1)
		const encryptedPassword2 = Crypto.encrypt(password2)
		const params = {
			password: encryptedPassword1,
			passwordConfirmation: encryptedPassword1,
			token: verifyToken
		}
		Api.post("/submit-forgot-password", params)
			.then(response => {
				const { success, error, data } = response
				if (!success || error) {
					if (error == 'The session has expired. Please refresh the page and login to your account to return to the dashboard.') {
						setError(["Error: Password link expired. "])
						setErrorCode(401)
						return
					}
					return
				}
				if(!data.accessToken){
					navigate(redirectTo({ route: routes.Login }))
				}
				Storage.accessToken = data.accessToken
				Storage.refreshToken = data.refreshToken
				Storage.cookieAccessToken = data.accessToken
				Storage.cookieRefreshToken = data.refreshToken

				setIsPasswordUpdated(true)
				userGetProfile()
			})
			.catch(error => {
				const message = Api.parseError(error, "Sorry, there are some troubles to reset password.")
				setError([message])
			})
	}

	return (
		<div className="new-password">
			<Helmet>
				<title>lockrMail | Reset your password</title>
			</Helmet>
			<div className="new-password-close" onClick={handleClose}>
				<img src={iconClose} alt="Close" />
			</div>
			<div className="new-password-container">
				<div className="new-password-logo">
					<img src={iconLock} alt="Loc.kr" />
				</div>
				<div className='new-password-title'>Reset your password</div>
				<div className="new-password-form mt-48">
					<TextField
						type="large"
						id="emailInput"
						autoFocus
						placeholder="New Password"
						value={password1}
						password error={error.length > 0}
						passwordVisibility={passwordOneVisibility}
						setPasswordVisibility={setPasswordOneVisibility}
						onChange={(event) => setPassword1(event.target.value)} />
					<TextField
						type="large"
						id="passwordInput"
						className="new-password-form-divider"
						placeholder="Confirm New Password"
						value={password2}
						passwordVisibility={passwordTwoVisibility}
						setPasswordVisibility={setPasswordTwoVisibility}

						password error={error.length > 0}
						onChange={(event) => setPassword2(event.target.value)}
						onNext={() => handleChangeMyPassword()} />
				</div>
				{error.map((e, index) => {
					return (
						<div key={index} className="new-password-error">
							{e}
						</div>
					)
				})}
				<div className="new-password-description mt-16">
					Passwords must have 6 characters, 1 uppercase character, 1 lowercase character, 1 number, 1 non-alphanumeric character.
				</div>
				<div className="new-password-button">
					<button type="button" disabled={!isValidText(password1) || !isValidText(password2)} onClick={() => handleChangeMyPassword()}>
						Change my password
					</button>
				</div>
				{errorCode == 401 && <div className="new-password-again-reset">
					Still need to reset your password? <Link to={"/forgot-password"} style={{ color: '#61CEF7', fontWeight: 'bold', cursor: 'pointer' }}>Get new link</Link>
				</div>}
			</div>
		</div>
	)
}

