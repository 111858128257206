import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import './styles.scss'
import iconRemove from 'assets/images/cross-12px-888888.svg'
import iconFilter from 'assets/images/filter/gray.svg'
import iconDrag from 'assets/images/no-drag-gray.svg'
import iconDragOn from 'assets/images/drag-green.svg'

import { Icon, BadgeButton, } from 'components'

import {
	useAlert,
	useDigests, Digest,
	Sender,
} from 'core'
import { routeToMe } from 'routes'

/**
 * @typedef {{sender: Sender, dragging: boolean, onFilter: Function}} DigetSenderListItemProps
 * @param {DigetSenderListItemProps} param0
 */
export function DigetSenderListItem({
	dragging = false,
	sender,
	onFilter = () => { },
}) {
	const navigate = useNavigate()
	const { setError } = useAlert()
	const { digestLoadSenders, currentDigest } = useDigests()
	const location = useLocation();

	async function handleRemove() {
		if (!sender) return

		const { error } = await Digest.removeSenders([sender], currentDigest.id)
		if (error) {
			setError(error)
			return
		}

		digestLoadSenders(false)
	}

	function handleFilter() {
		if (sender.digestFilterId) {
			navigate(routeToMe({ searches: { filterId: sender.digestFilterId } }), { state: { isDigest: true } })
			return
		}
		onFilter()
	}

	return (
		<React.Fragment>
			<div className={`my-digest-sender-list-item ${dragging && 'dragging'}`}>
				<div>
					<Icon src={sender?.avatar} hasWhite={sender?.hasWhite} placeholder={sender?.name} />
					<div className="name">{sender?.name}</div>
					<div className="email">{sender?.email}</div>
				</div>
				<div>
					<button className="remove" onClick={() => handleRemove()}>
						<img src={iconRemove} alt="" />
						<span>Remove</span>
					</button>
					<BadgeButton
						className="filter"
						icon={iconFilter}
						active={sender?.hasDigestFilter}
						onClick={() => handleFilter()} />
					<img className="drag" src={dragging ? iconDragOn : iconDrag} alt="" />
				</div>
			</div>
		</React.Fragment>
	)
}