import React from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './styles.scss'
import iconCircle1 from 'assets/images/numbers/circle-1.svg'
import iconCircle2 from 'assets/images/numbers/circle-2.svg'
import iconCircle3 from 'assets/images/numbers/circle-3.svg'
import iconHeaderLogo from 'assets/images/lockr-logo.svg'
import iconLogo from 'assets/images/onboard_lock.svg'
import iconChromeExtension from 'assets/images/browser-extension/chrome-extension.svg'
import iconChromeExtensionBackground from 'assets/images/browser-extension/chrome-extension-background.png'
import iconChromeExtensionLoggedIn from 'assets/images/browser-extension/chrome-extension-loggedin.svg'
import iconChromeExtensionLoggedInBackground from 'assets/images/browser-extension/chrome-extension-loggedin-background.png'
import iconBannerDots from 'assets/images/browser-extension/banner-dots.svg'
import iconArrowDown from 'assets/images/arrow/down-white-large.svg'
import iconHowItWorks from 'assets/images/browser-extension/how-it-works.svg'
import iconHowItWorksDots from 'assets/images/browser-extension/how-it-works-dots.svg'
import iconAutoFill from 'assets/images/browser-extension/auto-fill.svg'
import iconAutoFillDots from 'assets/images/browser-extension/auto-fill-dots.svg'

import {
	AppFooter,
	TextField
} from 'components'
import { HowItWorksEl } from './how-it-works'

import {
	useAlert,
	Api, useAuth, User
} from 'core'
import { ArrayHelper, isEmail, isValidText, stringContainsUrl } from 'utils'
import { config } from 'constants/config'
import { errorCodes, errorMessages } from 'constants/error'
import { routes, redirectTo } from 'routes'
import sanitize from 'sanitize-html'

const API_BASE_URL = process.env.REACT_APP_WORDPRESS_URL

const sampleHowItWorks = [
	{ featuredImage: iconCircle1, title: 'Sign up anywhere', content: 'Use lockrMail on any website with the lockrMail extension or easily remember it for use on mobile or offline' },
	{ featuredImage: iconCircle2, title: 'Protects your personal inbox and information', content: 'Have inbox fatigue? Concerned over the number of companies requiring your personal information? lockrMail is here to save you the hassle of email management and maintain your anonymity online.' },
	{ featuredImage: iconCircle3, title: 'Browse the web without interruption', content: 'Fast sign up on any site - with lockrMail browser extension, have your email auto-complete on any form. Plus, with partner websites you can be logged in automatically - avoiding any registration needs on publisher sites and some eCommerce platforms.' },
]

const sampleBottomBanner = {
	featuredImage: iconLogo,
	title: 'Saving you the hassle of email management.',
	content: 'Whether you have inbox fatigue and want to filter out emails from a specific sender, want to forward certain emails to your personal or professional email accounts, or want to create a digest of multiple emails into one.'
}

export function BrowserExtensionScene(props) {
	const navigate = useNavigate()

	const { setAlert, setError } = useAlert()

	const { currentUser } = useAuth()

	const [isLoading, setLoading] = React.useState(false)
	const [howItWorks, setHowItWorks] = React.useState(sampleHowItWorks)
	const [bottomBanner, setBottomBanner] = React.useState(sampleBottomBanner)

	const [email, setEmail] = React.useState('')
	const [emailError, setEmailError] = React.useState(null)
	React.useEffect(() => { setEmailError(null) }, [email])
	const [firstName, setFirstName] = React.useState('')
	const [lastName, setLastName] = React.useState('')

	async function load() {
		setLoading(true)
		try {
			const requestPath = API_BASE_URL + '/wp-json/wp/v2/browser-extension'
			const response = Api.handleResponse(await axios.get(requestPath))
			if (!ArrayHelper.is(response)) {
				setLoading(false)
				return
			}

			let howItWorks = response.filter(e => e.section === 'how-it-works')
			howItWorks.sort((a, b) => a['order'] - b['order'])
			setHowItWorks(howItWorks)
		} catch (error) {

		}
		setLoading(false)
	}

	async function checkAvailable() {
		const { error, errorCode } = await User.checkEmailUsed(email)
		if (error) {
			if (errorCode === errorCodes.REGISTER_EMAIL_ALREADY_IN_USE) setEmailError(errorMessages.ERROR_EMAIL_ALREADY_IN_USE)
			else if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) setEmailError(errorMessages.NOT_SUPPORTED_EMAILS)
			else if (errorCode === errorCodes.LOCKRMAIL_NOT_SUPPORTED) setEmailError(errorMessages.ERROR_EMAIL_NOT_SUPPORTED)
			else { setError(error) }
			return false
		}
		return true
	}

	async function handleCreateAccount() {
		const isAvailable = await checkAvailable()
		if (!isAvailable) return
		let fName = sanitize(firstName, { allowedTags: [] });
		let lName = sanitize(lastName, { allowedTags: [] });
		navigate(redirectTo({
			route: routes.Register,
			searches: { email, firstName: fName, lastName: lName, permalink: 'chrome-install' }
		}))
	}

	return (
		<div id="parent" className="page-container browser-extension">
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<div className="top-bar"></div>
			<div className="header">
				<div className="header-content">
					<a href="/"><img src={iconHeaderLogo} alt="Lockr.io" /></a>
					<div className="header-content-right">
						<Link className="header-item active" to="/browser-extension">Browser Extension</Link>
						<a className="header-item" href={`${process.env.REACT_APP_WORDPRESS_URL}/faq`} target="_blank">FAQ</a>
						<a className="header-item" href={`${process.env.REACT_APP_WORDPRESS_URL}/contact`} target="_blank">Contact</a>
						<a className="header-item" href="/login">Sign in</a>
						<a className="header-item" href="/signup">Sign up</a>
					</div>
				</div>
			</div>
			<div className="browser-extension-content">
				<div className="group banner-group">
					<div className="group-wrapper">
						<div className="screenshot-container">
							<img className="screenshot" src={currentUser ? iconChromeExtensionLoggedIn : iconChromeExtension} alt="" />
						</div>
						<div className="register-container">
							<div className="register-title">Thanks for installing lockrMail for Chrome!</div>
							{currentUser ?
								<div className="loggedin-content">
									<div className="subtitle">What now? Make sure you pin the lockrMail extension so it shows up in your browser bar.</div>
									<div className="subtitle">Once you have pinned the extension, log in to your lockrMail account and you're good to go!</div>
									<div className="subtitle gray">Don't have a login? Let's get started by <a href="/signup">creating your account.</a></div>
								</div>
								:
								<div className="register-content">
									<div className="register-subtitle">Let's get you started by creating your account.</div>
									<div className="register-subtitle">Already have an account? <a href="/login">Sign in</a> to the extension in your browser bar above.</div>
									<div className="register-form">
										<div className='register-form-email'>
											<TextField
												type="large"
												id="email"
												className="register-input"
												placeholder="Personal or work email"
												autofocus
												value={email}
												onChange={(event) => setEmail(event.target.value.trim())} />
											{emailError && <div className='register-error'>Error: {emailError}</div>}
										</div>
										<div className='register-form-name'>
											<TextField
												type="large"
												id="firstName"
												className="register-input"
												placeholder="First name"
												value={firstName}
												onChange={(event) => setFirstName(event.target.value)} />
											<TextField
												type="large"
												id="lastName"
												className="register-input"
												placeholder="Last name"
												value={lastName}
												onChange={(event) => setLastName(event.target.value)}
												onNext={() => handleCreateAccount()} />
										</div>
										<button
											id="register-button"
											className="register-button"
											disabled={!(isEmail(email) && isValidText(firstName) && isValidText(lastName) && !stringContainsUrl(firstName) && !stringContainsUrl(lastName))}
											onClick={() => handleCreateAccount()}>
											Create account
										</button>
									</div>
								</div>
							}
							<a className="see-how-it-works" href="#how-it-works">
								<span>See how it works</span>
								<img src={iconArrowDown} alt="" />
							</a>
						</div>
						<img className="screenshot-background" src={currentUser ? iconChromeExtensionLoggedInBackground : iconChromeExtensionBackground} />
					</div>
					<img className="background banner" src={iconBannerDots} alt="" />
				</div>
				<HowItWorksEl
					id='how-it-works'
					className='group' />
				<div className="group auto-fill-forms">
					<img className="background" src={iconAutoFillDots} alt="" />
					<div className="group-wrapper">
						<div className="auto-fill-forms-content">
							<img src={iconAutoFill} alt="" />
							<div>
								<div className="title">3 Tips for Using the lockrMail Chrome Extension</div>
								<a className="read-blog" href={`${config.LOCKR_URL}/3-tips-for-using-the-lockrmail-chrome-extension`} target="_blank">Read on the blog</a>
							</div>
						</div>
					</div>
				</div>
				<div className="group">
					<div className="group-wrapper">
						<div className="bottom-banner">
							<img src={bottomBanner.featuredImage} />
							<div className="title">{bottomBanner.title}</div>
							<div className="subtitle">{bottomBanner.content}</div>
							<div className="button-container">
								<a className="learn-more" href={process.env.REACT_APP_WORDPRESS_URL}>Learn more</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AppFooter />
		</div>
	)
}