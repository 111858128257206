import React from 'react'
import './styles.scss'

import { Loader } from 'components'
import { SenderListItem } from '../sender-list-item'

import {
	ArrayHelper
} from 'utils'

export function DashboardSenderList({
	id = "",
	className = "",
	isLoading = false,
	senders = [],
	getSenderList,
	searchText = '',
	viewState = 1,
	selectedSenders = [],
	onSelectSender = (sender) => { },
	highlightEmail,
	status = null,
	googleOauthLink = null,
	ipAddr=null,
	permalink=null,
}) {

	function renderSenders(senders) {
		return (
			<>
				{ArrayHelper.isValid(senders) ?
					(senders?.length > 0 ? (senders.map((sender) => {
						return (
							<SenderListItem
								key={sender.id}
								sender={sender}
								getSenderList={getSenderList}
								viewState={viewState}
								selected={selectedSenders.some(x => x.email === sender.email)}
								onSelect={() => onSelectSender(sender)}
								highlight={highlightEmail == sender?.email ? true : false}
								googleOauthLink={googleOauthLink}
								ipAddr={ipAddr}
								permalink={permalink}
							/>
						)
					})) : <div className='dashboard-no-hidden'>{viewState == 2 ? "No Hidden Senders to show" : "No Senders to show"}</div>)
					:
					((searchText == '' && !status && viewState !== 2) ? <div className="no-sender"> lockrScan is loading, please refresh the page.</div> : <div className='dashboard-no-hidden'>{viewState == 2 ? "No Hidden Senders to show" : "No Senders to show"}</div>)
				}
			</>
		)
	}

	return (
		<div id={id} className={`dashboard-sender-list ${className}`}>
			{isLoading && <Loader />}
			{!isLoading &&
				<div className="sender-list-group">
					<div className="sender-list">{renderSenders(senders)}</div>
				</div>
			}
		</div>
	)
}