import React from 'react'

import './styles.scss'
import {
	AlertPage, AlertTitle, AlertButtonContainer, AlertButton,
	TextField, Switch, HMenuDatePicker, TimePicker
} from '../../'

import { AdminSpecialLink } from '../../../core'
import { isNumber } from '../../../utils'

export function EditEl({
	initialPermalink,
	specialLink,
	onSaved = () => { },
	onCancel = () => { }
}) {
	const [permalink, setPermalink] = React.useState(initialPermalink)
	const [permalinkError, setPermalinkError] = React.useState(null)
	React.useEffect(() => { setPermalinkError(null) }, [permalink])

	const [isRestrictTime, setRestrictTime] = React.useState(true)
	const [startAt, setStartAt] = React.useState(new Date())
	const [expireAt, setExpireAt] = React.useState(new Date())

	const [isRestrictRegistration, setRestrictRegistration] = React.useState(true)
	const [limit, setLimit] = React.useState('0')
	const [limitError, setLimitError] = React.useState(null)
	React.useEffect(() => { setLimitError(null) }, [limit])

	React.useEffect(() => {
		if (specialLink) {
			const { permalink, isRestrictTime, startAt, expireAt, isRestrictRegistration, limit } = specialLink
			setPermalink(permalink)
			setRestrictTime(isRestrictTime)
			setStartAt(startAt)
			setExpireAt(expireAt)
			setRestrictRegistration(isRestrictRegistration)
			setLimit(`${limit}`)
		}
	}, [specialLink])

	function checkValidation() {
		let result = true
		if (!AdminSpecialLink.isValidLink(permalink)) {
			setPermalinkError('Invalid link.')
			result = false
		}

		if (isRestrictRegistration && (!isNumber(limit) || parseInt(limit) <= 0)) {
			setLimitError('Invalid limit.')
			result = false
		}

		return result
	}

	async function handleSave() {
		if (!checkValidation()) return

		const params = {
			permalink,
			startAt,
			expireAt,
			limit,
			isRestrictTime,
			isRestrictRegistration
		}

		const { error } = specialLink ? await specialLink.update(params) : await AdminSpecialLink.create(params)
		if (error) { console.log(error) }
		else { onSaved() }
	}

	return (
		<>
			<AlertTitle>{specialLink ? permalink : 'New Special Link'}</AlertTitle>
			<div className="edit-container">
				<div className="form-item">
					<TextField
						className="form-input"
						value={permalink}
						placeholder="Custom Link"
						onChange={(event) => setPermalink(event.target.value)} />
					{permalinkError && <div className="error">{permalinkError}</div>}
				</div>
				<div className="form-item">
					<span>Restrict by date/time:</span>
					<div>
						<Switch value={isRestrictTime} onChange={() => setRestrictTime(!isRestrictTime)} />
					</div>
				</div>
				{isRestrictTime &&
					<>
						<div className="form-item">
							<span>Start:</span>
							<div>
								<HMenuDatePicker
									position="first"
									date={startAt}
									onSelect={date => setStartAt(date)} />
								<TimePicker
									value={startAt}
									onChange={(value) => setStartAt(value)} />
							</div>
						</div>
						<div className="form-item">
							<span>End:</span>
							<div>
								<HMenuDatePicker
									position="first"
									date={expireAt}
									onSelect={date => setExpireAt(date)} />
								<TimePicker
									value={expireAt}
									onChange={(value) => setExpireAt(value)} />
							</div>
						</div>
					</>
				}
				<div className="form-item">
					<span>Restrict by registrations:</span>
					<div>
						<Switch value={isRestrictRegistration} onChange={() => setRestrictRegistration(!isRestrictRegistration)} />
					</div>
				</div>
				{isRestrictRegistration &&
					<div className="form-item">
						<span>Limit:</span>
						<div>
							<TextField
								className="form-input"
								inputType="number"
								value={limit}
								onChange={(event) => setLimit(event.target.value)} />
						</div>
						{limitError && <div className="error">{limitError}</div>}
					</div>
				}
			</div>
			<AlertButtonContainer>
				<AlertButton onClick={() => handleSave()}>Save</AlertButton>
				<AlertButton type="negative" onClick={() => onCancel()}>Cancel</AlertButton>
			</AlertButtonContainer>
		</>
	)
}