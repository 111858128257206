import { BaseModel } from 'core/models/base'
import { Api } from 'core/api'

import { errorMessages } from 'constants/error'

export class AdminWhiteListIP extends BaseModel {
    ipAddr
    tryCount

    /** @returns {AdminWhiteListIP} */
    static fromJSON(json) { return super.fromJSON(json) }

    static async search({ searchText, pageIndex, pageSize, sortBy }) {
        try {
            const { error, data } = await Api.get('/admin/whitelist-ip-addresses', { searchText, pageNum: pageIndex + 1, pageSize, sortBy })
            return {
                error: error && errorMessages.CONTACT_US,
                totalCount: data?.count ?? 0,
                results: AdminWhiteListIP.fromJSONArray(data?.results)
            }
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async create({ ipAddress, isOverride }) {
        try {
            const { error, errorCode } = await Api.post('/admin/whitelist-ip-addresses', { ipAddress, isOverride })
            return { error: error && errorMessages.CONTACT_US, errorCode };
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }

    static async delete(ipAddrs) {
        try {
            const ipAddresses = ipAddrs.map(x => typeof x === 'string' ? x : x.ipAddr)
            const { error } = await Api.delete('/admin/whitelist-ip-addresses', { ipAddresses })
            return { error: error && errorMessages.CONTACT_US, }
        } catch (error) {
            return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
        }
    }
}