import React from 'react'

import './styles.scss'
import iconInfo from 'assets/images/info-white.svg'
import iconClear from 'assets/images/cross/gray-16.svg'

import { IModal } from 'components/i-modal'

import { randomUUID, isNumber, isValidText } from 'utils'

export const NormalTextField = React.forwardRef(({
	id = randomUUID(),
	className = '',
	inputType = 'text',
	placeholder,
	clearable = false,
	disabled = false,
	value,
	imodal,
	onChange = (event) => { },
	onBlur = (event) => { },
	onNext = () => { },

	onFocus = (event) => { },
}, ref) => {
	const iIconRef = React.useRef()

	function handleChange(event) {
		event.preventDefault()

		if (inputType === 'number' && !isNumber(event.target.value)) return

		onChange(event)
	}

	function handleKeyDown(event) {
		if (event.key === 'Enter') {
			onNext()
		}
	}

	function handleClick(event) {
		event.stopPropagation()
	}

	function handleClear() {
		onChange('')
	}

	function handlePlaceholderClick(event) {
		if (!event.target.closest('.i-modal')) {
			if (ref && ref.current) ref.current.focus()
		}
	}

	return (
		<div className={`text-field normal ${className}`}>
			<input
				ref={ref}
				id={id}
				placeholder=" "
				disabled={disabled}
				value={value || ''}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onClick={handleClick}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			{(clearable && isValidText(value)) &&
				<button className="clear" onClick={() => handleClear()}><img src={iconClear} alt="" /></button>
			}
			<div className="placeholder" onClick={handlePlaceholderClick}>
				{placeholder}
				{imodal && <img ref={iIconRef} className="i-icon" src={iconInfo} alt="" />}
			</div>
			{imodal &&
				<IModal anchorEl={iIconRef.current}>{imodal}</IModal>
			}
		</div>
	)
})