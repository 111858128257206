import React, { useState, useEffect } from 'react'

import './styles.scss'
import iconChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconUnchecked from 'assets/images/checkbox-gray-0.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'

import {
	Loader, Checkbox,
	SearchField,
	Select, SelectDropDown, SelectGroup, SelectOption,
	VMenu, MenuOption,
	Pagination
} from 'components'
import { UserListItem } from './user-list-item'

import {
	useAlert,
	User,
	AdminCurrentUsersProvider, useAdminCurrentUsers, useAdminDeletedUsers, AdminUser, AdminUserTag
} from 'core'
import { ArrayHelper, ObjectHelper } from 'utils'

export function AdminCurrentUsersEl({ }) {
	const { setError } = useAlert()
	const { isLoading, totalCount, currentUsers, adminCurrentUsersSearch } = useAdminCurrentUsers()
	const { adminDeletedUsersReload } = useAdminDeletedUsers()

	const selectAllMenuRef = React.useRef()

	const [searchText, setSearchText] = useState('')
	const [pageNum, setPageNum] = useState(0)
	const [pageSize, setPageSize] = useState(5)
	const [sortBy, setSortBy] = useState('createdAt')	// "createdAt"|"-loginCount"|"loginCount"|"-mailCount"|"mailCount"|"senderCount"|"refSent"|"refEarned"|"permalink"
	const [autoBlock, setAutoBlock] = useState(null)
	const [allowConsent, setAllowConsent] = useState(0)
	const [extensionInstalled, setExtensionInstalled] = useState(0)


	const [selectedHiddenFeatures, setSelectedHiddenFeatures] = React.useState([])
	const [selectedBatchHiddenFeatures, setSelectedBatchHiddenFeatures] = React.useState([])
	const [selectedUsers, setSelectedUsers] = React.useState([])
	React.useEffect(() => {
		setSelectedUsers(selectedUsers?.map(x => currentUsers.find(y => y.id === x.id) ?? x))
	}, [currentUsers])
	React.useEffect(() => {
		const hiddenFeatures = []
		selectedUsers.forEach(x => {
			x.hiddenFeatures.forEach(feature => {
				if (!hiddenFeatures.includes(feature) && !selectedUsers.some(x => !x.hasHiddenFeature(feature))) {
					hiddenFeatures.push(feature)
				}
			})
		})
		setSelectedBatchHiddenFeatures(hiddenFeatures)
	}, [selectedUsers])

	// Special Links
	const [specialLinks, setSpecialLinks] = React.useState([])
	const [selectedSpecialLinks, setSelectedSpecialLinks] = React.useState([])

	// Wordpress Links
	const [wordpressLinks, setWordpressLinks] = React.useState([])
	const [selectedWordpressLinks, setSelectedWordpressLinks] = React.useState([])

	// User Tags
	const [userTags, setUserTags] = React.useState([])
	const [selectedUserTags, setSelectedUserTags] = React.useState([])

	useEffect(() => {
		loadUsers()
	}, [searchText, pageNum, pageSize, sortBy, autoBlock, allowConsent, selectedSpecialLinks, selectedWordpressLinks, selectedUserTags, selectedHiddenFeatures, extensionInstalled])

	React.useEffect(() => { loadUserTags() }, [])

	async function loadUsers() {
		let params = {
			searchText,
			pageNum: pageNum + 1,
			pageSize,
			sortBy,
		}
		if (selectedSpecialLinks.length > 0) params = { ...params, permalinks: selectedSpecialLinks.join(',') }
		if (selectedWordpressLinks.length > 0) params = { ...params, wordpressLinks: selectedWordpressLinks.join(',') }
		if (selectedUserTags.length > 0) params = { ...params, tags: selectedUserTags.join(',') }
		if (ArrayHelper.isValid(selectedHiddenFeatures)) params = { ...params, hiddenFeatures: selectedHiddenFeatures }
		if (ObjectHelper.isValid(autoBlock)) params = { ...params, autoBlock }
		if (allowConsent == 1) params = { ...params, allowConsent: true }
		else if (allowConsent == -1) params = { ...params, allowConsent: false }
		if (extensionInstalled == 1) params = { ...params, isExtensionInstalled: true }
		else if (extensionInstalled == -1) params = { ...params, isExtensionInstalled: false }


		adminCurrentUsersSearch(params)
	}

	async function loadUserTags() {
		const { specialLinks = [], wordpressLinks, userTags } = await AdminUserTag.getAll()

		setSpecialLinks(specialLinks)
		setWordpressLinks(wordpressLinks)
		setUserTags(userTags)
	}

	function handleSelectUser(user) {
		if (selectedUsers.some(x => x.id === user.id)) {
			setSelectedUsers(selectedUsers.filter(x => x.id !== user.id))
		} else {
			setSelectedUsers([...selectedUsers, user])
		}
	}

	function handleSelectAll(value) {
		if (value) setSelectedUsers(currentUsers)
		else setSelectedUsers([])
	}

	function handleSelectAllMenu(event) {
		event.preventDefault()
		event.stopPropagation()
		if (selectAllMenuRef.current) {
			selectAllMenuRef.current.toggle()
		}
	}

	function handleSpecialLink(value) {
		if (ArrayHelper.equals(specialLinks, selectedSpecialLinks)) {
			setSelectedSpecialLinks([value])
		} else if (selectedSpecialLinks.some(e => e === value)) {
			setSelectedSpecialLinks(selectedSpecialLinks.filter(e => e !== value))
		} else {
			setSelectedSpecialLinks([...selectedSpecialLinks, value])
		}
	}

	function handleWordpressLink(value) {
		if (ArrayHelper.equals(wordpressLinks, selectedWordpressLinks)) {
			setSelectedWordpressLinks([value])
		} else if (selectedWordpressLinks.some(e => e === value)) {
			setSelectedWordpressLinks(selectedWordpressLinks.filter(e => e !== value))
		} else {
			setSelectedWordpressLinks([...selectedWordpressLinks, value])
		}
	}

	function handleUserTag(value) {
		if (ArrayHelper.equals(userTags, selectedUserTags)) {
			setSelectedUserTags([value])
		} else if (selectedUserTags.some(e => e === value)) {
			setSelectedUserTags(selectedUserTags.filter(e => e !== value))
		} else {
			setSelectedUserTags([...selectedUserTags, value])
		}
	}

	function handleHiddenFeature(feature) {
		if (selectedHiddenFeatures.includes(feature)) {
			setSelectedHiddenFeatures(selectedHiddenFeatures.filter(x => x !== feature))
		} else {
			setSelectedHiddenFeatures([...selectedHiddenFeatures, feature])
		}
	}

	function handleResetViewOptions() {
		setSelectedSpecialLinks([])
		setSelectedWordpressLinks([])
		setSelectedUserTags([])
		setAutoBlock(null)
		setExtensionInstalled(0)
	}

	async function handleBatchHiddenFeature(feature) {
		const { error } = await AdminUser.enableHiddenFeatures({
			userIds: selectedUsers?.map(x => x.id),
			hiddenFeatures: [feature]
		})

		if (error) {
			setError(error)
			return
		}

		loadUsers()
	}
	async function handleBatchDeleteUsers(users) {
		const { error } = await AdminUser.deleteUsers(users)

		if (error) {
			setError(error)
			return
		}
		loadUsers()
		adminDeletedUsersReload()
	}

	const handleAllowConsent = (type) => {
		if (type == allowConsent) {
			setAllowConsent(0)
			return
		}
		setAllowConsent(type)
	}


	return (
		<div className="admin-current-users">
			<div className="total">Total: {totalCount}</div>
			<div className="header">
				<div onClick={() => handleSelectAll()}>
					<Checkbox
						iconChecked={iconChecked}
						iconUnchecked={iconUnchecked}
						checked={ArrayHelper.isValid(selectedUsers)}
						onChange={(value) => handleSelectAll(value)}
					/>
					<div className="menu-arrow v-menu-parent" onClick={handleSelectAllMenu}>
						<img src={iconArrowDown} alt="" />
						<VMenu
							ref={selectAllMenuRef}
							noselection
							options={[
								new MenuOption({ label: 'Select All', onClick: () => handleSelectAll(true) }),
								new MenuOption({ label: 'Deselect All', onClick: () => handleSelectAll(false) })
							]}
						/>
					</div>
					{(ArrayHelper.isValid(selectedUsers)) &&
						<div className="batch-container" onClick={event => event.stopPropagation()}>
							<div className="btn-batch more" data-descr="Approve Users">
								<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="18" cy="2" r="2" transform="rotate(90 18 2)" fill="#8B8B8B" />
									<circle cx="10" cy="2" r="2" transform="rotate(90 10 2)" fill="#8B8B8B" />
									<circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#8B8B8B" />
								</svg>
								<SelectDropDown type="horizontal">
									<SelectGroup label="Hidden Feature">
										{Object.keys(User.HiddenFeature)?.map((key, index) => {
											const feature = User.HiddenFeature[key]
											const selected = selectedBatchHiddenFeatures.includes(feature)
											return (
												<SelectOption
													key={index}
													selected={selected}
													onClick={() => handleBatchHiddenFeature(feature)}>
													{key}
												</SelectOption>
											)
										})}
									</SelectGroup>
									<SelectOption
										selectable={false}
										onClick={() => handleBatchDeleteUsers(selectedUsers)}>
										Delete
									</SelectOption>
								</SelectDropDown>
							</div>
						</div>
					}
				</div>
				<SearchField
					value={searchText}
					onChange={setSearchText} />
				<Select
					value={sortBy}
					onChange={value => setSortBy(value)}>
					<SelectOption value="createdAt">Most Recent</SelectOption>
					<SelectOption value="-loginCount">Least Active</SelectOption>
					<SelectOption value="loginCount">Most Active</SelectOption>
					<SelectOption value="-mailCount">Least Emails</SelectOption>
					<SelectOption value="mailCount">Most Emails</SelectOption>
					<SelectOption value="senderCount">Most Senders</SelectOption>
					<SelectOption value="refSent">Most Referrals</SelectOption>
					<SelectOption value="refEarned">Most Referrals Earned</SelectOption>
					<SelectOption value="permalink">Special Links</SelectOption>
				</Select>
				<Select
					className="view-options"
					keepLabel label="View Options"
					onReset={() => handleResetViewOptions()}>
					<SelectGroup label="Special Links">
						<SelectOption
							selected={ArrayHelper.equals(selectedSpecialLinks, specialLinks)}
							onClick={() => setSelectedSpecialLinks(specialLinks)}>
							All
						</SelectOption>
						<SelectOption
							selected={!ArrayHelper.isValid(selectedSpecialLinks)}
							onClick={() => setSelectedSpecialLinks([])}>
							None
						</SelectOption>
						{
							specialLinks?.map((specialLink, index) =>
								<SelectOption
									key={index}
									selected={selectedSpecialLinks.some(e => e === specialLink)}
									onClick={() => handleSpecialLink(specialLink)}>
									{specialLink}
								</SelectOption>
							)
						}
					</SelectGroup>
					<SelectGroup label="Wordpress Links">
						<SelectOption
							selected={ArrayHelper.equals(wordpressLinks, selectedWordpressLinks)}
							onClick={() => setSelectedWordpressLinks(wordpressLinks)}>
							All
						</SelectOption>
						<SelectOption
							selected={!ArrayHelper.isValid(selectedWordpressLinks)}
							onClick={() => setSelectedWordpressLinks([])}>
							None
						</SelectOption>
						{
							wordpressLinks?.map((wordpressLink, index) =>
								<SelectOption
									key={index}
									selected={selectedWordpressLinks.some(e => e === wordpressLink)}
									onClick={() => handleWordpressLink(wordpressLink)}>
									{wordpressLink}
								</SelectOption>
							)
						}
					</SelectGroup>
					<SelectGroup label="User Tags">
						<SelectOption
							selected={ArrayHelper.equals(userTags, selectedUserTags)}
							onClick={() => setSelectedUserTags(userTags)}>
							All
						</SelectOption>
						<SelectOption
							selected={!ArrayHelper.isValid(selectedUserTags)}
							onClick={() => setSelectedUserTags([])}>
							None
						</SelectOption>
						{
							userTags?.map((userTag, index) =>
								<SelectOption
									key={index}
									selected={selectedUserTags.some(e => e === userTag)}
									onClick={() => handleUserTag(userTag)}>
									{userTag}
								</SelectOption>
							)
						}
					</SelectGroup>
					<SelectGroup label='Hidden Features'>
						{
							Object.keys(User.HiddenFeature)?.map((key, index) => {
								const feature = User.HiddenFeature[key]
								const selected = selectedHiddenFeatures.includes(feature)
								return (
									<SelectOption
										key={index}
										selected={selected}
										onClick={() => handleHiddenFeature(feature)}>
										{key}
									</SelectOption>
								)
							})
						}
					</SelectGroup>
					<SelectOption selected={autoBlock} onClick={() => setAutoBlock(!autoBlock ? true : null)}>
						AutoBlock On
					</SelectOption>
					<SelectOption selected={allowConsent == 1} onClick={() => handleAllowConsent(1)}>
						Consent On
					</SelectOption>
					<SelectOption selected={allowConsent == -1} onClick={() => handleAllowConsent(-1)}>
						Consent Off
					</SelectOption>
					<SelectGroup label="Extension">
						<SelectOption
							selected={extensionInstalled == 0}
							onClick={() => setExtensionInstalled(0)}>
							All
						</SelectOption>
						<SelectOption
							selected={extensionInstalled == 1}
							onClick={() => setExtensionInstalled(1)}>
							Installed
						</SelectOption>
						<SelectOption
							selected={extensionInstalled == -1}
							onClick={() => setExtensionInstalled(-1)}>
							Not Installed
						</SelectOption>

					</SelectGroup>

				</Select>
			</div>
			<div className="list">
				<UserListItem isHeader />
				{isLoading ? <Loader /> :
					currentUsers?.map((user, index) =>
						<UserListItem
							key={index}
							user={user}
							selected={selectedUsers.some(x => x.id === user.id)}
							onSelect={() => handleSelectUser(user)}
							onRefresh={() => {
								loadUsers()
								loadUserTags()
							}} />
					)
				}
			</div>
			<Pagination
				className="admin-current-users-pagination"
				totalSize={totalCount}
				pageIndex={pageNum}
				pageSize={pageSize}
				onPageIndex={value => setPageNum(value)}
				onPageSize={value => setPageSize(value)}
			/>
		</div>
	)
}