import React from 'react'

import './styles.scss'

import {
	DatePicker,
	RadialCheckbox,
} from 'components'
import { DeliveryTimeEl } from './delivery-time'

import {
	useDeliveryHours,
	DeliveryHour,
} from 'core'
import {
	DateHelper, ObjectHelper,
} from 'utils'

/**
 * @typedef {{className: string, deliveryHour: DeliveryHour, onChange: *, onClose: *}} DetailsElProps
 * @param {DetailsElProps} param0
 */
export function DetailsEl({
	className = '',
}) {
	const {
		isOngoing, setOngoing,
		from, setFrom,
		to, setTo,
		dateRangeError,
		deliveryTimes, setDeliveryTimes,
	} = useDeliveryHours()

	function handleDeliveryTime(weekday, value) {
		deliveryTimes[weekday] = value
		setDeliveryTimes(ObjectHelper.copy(deliveryTimes))
	}

	function handleCopyDeliveryTime(weekday) {
		const value = deliveryTimes[weekday]

		for (const key of Object.keys(deliveryTimes)) {
			deliveryTimes[key] = value
		}

		setDeliveryTimes(ObjectHelper.copy(deliveryTimes))
	}

	return (
		<div className={`delivery-hours-modal-details ${className}`}>
			<div className='delivery-hours-modal-details-group date-range'>
				<div>Date range:</div>
				<div>
					<div className='delivery-hours-modal-group-item'>
						<RadialCheckbox
							label="Ongoing"
							value={isOngoing}
							onChange={(value) => setOngoing(true)} />
					</div>
					<div className='delivery-hours-modal-group-item date-range'>
						<RadialCheckbox
							label="Date range"
							value={!isOngoing}
							onChange={(value) => setOngoing(false)} />
						{!isOngoing &&
							<React.Fragment>
								<DatePicker
									className="date-range-start"
									value={from}
									onChange={(value) => setFrom(DateHelper.startOfDay(value))} />
								<DatePicker
									className="date-range-end"
									value={to}
									onChange={(value) => setTo(DateHelper.endOfDay(value))} />
							</React.Fragment>
						}
					</div>
					{!isOngoing &&
						<div className="delivery-hours-modal-group-item date-range-container">
							<DatePicker
								className="date-range-start"
								value={from}
								onChange={(value) => setFrom(value)} />
							<DatePicker
								className="date-range-end"
								value={to}
								onChange={(value) => setTo(value)} />
						</div>
					}
				</div>
				{dateRangeError && <div className="error">{dateRangeError}</div>}
			</div>
			<div className='delivery-hours-modal-details-group days-of-delivery'>
				<div>Days of delivery:</div>
				<div className="delivery-hours-modal-group-item">
					{Object.keys(DeliveryHour.Weekdays).map((key, index) => {
						const weekday = DeliveryHour.Weekdays[key]
						return (
							<DeliveryTimeEl
								key={index}
								label={key}
								value={deliveryTimes[weekday]}
								onChange={(value) => handleDeliveryTime(weekday, value)}
								onCopy={() => handleCopyDeliveryTime(weekday)}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}